<ul class="list-sidebar bg-defoult clear-both mt-3">
    <li> 
      <a routerLink="/result/" routerLinkActive="active"> 
        <i class="fa fa-th-large"></i> 
        <span class="nav-label">Dashboard</span> 
      </a>
    
  </li>

  <li>
   <a routerLink="/calendar/" routerLinkActive="active">
      <fa-icon [icon]="calendarIcon"></fa-icon>
     <span class="nav-label">Calendar</span></a> 
  </li>
  
  <li>
    <a >
        <i class="fa fa-user-times" aria-hidden="true"></i>
      <span class="nav-label">Attendance</span></a> 
   </li>
   
  <li>
    <a routerLink="/homework/" routerLinkActive="active">
        <i class="fa fa-fw fa-book"></i>
      <span class="nav-label">Home Work</span></a> 
   </li>
    <li>
        <a routerLink="/result/" routerLinkActive="active">
            <i class="fa fa-fw fa-signal"></i>
            <span class="nav-label">Analytics</span></a>
    </li>
    <li>
        <a routerLink="/finance/" routerLinkActive="active">
            <fa-icon [icon]="financeIcon"></fa-icon>
            <span class="nav-label">Payments</span></a>
    </li>
    <li>
        <a  routerLinkActive="active">
            <fa-icon [icon]="folderIcon"></fa-icon>
            <span class="nav-label">Folder</span></a>
    </li>
    <li>
        <a  routerLinkActive="active">
            <fa-icon [icon]="messageIcon"></fa-icon>
            <span class="nav-label">Chat</span></a>
    </li>
    <li>
        <a routerLink="/referral/" routerLinkActive="active">
            <i class="fa fa-fw fa-info-circle"></i>
            <span class="nav-label">Referral</span></a>
    </li>
    <li>
        <a routerLink="/add-child/" routerLinkActive="active">
            <fa-icon [icon]="editIcon"></fa-icon>
            <span class="nav-label">Registration</span></a>
    </li>
    <li>
        <a routerLink="/parent-setting/" routerLinkActive="active">
            <fa-icon [icon]="editIcon"></fa-icon>
            <span class="nav-label">Parent Setting</span></a>
    </li>
    
    
    <li>
        <a routerLink="/feedback/" routerLinkActive="active">
            <fa-icon [icon]="calendarIcon"></fa-icon>
            <span class="nav-label">Feedback</span></a>
    </li>
  
  </ul>