

<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >Analytic Students</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white shadow p-2">
                    <!-- <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'Name' | translate}}</label>
                                <input class="form-control form-control-sm" type="text" placeholder="{{'Name' | translate}}" name="Name" #Name="ngModel" [(ngModel)]="service.formData.AnalysisMasterInfo.Name"  required class.invalid="Name.invalid && Name.touched">
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'Class'|translate}}</label>

                                <select class="form-control form-control-sm appearance-auto" name="ClassId" [(ngModel)]="service.formData.AnalysisMasterInfo.ClassId"  required>
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList"  [value]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Academic Year</label>

                                <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" [(ngModel)]="service.formData.AnalysisMasterInfo.AcademicYearId"  required>
                                    <option [value]="0">Select Academic Year</option>
                                    <option *ngFor="let year of yearList"  [value]="year.Id">
                                    {{year.Name}}
                                    </option>
                                </select>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-12 p-0">
                        <ag-grid-angular #agGrid
                        style="width: 100%; height:calc(100vh - 200px); min-height:200px"
                        class="ag-theme-alpine"
                        id="myGrid"
                        [gridOptions]="analysisStuGridOptions"
                        [columnDefs]="analysisStuColumnDefs"
                        [defaultColDef]="analysisStuDefaultColDef"
                        [rowSelection]="analysisStuRowSelection"
                        [rowData]="analysisStuRowData"
                        (selectionChanged)="onAnalyticSelectionChanged($event)"
                        (gridReady)="onAnalyticGridReady($event)"
                        [frameworkComponents]="frameworkComponents"
                        (gridReload)="onAnalysisReload($event)"
                        [enableCellChangeFlash]="true"

                        >
                        </ag-grid-angular>

                    </div>
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <!-- <button type="button" (click)="saveAnalytics()" class="btn btn-warning float-right" type="button" >Save</button> -->
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
