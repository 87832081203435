import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { faAdd, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { EditProfileComponent } from '../edit-profile.component';
import { EditEmpathyComponent } from '../edit-empathy.component';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
// import { MatSliderChange } from '@angular/material/slider';
import { User, StudentEmpathyInfo } from '../../shared/user.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgFor, SlicePipe } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AgGridModule } from 'ag-grid-angular';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  standalone: true,
  imports: [AgGridModule, SlicePipe, MatTabsModule, MatTooltipModule, FormsModule, NgFor, FontAwesomeModule, MatCardModule, MatSlideToggleModule,MatSliderModule ]
})
export class MyProfileComponent implements OnInit {
  activeTab: any;
  gridApi;
  ColumnDefs;
  DefaultColDef;
  RowData;
  RowSelection;
  GridOptions:any;
  SelectedEmpathyInfo: StudentEmpathyInfo
  attendance: string;
  SchoolName: string = "";
  bsModalRef: BsModalRef;
  profileList: any;
  profilrtypeList: any = [];
  profileTypeInfo: any = [];
  studentProfileListInfo: any = [];
  addIcon = faAdd;
  editIcon = faPen;
  deleteIcon = faTrash;
  studentEmpathyInfo:any;
  userId:number=0;
  isReadOnly: boolean = true;
  public saved: EventEmitter<any> = new EventEmitter();

  // @ViewChild('slider') slider: MatSlider; // Reference to the MatSlider component
  
  constructor(public service: UserService, public modalService: BsModalService, public commonService: CommonService,
    private notificationServ:NotificationService, private sessionSer:SessionStorageService) { 
      this.ColumnDefs = [
        { field: 'Think_Feel', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'See', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'Hear', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'Say_Do', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'Pains', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'Gains', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
      ];
      this.RowSelection = 'single';
      let userdata = this.sessionSer.getUserData();
      this.SelectedEmpathyInfo = new StudentEmpathyInfo();
      if(userdata != null){
        this.userId = userdata.UserId;
        this.getStudentProfileInfoList(this.userId);
        this.getStudentEmpathyInfoList(this.userId)
      }

      this.commonService.RefreshGrid$.subscribe(
        item => {   
          this.getStudentProfileInfoList(this.userId);
          this.getStudentEmpathyInfoList(this.userId);
         }
      );
    }

  ngOnInit(): void {
    
    
    this.getMyInfo();
    
  }

  // onSliderChange(event: MatSliderChange, studentProfileList : any){
  //   if (!this.isReadOnly) {
  //     studentProfileList.Rating = event.value;
  //     console.log('Slider value changed:', event.value);
  //   }
  // }

  saveProfile(){
    this.service.formData.UserInfo.IsSaved = false;
    if (this.service.formData.UserInfo.UserId > 0) {

      this.service.saveUser().subscribe((res: User) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if (res.IsSaved) {
          this.saved.emit('someData');
          this.notificationServ.ShowInfo("User saved successfully");
          // this.bModalRef.hide();
          this.modalService.hide(CUSTOMCONST.USER_EDIT_MODAL);
        }
        else
          this.saved.emit('someData');
      });
    } 
  }
  editPersona(){
    this.isReadOnly = false;
  }

  savePersona(){
    let t = this.service.studentProfileViewInfo; 
    this.service.saveStudentProfileListInfo().subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Save Successfull");
        this.isReadOnly = true;
      }      
    });
  }
  
  getType(){
    //this.ProfileTypeInfo.filter(i => i.Code == 'Personality');
  }
  getMyInfo() {
    this.service.getMyInfo().subscribe((res: any) => {
      this.service.formData = res;
      console.log('getMyInfo');
      console.log(res);

      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.service.formData.UserInfo.CroppedProfileFilepath = (res.UserInfo.CroppedProfileFilepath).replace(re, "/");
      }
    });
  }
  activeTabChange(event) {
    
    this.activeTab = event.tab.textLabel;

    if(this.activeTab =="Profile"){
     
    }else if(this.activeTab =="Persona"){ 
          
    }else if(this.activeTab =="Empathy-Mapping"){ 
          
    }

  }

  getStudentProfileInfoList(id: number = 0) {
    this.service.getStudentProfileInfoList(id).subscribe((res: any) => {

      res.ProfileTypeInfo.forEach((element, index) => {
        element.Default = [{
          "StudentProfileDataId": 0,
          "Name": "",
          "Rating": 0,
          "ProfileTypeId": 0,
          "IsActive": false,
          "CreatedOn": null
      },{
        "StudentProfileDataId": 0,
        "Name": "",
        "Rating": 0,
        "ProfileTypeId": 0,
        "IsActive": false,
        "CreatedOn": null
    },{
      "StudentProfileDataId": 0,
      "Name": "",
      "Rating": 0,
      "ProfileTypeId": 0,
      "IsActive": false,
      "CreatedOn": null
  },{
    "StudentProfileDataId": 0,
    "Name": "",
    "Rating": 0,
    "ProfileTypeId": 0,
    "IsActive": false,
    "CreatedOn": null
}];
          element.StudentProfileList = res.StudentProfileListInfo.filter(item => {
              if(item.ProfileTypeId == element.Id){
                  element.Default.pop();
                  return element
              }
          });
       
       console.log(element)
        });
        this.profileTypeInfo.map(i => {
          i.StudentProfileList.map( j => {
            if(j.Code === 'Personality'){

            }
          })
        });
      this.service.studentProfileViewInfo = res;
      this.profileTypeInfo = res.ProfileTypeInfo;
      this.studentProfileListInfo = res.StudentProfileListInfo;
    })
  }

  EditprofileProfile(typeId: number, typeName: string) {
  
    let temp = this.studentProfileListInfo.filter(item => item.ProfileTypeId == typeId );
    if(temp.length >=4){
      this.notificationServ.ShowError("You have exceed maximam limit")
    }
    else{
      const initialState = {
        list: [
          {
            id: typeId,
            name: typeName,
            action:'Add'
          }
        ]
      };
      this.bsModalRef = this.modalService.show(EditProfileComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-md mb-0', ignoreBackdropClick: true });
      
    }
    // this.getStudentTravelInfo();
  }
   
  Editprofile(StudentProfileDataId:number,typeName: string, studentProfileObj) {
    const initialState = {
      list: [
        { 
          SPDId: StudentProfileDataId, 
          data:studentProfileObj,
          name:typeName,
          action:'Edit'
        }
      ]

    };
    this.bsModalRef = this.modalService.show(EditProfileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  deleteProfile(StudentProfileDataId:number,studentProfileObj) {
    this.service.studentProfileInfoView.StudentProfileInfo = studentProfileObj;
    this.service.studentProfileInfoView.StudentProfileInfo.IsActive = false;
    this.service.saveStudentProfileInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Delete Successfull");
        this.commonService.CallOnRefreshGrid();
      }
      
    });
  }
  getStudentEmpathyInfoList(id:number){
    this.service.getStudentEmpathyInfoList(id).subscribe((res:any)=>{
     this.studentEmpathyInfo=res;
     this.service.StudentEmpathyViewInfo = res;
      console.log(res);
     this.RowData = this.service.StudentEmpathyViewInfo.StudentEmpathyInfo; 
    });
  }
  onGridReady(params){
    this.gridApi = params.api;
  }

  onEmpathySelectionChanged(event){
    var selectedRows = this.gridApi.getSelectedRows();
    this.SelectedEmpathyInfo = selectedRows[0];
  }

  AddEmpathy(){
    const initialState = {
      list: [
        {
          id: "",
          name: "",
          action:'Add'
        }
      ]
    };
    this.bsModalRef = this.modalService.show(EditEmpathyComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-md mb-0', ignoreBackdropClick: true });
  }
}
