<div id="showconsultationsidebar" class="video-container">
  <!-- Chat Box -->
  <app-webrtc-chat-component />


  <!-- Patient Video Section -->


  <div class="video-camera-outer">
    <!-- <div class="local-camera"> -->
    <div id="local" class="teacher">
      <app-white-board *ngIf="showWhiteBoard" closeWhiteboard="enableWhiteBoard"></app-white-board>
    </div>
    <!-- </div> -->
    <!-- <div id="widget-container"></div> -->

    <div id="remote" class="student">

      <button class="btn btn-primary" type="button" disabled id="remoteloaderbutton">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Please wait...
      </button>
    </div>

    <div class="student-hand-rise">
      <p>MSP Hand Rise</p>
      <span class="accept-btn" id="acceptBtn">Accept</span>
      <span class="deny-btn" id="denyBtn">Deny</span>
    </div>

  </div>
  <div id="room-urls">
  </div>
  <!-- Video Controls -->
  <ul *ngIf="showList" class="student-list">
    <li *ngFor="let student of StudentList">
      {{student.Name}}
    </li>
  </ul>

  <div class="videos_controllers">
    <div style="display: none;" id="displayButtons">

      <div class="mute-video-btn-outer">
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnMute" title="Mute"><span
            class="bi bi-mic-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnUnMute" title="Unmute"><span
            class="bi bi-mic-mute-fill"></span>
        </button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="off-cam"
          title="Camera Off"><span class="bi bi-camera-video-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="start-cam"
          title="Camera On"><span class="bi bi-camera-video-off-fill"></span></button>
      </div>
      <!-- <iframe src="/recordScreen"></iframe> -->
      <div class="video-option-btn-outer">
        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Breakout Room" class="control-btn"
          id="splitRoom" (click)="makeSplitRoom()"><span class="bi bi-grid"></span>
        </button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Share Screen" class="control-btn"
          id="screenShare"><span class="bi bi-pip"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" title="Record"
          id="captureBtn"><span class="bi bi-record-circle"></span>
        </button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" title="Stop Record" id="stopBtn"
          style="display: none;"><span class="bi bi-stop-circle"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" title="Preview Record"
          id="previewBtn" style="display: none;" (click)="previewRecording()"><span class="bi bi-play-circle"></span>
        </button>
        <a id="download" data-bs-toggle="tooltip" data-bs-placement="top" title="Download Recording" href="#"
          style="display: none;" class="control-btn"><span class="bi bi-arrow-down-circle"></span></a>

        <!-- Show Student Icon -->
        <button data-bs-toggle="tooltip" data-bs-placement="top" id="studentshow" class="control-btn"
          title="Show Student List" (click)="switchShowList()"><span class="bi bi-people-fill"></span></button>

        <!-- Chat Icon -->
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="showchat" title="Show Chat"
          (click)="showchatbox()"><span class="bi bi-chat-square-dots"></span></button>
        <!-- <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" *ngIf="hidechatboxbutton"
          id="hidechat" title="Hide Chat" (click)="hidechatbox()"><span class="bi bi-chat-square-dots"></span></button> -->

        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" title="White board"
          (click)="enableWhiteBoard()"><span class="fa fa-chalkboard"></span></button>

      </div>
      <!-- <input type="file" (change)="onFileSelected($event)" />
      <button (click)="onUpload()">Upload</button> -->


      <div class="end-call-btn-outer">
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="btn-cut" id="btnLeave" (click)="endCall()"
          title="Exit Class">Exit Class</button>
      </div>
    </div>
  </div>
</div>