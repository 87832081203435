<div class="modal-dialog modal-md m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{list[0].action}} {{list[0].name}} {{list[0].action=="Edit"?" / "+list[0].data.Name:""}} </h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Name*</label>
                                <select *ngIf="ProfileName === 'Personality'" class="form-control form-control-sm appearance-auto" name="Name"
                                #Name="ngModel" [(ngModel)]="service.studentProfileInfoView.StudentProfileInfo.Name" (change)="selectRating($event)"
                                required>
                                <option [ngValue]="'Extrovert/Introvert'">Extrovert/Introvert</option>
                                <option [ngValue]="'Sensing/Institution'">Sensing/Institution</option>
                                <option [ngValue]="'Thinking/Feeling'">Thinking/Feeling</option>
                                <option [ngValue]="'Judging/Perceiving'">Judging/Perceiving</option>
                            </select>
                                <input *ngIf="ProfileName !== 'Personality'" class="form-control form-control-sm" type="text"
                                    placeholder="Name" name="Name"  
                                    #Name="ngModel"                                    
                                    [(ngModel)]="service.studentProfileInfoView.StudentProfileInfo.Name"
                                    required [class.invalid]="Name.invalid && Name.touched">
                            </div>
                            <div class="slider-container" *ngIf="ProfileName === 'Personality'">
                                <span class="slider-label">{{this.min}}</span>
                                <mat-slider name="Rating"
                                  class="slider"
                                  [min]="0"
                                  [max]="10"
                                  [step]="1"
                                  thumbLabel="always"
                                  #Rating="ngModel"
                                  [(ngModel)]="service.studentProfileInfoView.StudentProfileInfo.Rating">
                                </mat-slider>
                                <span class="slider-label">{{this.max}}</span>
                              </div>
                        </div>
                    </div>
                </div>
               

            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" *ngIf="list[0].action=='Add'" (click)="saveProfileInfo()" type="button">Save</button>
                <button type="button" class="btn btn-success" *ngIf="list[0].action=='Edit'" (click)="updateProfileInfo()" type="button">Update</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>