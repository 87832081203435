<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <!-- <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Upload Book</h3>
        </div> -->
        <div class="d-flex justify-content-end p-2">
            <i class="fa fa-times cursor-pointer" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
            aria-label="Close"></i>
          </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div class="row m-0">
                    <div class="col-4 ">
                        <img class="border" src="../../assets/images/2X/bg-home.png" alt="">
                    </div>
                    <div class="col-8">
                        <div class="row m-0 book-description">
                            <div class="col-12">
                                <h1 class="m-0">Taj Mahal Agra</h1>
                                <h3>Visited On: 12th January, 2023</h3>
                            </div>
                         <div class="col-12 d-flex justify-content-between">
                            <h3 class="m-0">Visited On: </h3>
                            <h3 class="m-0">Visited On:</h3>
                         </div>


                            <div class="col-12">
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                       <div class="row">
                                        <div class="col-3 pr-0">
                                            <img src="../../assets/images/benefits_page/benefits_banner.png" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="col-3 pr-0">
                                            <img src="../../assets/images/benefits_page/Parent.jpg" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="col-3 pr-0">
                                            <img src="../../assets/images/background/bg-home.png" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="col-3 pr-0">
                                            <img src="../../assets/images/common/homework.jpg" class="d-block w-100" alt="...">
                                        </div>
                                       </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/benefits_page/benefits_banner.png" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/common/homework.jpg" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/common/homework.jpg" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/benefits_page/benefits_banner.png" class="d-block w-100" alt="...">
                                            </div>
                                           </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/benefits_page/benefits_banner.png" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/common/homework.jpg" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/benefits_page/benefits_banner.png" class="d-block w-100" alt="...">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <img src="../../assets/images/common/homework.jpg" class="d-block w-100" alt="...">
                                            </div>
                                           </div>
                                      </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                      <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="sr-only">Next</span>
                                    </a>
                                  </div>
                            </div>

                            <div class="col-12">
                                <span>paragraph Heading</span>
                                <ul>
                                    <li>
                                       Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci voluptatum praesentium quam, ratione corrupti non nostrum soluta nihil, omnis et accusantium explicabo dicta cumque temporibus cupiditate harum. Aperiam, quia blanditiis.
                                    </li>
                                    <li>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci voluptatum praesentium quam, ratione corrupti non nostrum soluta nihil, omnis et accusantium explicabo dicta cumque temporibus cupiditate harum. Aperiam, quia blanditiis.
                                     </li>
                                     <li>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci voluptatum praesentium quam, ratione corrupti non nostrum soluta nihil, omnis et accusantium explicabo dicta cumque temporibus cupiditate harum. Aperiam, quia blanditiis.
                                     </li>
                                    
                                 
                                </ul>
                                <div class="d-flex cursor-pointer">
                                    <img src="../../assets/images/icons/svg/edit_file_green.svg" alt="">
                                    <span class="text-success">Edit Details</span>
                                 </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="modal-footer p-1">
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
                    aria-label="Close">Close</button>
            </div> -->
        </form>
    </div>
</div>