import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import CONFIG, { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { OwnFileService } from '../shared/ownFile.service';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-show-own-docs',
    templateUrl: './show-own-docs.component.html',
    styleUrls: ['./show-own-docs.component.scss'],
    standalone: true,
    imports: [NgIf, MatCheckboxModule, FormsModule]
})
export class ShowOwnDocsComponent implements OnInit {

  list:any[]=[];
  CUSTOMCONST:any=CUSTOMCONST; 
  CONFIG:any=CONFIG;
  extension:string;
  urlSafe: SafeResourceUrl;
  url:string;
  constructor(public service:OwnFileService,private notificationServ:NotificationService,private commonService:CommonService,
    public modalService:BsModalService, public bsModalRef: BsModalRef,public sanitizer: DomSanitizer) { 
    }

  ngOnInit(): void {
    this.getowndocumentlist();
  }
  getowndocumentlist(){
   
    this.service.getowndocument(this.list[0].id).subscribe((res : any) => {
        
      this.service.formData = res;

        let arr = res.FileName.split(".");
        this.extension = arr.pop();
      if((this.extension === 'docs' || this.extension === 'doc')){
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src='+res.FilePath);
      }else{
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(res.FilePath);
      }
       
       if(res.FilePath){
        this.url = res.FilePath;
       }
       
       
    });
  }
  generateshareddocumentid(){
    this.service.generateshareddocumentid().subscribe((res : any) => {
      this.service.formData = res;
       
    });
  }
}
