<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{'AddFeedback' | translate}}</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Feedback' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text"
                                    placeholder="{{'Feedback' | translate}}" name="Feedback" #Feedback="ngModel"
                                    [(ngModel)]="feedbackServ.formData.Feedback"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addFeedbackInfo(form)" class="btn btn-success"
                    type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>