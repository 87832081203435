import { formatDate, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { CommonService } from 'src/app/shared/common.service';
import { LeaveService } from 'src/app/shared/leave.service';
import { UserService } from 'src/app/shared/user.service';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-student',
    templateUrl: './student.component.html',
    styleUrls: ['./student.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule]
})
export class StudentComponent implements OnInit {

  frameworkComponents;
  leaveRowData:any;
  leaveRowSelection;
  leaveDefaultColDef;
  leaveColumnDefs;
  leaveGridOptions:any;
  gridApi;
  gridColumnApi;


  config:any = {
    params: {
      roleCode: 'Student',
      startDate:'',
      endDate:'',

    }
  };
  yearList:any[]=[];
  selectedYear:string=''
  selectedChileId:number=0;
  childrenList:any=[];
  userData:any=[];
  constructor(public userService:UserService, public commonService:CommonService,
     private leaveServ:LeaveService) {
      this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.greadReady();
      this.commonService.RefreshGrid$.subscribe(
        item => {
          this.getalluserleaveinfo();
         }
      );
      }

  ngOnInit(): void {
    this.getStartAndEndDate();
    // this.getMyInfo();

  }
  greadReady() {

    this.leaveColumnDefs = [
      // {
      //   headerName: "LeaveId",
      //   field: 'LeaveId', cellRendererFramework: CustommenuComponent,
      //   cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.editLeave, that: this }, { Name: "Delete", "Click": this.deleteLeave, that: this }] },

      //   filter: false, width: 60, maxWidth: 60,
      //   headerComponent: 'customHeader',
      //   headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      // },
      { field: 'UserName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'LeaveType', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'Reason', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { headerName: "StartDate", field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120 },
      { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'LeaveStatus', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'IsFullDayLeave', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.leaveDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  // getMyInfo(){
  //   this.userService.getMyInfo().subscribe((res: any) => {
  //     this.config.params.roleCode  = 'Teacher';
  //   this.getalluserleaveinfo();
      
  //   });
  // }
  
  getStartAndEndDate(){
    this.commonService.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config.params.startDate =res[0].Code;
      this.config.params.endDate= '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1);
     
    });
  }
  getalluserleaveinfo(){
    this.leaveServ.getalluserleaveinfo(this.config).subscribe((res:any)=>{
      const rowData =  res.LeaveListInfo;
      this.gridApi.setRowData(rowData);

    })
  }
  serchData(){
    this.getalluserleaveinfo();
  }
  changeYear(){
    this.config.params.startDate =this.selectedYear;
      this.config.params.endDate= '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1);
    
  }
  // getuserleaveinfo(){
  //   this.leaveServ.getuserleaveinfo(this.config).subscribe((res:any)=>{
  //     this.leaveRowData = res.LeaveListInfo;
  //     console.log(res);
      
  //   })
  // }

  onLeaveReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getalluserleaveinfo();
    }, 300);


  }

  onLeaveReload(data) {
    this.leaveRowData = data;
  }
  deleteLeave(that) {

  }
  editLeave(that) {
    console.log(that.LeaveId);
    alert(that.LeaveId);
  }
}
