<div class="container-fluid bg-light pb-2">
    <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">

            <!-- Profile widget -->
            <div class="bg-white shadow overflow-hidden">
                <div class="px-0 pt-3  bg-dark position-relative bg-s-cover min-h-300px"
                    [style.background-image]="'url(' + (service.formData.UserInfo.CroppedProfileFilepath?service.formData.UserInfo.CroppedProfileFilepath:'') + ')'">
                    <div class="media profile-header  sm-flex-wrap px-3">
                        <div class="profile mr-3 sm-m-auto text-center sm-w-100">
                            <!-- [src]="service.formData.UserInfo.CroppedFilepath" -->
                            <img [src]="service.formData.UserInfo.CroppedFilepath?service.formData.UserInfo.CroppedFilepath:'../../../assets/images/user/default.png'"
                                width="130" class="rounded-circle border border-w-5 border-light mt-2">
                            <div>
                                <!-- [ngClass]="{'pe-none':!service.formData.UserInfo.IsEditAllowed}" -->
                                <a (click)="editProfile(service.formData.UserInfo.UserId)"
                                    class="btn btn-link btn-sm my-2 rounded-0">Edit profile</a>
                                <a (click)="openHelpTooltip('EditProfile')" class="position-relative"></a>
                            </div>
                        </div>
                        <div class="media-body text-white xs-pb-20px d-none d-sm-block">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mt-0 mb-0">{{service.formData.UserInfo.FirstName}}
                                        {{service.formData.UserInfo.MiddleName}}
                                        {{service.formData.UserInfo.Surname}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-5">
                                    <h4 class="mt-0 mb-0">{{SchoolName}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Class}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Batch}}</h4>

                                </div>
                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{service.formData.UserInfo.About}}</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div class="px-0 pt-3 d-sm-none">
                    <div class="media profile-header sm-flex-wrap px-3">

                        <div class="media-body xs-pb-20px">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mt-0 mb-0">{{service.formData.UserInfo.FirstName}}
                                        {{service.formData.UserInfo.MiddleName}}
                                        {{service.formData.UserInfo.Surname}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-5">
                                    <h4 class="mt-0 mb-0">{{SchoolName}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Class}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Batch}}</h4>

                                </div>
                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{service.formData.UserInfo.About}}</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <!-- End profile widget -->

        </div>
        <div class="col-xl-12 col-md-12 col-sm-12 p-0 pt-4">
            <div class="row">
                <!-- <p>{{ profileTypeInfo | json }}</p> -->
                <div class="col-6" *ngFor="let profileType of profileTypeInfo" >
                    <div class="card" >
                        <!-- <div class="card-header">
                          {{profileType.Name}}
                        </div> -->
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">Cras justo odio</li>
                          <li class="list-group-item">Dapibus ac facilisis in</li>
                          <li class="list-group-item">Vestibulum at eros</li>
                        </ul>
                      </div>
                </div>
            </div>
            <!--<table boarder="1" >

            </table> -->
            <table style="width:100%;">
                <tr style="border:solid 1px; width:100%;" >
                    <th>

                        {{service.studentProfileViewInfo.ProfileTypeInfo.Name}}
                        <i class="fa fa-plus" aria-hidden="true" (click)="EditprofileProfile(0)"></i>
                    </th>
                    <th>Society Engagement <i class="fa fa-plus" aria-hidden="true"></i></th>
                </tr>
                <tr>
                    <td style="vertical-align: top;">
                        <table style="width: 100%;">

                            <tr style="display: grid;">
                                <td><i class="fa fa-bars"></i>fghjk</td>
                                <td>
                                    <!-- <button mat-icon-button [matMenuTriggerFor]="beforeMenu" aria-label="Example icon-button with a menu" class="menuList">
                                        <mat-icon>more_vert</mat-icon>
                                      </button>
                                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                                        <button mat-menu-item>
                                          <span>Edit</span>
                                        </button>
                                        <button mat-menu-item>
                                          <span>Delete</span>
                                        </button>
                                      </mat-menu> -->
                                    fghjk
                                </td>
                                <td>edfghjk</td>
                                <td>fghjk</td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;">
                        <table style="width: 50%;">

                            <tr style="display: grid; ">
                                <td>dfrgthjk</td>
                                <td>dfghjkl</td>

                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <th>Strength <i class="fa fa-plus" aria-hidden="true"></i></th>
                    <th>Weaknesses <i class="fa fa-plus" aria-hidden="true"></i></th>
                </tr>
                <tr>
                    <td style="vertical-align: top;">
                        <table style="width: 100%;">

                            <tr style="display: grid;">
                                <td>fghjk</td>
                                <td>fghjk</td>
                                <td>edfghjk</td>
                                <td>fghjk</td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;">
                        <table style="width: 50%;">

                            <tr style="display: grid; ">
                                <td>dfrgthjk</td>
                                <td>dfghjkl</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th>Short Term Goals <i class="fa fa-plus" aria-hidden="true"></i></th>
                    <th>Long Term Goals<i class="fa fa-plus" aria-hidden="true"></i></th>
                </tr>
                <tr>
                    <td style="vertical-align: top;">
                        <table style="width: 100%;">

                            <tr style="display: grid;">
                                <td>fghjk</td>
                                <td>fghjk</td>
                                <td>edfghjk</td>
                                <td>fghjk</td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;">
                        <table style="width: 50%;">

                            <tr style="display: grid; ">
                                <td>dfrgthjk</td>
                                <td>dfghjkl</td>

                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <th>Frustrations <i class="fa fa-plus" aria-hidden="true"></i></th>
                    <th>Influences <i class="fa fa-plus" aria-hidden="true"></i></th>
                </tr>
                <tr>
                    <td style="vertical-align: top;">
                        <table style="width: 100%;">

                            <tr style="display: grid;">
                                <td>fghjk</td>
                                <td>fghjk</td>
                                <td>edfghjk</td>
                                <td>fghjk</td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;">
                        <table style="width: 50%;">

                            <tr style="display: grid; ">
                                <td>dfrgthjk</td>
                                <td>dfghjkl</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th>Hobbies <i class="fa fa-plus" aria-hidden="true"></i></th>
                    <th>Personality <i class="fa fa-plus" aria-hidden="true"></i></th>
                </tr>
                <tr>
                    <td style="vertical-align: top;">
                        <table style="width: 100%;">

                            <tr style="display: grid;">
                                <td>fghjk</td>
                                <td>fghjk</td>
                                <td>edfghjk</td>
                                <td>fghjk</td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;">
                        <table style="width: 50%;">

                            <tr style="display: grid; ">
                                <td>dfrgthjk</td>
                                <td>dfghjkl</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
