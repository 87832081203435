import { Component, ElementRef, ViewChild } from '@angular/core';
import { AnswerListComponentComponent } from './../answer-list-component/answer-list-component.component';
import { AnswerModalComponentComponent } from './../answer-modal-component/answer-modal-component.component';
import { CommonModule, Location } from '@angular/common';
import { EventService } from 'src/app/shared/event.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-question-details-component',
  standalone: true,
  imports: [CommonModule,AnswerListComponentComponent,AnswerModalComponentComponent],
  templateUrl: './question-details-component.component.html',
  styleUrls: ['./question-details-component.component.scss']
})
export class QuestionDetailsComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  question: any;
  public bsModalRef: BsModalRef;
  isShowAnswer: boolean=false;

  constructor(
    private location: Location,
    public eventService: EventService,
    private modalService: BsModalService
 ) {}
  ngOnInit(): void {
    this.question = {
      subject: "test subject",
      video: "https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
      userName: "test",
      questionId: "1"
    };
  }
  getAnswers(): void{
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }
  addAnswer() {
    this.isShowAnswer=!this.isShowAnswer
  }
    
}
