<div class="dashboardInnerPage flex-wrap inner-page-outer">
  <div class="title w-100">
    <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
      class="bi bi-arrow-left"></span>
    <h3>Question View Details</h3>
    <a class="add_button" (click)="addAnswer()">{{ !isShowAnswer ? 'Add Answer' : 'Cancel' }}</a>
  </div>
  <app-answer-modal-component *ngIf="isShowAnswer" [questionId]="question.questionId"
    (answerReload)="getAnswers()"></app-answer-modal-component>

  <div class="question-col">
    <div class="question-header">
      <div class="question-title">
        <h3>{{question.subject}}</h3>
        <p><span class="bi bi-person"></span> {{question.userName}}</p>
      </div>
    </div>
    <div class="ques-video">
      <video #videoElement [src]="question.video" controls></video>
    </div>
  </div>

  <app-answer-list-component class="all_questionOuter" [questionId]="question.questionId"></app-answer-list-component>

</div>