<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{'Recording Preview' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <div class="breackout_body">
        <div class="video_body">
            <video [src]="videoUrl" controls id></video>
        </div>
    </div>
    <!-- <div class="modal-footer p-1">
        <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Cancel">Close</button>
    </div> -->
</div>