import { Component, OnInit } from '@angular/core';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
    standalone: true,
    imports: [CarouselModule, LottieModule, MatCardModule]
})
export class FeaturesComponent implements OnInit {
  course: string;
  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  animationItem:AnimationItem;
  banner: AnimationOptions = {
    path: '../../assets/images/Json/home/assessments_and_assignments_interactive_classess.json',
  };
  VIExperience:AnimationOptions = {
    path: '../../assets/images/Json/features/virtual_interactive_experience.json',
  }
  CMForAAssignments:AnimationOptions = {
    path: '../../assets/images/Json/features/content_management_for_assessments_and_assignments.json',
  }
  ACGeneration:AnimationOptions = {
    path: '../../assets/images/Json/features/assessment_content_generation.json',
  }
  FAProcess:AnimationOptions = {
    path: '../../assets/images/Json/features/formative_assessments_process.json',
  }
  SPManagement:AnimationOptions = {
    path: '../../assets/images/Json/features/student_portfolio_management.json',
  }
  VDManagement:AnimationOptions = {
    path: '../../assets/images/Json/features/vocational_development_management.json',
  }
  
  HPBGraphics:AnimationOptions = {
    path: '../../assets/images/Json/home/home_page_banner_raphics.json',
  }

  ngOnInit(): void {
    this.titleService.setTitle('Hybrid Learning Platform with Dynamic Assignments, Dynamic Assessments');
    this.metaService.addTags([
      {name: 'keywords', content: 'INTERACTIVE CLASSES, ASSIGNMENTS, ASSESSMENTS, STUDENT PORTFOLIO, SCHOOL DEVELOPMENT, CALENDAR MANAGEMENT, COMMUNICATION APPLICATIONS, '},
      {name: 'description', content: 'A Dynamic Learning Environment That Exceeds Classroom Walls. It ensures that learning continues uninterruptedly outside the school boundaries.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
    if(typeof window != 'undefined')
    window.scroll(0,0);
  }
  featureBannnerOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoplay:true,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  
  mobileAppOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['<img mat-card-image src="../../assets/images/2X/Group-296.png" alt="value_education" class="m-0 w-100">', '<img mat-card-image src="../../assets/images/2X/Group-295.png" alt="value_education" class="m-0 w-100">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  HLCPOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['<img mat-card-image src="../../assets/images/2X/Group-293.png" alt="value_education" class="m-0 w-100">', '<img mat-card-image src="../../assets/images/2X/Group-294.png" alt="value_education" class="m-0 w-100">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  featureBannerAnimationCreated(animation: AnimationItem): void {
  }


}
