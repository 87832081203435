import { formatDate, NgFor, NgIf } from '@angular/common';
import { Component, OnInit,ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { HelpComponent } from 'src/app/common/help.component';
import { CommonService } from 'src/app/shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { FeedbackService } from 'src/app/shared/feedback.service';
import { UserService } from 'src/app/shared/user.service';
import { ApexNonAxisChartSeries, ApexPlotOptions, ApexChart, ApexFill, ChartComponent, ApexTitleSubtitle, ApexAxisChartSeries, ApexDataLabels, ApexStroke, ApexXAxis, ApexYAxis, ApexLegend, NgApexchartsModule } from "ng-apexcharts";
import { series } from 'src/assets/Data/chartData';
import { ParentShareDocService } from 'src/app/shared/Parent-Share-Doc.service';
import { MatIconModule } from '@angular/material/icon';
import { GoogleChartsModule } from 'angular-google-charts';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  series1: ApexAxisChartSeries;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
};
@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgFor, FormsModule, NgApexchartsModule, BsDatepickerModule, AgGridModule, NgIf, GoogleChartsModule, MatIconModule, CarouselModule]
})
export class ResultComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptionsHumanityLife: Partial<ChartOptions>;
  public chartOptionsLifeSkills: Partial<ChartOptions>;
  public chartOptionsAcademics: Partial<ChartOptions>;
  public chartOptionsLearnOutOfBox: Partial<ChartOptions>;
  public academicAsignmentChartOptions: Partial<ChartOptions>;
  
  ILClassGraph: any = [];   // -- for interativeclass graph 
  ILClassGraphType: any;
  ILClassGraphTitle: any;
  ILClassGraphCol;
  ILClassGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  graphWidth = '100%';
  graphHeight = '100%';

  assessmentsGraph: any = []; // -- for assessments graph
  assessmentsGraphType: any;
  assessmentsGraphTitle: any;
  assessmentsGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  assessmentsGraphCol: any;

  assignmentsGraph: any = []; // -- for assignments graph
  assignmentsGraphType: any;
  assignmentsGraphTitle: any;
  assignmentsGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  assignmentsGraphCol: any;

  
  stuAnalysisGraph: any = []; // -- for Student Analysis graph
  stuAnalysisGraphType: any;
  stuAnalysisGraphTitle: any;
  stuAnalysisGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  stuAnalysisGraphCol: any;

  studentsByGradeCharGraph:any[]=[];
  studentsByGradeCharGraphType: any;
  studentsByGradeCharGraphTitle: any;
  studentsByGradeCharGraphOption = { colors: ['#00b02c', '#ff8000'],  pieHole:0.4 };
  studentsByGradeCharGraphCol: any;



  graphdata:any[]=[];
  stuanalysisgraph:any;

  selectedChileId:number=0;
  childrenList:any=[];
  activeTab:string = 'Academics';
  bsModalRef: BsModalRef;
  
  feedbackList: any[];
  userData:any=[];
  urlSafe: SafeResourceUrl;


  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  columnDefsPSD;
  defaultColDef;
  rowSelection;
  logListData: any[];
  startDate:any;
  endDate:any;
  datepickerConfig : BsDatepickerConfig;
  bsValue = new Date();
  bsRangeValue: Date[];
  PSDListData: any[];
  // maxDate = new Date();
  // minDate = new Date();

  config = {
    params: {
      startDate: "",
      endDate: "",
      userId:0
    }
  };
  chartConfig = {
    params: {
      chartName  : "",
      category : "",
      viewName: "",
      schoolId : 0,
      userId : 0
    }
  };
  chartConfig1 = {
    params: {
      chartName  : "",
      viewName: "",
      schoolId : 0,
      userId : 0
    }
  };
  viewName:string = "Year"

  constructor(public userService:UserService, public commonService:CommonService, public modalService: BsModalService,public PSDService :ParentShareDocService,
    public feedbacServ:FeedbackService,public sanitizer: DomSanitizer,public router:Router) { 
      
      this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;

      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    // this.minDate.setDate(this.minDate.getDate() - 0);
    // this.maxDate.setDate(this.maxDate.getDate() + 30);
    // this.bsRangeValue = [this.bsValue, this.maxDate];
    
    var date = new Date();
    this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.endDate = new Date();
    this.config.params.userId = this.commonService.selectedChileId?this.commonService.selectedChileId:0;
    

    this.columnDefs = [
      { headerName:'Event', field: 'EventName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150},
      { headerName:'Date', field: 'EventDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
      { headerName:'IP', field: 'IPAdress', sortable: false, filter: 'agTextColumnFilter'  ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },      
   ];
   this.columnDefsPSD = [
   
  { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
  { field: 'PurposeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
  { field: 'SchoolCategory', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200 ,maxWidth: 300 },
  { field: 'TypeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 200 },
  { field: 'StateId', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 300 ,maxWidth: 300 },

  
 ];
   this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
    }

  ngOnInit(): void {
    this.getMyInfo();
    this.getStudentFeedback();
    this.getParentShareListDocViewForParent();
    
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getuserloginfo();
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      if(this.commonService.selectedChileId<=0)
      {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      this.childrenList =  res.ParentInfo.ChildrenList;
      // this.getAllStudentsQuestionsById();


      this.interactiveLiveClassChartInfo();
      this.assignmentsChartInfo();
      this.assessmentsChartInfo()
      this.getStudentAnalyticsChartInfo(this.activeTab);
      this.getuser();
      
    });
  }
  changeChild(){
      this.getStudentAnalyticsChartInfo(this.activeTab);
      this.commonService.selectedChileId = this.selectedChileId;
      // this.getstudentfeeinfo(this.selectedChileId);
  }
  getStudentAnalyticsChartInfo(activeTab){
    this.userService.getStudentAnalyticsChartInfo(this.selectedChileId,activeTab,0).subscribe((res : any) => {
   
    this.stuAnalysisGraph = res;
    this.stuanalysisgraph = res;
      res.forEach(element => {
        var arrObj = [['Completed', element.ValueInt],['Not Completed',element.ValueLong]];
        this.graphdata.push(arrObj);
      });
      this.stuAnalysisGraphType = "PieChart";
      
    });
  }
  interactiveLiveClassChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "InteractiveLiveClass"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.ILClassGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.ILClassGraph.push(arrObj);
      });
      this.ILClassGraphTitle = "InteractiveLiveClass";
      this.ILClassGraphType = "PieChart";

    });
  }
  assessmentsChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "Assessments"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.assessmentsGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.assessmentsGraph.push(arrObj);
      });
      this.assessmentsGraphTitle = "Assessments";
      this.assessmentsGraphType = "PieChart";
      
    });
  }
  assignmentsChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "Assignments"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.assignmentsGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.assignmentsGraph.push(arrObj);
      });
      this.assignmentsGraphTitle = "Assignments";
      this.assignmentsGraphType = "PieChart";

    });
  }
  openHelpTooltip(tooltipName:any){
    const initialState = {
      list: [
        { name: tooltipName }
      ]

    };
    this.bsModalRef = this.modalService.show(HelpComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  mobileAppOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: false,
    margin: 10,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    navText: ['<img mat-card-image src="../../assets/images/2X/Group-296.png" alt="value_education" class="m-0 w-100">', '<img mat-card-image src="../../assets/images/2X/Group-295.png" alt="value_education" class="m-0 w-100">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  }
  getStudentFeedback() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
      }
    };
    this.feedbacServ.getStudentFeedbacks(config).subscribe((res: any) => {
      this.feedbackList = res;
    });
  }
  selectChild(id:any){
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    this.config.params.userId = id;
    if(id>0){
      this.getStudentAnalyticsChartInfo(this.activeTab);
      this.commonService.selectedChileId = this.selectedChileId;
    this.getuser();
    this.getuserloginfo();
    }else{
      this.router.navigateByUrl('/add-child');
    }
   
  }
  getuser(){
    var userid = this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData =  res; 
      this.chartConfig.params.userId = res.UserInfo.UserId;
      this.chartConfig.params.schoolId = res.UserInfo.SchoolId;
      this.chartConfig1.params.userId = res.UserInfo.UserId;
      this.chartConfig1.params.schoolId = res.UserInfo.SchoolId;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath);
      
      this.semiCircleChaertHumanityLife();
      this.semiCircleChaertLifeSkills();
      this.semiCircleChaertAcademics();
      this.semiCircleChaertLearnOutOfBox();
      this. getStudentsByGradeChart();
      this.getAcademicAsignmentChart();
    });
  }

  getuserloginfo(){
    
    if(this.startDate !== undefined && this.endDate  !== undefined){
      this.config.params.startDate = formatDate(this.startDate,'dd-MM-yyyy','en');
      this.config.params.endDate = formatDate(this.endDate,'dd-MM-yyyy','en');
    }
    this.config.params.userId = this.commonService.selectedChileId?this.commonService.selectedChileId:0;
    this.userService.getuserloginfo(this.config).subscribe((res : any) => {
      this.logListData =  res;
    });
  }

  
  onSearch(form){
    this.getuserloginfo();
  }
  // semiCircleChaertHumanityLife(category){

  // }
  
  semiCircleChaertHumanityLife(){
    this.chartConfig.params.viewName = this.viewName;
    this.chartConfig.params.category = "HumanityLife";
    this.chartConfig.params.chartName = "CategorySpecific";
    this.userService.getstudentnewchartinfo(this.chartConfig).subscribe((res: any) => {
    //  this.getChart(this.chartOptionsHumanityLife, res[0].ValueDecimal);
    var seriesValue:any=[];
    var cartLabels:any=[];
    res.forEach(element => {
      // var arrObj = [element.ValueInt];
      seriesValue.push(element.ValueInt);
      cartLabels.push(element.Key)
    });
    this.chartOptionsHumanityLife = {
      series: seriesValue,
      chart: {
        type: "radialBar",
        offsetY: 5,
        width:"100%"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "22px"
            }
          }
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value < 50) {
              return '#E84D00'
          } else if (value >= 50 && value < 80) {
              return '#FFCD17'
          } else {
              return '#00E667'
          }
        }],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: cartLabels,
      title: {
        text: "SEL",
        align: "center"
      },
      subtitle: {
        text: cartLabels,
        align: "center"
      },
      
    };
    });
    
  
  }
  semiCircleChaertLifeSkills(){
    this.chartConfig.params.viewName = this.viewName;
    this.chartConfig.params.category = "LifeSkills";
    this.chartConfig.params.chartName = "CategorySpecific";
    this.userService.getstudentnewchartinfo(this.chartConfig).subscribe((res: any) => {
    //  this.getChart(this.chartOptionsHumanityLife, res[0].ValueDecimal);
    var seriesValue:any=[];
    var cartLabels:any=[];
    res.forEach(element => {
      // var arrObj = [element.ValueInt];
      seriesValue.push(element.ValueInt);
      cartLabels.push(element.Key)
    });
    this.chartOptionsLifeSkills = {
      series: seriesValue,
      chart: {
        type: "radialBar",
        offsetY: 5,
        width:"100%"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "22px"
            }
          }
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value < 50) {
              return '#E84D00'
          } else if (value >= 50 && value < 80) {
              return '#FFCD17'
          } else {
              return '#00E667'
          }
        }],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: cartLabels,
      title: {
        text: "Life Skills",
        align: "center"
      },
      subtitle: {
        text: cartLabels,
        align: "center"
      },
      
    };
    });
  }
  semiCircleChaertAcademics(){
    this.chartConfig.params.viewName = this.viewName;
    this.chartConfig.params.category = "Academics";
    this.chartConfig.params.chartName = "CategorySpecific";
    this.userService.getstudentnewchartinfo(this.chartConfig).subscribe((res: any) => {
    //  this.getChart(this.chartOptionsHumanityLife, res[0].ValueDecimal);
    var seriesValue:any=[];
    var cartLabels:any=[];
    res.forEach(element => {
      // var arrObj = [element.ValueInt];
      seriesValue.push(element.ValueInt);
      cartLabels.push(element.Key)
    });
    this.chartOptionsAcademics = {
      series: seriesValue,
      chart: {
        type: "radialBar",
        offsetY: 5,
        width:"100%"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "22px"
            }
          }
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value < 50) {
              return '#E84D00'
          } else if (value >= 50 && value < 80) {
              return '#FFCD17'
          } else {
              return '#00E667'
          }
        }],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: cartLabels,
      title: {
        text: "Academics",
        align: "center"
      },
      subtitle: {
        text: cartLabels,
        align: "center"
      },
      
    };
    });
  }
  semiCircleChaertLearnOutOfBox(){
    this.chartConfig.params.viewName = this.viewName;
    this.chartConfig.params.category = "LearnOutOfBox";
    this.chartConfig.params.chartName = "CategorySpecific";
    this.userService.getstudentnewchartinfo(this.chartConfig).subscribe((res: any) => {
    //  this.getChart(this.chartOptionsHumanityLife, res[0].ValueDecimal);
    var seriesValue:any=[];
    var cartLabels:any=[];
    res.forEach(element => {
      // var arrObj = [element.ValueInt];
      seriesValue.push(element.ValueInt);
      cartLabels.push(element.Key)
    });
    this.chartOptionsLearnOutOfBox = {
      series: seriesValue,
      chart: {
        type: "radialBar",
        offsetY: 5,
        width:"100%"
      },
     
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false,
              offsetY: -2,
            },
            value: {
              offsetY: -2,
              fontSize: "22px"
            }
          },
          
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value < 50) {
              return '#E84D00'
          } else if (value >= 50 && value < 80) {
              return '#FFCD17'
          } else {
              return '#00E667'
          }
        }],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: cartLabels,
      title: {
        text: "Outside The Box",
        align: "center"
      },
      subtitle: {
        text: cartLabels,
        align: "center"
      },
      
    };
    });
  }
  getStudentsByGradeChart(){
    this.chartConfig1.params.viewName = this.viewName;
    this.chartConfig1.params.chartName = "AllCategory";
    this.studentsByGradeCharGraph = [];
    this.userService.getstdNewChartInfo(this.chartConfig1).subscribe((res: any) => {
      
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.studentsByGradeCharGraph.push(arrObj);
      });
      this.studentsByGradeCharGraphTitle = "Students By Grade";
      this.studentsByGradeCharGraphType = "PieChart";
    });
  }
  getAcademicAsignmentChart(){
    this.academicAsignmentChartOptions = {
      series1: [
        {
          name: "STOCK ABC",
          data: series.monthDataSeries1.prices
        }
      ],
      chart: {
        type: "area",
        // height: 350,
        offsetY: 5,
        width:"100%",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },

      title: {
        text: "Tracking Student Learning In Real Time",
        align: "left"
      },
      // subtitle: {
      //   text: "Price Movements",
      //   align: "left"
      // },
      labels: series.monthDataSeries1.dates,
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        opposite: true
      },
      legend: {
        horizontalAlign: "left"
      }
    };
  }
  getChart(chartOptions, value){
    chartOptions = {
      series: [value],
      // series: [res[0].ValueDecimal],
      chart: {
        type: "radialBar",
        offsetY: -20,
        // height:"150px"
        width:"100%"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: "22px"
            }
          }
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value < 50) {
              return '#E84D00'
          } else if (value >= 50 && value < 80) {
              return '#FFCD17'
          } else {
              return '#00E667'
          }
        }],
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ["Average Results"]
      
    };
  }
  changeView(){
    // this.viewName = viewType;
    this.getuser();
  }

  getParentShareListDocViewForParent(){
    this.PSDService.getParentShareListDocViewForParent().subscribe((res : any) => {
      this.PSDListData =  res.ParentShareDocListInfo;
      console.log(this.PSDListData);
      // this.ownDocsServ.formData = res;
    });
  }

}
