import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from './session-storage.service';
import {  SidebarViewInfo } from './sidebar.model';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  _sidebarMenuData  = new BehaviorSubject<any>([]);
  constructor(private http:HttpClient,
    private sessionSer:SessionStorageService) {
  }
  formData: SidebarViewInfo= new SidebarViewInfo();
  readonly baseUrl = CONFIG.BASE_URL+'api/user';

 

  getmymenu(){
 
    // return this.http.get(this.baseUrl+'/getStudentHobbyInfoList',config);
    return this.http.get(this.baseUrl+'/getmymenu');
  }
  CheckSidebar() {
    var isToken = typeof window != 'undefined' ? localStorage.getItem('schoolAuth'):'';
    if(isToken)
    {
      this.sessionSer.getUserData();
      this.getSidebarData();
    }
  }
  getSidebarData(){
    this.getmymenu().subscribe((res:any)=>{
      this.setSidebarMenuData(res);
    })
  }
  setSidebarMenuData(data:any){
    this._sidebarMenuData.next(data.MenuInfo);
  }

  getusermenuview(userid:number){
    var config = {
        params: {
          userId: userid
        }
      };
    return this.http.get(this.baseUrl+'/getusermenuview',config);
  }
  saveusermenuview(){
    return this.http.post(this.baseUrl+'/saveusermenuview',this.formData);
  }
}
