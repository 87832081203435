import { Component, OnInit,EventEmitter } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalRef } from "ngx-bootstrap/modal";
import { Branch } from '../shared/branch.model';
import { NotificationService } from '../shared/notification.service';
import { BranchService } from '../shared/branch.service';
import { CommonService } from '../shared/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-branch',
    templateUrl: './branch.component.html',
    styleUrls: ['./branch.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, TranslateModule]
})
export class BranchComponent implements OnInit {
  gridApi;
  gridColumnApi;
  list: any[] = [];
  schoollist : any = [];
  constructor(public service:BranchService, public bsModalRef: BsModalRef,
    public notificationSrv: NotificationService,private commonService:CommonService) { }
  ngOnInit(): void {
    this.service.getBranchInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData =  res.BranchInfo; 
      this.schoollist = res.SchoolList;
      this.service.formData.SchoolId = this.list[1].schoolId;
      
    });
  }

  onSave(form:NgForm){
    if(this.service.formData.BranchId > 0){
    this.service.updateBranch().subscribe((res:Branch)=> {
      this.notificationSrv.ShowInfo('Branch saved successfully');
      this.commonService.CallOnRefreshGrid();
      this.service.formData = res;
      this.bsModalRef.hide();
    });
  }
  else
  {
    this.service.addBranch().subscribe((res:Branch)=> {
      this.notificationSrv.ShowInfo('Branch saved successfully');
      this.commonService.CallOnRefreshGrid();
      this.service.formData = res;
    });
  }
  }
}
