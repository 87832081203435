<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="!attendanceSer.formdata.IsSaved">Take Attendence</h3>
            <h3 class="modal-title h3 m-1" *ngIf="attendanceSer.formdata.IsSaved">View Attendence Info</h3>
        </div>
        <div class="modal-body p-2 min-h-fitscreen1">
            <div >
                <div class="row form-row m-0">
                    <div class="col-xl-12 col-md-12 col-sm-12 p-0">
                        <div class="col-4">
                            <div class="form-group mb-0">
                                <!-- <select class="form-control form-control-sm appearance-auto m-0" name="ClassId" #ClassId="ngModel"
                                [(ngModel)]="classId" (change)="selectedClassChange()">
                                  <option [value]="0">Select Class</option>
                                  <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                                </select> -->
                                <p class="p-0 m-0">{{className}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row m-0 border-top mt-3" *ngFor="let student of studentList,let i=index">
                    <div class="col-xl-12 col-md-12 col-sm-12 p-0 py-2 border-bottom">
                        <div class="row">
                            <div class="col-7">
                                <div class="d-flex">
                                    <div class="pr-3">
                                        <!-- <iframe [src]="student.urlSafe" height="30" width="30"></iframe> -->
                                        <img [src]="student.ImageUrl!=''?student.ImageUrl:'../../../assets/images/user/default.png'" alt="" width="30px" height="30px">
                                    </div>
                                    <div class="pr-3">
                                        <p>{{student.Name}}</p>
                                    </div>

                                </div>

                            </div>
                            <div class="col-5">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [(ngModel)]="attendanceSer.formdata.StudentListInfo[i].AttendanceTypeId">
                                    <mat-radio-button class="example-radio-button pr-3" *ngFor="let attType of attendanceTypeList,let i=index" [value]="attType.Id" [disabled]="attendanceSer.formdata.IsSaved">
                                        {{attType.Name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="saveStudentAttendance()" class="btn btn-success"  type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
          </div>
    </div>
</div>

