import { formatDate, NgFor, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomControlsComponent } from '../custommenu/custom-controls/custom-controls.component';
import { CustomGrideCelComponent } from '../custommenu/custom-gride-cel/custom-gride-cel.component';
import { CustomHeader } from '../custommenu/custom-header.component';
import { TimeEditorComponent } from '../custommenu/time-editor/time-editor.component';
import { CommonService } from '../shared/common.service';
import { EventService } from '../shared/event.service';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AgGridModule } from 'ag-grid-angular';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-multievent',
    templateUrl: './multievent.component.html',
    styleUrls: ['./multievent.component.scss'],
    standalone: true,
    // imports: [BsDatepickerModule, FormsModule, TimepickerModule, PopoverModule, AgGridModule, NgFor, NgMultiSelectDropDownModule, MatCheckboxModule, DatePipe, TranslateModule]
    imports: [BsDatepickerModule, FormsModule, TimepickerModule, PopoverModule, AgGridModule, NgFor, MatCheckboxModule, DatePipe, TranslateModule]

})
export class MultieventComponent implements OnInit {
  gridApi;
  gridColumnApi;
  eventsRowData:any;
  eventRowSelection;
  eventDefaultColDef;
  eventColumnDefs;
  eventGridOptions:any;
  eventStartTime:any;
  eventEndTime:any;
  StartDate:any="";
  EndDate:any="";
  StartTime:any="";
  EndTime:any="";
  Description:string="";
  date:any;
  datepickerConfig : BsDatepickerConfig;
  minDate = new Date();
  frameworkComponents;
  list: any[] = [];
  classList:any[] = [];
  dropdownSettings:IDropdownSettings;
  multiEventListInfo:any[]=[];
  yearList:any=[];
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  constructor(public eventService:EventService,public commonService: CommonService,  public notificationServ:NotificationService,public bsModalRef: BsModalRef) {

    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.eventColumnDefs = [
      {
        headerName:"MultiEventId",
        field: 'IdActive',  cellRendererFramework: CustomControlsComponent,
        cellRendererParams : {enableCheckbox : true, controlList : [{Name: "checkbox",  Click: params => this.selectUnselect(params,this), that:this}] },
        filter:false, width:60,maxWidth: 60,
        headerComponent: 'customHeader',
        headerComponentParams : { button : "click", checkboxToggle : true, menuIcon : "far fa-check-square", enableSorting:false, "Click":this.selectUnselectAll, that:this }
      },
      { field: 'EventDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
      {
        headerName:"Start Time",
        field: 'StartTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 , maxWidth: 100  ,
        editable: true,
      },
      {
        field: 'EndTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100,
        editable: true,
      },



    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
      startTime: TimeEditorComponent,
      endtTime: CustomHeader
    };
    this.eventDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: false,
      floatingFilter: false,
    };

  }

  ngOnInit(): void {
    var config = {
      params: {
        startDate:this.StartDate,
        endDate:this.EndDate,
        startTime:this.StartTime,
        endTime:this.EndTime,
      }
    };

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
      // this.eventService.formData.EventListInfo
    };
    this.commonService.getclasses().subscribe((res:any)=>{
      this.classList = res;
    });
  }
  onEventReady(params) {
    this.date= new Date();
    this.date = formatDate(this.date,'dd-MM-yyyy','en');
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.eventService.getEvents().subscribe((res : any) => {
    //   this.eventsRowData =  res;
    // });
    this.getmultieventinfo();
  }
  getmultieventinfo(){
    var config = {
      params: {
        startDate:this.StartDate,
        endDate:this.EndDate,
        startTime:this.StartTime,
        endTime:this.EndTime,
      }
    };
    this.eventService.getmultieventinfo(config).subscribe((res: any) => {
      this.eventsRowData =  res.EventListInfo;
      if(res){
        this.eventsRowData.filter( i =>
          {
            i.IsActive = false;
            i.IdActive = i.Id+"_"+i.IsActive;
          }
          );
          // if((this.yearList.length <=0) || (this.yearList == null)){
          //   this.getAcademicYearList();
          // }
      }


  });

  }
saveMultiEvent(){
  // this.eventsRowData
  this.eventService.formData.EventListInfo = this.eventsRowData;
  // this.eventService.formData.EventInfo.IsMeeting

  this.eventService.savemultievent().subscribe((res:any)=>{
    if(res.IsSaved){
      this.notificationServ.ShowInfo('Successfully saved!');
      this.commonService.CallOnRefreshGrid();
      this.bsModalRef.hide();

    }else{
      this.notificationServ.ShowError(res.ErrorMessage);
    }
  });

}
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.eventService.formData.ClassMappingList){

      var ids = item.Id;
      for(var i=0;i<this.eventService.formData.ClassMappingList.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+this.eventService.formData.ClassMappingList[i].Id;
      }
    }
  }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){

      var ids = '';
      for(var i=0;i<items.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+items[i].Id;
      }

    }
  }

  searchEventInfo(){
    if(typeof(this.StartDate) === "object")
     {
      this.StartDate = formatDate(this.StartDate,'dd-MM-yyyy','en');
     }
     if(typeof(this.EndDate)  === "object")
     {
      this.EndDate = formatDate(this.EndDate,'dd-MM-yyyy','en');
     }
     if(typeof(this.StartTime)  === "object")
     {
      this.StartTime = formatDate(this.StartTime,'HH:mm','en');
     }
     if(typeof(this.EndTime)  === "object")
     {
      this.EndTime = formatDate(this.EndTime,'HH:mm','en');
     }


    var config = {
      params: {
        startDate:this.StartDate,
        endDate:this.EndDate,
        startTime:this.StartTime,
        endTime:this.EndTime,
      }
    };

  this.getmultieventinfo();
  }
  selectUnselect(isactive ,that){
    var id = isactive.split("_")[0];

    var selItem = that.eventsRowData.filter(item => item.Id == id);
    selItem[0].IsActive = !selItem[0].IsActive;
    selItem[0].IdActive = selItem[0].Id + "_"+selItem[0].IsActive;
   }
   selectUnselectAll(ClassId ,that){
     that.toggleSelection = !that.toggleSelection;
     if(that.eventsRowData){
       that.eventsRowData.filter( i =>
         {
           i.IsActive = that.toggleSelection;
           i.IdActive = i.Id+"_"+i.IsActive;
         }
         )
     }
      that.gridApi.setRowData(that.eventsRowData);

     // var params = {
     //   force: true,
     //   suppressFlash: true,
     //   rowNodes: that.rowData,
     // };
    // that.gridApi.redrawRows();
   }
  //  getAcademicYearList(){
  //   this.commonService.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
  //     res = res.slice().reverse();
  //     this.yearList = res;
  //   this.eventService.formData.EventInfo.AcademicYearId = res[0].Id;
  //     this.config = {
  //       params: {
  //         startDate: res[0].Code,
  //         endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
  //       }
  //     }
  //   });
  // }
}
