import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-break-out-rooms',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './break-out-rooms.component.html',
  styleUrl: './break-out-rooms.component.scss'
})
export class BreakOutRoomsComponent  implements OnInit {
  list:any[]=[];
  activeList:any[]=[];
  tGroups: number=2;
  selectedGroup: any;
  selectedItem: any;
  selectedList:any[]=[];
  constructor(public bsModalRef: BsModalRef) {}
  ngOnInit(): void {}
  send(){
    this.bsModalRef.hide()
  }
}
