import { Component, OnInit,EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
import { UserService } from 'src/app/shared/user.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { EditpicComponent } from './editpic.component';
import { StudentData, User } from '../shared/user.model';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
} from 'ng-multiselect-dropdown';
import { AddmlinfoComponent } from '../question/addmlinfo/addmlinfo.component';
import { PasswordResetComponent } from '../login/password-reset.component';
import { UserAcademicYearLogComponent } from '../academic/user-academic-year-log.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-search-user-shared-id',
    templateUrl: './search-user-shared-id.component.html',
    styleUrls: ['./search-user-shared-id.component.scss'],
    standalone: true,
    // imports: [NgIf, FormsModule, NgFor, MatCheckboxModule, MatIconModule, BsDatepickerModule, NgMultiSelectDropDownModule, TranslateModule]
    imports: [NgIf, FormsModule, NgFor, MatCheckboxModule, MatIconModule, BsDatepickerModule, TranslateModule]

})
export class SearchUserSharedIdComponent implements OnInit {

  gridApi;
  timepickerVisible = false;
  public time: Date = new Date();
  public mytime: string;
  maxLength:number;
  currentLength:number;
  gridColumnApi;
  datepickerConfig : BsDatepickerConfig;
  public event: EventEmitter<any> = new EventEmitter();
  public saved: EventEmitter<any> = new EventEmitter();
  CUSTOMCONST : any;
  dropdownSettings : IDropdownSettings;
  selectedItems = [];
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  childList:any =  [];
  yearList:any;
  genderlist : any = [];
  branchlist : any = [];
  rolelist : any = [];
  schoollist : any = [];
  list: any[] = [];
  classList:any[] = [];
  batchList:any[] = [];
  role: string;
  userShareId:string;

  constructor(public service:UserService,public bModalRef: BsModalRef, public modalService:BsModalService,
    private notificationServ:NotificationService,private commonService:CommonService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: false  });
    this.CUSTOMCONST = CUSTOMCONST;
    this.commonService.RefreshItemList$.subscribe(
      item => {
        this.getUserInfo();
       }
    );
   }
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
   this.getUserInfo();

  }
  getUserInfo(){
    this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData = res;
      this.genderlist = res.GenderList;
      this.branchlist = res.BranchList;
      this.rolelist = res.RoleList;
      this.schoollist = res.SchoolList;
      this.role = this.service.formData.UserInfo.Role;
      this.classList = res.ClassList;
      this.batchList = res.BatchList;
      this.yearList = res.AcademicYearList;
      this.maxLength = 2000;
      this.currentLength = 0;
    });
  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){

    var ids = item.Id;
    for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.ClassMappingList[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
        }
        else{
          this.service.getbatches(item.Id).subscribe((res:any)=>{
            this.batchList = res;
                  });
        }

      }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
          }
        }
  schoolChange(){
    if(this.service.formData.UserInfo.SchoolId > 0){
      this.branchlist = [];
      this.childList =  [];
      this.service.getBranchList(this.service.formData.UserInfo.SchoolId).subscribe((res:any)=>{
        this.branchlist = res;
      });
      this.service.getBasicStudentList(this.service.formData.UserInfo.SchoolId).subscribe((res: any) => {
        this.childList = res;
      });
    }
    else{
      this.branchlist = [];
      this.childList =  [];
    }
  }

  getTime(time){
    return "s";
  }
  onSave(form:NgForm){
    this.service.formData.UserInfo.IsSaved = false;
    console.log(this.service.formData.UserInfo);
    debugger;

    if(this.service.formData.UserInfo.UserId > 0){
      this.service.saveshareduserinfo().subscribe((res:User)=>{
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        this.saved.emit('someData');
      this.notificationServ.ShowInfo("User saved successfully");
      this.commonService.CallOnRefreshGrid();
      }
      else
      this.saved.emit('someData');
      });
    }
    else{
      this.service.formData.UserInfo.IsActive = true;
      this.saved.emit('someData');
      this.service.saveshareduserinfo().subscribe((res:User)=>{
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
        this.notificationServ.ShowInfo("User saved successfully");
        this.commonService.CallOnRefreshGrid();
        this.bModalRef.hide();
        }
        });
    }
  }

  openbranchmodal(data:string) {
    const initialState = {
      list: [
        {editImageOf:data}
      ]
    };
    this.bModalRef = this.modalService.show(EditpicComponent,{initialState,class: 'modal-xl box-shadow',ignoreBackdropClick: true});
    this.bModalRef.content.event.subscribe(res => {
       if(data === "profilePic")
      {
        this.service.formData.UserInfo.FileInfo = res.data;
      }
      else if(data === "coverImage")
      {
        this.service.formData.UserInfo.ProfileFileInfo = res.data;
      }
    });
  }
  editPic()
  {
    this.openbranchmodal("profilePic");
  }
  editCoverImage(){
    this.openbranchmodal("coverImage");
  }
  onRoleChange(){
    this.role = '';
    if(this.service.formData.UserInfo.RoleId > 0){
      for(var i = 0 ;i<this.rolelist.length;i++){
        if(this.rolelist[i].Id == this.service.formData.UserInfo.RoleId){
          this.role = this.rolelist[i].Name;
          if(this.role == "Student" || this.role == "Teacher" || this.role == "Parent"){
            this.commonService.getclasses().subscribe((res:any)=>{
              this.classList = res;
            });
          }
          break;
        }
      }
    }
  }
  classChange(){
    if(this.service.formData.StudentInfo.ClassId > 0){
      this.commonService.getbatches(this.service.formData.StudentInfo.ClassId).subscribe((res:any)=>{
this.batchList = res;
      });
    }
    if(this.service.formData.StudentInfo.ClassId < 0){
      this.batchList = [];
      this.openAddMLInfoModal('ClassData');
    }
    else{

      this.batchList = [];
    }
  }
  branchChange() {
    if(this.service.formData.UserInfo.SchoolId > 0 && this.service.formData.UserInfo.BranchId > 0){
      this.service.getclasss(this.service.formData.UserInfo.SchoolId,this.service.formData.UserInfo.BranchId).subscribe((res:any)=>{
        this.classList = res;
              });
            }
          }
  lenghtCounter(){
    this.currentLength = this.service.formData.UserInfo.About.length;
  }


openAddMLInfoModal(tableName) {
  const initialState = {

    list: [
      {id:0},
      {Text:tableName},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]

  };

  this.bsModalRef2 = this.modalService.show(AddmlinfoComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
}
resetPwd(){
  const initialState = {

    list: [
      {id:0},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]

  };
  this.bModalRef = this.modalService.show(PasswordResetComponent, { id: CUSTOMCONST.RESET_PWD_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
  this.bModalRef.content.event.subscribe(res => {

 });
}
userAcademicYearLogInfo(){

  const initialState = {

    list: [
      {id:this.list[0].id},
    ]

  };
  this.bModalRef = this.modalService.show(UserAcademicYearLogComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });

}
  searchUserWithSharedId() {
    this.service.searchuserwithsharedid(this.userShareId).subscribe((res: any) => {
      if (res.ErrorMessage) {
        this.notificationServ.ShowError(res.ErrorMessage);
      }else{
      this.service.formData =  res;
      this.genderlist = res.GenderList;
      this.branchlist = res.BranchList;
      this.rolelist = res.RoleList;
      this.schoollist = res.SchoolList;
      this.role = this.service.formData.UserInfo.Role;
      this.classList = res.ClassList;
      this.batchList = res.BatchList;
      this.yearList = res.AcademicYearList;
      this.maxLength = 2000;
      this.currentLength = 0;
      }
    });
  }
}
