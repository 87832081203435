import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { achievements } from '../shared/achievements.service';
import { UploadBookComponent } from './upload-book.component';
import { EditMyachievementComponent } from './edit-myachievement.component';
import { EditMyhobbyComponent } from './edit-myhobby.component';
import { EditVisitingPlaceComponent } from './edit-visiting-place.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-book-description',
    templateUrl: './book-description.component.html',
    styleUrls: ['./book-description.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf]
})
export class BookDescriptionComponent implements OnInit {
  list:any;
  CUSTOMCONST:any=CUSTOMCONST;

  bookDetails:any=[];
  tarvelDetails:any=[];
  hobbyDetails:any=[];
  achievementDetails:any=[];
  achievementName:string='';

  constructor(private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService, public bsModalRef: BsModalRef,public service:achievements,) {
      
     }

  ngOnInit(): void {
    this.achievementName = this.list[0].name;
    if(this.list[0].name === 'book'){
      this.getStudentBookInfo();
    }else if(this.list[0].name === 'tarvel'){
      this.getStudentTravelInfo();
    }else if(this.list[0].name === 'hobby'){
      this.getStudentHobbyInfo();
    }else if(this.list[0].name === 'achievement'){
      this.getStudentAchievementInfo();
    }
    
  }
  getStudentBookInfo(){
    this.service.getStudentBookInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.bookDetails = res.StudentBookInfo;
    })
  }
  getStudentTravelInfo(){
    this.service.getStudentTravelInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.tarvelDetails = res.StudentTravelInfo;
    })
  }
  getStudentHobbyInfo(){
    this.service.getStudentHobbyInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.hobbyDetails = res.StudentHobbyInfo;
    })
  }
  getStudentAchievementInfo(){
    this.service.getStudentAchievementInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.achievementDetails = res.StudentAchievementInfo;
    })
  }

  openUploadBookModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(UploadBookComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  
  }
  openMyAchievementModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditMyachievementComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
  openMyHobbyModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditMyhobbyComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
  openVisitingPlaceModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditVisitingPlaceComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL, initialState,class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }

}
