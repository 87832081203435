import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { CommonService } from 'src/app/shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { TimetableService } from 'src/app/shared/timetable.service';
import { UserService } from 'src/app/shared/user.service';
import { EditTimeTableComponent } from '../../edit-time-table/edit-time-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-time-tables',
    templateUrl: './time-tables.component.html',
    styleUrls: ['./time-tables.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, AgGridModule]
})
export class TimeTablesComponent implements OnInit {
  TTGridOptions:any;
  TTColumnDefs;
  TTDefaultColDef;
  TTRowSelection;
  TTRowData:any;
  gridApi;
  gridColumnApi;
  frameworkComponents;
  
 

  role:string;
  selectedClassId:number;
  selectedStuId:number;

  public bsModalRef:BsModalRef;
  constructor(public TTService:TimetableService, private userService:UserService,
    public commonService:CommonService,public modalService: BsModalService) {
    this.commonService.RefreshGrid$.subscribe(
      item => {
        this.getclasstimetablelistinfo(this.selectedClassId);
       }
    );
    
   }

  ngOnInit(): void {
    this.greadReady();
    this.getMyInfo();
    
  }
  greadReady() {

   
      this.TTColumnDefs = [
        {
          headerName: "ClassTTId",
          field: 'ClassTimeTableId', cellRendererFramework: CustommenuComponent,
          cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.editTimeTable, that: this }, { Name: "Delete", "Click": this.deleteTimeTable, that: this }] },
  
          filter: false, width: 70, maxWidth: 70, minWidth: 70,
          headerComponent: 'customHeader',
          headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
        },
        { field: 'ClassName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'Comments', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'TeacherName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        { field: 'Subject', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
        
      ];
    
    
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.TTDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getclasstimetablelistinfo(classId){
    this.TTService.getclasstimetablelistinfo(classId).subscribe((res:any)=>{
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  classList:any;
  selectedClass:number=1;
  changeClass(){
    this.selectedClassId=this.selectedClass;
  }
  serchTimeTable(){
    this.getclasstimetablelistinfo(this.selectedClass);
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;

      this.role = res.UserInfo.Role;
      console.log("getMyInfo");
      console.log(res);
      
      if(this.role == "Student"){
       
        this.selectedClassId = res.StudentInfo.ClassId;
        this.selectedStuId = res.StudentInfo.UserId;
        this.getclasstimetablelistinfo(this.selectedClassId);
        

      }else{
        this.classList=res.ClassList;
        this.selectedClassId = this.selectedClass;
        this.selectedStuId = res.StudentInfo.UserId;
        this.getclasstimetablelistinfo(this.selectedClassId);
        // this.greadReady();
      }
      
      
    });
  }
  onTTReload($event){

  }
  onTTReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

  }
  editTimeTable(id, that){
    that.openTimeTableModel(id);
  }
  deleteTimeTable(id, that){
    
  }
  
  openTimeTableModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditTimeTableComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  }
}
