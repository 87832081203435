<div class="modal-dialog  modal-xl m-0">
  <!-- Submit Confirmation Start-->
    <div class="modal-content" *ngIf="list[0].id != 'delete'">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1">Confirmation</h3>
      </div>
      <div class="modal-body p-2">
       <p>{{ConfirmMessage}}</p>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-success" (click)="onConfirm()">Save</button>
        <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.CONFIRM_MODAL)" aria-label="Close">Cancel</button>
      </div>
    </div>
    <!-- Submit Confirmation End-->

    <!-- Detele Confirmation Start-->
    <div class="modal-content" *ngIf="list[0].id == 'delete'">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1">Confirmation</h3>
      </div>
      <div class="modal-body p-2">
       <p>{{list[0].message}}</p>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-success" (click)="onConfirm()">Yes</button>
        <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.CONFIRM_MODAL)" aria-label="Close">Cancel</button>
      </div>
    </div>
    <!-- Detele Confirmation End -->
  </div>
