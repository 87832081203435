import { Component, OnInit } from '@angular/core';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as fileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { OwnFileService } from '../shared/ownFile.service';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { ShowOwnDocsComponent } from './show-own-docs.component';
import { EditOwnDocsComponent } from './edit-own-docs.component';
import { AgGridModule } from 'ag-grid-angular';


@Component({
    selector: 'app-own-documents',
    templateUrl: './own-documents.component.html',
    styleUrls: ['./own-documents.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class OwnDocumentsComponent implements OnInit {

  list:any[]=[];
  
  
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  fileListData: any[];



  filterStartDate:any;
  filterEndDate:any;
  datepickerConfig : BsDatepickerConfig;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();
  public bsModalRef:BsModalRef;

  CUSTOMCONST:any=CUSTOMCONST;
  constructor(public notificationServ:NotificationService,public service:AssignemntService,public commonService:CommonService,
    public ownDocsServ:OwnFileService, private modalService: BsModalService) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.bsRangeValue = [this.bsValue, this.maxDate];


  //   this.columnDefs = [
  //     { 
  //       headerName:"UserDocumentId",
  //       field: 'UserDocumentId', cellRendererFramework: CustommenuComponent, 
  //       cellRendererParams : { MenuList : [{Name: "Show", "Click" : this.ShowDocument , that : this}, {Name: "Delete", "Click" : this.deleteDocument , that : this}]},
        
  //       filter:false, width:60,maxWidth: 60, 
  //       headerComponent: 'customHeader', 
  //       headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  //     }, 
     
  //     { field: 'FileName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
  //   { field: 'FilePath', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
  //   { field: 'FileSize', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 100 ,maxWidth: 100 },
    
  //  ];
  //  this.frameworkComponents = { 
  //   customHeader: CustomHeader 
  // };
  this.gridInit();
  this.commonService.RefreshGrid$.subscribe(
  
    item => {
      this.getowndocumentlist();
      }
  );
   
   }

  ngOnInit(): void {
  }
  gridInit(){
    
    this.columnDefs = [
      { 
        headerName:"UserDocumentId",
        field: 'UserDocumentId', cellRendererFramework: CustommenuComponent, 
        cellRendererParams : { MenuList : [{Name: "Show", "Click" : this.ShowDocument , that : this}, {Name: "Delete", "Click" : this.deleteDocument , that : this}]},
        
        filter:false, width:60,maxWidth: 60, 
        headerComponent: 'customHeader', 
        headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      }, 
     
      { field: 'FileName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
    { field: 'FilePath', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
    { field: 'FileSize', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 100 ,maxWidth: 100 },
    
   ];
   this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
  this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getowndocumentlist();
  }
  getowndocumentlist(){
    this.ownDocsServ.getowndocumentlist().subscribe((res : any) => {
      // this.fileListData =  res;
      this.ownDocsServ.formData = res;
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  
  deleteFiles(){
    if(this.fileListData && this.fileListData.length > 0){
    this.commonService.deleteunusedfiles(this.fileListData).subscribe((res : any) => {
      this.getowndocumentlist()
    });
  }
  }
  downloadFiles(){
    if(this.fileListData && this.fileListData.length > 0){
      this.commonService.zipanddownload(this.fileListData).subscribe((res:any)=>{
        fileSaver.saveAs(res, 'filedownloads.zip');
      });
      
  }
  }
  // editDocument(documentId, that){
  //   that.openOwnDocumentEditModel(documentId);
  // }
  deleteDocument(documentId, that){
    that.ownDocsServ.getowndocument(documentId).subscribe((res : any) => {
      that.ownDocsServ.formData = res;
      
      if(res){
        that.ownDocsServ.formData.IsActive = false
        that.ownDocsServ.deleteowndocument().subscribe((res:any)=>{
          if(res.IsSaved){
            that.notificationServ.ShowInfo('Successfully deleted!');
            that.getowndocumentlist();
          }else{
            that.notificationServ.ShowError(res.Message);
          }
        });
      }
     });
    
  }
  ShowDocument(documentId, that){
    that.openShowDocModel(documentId);
  }
  openShowDocModel(documentId){
    const initialState = {
      list: [
        {id:documentId}
      ]      
    };
    this.bsModalRef = this.modalService.show(ShowOwnDocsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  }
  // openOwnDocumentEditModel(documentId){
  //   const initialState = {
  //     list: [
  //       {id:documentId}
  //     ]      
  //   };
  //   this.bsModalRef = this.modalService.show(EditOwnDocsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  // }
}

