import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class ContactListComponent implements OnInit {
  
  contactListcolumnDefs:any;
  contactListdefaultColDef:any;
  contactListgridOptions:any;
  contactListrowSelection;
  contactListData:any;
  constructor(public commomService:CommonService) { 
    
this.contactListcolumnDefs = [
  { headerName:"Name",field: 'FirstName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { headerName:"Mobile", field: 'Mobile', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
  { headerName:"Email", field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 300 , maxWidth: 300,minWidth: 200   },
  { headerName:"JobTitle",field: 'JobTitle', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"School Name",field: 'SchoolName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 300    },
  { headerName:"School Type",field: 'SchoolType', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"City",field: 'City', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Country",field: 'Country', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Source",field: 'Source', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Created Date",field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 200    },
  { headerName:"Comments",field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
];

this.contactListdefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: false,
  sortable: false,
  floatingFilter: false,
  };
  }

  ngOnInit(): void {
    this.commomService.getregisterinfoWithType("Contact").subscribe((res: any) => {
      this.contactListData =  res;
  });
  }

}
