import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CustomHeader } from "../custommenu/custom-header.component";
import { CustommenuComponent } from "../custommenu/custommenu.component";
import { CommonService } from "../shared/common.service";
import { AgGridModule } from "ag-grid-angular";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-subject-list",
  templateUrl: "./subject-list.component.html",
  styleUrls: ["./subject-list.component.scss"],
  standalone: true,
  imports: [FormsModule, NgFor, AgGridModule],
})
export class SubjectListComponent implements OnInit {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: any;
  bsModalRef: BsModalRef;
  gridOptions: any;
  classId: number = 0;
  classList: any;
  constructor(
    public commonServ: CommonService,
    private modalService: BsModalService
  ) {
    this.columnDefs = [
      {
        headerName: "Action",
        field: "Id",
        filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Edit", Click: this.MenuEditClick, that: this },
            { Name: "Delete", Click: this.deleteUser, that: this },
          ],
        },
        headerComponent: "customHeader",
        maxWidth: 50,
        minWidth: 50,
        headerComponentParams: {
          button: "click",
          enableMenu: true,
          menuIcon: "fa-plus",
          enableSorting: false,
        },
      },
      {
        field: "Name",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        minWidth: 120,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";
    this.commonServ.RefreshGrid$.subscribe((item) => {
      this.getsubjectsbyclassid(this.classId);
    });
  }

  ngOnInit(): void {
    this.getClass();
  }
  getsubjectsbyclassid(classId: number) {
    this.commonServ.getsubjectsbyclassid(classId).subscribe((res: any) => {
      const rowData = res;
      this.gridApi.setRowData(rowData);
    });
  }
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getsubjectsbyclassid(this.classId);
    }, 300);
  }

  deleteUser(UserId, that) {}
  MenuEditClick(UserId, that) {
    const initialState = {
      list: [{ id: UserId }],
    };
    //  that.bsModalRef = that.modalService.show(UsereditComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
    //  that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that))
  }
  getClass() {
    this.commonServ.getclasses().subscribe((res: any) => {
      this.classList = res;
    });
  }
  changeClass() {}
  serchSubject() {
    this.getsubjectsbyclassid(this.classId);
  }
}
