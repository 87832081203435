import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { UserService } from '../shared/user.service';
import { StudentProfileInfo } from '../shared/user.model';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, MatSliderModule]
})
export class EditProfileComponent implements OnInit {
  list: any;
  ProfileName: any; 
  min : String;
  max:string;

  constructor(public service:UserService,private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService) { }

  ngOnInit(): void {
    this.service.studentProfileInfoView.StudentProfileInfo = new  StudentProfileInfo()
    this.ProfileName = this.list[0].name;
    if(this.list[0].action == 'Edit'){
      this.service.studentProfileInfoView.StudentProfileInfo = this.list[0].data;
    }
  }
  formatLabel(value: number): string {

    return `${value}`;
  }
  selectRating(event){
    var rating : any = event.target.value;
    var arr = rating.split("/");
    this.min = arr[0];
    this.max = arr[1];
  }
  saveProfileInfo(){
    this.service.studentProfileInfoView.StudentProfileInfo.ProfileTypeId = this.list[0].id;
    this.service.saveStudentProfileInfo().subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Save Successfull");
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }
      
    });
  }
  updateProfileInfo(){
    // SPDId
    this.service.saveStudentProfileInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Save Successfull");
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }
      
    });
  }
  closeModel(){
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
  }

}
