import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChatService } from '../shared/chat.service';
import { CommonService } from '../shared/common.service';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-viewchat',
    templateUrl: './viewchat.component.html',
    styleUrls: ['./viewchat.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, NgIf]
})
export class ViewchatComponent implements OnInit {
list:any[]=[];
messageList:any[]=[];
  constructor(public service:ChatService,private modalService: BsModalService,public commonServ:CommonService,public bsModalRef: BsModalRef,) { 
    
    
  }

  ngOnInit(): void {
    this.service.getMessageInfo(this.list[0].id).subscribe((res: any) => {
     
      this.service.formData= res;
      this.messageList = res.MessageInfo;
      this.service.formData.MessageMasterInfo = res.MessageMasterInfo;
      // this.service.formData.EventInfo.EventDate
      // this.service.formData.MessageInfo[this.list[0].id].MessageId;
      // this.service.formData.MessageMasterInfo.Name;
      
    });
  }

}
