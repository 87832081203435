import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../../custommenu/custom-header.component';
import { CustommenuComponent } from '../../custommenu/custommenu.component';
import { CommonService } from '../../shared/common.service';
import { CUSTOMCONST } from '../../shared/Const';
import { EventService } from '../../shared/event.service';
import { UsereditComponent } from '../../user/useredit.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
  selector: 'app-view-social-event',
  standalone: true,
  imports: [AgGridModule],
  templateUrl: './view-social-event.component.html',
  styleUrl: './view-social-event.component.scss'
})
export class ViewSocialEventComponent {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData:any;
  bsModalRef: BsModalRef;
  gridOptions: any;
  constructor(public commonServ:CommonService,public service:EventService,private modalService: BsModalService,) {
    this.columnDefs = [
      {
        headerName: "Action", field: 'eventId', filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.MenuEditClick, that: this }, { Name: "Delete", "Click": this.deleteUser, that: this }] },
        headerComponent: 'customHeader',
        headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      { field: 'EventDate',  sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'EventStartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'EventEndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
     
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = 'single';
    this.commonServ.RefreshGrid$.subscribe(
      item => {

        this.getStuUserList();
        
      }
    );
   }

  ngOnInit(): void {
  }
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getStuUserList();
    }, 300);
  }
getStuUserList(){
  this.service.getEvents().subscribe((res: any) => {
    const rowData =  res;
    this.gridApi.setRowData(rowData);
  });
}


deleteUser(UserId, that) {
}
MenuEditClick(UserId, that) {
 const initialState = {
   list: [
     { id: UserId }
   ]

 };
 that.bsModalRef = that.modalService.show(UsereditComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
 that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that))

}
}
