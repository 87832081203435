import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { QuestionService } from '../shared/question.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-student-answerpreview',
    templateUrl: './student-answerpreview.component.html',
    styleUrls: ['./student-answerpreview.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgClass, MatRadioModule, MatIconModule, MatCheckboxModule, FormsModule, NgxDocViewerModule, TranslateModule]
})
export class StudentAnswerpreviewComponent implements OnInit {
list:any[]=[];
stdAnswerMasterInfo:any;
stuAnswerListData:any;
stdAnswerInfo:any;
// questionInfo:any;
resultTypeList:any;
urlSafe: SafeResourceUrl;
fileType:any;
fileInfoList:any;
reqBy:any;
docUrl:any;
re:any="\ ";
public role:string; 
  constructor(public bsModalRef:BsModalRef,public service:QuestionService,public sanitizer: DomSanitizer,public assignmentService:AssignemntService,public notificationServ:NotificationService,public commonserv : CommonService) { }

  ngOnInit(): void {
    this.role = this.commonserv.role;
    
    this.service.getstdquestionfullinfo(this.list[0].id).subscribe((res : any) => {
      this.stuAnswerListData =  res;
      this.service.formData.QuestionInfo = res.QuestionInfo;
      // this.questionInfo = res.QuestionInfo;
      this.stdAnswerInfo = res.StdAnswerInfo;
      this.stdAnswerMasterInfo = res.StdAnswerMasterInfo;
      this.fileInfoList = res.FileInfoList;
      this.reqBy=this.list[0].reqBy;
      
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.StdAnswerMasterInfo.ResponseFilePath);
      var ftList = res.StdAnswerMasterInfo.ResponseFilePath.split('.');
      this.fileType = ftList[ftList.length-1];
      if(this.fileType === 'doc' || this.fileType === 'docx'){
        (this.re).replace(" ", "");
        var re = /\\/gi; 
        
        // this.docUrl = "https://docs.google.com/gview?url=%"+(res.StdAnswerMasterInfo.ResponseFilePath).replace(re, "/")+"%&embedded=true";
        this.docUrl = (res.StdAnswerMasterInfo.ResponseFilePath).replace(re, "/");

        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUrl);
        
      }
      this.resultTypeList = res.ResultTypeList;
      if(this.fileInfoList){
        for(var i = 0; i < this.fileInfoList.length; i++){
          var ftList = this.fileInfoList[i].Result.split('.');
          this.fileInfoList[i].fileType = ftList[ftList.length-1];

            if(this.fileInfoList[i].fileType === 'doc' || this.fileInfoList[i].fileType === 'docx'){
              var re1 = /\\/gi; 
              this.fileInfoList[i].docUrl = (this.fileInfoList[i].Result).replace(re1, "/");
            }
            else{
          this.fileInfoList[i].urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileInfoList[i].Result);

            }
         
        }
      }
      
    });
  }
  saveResult(){
    this.assignmentService.updateStdResults(this.stdAnswerMasterInfo).subscribe((res : any)=>{
      if(res){
        this.notificationServ.ShowInfo('Successfully saved!');
      }
      else{
        this.notificationServ.ShowError('Something went wrong please try again!');
      }
    })
  }
  htmlContent = '';

config: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '15rem',
  minHeight: '5rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [
    ['bold']
    ],
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ]
};
}
