import { Component, OnInit,EventEmitter,importProvidersFrom, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
import { UserService } from 'src/app/shared/user.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { EditpicComponent } from './editpic.component';
import { StudentData, User } from '../shared/user.model';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AddmlinfoComponent } from '../question/addmlinfo/addmlinfo.component';
import { PasswordResetComponent } from '../login/password-reset.component';
import { UserAcademicYearLogComponent } from '../academic/user-academic-year-log.component';
import { UserLogInfoComponent } from './user-log-info.component';
import { StuOfferComponent } from '../student/stu-offer.component';
import { SidemenuSettingComponent } from './sidemenu-setting/sidemenu-setting.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
//import {  IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.scss'],
  imports: [MatCheckboxModule,TranslateModule,MatIconModule,FormsModule,CommonModule, NgMultiSelectDropDownModule
    //  NgForm
    ],
  standalone: true,
})

export class UsereditComponent implements OnInit {
  gridApi;
  timepickerVisible = false;
  public time: Date = new Date();
  public mytime: string;
  maxLength:number;
  currentLength:number;
  gridColumnApi;
  datepickerConfig : BsDatepickerConfig;
  public event: EventEmitter<any> = new EventEmitter();
  public saved: EventEmitter<any> = new EventEmitter();
  CUSTOMCONST : any;
  dropdownSettings : IDropdownSettings;
  selectedItems = [];
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  childList:any =  [];
  yearList:any;
  genderlist : any = [];
  branchlist : any = [];
  rolelist : any = [];
  schoollist : any = [];
  list: any[] = [];
  classList:any[] = [];
  batchList:any[] = [];
  role: string;
  userId:number;

cordinatorNumList:any[]=[];
studentNumList:any[]=[];
teacherNumList:any[]=[];
parentNumList:any[]=[];

  constructor(public service:UserService,public bModalRef: BsModalRef, public modalService:BsModalService,
    private notificationServ:NotificationService,private commonService:CommonService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: false  });
    this.CUSTOMCONST = CUSTOMCONST;
    this.commonService.RefreshItemList$.subscribe(
      item => {
        this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
    this.classList = res.ClassList;
    this.batchList = res.BatchList;
    this.yearList = res.AcademicYearList;
    this.service.formData.StudentInfo.BatchId = 0;
    this.service.formData.StudentInfo.ClassId = 0;
    // this.service.formData.TeacherInfo.IsHumanityLife;
        });
       }
    );
    this.cNumList();
    this.sNumList();
    this.tNumList();
    this.pNumList();


   }

    cNumList = () =>{
      for(let i=1; i<=50; i++){
        let data={
          Id:i,
          Value:i
        }
        this.cordinatorNumList.push(data);
      }
    }
    sNumList = () =>{
      for(let i=1; i<=1000; i++){
        let data={
          Id:i,
          Value:i
        }
        this.studentNumList.push(data);
      }
    }
    tNumList = () =>{
      for(let i=1; i<=50; i++){
        let data={
          Id:i,
          Value:i
        }
        this.teacherNumList.push(data);
      }
    }
    pNumList = () =>{
      for(let i=1; i<=1000; i++){
        let data={
          Id:i,
          Value:i
        }
        this.parentNumList.push(data);
      }
    }



    //formData : User;
    // rowData : any;


  // formData:User.studentData = StudentData
  ngOnInit(): void {
    this.userId=this.list[0].id;
    this.service.formData.UserInfo= new User();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData =  res;
this.genderlist = res.GenderList;
this.branchlist = res.BranchList;
this.rolelist = res.RoleList;
this.schoollist = res.SchoolList;
this.role = this.service.formData.UserInfo.Role;
this.classList = res.ClassList;
this.batchList = res.BatchList;
this.yearList = res.AcademicYearList;
this.maxLength = 2000;
this.currentLength = 0;
this.yearList.slice().reverse();
// disabled:Boolean;
//this.mytime = new Date();
      // this.rowData =    res.UserInfo;
      console.log(this.service.formData.UserInfo.Role);
    });
    // this.onRoleChange();


  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){

    var ids = item.Id;
    for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.ClassMappingList[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
        }
        else{
          this.service.getbatches(item.Id).subscribe((res:any)=>{
            this.batchList = res;
                  });
        }

      }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
          }
        }
  schoolChange(){
    if(this.service.formData.UserInfo.SchoolId > 0){
      this.branchlist = [];
      this.childList =  [];
      this.service.getBranchList(this.service.formData.UserInfo.SchoolId).subscribe((res:any)=>{
        this.branchlist = res;
      });
      this.service.getBasicStudentList(this.service.formData.UserInfo.SchoolId).subscribe((res: any) => {
        this.childList = res;
      });
    }
    else{
      this.branchlist = [];
      this.childList =  [];
    }
  }

  getTime(time){
    return "s";
  }
  onSave(form:NgForm){
    this.service.formData.UserInfo.IsSaved = false;
    if(this.service.formData.UserInfo.UserId > 0){
      this.service.saveUser().subscribe((res:User)=>{
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        this.saved.emit('someData');
      this.notificationServ.ShowInfo("User saved successfully");
      this.commonService.CallOnRefreshGrid();
      this.modalService.hide(CUSTOMCONST.USER_EDIT_MODAL);
      }
      else
      this.saved.emit('someData');
      });
    }
    else{
      this.service.formData.UserInfo.IsActive = true;
      this.saved.emit('someData');
      this.service.saveUser().subscribe((res:User)=>{
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
        this.notificationServ.ShowInfo("User saved successfully");
        this.commonService.CallOnRefreshGrid();
        // this.bModalRef.hide();
        this.modalService.hide(CUSTOMCONST.USER_EDIT_MODAL);
        }
        });
    }
  }

  openbranchmodal(data:string) {
    const initialState = {
      list: [
        {editImageOf:data}
      ]
    };
    this.bModalRef = this.modalService.show(EditpicComponent,{id: CUSTOMCONST.LABEL_TWO_MODAL, initialState,class: 'modal-xl box-shadow',ignoreBackdropClick: true});
    this.bModalRef.content.event.subscribe(res => {
       if(data === "profilePic")
      {
        this.service.formData.UserInfo.FileInfo = res.data;
      }
      else if(data === "coverImage")
      {
        this.service.formData.UserInfo.ProfileFileInfo = res.data;
      }
    });
  }
  editPic()
  {
    this.openbranchmodal("profilePic");
  }
  editCoverImage(){
    this.openbranchmodal("coverImage");
  }
  onRoleChange(){
    //this.rolelist
    // debugger;
    this.role = '';
    if(this.service.formData.UserInfo.RoleId > 0){
      for(var i = 0 ;i<this.rolelist.length;i++){
        if(this.rolelist[i].Id == this.service.formData.UserInfo.RoleId){
          this.role = this.rolelist[i].Code;
          if(this.role == "Student" || this.role == "Teacher" || this.role == "Parent"){
            this.commonService.getclasses().subscribe((res:any)=>{
              this.classList = res;
            });
          }
          break;
        }
      }
    }
  }
  classChange(){
    if(this.service.formData.StudentInfo.ClassId > 0){
      this.commonService.getbatches(this.service.formData.StudentInfo.ClassId).subscribe((res:any)=>{
this.batchList = res;
      });
    }
    if(this.service.formData.StudentInfo.ClassId < 0){
      this.batchList = [];
      this.openAddMLInfoModal('ClassData');
    }
    else{

      this.batchList = [];
    }
  }
  branchChange() {
    if(this.service.formData.UserInfo.SchoolId > 0 && this.service.formData.UserInfo.BranchId > 0){
      this.service.getclasss(this.service.formData.UserInfo.SchoolId,this.service.formData.UserInfo.BranchId).subscribe((res:any)=>{
        this.classList = res;
              });
            }
          }
  lenghtCounter(){
    this.currentLength = this.service.formData.UserInfo.About.length;
    // this.disabled = this.currentLength<this.maxLength?true;
  }


openAddMLInfoModal(tableName) {
  const initialState = {

    list: [
      {id:0},
      {Text:tableName},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]

  };

  this.bsModalRef2 = this.modalService.show(AddmlinfoComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
}
resetPwd(){
  const initialState = {

    list: [
      {id:0},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]

  };
  this.bModalRef = this.modalService.show(PasswordResetComponent, { id: CUSTOMCONST.RESET_PWD_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
  this.bModalRef.content.event.subscribe(res => {

 });
}
userAcademicYearLogInfo(){

  const initialState = {

    list: [
      {id:this.list[0].id},
    ]

  };
  this.bModalRef = this.modalService.show(UserAcademicYearLogComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });

}
userLogHistory(){
  const initialState = {

    list: [
      {id:this.list[0].id},
    ]

  };
  this.bModalRef = this.modalService.show(UserLogInfoComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });

}
studentOffer(studentId:number){
  const initialState = {
    list: [
      { id: studentId }
    ]

  };
  this.bModalRef = this.modalService.show(StuOfferComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
}
sidemenu(userId:number){
  // debugger;
  const initialState = {
    list: [
      { id: userId }
    ]

  };
  this.bModalRef = this.modalService.show(SidemenuSettingComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
}
}
