import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { formatDate, NgIf, NgFor, NgClass, NgStyle } from '@angular/common';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-classtimetable',
  templateUrl: './classtimetable.component.html',
  styleUrls: ['./classtimetable.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TranslateModule, MatIconModule, NgClass, NgStyle]
})
export class ClasstimetableComponent implements OnInit {

  @Input() data: [];
  Weeklydata = []; 
  AvailWeeks = [];
  isDisabledPrevWeek = false;
  isDisabledNextWeek = false;  
  selectedWeek = '';
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  daytimes = ['08:00-09:00','09:00-10:00','10:00-11:00','11:00-12:00','12:00-01:00','01:00-02:00','02:00-03:00'];
  
  constructor() { }

  NextWeek(){
    if(this.AvailWeeks.indexOf(this.selectedWeek) != -1){
      let idx = this.AvailWeeks.indexOf(this.selectedWeek);
      if(this.AvailWeeks[idx+1]){
        this.selectedWeek = this.AvailWeeks[idx+1];
        this.isDisabledNextWeek = !this.AvailWeeks[idx+2];
        this.isDisabledPrevWeek = !this.AvailWeeks[idx];

      }
  }
  }

  PrevWeek(){
      if(this.AvailWeeks.indexOf(this.selectedWeek) != -1){
        let idx = this.AvailWeeks.indexOf(this.selectedWeek);
        if(this.AvailWeeks[idx-1]){
          this.selectedWeek = this.AvailWeeks[idx-1];
          this.isDisabledPrevWeek = !this.AvailWeeks[idx-2];
          this.isDisabledNextWeek = !this.AvailWeeks[idx];
        }
    }
  }
  // Function to get weekday name from a date
  getWeekdayFromDate(date) {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let dayIndex = (new Date(date)).getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  dayIndex = dayIndex === 0 ? 7 : dayIndex; 
  return dayIndex;
  }
  ngOnInit(): void {
    let startDate = new Date();
    let mnth = (startDate.getMonth()+1).toString().length == 1 ? "0" + (startDate.getMonth()+1) : (startDate.getMonth()+1); 
    let stdt = startDate.getDate() + '-' + mnth + '-'  + startDate.getFullYear()
    stdt = stdt.substring(3,6) + stdt.substring(0,3) + stdt.substring(6);
    this.initData(stdt);
    let getweek = this.getWeekDates(stdt);
    let currweekdate = getweek.startDate.getDate() + '-' + (getweek.startDate.getMonth()+1) + '-'  + getweek.startDate.getFullYear();
    if(!this.AvailWeeks.includes(currweekdate))
      this.AvailWeeks.push(currweekdate);
    this.selectedWeek = currweekdate;
  }

  initData(dt:any){
    try{    
      let getweek = this.getWeekDates(dt);
      if(!this.Weeklydata[getweek.startDate.getDate() + '-' + (getweek.startDate.getMonth()+1) + '-'  + getweek.startDate.getFullYear()]){  
        let temp =  []; //temp data
        this.daytimes.forEach((obj:any) => {
          let tempobj = [];
          let stdt = getweek.startDate;
          const endt = getweek.endDate;
          tempobj.push(obj); //adding time slot here
          while(stdt <= endt){
            tempobj.push('');
            stdt  = this.addDays(stdt, 1);
          }
          temp.push(tempobj);
        });
        this.Weeklydata[getweek.startDate.getDate() + '-' + (getweek.startDate.getMonth()+1) + '-'  + getweek.startDate.getFullYear()] = temp;
      }
    }
    catch(e){}
    }
  getampm(t:any){
    if(t > 11.50 && t < 23.59)
    return t + 'pm';
    else
    return t + 'am';
  }

  get12hrsTime(dt, b){
    let t = "";
    if(b)
      t = Math.ceil(parseFloat(dt.replaceAll(":", "."))-12)+":00";
    else
      t = Math.floor(parseFloat(dt.replaceAll(":", "."))-12)+":00";

      return "0" + t;
  }
  ngOnChanges(changes: SimpleChanges) {
    // Respond to input changes
    try{
    if (changes.data) {
      this.data.forEach((obj: any) => {
        let stdt = obj.StartDate;
        stdt = stdt.substring(3,6) + stdt.substring(0,3) + stdt.substring(6);
        this.initData(stdt);
        stdt = new Date(stdt);
        let endt = obj.EndDate;
        endt = endt.substring(3,6) + endt.substring(0,3) + endt.substring(6);
        this.initData(endt);
        endt = new Date(endt);
        while(stdt < endt){
          let timeoffset = (parseInt(obj.StartTime.replaceAll(":", ".")) > 12 ? this.get12hrsTime(obj.StartTime, false) : obj.StartTime) + '-' + (parseInt(obj.EndTime.replaceAll(":", ".")) > 12 ? this.get12hrsTime(obj.EndTime, true) : obj.EndTime);
          let weekday = this.getWeekdayFromDate(stdt);
          let getweek = this.getWeekDates(stdt);
          let currweekdate = getweek.startDate.getDate() + '-' + (getweek.startDate.getMonth()+1) + '-'  + getweek.startDate.getFullYear();
          if(!this.AvailWeeks.includes(currweekdate))
            this.AvailWeeks.push(currweekdate);
          this.selectedWeek = currweekdate;
          this.Weeklydata[currweekdate][this.daytimes.indexOf(timeoffset)][weekday] = {heading:obj.Subject,subheading:obj.Comments};
          stdt  = this.addDays(stdt, 1);
        }
      }); 
      this.AvailWeeks.forEach((tempweak:any) => {
        this.Weeklydata[tempweak].forEach((obj:any) => {
          let t = obj[0].split('-');
          if(t[0].indexOf("am") === -1)
            obj[0] = this.getampm(t[0]) + '- ' + this.getampm(t[1]); 
        });
      });
      if(this.AvailWeeks.length > 1){
        const currIdx = this.AvailWeeks.indexOf(this.selectedWeek);
        if(!this.AvailWeeks[currIdx-1])
          this.isDisabledPrevWeek = true;                
        if(!this.AvailWeeks[currIdx+1])
          this.isDisabledNextWeek = true;
      }
      else{
        this.isDisabledPrevWeek = true;                
        this.isDisabledNextWeek = true;
      }

    }
  }
  catch(e){
    console.log(e);
  }
  }
  // Function to add days to a given date
  addDays(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() + daysToAdd);
    return result;
  }
  // Function to get the start and end dates of the week containing a given date
  getWeekDates(date) {
    const currentDate = new Date(date); // Create a new Date object based on the provided date
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    
    // Calculate the start date of the week (Sunday)
    const startDate = new Date(currentDate); // Create a new Date object for the current date
    startDate.setDate(currentDate.getDate() - dayOfWeek); // Move backward to start of the week (Sunday)
    startDate.setHours(0, 0, 0, 0); // Set to beginning of the day (midnight)
    
    // Calculate the end date of the week (Saturday)
    const endDate = new Date(startDate); // Create a new Date object based on the start date
    endDate.setDate(startDate.getDate() + 6); // Move forward to end of the week (Saturday)
    endDate.setHours(23, 59, 59, 999); // Set to end of the day (just before midnight)

    return { startDate, endDate };
  }

}
