<!-- <p>help works!</p> -->


<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1">{{helpTabName | translate}}</h3>
      </div>
      <div class="modal-body p-2">
          <div *ngIf="helpTabName === 'EditProfile' ">
            <h3>A brief introduction to you and your goal-based learning plan</h3>
            <p>Give your teachers and school an overview of yourself and your learning goals so that you can get support from them.</p>
            <h4>Student profile</h4>
            <p>Ideally, a student should study hard, behave responsibly both at home and at school, and participate in extracurricular activities. The goal of every parent is for his child to become an ideal student who serves as a role model for other students.</p>
            <h4>Setting learning goals</h4>
            <p>Having goals is a valuable practice that can benefit anyone with a vision or dream for the future. When you are just starting out on life's grand adventure, now is the perfect time to develop your goal setting skills - not only will they serve you throughout your life but they will prepare you for the future.</p>
            <p>Although parents can encourage goal setting in their children-and should do so-the importance of goal setting justifies its inclusion in the school curriculum.</p>
          </div>
          <div *ngIf="helpTabName === 'Attendance'">
            <h3>Online Attendance</h3>
            <p>Online Attendance will be stored automatically in the system, so make sure you don't miss any classes.</p>
            <p>There are a number of features on the platform that are designed to make online classes more engaging for students and also to make teaching easier for teachers. Teachers take attendance in the system even if you're physically present.</p>
            
          </div>
          <div *ngIf="helpTabName === 'SBAssignment'">
            <h3>SCHOOL BASED LEARNING</h3>
            <p>You can find list/pending Assignments, Assessments, and Interactive online class. As soon as you complete the assignments the count will reduce from pending list. </p>
            <p>Each time a student completes an assignment, you'll receive points automatically. If you present a particular assignment from what you have learned, you will get additional points from the teacher based on your performance.</p>
          </div>
          <div *ngIf="helpTabName === 'HBAssignment'">
            <h3>HOME BASED LEARNING</h3>
            <p>You can find list/pending Assignments, Assessments, and Interactive online class. As soon as you complete the assignments the count will reduce from pending list. </p>
            <p>Each time a student completes an assignment, you'll receive points automatically. If you present a particular assignment from what you have learned, you will get additional points from the teacher based on your performance.</p>
          </div>
          <div *ngIf="helpTabName === 'CBAssignment'">
            <h3>COMMUNITY BASED LEARNING</h3>
            <p>You can find list/pending Assignments, Assessments, and Interactive online class. As soon as you complete the assignments the count will reduce from pending list. </p>
            <p>Each time a student completes an assignment, you'll receive points automatically. If you present a particular assignment from what you have learned, you will get additional points from the teacher based on your performance.</p>
          </div>
          <div *ngIf="helpTabName === 'SOBAssignment'">
            <h3>SOCIETY BASED LEARNING</h3>
            <p>You can find list/pending Assignments, Assessments, and Interactive online class. As soon as you complete the assignments the count will reduce from pending list. </p>
            <p>Each time a student completes an assignment, you'll receive points automatically. If you present a particular assignment from what you have learned, you will get additional points from the teacher based on your performance.</p>
          </div>
          <div *ngIf="helpTabName === 'PBAssignment'">
            <h3>List of Assignments, Assessments, Interactive Online Class.</h3>
            <p>You can find list/pending Assignments, Assessments, and Interactive online class. As soon as you complete the assignments the count will reduce from pending list. </p>
            <p>Each time a student completes an assignment, you'll receive points automatically. If you present a particular assignment from what you have learned, you will get additional points from the teacher based on your performance.</p>
          </div>
          <div *ngIf="helpTabName === 'InteractiveClasses'">
            <h3>Interactive live classes</h3>
            <p>Interactive live classes allow you to join a particular class at a particular time.</p>
            <p>Students can learn effectively by listening to lectures and taking notes. For today's students of all ages, learning involves combining different multimedia and sensory experiences. It is much more engaging for students to participate in interactive classes that involve choices, collaboration, and discussion. Compared to other learning methods, interactive learning helps students learn faster.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Feedback'">
            <h3>Student Feedback</h3>
             <p>Feedback assists all students in understanding the subject matter and provides clear guidance on how to improve their learning procedure. 
              Feedback can help students gain confidence, self-awareness, and enthusiasm for what they are learning.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Assessments'">
            <h3>Formative Assessments, Summative Assessments and Slip Test</h3>
            <p>Teachers should conduct assessments (Formative, Summative, Slip Tests, Any kind of Exam) and the students will learn more if they do so.</p>
            <p>Assessments are critical for improving student learning, improving teaching, and communicating evidence about learning processes to others. A student's progress will be updated annually in the portfolio.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Assignments'">
            <h3>Assignments (Watch, Listen, Read, and Do Assignments)</h3>
            <p>Assignments are given by teachers every day to help students better understand the topic they are studying.</p>
            <p>Students are given assignments by their teachers to complete in a defined timeframe. A student's assignment can also be referred to as work they receive as part of their education.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Watch Assignments'">
            <h3>Watch Assignments</h3>
            <p>Watch Assignments are given by teachers every day to help students better understand the topic they are studying.</p>
            <p>Students are given assignments by their teachers to complete in a defined timeframe. A student's assignment can also be referred to as work they receive as part of their education.</p>
            
          </div>
          <div *ngIf="helpTabName === 'ListenAssignments'">
            <h3>Listen Assignments</h3>
            <p>Listen Assignments are given by teachers every day to help students better understand the topic they are studying.</p>
            <p>Students are given assignments by their teachers to complete in a defined timeframe. A student's assignment can also be referred to as work they receive as part of their education.</p>
            
          </div>
          <div *ngIf="helpTabName === 'ReadAssignments'">
            <h3>Read Assignments</h3>
            <p>Read Assignments are given by teachers every day to help students better understand the topic they are studying.</p>
            <p>Students are given assignments by their teachers to complete in a defined timeframe. A student's assignment can also be referred to as work they receive as part of their education.</p>
            
          </div>
          <div *ngIf="helpTabName === 'DoAssignments'">
            <h3>Do Assignments</h3>
            <p>Do Assignments are given by teachers every day to help students better understand the topic they are studying.</p>
            <p>Students are given assignments by their teachers to complete in a defined timeframe. A student's assignment can also be referred to as work they receive as part of their education.</p>
            
          </div>

          <div *ngIf="helpTabName === 'Score'">
            <h3>Score</h3>
            <p>Good study habits develop because of daily homework. Children may also gain a sense of personal responsibility and increased independence because they learn to manage their time and expanding their organizational skills.</p>
            <p>Homework is beneficial because it helps students develop vital skills that they will use throughout their lives, especially self-regulation. Students must manage distractions, manage their time, delay gratification, and set goals when they do homework—all of these skills are incredibly important for success in life.</p>
            
          </div>
          <div *ngIf="helpTabName === 'LearningTime'">
            <h3>LearningTime</h3>
            <p>Good study habits develop because of daily homework. Children may also gain a sense of personal responsibility and increased independence because they learn to manage their time and expanding their organizational skills.</p>
            <p>Homework is beneficial because it helps students develop vital skills that they will use throughout their lives, especially self-regulation. Students must manage distractions, manage their time, delay gratification, and set goals when they do homework—all of these skills are incredibly important for success in life.</p>
            
          </div>

          <div *ngIf="helpTabName === 'HomeWork'">
            <h3>HomeWork</h3>
            <p>Good study habits develop because of daily homework. Children may also gain a sense of personal responsibility and increased independence because they learn to manage their time and expanding their organizational skills.</p>
            <p>Homework is beneficial because it helps students develop vital skills that they will use throughout their lives, especially self-regulation. Students must manage distractions, manage their time, delay gratification, and set goals when they do homework—all of these skills are incredibly important for success in life.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Point'">
            <h3>Benefits Of Point Based Learning</h3>
            <p>1. Motivates Students: Students are motivated by this plugin. The reward system could motivate students to complete their lessons or assignments. As a result, deeper learning and retention could be achieved.</p>
            <p>2. Enhances Overall Experience: Students are engaged and excited by the points and rewards system.</p>
            <p>3. Competition and collaboration are encouraged: Students are encouraged to compete among themselves using the point-based reward system. Also, this motivates teachers to create quality assignments.</p>
            <p>4. Provides a sense of accomplishment: After completing an assignment, students can feel a sense of accomplishment, which can keep them motivated.</p>
            <p>5. Flexible and Customizable Reward System: Points and Rewards offers a flexible and customizable system that makes learning and teaching more enjoyable.</p>
            <p>6. User-Friendly: The Hybrid Learning Platform has an extremely user-friendly interface and step-by-step instructions making it easy to set up.</p>
            <p>7. A Redeemable Points program: Students can use their points to purchase other activities.</p>
          </div>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Cancel</button>
      </div>
    </div>
  </div>
