import { Component, OnInit, OnChanges} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { PaymentInfo } from '../shared/user.model';
import { UserService } from '../shared/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-payments',
    templateUrl: './edit-payments.component.html',
    styleUrls: ['./edit-payments.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, TranslateModule]
})
export class EditPaymentsComponent implements OnInit {
  list:any[];
  yearList:any=[];
  termList:any=[];
  classList:any=[];
  CUSTOMCONST:any=CUSTOMCONST;
  
  constructor(public service:UserService, private commonServ:CommonService, public modalService:BsModalService, 
    private notificationServ:NotificationService) { 
    this.getpaymentview();
    // console.log(this.modalService.config.initialState.list[0].id);
    // this.service.paymentView.Paymentinfo=new PaymentInfo();
    
  }
  ngOnChanges(): void {
    this.getpaymentview();
  }
  ngOnInit(): void {
    
  }
  getpaymentview(){
    this.service.getpaymentview(this.modalService.config.initialState.list[0].id).subscribe((res:any)=>{
      this.service.paymentView = res;
      this.yearList = res.AcademicYearList;
      this.termList = res.TermList;
      this.classList = res.ClassList;
      
    })
  }
  addPaymentInfo(){
    if(this.list[0].id >0)
    {
      this.service.paymentView.IsSaved = true;
    }
    this.service.savepaymentinfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        if(this.list[0].id >0)
        {
          this.notificationServ.ShowInfo('Data Successfully Updated!');
        }
        else{
          this.notificationServ.ShowInfo('Successfully Saved!');
        }
        
      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      
    })
  }
  changeAcademicYear(){
    if(this.service.paymentView.PaymentInfo.AcademicYearId > 0){
      this.gettermlistbyacademicyearid();
    }
    else{
      this.termList = [];
    }
  }
  gettermlistbyacademicyearid(){
    this.service.gettermlistbyacademicyearid(this.service.paymentView.PaymentInfo.AcademicYearId).subscribe((res:any)=>{
      this.termList = res;
    })
  }
  changeTerm(){
    console.log(this.service.paymentView.PaymentInfo.TermId);
    
  }
}