<!-- For demo purpose -->
<div class="profile-group">
    <img class="shadow" [src]="userData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">

    <p class="shadow">{{userData.UserInfo.FullName?userData.UserInfo.FullName:''}}</p>
</div>
<button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i
        class="fa fa-caret-down"></i></button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let child of userService.formData.ParentInfo.ChildrenList"
        (click)="selectChild(child.Id)">{{child.Name}}</button>
    <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
</mat-menu>

<div class="container-fluid bg-light pb-2 min-height-100vh">
    <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">

            <!-- Profile widget -->
            <div class="bg-white shadow overflow-hidden">
                <div class="px-0 pt-3  bg-dark position-relative bg-s-cover min-h-300px"
                    [style.background-image]="'url(' + (userService.formData.UserInfo.CroppedProfileFilepath?userService.formData.UserInfo.CroppedProfileFilepath:'') + ')'">
                    <div class="media profile-header  sm-flex-wrap px-3">
                        <div class="profile mr-3 sm-m-auto text-center sm-w-100">
                            <img [src]="userService.formData.UserInfo.CroppedFilepath?userService.formData.UserInfo.CroppedFilepath:'../../../assets/images/user/default.png'"
                                width="130" class="rounded-circle border border-w-5 border-light mt-2">
                    <div>
                        <a (click)="editProfile(userService.formData.UserInfo.UserId)"
                        class="btn btn-link btn-sm btn-block"
                        [ngClass]="{'pe-none':!userService.formData.UserInfo.IsEditAllowed}">Edit profile</a>
                        <a (click)="openHelpTooltip('EditProfile')" class="position-relative"><mat-icon>help_outline</mat-icon></a>
                    </div>
                        </div>
                        <div class="media-body text-white xs-pb-20px">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mt-0 mb-0">{{userService.formData.UserInfo.FirstName}}
                                        {{userService.formData.UserInfo.MiddleName}}
                                        {{userService.formData.UserInfo.Surname}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-5">
                                    <h4 class="mt-0 mb-0">{{userService.formData.UserInfo.Address}}
                                        {{userService.formData.UserInfo.Street}}</h4>
                                </div>
                                <!-- <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{userService.formData.StudentInfo.Class}}</h4>
                                </div> -->
                                <!-- <div class="col-xs-6 col-md-2">
                                    <div class="form-group mb-0">
                                        <select class="form-control form-control-sm appearance-auto"
                                            name="selectedChileId" [(ngModel)]="selectedChileId"
                                            (change)="changeStudent()">
                                            <option
                                                *ngFor="let children of userService.formData.ParentInfo.ChildrenList"
                                                [value]="children.Id">{{children.Name}}</option>
                                                <option [value]="-1">Link a child to my account</option>
                                        </select>
                                    </div>

                                </div> -->
                                <div class="col-xs-6 col-md-2">
                                    <div class="form-group mb-0">
                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0">
                                           <a class="cursor-pointer d-block btn btn-primary btn-sm rounded-0 h-100" (click)="userLogHistory()">Log History</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{userService.formData.UserInfo.About}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="d-flex xs-flex-wrap fix-bottom">

                        <div
                            class="d-flex justify-content-end text-center r-0 b-0 bg-light-transparent-05 profile-footer xs-justify-content-center">
                            <ul class="list-inline mb-0">

                                <li class="list-inline-item p-2 border-right border-light" (click)="openStudentFeeTooltip(selectedChileId)">
                                    <h5 class="font-weight-bold mb-0 d-block">{{deuPayment}}</h5><small class="text-muted">
                                        <i class="fa fa-user-circle-o mr-1"></i>Due Payment</small>
                                </li>
                                <li class="list-inline-item p-2 border-right border-light">
                                    <h5 class="font-weight-bold mb-0 d-block">{{points}}</h5><small class="text-muted">
                                        <i class="fa fa-user-circle-o mr-1"></i>Total Points</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <section>

                    <div class="d-flex justify-content-center">
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group1 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <fa-icon [icon]="calendarIcon"></fa-icon>
                                        </div>
                                        <div class="content">
                                            <p>Agenda</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group2 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <i class="fa fa-user-times" aria-hidden="true"></i>
                                        </div>
                                        <div class="content">
                                            <p>Absences</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group3 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <i class="fa fa-fw fa-book"></i>
                                        </div>
                                        <div class="content">
                                            <p>Home Work</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-1 shadow bg-group4">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <i class="fa fa-fw fa-signal"></i>
                                        </div>
                                        <div class="content">
                                            <p class="">Result</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group5 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <fa-icon [icon]="financeIcon"></fa-icon>
                                        </div>
                                        <div class="content">
                                            <p>Finance</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group6 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <fa-icon [icon]="folderIcon"></fa-icon>
                                        </div>
                                        <div class="content">
                                            <p>Folder</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group7 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <fa-icon [icon]="messageIcon"></fa-icon>
                                        </div>
                                        <div class="content">
                                            <p>Message</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-1 shadow bg-group8">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <i class="fa fa-fw fa-info-circle"></i>
                                        </div>
                                        <div class="content">
                                            <p class="">Resources</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                        <div class="mb-3 p-1 small-group-item">
                            <mat-card class="dasbord-info-card p-0 pt-2 bg-group9 shadow">
                                <div class="grouup-body">
                                    <div class="text-center">
                                        <div class="icon">
                                            <fa-icon [icon]="editIcon"></fa-icon>
                                        </div>
                                        <div class="content">
                                            <p>Registration</p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                    </div>
                    </section> -->
                <section class="course-details-content-section">
                    <div class="container-fluid p-0">
                        <mat-tab-group animationDuration="0ms" class="bg-transparent border-0"
                            (selectedTabChange)="activeTabChange($event)">
                            <mat-tab (click)="activeTabChange('IsAcademics')" label="Academics"
                                *ngIf="userService.formData.ParentInfo.IsAcademics">
                                <ng-template mat-tab-label>
                                    Academics
                                    <!-- <div class="icon-group">
                                    <mat-icon>question_answer</mat-icon> ({{selAssignmentCount}})
                                    <mat-icon>assignment</mat-icon> ({{selQstCount}})
                                    <mat-icon>event</mat-icon> ({{selEventCount}})
                                </div> -->
                                </ng-template>
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Academics is more than just schooling - it is a lifelong process through which a
                                        learner constantly drives their own development. Academics - like learners
                                        themselves - needs to be holistic, dynamic, responsive, open to new ideas &
                                        perspectives. Today’s education systems predominantly seem to focus on cerebral
                                        development at the expense of all other aspects of a person's development.
                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab (click)="activeTabChange('IsHumanityLife')" label="SEL"
                                *ngIf="userService.formData.ParentInfo.IsHumanityLife">
                                <ng-template mat-tab-label>
                                    SEL
                                    <!-- <div class="icon-group">
                                    <mat-icon>question_answer</mat-icon> ({{selAssignmentCount}})
                                    <mat-icon>assignment</mat-icon> ({{selQstCount}})
                                    <mat-icon>event</mat-icon> ({{selEventCount}})
                                </div> -->
                                </ng-template>
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Social and Emotional Learning. In today’s challenging and changing learning
                                        environments, Social and Emotional Learning must be one of the main goals of our
                                        formal education systems.<br /><br />
                                        During the COVID-19 pandemic, school closures have forced students to face
                                        remote learning and isolation from their normal social settings. The reduction
                                        of social interactions, physical activities, and play combined with increased
                                        emotional stress have affected students. Loneliness and depression are on the
                                        rise among all ages, but especially young people. Through SEL we cover to teach
                                        about Humanity, Character Building, and SEL.
                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab (click)="activeTabChange('IsSkills')"
                                *ngIf="userService.formData.ParentInfo.IsSkills" label="Life Skills">
                                <ng-template mat-tab-label>
                                    Life Skills
                                    <!-- <div class="icon-group">
                                    <mat-icon>question_answer</mat-icon> ({{selAssignmentCount}})
                                    <mat-icon>assignment</mat-icon> ({{selQstCount}})
                                    <mat-icon>event</mat-icon> ({{selEventCount}})
                                </div> -->
                                </ng-template>
                                <div class="mt-3">
                                    <p class="tab-content">
                                        In today’s fast-moving knowledge economy and automated labor market, students
                                        experience uncertainty in planning their future. They must learn more about
                                        themselves and the world of work and align their life and career decision with
                                        their age, developmental stage, and life goals.<br /><br />
                                        While Developing our life skills curriculum, we focused on the importance and
                                        the benefits of integrating life skill education in the school curriculum to
                                        help students develop the proper core life skills like Pottery, Sewing,
                                        Gardening, Electronics, and Robotics. as they are the most important building
                                        blocks for our societies.
                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab (click)="activeTabChange('IsLearnOutOfBox')"
                                *ngIf="userService.formData.ParentInfo.IsLearnOutOfBox" label="Outside the Box">
                                <ng-template mat-tab-label>
                                    Outside the Box
                                    <!-- <div class="icon-group">
                                    <mat-icon>question_answer</mat-icon> ({{selAssignmentCount}})
                                    <mat-icon>assignment</mat-icon> ({{selQstCount}})
                                    <mat-icon>event</mat-icon> ({{selEventCount}})
                                </div> -->
                                </ng-template>
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Because kids deserve authentic, individualized, and powerful learning
                                        experiences!<br /><br />
                                        We believe in the capacity of children to learn without exterior motivation,
                                        grade levels, or grading. Children are naturally curious and love to explore,
                                        experience, experiment, create, and learn.<br /><br />
                                        Textbooks, worksheets, lectures, and sitting in a crowded classroom for multiple
                                        hours a day is a slow learning process that approaches futile and rarely elicits
                                        positive feelings toward learning. Exploring, playing, questioning, and
                                        experimenting is not only a more enjoyable way to learn, but it is a more
                                        powerful and effective way to learn.
                                    </p>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </section>


            </div><!-- End profile widget -->

        </div>

        <section class="mt-3 p-0">
            <div class="row m-0 justify-content-c xs-flex-wrap">
                <div class="p-2 shadow bg-white event-group">
                    <div class="mb-0 w-100">
                        <label>Assessments <a (click)="openHelpTooltip('Assessments')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
                        <ag-grid-angular #agGrid style="width: 100%; height:248px;min-height: 200px;max-height: 248px;"
                            class="ag-theme-alpine" id="myGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowData]="rowData"
                            (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"
                            [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                    </div>
                </div>

                <div class="ml-15px p-0 inline-calander-group xs-ml-0px xs-mt-3">
                    <bs-datepicker-inline class="inlinedate" name="inlineDate" [(ngModel)]="inlineDate"
                        [bsValue]="inlineDate" [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
                    </bs-datepicker-inline>
                </div>
            </div>
        </section>
        <section class="mt-3 p-0 shadow bg-white">
            <div class="row m-0 pt-3">
                <div class="col-4 pb-3" *ngIf="ILClassGraph.length > 0">
                    <div class="border">
                        <div class="google-chart-group">
                            <google-chart [title]="ILClassGraphTitle" [type]="ILClassGraphType" [data]="ILClassGraph"
                                [columns]="ILClassGraphCol" [options]="ILClassGraphOption" [width]="graphWidth"
                                [height]="graphHeight">
                            </google-chart>
                        </div>

                    </div>
                </div>
                <div class="col-4 pb-3" *ngIf="assessmentsGraph.length > 0">
                    <div class="border">
                        <div class="google-chart-group">
                            <div class="google-chart-group">
                                <google-chart [title]="assessmentsGraphTitle" [type]="assessmentsGraphType"
                                    [data]="assessmentsGraph" [columns]="assessmentsGraphCol"
                                    [options]="assessmentsGraphOption" [width]="graphWidth" [height]="graphHeight">
                                </google-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 pb-3" *ngIf="assignmentsGraph.length > 0">
                    <div class="border">
                        <google-chart [title]="assignmentsGraphTitle" [type]="assignmentsGraphType"
                            [data]="assignmentsGraph" [columns]="assignmentsGraphCol" [options]="assignmentsGraphOption"
                            [width]="graphWidth" [height]="graphHeight">
                        </google-chart>
                    </div>
                </div>
                <div class="col-4 pb-3" *ngFor="let stuanalysisGraph of stuanalysisgraph; let i=index">
                    <div class="border">
                        <google-chart [title]="stuanalysisGraph.Key" [type]="stuAnalysisGraphType"
                            [data]="graphdata[i]" [columns]="stuAnalysisGraphCol" [options]="stuAnalysisGraphOption"
                            [width]="graphWidth" [height]="graphHeight">
                        </google-chart>
                    </div>
                </div>
            </div>
        </section>

    </div>

    <section class="mt-3">
        <div class="row pt-2 mb-3 m-0 bg-white shadow" *ngIf="watchList.length>0 ">
            <label>Watch Assignments <a (click)="openHelpTooltip('Assignments')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 min-height-150 mb-3"
                *ngFor="let watch of watchList">
                <div layout="column" flex>

                    <div class="border p-1 "
                        [ngClass]="{'border-primary':watch.Status == 'New', 'border-success': watch.Status == 'Completed', 'border-danger':watch.Status == 'Expired'}">
                        <a class="cursor-pointer assingment-item" (click)="openModel(watch)">
                            <img class="img-fluid z-depth-1" src="../../../assets/media/videos/watc_hlist_poster.jpeg"
                                alt="video">
                            <div *ngIf="watch.Result != ''" [title]="watch.Comments" class="stu-result">
                                <p>{{watch.Result}}</p>
                            </div>
                        </a>
                        <span class="d-block w-100 bg-theam2 pt-2 break-word">{{watch.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2 mb-3 m-0 bg-white shadow" *ngIf="listenList.length>0 ">
            <label>Listen Assignments <a (click)="openHelpTooltip('Assignments')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
            <div *ngFor="let listen of listenList; let i=index"
                class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3 min-height-150">
                <div class="h-100">
                    <div class="border p-1 "
                        [ngClass]="{'border-primary':listen.Status == 'New', 'border-success': listen.Status == 'Completed', 'border-danger':listen.Status == 'Expired'}">
                        <a class="assingment-item cursor-pointer" (click)="openModel(listen)">
                            <img src="../../../assets/images/common/Listen_think_explore_present.jpeg" class="cursor-pointer "
                                width="100%" />

                            <div *ngIf="listen.Result != ''" [title]="listen.Comments" class="stu-result">
                                <p>{{listen.Result}}</p>
                            </div>
                        </a>

                        <span class="d-block w-100 bg-theam2 pt-2 break-word">{{listen.Name}}</span>
                    </div>

                </div>
            </div>
        </div>

        <div class="row pt-2 mb-3 m-0 bg-white shadow" *ngIf="readList.length>0 ">
            <label>Read Assignments <a (click)="openHelpTooltip('Assignments')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
            <div *ngFor="let read of readList"
                class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                <!-- <a [href]="read.Url" target="_blank" ><img src="../../../assets/media/doc/docx.png" class="max-height-150px m-auto" /></a> -->
                <div class="border p-1 "
                    [ngClass]="{'border-primary':read.Status == 'New', 'border-success': read.Status == 'Completed', 'border-danger':read.Status == 'Expired'}">
                    <a class="cursor-pointer assingment-item" (click)="openModel(read)">
                        <img src="../../../assets/images/common/read_think_explore_present.jpeg" class="max-height-150px m-auto" />
                        <div *ngIf="read.Result != ''" [title]="read.Comments" class="stu-result">
                            <p>{{read.Result}}</p>
                        </div>
                    </a>

                    <span class="d-block w-100 bg-theam2 pt-2 break-word">{{read.Name}}</span>
                </div>
            </div>
        </div>
        <div class="row pt-2 mb-3 m-0 bg-white shadow" *ngIf="doList.length>0 ">
            <label>Do Assignments <a (click)="openHelpTooltip('Assignments')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
            <div *ngFor="let do of doList"
                class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                <div class="border p-1 "
                    [ngClass]="{'border-primary':do.Status == 'New', 'border-success': do.Status == 'Completed', 'border-danger':do.Status == 'Expired'}">
                    <a class="cursor-pointer assingment-item" (click)="openModel(do)">
                        <img src="../../../assets/images/common/do_think_explore_present.jpeg" class="max-height-150px m-auto" />
                        <div *ngIf="do.Result != ''" [title]="do.Comments" class="stu-result">
                            <p>{{do.Result}}</p>
                        </div>
                    </a>
                    <span class="d-block w-100 bg-theam2 pt-2 break-word">{{do.Name}}</span>
                </div>
            </div>
        </div>
        <div class="row pt-2 mb-3 m-0 bg-white shadow" *ngIf="homeWorkList.length>0 ">
            <label>Home work <a (click)="openHelpTooltip('HomeWork')" class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
            <div *ngFor="let homeWork of homeWorkList"
                class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                <div class="border p-1 "
                    [ngClass]="{'border-primary':homeWork.Status == 'New', 'border-success': homeWork.Status == 'Completed', 'border-danger':homeWork.Status == 'Expired'}">
                    <a class="cursor-pointer assingment-item" (click)="openModel(homeWork)">
                        <img src="../../../assets/images/common/homework.jpg" class="max-height-150px m-auto" />
                        <div *ngIf="homeWork.Result != ''" [title]="homeWork.Comments" class="stu-result">
                            <p>{{homeWork.Result}}</p>
                        </div>
                    </a>

                    <span class="d-block w-100 bg-theam2 pt-2 break-word">{{homeWork.Name}}</span>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container-fluid bg-theam-body pb-5">
            <div class="row justify-content-center">
                <div class="col-10">
                    <div class="row mt-5 align-items-center">
                        <div class="col-12 mb-3">
                            <div class="section-header">
                                <h1 class="d-block title pt-1 pb-1 text-center theam-color-8">Feedback <a (click)="openHelpTooltip('HomeWork')" class="position-relative"><mat-icon>help_outline</mat-icon></a></h1>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 h-100">
                            <owl-carousel-o class="multy-item-carousel feedbackOptions " [options]="mobileAppOptions">
                                <ng-container *ngFor="let feedbacks of feedbackList; let i=index">
                                    <ng-template carouselSlide>
                                        <div class="row justify-content-center pb-3 pt-3">
                                            <div class="col-5 mb-0">
                                                <div class="row">
                                                    <div class="col-3 pr-0">
                                                        <img [src]="feedbacks.CreatedByImageUrl?feedbacks.CreatedByImageUrl:'../../../assets/images/user/default.png'" alt="" class="rounded-circle border border-w-5 border-light">
                                                    </div>
                                                    <div class="col-9 pl-0 mt-2">
                                                        <span
                                                            class="d-block h6 font-weight-500 theam-color-333">{{feedbacks.CreatedByName}}</span>
                                                        <p class="theam-color-333"><span class="mr-1">
                                                                <mat-icon>calendar_today</mat-icon>
                                                            </span>{{feedbacks.CreatedOn}}</p>
                                                    </div>
                                                </div>
                                                <p class="theam-color-333">
                                                    {{feedbacks.Feedback}}
                                                </p>

                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
