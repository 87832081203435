export class StudentBookInfo{
    
StudentBookDataId:number=0;    
BookName:string;
Author:string;
Date:string;
StartDate:string;
EndDate:string;  
SourceId:number;
Outcome:string;
Hours:number;
IsActive:boolean;
DocumentIdList:Array<any>;
FileInfoList:FileInfoList;
StudentBookViewInfo: any;

}
export class StudentBookViewInfo{
    StudentBookInfo:StudentBookInfo;
    BookSourceInfo:BookSourceInfo;
    IsSave:boolean;
    ErrorMessage:string;
}

export class FileInfoList{
    Value: string;
    Result:string;
    Result1:string;
    
}

export class BookSourceInfo {
    Id:number = 0;
    Name:string;   
}

export class StudentTravelInfo {

    StudentTravelDataId: number = 0;
    PlaceVisited: string;
    Place: string;
    Date: string;
    StartDate: string;
    EndDate: string;
    TravelWithId: number;
    Outcome: string;
    Hours: number;
    IsActive: boolean;
    DocumentIdList: Array<any>;
    FileInfoList: FileInfoList;
    StudentTravelViewInfo: any;

}
export class StudentTravelViewInfo{
    StudentTravelInfo:StudentTravelInfo;
    TravelWithInfo:TravelWithInfo;
    IsSave:boolean;
    ErrorMessage:string;
}

export class TravelWithInfo {
    Id: number = 0;
    Name: string;
}

export class StudentHobbyInfo {

    StudentHobbyDataId: number = 0;
    Name: string;    
    StartDate: string;
    EndDate: string;
    SourceId:number=0;   
    Outcome: string;
    Hours: number;
    IsActive: boolean;
    DocumentIdList: Array<any>;
    FileInfoList: FileInfoList;
    StudentHobbyViewInfo: any;

}
export class StudentHobbyViewInfo {
    StudentHobbyInfo:StudentHobbyInfo;
    HobbySourceInfo:HobbySourceInfo;
    IsSave:boolean;
    ErrorMessage:string;
}
export class HobbySourceInfo {
    Id: number = 0;
    Name: string;
}



export class StudentAchievementInfo {

    StudentAchievementDataId: number = 0;
    CompetitionName: string;    
    StartDate: string;
    EndDate: string;
    CompetitionTypeId:number=0;   
    Achievement: string;  
    IsActive: boolean;
    DocumentIdList: Array<any>;
    FileInfoList: FileInfoList;
    StudentAchievementViewInfo: any;

}
export class StudentAchievementViewInfo {
    StudentAchievementInfo:StudentAchievementInfo;
    AchievementSourceInfo:AchievementSourceInfo;
    IsSave:boolean;
    ErrorMessage:string;
}
export class AchievementSourceInfo {
    Id: number = 0;
    Name: string;
}
