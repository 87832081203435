<div class="login-form d-flex align-items-center">
   <div class="container-fluid">

       <div class="row justify-content-c">
        <h1 class="display-4 text-center mb-100px">{{LoginMessage}}</h1>
           <div class="max-w-500px col-xs-12 col-sm-8 col-md-5 bg-white shadow p-4 ">
               <h1 class="display-4 text-center">{{'Login'| translate}}</h1>
               <form novalidate #form="ngForm" (submit)="onLogin(form)" autocomplete="off">
                   <div class="form-row">
                       <div class="form-group full-group mb-0">
                           <label>{{'UserId'| translate}}</label>
                           <input class="form-control form-control-lg" placeholder="{{'UserId'| translate}}" name="userlogin" 
                           [(ngModel)]="service.formData.UserInfo.UserLogin" required minlength="5" class.invalid="UserLogin.invalid && UserLogin.touched">
                       </div>
                       <div class="form-group full-group mb-0">
                           <label>{{'Password'| translate}}</label>
                           <input class="form-control form-control-lg" type="password" placeholder="********" name="Password" #Password="ngModel"
                           [(ngModel)]="service.formData.UserInfo.Password" required minlength="5" class.invalid="Password.invalid && Password.touched">
                       </div>
                   </div>
                   <div class="form-row mt-3">
                       <div class="form-group full-group mb-0">
                           <button class="btn btn-primary btn-lg btn-block form-control bd-dadios-0" type="submit" [disabled]="form.invalid">{{'Login'| translate}}</button>
                       </div>
                       <div class="form-group full-group mb-0 mt-2">
                        <p class="m-0">Not Registered? <a routerLink="/self-register/">Sign Up</a></p>
                    </div>
                   </div>
                   <div class="form-row mt-3">
                    <div class="form-group">
                        <!-- <div id="g_id_onload"
         data-client_id="115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com"
         data-login_uri="http://localhost:4200/login"
         data-auto_prompt="false">
      </div>
      <div class="g_id_signin"
         data-type="standard"
         data-size="large"
         data-theme="outline"
         data-text="sign_in_with"
         data-shape="rectangular"
         data-logo_alignment="left">
      </div> -->


      <div id="g_id_onload"
     data-client_id="115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="popup"
     data-callback="googleauthcompleted"
     data-auto_prompt="false">
</div>

<div class="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div>
                    </div>
                   </div>

               </form>


           </div>

       </div>
   </div>
</div>
