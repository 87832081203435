import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuestionViewData ,QuestionData } from '../shared/question.model';
import { QuestionService } from '../shared/question.service';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
// import { CountdownConfig } from 'ngx-countdown';
import { countUpTimerConfigModel, timerTexts, CountupTimerService,
  // NgxTimerModule
 } from 'ngx-timer';
import { ConfirmComponent } from '../common/confirm.component';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
@Component({
    selector: 'app-quez',
    templateUrl: './quez.component.html',
    styleUrls: ['./quez.component.scss'],
    standalone: true,
    // imports: [FormsModule, MatCardModule, NgIf, NgxTimerModule, NgFor, MatRadioModule, MatCheckboxModule, Ng7LargeFilesUploadLibModule, TranslateModule]
    imports: [FormsModule, MatCardModule, NgIf,  NgFor, MatRadioModule, MatCheckboxModule,  TranslateModule]

})
export class QuezComponent implements OnInit {
  list: any[] = [];
  questiontypelist: any = [];
  IsStart:boolean;
  noofQuestions: number;
  currentQuestionNo: number;
  public timeDuretion:number | 0;
  public questionInfo :QuestionData;
  public questionList:any=[];
  totalRow: number;
  testConfig:countUpTimerConfigModel;

  totalTime:number=0;
  interval;
  CUSTOMCONST:any;
  isprocessing : boolean = false;
  lastdocumentId:number;
  constructor(public bsModalRef: BsModalRef, public questionservice:QuestionService,
    private notificationServ:NotificationService,public commonServ:CommonService,
    public countupTimerService:CountupTimerService,public modalService:BsModalService,
    private _route : ActivatedRoute) {
    this.commonServ.OnConfirm$.subscribe(
      item => {
        this.saveStudentAnswerInner();
          }
    );
    this.CUSTOMCONST = CUSTOMCONST;

  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params)=>{
      console.log(params);

      //change the value of showRoutes based on your requirements
     });
     debugger;
    this.questionservice.getquestioninfo(this.list[0].id).subscribe((res:QuestionViewData) => {


      this.questionservice.formData = res;

    })

    this.commonServ.getMlListInfo("MLQuestionType").subscribe((res: any) => {
      this.questiontypelist = res;
      // this.questionInfo.QuestionNo = 0;
      this.currentQuestionNo = 0;
    });


    this.testConfig = new countUpTimerConfigModel();
    //custom class
    this.testConfig.timerClass  = 'test_Timer_class';

    //timer text values
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " "; //default - ss


    this.commonServ.getlastdocumentid().subscribe((res : any) => {
      this.lastdocumentId = res;
       });

  }
  startQuiz(){
    this.IsStart = true;
    // this.timeDuretion = this.timeDuretion;
    this.currentQuestionNo = 1;
    this.questionInfo = this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1];
    this.questionList = this.questionservice.formData.QuestionInfo;
    this.noofQuestions = this.questionservice.formData.QuestionMasterInfo.NoOfQuestions;

    this.startTimer();

  }
  nextQuestion(){

    this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;
    this.currentQuestionNo = this.currentQuestionNo +1;
    // this.currentQuestionNo = this.currentQuestionNo +1;

    this.questionInfo = this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1];
  }
  prevQuestion(){
    this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;
    this.currentQuestionNo = this.currentQuestionNo -1;
    this.questionInfo = this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1];
  }



  addStudentAnswers(){

    const initialState = {
      list: [
        {id:'confirm'},
        {message:"Are you sure want to submit your answers?"}
      ]
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent,{id : CUSTOMCONST.CONFIRM_MODAL, initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});



  }
  saveStudentAnswerInner(){
    if(!this.isprocessing){
      this.isprocessing = true;
    // alert('triger');
    var totalTime = this.countupTimerService.timerValue.hours+":"+this.countupTimerService.timerValue.mins+":"+this.countupTimerService.timerValue.seconds;
    this.questionservice.formData.QuestionMasterInfo.TotalTime= totalTime;
    this.questionservice.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;

    this.questionservice.addStudentAnswers().subscribe((res:QuestionViewData)=>{
      this.isprocessing = false;
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL);
        // this.bsModalRef.hide();
      }
    });
  }
  }
  startTimer() {


    // let cdate = new Date();
    let cInterval =new Date();
    cInterval.setHours(cInterval.getHours());
   this.countupTimerService.startTimer(cInterval);
    // this.countupTimerService.startTimer();
  }
  largefileschange(event){
  }
  filesuploaded(fileInfo){
    if(fileInfo && fileInfo.IsSaved)
    this.questionservice.formData.QuestionMasterInfo.ResponseFileId = fileInfo.UserDocumentId;

    this.commonServ.getnewlyaddeddocumentids(this.lastdocumentId).subscribe((res : any) => {
      var newlyAddedDocumentList = res;
      if(res && res.length > 1){
      var docId = '';
      newlyAddedDocumentList.forEach(element => {
        if(docId){
          docId = docId+','+element;
        }
        else {
          docId = element;
        }
      });
      this.questionservice.formData.QuestionMasterInfo.ResponseFileIds = docId;
    }
    else{

    }

       });
    // if(!this.questionservice.formData.QuestionMasterInfo.ResponseFileIds)
    // this.questionservice.formData.QuestionMasterInfo.ResponseFileIds = fileInfo.UserDocumentId.toString();
    // else
    // this.questionservice.formData.QuestionMasterInfo.ResponseFileIds = this.questionservice.formData.QuestionMasterInfo.ResponseFileIds +','+ fileInfo.UserDocumentId.toString();
  }

  changeMC(length, index ) {
    var i=0;
    for(i; i<length; i++ ){
      if(i!=index)
      {
        this.questionInfo.QAnswerList[i].StrAnswer = '';
      }
      else
      {
        this.questionInfo.QAnswerList[index].StrAnswer = this.questionInfo.QAnswerList[index].StrAnswer;
      }

    }
  }
  changeTF(){
    this.questionInfo.QAnswerList[1].IsAnswer = !this.questionInfo.QAnswerList[0].IsAnswer;
  }
}
