import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import{StudentAchievementInfo, StudentAchievementViewInfo, StudentBookInfo, StudentBookViewInfo, StudentHobbyInfo, StudentHobbyViewInfo, StudentTravelInfo, StudentTravelViewInfo}from './achievements.model'
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class achievements {
  studentid: number

  constructor(private http:HttpClient) {
    this.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
    this.formDataVisitingPlace.StudentTravelInfo = new  StudentTravelInfo();
    this.formDataHobby.StudentHobbyInfo = new  StudentHobbyInfo();
    this.formDataAchievement.StudentAchievementInfo = new  StudentAchievementInfo();
  }
  readonly baseUrl = CONFIG.BASE_URL+'api/user';

  formDataReadingBooks:StudentBookViewInfo = new StudentBookViewInfo();
  formDataVisitingPlace:StudentTravelViewInfo = new StudentTravelViewInfo();
  formDataHobby:StudentHobbyViewInfo = new StudentHobbyViewInfo();
  formDataAchievement:StudentAchievementViewInfo = new StudentAchievementViewInfo();
  
  getStudentBookInfoList(studentId:number){
    var config ={
      params:{
        studentId:studentId,
      }
    }
    return this.http.get(this.baseUrl+'/getStudentBookInfoList',config);
  }

  saveStudentBookInfo(){
    return this.http.post(this.baseUrl+'/saveStudentBookInfo',this.formDataReadingBooks);
  }
 

  getStudentBookInfo(stdBookId:number){
    var config = {
     params: {
      stdBookId: stdBookId,
      
     }
   };
   return this.http.get(this.baseUrl+'/getStudentBookInfo',config);
  }
   
  getStudentTravelInfo(stdTravelId :number){
    var config = {
     params: {
      stdTravelId : stdTravelId,
      
     }
   };
   return this.http.get(this.baseUrl+'/getStudentTravelInfo',config);
  }
  getStudentTravelInfoList(studentId :number){
    var config ={
      params:{
        studentId :studentId,
      }
    }
    return this.http.get(this.baseUrl+'/getStudentTravelInfoList',config);
  }

  saveStudentTravelInfo(){
    return this.http.post(this.baseUrl+'/saveStudentTravelInfo',this.formDataVisitingPlace);
  }
 
  getStudentHobbyInfo(stdHobbyId :number){
    var config = {
     params: {
      stdHobbyId : stdHobbyId,
      
     }
   };
   return this.http.get(this.baseUrl+'/getStudentHobbyInfo',config);
  }
  getStudentHobbyInfoList(studentId :number){
    var config ={
      params:{
        studentId :studentId,
      }
    }
    return this.http.get(this.baseUrl+'/getStudentHobbyInfoList',config);
  }
   
  saveStudentHobbyInfo(){
    return this.http.post(this.baseUrl+'/saveStudentHobbyInfo',this.formDataHobby);
  }
 

  
  getStudentAchievementInfo(stdAchievementId:number){
    var config = {
     params: {
      stdAchievementId:stdAchievementId ,
      
     }
   };
   return this.http.get(this.baseUrl+'/getStudentAchievementInfo',config);
  }
  getStudentAchievementInfoList(studentId:number){
    var config ={
      params:{
        studentId:studentId,
      }
    }
    return this.http.get(this.baseUrl+'/getStudentAchievementInfoList',config);
  }
   
  saveStudentAchievementInfo (){
    return this.http.post(this.baseUrl+'/saveStudentAchievementInfo',this.formDataAchievement);
  }

   
  // deleteQuestion(questionMasterId){
  // var questionMasterinfo = new QuestionMaster();
  // questionMasterinfo.IsActive = false;
  // questionMasterinfo.QuestionMasterId = questionMasterId;
  // return this.http.post(this.baseUrl+'/deletequestioninfo',questionMasterinfo);
  // }

}
