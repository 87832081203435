import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { UserService } from '../shared/user.service';
import { StudentEmpathyInfo } from '../shared/user.model';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { formatDate, NgIf, NgFor } from '@angular/common';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-edit-empathy',
  templateUrl: './edit-empathy.component.html',
  styleUrls: ['./edit-empathy.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TranslateModule]
})
export class EditEmpathyComponent implements OnInit {
  list: any;
  ProfileName: any; 
  min : String;
  max:string;
  EmpathyInfo : any;

  constructor(public service:UserService,private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService) { }

  ngOnInit(): void {
    this.EmpathyInfo = new StudentEmpathyInfo();
    if(this.list[0].action == 'Edit'){
      this.EmpathyInfo = this.service.studentProfileInfoView.EmpathyInfo[this.list[0].idx];
    }
  }
  formatLabel(value: number): string {

    return `${value}`;
  }
  selectRating(event){
    var rating : any = event.target.value;
    var arr = rating.split("/");
    this.min = arr[0];
    this.max = arr[1];
  }
  saveEmpathyInfo(){
    this.service.StudentEmpathyViewInfo.StudentEmpathyInfo.push(this.EmpathyInfo);
    this.service.saveStudentEmpathyInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Save Successfull");
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }      
    });
  }
  updateProfileInfo(){
    // SPDId
    this.service.saveStudentProfileInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Save Successfull");
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }
      
    });
  }
  closeModel(){
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
  }

}
