export class Attendance {
    IsSaved: boolean;
    ErrorMessage: string;
    AttendanceInfo:AttendanceInfo;
    StudentListInfo:Array<StudentListInfo>
}
export class AttendanceInfo{
    ClassId: number;
        Date: string;
        AppointmentId: number;
}
export class StudentListInfo{
    Id:number;
    Name:string;
    ProfileImageUrl:string;
    AttendanceTypeId:number;
}