import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomGrideCelComponent } from 'src/app/custommenu/custom-gride-cel/custom-gride-cel.component';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
// import { QuestionmenuComponent } from 'src/app/question/questionmenu/questionmenu.component';
import { QuezComponent } from 'src/app/question/quez.component';
import { Assignemnt } from 'src/app/shared/assignemnt.model';
import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { QAssignStudentList } from 'src/app/shared/question.model';
import { QuestionService } from 'src/app/shared/question.service';
import { UserService } from 'src/app/shared/user.service';
import { ViewdocumentComponent } from 'src/app/assignment/viewdocument.component';
import { formatDate, NgFor, NgClass, NgIf } from '@angular/common';
import { EventService } from 'src/app/shared/event.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NotificationService } from 'src/app/shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { CustomTooltipComponent } from 'src/app/custom-tooltip/custom-tooltip.component';
import { EditprofileComponent } from '../user/editprofile.component';
import { CUSTOMCONST } from '../shared/Const';
import { StudentAnswerpreviewComponent } from '../assignment/student-answerpreview.component';
import { ViewChildResultComponent } from './view-child-result.component';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { FeedbackService } from 'src/app/shared/feedback.service';
import { StudentService } from '../shared/student.service';
import { GoogleChartComponent, GoogleChartsModule } from 'angular-google-charts';
import { HelpComponent } from '../common/help.component';
import { AnalysisService } from '../shared/analysis.service';
import { UserLogInfoComponent } from '../user/user-log-info.component';
import { UserPaymentfeeInfoComponent } from '../payments/user-paymentfee-info.component';
import { EditChildComponent } from './edit-child.component';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

// import { faFolder,faCalendarDays,faDollarSign,faEnvelope,faPencilSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-parent-dashboard',
    templateUrl: './parent-dashboard.component.html',
    styleUrls: ['./parent-dashboard.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgFor, NgClass, MatIconModule, MatTabsModule, NgIf, AgGridModule, BsDatepickerModule, FormsModule, GoogleChartsModule, CarouselModule]
})
export class ParentDashboardComponent implements OnInit {

  // folderIcon = faFolder;
  // calendarIcon = faCalendarDays;
  // financeIcon = faDollarSign;
  // messageIcon = faEnvelope;
  // editIcon = faPencilSquare;
  rowData: QAssignStudentList;
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowSelection;
  gridApi;
  gridColumnApi;
  gridOptions: any;
  tooltipShowDelay;
  bsModalRef: BsModalRef;
  params: any;
  assignmentList: Assignemnt[];
  watchList: any[] = [];
  listenList: any[] = [];
  readList: any[] = [];
  doList: any[] = [];
  homeWorkList: any[] = [];
  date: any;
  points: number;
  daypoints: number;
  weekpoints: number;
  monthpoints: number;
  assignmentListByDate: Assignemnt[];
  datepickerConfig: BsDatepickerConfig;
  inlineDate: any;

  stdAnswerMasterInfo: any;
  answeredInfo: any;
  activeTab: string = "Academics";
  attendance: string;
  selectedChileId: any;
  answerListData: any[];
  feedbackList: any[];


  ILClassGraph: any = [];   // -- for interativeclass graph 
  ILClassGraphType: any;
  ILClassGraphTitle: any;
  ILClassGraphCol;
  ILClassGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  graphWidth = '100%';
  graphHeight = '100%';

  assessmentsGraph: any = []; // -- for assessments graph
  assessmentsGraphType: any;
  assessmentsGraphTitle: any;
  assessmentsGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  assessmentsGraphCol: any;

  assignmentsGraph: any = []; // -- for assignments graph
  assignmentsGraphType: any;
  assignmentsGraphTitle: any;
  assignmentsGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  assignmentsGraphCol: any;

  
  stuAnalysisGraph: any = []; // -- for Student Analysis graph
  stuAnalysisGraphType: any;
  stuAnalysisGraphTitle: any;
  stuAnalysisGraphOption = { colors: ['#00b02c', '#ff8000'], is3D: true };
  stuAnalysisGraphCol: any;
deuPayment:number;
userData:any=[]
  urlSafe: SafeResourceUrl;
  constructor(public eventService: EventService, public service: QuestionService,public router:Router,
    public assignedService: AssignemntService, public modalService: BsModalService,
    public userService: UserService, private notificationServ: NotificationService,
    public commonService: CommonService, public feedbacServ: FeedbackService,
    public stuService: StudentService,public analysisServ:AnalysisService,public sanitizer: DomSanitizer) {
    this.activeTab = "Academics";
    this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
    this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
    this.commonService.RefreshGrid$.subscribe(
      item => {
        this.getassignments();
        this.getAllStudentsQuestionsById();
      }
    );
    this.datepickerConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, showTodayButton: false, todayPosition: 'center', minMode: 'day', maxDate: new Date() });
    this.inlineDate = new Date();
    this.columnDefs = [
      { field: 'Name', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 200, width: 200 },
      { field: 'Description', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 120 },
      { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 200 },
      { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'NoOfQuestions', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 200, minWidth: 100 },
      {
        field: 'Result', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,
        maxWidth: 100, minWidth: 100,
        tooltipField: 'Comments',
        tooltipComponentParams: { color: '#ffffff' },
      },

      {
        headerName: "Status",
        field: 'QuestionMasterId', cellRendererFramework: CustomGrideCelComponent, maxWidth: 100, minWidth: 100, sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, },
        cellRendererParams: { enableMeetingLink: false, enableButton: true, celName: [{ Name: "Join", "Click": this.answerPreview, that: this }] },
      },
    ];
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: true,
      floatingFilter: false,
      tooltipComponent: 'customTooltip',
    };
    this.rowSelection = 'single';
  }

  ngOnInit(): void {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.attendance = res.StudentInfo.LastAttendended;
      if(this.commonService.selectedChileId<=0)
      {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.userService.formData.UserInfo.CroppedProfileFilepath = (res.UserInfo.CroppedProfileFilepath).replace(re, "/");
      }
      this.getStudentPoint(this.selectedChileId);
      this.getstudentfeeinfo(this.selectedChileId);
      this.getStudentFeedback();

      this.interactiveLiveClassChartInfo();
      this.assignmentsChartInfo();
      this.assessmentsChartInfo()
      this.getStudentAnalyticsChartInfo(this.activeTab);
      this.getassignments();
      this.getuser();
    });
    this.date = formatDate(new Date(), 'dd-MM-yyyy', 'en');
    


  }
  getStudentPoint(stuId) {
    this.userService.getstudentpointsbyId(stuId).subscribe((res: any) => {
      this.points = res.filter(item => item.Type == 'Total')[0].Points;
    });
  }
  interactiveLiveClassChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "InteractiveLiveClass"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.ILClassGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.ILClassGraph.push(arrObj);
      });
      this.ILClassGraphTitle = "InteractiveLiveClass";
      this.ILClassGraphType = "PieChart";

    });
  }

  assessmentsChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "Assessments"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.assessmentsGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.assessmentsGraph.push(arrObj);
      });
      this.assessmentsGraphTitle = "Assessments";
      this.assessmentsGraphType = "PieChart";
      
    });
  }
  assignmentsChartInfo() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        charName: "Assignments"
      }
    };
    this.userService.getStudentChartiInfo(config).subscribe((res: any) => {
      this.assignmentsGraph = [];
      res.forEach(element => {
        var arrObj = [element.Key, element.ValueInt];
        this.assignmentsGraph.push(arrObj);
      });
      this.assignmentsGraphTitle = "Assignments";
      this.assignmentsGraphType = "PieChart";

    });
  }
  getassignments() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
        selectedDate: this.date
      }
    };
    //get Selected Student Assignedstd Assignments By Param
    if (this.userService.formData.UserInfo.SchoolId > 0 && this.selectedChileId > 0 && this.date !== "") {
      this.assignedService.getSSAAByParam(config).subscribe((res: any) => {
        if (this.activeTab === "Academics") {
          this.assignmentListByDate = res.filter(item => item.IsAcademics == true);
        } else if (this.activeTab === "SEL") {
            this.assignmentListByDate = res.filter(item => item.IsHumanityLife == true);
        } else if (this.activeTab === "Life Skills") {
          this.assignmentListByDate = res.filter(item => item.IsSkills == true);
        } else if (this.activeTab === "Outside The Box") {
          this.assignmentListByDate = res.filter(item => item.IsLearnOutOfBox == true);
        }
       
        this.watchList = this.assignmentListByDate.filter(item => item.Type == 'Watch');
        this.listenList = this.assignmentListByDate.filter(item => item.Type == 'Listen');
        this.readList = this.assignmentListByDate.filter(item => item.Type == 'Read');
        this.doList = this.assignmentListByDate.filter(item => item.Type == 'Do');
        this.homeWorkList = this.assignmentListByDate.filter(item => item.Type == 'Home work');

      });
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getAllStudentsQuestionsById();
  }
  getAllStudentsQuestionsById() {
    this.service.getallstudentsquestionsbyId(this.selectedChileId).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);
      }
      this.rowData = res;
    });
  }
  transform(time: any): any {

    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'PM' : 'AM';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  onSelectionChanged($event) {

  }
  // openModel(data){
  //   const initialState = {
  //     list: [
  //       {data:data}
  //     ]      
  //   };
  //   this.bsModalRef = this.modalService.show(ViewdocumentComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});


  // }
  openModel(data) {

    var sd = data.StartDate.split('-');
    var ed = data.EndDate.split('-');
    var examStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + data.StartTime);
    var examEndTime = new Date(ed[1] + '-' + ed[0] + '-' + ed[2] + ' ' + data.EndTime);
    this.date = new Date();
    this.date = formatDate(new Date(), 'MM-dd-yyyy HH:mm a', 'en', '+0530');
    var currentTime = new Date(); //new Date(this.date);
    if (currentTime <= examStartTime) {
      this.notificationServ.ShowError('Assignment will start at ' + data.StartDate + ' ' + data.StartTime);
    }

    else {
      const userData = {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
      }
      const initialState = {
        list: [
          {
            data: data,
            userinfo: userData
          }
        ]
      };
      this.bsModalRef = this.modalService.show(ViewChildResultComponent, { id: CUSTOMCONST.STDASSIGNMENT_MODAL, initialState, class: 'modal-lg mb-0', ignoreBackdropClick: true });
    }

  }
  onDateChange(e: Event) {
    this.date = e;
    this.date = formatDate(this.date, 'dd-MM-yyyy', 'en');
    this.getassignments();
  }
  activeTabChange(event) {
    this.activeTab = event.tab.textLabel;
    this.getassignments();
    this.getStudentAnalyticsChartInfo(this.activeTab);
  }
  editProfile(UserId) {
    const initialState = {
      list: [
        { id: UserId }
      ]
    };
    this.bsModalRef = this.modalService.show(EditprofileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
  }

  changeStudent() {
    if (this.selectedChileId > 0) {
      this.activeTab = 'Academics';
      this.getStudentPoint(this.selectedChileId);
      this.commonService.CallOnRefreshGrid();
      this.getStudentAnalyticsChartInfo(this.activeTab);
      this.commonService.selectedChileId = this.selectedChileId;
      this.getstudentfeeinfo(this.selectedChileId);
    }
    else{
      this.router.navigateByUrl('/add-child');
      // const initialState = {
      //   list: [
      //     { id: 0 }
      //   ]
      // };
      // this.bsModalRef = this.modalService.show(EditChildComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
    }
  }
  answerPreview(StdQuestionMasterId, that) {
    const initialState = {
      list: [
        {
          id: StdQuestionMasterId,
          reqBy: 'Parent'
        }
      ]
    };
    this.bsModalRef = that.modalService.show(StudentAnswerpreviewComponent, { initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
  }

  getStudentFeedback() {
    var config = {
      params: {
        schoolId: this.userService.formData.UserInfo.SchoolId,
        userId: this.selectedChileId,
      }
    };
    this.feedbacServ.getStudentFeedbacks(config).subscribe((res: any) => {
      this.feedbackList = res;
    });
  }
  mobileAppOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: false,
    margin: 10,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    navText: ['<img mat-card-image src="../../assets/images/2X/Group-296.png" alt="value_education" class="m-0 w-100">', '<img mat-card-image src="../../assets/images/2X/Group-295.png" alt="value_education" class="m-0 w-100">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  }
  openHelpTooltip(tooltipName:any){
    const initialState = {
      list: [
        { name: tooltipName }
      ]

    };
    this.bsModalRef = this.modalService.show(HelpComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  graphdata:any[]=[];
  stuanalysisgraph:any;
  getStudentAnalyticsChartInfo(activeTab){
    this.userService.getStudentAnalyticsChartInfo(this.selectedChileId,activeTab,0).subscribe((res : any) => {
   
    this.stuAnalysisGraph = res;
    this.stuanalysisgraph = res;
      res.forEach(element => {
        var arrObj = [['Completed', element.ValueInt],['Not Completed',element.ValueLong]];
        this.graphdata.push(arrObj);
      });
      this.stuAnalysisGraphType = "PieChart";
      
    });
  }
  userLogHistory(){
    const initialState = {
      
      list: [
        {id: this.selectedChileId}
      ]
      
    };
    this.bsModalRef = this.modalService.show(UserLogInfoComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
   
  }
  getstudentfeeinfo(stuId) {
    this.userService.getstudentfeeinfo(stuId).subscribe((res: any) => {
      console.log(res);
      this.deuPayment = res.FeeListInfo[2].Amount;
    });
  }
  openStudentFeeTooltip(stuId:number){
    const initialState = {
      list: [
        { id: stuId }
      ]

    };
    this.bsModalRef = this.modalService.show(UserPaymentfeeInfoComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  selectChild(id:any){
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;

    if (this.selectedChileId > 0) {
      this.activeTab = 'Academics';
      this.getStudentPoint(this.selectedChileId);
      this.commonService.CallOnRefreshGrid();

      this.interactiveLiveClassChartInfo();
      this.assignmentsChartInfo();
      this.assessmentsChartInfo()
      this.getStudentAnalyticsChartInfo(this.activeTab);

      this.commonService.selectedChileId = this.selectedChileId;
      this.getstudentfeeinfo(this.selectedChileId);
      this.getuser();
    }
    else{
      this.router.navigateByUrl('/add-child');
     
    }
    
   
  }
  
  getuser() {
    var userid = this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData =  res; 
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath)
    });
  }
}
