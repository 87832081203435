import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/shared/school.service';
import { SchoolSharedDocService } from 'src/app/shared/school-shared-doc.service';
// import { School } from 'src/app/shared/School.model';
import { School } from 'src/app/shared/school.model';
import { AgGridModule } from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'app-dasboard',
    templateUrl: './dasboard.component.html',
    styleUrls: ['./dasboard.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatGridListModule, MatButtonModule, AgGridModule]
})
export class DasboardComponent implements OnInit {

  gridApi;
  gridColumnApi;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: School[];
  adminDocView:any[];
  gridOptions:any;
  constructor(public service:SchoolService,public service1:SchoolSharedDocService) {  this.columnDefs = [
    
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    
];

this.defaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
      sortable: true,
      floatingFilter: true,
};
this.rowSelection = 'single';

}

  ngOnInit(): void {
    this.getAdminShareListDocViewForAdmin();
  }

  onGridReady(params) {
    // this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
    // this.service.getSchoolBranchList().subscribe((res : School[]) => {
    //   this.rowData =  res;
    // });
  }
  getAdminShareListDocViewForAdmin(){
    this.service1.getAdminShareListDocViewForAdmin().subscribe((res : any) => {
      // this.adminDocView =  res.AdminShareDocListInfo;
      console.log(res);
      
    });
  }

  // getAdminShareListDocViewForAdmin(){
  //   this.service1.getAdminShareListDocViewForAdmin().subscribe((res:any) =>{

  //   })
  // }

  
}
