<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'Student Info' | translate}}</h3>
      </div>
      <div class="modal-body p-2">
        <form novalidate #form="ngForm"  autocomplete="off">

          <div class="row form-row m-0 mb-2">
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                <div class="form-group col-4 mb-0">
                  <label>{{'FirstName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel"
                  [(ngModel)]="service.formData.UserInfo.FirstName" required class.invalid="FirstName.invalid && FirstName.touched"
                  [disabled]="true">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'MidName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}" name="MiddleName" #MiddleName="ngModel"
                  [(ngModel)]="service.formData.UserInfo.MiddleName" required class.invalid="MiddleName.invalid && MiddleName.touched"
                  [disabled]="true">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Surname' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel"
                  [(ngModel)]="service.formData.UserInfo.Surname" required class.invalid="Surname.invalid && Surname.touched"
                  [disabled]="true">
                </div>

                <div class="form-group col-4 mb-0">
                  <label>{{'Gender' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel" [(ngModel)]="service.formData.UserInfo.GenderId"  [disabled]="true">
                    <option  [value]="0">Select Gender</option>
                    <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
                  </select>
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'DateOfBirth' | translate}}</label>
                  <input type="text" class="form-control form-control-sm" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel"
                  [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig" bsDatepicker  [disabled]="true">
                </div>
              </div>



            </div>
          </div>
          <div class="row form-row m-0">
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary mt-2 py-2 mt-2 ">
                <div class="form-group col-3 mb-0">
                  <label>Day Points ({{daypoints}})</label>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>Week Points ({{weekpoints}})</label>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>Month Points ({{monthpoints}})</label>
                </div>

                <div class="form-group col-3 mb-0">
                  <label>Total Points ({{points}})</label>
                </div>

              </div>
            </div>
          </div>

          <div class="row form-row m-0 mb-0">
            <div class="col-12 p-0">
                <div style="width: calc(100%);" class="p-0 mb-0 mt-2 mb-0">
                    <ag-grid-angular


                #agGrid style="width: 100%; height:calc(100vh - 330px);min-height: 200px;"
                    class="ag-theme-alpine"
                    id="feedbacktGrid"
                    [gridOptions]="feedbackGridOptions"
                    [columnDefs]="feedbackColumnDefs"
                    [defaultColDef]="feedbackDefaultColDef"
                    [rowSelection]="feedbackRowSelection"
                    [rowData]="feedbackRowData"
                    (selectionChanged)="feedbackRowSelectionChanged($event)"
                    (gridReady)="onFeedbackReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    (gridReload)="onFeedbackReload($event)"
                    ></ag-grid-angular>

                </div>
            </div>
          </div>
      </form>
      </div>
      <div class="modal-footer p-1">
        <!-- <button type="button" class="btn btn-primary" (click)="onSave(form)">Save</button> -->
        <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)" aria-label="Close">Close</button>
      </div>
    </div>
  </div>
