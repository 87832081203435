

<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >{{list[0].id === 0?'Add Analytics':'Edit Analytics'}}</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white p-2">
                    <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'Name' | translate}}</label>
                                <input class="form-control form-control-sm" type="text" placeholder="{{'Name' | translate}}" name="Name" #Name="ngModel" [(ngModel)]="analysisServ.formData.AnalysisMasterInfo.Name"  required class.invalid="Name.invalid && Name.touched">
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'Class'|translate}}</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="ClassId" [(ngModel)]="analysisServ.formData.AnalysisMasterInfo.ClassId"  required>
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList"  [value]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>
        
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Academic Year</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" [(ngModel)]="analysisServ.formData.AnalysisMasterInfo.AcademicYearId"  required>
                                    <option [value]="0">Select Academic Year</option>
                                    <option *ngFor="let year of yearList"  [value]="year.Id">
                                    {{year.Name}}
                                    </option>
                                </select>
        
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section>
                <section class="pt-0"  *ngFor="let qList of analysisServ.formData.AnalysisQuestionListInfo; let i=index;" >
                    <div class="row justify-content-c m-0 p-2" *ngIf="analysisServ.formData.AnalysisQuestionListInfo[i].IsActive">
                        <div class="row border-bottom m-0 mb-2 justify-content-between p-0" >
                            
                            <div class="col-1">
                                <div class="form-group mb-0">
                                    <label>&nbsp;</label>
                                    <div><h4>{{qList.OrderNo}}</h4></div>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="form-group mb-0">
                                    <label>Question</label>
                                    <input class="form-control form-control-sm" type="text" placeholder="Question" 
                                    name="Question" #Question="ngModel" 
                                    [(ngModel)]="qList.Question"  
                                    required class.invalid="Question.invalid && Question.touched">
                                </div>
                            </div>
                            <div class="col-2 px-2">
                                <div class="form-group mb-0">
                                    <label>Category</label>
                                    
                                    <select class="form-control form-control-sm appearance-auto" name="CategoryId" 
                                    [(ngModel)]="qList.CategoryId"  required>
                                        <option [value]="0">Select Category</option>
                                        <option *ngFor="let class of categoryList"  [value]="class.Id">
                                        {{class.Name}}
                                        </option>
                                    </select>
            
                                </div>
                            </div>
                            <div class="col-1 px-2">
                                <div class="form-group mb-0">
                                    <label>Score</label>
                                    
                                    <select class="form-control form-control-sm appearance-auto" name="Score" 
                                    [(ngModel)]="qList.Score"  required>
                                        <option [value]="0">Select Score</option>
                                        <option *ngFor="let score of scoreList"  [value]="score.Id">
                                        {{score.Name}}
                                        </option>
                                    </select>
            
                                </div>
                            </div>
                            <div class="col-1" *ngIf="i>=0 && i+1<analysisServ.formData.AnalysisQuestionListInfo.length">
                                <div class="form-group mb-0" >
                                    <!-- <label>{{'Correct' | translate}}</label> -->
                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 " style="height: 54px;">
                                        <div class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                            <mat-icon (click)="deleteRow(i)" class="text-danger cursor-pointer">delete_outline</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1" *ngIf="i+1==analysisServ.formData.AnalysisQuestionListInfo.length">
                                <div class="form-group mb-0" >
                                    <!-- <label>{{'Correct' | translate}}</label> -->
                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 " style="height: 54px;">
                                        <div class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                            <!-- <mat-icon (click)="addNewRow()" class="text-danger cursor-pointer">delete_outline</mat-icon> -->
                                            <mat-icon (click)="addNewRow()" class="text-success cursor-pointer">add</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </section>
            </section>
            
            <!-- <button class="btn btn-primary"  type="submit" (click)='addNewRow("MR")'>Add New Row</button> -->
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="saveAnalytics()" class="btn btn-success float-right" type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
