import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-feedbacklist',
    templateUrl: './feedbacklist.component.html',
    styleUrls: ['./feedbacklist.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class FeedbacklistComponent implements OnInit {
  public bsModalRef:BsModalRef;
  frameworkComponents;
  feedbackRowData:any;
  feedbackRowSelection;
  feedbackDefaultColDef;
  feedbackColumnDefs;
  feedbackGridOptions:any;
  feedbackGridApi;
  feedbackGridColumnApi
  constructor(
      public FeedbackSer:FeedbackService, 
      private modalService: BsModalService,
      private notificationServ:NotificationService,
      public commonServ:CommonService
    ) {
    this.commonServ.RefreshGrid$.subscribe(
      item => {
        
        this.FeedbackSer.getFeedback().subscribe((res: any) => {
          this.feedbackRowData =  res;
      });
       }
    );
   }

  ngOnInit(): void {


  
this.feedbackColumnDefs = [
  { 
    headerName:"FeedbackId",
    field: 'FeedbacktId', cellRendererFramework: CustommenuComponent, 
    cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editFeedback , that : this}, {Name: "Delete", "Click" : this.deleteFeedback , that : this}]},
    
    filter:false, width:60,maxWidth: 60, 
    headerComponent: 'customHeader', 
    headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  }, 
  { headerName:"Given", field: 'CreatedByName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'Feedback', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
  this.feedbackDefaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
}
  
onFeedbackReady(params) {
  
  this.feedbackGridApi = params.api;
  this.feedbackGridColumnApi = params.columnApi;
  this.FeedbackSer.getFeedback().subscribe((res: any) => {
    this.feedbackRowData =  res;
  });
}
onFeedbackReload(data){
  this.feedbackRowData = data;
}
editFeedback(feedbackId, that){
  that.openFeedbakModal(feedbackId);
  
}

deleteFeedback(feedbackId, that){
  if(that.commonServ.role == 'Teacher'){
    that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
  }
  else{
var item = that.feednackRowData.filter(item=>item.FeedbackId == feedbackId);
that.FeedbackSer.feednackData.FeedbackInfo = item[0];
that.FeedbackSer.feednackData.FeedbackInfo.IsActive = false;
that.FeedbackSer.savefeedback().subscribe((res:any)=>{
    if(res.IsSaved){
      that.notificationServ.ShowInfo('Successfully delete!');
      that.FeedbackSer.getFeedback().subscribe((res: any) => {
        that.feedbackRowData =  res;
    });
    }else{
      that.notificationServ.ShowError(res.ErrorMessage);
    }
  });
}
}
}
