import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { SurveyResultInfo } from '../shared/feedback.model';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarRatingModule } from 'ngx-bar-rating';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-save-survey-result',
    templateUrl: './save-survey-result.component.html',
    styleUrls: ['./save-survey-result.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, BarRatingModule, FontAwesomeModule, MatRadioModule, TranslateModule]
})
export class SaveSurveyResultComponent implements OnInit {
  rate: 3.5;
  list: any[] = [];
  Description: string;
  RatingType: string;
  IsCorrect: any;
  constructor(public bsModalRef: BsModalRef, public surveyServ: FeedbackService,
    private notificationServ: NotificationService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.surveyServ.formData = new SurveyResultInfo();
    this.Description = this.list[0].data[0].Description;
    this.RatingType = this.list[0].data[0].RatingType;
  }
  onFaoRate(event: any) {
    this.surveyServ.formData.Result = event;
  }
  changeYN() {

  }
  addSurveyResultInfo(form) {
    if (this.RatingType !== '5 Star' && !this.surveyServ.formData.Result) {
      return;
    }
    else {
      this.surveyServ.formData.SurveyId = this.list[0].data[0].SurveyId;
      this.surveyServ.formData.IsActive = true;
      this.surveyServ.saveSurveyResult(this.surveyServ.formData).subscribe((res: any) => {
        if (res.IsSaved) {
          this.notificationServ.ShowInfo('Successfully saved!');
          this.commonService.CallOnRefreshGrid();
          this.bsModalRef.hide();
        } else {
          this.notificationServ.ShowError(res.ErrorMessage);
        }
      });
    }
  }
}
