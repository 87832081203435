<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{'AddMultiEvent' | translate}}</h3>
        </div>
        <div class="modal-body p-2">
            <div class="border mb-2 p-1">
                <div  class="row form-row m-0">

                    <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                       <div class="form-group mb-0">
                           <label>{{'StartDate' | translate}}</label>
                           <input type="text"
                           class="form-control form-control-sm"
                           placeholder="{{'StartDate' | translate}}"
                           name="StartDate"
                           [(ngModel)]="StartDate"
                           [bsConfig]="datepickerConfig"
                           bsDatepicker
                           autocomplete="off"
                           [minDate]="minDate"
                           >
                       </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group mb-0">
                            <label>{{'EndDate' | translate}}</label>
                            <input type="text"
                            class="form-control form-control-sm"
                            placeholder="{{'EndDate' | translate}}"
                            name="EndDate"
                            [(ngModel)]="EndDate"
                            [bsConfig]="datepickerConfig"
                            bsDatepicker
                            autocomplete="off"
                            [minDate]="minDate"
                            >
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group mb-0">
                            <label>{{'StartTime' | translate}}</label>
                            <ng-template #popTemplate>
                                <timepicker [(ngModel)]="StartTime" name="start Time" [showMeridian]="true"></timepicker>
                            </ng-template>
                            <input [ngModel]="StartTime| date:'shortTime'" class="form-control form-control-sm" name="start Time" [popover]="popTemplate" [outsideClick]="true" />
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group mb-0">
                            <label>{{'EndTime' | translate}}</label>
                            <ng-template #popTemplate1>
                                <timepicker [(ngModel)]="EndTime" name="endTime" [showMeridian]="true"></timepicker>
                            </ng-template>
                            <input [ngModel]="EndTime | date:'shortTime'" class="form-control form-control-sm" name="endTime" [popover]="popTemplate1" [outsideClick]="true" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <button type="button" (click)="searchEventInfo()" class="btn btn-warning mt-3 float-right"  type="button" >Search</button>
                    </div>
               </div>
            </div>
            <ag-grid-angular #agGrid
                style="width: 100%; height:calc(100vh - 300px);min-height: 200px;"
                class="ag-theme-alpine"
                id="eventGrid"
                [gridOptions]="eventGridOptions"
                [columnDefs]="eventColumnDefs"
                [defaultColDef]="eventDefaultColDef"
                [rowSelection]="eventRowSelection"
                [rowData]="eventsRowData"
                (gridReady)="onEventReady($event)"
                [frameworkComponents]="frameworkComponents"
            ></ag-grid-angular>
            <div class="row form-row m-0 mt-2">

                <div class="col-12 ">
                    <div class="form-group mb-0">
                        <label>{{'Description' | translate}}</label>
                        <input type="text"
                        class="form-control form-control-sm"
                        placeholder="{{'Description' | translate}}"
                        name="Description"
                        [(ngModel)]="eventService.formData.EventInfo.Description"
                        autocomplete="off"
                        >
                    </div>
                </div>
                <div class="form-group col-6 mb-0">
                    <label>Academic year</label>
                    <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel"
                    [(ngModel)]="eventService.formData.EventInfo.AcademicYearId">
                      <option [value]="0">Select</option>
                      <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                    </select>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-group mb-0">
                        <label>{{'Select Class' | translate}}</label>
                        <!-- <ng-multiselect-dropdown
                            [placeholder]="'Search'"
                            [settings]="dropdownSettings"
                            name="StdClass"
                            [data]="classList"
                            [(ngModel)]="eventService.formData.ClassMappingList"
                            (onSelect)="onItemSelect($event,'Class')"
                            (onSelectAll)="onSelectAll($event,'Class')"
                            (onDeSelect)="onItemSelect($event,'Class')"
                            (onSelectAll)="onSelectAll($event,'Class')">
                            </ng-multiselect-dropdown> -->
                    </div>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                    <label class="mb-2px">&nbsp;</label>
                    <div class="form-groupmb-0">
                        <mat-checkbox class="example-margin"  name="IsMeeting" #IsMeeting="ngModel"
                        [(ngModel)]="eventService.formData.EventInfo.IsMeeting" >{{'MeetingUrl' | translate}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row form-row m-0">
                <div class="form-group col-3 mb-0">
                    <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                        [(ngModel)]="eventService.formData.EventInfo.IsAcademics">{{'Academics' | translate}}
                    </mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                    <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                        [(ngModel)]="eventService.formData.EventInfo.IsHumanityLife">{{'HumanityLife' |
                        translate}}</mat-checkbox>

                </div>
                <div class="form-group col-3 mb-0">
                    <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                        [(ngModel)]="eventService.formData.EventInfo.IsSkills">{{'Skills' | translate}}
                    </mat-checkbox>
                </div>

                <div class="form-group col-3 mb-0">
                    <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                        [(ngModel)]="eventService.formData.EventInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                        translate}}</mat-checkbox>
                </div>

            </div>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-success mr-2" (click)="saveMultiEvent()" type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
          </div>
    </div>
</div>
