// Constants for time format

// Get the current date and time
export const currentTime = new Date();

// Convert the current time to a localized time string
export const timeString = currentTime.toLocaleTimeString();

// Determine if the time format is 12-hour (contains AM/PM)
export const is12HourFormat = timeString.includes('AM') || timeString.includes('PM');

// Create an Intl.DateTimeFormat object to format the time according to the user's locale
const timeFormat = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric' });

// Get the resolved options of the time format, which includes whether it is 12-hour or 24-hour format
const options = timeFormat.resolvedOptions();

// Determine the time format string ('12-hour' or '24-hour')
export const timeFormatString = options.hour12 ? '12-hour' : '24-hour';

// Set the time format pattern based on whether the format is 12-hour or 24-hour
export const FORMAT = options.hour12 ? "hh:mm A" : "HH:mm";

// Define a constant duration of 60 minutes
export const DURATION = 60;

// Get the user's timezone
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Constants for token

// Retrieve the token from local storage, if it doesn't exist, use an empty string
export const TOKEN = localStorage.getItem("Token") ?? '';

// Base URL for images
export const IMGURL = 'https://192.168.0.87:4011/';
