<ul class="list-sidebar bg-defoult clear-both mt-3">
    <li>
      <a routerLink="/dashboard/" routerLinkActive="active">
        <i class="fa fa-th-large"></i>
        <span class="nav-label">Dashboard</span>
      </a>

  </li>
  <li>
   <a routerLink="/calendar/" routerLinkActive="active">
      <fa-icon [icon]="calendarIcon"></fa-icon>
     <span class="nav-label">Calendar</span></a>
  </li>

  <li>
  <a href="#" data-toggle="collapse" data-target="#functionsDropdown" [ngClass]="{'active': (questionActive.isActive),'collapsed':!(questionActive.isActive) }">
    <i class="fa fa-sliders-h" aria-hidden="true"></i> <span class="nav-label">Functions</span> <span class="fa fa-chevron-left float-right"></span>
  </a>
  <ul class="sub-menu collapse" id="functionsDropdown" [ngClass]="{'show': (questionActive.isActive ) }">
    <li class="active"><a routerLink="/question/" routerLinkActive="active" #questionActive="routerLinkActive">Assessments</a></li>

  </ul>
  </li>

  </ul>
