<div class="container-fluid bg-light pb-2">
    <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">

            <!-- Profile widget -->
            <div class="bg-white shadow overflow-hidden">
                <div class="px-0 pt-3  bg-dark position-relative bg-s-cover min-h-300px"
                    [style.background-image]="'url(' + (service.formData.UserInfo.CroppedProfileFilepath?service.formData.UserInfo.CroppedProfileFilepath:'') + ')'">
                    <div class="media profile-header  sm-flex-wrap px-3">
                        <div class="profile mr-3 sm-m-auto text-center sm-w-100">
                            <!-- [src]="service.formData.UserInfo.CroppedFilepath" -->
                            <img [src]="service.formData.UserInfo.CroppedFilepath?service.formData.UserInfo.CroppedFilepath:'../../../assets/images/user/default.png'"
                                width="130" class="rounded-circle border border-w-5 border-light mt-2">
                            <div>
                                <!-- [ngClass]="{'pe-none':!service.formData.UserInfo.IsEditAllowed}" -->
                                <a (click)="editProfile(service.formData.UserInfo.UserId)"
                                    class="btn btn-link btn-sm my-2 rounded-0">Edit profile</a>
                                <a (click)="openHelpTooltip('EditProfile')" class="position-relative"></a>
                            </div>
                        </div>
                        <div class="media-body text-white xs-pb-20px d-none d-sm-block">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mt-0 mb-0">{{service.formData.UserInfo.FirstName}}
                                        {{service.formData.UserInfo.MiddleName}}
                                        {{service.formData.UserInfo.Surname}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-5">
                                    <h4 class="mt-0 mb-0">{{SchoolName}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Class}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Batch}}</h4>

                                </div>
                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{service.formData.UserInfo.About}}</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div class="px-0 pt-3 d-sm-none">
                    <div class="media profile-header sm-flex-wrap px-3">

                        <div class="media-body xs-pb-20px">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mt-0 mb-0">{{service.formData.UserInfo.FirstName}}
                                        {{service.formData.UserInfo.MiddleName}}
                                        {{service.formData.UserInfo.Surname}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-5">
                                    <h4 class="mt-0 mb-0">{{SchoolName}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Class}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{service.formData.StudentInfo.Batch}}</h4>

                                </div>
                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{service.formData.UserInfo.About}}</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <!-- End profile widget -->

        </div>
        <div class="col-xl-12 col-md-12 col-sm-12 p-0 pt-4">
            <div class="row">
                <!-- <p>{{ profileTypeInfo | json }}</p> -->
                <div class="col-6 mb-3" *ngFor="let profileType of profileTypeInfo">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between">
                                {{profileType.Name}} <span><img src="../../../assets/images/icons/svg/plus_green.svg" (click)="EditprofileProfile(profileType.Id, profileType.Name)"></span>
                                <!-- <fa-icon [icon]="addIcon"
                                        ></fa-icon> -->
                            </div>
                        </div>
                        <ul class="list-group list-group-flush">

                            <li class="list-group-item"
                                *ngFor="let studentProfileList of profileType.StudentProfileList">
                                <div class="d-flex justify-content-between">
                                    {{studentProfileList.Name}}
                                    <span>
                                        <fa-icon class="mr-2 text-primary" [icon]="editIcon"
                                            (click)="Editprofile(studentProfileList.StudentProfileDataId, profileType.Name, studentProfileList)"></fa-icon>
                                        <fa-icon class="text-danger" [icon]="deleteIcon"
                                            (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></fa-icon>
                                    </span>
                                </div>
                            </li>
                            <li class="list-group-item" *ngFor="let defaultvalue of profileType.Default">
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <div id="profileLayout" class="col-12 col-xl-12 col-md-12 col-sm-12 p-0">
            <div class="d-md-block d-xs-none">
                <div class="shadow">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="profile-group d-flex">
                                <div class="Profile-background">
                                    <img src="../../../assets/images/background/Profile-background1.svg" alt="">
                                </div>
                                <div class="profile-pic">
                                    <img src="'../../../assets/images/user/default.png" alt="">
                                </div>
                                <div class="profile-con-top">
                                    <div class="w-50 text-center">
                                        <h1 class="m-0">Think and Feel</h1>
                                        <span>{{studentEmpathyInfo.Think_Feel!=null?studentEmpathyInfo.Think_Feel:'What really matters \n Worries/Aspiration?'}}</span>
                                    </div>
                                </div>
                                <div class="profile-con-bottom">
                                    <div class="w-50 text-center">
                                        <h1 class="text-center m-0">Say & Do</h1>
                                        <span>{{studentEmpathyInfo.Say_Do!=null?studentEmpathyInfo.Say_Do:'Appearance \n Attitude in public'}}</span>
                                    </div>
                                </div>
                                <div class="profile-con-left">
                                    <div class="w-50 text-center">
                                        <h1 class="text-center m-0">Hear</h1>
                                        <span>{{studentEmpathyInfo.Hear!=null?studentEmpathyInfo.Hear:'What friends say \n What family say \n What bos/preers say \n What infuencers say'}}</span>
                                    </div>
                                </div>
                                <div class="profile-con-right">
                                    <div class="w-50 text-center">
                                        <h1 class="m-0">See</h1>
                                        <span>{{studentEmpathyInfo.See!=null?studentEmpathyInfo.See:'Envirnment \n Social Media \n Websites \n What the markets offers'}}</span>
                                    </div>

                                </div>


                            </div>

                        </div>
                        <div class="col-6 pe-0 pggroup">
                            <div class=" text-center border p-4 point">
                                <h1 class="text-center m-0">Pains</h1>
                                <span class="white-space-pre">{{studentEmpathyInfo.Pains!=null?studentEmpathyInfo.Pains:'Fears \n Frustrations \n Obstacles'}}</span>
                            </div>
                        </div>
                        <div class="col-6 ps-0 pggroup">
                            <div class="text-center border p-4 gain">
                                <h1 class="text-center m-0">Gains</h1>
                                <span class="white-space-pre">{{studentEmpathyInfo.Gains!=null?studentEmpathyInfo.Gains:'Wants/Needs \n Measure of success \n Goals'}}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="d-md-none d-sm-block mobile-view">
                <div class="shadow">
                    <div class="row">
                        <div class="col-12 p-3">
                            <div class="profile-pic-sm-md text-center border m-auto" >
                                <img src="'../../../assets/images/user/default.png" alt="">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 think_feel">
                                    <h1 class="text-center m-0">Think and Feel</h1>
                                    <span>{{studentEmpathyInfo.Think_Feel!=null?studentEmpathyInfo.Think_Feel:'What really matters \n Worries/Aspiration?'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 say_do">
                                    <h1 class="text-center m-0">Say & Do</h1>
                                    <span>{{studentEmpathyInfo.Say_Do!=null?studentEmpathyInfo.Say_Do:'Appearance \n Attitude in public'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 hear">
                                    <h1 class="text-center m-0">Hear</h1>
                                    <span>{{studentEmpathyInfo.Hear!=null?studentEmpathyInfo.Hear:'What friends say \n What family say \n What bos/preers say \n What infuencers say'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 see">
                                    <h1 class="text-center m-0">See</h1>
                                    <span>{{studentEmpathyInfo.See!=null?studentEmpathyInfo.See:'Envirnment \n Social Media \n Websites \n What the markets offers'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 point">
                                    <h1 class="text-center m-0">Point</h1>
                                    <span class="white-space-pre">{{studentEmpathyInfo.Pains!=null?studentEmpathyInfo.Pains:'Fears \n Frustrations \n Obstacles'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="text-center border">
                                <div class="text-center border p-4 gain">
                                    <h1 class="text-center m-0">Gains</h1>
                                    <span class="white-space-pre">{{studentEmpathyInfo.Gains!=null?studentEmpathyInfo.Gains:'Wants/Needs \n Measure of success \n Goals'}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
