<!-- <div class="profile-group">
    <img class="shadow" [src]="userData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">
    
    <p class="shadow">{{userData.UserInfo.FullName?userData.UserInfo.FullName:''}}</p>
  </div>
  <button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i class="fa fa-caret-down"></i></button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <button mat-menu-item *ngFor="let child of childrenList" (click)="selectChild(child.Id)">{{child.Name}}</button>
      <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
    </mat-menu> -->

    <div class="container-fluid bg-light">
        <div class="row">
            <div class="col-12 px-0">
                <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                    <div class="row border m-0 py-2 mb-2 justify-content-between" *ngIf="role == 'Teacher'">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Select Class</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="classId" [(ngModel)]="selectedClass" (change)="changeClass()" required>
                                    <option *ngFor="let class of classList"  [value]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>
        
                            </div>
    
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <button type="button" (click)="serchTimeTable()" class="btn btn-warning float-right" type="button" >Search</button>
                        </div>
                    </div>
                    <ag-grid-angular #agGrid
                    style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                    class="ag-theme-alpine"
                    id="eventGrid"
                    [gridOptions]="TTGridOptions"
                    [columnDefs]="TTColumnDefs"
                    [defaultColDef]="TTDefaultColDef"
                    [rowSelection]="TTRowSelection"
                    [rowData]="TTRowData"
                    (gridReady)="onTTReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    (gridReload)="onTTReload($event)"
                    ></ag-grid-angular>
                   
                </div>
            </div>
        </div>
    </div>
     
  