import { Component, OnInit } from '@angular/core';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { TimetableService } from 'src/app/shared/timetable.service';
import { UserService } from 'src/app/shared/user.service';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-time-table',
    templateUrl: './time-table.component.html',
    styleUrls: ['./time-table.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, AgGridModule]
})
export class TimeTableComponent implements OnInit {
  TTGridOptions:any;
  TTColumnDefs;
  TTDefaultColDef;
  TTRowSelection;
  TTRowData:any;
  gridApi;
  gridColumnApi;
  frameworkComponents;
  
 

  role:string;
  selectedClassId:number;
  selectedStuId:number;
  constructor(public TTService:TimetableService, private userService:UserService) { }

  ngOnInit(): void {
    this.getMyInfo();
    
  }
  greadReady() {

    this.TTColumnDefs = [
        
      { field: 'Subject', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'TeacherName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      
    ];
    
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.TTDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getclasstimetablelistinfo(classId){
    this.TTService.getclasstimetablelistinfo(classId).subscribe((res:any)=>{
      this.greadReady();
      this.TTRowData = res;
      console.log(res);
    });
  }
  classList:any;
  selectedClass:number=1;
  changeClass(){
    this.selectedClassId=this.selectedClass;
  }
  serchTimeTable(){
    this.getclasstimetablelistinfo(this.selectedClass);
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.role = res.UserInfo.Role;
      console.log("getMyInfo");
      console.log(res);
      
      if(this.role == "Student"){
       
        this.selectedClassId = res.StudentInfo.ClassId;
        this.selectedStuId = res.StudentInfo.UserId;
        this.getclasstimetablelistinfo(this.selectedClassId);
        

      }else{
        this.classList=res.ClassList;
        this.selectedClassId = this.selectedClass;
        this.selectedStuId = res.StudentInfo.UserId;
        this.getclasstimetablelistinfo(this.selectedClassId);
        this.greadReady();
      }
      
      
    });
  }
  onTTReady($event){

  }
  onTTReload($event){

  }
  editTimeTable(id, that){

  }
  deleteTimeTable(id, that){
    
  }
}
