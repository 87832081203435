<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{'StudentAnswerList' | translate}}</h3>
        </div>
        <div class="breackout_body p-2 bg-light ">
            <section>
                <div class="row justify-content-c m-0  bg-white p-2">
                    <div class="col-md-12 p-0">
                        <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 192px)" class="ag-theme-alpine"
                            id="answerList" [gridOptions]="answerListOptions" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowData]="answerListData"
                            (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                    </div>
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Close">Close</button>
        </div>
    </div>
</div>