<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
           <div class="mt-3 mb-3">
            <div class="modal-header p-1 bg-theam">
                <h3 class="modal-title h3 m-1" >Own files</h3>
              </div>

            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0">
                <!-- <form novalidate #form="ngForm" (submit)="onSearch(form)" method="POST">
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text"
                                class="form-control form-control-sm"
                                placeholder="{{'StartDate' | translate}}"
                                name="StartDate"
                                #StartDate="ngModel"
                                [(ngModel)]="filterStartDate"
                                [bsConfig]="datepickerConfig"
                                bsDatepicker
                                [bsValue]="bsValue"
                                required class.invalid="StartDate.invalid && StartDate.touched"
                                autocomplete="off"
                                >
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text"
                                class="form-control form-control-sm"
                                placeholder="{{'EndDate' | translate}}"
                                name="EndDate"
                                #EndDate="ngModel"
                                [(ngModel)]="filterEndDate"
                                [bsConfig]="datepickerConfig"
                                bsDatepicker
                                [bsValue]="bsValue"
                                required class.invalid="EndDate.invalid && EndDate.touched"
                                autocomplete="off"
                                >
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>&nbsp;</label>
                                <div class="form-control form-control-sm p-0 border-0">
                                    <button class="btn btn-warning btn-sm float-right" type="submit" [disabled]="form.invalid">Search</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form> -->
                <ag-grid-angular #agGrid
                    style="width: 100%; height:calc(100vh - 232px);min-height: 200px;"
                    class="ag-theme-alpine"
                    id="fileList"
                    [ensureDomOrder]="true"
                    [enableCellTextSelection]="true"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [rowData]="fileListData"
                    (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    >
                </ag-grid-angular>
                <!-- <div class="card-footer text-right p-2 mt-2">
                    <button type="button" class="btn btn-primary mr-2" (click)="downloadFiles()" type="button" >Download</button>
                    <button type="button" class="btn btn-danger" (click)="deleteFiles()" type="button" >Delete</button>
                </div> -->
            </div>
           </div>
        </div>
    </div>
</div>
