import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true
})
export class AdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
