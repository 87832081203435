<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" >Payment</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div >
                    <div  class="row form-row m-0">
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Name</label>
                                <input class="form-control form-control-sm " type="text" 
                                placeholder="Name" name="Name"  
                                #Name="ngModel" [(ngModel)]="service.paymentView.PaymentInfo.Name"
                                required class.invalid="Name.invalid && Name.touched"/>
                            </div>
                        </div>
                       
                        
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Amount</label>
                                <input type="text" 
                                class="form-control form-control-sm" 
                                placeholder="Amount" 
                                name="Amount" 
                                #Amount="ngModel" 
                                [(ngModel)]="service.paymentView.PaymentInfo.Amount" 
                                required class.invalid="Amount.invalid && Amount.touched"
                                >
                            </div>
                            
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Class</label>
                            <select class="form-control form-control-sm appearance-auto" name="Class" #Class="ngModel" 
                            [(ngModel)]="service.paymentView.PaymentInfo.ClassId">
                              <option [value]="0">Select School</option>
                              <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                            </select>

                            </div>
                            
                        </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Academic year</label>
                            <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel" 
                            [(ngModel)]="service.paymentView.PaymentInfo.AcademicYearId" (change)="changeAcademicYear()">
                              <option [value]="0">Select Academic year</option>
                              <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                            </select>

                            </div>
                            
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Term</label>
                            <select class="form-control form-control-sm appearance-auto" name="TermId" #TermId="ngModel" 
                            [(ngModel)]="service.paymentView.PaymentInfo.TermId" (change)="changeTerm()">
                              <option [value]="0">Select Term</option>
                              <option *ngFor="let term of termList" [value]="term.TermId">{{term.Name}}</option>
                            </select>

                            </div>
                            
                        </div>
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Description' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text" 
                                placeholder="{{'Description' | translate}}" name="Description" 
                                #Description="ngModel" [(ngModel)]="service.paymentView.PaymentInfo.Description"></textarea>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addPaymentInfo()" class="btn btn-success"  type="button" >Save</button>
                <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
