import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { AnalysisQuestion } from '../shared/analysis.model';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { StudAnalysisQuestionComponent } from './stud-analysis-question.component';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-analytics-student',
    templateUrl: './analysis-student.component.html',
    styleUrls: ['./analysis-student.component.scss'],
    standalone: true,
    imports: [NgFor, FormsModule]
})
export class AnalysisStudentComponent implements OnInit {
  list:any[]=[];
  rowData:any[]=[];
  CUSTOMCONST:any=CUSTOMCONST; 
  scoreList:any[]= [];
  questionObj: AnalysisQuestion;
  constructor(public service:AnalysisService,  public modalService:BsModalService,
    public bsModalRef: BsModalRef,public commonServ:CommonService,private notificationServ:NotificationService) {
    
  
}

  ngOnInit(): void {
    this.getAnalysisList();
  }
  
  tempArr:any[] = [];
  getAnalysisList(){
    this.service.getStudentAnalysis(this.list[0].studentId,this.list[0].analysisMasterId).subscribe((res : any) => {
     
      this.service.formData =  res;
      this.rowData =  res.AnalysisQuestionListInfo;
     
      for(let i=0 ;i<res.AnalysisQuestionListInfo.length ; i++){
        this.tempArr = [];
        for(let  j=0; j<res.AnalysisQuestionListInfo[i].MaxScore; j++){
          this.tempArr.push( { Id:j+1,Name:j+1},);
         
        }
        this.scoreList.push(this.tempArr,);
      }

    });
    
  }

      saveStudentAnalysis(){
       
        this.service.saveStudentAnalysis().subscribe((res : any) => {
          if(res.IsSaved===true)
          {
            this.notificationServ.ShowInfo('Successfully Saved!');
            this.commonServ.CallOnRefreshGrid();
            this.modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL);
          }
        });
      }
}
