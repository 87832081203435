import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
import { NgIf } from '@angular/common';
@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class ConfirmComponent implements OnInit {
  list: any[] = [];
  ConfirmMessage:string = "Are you sure want to submit?"
  constructor(public bsModalRef2: BsModalRef,public modalService:BsModalService,private commonService:CommonService) {
  // this.ConfirmMessage = this.list[0].message;
  this.CUSTOMCONST = CUSTOMCONST;
  }
  CUSTOMCONST : any;

  
  
  ngOnInit(): void {
    console.log(this.list[0].message);
    if(this.list[0].id!='confirm')
    {this.ConfirmMessage = this.list[1].message;}
  }
  onConfirm(){
    this.commonService.CallOnConfirm();
    this.modalService.hide(CUSTOMCONST.CONFIRM_MODAL);
  }
}
