import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { MasterListData, MLData } from 'src/app/shared/contact.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-addmlinfo',
    templateUrl: './addmlinfo.component.html',
    styleUrls: ['./addmlinfo.component.scss'],
    standalone: true,
    imports: [FormsModule, TranslateModule]
})
export class AddmlinfoComponent implements OnInit {
  // bsModalRef2: BsModalRef;
  Name:string;
  list: any[] = [];
  constructor(public bsModalRef2: BsModalRef,public service:CommonService, private notificationServ:NotificationService,) { }

  ngOnInit(): void {
    
  }
  addMLSubject(data){
    if(this.list[1].Text == 'BatchData'){
      this.service.addbatchInfo(this.list[1].Text,data.Name,data.Name,this.list[2].classId).subscribe((res:MasterListData) =>{
        if(res.ErrorMessage){
          //
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
          //display added message.
          this.notificationServ.ShowInfo('Item saved successfully');
          this.service.CallOnRefreshItem();
          this.bsModalRef2.hide();
          
        }
      });
    }
    else if(this.list.length > 1 && this.list[2].classId > 0){
      this.service.addmlInfoWithClassAndSubject(this.list[1].Text,data.Name,data.Name,this.list[2].classId,this.list[3].subjectId).subscribe((res:MasterListData) =>{
        if(res.ErrorMessage){
          //
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
          //display added message.
          this.notificationServ.ShowInfo('Item saved successfully');
          this.service.CallOnRefreshItem();
          this.bsModalRef2.hide();
          
        }
      });
    }
    else{
    this.service.addmlInfo(this.list[1].Text,data.Name,data.Name).subscribe((res:MasterListData) =>{
      if(res.ErrorMessage){
        //
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        //display added message.
        this.notificationServ.ShowInfo('Item saved successfully');
        this.service.CallOnRefreshItem();
        this.bsModalRef2.hide();
        
      }
    });
  }
    // this.service.addmlInfo('MLSubject','MLSubject','MLSubject');
  }
}

