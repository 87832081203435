import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { AgGridModule } from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-admindashboard',
    templateUrl: './admindashboard.component.html',
    styleUrls: ['./admindashboard.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatGridListModule, MatButtonModule, AgGridModule]
})
export class AdmindashboardComponent implements OnInit {

  registercolumnDefs:any;
  registerdefaultColDef:any;
  registergridOptions:any;
  registerrowSelection;
  registerData:any;

  register1columnDefs:any;
  register1defaultColDef:any;
  register1gridOptions:any;
  register1rowSelection;
  register1Data:any;

  constructor(public commomService:CommonService) {
    
this.registerdefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: false,
  sortable: false,
  floatingFilter: false,
  };

this.registercolumnDefs = [
  { headerName:"Name",field: 'FirstName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { headerName:"Mobile", field: 'Mobile', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
  { headerName:"Email", field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 , maxWidth: 100   },
  { headerName:"Job Title",field: 'JobTitle', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"School Name",field: 'SchoolName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"School Type",field: 'SchoolType', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"City",field: 'City', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Country",field: 'Country', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Source",field: 'Source', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Created Date",field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
  { headerName:"Comments",field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
];

this.register1defaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: false,
  sortable: false,
  floatingFilter: false,
  };

this.register1columnDefs = [
  { headerName:"Name",field: 'FirstName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { headerName:"Email", field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 , maxWidth: 100   },
];

  }

  ngOnInit(): void {
    this.commomService.getregisterlist().subscribe((res: any) => {
      this.registerData =  res.filter((item:any)=> item.Mobile || item.JobTitle);
      this.register1Data =  res.filter((item:any)=> !item.Mobile && !item.JobTitle);
  });
  }

}
