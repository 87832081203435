<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header p-1 bg-theam">
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.BranchId <= 0">{{'AddBranch' | translate}}</h3>
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.BranchId > 0">Edit Branch</h3>
      </div>
      <form novalidate #form="ngForm" (submit)="onSave(form)" method="POST">
      <div class="modal-body p-2">


          <div class="row form-row m-0">
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 ">



                <div class="form-group col-4 mb-0">
                  <label>{{'RegistrationNo' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'RegistrationNo' | translate}}" name="RegistrationNo" #RegistrationNo="ngModel"
                  [(ngModel)]="service.formData.RegistrationNo" >
                </div>
                <div class="form-group col-4 mb-0">
                    <label>{{'Name' | translate}}</label>
                    <input class="form-control form-control-sm" placeholder="{{'Name' | translate}}" name="Name" #Name="ngModel"
                    [(ngModel)]="service.formData.Name" required class.invalid="Name.invalid && Name.touched">
                  </div>
                  <div class="form-group col-4 mb-0">
                    <label>{{'Website' | translate}}</label>
                    <input class="form-control form-control-sm" placeholder="{{'Website' | translate}}" name="Website" #Website="ngModel"
                    [(ngModel)]="service.formData.Website" >
                  </div>

                <div class="form-group col-4 mb-0">
                  <label>{{'Mobile' | translate}}</label>
                  <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel"
                  [(ngModel)]="service.formData.Mobile" required minlength="8" class.invalid="Mobile.invalid && Mobile.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Telephone' | translate}}</label>
                  <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'Telephone' | translate}}" name="Telephone" #Telephone="ngModel"
                  [(ngModel)]="service.formData.Telephone" >
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Email' | translate}}</label>
                  <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel"
                  [(ngModel)]="service.formData.Email" required class.invalid="Email.invalid && Email.touched">
                </div>
                <div class="form-group col-8 mb-0">
                  <label>{{'Address' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Address' | translate}}" name="Address" #Address="ngModel"
                  [(ngModel)]="service.formData.Address" required  class.invalid="Address.invalid && Address.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Street' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Street' | translate}}" name="Street" #Street="ngModel"
                  [(ngModel)]="service.formData.Street" required class.invalid="Street.invalid && Street.touched">
                </div>

                <div class="form-group col-4 mb-0">
                  <label>{{'PostalNo' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'PostalNo' | translate}}" name="PostalNo" #PostalNo="ngModel"
                  [(ngModel)]="service.formData.PostalNo" required class.invalid="PostalNo.invalid && PostalNo.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'City' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'City' | translate}}" name="City" #City="ngModel"
                  [(ngModel)]="service.formData.City" required  class.invalid="City.invalid && City.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'State' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'State' | translate}}" name="State" #State="ngModel"
                  [(ngModel)]="service.formData.State" required class.invalid="State.invalid && State.touched">
                </div>

              </div>
            </div>
          </div>



      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-success"  type="submit">Save</button>
        <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
      </div>
    </form>
    </div>
  </div>
