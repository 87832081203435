<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >{{'AddMassage' | translate}}</h3>
          <!-- <h3 class="modal-title h3 m-1" >{{'EditAssgnment' | translate}}</h3> -->
        </div>
        <form novalidate #form="ngForm" autocomplete="off" enctype="multipart/form-data">
            <div class="modal-body p-2">
                <div  class="row form-row m-0">
                    <div class="col-4" *ngIf="service.formData.MessageMasterInfo">
                        <div class="form-group mb-0">
                            <label>{{'Name' | translate}}*</label>
                            <input class="form-control form-control-sm" type="text" placeholder="{{'Name' | translate}}" name="Name" #Name="ngModel" [(ngModel)]="service.formData.MessageMasterInfo.Name" required class.invalid="Name.invalid && Name.touched">
                        </div>
                    </div>
                    <div class="col-4" *ngIf="service.formData">
                        <div class="form-group mb-0">
                            <label>{{'Class' | translate}}*</label>
                            <ng-multiselect-dropdown
                                placeholder="'Search'"
                                [settings]="dropdownSettings"
                                name="StdClass"
                                [data]="classList"
                                [(ngModel)]="service.formData.ClassMappingList"
                                (onSelect)="onItemSelect($event,'Class')"
                                (onSelectAll)="onSelectAll($event,'Class')"
                                (onDeSelect)="onItemSelect($event,'Class')"
                                (onSelectAll)="onSelectAll($event,'Class')">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="service.formData.MessageMasterInfo">
                        <div class="form-group mb-0">
                            <label>{{'Student' | translate}}*</label>
                            <ng-multiselect-dropdown
                                [placeholder]="'Search'"
                                [settings]="dropdownSettings"
                                name="students"
                                [data]="studentList"
                                [(ngModel)]="service.formData.MessageMasterInfo.UserIdList"
                                (onSelect)="onStuentSelect($event,'Class')"
                                (onSelectAll)="onStuentSelectAll($event,'Class')"
                                (onDeSelect)="onStuentSelect($event,'Class')"
                                (onSelectAll)="onStuentSelectAll($event,'Class')">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="service.formData.MessageMasterInfo">
                        <div class="form-group mb-0">
                            <label>{{'Description' | translate}}*</label>
                            <input class="form-control form-control-sm" type="text" placeholder="{{'Description' | translate}}" name="Description" #Description="ngModel" [(ngModel)]="service.formData.MessageMasterInfo.Description" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">

                <button type="button"  class="btn btn-success"  type="button" (click)="onStart()" >Start</button>
                <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
