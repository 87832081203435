import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { CommonService } from '../shared/common.service';
import { Partner, PartnerResponse, UserRegisterData } from '../shared/contact.model';
import { NotificationService } from '../shared/notification.service';
import contactPageData from '../schoolConst'
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatCheckboxModule, _MatCheckboxRequiredValidatorModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgFor } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-share-and-earn',
    templateUrl: './share-and-earn.component.html',
    styleUrls: ['./share-and-earn.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgFor, FormsModule, MatRadioModule, MatCheckboxModule, _MatCheckboxRequiredValidatorModule]
})
export class ShareAndEarnComponent implements OnInit {
  childrenList:any=[];
  SignupRoleList:any[]= contactPageData.contact.SignupRoleList;
  selectedChileId:number=0;
  urlSafe: SafeResourceUrl;
  // role: string;
  constructor(public notificationSrv:NotificationService,public commonServ:CommonService,public service:UserService,
    public sanitizer: DomSanitizer, public router:Router) { 
    this.commonServ.selectedChileId=this.commonServ.selectedChileId?this.commonServ.selectedChileId:0;
    this.selectedChileId=this.commonServ.selectedChileId?this.commonServ.selectedChileId:0;
  }

  ngOnInit(): void {
    this.commonServ.userFormData=new UserRegisterData();
    this.getuseregisterinfo();
    this.getMyInfo()
    // this.getuser();
  }
  getuser(){
    var userid = this.commonServ.selectedChileId>0?this.commonServ.selectedChileId:0;
    this.service.getUserInfo(userid).subscribe((res: any) => {
      this.service.formData =  res; 
// this.role = this.service.formData.UserInfo.Role;
this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath);
console.log(this.service.formData);

    });
  }
  getMyInfo(){
    this.service.getMyInfo().subscribe((res: any) => {
      if(this.commonServ.selectedChileId<=0)
      {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      this.commonServ.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      this.childrenList =  res.ParentInfo.ChildrenList;
      this.getuser();
    });
    
  }
  getuseregisterinfo(){
    this.commonServ.getuseregisterinfo().subscribe((res: any) => {
      this.commonServ.userFormData = res;
      
    });
  }
 
  
    onRegister(form:NgForm){
      this.commonServ.saveuseregisterinfo().subscribe((res: any) => {
        if (res.status) {
          this.notificationSrv.ShowInfo(res.message);
          this.resetForm(form);
        }
        else {
          this.notificationSrv.ShowError(res.ErrorMessage);
        }
        
      }, err => { 
        this.notificationSrv.ShowError(err);
       });
    }
    resetForm(form: NgForm) {
      form.form.reset();
      // this.SignupName = '';
      // this.SignupEmail = '';
    }
    selectChild(id:any){
      this.selectedChileId = id;
      this.commonServ.selectedChileId = id;
    if(id>0){
      this.commonServ.selectedChileId = this.selectedChileId;
    this.getuser();
    }else{
      this.router.navigateByUrl('/add-child');
    }
     
    }
}
