<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <!-- <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1 w-100" >{{fileType}}<span class="float-right" *ngIf="startAnswer && !ispreviewAnswer">Total Questions: {{noofQuestions}}</span></h3>
        </div> -->
        <div class="d-flex justify-content-end p-2">
          <i class="fa fa-times cursor-pointer" (click)="modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL)" aria-hidden="true"></i>
        </div>
        <div class="d-flex px-3">
          <div class="mr-3 max-width-100px">
            <img [src]="feedbackData.CreatedByImageUrl" class="card-img-top">
          </div>
          <div class="w-100">
            <h2 class="m-0 ">{{feedbackData.CreatedByName}} </h2>
            <h5 class="border-bottom mb-1"><small> {{feedbackData.CreatedOn}}</small></h5>
            <h4 class="m-0 ">Comments</h4>
            <p class="m-0 mb-2">{{feedbackData.Feedback}}</p>
          </div>
        </div>
        
    </div>

</div>