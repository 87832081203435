import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { CommonService } from '../shared/common.service';
import { Contact, Partner, PartnerResponse } from '../shared/contact.model';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
    standalone: true,
    imports: [MatCardModule, FormsModule, TranslateModule]
})
export class PartnersComponent implements OnInit {

  constructor(public service:CommonService,public notificationSrv:NotificationService,
    private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hybrid Learning Platform Partners Bring curriculum to students Life');
    this.metaService.addTags([
      {name: 'keywords', content: 'Educational Partner, PUBLISHERS, ACADEMIC, SEL, LIFE SKILLS, OUTSIDE THE BOX'},
      {name: 'description', content: 'Hybrid Learning Platform is an open ecosystem connecting multiple platforms for streamlined technology solutions. We are currently integrating with technologies our clients are requesting.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
    this.service.partenrFormData.PartnerFor = "";
  }
  saveParterinfo(form:NgForm){
    this.service.saveParterinfo().subscribe((res:PartnerResponse)=>
    {
      if(res.status){
        this.notificationSrv.ShowInfo(res.message);
      }
      else{
        this.notificationSrv.ShowError(res.message);
      }
      this.resetForm(form);
    },err=>{console.log(err); });
  }
  resetForm(form:NgForm){
    form.form.reset();
    this.service.partenrFormData =  new Partner();
    }
}
