<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">My Achievements</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Competitions' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Competitions' | translate}}" name="Competitions"  
                                    #Competitions="ngModel" 
                                    [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.CompetitionName"
                                    required class.invalid="Competitions.invalid && Competitions.touched">

                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Type of Competition' | translate}}*</label>
                                <select class="form-control form-control-sm appearance-auto"
                                name="AchievementSource" 
                                #AchievementSource="ngModel" 
                                [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.CompetitionTypeId"  required
                                    class.invalid="AchievementSource.invalid && AchievementSource.touched">                                
                                    <option *ngFor="let AchievementSource of service.formDataAchievement.AchievementSourceInfo" [value]="AchievementSource.Id">{{AchievementSource.Name}}</option>
                                </select>
    
                            </div>
                        </div>
                        
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue"
                                    timepicker required class.invalid="StartDate.invalid && StartDate.touched">
                            </div>
    
                        </div>
    
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.EndDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker [bsValue]="bsValue" [minDate]="minDate" required
                                    class.invalid="EndDate.invalid && EndDate.touched">
                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Achievement' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Achievement' | translate}}" name="Achievement"  
                                    #Achievement="ngModel" 
                                    [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.Achievement"
                                    required class.invalid="Achievement.invalid && Achievement.touched">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row m-0">                                                                         
                    <div class="row justify-content-c m-0  bg-white p-2">
                        <label class="m-0 p-0">{{'Pictures' | translate}}*</label>
                        <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                <div class="form-group mb-0" >
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                     name="FileInfoList" [(ngModel)]="service.formDataAchievement.StudentAchievementInfo.FileInfoList" 
                                     (updatedResponseEmitter)="filesuploaded($event)" 
                                     title="{{'Upload File' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                                    </mb-large-files-uploader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="saveDocument()" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>