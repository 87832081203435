import { formatDate, NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateService } from '../services/date.service';
import { Appointment, appViewInfo } from '../shared/appointment.model';
import { AppointmentService } from '../shared/appointment.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-cal-appointment',
    templateUrl: './edit-cal-appointment.component.html',
    styleUrls: ['./edit-cal-appointment.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TranslateModule]
})
export class EditCalAppointmentComponent implements OnInit {
  list: any[] = [];
  datepickerConfig: BsDatepickerConfig;
  classList: any[] = [];
  minDate = new Date();
  role: string;
  yearList: any = [];
  public eventData1:any = {};
  CUSTOMCONST:any=CUSTOMCONST;
  public bsModalRef: BsModalRef;
  preheaderhour: number = 0;
  preheaderTime: number = 0;
appDate: NgbDate;
timeSlotList:any=[];
  constructor(public service: AppointmentService,  private notificationServ: NotificationService,
    private commonService: CommonService,public modalService: BsModalService,public dateService: DateService, public calendar: NgbCalendar) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.role = commonService.role;
    this.eventData1 = this.getjson();
  }

  ngOnInit(): void {
    this.service.formdata.AppointmentInfo.AppointmentId = this.list[0].id;
    this.getAppointmentViewInfo(this.list[0].id)

    this.gettimeslotlist();
    this.appDate = this.calendar.getToday();
  }
  getAppointmentViewInfo(id:number){
    this.service.getAppointmentViewInfo(id).subscribe((res: any) => {
      this.service.formdata = res;
      // if(this.list[0].id > 0){
      //   this.service.formdata.AppointmentInfo.StartTime = this.getStartDateTime(res.AppointmentInfo.Date, res.AppointmentInfo.StartTime)
      //   this.service.formdata.AppointmentInfo.EndTime = this.getEndDateTime(res.AppointmentInfo.Date, res.AppointmentInfo.EndTime)
      // }
      this.classList = res.ClassList;
      console.log(this.service.formdata);
      
      
    });
  }
  getStartDateTime(date,startTime){
    let datetime;
      datetime = this.dateService.getDateTimeFromString(date, startTime);
      return formatDate(datetime, 'yyyy-MM-ddThh:mm:ss.mss', 'en');
  }
  getEndDateTime(date,endTime){
    let datetime;
      datetime = this.dateService.getDateTimeFromString(date,endTime);
      
      return formatDate(datetime, 'yyyy-MM-ddThh:mm:ss.mss', 'en');
  }
  addAppointmentInfo(form) {
    // this.service.formdata.AppointmentInfo.Date = formatDate(this.service.formdata.AppointmentInfo.Date, 'dd-MM-yyyy', 'en');
    this.service.formdata.AppointmentInfo.IsActive = true;
    this.service.saveAppointmentView().subscribe((res: any) => {
      if (res.IsSaved) {
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshCalender();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      } else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
changeData(){
  // console.log(this.service.formdata.AppointmentInfo.Date);
  this.service.formdata.AppointmentInfo.Date =formatDate(this.service.formdata.AppointmentInfo.Date, 'dd-MM-yyyy', 'en')
  
}


counterMinMax(min: number,max:number) {
  return new Array(max-min);
}
gethour(hour: number, index: number) {
  return (hour + index) < 10 ? '0' + (hour + index) : hour + index;
}
getheaderTime(hour: any, index: number, min: number) {
  hour = this.gethour(hour, index);
  if (this.preheaderhour == hour) {
    this.preheaderTime = this.preheaderTime + (60 / this.eventData1.options.timeslotsPerHour);
  }
  else {
    this.preheaderTime = 0;
  }
  this.preheaderhour = hour;
  if (this.preheaderTime < 10) {
    return "0" + this.preheaderTime;
  }
  else {
    return this.preheaderTime;
  }
}
counter(i: number) {
  return new Array(i);
}

gettimeslotlist(){
  let index=0;
  for (let hour of this.counterMinMax(this.eventData1.options.businessHours.start,this.eventData1.options.businessHours.end)){
   let j = 0;
   
    for (let min of  this.counter(this.eventData1.options.timeslotsPerHour)){
      let obj= {value:'',date:''};
      let intervel = this.gethour(this.eventData1.options.businessHours.start, index)+':'+this.getheaderTime(this.eventData1.options.businessHours.start,index,j);
      obj.value= intervel;
      obj.date=intervel;
      this.timeSlotList.push(obj);
      
      
      
      j++;
    }
    let obj= {value:'',date:''};
    if(index == ((this.eventData1.options.businessHours.end-this.eventData1.options.businessHours.start)-1)){
      obj.value= this.eventData1.options.businessHours.end+':'+'00';
      obj.date= this.eventData1.options.businessHours.end+':'+'00';
      this.timeSlotList.push(obj);
    }
    index++;
  }
  
}

chkIsGreater(){
  let date = this.dateService.getStringDate(new Date(this.appDate.year, this.appDate.month - 1, this.appDate.day))
  let sdatetime = this.dateService.getDateTimeFromString(date, this.service.formdata.AppointmentInfo.StartTime)
  let edatetime = this.dateService.getDateTimeFromString(date, this.service.formdata.AppointmentInfo.EndTime);
  if(sdatetime > edatetime){
    this.notificationServ.ShowError("End Time should be grater than Start time");
    this.service.formdata.AppointmentInfo.EndTime = "";
  }
}

getjson(){
  return {
  "options": {
    "timeslotsPerHour": 4,
    "timeslotHeight": 20,
    "defaultFreeBusy": {
      "Key": "free",
      "Value": true
    },
    "businessHours": {
      "start": 6,
      "end": 18,
      "limitDisplay": true
    }
  }
}
}
}
