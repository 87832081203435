<!-- For demo purpose -->

<div class="container-fluid bg-light pb-2">
    <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">

            <section>
                <div class="d-flex ">
                    <div class="group-left">
                        <div class="row">
                            <div class="col-6 d-flex"><p>Scores</p>
                                <a (click)="openHelpTooltip('Score')" class="d-block">
                                    <img class="" src="../../../assets/images/icons/svg/Info_gray.svg" alt="">
                                </a>
                            </div>
                            <div class="col-6 d-flex"><p>Learning Time</p>
                                <a (click)="openHelpTooltip('LearningTime')" class="d-block">
                                    <img class="" src="../../../assets/images/icons/svg/Info_gray.svg" alt="">
                                </a>
                            </div>
                            <div class="col-6">
                                
                                <div class="card">
                                    <div class="m-2 d-flex">
                                        <div class="score">
                                            <h1 class="card-title">{{points}}</h1>
                                            <p class="card-text">Score</p>                
                                        </div>
                                        <div class="total-score-main">
                                            <h2 class="card-title m-0">Total Score</h2>
                                            <div class="star-icon">
                                                <img src="../../../assets/images/icons/svg/christmas_star.svg" alt="">
                                                <img src="../../../assets/images/icons/svg/christmas_star.svg" alt="">
                                                <img src="../../../assets/images/icons/svg/christmas_star.svg" alt="">
                                                <img src="../../../assets/images/icons/svg/christmas_star_gray.svg" alt="">
                                                <img src="../../../assets/images/icons/svg/christmas_star_gray.svg" alt="">
                                            </div>
                                            <p class="card-text">Self-Assessment</p>
                                        </div>
                                    </div>
                                    <div class="row m-2">
                                        <div class="col-6 p-0 pr-1 pb-2">
                                            <div class="card daily-score">
                                                <div class="d-flex">
                                                   <div class="content">
                                                    <h1 class="card-title m-0">{{daypoints}}</h1>
                                                    <p class="card-text">Daily Score</p>
                                                   </div>
                                                   <div class="icon">
                                                    <img src="../../../assets/images/icons/svg/scoreboard.svg" alt="">
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 p-0 pl-1 pb-2">
                                            <div class="card weekly-score">
                                                <div class="d-flex">
                                                   <div class="content">
                                                    <h1 class="card-title m-0">{{weekpoints}}</h1>
                                                    <p class="card-text">Weekly Score</p>
                                                   </div>
                                                   <div class="icon">
                                                    <img src="../../../assets/images/icons/svg/scoreboard.svg" alt="">
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 p-0 pr-1 pb-2">
                                            <div class="card monthly-score">
                                                <div class="d-flex">
                                                   <div class="content">
                                                    <h1 class="card-title m-0">{{monthpoints}}</h1>
                                                    <p class="card-text">Monthly Score</p>
                                                   </div>
                                                   <div class="icon">
                                                    <img src="../../../assets/images/icons/svg/scoreboard.svg" alt="">
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 p-0 pl-1 pb-2">
                                            <div class="card total-score">
                                                <div class="d-flex">
                                                   <div class="content">
                                                    <h1 class="card-title m-0">{{points}}</h1>
                                                    <p class="card-text">Total Score</p>
                                                   </div>
                                                   <div class="icon">
                                                    <img src="../../../assets/images/icons/svg/scoreboard.svg" alt="">
                                                   </div>
                                                </div>
                                            </div>
                                        </div>         
                                    </div>
                            
                                </div>
                            </div>
                            <div class="col-6">
                                <div #gradeCharGraph class="h-100" *ngIf="isShowLearningTime">
                                    
                                    <div class="border h-100 d-flex align-items-center bg-white" *ngIf="studentsByGradeCharGraph?.length > 0">
                                        <div class="google-chart-group">
                                            <google-chart [title]="studentsByGradeCharGraphTitle" [type]="studentsByGradeCharGraphType"
                                            [data]="studentsByGradeCharGraph" [columns]="studentsByGradeCharGraphCol" [options]="studentsByGradeCharGraphOption"
                                            [width]="graphWidth" [height]="graphHeight">
                                        </google-chart>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="default-learningtime bg-white border h-100 d-flex align-items-center"  *ngIf="!isShowLearningTime">
                                    <img src="../../../assets/images/default_images/learning_time.PNG" alt="">
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <section class="course-details-content-section">
                                    <div class="container-fluid p-0">
                                        <mat-tab-group animationDuration="0ms" class="bg-transparent border-0 dashboard-tab1"
                                            (selectedTabChange)="activeTabChange($event)" [selectedIndex]="tabIndex">
                                            
                                            <mat-tab label="LiveClass" >
                                                <ng-template mat-tab-label>
                                                    
                                                    <div class="m-0"> 
                                                        <div class="d-flex align-items-center">
                                                            <div class="position-relative tab-icon-left">
                                                                <img class="position-absolute" src="../../../assets/images/icons/svg/timetable_gray.svg" alt="">
                                                            </div>
                                                            <label class="m-0">Live Class</label>
                                                            <div class="tab-icon-right">
                                                                <a (click)="openHelpTooltip('SBAssignment')" class="d-block">
                                                                    <img class="" src="../../../assets/images/icons/svg/Info_gray.svg" alt="">
                                                                </a>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    
                                                </ng-template>
                                                <div class="mt-3">
                                                    <div class="tab-content p-0">
                                                        <div class="">
                                                            <div class="bg-white rounded tab-content">
                                                                <div class="today-live-class">
                                                                    <p class="m-0 pt-2">Today Live Classes</p>
                                                                    <div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "*ngFor="let todayClass of todayliveClass">
                                                                        <div class="lang-icon me-2">
                                                                            <label class="p-2 mb-0">
                                                                                <img  src="../../../assets/images/icons/svg/language_green.svg" alt="">
                                                                            </label>
                                                                        </div>
                                                                        <div class="item-group w-100">
                                                                            <h3 class="m-0 subject">{{todayClass.Description | titlecase }}</h3>
                                                                            <p class="m-0 mt-1 teacher">Class By {{todayClass.UserName}}</p>
                                                                            <div class="d-flex justify-content-between">
                                                                                <p class="m-0 mt-1"><span class="lbl-time">Start Time </span> <span class="time-value"> {{todayClass.StartTimeISO | customDate:'shortTime'}}</span></p>
                                                                                <p class="m-0 mt-1"><span class="lbl-time">End Time </span><span class="time-value"> {{todayClass.EndTimeISO | customDate:'shortTime'}}</span></p>
                                                                                <!-- <p class="m-0 mt-1"><span class="lbl-started-in">Starting in </span><span class="started-in-value">  {{todayClass.StartTime}}</span></p> -->
                                                                                <app-timer-button [event]="todayClass"
                                                                                (isActive)="startClass(todayClass)" 
                                                                                >
                                                                              </app-timer-button>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="no-live-Class text-center" *ngIf="todayliveClass?.length == 0">
                                                                        <img src="../../../assets/images/default_images/no_live_class.png" alt="no_live_class.png" >
                                                                        <p class="text-center text-danger">You do not have any assigned live class for today</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mt-4"></div>
                                                            <div class="bg-white rounded tab-content">
                                                                <div class="today-live-class">
                                                                    <p class="m-0 pt-2">Tomorrow Live Classes</p>
                                                                    <div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "*ngFor="let todayClass of tomorrowliveClass">
                                                                        <div class="lang-icon me-2">
                                                                            <label class="p-2 mb-0">
                                                                                <img  src="../../../assets/images/icons/svg/language_green.svg" alt="">
                                                                            </label>
                                                                        </div>
                                                                        <div class="item-group w-100">
                                                                            <h3 class="m-0 subject">{{todayClass.Description | titlecase }}</h3>
                                                                            <p class="m-0 mt-1 teacher">Class By {{todayClass.UserName}}</p>
                                                                            <div class="d-flex justify-content-between">
                                                                                <p class="m-0 mt-1"><span class="lbl-time">Start Time </span> <span class="time-value"> {{todayClass.StartTime}}</span></p>
                                                                                <p class="m-0 mt-1"><span class="lbl-time">End Time </span><span class="time-value"> {{todayClass.EndTime}}</span></p>
                                                                                <p class="m-0 mt-1"><span class="lbl-started-in">Starting in </span><span class="started-in-value">  {{todayClass.StartTime}}</span></p>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="no-live-Class text-center" *ngIf="tomorrowliveClass?.length == 0">
                                                                        <img src="../../../assets/images/default_images/no_live_class.png" alt="no_live_class.png" >
                                                                        <p class="text-center text-danger">You do not have any assigned live class for tomorrow</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </mat-tab>
                                            <mat-tab label="TimeTable" >
                                                <ng-template mat-tab-label>
                                                    
                                                    <div class="m-0"> 
                                                        <div class="d-flex align-items-center">
                                                            <div class="position-relative tab-icon-left">
                                                                <img class="position-absolute" src="../../../assets/images/icons/svg/timetable_gray.svg" alt="">
                                                            </div>
                                                            <label class="m-0">Time Table</label>
                                                            <div class="tab-icon-right">
                                                                <a (click)="openHelpTooltip('SBAssignment')" class="d-block">
                                                                    <img class="" src="../../../assets/images/icons/svg/Info_gray.svg" alt="">
                                                                </a>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    
                                                </ng-template>
                                                <div class="mt-3 bg-white rounded">
                                                    <div class="tab-content">
                                                        <div class="today-live-class">
                                                            <p class="m-0 pt-2">Time Table</p>
                                                            <app-classtimetable [data]="timeTableData"></app-classtimetable>
                                                            <!--<div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "*ngFor="let timetable of timeTableData">
                                                                <div class="lang-icon me-2">
                                                                    <label class="p-2 mb-0">
                                                                        <img  src="../../../assets/images/icons/svg/language_green.svg" alt="">
                                                                    </label>
                                                                </div>
                                                                <div class="item-group w-100">
                                                                    <h3 class="m-0 subject">{{timetable.Subject | titlecase }}</h3>
                                                                    <p class="m-0 mt-1 teacher">Class By {{timetable.TeacherName}}</p>
                                                                    <div class="d-flex justify-content-between">
                                                                        <p class="m-0 mt-1"><span class="lbl-time">Start Time </span> <span class="time-value"> {{timetable.StartTime}}</span></p>
                                                                        <p class="m-0 mt-1"><span class="lbl-time">End Time </span><span class="time-value"> {{timetable.EndTime}}</span></p>
                                                                        <p class="m-0 mt-1"><span class="lbl-started-in">Starting in </span><span class="started-in-value">  {{timetable.StartTime}}</span></p>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        -->
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </mat-tab>
                                            <mat-tab label="SchoolDiary" >
                                                <ng-template mat-tab-label>
                                                    
                                                    <div class="m-0"> 
                                                        <div class="d-flex align-items-center">
                                                            <div class="position-relative tab-icon-left">
                                                                <img class="position-absolute" src="../../../assets/images/icons/svg/moleskine_gray.svg" alt="">
                                                            </div>
                                                            <label class="m-0">School Diary</label>
                                                            <div class="tab-icon-right">
                                                                <a (click)="openHelpTooltip('SBAssignment')" class="d-block">
                                                                    <img class="" src="../../../assets/images/icons/svg/Info_gray.svg" alt="">
                                                                </a>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <div class="mt-3 bg-white rounded">
                                                    <div class="tab-content">
                                                        <div class="today-live-class">
                                                            <p class="m-0 pt-2">School Diary </p>
                                                            <!-- diary component-->
                                                            <app-student-dairy></app-student-dairy>
                                                            <!--<div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "*ngFor="let diary of diaryData">
                                                                <div class="lang-icon me-2">
                                                                    <label class="p-2 mb-0">
                                                                        <img  src="../../../assets/images/icons/svg/language_green.svg" alt="">
                                                                    </label>
                                                                </div>
                                                                <div class="item-group w-100">
                                                                    <h3 class="m-0 subject">{{diary.Subject | titlecase }}</h3>
                                                                    <p class="m-0 mt-1 diary-desc">Description : {{diary.DairyNotes}}</p>
                                                                    <p class="m-0 mt-1 teacher">Class By {{diary.TeacherName}}</p>
                                                                    <div class="d-flex justify-content-between">
                                                                        <p class="m-0 mt-1"><span class="lbl-started-in">Status </span><span class="started-in-value">  {{diary.Result}}</span></p>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>-->
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </mat-tab>
                                            
                                        </mat-tab-group>
                                    </div>
                                </section>
                            </div>
                        </div>
                        
                
                    </div>
                    <div class="group-right student-dashboard">
                        <p>&nbsp;</p>
                        <div class="bg-white" >
                            <div>
                                <p class="m-0 px-10px pt-10px calendar_link"><img src="../../assets/images/icons/svg/sidebar/calendar_gray.svg" alt="calendar_gray"><a class="text-success" [routerLink]="['/calendar']"> Open Calendar</a></p>
                            </div>
                            <div class="w-100 p-0 inline-calander-group xs-ml-0px">
                                <bs-datepicker-inline class="inlinedate w-100" name="inlineDate" [(ngModel)]="inlineDate"
                                    [bsValue]="inlineDate" [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
                                </bs-datepicker-inline>
            
            
                            </div>
                            <div class="w-100 px-3">
                                <p class="text-success pt-3">{{"Upcoming Task" | titlecase}}</p>
                                <div class="mb-3">
                                    <div class="d-flex justify-content-between">
                                        <h3>Assignments</h3>
                                        <a [routerLink]="['/assignments']">View All</a>
                                    </div>
                                    
                                    <div class="assessment-list">
                                        <div class="assessment-list">
                                            <div class="assessment-list-item d-flex justify-content-between mb-3"  *ngFor="let assignment of assignmentData;  let assignmentIndex = index">
                                                <div class="test_passed-icon me-2">
                                                    <label class="p-2">
                                                    <img src="../../../assets/images/icons/svg/test_passed.svg" alt="">
                                                    </label>
                                                </div>
                                                <div class="item-group w-100">
                                                    <h3 class="m-0">{{assignment.Name | titlecase }}</h3>
                                                    <p class="m-0 mt-1">{{assignment.Description}}</p>
                                                </div>
                                                <div class="arrow-icon-group">
                                                    <span class="fa fa-chevron-right"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="border-top mt-3">
                                    <div class="d-flex justify-content-between">
                                        <h3>Assessments</h3>
                                        <a [routerLink]="['/assessments']">View All</a>
                                    </div>
                                    <div class="assessment-list">
                                        <div class="assessment-list-item d-flex justify-content-between mb-3"  *ngFor="let assessmentData of rowData;  let assessmentIndex = index">
                                            <div class="test_passed-icon me-2">
                                                <label class="p-2">
                                                <img src="../../../assets/images/icons/svg/test_passed.svg" alt="">
                                                </label>
                                            </div>
                                            <div class="item-group w-100">
                                                <h3 class="m-0">{{assessmentData.Name | titlecase }}</h3>
                                                <p class="m-0 mt-1">{{assessmentData.Description}}</p>
                                            </div>
                                            <div class="arrow-icon-group">
                                                <span class="fa fa-chevron-right"></span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            </section>
            

        </div>
    </div>



</div>