<div class="banner">
  <div class="banner-bg-image">
    <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
  </div>
  <div class="banner-image w-100">
    <img class="img-fluid w-100" src="../../assets/images/FAQs_page/faq_banner.png" alt="faq_banner">
  </div>
  <div class="banner-content-left d-none d-sm-block">
    <h1>“You can’t grow if you are afraid of change.”</h1>
    <p></p>
    </div>
</div>
<section>
  <div class="container-fluid bg-theam-body page-faqs">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="row mt-5 align-items-center">
          <div class="col-12 mb-3">
            <div class="section-header">
              <h1 class="d-block title pt-1 pb-1 text-center">Frequently Asked Questions</h1>
            </div>
          </div>
          <div class="col-12 mb-3">

            <div class="accordion calendarManagement" id="accordionExample">
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" 
                data-target="#collapseOne"
                  aria-expanded="false" aria-controls="collapseOne">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW DOES A HYBRID LEARNING PLATFORM WORK?</span>
                </div>
                <div id="collapseOne" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform is the world’s first “Collaborative, Personalized Learning, Next Generation
                    Education Management System for SCHOOL BASED LEARNING, HOME BASED LEARNING, COMMUNITY BASED LEARNING, SOCIETY BASED LEARNING”. It is a comprehensive
                    education solution that integrates all educational boards, managerial and production-based processes
                    of schools as the "Academic Backbone" and enables them to be managed from a single point. It is
                    unique with its total benefit as an integrated process management and its structure that ensures
                    that learning continues uninterrupted outside the school boundaries.
                  </div>
                </div>
              </div>
              <div class="card  bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT IS THE BEST LEARNING MANAGEMENT SYSTEM (LMS)?</span>
                </div>
                <div id="collapseTwo" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    A Best Learning Management System (LMS) offers the following features:
                    <ul class="pl-0 custom-list-style1">
                      <li class="mb-2 right-angle1">Collaboration model with National and International teaching
                        methods. <span class="font-weight-500"></span> </li>
                      <li class="mb-2 right-angle2">Meaningful Assignments that trigger system<span
                          class="font-weight-500"></span></li>
                      <li class="mb-2 right-angle3">Conduct, and evaluate online assessments and Exams <span
                          class="font-weight-500"></span>  <span class="font-weight-500"></span> </li>
                      <li class="mb-2 right-angle4">Integration with mainstream collaboration platforms such as Zoom or
                        Google Meet, Teams, CollabNow. <span class="font-weight-500"></span>
                      </li>
                      <li class="mb-2 right-angle5">Transparent communication between Parents, Teachers, Students and
                        Administrators. <span class="font-weight-500"> </span>
                      </li>
                      <li class="mb-2 right-angle1">Student overall <span class="font-weight-500">Portfolio</span>
                      </li>
                      <li class="mb-2 right-angle2"><span class="font-weight-500">Customization </span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT IS EDTECH AND ITS POSSIBILITIES?</span>
                </div>
                <div id="collapseThree" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    EdTech is the combination of tech tools and educational practices used to enhance learning. It is a
                    combination of hardware and software, such as tablets, projectors, Learning Management Systems,
                    cloud, Artificial Intelligence, etc.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHY IS GROUP PARTICIPATION REQUIRED FOR LEARNING?</span>
                </div>
                <div id="collapseFour" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Group participation is an essential part of a student’s learning journey. When students speak up
                    during class, they learn to word their ideas in a way that others can understand. Through questions,
                    they obtain information that enhances their understanding of the topic taught. Moreover, it is a
                    valuable teaching tool as well, as teachers know about the student's understanding and adjust their
                    concept explanation and assignments.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseFive"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW TO ASSIGN ASSIGNMENTS?</span>
                </div>
                <div id="collapseFive" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Assignments can be created by identifying learning gaps, gathering ideas, creating videos and
                    digitizing current resources.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse05"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">CAN STUDENT UPLOAD THEIR ASSIGNMENTS BACK?</span>
                </div>
                <div id="collapse05" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Yes, the student can upload their assignment back.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseSix"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">IS HYBRID LEARNING PLATFORM A LEARNING MANAGEMENT SYSTEM OR A SCHOOL MANAGEMENT
                    SYSTEM?</span>
                </div>
                <div id="collapseSix" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform is a Personalised and Student Agency Learning Management System (LMS)
                    focused on classroom collaboration for enhanced pedagogy and learning experience in educational
                    institutions.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseSeven"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHO CAN USE HYBRID LEARNING PLATFORM?</span>
                </div>
                <div id="collapseSeven" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform is a multi-user Learning Management System (LMS) platform with a single,
                    secure sign-in. It is used by the school administration, teachers, students, and parents.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseEight"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">IS HYBRID LEARNING PLATFORM SECURE?</span>
                </div>
                <div id="collapseEight" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform is fully secured. This ensures no malicious or unwanted code is being
                    stored in the database
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseNine"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT ARE THE PRICING PLANS FOR HYBRID LEARNING PLATFORM?</span>
                </div>
                <div id="collapseNine" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform versatile and inclusive pricing plan ensures no school and educational
                    institution is left behind on our journey to digitally transform education.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseten"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">IS SUPPORT INCLUDED? </span>
                </div>
                <div id="collapseten" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform offers its superior customer support to schools included in their Advanced
                    Plan.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapseEleven"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHICH PLATFORMS ARE SUPPORTED ON HYBRID LEARNING PLATFORM? </span>
                </div>
                <div id="collapseEleven" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform focuses on providing an optimum user experience at all times, this is why
                    Hybrid Learning Platform is web-based and app-based in mobile. Web-based applications can run on any
                    modern and even some old browsers.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse12"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT CONTENT FORMATS DOES HYBRID LEARNING PLATFORM SUPPORT? </span>
                </div>
                <div id="collapse12" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Research indicates that diversified content formats reinforce learning amongst students. This is why
                    our content management system supports content in picture, audio, video, and document formats.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse13"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT TYPE OF QUESTIONS CAN I CREATE ON HYBRID LEARNING PLATFORM? </span>
                </div>
                <div id="collapse13" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform understands the teachers’ need to test students’ learning with objective
                    and subjective questions. This is why we support numerous question types and formats for our online
                    assessments, exams, and assignments – essay, short questions, fill in the blanks, multiple-choice
                    questions, match following, match matrix, multiple response and true/false.
                  </div>
                </div>
              </div>
              <!-- <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse14"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">IS SUPPORT INCLUDED? </span>
                </div>
                <div id="collapse14" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform offers its superior customer support to schools included in their Advanced
                    Plan.
                  </div>
                </div>
              </div> -->
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse15"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">CAN THE PARENTS ACCESS THE APP? </span>
                </div>
                <div id="collapse15" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform facilitates parents as they play a key role in students’ learning future.
                    We have a separate sign-in for parents where they can collaborate with teachers on students’
                    performances, access notice boards, get notified when assessments are uploaded and their progress,
                    and view their children’s individual progress reports.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse16"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW CAN THE STUDENTS ACCESS THE APP?</span>
                </div>
                <div id="collapse16" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform students’ portal allows students to access their online classes according
                    the timetable, Assignments, Assessments. Students can directly message other students and teachers
                    and be part of groups. It all counts towards effective class participation.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse17"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">DO TEACHERS AND ADMINISTRATORS HAVE SEPARATE DASHBOARDS? </span>
                </div>
                <div id="collapse17" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Our single sign-in application supports different user levels. This is why we have a separate
                    dashboard for students, teachers, administration, and parents. The teachers and school
                    administrations can access their respective dashboard features that enhance and focus on students’
                    learning as an end result.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse18"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WHAT TYPE OF PERFORMANCE REPORTS DOES HYBRID LEARNING PLATFORM PROVIDE? </span>
                </div>
                <div id="collapse18" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning platform supports for students and teachers performance, assignments and assessment
                    performance reports, and graphical annual portfolio.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse19"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">CAN WE TRY HYBRID LEARNING PLATFORM FOR FREE? </span>
                </div>
                <div id="collapse19" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    We believe in ‘seeing is believing’. Hybr id Learning platform offers a demo run of our web
                    application before you can proceed to on-board your school with the best collaborative and
                    personalized LMS.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse20"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW LONG DOES HYBRID LEARNING PLATFORM TAKE TO ONBOARD OUR LEGACY LEARNING SYSTEM?
                  </span>
                </div>
                <div id="collapse20" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    How quickly you can sign the agreement. Hybrid Learning platform can on-board your entire school or
                    educational institute within 5 days.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse21"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">DOES HYBRID LEARNING PLATFORM FACILITATE ONLINE EXAMS AND ASSESSMENTS? </span>
                </div>
                <div id="collapse21" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Yes, we facilitate online Assessments/exams, that allow teachers to view the students through their
                    webcam. Additionally, we support AI-based user authorization and time-stamped reports that include
                    tab-switching and face-tracking.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse22"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">IS HYBRID LEARNING PLATFORM INTEGRATED WITH ZOOM OR GOOGLE MEET? </span>
                </div>
                <div id="collapse22" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning platform understands, as the best collaborative and personalized LMS, the importance
                    of integrating top video-conferencing software to facilitate easier collaboration amongst teachers
                    and students. This is why we have integrated Zoom and Google Meet with Hybrid Learning platform.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse23"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">DOES HYBRID LEARNING PLATFORM SUPPORT INSTANT MESSAGING?</span>
                </div>
                <div id="collapse23" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Our instant messaging feature is one of the key Hybrid Learning platform features that resemble the
                    top instant messaging applications. It not only allows easier collaboration amongst students but
                    also with teachers-students and parents-teachers. Moreover, it allows the administration to value
                    their image and monitor the exchange between students.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse24"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW MANY SUBJECTS PER CLASS DOES HYBRID LEARNING PLATFORM SUPPORT? </span>
                </div>
                <div id="collapse24" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    There is no limit on the number of classes you host. You can have unlimited number of subjects and
                    classes in your school.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse25"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW DO TEACHERS MANAGE THE ASSESSMENTS? </span>
                </div>
                <div id="collapse25" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning platform facilitates teachers by allowing them to post scheduled, subject-wise
                    assessments/exams. They can then share the assessment snippet through the instant messaging system
                    and receive submission notices as soon as a student submits the assessment.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse26"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW IS TEACHERS’ PERFORMANCE MEASURED? </span>
                </div>
                <div id="collapse26" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    With our automated system, you get daily reports on which teacher is contributing to the learning of
                    your students the most and which teacher is lagging behind.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse27"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">DOES HYBRID LEARNING PLATFORM SUPPORT AN AUTOMATED NOTIFICATION SYSTEM? </span>
                </div>
                <div id="collapse27" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Our instant push notification system allows the administration to post role-based notices that can
                    be accessed by students, teachers, and parents. Hybrid Learning platform automated notification
                    system supports attachments that allow the administration to attach multimedia such as circulars or
                    event posters, etc. to their announcement.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse28"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW DO I CHOOSE A GOOD SCHOOL DURING THE PANDEMIC? </span>
                </div>
                <div id="collapse28" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Any school that values learning will have an effective learning management system that maximizes the
                    students’ learning experience. Learn more about how Hybrid Learning platform helps schools in their
                    teaching journey here.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse29"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">DOES HYBRID LEARNING PLATFORM SUPPORT MULTIPLE DEVICES? </span>
                </div>
                <div id="collapse29" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Being a web-based and app-based Learning Management System, Hybrid Learning platform supports
                    multiple devices. Web-based applications can run on any browsers.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse30"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW DOES HYBRID LEARNING PLATFORM RECORD ATTENDANCE? </span>
                </div>
                <div id="collapse30" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning Platform records attendance through its automated attendance management system.
                    Parents receive notifications for students’ attendance as well.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse31"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">HOW IS HYBRID LEARNING PLATFORM DIFFERENT FROM OTHER LEARNING MANAGEMENT SYSTEMS?
                  </span>
                </div>
                <div id="collapse31" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    We focus on you and the learning journey your students are upon. This enables Hybrid Learning
                    platform to create features that enhance the learning process for teachers and students alike,
                    simultaneously enabling you to measure the learning process in terms of students’ performance.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse32"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">AS HYBRID LEARNING PLATFORM IS A SINGLE APP, HOW DO YOU PREVENT USERS FROM
                    ACCESSING DATA BESIDES THEIR ROLE?</span>
                </div>
                <div id="collapse32" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning platform has a Role-Based Access Control (RBAC) System as well as an Attribute-Based
                    Access Control System (ABAC). Different roles have different access controls, and each role can have
                    access based on certain attributes as well.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent">
                <div class="card-header collapsed bg-transparent" data-toggle="collapse" data-target="#collapse33"
                  aria-expanded="false">
                  <span class="icon-arrow"><img src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                      class="mr-4 h-25px"></span>
                  <span class="title">WILL HYBRID LEARNING PLATFORM WORK IF MY INTERNET CONNECTION IS SLOW? </span>
                </div>
                <div id="collapse33" class="collapse" data-parent="#accordionExample">
                  <div class="card-body">
                    Hybrid Learning platform understands all its users’ needs. We understand not everyone has access to
                    large bandwidths at all times and accessing/posting data, such as notifications or checking marks
                    and reports may be urgent, at times. This is why we support low bandwidth internet connection as
                    well.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>