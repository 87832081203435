export class Event {
    EventId:number;
    UserId:number;
    EventDate:string;
    StartTime:string;
    StartTimeISO:string;
    EndTime:string;
    EndTimeISO:string;
    Description:string;
    // ClassId:number;
    IsMeeting:boolean;
    IsActive:boolean;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    AcademicYearId:number;
    RoomId:string;
}
export class EventAtionData {
    EventId:number;
    Action_Type:string;
}
export class EventViewData{
    EventInfo: Event;
    ClassMappingList:Array<any>;
    IsSaved:boolean;
    ErrorMessage:string;
    EventListInfo:Array<Event>;
}
