import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/shared/event.service';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-feedback-component',
  standalone: true,
  imports: [CommonModule,FormsModule,MatCheckboxModule],
  templateUrl: './feedback-component.component.html',
  styleUrl: './feedback-component.component.scss'
})
export class FeedbackComponentComponent{
  rating :number=0;
  feedback: any;
  constructor(public eventService:EventService,public bsModalRef: BsModalRef) {}
  ngOnInit(): void {}
  rate(arg0: number) {
    if(arg0 == this.rating)
      this.rating=0
    else
      this.rating=arg0;
  }
  submit(){
    const eventData=JSON.parse(localStorage.getItem("eventData"))
    console.log(this.feedback);
    
    const actionData={
      "EventId":eventData.EventId,
      "Rating":this.rating,
      "Feedback":this.feedback
    }

    this.eventService.saveFeedbackInfo(actionData).subscribe();
  }
}
