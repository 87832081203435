import { Component, OnInit } from '@angular/core';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CustomHeader } from '../custommenu/custom-header.component';
import { SchoolSharedDocService } from '../shared/school-shared-doc.service';
import { CUSTOMCONST } from '../shared/Const';
import { EditASSDocComponent } from './edit-assdoc.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-school-shared-doc',
    templateUrl: './school-shared-doc.component.html',
    styleUrls: ['./school-shared-doc.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class SchoolSharedDocComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  SSDListData: any[];
  public bsModalRef:BsModalRef;

  constructor(public SSDService :SchoolSharedDocService,private modalService: BsModalService,public commonService:CommonService) {
    this.gridInit();   
    this.commonService.RefreshGrid$.subscribe(
    
      item => {
        this.getAdminShareListDocView();
        }
    );
   }

  ngOnInit(): void {
  }
  gridInit(){
    
    this.columnDefs = [
      { 
        headerName:"shareDocId",
        field: 'shareDocId', cellRendererFramework: CustommenuComponent, 
        cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editSDFile , that : this}, {Name: "Delete", "Click" : this.deleteDocument , that : this}]},
        
        filter:false, width:60,maxWidth: 60,  
        headerComponent: 'customHeader', 
        headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      }, 
     
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
    { field: 'PurposeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
    { field: 'SchoolCategory', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200 ,maxWidth: 300 },
    { field: 'TypeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 200 },
    { field: 'StateId', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 300 ,maxWidth: 300 },

    
   ];
   this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
 this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   this.getAdminShareListDocView();
  }

  getAdminShareListDocView(){
    this.SSDService.getAdminShareListDocView().subscribe((res : any) => {
      this.SSDListData =  res.AdminShareDocListInfo;
      console.log(this.SSDListData);
      // this.ownDocsServ.formData = res;
    });
  }
  editSDFile(id,that){
    that.openAdminSharedModel(id)
  }
  deleteDocument(id,that){
    that.SSDService.getowndocument(id).subscribe((res : any) => {
      that.SSDService =  res;
      
      if(res){
        that.SSDService.IsActive = false
        that.SSDService.deleteowndocument().subscribe((res:any)=>{
          if(res.IsSaved){
            that.notificationServ.ShowInfo('Successfully deleted!');
            that.getAdminShareListDocView();
          }else{
            that.notificationServ.ShowError(res.Message);
          }
        });
      }
     });
  }

  openAdminSharedModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditASSDocComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
}
