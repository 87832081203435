<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Assignments</h3>
        <a class="add_button" routerLink="#" (click)="openquestionmodal(0)">Add Assignments</a>
    </div>
    <div class="field-row filter-row ">
        <div class="select_field">
            <label>Academic Year</label>

            <select class="field-control" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()"
                required>
                <option *ngFor="let year of yearList" [value]="year.Code">
                    {{year.Name}}
                </option>
            </select>

            <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
        </div>
        <div class="filter_option">
            <span (click)="serchAssignment()" class="filter_button"><i class="bi bi-search"></i> Search</span>
            <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>
            <span *ngIf="commonServ.role == 'SuperAdmin' || commonServ.role == 'Admin'" (click)="onBtExport()"
                class="filter_button"><i class="bi bi-file-earmark-arrow-down"></i> Export</span>
        </div>
    </div>
    <div class="field-row filter-form collapse" id="collapseExample">
        <div class="select_field">
            <label>Name</label>
            <input type="text" class="field-control" name="Name" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Start Date</label>
            <input type="text" class="field-control" name="StartDate" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>End Date</label>
            <input type="text" class="field-control" name="EndDate" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Class</label>
            <input type="text" class="field-control" name="ClassNames" (keyup)="filter($event)" />
        </div>
    </div>

    <div class="custom-table">
        <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Class</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredrowData.length == 0">
                no data available
            </tbody>
            <tbody *ngIf="filteredrowData.length > 0">
                <tr *ngFor="let data of filteredrowData">
                    <td>{{data.Name}}</td>
                    <td>{{data.StartDate}}</td>
                    <td>{{data.EndDate}}</td>
                    <td>{{data.ClassNames}}</td>
                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-pencil-square"
                                title="Edit assignment" (click)="openquestionmodal(data.AssignmentId)"></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-repeat"
                                title="Assign Classes" (click)="openAssignClassesModal(data.AssignmentId)"></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-list-ul"
                                title="Answer List" (click)="getAnswerList(data.AssignmentId)"></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- <div class="col-md-12 p-0">
        <ag-grid-angular #agGrid style="width: 100%; height:500px" class="ag-theme-alpine" id="myGrid"
            [gridOptions]="gridOptions" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [rowSelection]="rowSelection" [rowData]="rowData" (selectionChanged)="onSelectionChanged($event)"
            (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
        </ag-grid-angular>
    </div> -->
</div>