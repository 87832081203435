import { formatDate, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { Analysis, AnalysisQuestion, AnalysisView, StudentAnalysisData, StudentsAnalysisData } from '../shared/analysis.model';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { AnalysisQuestionairComponent } from './analysis-questionair.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-analysis',
    templateUrl: './analysis.component.html',
    styleUrls: ['./analysis.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, MatIconModule, TranslateModule]
})
export class AnalysisComponent implements OnInit {
  list:any[]=[];
  gridApi;
  
  analysisRowData;
  
  analysisInfo:Analysis;
  currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  classList:any[];
  categoryList:any[];
  CUSTOMCONST:any=CUSTOMCONST; 
  role:string;
  public  questionObj: AnalysisView;
  scoreList:any[]= [
    { Id:1,Name:1},
    { Id:2,Name:2},
    { Id:3,Name:3},
    { Id:4,Name:4},
    { Id:5,Name:5},
    { Id:6,Name:6},
    { Id:7,Name:7},
    { Id:8,Name:8},
    { Id:9,Name:9},
    { Id:10,Name:10},
    
  ];
  questionInfo = new AnalysisQuestion();
  count:number=1;
  questionNo:any[]=[];
  constructor(public analysisServ:AnalysisService,  public modalService:BsModalService,
    public bsModalRef: BsModalRef,public commonServ:CommonService,private notificationServ:NotificationService) {  
    this.role = this.commonServ.role;
    this.questionObj = new AnalysisView();
   

}

  ngOnInit(): void {
   this.getAnalysisList();
  }

 
  getAnalysisList(){
    this.analysisServ.getanalysisview(this.list[0].id).subscribe((res : any) => {

      this.analysisServ.formData = res;
     
      var yearlist = res.AcademicYearList.slice().reverse();
      this.yearList = yearlist;
      this.selectedYear = yearlist[0].Code;
      this.classList = res.ClassList;
      this.categoryList = res.CategoryList;
     
      // console.log(this.categoryList);
      // debugger;

      
      if(this.analysisServ.formData.AnalysisQuestionListInfo.length<=0){
        var Question1 = new AnalysisQuestion();
      Question1.IsActive = true;
      Question1.Question = '';
      Question1.CategoryId = 0;
      Question1.Score = 10;
      Question1.MaxScore =0;
      Question1.AnalysisQuestionId=0;
      Question1.AnalysisMasterId=0;
      this.questionNo.push(this.count);
      Question1.OrderNo = this.analysisServ.formData.AnalysisQuestionListInfo.length+1;
      this.analysisServ.formData.AnalysisQuestionListInfo.push(Question1);
      
      }
    });
  }
 
 
  saveAnalytics(){

    if(this.analysisServ.formData.AnalysisQuestionListInfo.length<=0)
  this.analysisServ.formData.AnalysisQuestionListInfo = new Array<AnalysisQuestion>();
  
    this.analysisServ.saveAnalysisView().subscribe((res : any) => {
      if(res.IsSaved === true){
        this.notificationServ.ShowInfo('Successfully Saved!');
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      }
    });
  }
 
  addNewRow(){
  
      var Question1 = new AnalysisQuestion();
      Question1.IsActive = true;
      Question1.Question = '';
      Question1.CategoryId = 0;
      Question1.Score = 10;
      Question1.MaxScore =0;
      Question1.AnalysisQuestionId=0;
      Question1.AnalysisMasterId=0;
      Question1.OrderNo = this.analysisServ.formData.AnalysisQuestionListInfo.length+1;
      this.analysisServ.formData.AnalysisQuestionListInfo.push(Question1)
  }
 
  deleteRow(rowIndex:number)
{
  
  this.analysisServ.formData.AnalysisQuestionListInfo.forEach((element,index)=> {
  if(index== rowIndex){
    this.analysisServ.formData.AnalysisQuestionListInfo[index].IsActive = false;
   
  }
});
// debugger;
 for(var i =0;i<this.analysisServ.formData.AnalysisQuestionListInfo.length;i++){
  this.analysisServ.formData.AnalysisQuestionListInfo[i].OrderNo = i+1;
 }
 
}

  
}
