<section class=" pt-2">
    <div class="container-fluid">
        <span class="d-block text-center h5 theam-color-3 section-title">Life Skills</span>
        <!-- <span class="text-center theam-color-2 p-3 tex-decoration-underline display-5 d-block">Life Skills</span> -->
        <p>Life skills is a term used to describe a set of basic skills acquired through learning and/or direct life
            experience that enable individuals and groups to effectively handle issues and problems commonly encountered in
            daily life.
        </p>
        <p>
            They include creativity, critical thinking, problem-solving, decision-making, the ability to communicate and collaborate, along with personal and social responsibility that contribute to good citizenship – all essential skills for success in the 21st century, both for healthy societies and for successful and employable individuals.
        </p>
        <h3>
            Life skills touch upon issues that are:
        </h3>
        <ul>
            <li>real: they actually affect people’s lives</li>
            <li>sometimes sensitive: they can affect people on a personal level, especially when family or friends are involved</li>
            <li>Often controversial: people disagree and hold strong opinions about them</li>
            <li>Ultimately moral: they relate to what people think is right or wrong, good or bad, important or unimportant in society.</li>
        </ul>
    
        <h1 class="font-weight-bold">Why do we need to teach life skills?</h1>
        <p>
            Democracies need active, informed and responsible citizens, who are willing and able to take responsibility for themselves and their communities and contribute to the political process.
        </p>
        <p>
            Democracies depend upon citizens who, among other things, are:
        </p>
    <ul>
        <li>Aware of their rights and responsibilities as citizens</li>
        <li>Informed about social and political issues</li>
        <li>Concerned about the welfare of others</li>
        <li>Able to clearly articulate their opinions.</li>
        <li>Capable of having an influence on the world</li>
        <li>Active in their communities</li>
        <li>Responsible in how they act as citizens.</li>
    </ul>
    <P class="pt-0">
        These capacities do not develop unaided; they have to be learnt. While certain life skills may be acquired through our everyday experience in the home or at work, they are not sufficient to adequately equip citizens for the active role required of them in today’s complex and diverse society.
    </P>
    <p>
        If citizens are to become genuinely involved in public affairs, then a more systematic approach towards citizenship education is essential.
    
    </p>
    <h1 class="font-weight-bold">
        How does training in life skills benefit young people?
    
    </h1>
    
    <ul>
        <li>It helps them to develop self-confidence and successfully deal with significant life changes and challenges, such as bullying and discrimination.</li>
        <li>It gives them a voice at school, in their community and in society at large.</li>
        <li>It enables them to make a positive contribution by developing the expertise and experience they need to assert their rights and understand their responsibilities, while preparing them for the challenges and opportunities of adult and working life.</li>
    </ul>
    <h1 class="text-center tex-decoration-underline theam-color-2">Pottery</h1>
    <p>
        Do you remember the first time you touched clay when your were kid. Yes the feeling of freedom to make anything and give your best to create something new. It gives wings to your imagination and calms you.
    
    </p>
    <p>
        Pottery is one of the most creative activity your kid would love to experience. Kids are full of imagination and energy, pottery gives them the platform for using it in right direction.
    </p>
    <h3 class="font-weight-bold mb-2">Introduction</h3>
    <p>
        Culture is regarded as the complex system of meaning and behavior that defines the way of life for a given to society. Culture includes ways of thinking as well as patterns of behavior. Observing culture involves studying what people think, how they interact, and the objects they use. In any society, culture defines what is perceived as beautiful and ugly, right and wrong, or good and bad. Culture helps to hold society together, giving people a sense of belonging, instructing them on how to behave, and telling them what to think in particular situations.
    </p>
    <h3 class="font-weight-bold mb-2">So why to choose Pottery?</h3>
    <h3 class="font-weight-bold">Benefits of pottery for children -</h3>
    <h3 class="font-weight-bold">Imagination & Self esteem</h3>
    <p>
        Kids are full of imagination, they can imagine what you have never thought of. Pottery is way by which they can express and give shape to their imagination. When they can create something with their hands it gives them confidence and open more doors to their imagination and creativity.
    
    </p>
    <h3 class="font-weight-bold">Sensory and motor skills development</h3>
    <p>
        Pottery class can help in sensory and motor skills development in many ways. They use their arms and hand muscles for molding the clay and making different shapes. In a way they are learning the usefulness of their hands. They are working on fine motor skills development by using hand eye co-ordination and trying to balance with the moving wheel.
    </p>
    <p>
        This whole development will help them. They will learn to write properly and concentrate.
    </p>
    <h3 class="font-weight-bold">Calming effect</h3>
    <p>
        Pottery involves playing with clay and it gives the most calming effect. You can notice how kids engage themselves in clay molding. Even the hyperactive kids enjoy clay and focus on building something new with their imagination. The soothing qualities of clay keeps them engage for long time.
    
    </p>
    <h3 class="font-weight-bold">Imagination to responsibility, things kids can learn through pottery</h3>
    
    <p>
        "With clay, you can engage a kid for long durations of time, as well as have the whole family participate in a group activity."
    </p>
    <p>
        Almost all children are given clay to play with when they’re young, mostly for fun. However, a few take to this wonderful medium and explore their creative side. We spoke with some teachers to find out what benefits come with learning pottery.
    </p>
    
    <h3 class="font-weight-bold">Here are the health benefits of taking up pottery.</h3>
    <ul>
        <li>
            
            <p>
                <span class="h5">Creative outlet –</span>
                There are both physical and mental benefits from expressing yourself by creating something. Art offers an outlet and a release from all of that. With pottery you can produce something and express yourself in some way.
    
            </p>
        </li>
        <li>
            <p>
                <span class="h5">Reduces stress –</span>
                Our hands are an outlet for creativity, the sense of touch is of high importance. A lot of focus is required while you’re making pottery, therefore outside distractions are reduced and no longer stress you out.
    
            </p>
        </li>
        <li>
            <p>
                <span class="h5">Exercises the hands, wrists, and arms –</span>
                The movement of making pottery is gentle yet strengthening to the hands, wrists, and arms. This can be beneficial to those prone to arthritis in the hands, as it promotes joint movement and dexterity.
    
            </p>
        </li>
        <li>
            <p>
                <span class="h5">Improve quality of life –</span>
                Art is an important hobby for self-expression. It is a good way to connect with yourself by expanding your body and mind. Embarking on new creations, learning new techniques and finishing your creations can contribute to a lifetime commitment of learning and maintaining a productive hobby.
    
            </p>
    
        </li>
    </ul>
    <h3 class="font-weight-bold">Benefits of pottery for kids</h3>
    <ul>
        <li>
            You will never forget seeing your child touching clay for the first time. The look on their little faces when they are busy squishing, pinching, rolling, pulling, and generally shaping the clay to their will is beautiful and exciting.
        </li>
        <li>
            It gives them the freedom to express their creativity and the ability to change something with their own hands.
    
        </li>
    
    </ul>
    <p>
        Pottery kids often laugh and express joy at the feelings, smells, and sounds that clay can make. Manipulating the clay can be also simple first lesson for children, to help them understand that their actions influence the outside world and the art which they create.
    
    </p>
    <h3 class="font-weight-bold">Conclusion</h3>
    <p>
        From the discussion presented in this paper is it clear that pottery is an amalgam of many things. In the late twentieth century, we may not be aware of many of the attributes, considerations, and hidden meanings that are built into pots of old, or of their importance to the cultures that made them. More often than not, we are only aware of the form or surface itself, and of one culture’s forms in relation to those of another.
    
    </p>
    <h1 class="text-center tex-decoration-underline theam-color-2">Sewing</h1>
    <h3 class="font-weight-bold">Why Sewing is important for your child?</h3>
    <p>
        Learning to sew can save money. There is a sense of accomplishment that comes with being able to make something on your own. Sewing is a great hobby and you'll never be feel you learned how! It's the hobby that continues to give for as long as you sew.
    
    </p>
    <p>
        Sewing your own clothes is a way to dress yourself in exactly the kind of style you want. Clothing bought in stores is expensive and often does not fit right. Sometimes the result is spending more money on alterations than you originally did when purchasing the item.
    
    </p>
    <p>
        Sewing is an important Life Skill to teach self-confidence through skill building. Sewing helps you develop fine motor skills, improves your focus and concentration and teaches the importance of patience and self-control. Knowing personal boundaries, increasing skill, achieving tangible goals while working outside your comfort zone all support the development of confidence and self-esteem.
    
    </p>
    <p>
        Let’s teach our kids and teens the skills they will carry with them throughout life.
    </p>
    <p>
        If you want your child to have a hobby that will have a positive impact on their character development and mental growth, consider teaching them how to sew. Apart from allowing them to sew on a button, fix their toys or make a nice and original gift for someone, sewing can provide them with educational, physical, and mental benefits in an interesting and fun way. On top of that, it can also become a valuable skill in their lives, one which will prove itself useful over and over, all the way to their adult lives and further.
    <br>
    With that in mind, we can easily say that teaching your children how to sew is a very smart course of action and that they'll be very grateful to you for it once they grow up. In order to demonstrate the advantages of sewing, we have prepared this which explains how exactly it benefits children and why. Having said that, let's take a look at the following list of benefits together.
    
    </p>
    <h3 class="font-weight-bold">3. With sewing, they'll learn how to be patient</h3>
    <p>
        Most kids don't have a lot of patience, when they want something, they want it right away - waiting is unacceptable. Explaining that it's impossible for them to get the thing immediately could prove itself to be pointless.
    
    </p>
    <p>
        But if they learn that from their own example, empirically, they'll perfectly understand that waiting is necessary sometimes. Sewing can teach them about patience in a subtle but very effective way. Once they realize that they can't sew before threading the needle, can't cut fabric before marking it, can't make shortcuts while stitches two pieces of fabric together and so on, they'll slowly but surely start learning how to be more patient.
    </p>
    <h3 class="font-weight-bold">4. Sewing will help them to practice communicating and follow instructions</h3>
    <p>
        Children can't learn how to sew on their own, they need someone to teach them and explain all about it to them. If you decide to do just that, at first, they might ignore your words and try to do their own thing, to sew in their own way. Naturally, that won't work so they'll eventually start listening to your instructions. In order to avoid failure, they will most likely start following your guidelines perfectly, which will also apply to your everyday lives, not just when it's sewing time.
    </p>
    <p>
        If they don't understand what you're telling them to do, they'll try to express that as best as they can. In other words, they'll start improving their communicating skills by explaining the difficulties they're having. This too, will become useful in their everyday lives, because they'll be able to express their thoughts more easily and precisely at all times.
    </p>
    <h3 class="font-weight-bold">5. Sewing will make them use their imagination and creativity</h3>
    <p>
        Creativity and imagination go hand in hand with each other, that's a well-known fact. And since kids have a very powerful imagination, they can put it to good use with sewing. With its help, they can come up with very unique and fascinating ideas, maybe even some that are far better than those of adults.
    </p>
    <p>
        The best part about this is the fact that they won't be able to neglect their creativity and imagination and let them deteriorate because sewing requires regular use and therefore improvement of both.
    </p>
    <p>
        So, if your child who sews suggests a peculiar sewing project, let them try it out, it might turn out to be something really amazing and original. Either way, it will be something creative without a doubt.
    </p>
    <p>
        Now it's time for us to make a brief recap of this topic before we part ways. Sewing is definitely a great hobby for kids because it will teach them how to create unique items, fix broken ones, or alter some of the clothes or toys they possess.
    </p>
    <p>
        But sewing does more than just that since it also helps them to improve abilities like hand dexterity, communication skills, as well as their character traits like confidence, patience, and creativity.
    </p>
    <p>
        In other words, it's one of the best hobbies a child could have.
    </p>
    <h3 class="font-weight-bold">Health benefits of sewing</h3>
    <h3 class="font-weight-bold">Stress relief</h3>
    <p>
        Sewing encourages mindfulness and reduces stress and anxiety. The meditative action of sewing encourages positivity and feelings of relaxation. Being forced to concentrate on one particular task enables you to become immersed in a situation and truly unwind.
    </p>
    <h3 class="font-weight-bold">Improved hand-eye coordination</h3>
    <p>
        If you’re keen to enhance your motor skills, sewing does just that; especially hand-eye-coordination. The attention to detail that sewing requires encourages coordination and relieves physical issues such as back pain.
    
    </p>
    <h3 class="font-weight-bold">Brain growth</h3>
    <p>
        Sewing requires creativity, which improves the brain’s ability to grow new brain cells. As mental deterioration is a result of lost connection between neurons, sewing promotes mental growth.
    </p>
    <h3 class="font-weight-bold">Happiness</h3>
    <p>
        Like other craft activities, sewing increases dopamine in the brain, which makes us feel more positive.
    </p>
    <h1 class="text-center tex-decoration-underline theam-color-2">Gardening </h1>
    <p>
    Gardening is a fun for indoor and outdoor activity we can share with children. 
    It teaches patience and careful watchfulness; it teaches industry and thrift; above all it teaches entire trust." These are lofty character traits—ones we want our children to gain. Teaching kids to garden also encourages an attitude of conservation and respect for nature. Perhaps most importantly, though, gardening with children is downright fun. Children are natural explorers who find joy in the simple pleasures of planting seeds or searching for earthworms.
    <br>
    Gardening doesn't have to be expensive, complicated, or time-consuming. With just a few minutes a day, you can grow a simple garden project with children. 
    </p>
    <p>
        As you plan gardening activities with children, consider the growing season and climate in your area. Choose plants known to grow well there. Think about your schedule, including vacations, and plan accordingly. It's much better to grow a small garden successfully than plan an unrealistic larger project that ends with poor results. 
    </p>
    <h3>Benefits of Gardening</h3>
    <h3>1. Gardening helps children to learn.</h3>
    <p>
        Gardening is the study of life. The simple act of caring for living soil and plants gives children a foundation for understanding the principles of birth, growth, maturity, death, competition, cooperation and many other lessons that transfer to human lives. Children experience these lessons ‘hands on’ through a learning method that is rich and inclusive to varied learning abilities. The results teachers see every day are now supported by science: school gardens can help our children learn better, both academically and emotionally. 
    </p>
    <h3>2. Gardening together strengthens ties between family and community.</h3>
    <p>
        Gardening activity offers opportunities for community members to get involved, reducing the social isolation of seniors with skills to share and connecting children to older generations. They also help connect with neighbours to local businesses and groups when they request sponsorship or volunteer assistance.
    </p>
    <h3>3. Getting their hands dirty helps connect children with nature.</h3>
    <p>
        Children who garden get a close-up look at natural processes and the living organisms that thrive in these environments. By learning to care for a living, breathing ecosystem, children develop an understanding of nature’s importance in their lives and the lives of other beings. This fosters a culture of environmental stewardship.
    </p>
    <h3>4. Gardening strengthens children’s immune systems.</h3>
    <p>
        There’s more and more evidence that getting dirty exposes us to a variety of microbes that can fortify our health and balance our immune systems against our overly sterilized world. This is particularly true for children who benefit from reduced allergies and asthma when exposed early in life to dirt and the outdoors. The vitamin D they absorb when gardening doesn’t hurt, either!
    </p>
    <h3>5. Gardening helps children stay active, reducing obesity.</h3>
    <p>
        Experts across the country agree: when children garden, they move their bodies more than when passively listening in a classroom. Jumping, bending, lifting, and stretching all take place during a typical gardening session.
    </p>
    <h3>6. Children who garden at home develop empathy and practice risk.</h3>
    <p>
        Teachers who garden with their students notice increased empathy towards other students and the organisms living in their school patch. That’s because tending to a ‘bug hotel’ or watching birds and earthworms thrive in the garden helps children understand the interdependency of nature. A garden also provides the perfect place for children to learn about boundaries and responsibility by practicing new activities in a safe space. Using a paring knife, trying out a hammer, or balancing on the edge of a raised bed are all ways for children to test their edges and learn new skills in a supportive environment.
    </p>
    <h3>7. Teaching and food gardens improve children’s diets.</h3>
    <p>
        Academics and journalists agree: children who garden eat more fresh vegetables. This extends beyond what they nibble on during classroom gardening time. Apparently just having a garden at school increases their intake of vegetables at home. And that’s good news for parents, teachers, and kids.
    </p>
    <p>
        Whatever your reasons for wanting to start a gardening, the benefits are many. So how can you help a garden come to your home?
    </p>
    <h3 class="font-weight-bold">To provide outdoor, hands-on learning.</h3>
    <p>
        The classic teaching garden helps parents provide their students with real-life learning. The hands-on environment compliments lessons about plant and insect life cycles, along with experiential activities like building a Art, math, English, and social studies: all these lessons can find a home in the learning from gardening.
    </p>
    <h3 class="font-weight-bold">Find Your place.</h3>
    <p>
        Now that you know the main purposes for your garden, review available sites and determine which one is right for your needs. Along the way, consider the following questions.
    </p>
    <h3 class="font-weight-bold">How much space do you need to meet gardening at home?</h3>
    <p>
        there is no minimum place required. Places can be use like balcony, backyard in ground floor or terrace.
    
    </p>
    <h3 class="font-weight-bold"> Is there enough sun?</h3>
    <p>
        Direct exposure to sunlight is one of the most important needs your garden will have. 
    </p>
    <h3 class="font-weight-bold">What type of soil will you use?</h3>
    <p>
        While a traditional in-ground garden is the simplest to install, it requires that good quality soil be available outside in nurseries. 
    </p>
    <p>
        Consider Materials
        With your new design in hand, consider what materials need to make the most of your garden. This includes thinking about both the building and operational phases.
    </p>
    <h3 class="font-weight-bold">Recycled Plastic or Wood can use in balcony or terrace.</h3>
    <h4>Tools</h4>
    <p>
        Since most gardens are designed for one class to visit at a time, the number of tools will usually reflect the average class size at your school. The following list of materials was adapted from the Healthy Planet Foundation’s basic supply list for a schoolyard garden.
    </p>
    <p>
        Watering can <br>  
        Hand trowels  <br>
        Digging fork  <br>
        Gardening gloves <br>
        Spray nozzle
    </p>
    <h1 class="text-center tex-decoration-underline display-6 theam-color-2">Art & Craft</h1>
    <p class="font-weight-bold">Craft activities might get a little messy, but don't worry, arts and craft are brilliant for developing little minds and bodies. 
    </p>
    <p>
        Arts and crafts as a subject are typically taken for granted as a must for children and young kids in the formal education setup. But, over the past several years, many schools have unfortunately cut down on arts in their school curriculum. Music, painting, theatre- they are fast disappearing.
    </p>
    <p>
        There is no doubt that arts and crafts are fun activities for kids. Be it coloring with crayons or making miniature statues from clay, folding paper to create fine origami or designing a handmade birthday card, there are several arts and crafts activities, which can enhance the interest of the children and exploit their artistic potential.
    
    </p>
    <h3 class="font-weight-bold">Physical Benefits</h3>
    <ul>
        <li>Development of fine motor skills</li>
    </ul>
    <p>
        Since most arts and crafts activities consist of moving fingers and hands, they help in developing fine motor skills. Simple actions like holding a paintbrush and coloring with pencils help strengthen muscles and improve their control.
    
    </p>
    <ul>
        <li>Improvement of hand-eye coordination
        </li>
    </ul>
    <p>
        Engaging in activities related to arts and crafts from a very young age leads to a tremendous improvement in hand-eye coordination. This will help a child during later primary school years when she or he is spacing out words or forming letters.
    
    </p>
    <h3>Health Benefits of Arts and Crafts for Kids</h3>
    <p>
        Most children are happy to sit down to create arts and crafts. Whether they’re just finger painting or making something personalized and intricate, it seems to keep their attention. 
    </p>
    <p>
        This activity is more than just a bit of fun, though. Arts and crafts can also be beneficial to a child's health. Mainly, it helps to promote their development and mental health. Here, we are going to take a look at exactly how arts and crafts can be great for your child.
    </p>
    <h3 class="font-weight-bold">Think More Creativity</h3>
    <p>
        It might sound a little far fetched but art has been proven to help children succeed in school and academic pursuits
    </p>
    <p>
        This is, in part, thanks to the creative thinking and decision-making skills that art instills in children.
    
    </p>
    <p>
        How art instills creativity in a child is fairly straightforward. If you give a child a variety of craft supplies - paint, crayons, and more - you are likely to see something completely one of a kind. This also taps into a child’s inventiveness. In other words, they will learn to take risks and work around problems.
    
    </p>
    <p>
        Being able to think creatively and handle harder problems as they grow, these children will be less likely to be easily stressed by their school assignments. With less stress, they will have better mental health.
    
    </p>
    
    <h3 class="font-weight-bold">Stress Relief</h3>
    <p>
        Speaking of stress, arts and crafts can help a child on this front.
    
    </p>
    <p>
        Often, we don’t think of how stressed a young child can be. Starting school, creating and maintaining friendships, and handling homework can all be stressful.
    
    </p>
    <p>
        Just like when an adult is stressed, a child can destress by doing something they love or something generally therapeutic. This is where arts and crafts come in. As a matter of fact, it can help lower the “stress hormone”. They also work well as a distraction from everyday life.
    
    </p>
    <p>
        This is important for more than just short-term unhappiness, though. If stress continues unchecked, it can lead to physical problems such as trouble sleeping.
    
    </p>
    <h3 class="font-weight-bold">Instill Confidence</h3>
    <p>
        Arts and crafts can build a child’s confidence. This is important because a sense of confidence is more likely to be independent and take on responsibility. Not only this, though, but confident children are also likely to have a strong sense of self-worth and have better mental health moving forward.
    
    </p>
    <p>
        This comes from a few sources. First, there is the fact that they are creating something themselves. Especially when these art projects are personalized, children feel confident and empowered in the fact that they made something that no one will perfectly replicate. 
    
    </p>
    <p>
        What is really needed to solidify this is encouragement. If a child continues to create projects but never received any encouragement or positive feedback, they will feel less confident because they will feel like their creations are less valuable.
    </p>
    <h2 class="text-center tex-decoration-underline h3 d-block">Conclusion</h2>
    <p>
        Arts and crafts benefit our kids in so many ways. Whether it’s painting, drawing, sewing, music, dance, theatre, or any of the other arts, they all contribute to our children’s well-being. It helps them develop and learn to express themselves in different ways.
    
    </p>
    <p>
        It’s important, as parents, we make sure that our children have access to the arts, both in and out of school. You never know, it might be the path to a career later in life. Even if it isn’t, it will ensure our kids grow up well-rounded.
    
    </p>
    <span class="text-center theam-color-2 p-3 tex-decoration-underline display-5 d-block">Electronics</span>
    <p>
        Have you ever think? When is the right time to get started learning electronics? It might be a simple question, but the answer is very complicated. Are you making the right choice? While we initially thought this is a simple thing, it proved to be difficult to pinpoint the “right” age for someone to make the first steps in electronics.
    
    </p>
    <p>
        Most manufacturers of development boards are assuming the user is experienced in electronics, and, beyond the “what you do, you do it on your risk,” they don’t offer any safety sheets, least to say they indicate an age limit to use their products.
    
    </p>
    <p>
        As such, we have started to gather as much information regarding electronics kits for children, focusing on the intended age use. The result is a selection of electronic kits for kids and beginners.
    
    </p>
    <p>
        Kids are naturally curious about how things work, and with a new trend in hardware companies creating open source hardware products, it's a great time to teach kids about electronics. But modern technology can seem too complex to even begin to understand. So where do you start?
    
    </p>
    <p>
        For the past four years we have dedicated to teaching electronics to beginners, both kids and adults. 
    
    </p>
    <p>
        Professionals in the field may balk at this claim! And of course there are super-complex areas in electronics, as in every field. But that shouldn't discourage anyone from starting out. Take football for example: Is it hard to play football at a professional level? Of course! But that doesn't stop your eight-year-old daughter from playing. The same is true for building electronics.
    
    </p>
    <p>
        Whether you're a parent or teacher, an electronics engineer or total beginner, here are six tips for teaching your kids how to build electronics.
    
    </p>
    <p class="h2">Academic Effects</p>
    <p>The effects that classroom electronics have on academic achievement is under debate. Some studies show that using technology can help improve anything from reading comprehension skills to content-area learning. Others have found no effect, or even a negative effect. 
    </p>
    <p class="h2">Effects on Student Motivation</p>
    <p>
        Because electronics allow students to make their own choices about what they will learn and how they will learn it, students are more motivated to learn. Students also enjoy the fact they often get immediate feedback from a computer, that they can connect what they're learning to everyday technology, and that technology can have more engaging "bells and whistles" than a teacher can produce. Students who have shown little initiative with pen-and-paper work can often succeed much more easily when completing that same work on the computer, a fact that increases their confidence and motivation to succeed.
        Because electronics allow students to make their own choices about what they will learn and how they will learn it, students are more motivated to learn. Students also enjoy the fact they often get immediate feedback from a computer, that they can connect what they're learning to everyday technology, and that technology can have more engaging "bells and whistles" than a teacher can produce. Students who have shown little initiative with pen-and-paper work can often succeed much more easily when completing that same work on the computer, a fact that increases their confidence and motivation to succeed.
    
    </p>
    <p class="h2">Effects on Technical Skills</p>
    <p>
        Technology is here to stay, and students who use electronics in the classroom on a regular basis are able to master technology while they learn. Besides learning actual computer-related skills -- which will likely evolve before they enter adulthood or the workplace -- they also gain confidence around computers. Students with skilled, technical know-how can go on to use this knowledge outside of class as well.
    
    </p>
    <span class="text-center theam-color-2 p-3 tex-decoration-underline display-5 d-block">What are life skills?
    </span>
    <p>
        Life skills is a term used to describe a set of basic skills acquired through learning and/or direct life experience that enable individuals and groups to effectively handle issues and problems commonly encountered in daily life.
    </p>
    <p>
        They include creativity, critical thinking, problem-solving, decision-making, the ability to communicate and collaborate, along with personal and social responsibility that contribute to good citizenship – all essential skills for success in the 21st century, both for healthy societies and for successful and employable individuals.
    </p>
    <p>Life skills touch upon issues that are:</p>
    <ul>
        <li>real: they actually affect people’s lives</li>
        <li>sometimes sensitive: they can affect people on a personal level, especially when family or friends are involved</li>
        <li>Often controversial: people disagree and hold strong opinions about them</li>
        <li>Ultimately moral: they relate to what people think is right or wrong, good or bad, important or unimportant in society.
        </li>
    </ul>
    <p class="h2">Effects on Technical Skills</p>
    <p>Democracies need active, informed and responsible citizens, who are willing and able to take responsibility for themselves and their communities and contribute to the political process.
    </p>
    <p>
        Democracies depend upon citizens who, among other things, are:
    </p>
    <ul>
        <li>Aware of their rights and responsibilities as citizens</li>
        <li>Informed about social and political issues</li>
        <li>Concerned about the welfare of others</li>
        <li>Able to clearly articulate their opinions.
        </li>
        <li>Capable of having an influence on the world
        </li>
        <li>Active in their communities</li>
        <li>Responsible in how they act as citizens.</li>
    </ul>
    <p>
        These capacities do not develop unaided; they have to be learnt. While certain life skills may be acquired through our everyday experience in the home or at work, they are not sufficient to adequately equip citizens for the active role required of them in today’s complex and diverse society.
    </p>
    <p>If citizens are to become genuinely involved in public affairs, then a more systematic approach towards citizenship education is essential.</p>
    <p class="h2">How does training in life skills benefit young people?</p>
    <ul>
        <li>It helps them to develop self-confidence and successfully deal with significant life changes and challenges, such as bullying and discrimination.</li>
        <li>It gives them a voice at school, in their community and in society at large.</li>
        <li>It enables them to make a positive contribution by developing the expertise and experience they need to assert their rights and understand their responsibilities, while preparing them for the challenges and opportunities of adult and working life.</li>
    </ul>
    <span class="text-center theam-color-2 p-3 tex-decoration-underline display-5 d-block">Carpentry</span>
    <p>
        The importance of woodwork in childhood education – building confidence and providing a foundation for lifelong learning.
    
    </p>
    <p>
        
    </p>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <h3 class="font-weight-bold"></h3>
    <p>
        
    </p>
    </div>
</section>
