import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { DateService } from '../services/date.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { achievements } from '../shared/achievements.service';
import { StudentBookInfo, StudentBookViewInfo } from '../shared/achievements.model';
import { TranslateModule } from '@ngx-translate/core';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-upload-book',
    templateUrl: './upload-book.component.html',
    styleUrls: ['./upload-book.component.scss'],
    standalone: true,
    imports: [FormsModule, BsDatepickerModule, NgFor,
       //Ng7LargeFilesUploadLibModule,
        TranslateModule]
})
export class UploadBookComponent implements OnInit, OnDestroy {
  list:any[];
  CUSTOMCONST:any=CUSTOMCONST;
  datepickerConfig : BsDatepickerConfig;
  maxDate = new Date();
  minDate = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];
  format: string;
  fileType: string;
  url: any;
  urlSafe: any;
  fileUploading:boolean;
  booksourceList:any=[];
  DocId:number;
public bsModalRef:BsModalRef;
  constructor(public service:achievements,private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService,public _dateSer :DateService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    // this.minDate.setDate(this.minDate.getDate() - 0);
    // this.maxDate.setDate(this.maxDate.getDate() + 30);
    // this.bsRangeValue = [this.bsValue, this.maxDate];
    // this.role = commonService.role;
    // this.timeSlotList = this._dateSer.gettimeslotlist();
    }

  ngOnInit(): void {
    // this.service.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
    console.log(this.list[0].id);
    this.getStudentBookInfo();
  }
  enddateSetting(){
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.bsRangeValue = [this.bsValue, this.maxDate];

  }
  saveDocument(){
    this.service.formDataReadingBooks.IsSave = true;
    this.service.formDataReadingBooks.StudentBookInfo.IsActive=true
    this.service.saveStudentBookInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        // this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        if(this.list[0].id >0)
        {
          this.notificationServ.ShowInfo('Data Successfully Updated!');
          this.commonServ.CallOnRefreshAchievements("Book");
        }
        else{
          this.notificationServ.ShowInfo('Successfully Saved!');
          this.commonServ.CallOnRefreshAchievements("Book");
        }

      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }

    })

  }

  largefileschange(event){
    // console.log(event);

  }

  filesuploaded(fileInfo){
    let fileData:any[]=[];
    if(fileInfo && fileInfo.IsSaved){
      fileData.push(fileInfo.UserDocumentId);
      this.service.formDataReadingBooks.StudentBookInfo.DocumentIdList=fileData;
    }


  }
  // qAnswerFilesuploaded(fileInfo, index){
  //   if(fileInfo && fileInfo.IsSaved){
  //       this.questionInfo.QAnswerList[index].FileId = fileInfo.UserDocumentId;
  //       this.questionInfo.QAnswerList[index].FilePath = this.baseurl+'ReportFiles/'+fileInfo.FilePath;
  //   }


  // }
  selectDocumentFile(event){

    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if(file.type.indexOf('pdf')> -1){
        this.format = 'pdf';
        this.fileType='';

      }
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      }
      if(this.format == 'pdf' || this.format == 'image')
      {
        this.fileUploading = true;
        reader.onload = (event) => {

        }
      }
      else{
        this.notificationServ.ShowError('Invalid file format! Please select PDF file only' );
      }
    }
  }

  getStudentBookInfo(){
    // debugger;
    this.service.getStudentBookInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.service.formDataReadingBooks = res;


    })
  }
  closeModel(){
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
  }
  ngOnDestroy(){
    // this.service.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
  }
}
