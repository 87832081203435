
  <div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >Side Menu Setting</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
           <form novalidate #form="ngForm" (submit)="onSavemernu()" autocomplete="off">
            <section class="p-2">
              <div class="row justify-content-c m-0  bg-white  p-2">
                  <ul class="list-sidebar bg-defoult clear-both mt-3">
                      <li class="col-md-12 p-0" *ngFor="let menu of menuItems; let parentMenuIndex = index">
                        <div class="row m-0">
                          <div class="col-6 d-flex align-items-center">
                            <mat-checkbox class="example-margin" name="IsActive{{parentMenuIndex}}" #IsActive{{parentMenuIndex}}="ngModel"
                              [(ngModel)]="service.formData.MenuInfo[parentMenuIndex].IsActive">{{menu.Name}}
                            </mat-checkbox>
                          </div>
                            <div class="col-6">
                              <mat-form-field class="w-100 pb-0">
                                  <input matInput name="Icon{{parentMenuIndex}}" [(ngModel)]="service.formData.MenuInfo[parentMenuIndex].Icon" placeholder="Icon">
                              </mat-form-field>
                          </div>
                        </div>
                          <ul>
                              <li *ngFor="let subMenuItem of menu.ChildMenuList ; let childListIndex = index" >  
                                  <mat-checkbox 
                                  [disabled]="!service.formData.MenuInfo[parentMenuIndex].IsActive" 
                                  class="example-margin h-30px lh-30px" 
                                  name="IsActive{{parentMenuIndex}}{{childListIndex}}" 
                                  #IsActive="ngModel" 
                                  [(ngModel)]="service.formData.MenuInfo[parentMenuIndex].ChildMenuList[childListIndex].IsActive">{{subMenuItem.Name}}</mat-checkbox>
                              </li>
                          </ul>
                      </li>
                  </ul>
                  
              </div>
          </section>
           </form>
        </div>
        <div class="modal-footer p-1">
          <button type="button" class="btn btn-success" (click)="onSavemernu()">Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
