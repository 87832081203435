import { formatDate, NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { ClassDairyInfo, ClassDairyViewInfo, TeacherInfo } from 'src/app/shared/dairy.model';
import { DairyService } from 'src/app/shared/dairy.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-editclass-dairy',
    templateUrl: './editclass-dairy.component.html',
    styleUrls: ['./editclass-dairy.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TranslateModule]
})
export class EditclassDairyComponent implements OnInit {
  list: any[] = [];
  datepickerConfig: BsDatepickerConfig;
  classList: any[] = [];
  subjectList: any[] = [];
  teacherList: any[] = [];
  minDate = new Date();
  selectedClass: number;
  selectedDate: string;
  selectedSubject: number
  config = {
    params: {
      classId: 1,
      dairyDate: formatDate(new Date(), 'dd-MM-yyyy', 'en'),
    }
  };


  constructor(public dairyServ: DairyService, public bsModalRef: BsModalRef, private notificationServ: NotificationService,
    private commonService: CommonService, public modalService: BsModalService, public userServ: UserService) {
    this.datepickerConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center' });
    this.minDate.setDate(this.minDate.getDate() - 0);

  }

  ngOnInit(): void {
    this.getClassList();
    if(this.dairyServ.formData.ClassDairyInfo.length <= 0 || this.list[0].id <= 0){
      this.dairyServ.formData.ClassDairyInfo = [new ClassDairyInfo()];
    }
    this.selectedClass = this.dairyServ.formData.ClassDairyInfo[0].ClassId;
    this.selectedDate = this.dairyServ.formData.ClassDairyInfo[0].DairyDate;
    //this.subjectList = this.dairyServ.formData.SubjectInfo;
    console.log(this.list);
  }
  getClassList() {
    this.commonService.getclasses().subscribe((res: any) => {
      this.classList = res;
    });
  }
  getStudentDairy() {
    this.dairyServ.getClassDairyViewInfo(this.config).subscribe((res: any) => {
      this.dairyServ.formData = res;
      console.log(res);     
    })
  }

  onSelectDate(event : Event){
    const selectElement = event.target as HTMLSelectElement;
  }

  addClassDairyInfo() {
    // if(this.list[0].id<=0){
    //   this.dairyServ.formData.ClassDairyInfo[0].UserId = this.commonService.UserId;
    // }
    this.dairyServ.formData.ClassDairyInfo[0].ClassId = this.selectedClass;
    let t = new Date(this.selectedDate);
    if(JSON.stringify(t) !== 'null')
      this.dairyServ.formData.ClassDairyInfo[0].DairyDate = formatDate(new Date(this.selectedDate), 'dd-MM-yyyy', 'en');
    this.dairyServ.saveClassDairyViewInfo().subscribe((res: any) => {
      this.dairyServ.formData = res;
      if (res.IsSaved) {
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
        // this.bsModalRef.hide()
      }
      console.log(res);
    })
  }
  search() {
    this.config.params.dairyDate = formatDate(new Date(this.selectedDate), 'dd-MM-yyyy', 'en');
    this.config.params.classId = this.selectedClass;
    this.getStudentDairy();
  }
  onSelectClass(event : Event) {
    if(this.list[0].id<=0){
      // const selectElement = event.target as HTMLSelectElement;
      // this.dairyServ.formData.ClassDairyInfo[0].ClassId = parseInt(selectElement.value);
      // this.userServ.getteacheruserlist().subscribe((res: any) => {
      //   this.dairyServ.formData.TeacherInfo = res;
      // });  
      // this.commonService.getsubjects(parseInt(selectElement.value)).subscribe(((res:any) => {
      //   this.subjectList = res;
      //   this.dairyServ.formData.SubjectInfo = res;
      // }));
    }
  }
  onSelectSubject(event : Event) {
    const selectElement = event.target as HTMLSelectElement;
  }






}
