import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { QuestionService } from '../shared/question.service';
import { QuestionList , QuestionMaster, QuestionViewData} from '../shared/question.model';
import { CustomHeader } from '../custommenu/custom-header.component';
//import { QuestionmenuComponent } from './questionmenu/questionmenu.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuestioneditComponent } from './questionedit.component';
import { AssignClassesComponent } from './assign-classes/assign-classes.component';
import { StuanswerlistComponent } from './stuanswerlist.component';
import { NotificationService } from '../shared/notification.service';
import { formatDate, NgFor } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule]
})
export class QuestionComponent implements OnInit {
  // formData : QuestionViewData;
  subjectlist : any = [];
  topiclist : any = [];
  questiontypelist : any = [];

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: QuestionList[];
  gridOptions:any;
  public bsModalRef:BsModalRef;
  
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any ;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };

  constructor(public service:QuestionService, private modalService: BsModalService,public commonServ:CommonService,public notificationServ : NotificationService) {  
    this.columnDefs = [
    { 
      headerName:"QuestionMasterId",
      field: 'QuestionMasterId', cellRendererFramework: CustommenuComponent, 
      cellRendererParams : { MenuList :(this.commonServ.role == 'Teacher'?  [{Name: "Edit", "Click" : this.editQuestion , that : this},{Name: "Answer list", "Click" : this.answerList , that : this}]: [{Name: "Edit", "Click" : this.editQuestion , that : this}, {Name: "Delete", "Click" : this.deleteQuestion , that : this},{Name: "Answer list", "Click" : this.answerList , that : this}])},
      
      filter:false, width:60,maxWidth: 60, 
      headerComponent: 'customHeader', 
      headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
    }, 
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Subject', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Topic', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'NoOfQuestions', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120,  minWidth: 150  },
    { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,  minWidth: 200 },
    { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,  minWidth: 200 },
    { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,  minWidth: 200 },
    { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,  minWidth: 200 },
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.defaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
      sortable: true,
      floatingFilter: true,
};
this.rowSelection = 'single';
this.commonServ.RefreshGrid$.subscribe(
  item => {   
    this.getallquestionlist();
    
   }
);
}

  ngOnInit(): void {
    this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
        }
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getallquestionlist();
    }, 300);
    
    
  }
  getallquestionlist(){
    this.service.getallquestionlistByParams(this.config).subscribe((res : any) => {
      
      for(var i =0; i < res.length; i++){
        res[i].StartTime =this.commonServ.timeFormate(res[i].StartTime);
        res[i].EndTime =this.commonServ.timeFormate(res[i].EndTime);
        
      }
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }

  editQuestion(QuestionId, that){
//    that.openquestionmodal.bind(that);
    that.openquestionmodal(QuestionId);
  }
  assignClasses(QuestionId, that){
    that.openAssignClassesModal(QuestionId)
  }

  deleteQuestion(questionMasterId, that){
    if(that.commonServ.role == 'Teacher'){
      that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
    }
    else{
    // that.service.formData.QuestionMasterInfo = new QuestionMaster();
    // that.service.formData.QuestionMasterInfo.IsActive = false;
    // that.service.formData.QuestionMasterInfo.QuestionMasterId = questionMasterId;
    that.service.deleteQuestion(questionMasterId).subscribe((res:any)=>{
      if(res.IsSaved){
        that.notificationServ.ShowInfo('Successfully deleted!');
        this.getallquestionlist();
      }else{
        that.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  }
  answerList(QuestionMasterId, that){
    that.openAnswerListModel(QuestionMasterId);
  }
  openquestionmodal(questionId:number) {
    const initialState = {
      list: [
        {id:questionId}
      ]      
    };
    this.bsModalRef = this.modalService.show(QuestioneditComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  openAssignClassesModal(questionId:number) {
    const initialState = {
      list: [
        {id:questionId}
      ]      
    };
    this.bsModalRef = this.modalService.show(AssignClassesComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  openAnswerListModel(QuestionMasterId){
    const initialState = {
      list: [
        {id:QuestionMasterId}
      ]      
    };
    this.bsModalRef = this.modalService.show(StuanswerlistComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  onSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
  }
  serchQuestion(){
    this.getallquestionlist();
  }
 
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    } 
  }
}
