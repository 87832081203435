import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { UserService } from '../shared/user.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-user-paymentfee-info',
    templateUrl: './user-paymentfee-info.component.html',
    styleUrls: ['./user-paymentfee-info.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class UserPaymentfeeInfoComponent implements OnInit {

  list:any[];
  feeInfo:any={};
  stdId:number;
  CUSTOMCONST:any=CUSTOMCONST; 
  constructor(public userService:UserService, public modalService:BsModalService, public bsModalRef: BsModalRef) {
    this.stdId = this.modalService.config.initialState.list[0].id;
   }

  ngOnInit(): void {
this.getstudentfeeinfo(this.stdId);
  }
getstudentfeeinfo(stuId) {
    this.userService.getstudentfeeinfo(stuId).subscribe((res: any) => {
      
      this.feeInfo = res.FeeListInfo;
      console.log("this.feeInfo");
      console.log(this.feeInfo);
    });
  }
}
