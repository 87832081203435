import { formatDate, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { AnalysisStudentsComponent } from './analysis-students.component';
import { AnalysisComponent } from './analysis.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-analysises',
    templateUrl: './analysises.component.html',
    styleUrls: ['./analysises.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule]
})
export class AnalysisesComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  analysisesColumnDefs;
  analysisesDefaultColDef;
  analysisesRowData;
  analysisesRowSelection;
  analysisesGridOptions:any;
  public bsModalRef:BsModalRef;
  
  currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  role:string;
  constructor(public service:AnalysisService, private modalService: BsModalService,public commonServ:CommonService,private notificationServ:NotificationService) {  
    this.role = this.commonServ.role;
    this.analysisesColumnDefs = [
    { 
      headerName:"AnalysisMasterId",
      field: 'AnalysisMasterId', cellRendererFramework: CustommenuComponent, 
      cellRendererParams : { MenuList : this.commonServ.role == 'Teacher'?  [{Name: "Edit", "Click" : this.editAnalytic , that : this}]: [{Name: "Edit", "Click" : this.editAnalytic , that : this}, {Name: "Delete", "Click" : this.deleteAnalytic , that : this},{Name: "Students", "Click" : this.getAnalyticStudent , that : this}]},
      
      filter:false, width:60,maxWidth: 60, 
      headerComponent: 'customHeader', 
      headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
    }, 
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Class', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'AcademicYear', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.analysisesDefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
      sortable: true,
      floatingFilter: true,
};
this.analysisesRowSelection = 'single';
this.commonServ.RefreshGrid$.subscribe(
  
  item => {
    this.getAnalysisList();
    }
);
}

  ngOnInit(): void {
    this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
        }
      }
    });
    
  }

  onAnalyticGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.getassignments();
    setTimeout(() =>  {
      this.getAnalysisList();
    }, 300);
    
  }
  getAnalysisList(){
    this.service.getAnalysisList().subscribe((res : any) => {
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  editAnalytic(analysisMasterId, that){
//    that.openquestionmodal.bind(that);
    that.openquestionmodal(analysisMasterId);
  }

  
  deleteAnalytic(analyticId, that){
    if(that.role === 'Admin' || that.role === 'SuperAdmin'){
      that.service.getanalysisview(analyticId).subscribe((res : any) => {
        that.service.formData = res;
        
        if(res){
          that.service.formData.AnalysisMasterInfo.IsActive = false
          that.service.deleteAnalytic().subscribe((res:any)=>{
            if(res.IsSaved){
              that.notificationServ.ShowInfo('Successfully deleted!');
              that.getAnalysisList();
            }else{
              that.notificationServ.ShowError(res.Message);
            }
          });
        }
       });
       
    }
    else{
      
      that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
      
    // that.service.deleteAnalytic(AnalyticId).subscribe((res:any)=>{
    //     if(res.IsSaved){
    //       that.notificationServ.ShowInfo('Successfully deleted!');
    //       this.getAnalysisList();
    //     }else{
    //       that.notificationServ.ShowError(res.ErrorMessage);
    //     }
    //   });
    }
  }
 
  getAnalyticStudent(analysisMasterId, that){
    //    that.openquestionmodal.bind(that);
        that.openAnalyticStudentsmodal(analysisMasterId);
      }
  openquestionmodal(analysisMasterId:number) {
    const initialState = {
      list: [
        {id:analysisMasterId}
      ]      
    };
    this.bsModalRef = this.modalService.show(AnalysisComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  openAnalyticStudentsmodal(analysisMasterId:number) {
    const initialState = {
      list: [
        {id:analysisMasterId}
      ]      
    };
    this.bsModalRef = this.modalService.show(AnalysisStudentsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  onAnalyticSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
  }
  
  
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    }
  }
  serchAnalysises(){
    this.getAnalysisList();
  }
   
  
}
