
<div class="container-fluid mb-3 ">
    <div class="row">
        <div class="col-12">
            <div class="bg-white dairy-page">
                <div class="row border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Class</label>

                            <select class="form-control form-control-sm appearance-auto" name="selectedClass" [(ngModel)]="selectedClass" (change)="changeClass()" required>
                                <option *ngFor="let class of classList"  [value]="class.Id">
                                {{class.Name}}
                                </option>
                            </select>

                        </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Date</label>
                            <input type="text"
                                class="form-control form-control-sm"
                                placeholder="{{'Date' | translate}}"
                                name="selectedDate"
                                [(ngModel)]="selectedDate"
                                [bsConfig]="datepickerConfig"
                                bsDatepicker
                                timepicker
                                >
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2  d-flex align-items-center justify-content-end">
                        <label class="d-block m-0">&nbsp;</label>
                        <div class="d-flex align-items-endno">
                            <button type="button" (click)="serchDairy()" class="btn btn-warning float-right" type="button" >Search</button>

                        <button type="button" (click)="openClassDairyModel()" class="btn btn-success float-right" type="button" >Add Dairy</button>
                        </div>
                    </div>

                </div>
                <mat-table  [dataSource]="dataSource" *ngIf="dataSource.length > 0" class="mat-elevation-z8 p-2">


                    <!-- Subject Column -->
                    <ng-container matColumnDef="Subject">
                      <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.Subject}}</mat-cell>
                    </ng-container>

                    <!-- Teacher Column -->
                    <!-- <ng-container matColumnDef="Teacher">
                      <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.TeacherName}}</mat-cell>
                    </ng-container> -->

                    <!-- Dairy Notes Column -->
                    <ng-container matColumnDef="Dairy Notes">
                      <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.DairyNotes}}</mat-cell>
                    </ng-container>

                    <!-- Dairy Date Column -->
                    <ng-container matColumnDef="Dairy Date">
                      <mat-header-cell *matHeaderCellDef> Dairy Date </mat-header-cell>
                      <mat-cell *matCellDef="let element" >
                        {{element.DairyDate}}
                      </mat-cell>
                    </ng-container>

                    <!-- Dairy Action Column -->
                    <ng-container matColumnDef="Dairy Action">
                      <mat-header-cell *matHeaderCellDef> Dairy Action </mat-header-cell>
                      <mat-cell *matCellDef="let element" >
                        <label (click)="updateDairy(element.ClassDairyMasterId)" class="mr-3 text-success link">Edit</label>
                        <label (click)="deleteData(element.ClassDairyMasterId)" class="text-danger link">Delete</label>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>


                  <mat-table  [dataSource]="emptyDataSource" *ngIf="dataSource.length === 0" class="mat-elevation-z8 p-2">
                    <!-- Subject Column -->
                    <ng-container matColumnDef="Subject">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject </mat-header-cell>
                        <mat-cell class="d-flex justify-content-center min-h-25px" *matCellDef="let element"> No records found </mat-cell>
                    </ng-container>

                    <!-- Teacher Column -->
                    <ng-container matColumnDef="Teacher">
                      <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <!-- Dairy Notes Column -->
                    <ng-container matColumnDef="Dairy Notes">
                      <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <!-- Dairy Date Column -->
                    <ng-container matColumnDef="Dairy Date">
                      <mat-header-cell *matHeaderCellDef> Dairy Date </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <!-- Dairy Action Column -->
                    <ng-container matColumnDef="Dairy Action">
                      <mat-header-cell *matHeaderCellDef> Dairy Action </mat-header-cell>
                      <mat-cell  style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>




            </div>
        </div>
    </div>

</div>


