<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">My Visiting Places</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Place Visited ' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Place Visited ' | translate}}" name="PlaceVisited "  
                                    #PlaceVisited="ngModel" 
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.PlaceVisited"
                                    required class.invalid="PlaceVisited.invalid && PlaceVisited.touched">

                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Where ' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Where ' | translate}}" name="Where"  
                                    #Where="ngModel" 
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.Place"
                                    required class.invalid="Where.invalid && Where.touched">

                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'With Whom' | translate}}*</label>
                                <select class="form-control form-control-sm appearance-auto"
                                name="learningsource" 
                                #learningsource="ngModel" 
                                [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.TravelWithId"  required
                                    class.invalid="learningsource.invalid && learningsource.touched">                                
                                    <option *ngFor="let TravelWith of service.formDataVisitingPlace.TravelWithInfo" [value]="TravelWith.Id">{{TravelWith.Name}}</option>
                                </select>
    
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue"
                                    timepicker required class.invalid="StartDate.invalid && StartDate.touched">
                            </div>
    
                        </div>

                
    
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.EndDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker [bsValue]="bsValue" [minDate]="minDate" required
                                    class.invalid="EndDate.invalid && EndDate.touched">
                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Hours Spent' | translate}}*</label>
                                <input class="form-control form-control-sm" type="number"
                                    placeholder="{{'Hours Spent' | translate}}" name="Hours"  
                                    #Hours="ngModel" 
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.Hours"
                                    required class.invalid="Hours.invalid && Hours.touched">

                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row form-row m-0">                                                  
                    <div class="row form-row m-0 p-0">                    
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="form-group mb-0">
                                <label>{{'Outcomes of Visiting ' | translate}}*</label>
                                <textarea
                                    class="form-control form-control-sm appearance-auto resizebele-false"
                                    name="Outcomes " #Outcomes="ngModel"
                                    [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.Outcome"
                                    required class.invalid="Outcomes.invalid && Outcomes.touched"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-c m-0  bg-white p-2">
                        <label class="m-0 p-0">{{'Trip photos ' | translate}}*</label>
                        <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                <div class="form-group mb-0" >
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                     name="FileInfoList" [(ngModel)]="service.formDataVisitingPlace.StudentTravelInfo.FileInfoList" 
                                      (updatedResponseEmitter)="filesuploaded($event)" 
                                     title="{{'Upload File' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                                    </mb-large-files-uploader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="saveDocument()" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>