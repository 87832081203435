import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-grid-image',
    templateUrl: './grid-image.component.html',
    styleUrls: ['./grid-image.component.scss'],
    standalone: true
})
export class GridImageComponent implements OnInit {
  @Output() Editclick = new EventEmitter<string>();
  params:any;
  ImageUrl:any;
  constructor() { }
  ngOnInit(): void {
  }
  agInit(params: any) {
    
    this.ImageUrl = params.value;
   }

  refresh(params: any) {
    return true;
  }
 
}
