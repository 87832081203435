export class School {
    SchoolId: number;
    Name: string;
    RegistrationNo: string;
    Address: string;
    Street: string;
    City: string;
    State: string;
    MaxDiskSpace:number;
    RemainingDiskSpace:number;
    PostalNo: string;
    Email: string;
    Mobile: string;
    Telephone: string;
    Website: string;
    IsActive: boolean;
    FileId:number;
    FileInfo: FileData;
    IsSaved:boolean;
    ErrorMessage:string;
    Url:string;
    Id:number;
    LoginMessage:string;
}
export class SchoolView {
    SchoolInfo:School
}
export class Branch {
    BranchId:number;
    SchoolId: number;
    Name: string;
    RegistrationNo: string;
    Address: string;
    Street: string;
    City: string;
    State: string;
    PostalNo: string;
    Email: string;
    Mobile: string;
    Telephone: string;
    Website: string;
    IsActive: boolean;
}
export class FileData{
    UserDocumentId:number;
    FileName:string;
    FilePath:string;
    CroppedFilepath:string;
    CroppedContentString:string;
    OriginalContentString:string;
    FileType:string;
    FileHeader:string;
    FileSize:number;
    Description:string;
}