import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';

@Component({
    selector: 'app-read-more',
    templateUrl: './read-more.component.html',
    styleUrls: ['./read-more.component.scss'],
    standalone: true
})
export class ReadMoreComponent implements OnInit {
  list:any;
  feedbackData:any;
  CUSTOMCONST:any;
  constructor(public bsModalRef: BsModalRef,public modalService:BsModalService) {
    this.CUSTOMCONST = CUSTOMCONST;
   }

  ngOnInit(): void {
    this.feedbackData = this.list[0].data;
    console.log("Read more page data")
    console.log(this.list[0])
  }

}
