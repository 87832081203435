import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
// import { resolve4 } from 'dns';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from 'src/app/shared/notification.service';
// import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}


@Component({
    selector: 'app-sidemenu-setting',
    templateUrl: './sidemenu-setting.component.html',
    styleUrls: ['./sidemenu-setting.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, MatCheckboxModule, MatFormFieldModule, MatInputModule]
})




export class SidemenuSettingComponent implements OnInit {
  CUSTOMCONST:any=CUSTOMCONST;
  userData:any;
  list:any;
  menuItems:any=[];
  subMenuItems:any=[];
  bModalRef: BsModalRef



  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Primary', completed: false, color: 'primary'},
      {name: 'Accent', completed: false, color: 'accent'},
      {name: 'Warn', completed: false, color: 'warn'},
    ],
  };

  allComplete: boolean = false;

  constructor(
    public service:SidebarService,
    public modalService:BsModalService,
    private sidebarSer:SidebarService,
    private notificationServ:NotificationService
    ) { }

  ngOnInit(): void {
    // this.userData = this.sessionSer.getUserData();
    // debugger;
    //  console.log(this.list);
    this.getusermenuview();
   
    
  }
  getusermenuview(){
    this.sidebarSer.getusermenuview(this.list[0].id).subscribe((res:any)=>{
        
        this.service.formData =  res;
        this.menuItems=res.MenuInfo;
      });
    }
    onSavemernu(){
      this.sidebarSer.saveusermenuview().subscribe((res:any)=>{
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        }else if (res.IsSaved) {
          this.notificationServ.ShowInfo("Menu saved successfully");
          this.modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL);
        }
        
      });
    }





    
  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }
}
