
<div class="container-fluid bg-light pb-3">
    <section class="pt-3">
        <div class="row justify-content-c m-0  bg-white shadow p-2">
            <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                    <div class="form-group mb-0">
                        <label>Academic Year</label>

                        <select class="form-control form-control-sm appearance-auto" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()" required>
                            <option *ngFor="let year of yearList"  [value]="year.Code">
                            {{year.Name}}
                            </option>
                        </select>

                    </div>
                    <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                    <button type="button" (click)="serchAssignment()" class="btn btn-warning float-right" type="button" >Search</button>
                </div>
            </div>
            <div class="col-md-12 p-0">
                    <ag-grid-angular #agGrid
                    style="width: 100%; height:calc(100vh - 150px)"
                    class="ag-theme-alpine"
                    id="myGrid"
                    [gridOptions]="gridOptions"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [rowData]="rowData"
                    (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    >
                    </ag-grid-angular>
                    <div class="card-footer text-right p-2 mt-2" *ngIf="commonServ.role == 'SuperAdmin' || commonServ.role == 'Admin'">
                        <button type="button" class="btn btn-primary" (click)="onBtExport()" type="button" >Export</button>
                    </div>
            </div>
        </div>
    </section>
</div>
