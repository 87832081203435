import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropdownSettings,
  NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageMasterData } from '../shared/chat.model';
import { ChatService } from '../shared/chat.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { SignalrService } from '../shared/signalr.service';
import { UserService } from '../shared/user.service';
import { ChatboxComponent } from './chatbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-editchat',
    templateUrl: './editchat.component.html',
    styleUrls: ['./editchat.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgIf, NgMultiSelectDropDownModule, TranslateModule]
    imports: [FormsModule, NgIf, TranslateModule,NgMultiSelectDropDownModule]

})
export class EditchatComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  list:any[]=[];
  classList:any[] = [];
  studentList:any[] = [];
  dropdownSettings:IDropdownSettings;
  public chatbox : ChatboxComponent;
  constructor(private modalService: BsModalService,public userService : UserService,
    public service:ChatService,public bsModalRef: BsModalRef,
    private commonService:CommonService,private notificationServ:NotificationService
    ,public signalRServ:SignalrService) {

  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };

    this.service.getMessageInfo(this.list[0].id).subscribe((res: any) => {

    this.service.formData= res;
    // this.service.formData.EventInfo.EventDate
    // this.service.formData.MessageInfo[this.list[0].id].MessageId;
    // this.service.formData.MessageMasterInfo.Name;
  });
  this.commonService.getclasses().subscribe((res:any)=>{
    this.classList = res;
  });
  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){

      var ids = '';
      // this.classIdList
      for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+this.service.formData.ClassMappingList[i].Id;
      }
      this.getstudentsbyclassIds(ids);
    }

  }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){
      var ids = '';
      for(var i=0;i<items.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+items[i].Id;
      }
      this.getstudentsbyclassIds(ids);
    }
  }
  onStuentSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){

      var ids = '';
      // this.classIdList
      for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
        // if(ids){
        //   ids = ids+',';
        // }
        ids = ids+this.service.formData.ClassMappingList[i].Id;
      }
    }

  }
  onStuentSelectAll(items: any,type:string) {
    if(type == 'Class'){
      var ids = '';
      for(var i=0;i<items.length;i++){
        // if(ids){
        //   ids = ids+',';
        // }
        ids = ids+items[i].Id;
      }
      // this.getstudentsbyclassIds(ids);
    }
  }
  getstudentsbyclassIds(classid){
    this.userService.getStudentsByClassIds(classid).subscribe((res:any)=>{
      this.studentList = res;
    });
  }

  onStart(){
    //this.commonService.isChatting.next(false);
    this.service.saveMessageMasterInfo().subscribe((res:MessageMasterData)=>{
      if(res.MessageMasterId){
        this.notificationServ.ShowInfo('Successfully saved!');


        this.commonService.CallOnRefreshGrid();

        // this.openEventModal(res.MessageMasterId);

       // this.chatbox.startChat(res.MessageMasterId);
      this.commonService.messageMasterInfo = res;
      //this.commonService.isChatting.next(true);
      this.signalRServ.startChat(res);
      this.bsModalRef.hide();
      }else{
        this.notificationServ.ShowError('Somthing went wromg please contact to admin');
      }
    });
  }
  openEventModal(MessageMasterId){
    const initialState = {
      list: [
        {id:MessageMasterId}
      ]
    };
    this.bsModalRef = this.modalService.show(ChatboxComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }
}
