  <div class="container mt-4">
    <div>
        <select [(ngModel)]="selectedWeek">
            <option [ngValue]="availweek" *ngFor="let availweek of AvailWeeks">
                {{availweek}}
            </option>
        </select>
        <mat-icon (click)="PrevWeek()" [ngClass]="{'disabled-icon': isDisabledPrevWeek}" [ngStyle]="{ 'pointer-events': isDisabledPrevWeek? 'none' : 'auto'}">chevron_left</mat-icon>
                {{selectedWeek}}
        <mat-icon (click)="NextWeek()" [ngClass]="{'disabled-icon': isDisabledNextWeek}" [ngStyle]="{ 'pointer-events': isDisabledNextWeek? 'none' : 'auto'}">chevron_right</mat-icon>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">&nbsp;</th>
          <th scope="col" class="timetable-header"><div>Monday</div><div class="subdiv">01</div></th>
          <th scope="col" class="timetable-header"><div>Tuesday</div><div>01</div></th>
          <th scope="col" class="timetable-header"><div>Wednesday</div><div>01</div></th>
          <th scope="col" class="timetable-header"><div>Thursday</div><div>01</div></th>
          <th scope="col" class="timetable-header"><div>Friday</div><div>01</div></th>
          <th scope="col" class="timetable-header"><div>Saturday</div><div>01</div></th>
          <th scope="col" class="timetable-header"><div>Sunday</div><div>01</div></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let offset of Weeklydata[selectedWeek]">
            <td *ngFor="let weekdata of offset; let i =index" class="timetable-content" [ngClass]="{'timetable-header' : i === 0}">
                <span *ngIf="i === 0" >{{weekdata}}</span>
                <div *ngIf="i !== 0" class="font-bold font-title">{{weekdata.heading}}</div>
                <div *ngIf="i !== 0" class="font-subtitle">{{weekdata.subheading}}</div>
            </td>
        </tr>
    </tbody>
    </table>
  </div>
