import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { HttpClient } from '@angular/common/http';
import { ParentSettingInfo, ParentSettingView, ParentShareDocInfo, ParentShareDocView } from './Parent-Share-Doc.service.model';


@Injectable({
  providedIn: 'root'
})
export class ParentShareDocService {
  formData:ParentShareDocView ;
  formData1:ParentSettingView ;
  readonly baseUrl = CONFIG.BASE_URL + 'api/Admin';
  readonly baseUrl1 = CONFIG.BASE_URL + 'api/user';

  constructor(private http: HttpClient) {
    this.formData = new ParentShareDocView();
    this.formData.ParentShareDocInfo = new ParentShareDocInfo()
    this.formData1 = new ParentSettingView();
    this.formData1.ParentSettingInfo = new ParentSettingInfo()
  }


  getParentShareListDocView() {
    return this.http.get(this.baseUrl + '/getParentShareListDocView');
  }


  getParentShareDocView(documentid: number) {
    var config = {
      params: {
        shareDocId: documentid,
      }
    };
    return this.http.get(this.baseUrl + '/getParentShareDocView', config);
  }

  saveParentShareDocView(){
    
    return this.http.post(this.baseUrl+'/saveParentShareDocView', this.formData);
  }

// Parent Dashboard

  getParentShareListDocViewForParent() {
    return this.http.get(this.baseUrl + '/getParentShareListDocViewForParent');
  }

  // Document settings

  getParentSettingInfo(schoolid:number,branchid:number,userid:number){
  var config = {
      params: {
        schoolId: schoolid,
        branchId :branchid,
        userId:userid
      }
    };
  return this.http.get(this.baseUrl1+'/getParentSettingInfo',config);
}

saveParentSettingInfo(){  
  return this.http.post(this.baseUrl1+'/saveParentSettingInfo', this.formData1);
}

}