// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-custom-tooltip',
//   templateUrl: './custom-tooltip.component.html',
//   styleUrls: ['./custom-tooltip.component.scss']
// })
// export class CustomTooltipComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }






import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tooltip-component',
    template: ` <div class="custom-tooltip" [style.background-color]="color">
  <div *ngIf="comments" class="border">
  <p>
      <span>Comments</span>
    </p>
    <div [innerHtml]="data.Comments" class="pl-5px pr-5px pb-5px"></div>
  </div>

  </div>`,
    styles: [
        `
      :host {
        position: absolute;
        pointer-events: none;
        transition: opacity 1s;
        min-width: 150px !important;
        width: auto;
        max-width: 500px;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
      .custom-tooltip .pl-5px{
        padding-left: 5px !important;
      }
      .custom-tooltip .pr-5px{
          padding-right: 5px !important;
      }
      .custom-tooltip .pb-5px{
        padding-bottom: 5px !important;
    }
    `,
    ],
    standalone: true,
    imports: [NgIf]
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  public params;
  public data: any;
  public color: string;
  comments:boolean=false;
  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;

    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.color = this.params.color || 'white';


    if(this.params.colDef.tooltipField === "Comments"){
      this.comments=true;
    }

  }
}
