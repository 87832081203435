<div class="chatbox-outer">
    <div class="message_user_list">
        <div class="message-header">
            <h3>Contacts</h3>
        </div>
        <ul class="message_user_list_inner">
            <li *ngFor="let masterMessage of masterMessageList" class="msg-user-name">
                <a (click)="handleChatClick(masterMessage)"
                    [ngClass]="{'activeuser':(commonServ.messageMasterInfo && commonServ.messageMasterInfo.MessageMasterId===masterMessage.MessageMasterId)}"
                    href="Javascript:void(0);"><span class="msg-user-img">DT</span>{{masterMessage.Name}}</a>
            </li>
        </ul>
    </div>

    <div class="meassge_body">
        <div class="message-header">
            <h3>{{commonServ.messageMasterInfo && commonServ.messageMasterInfo &&
                commonServ.messageMasterInfo.Name || 'Messages'}}</h3>
        </div>
        <ul class="meassgae_body_inner">
            <li class="message-text" *ngFor="let messagelist of messageList"
                [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing':'incoming'">
                <div *ngIf="messagelist.Message != '%PAUSE%' && messagelist.Message != '%RESUME%'">
                    <span class="sender-name"
                        *ngIf="messagelist.CreatedBy != commonServ.UserId">{{messagelist.CreatedByName}}
                    </span>
                    <pre>{{messagelist.Message}} </pre>



                    <span class="sender-date">
                        {{messagelist.CreatedOn}}
                    </span>
                </div>
            </li>
            <!-- <li>
                <div *ngIf="isAdmin && messageList && messageList.length > 1">
                    <button type="button" class="btn btn-danger"
                        (click)="sendMessageAction(messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME' : 'PAUSE')"
                        aria-label="Close">{{messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME'
                        : 'PAUSE'}}</button>
                </div>


            </li> -->
            <li *ngIf="isAdmin && messageList && messageList.length > 1">

                <button type="button" class="btn btn-danger"
                    (click)="sendMessageAction(messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME' : 'PAUSE')"
                    aria-label="Close">{{messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME'
                    : 'PAUSE'}}</button>


            </li>
        </ul>
        <div class="card-footer" *ngIf="messageList">
            <div class="chart-form position-relative">
                <div class="input-group text-group">
                    <div style="text-align:center">
                        <h1 style="margin-bottom:30px;">Video Record</h1>
                        <button class="btn btn-link" (click)="showCamera()">Record</button>
                        <video class="video" #liveVideo autoplay controls></video>
                        <span class="m-1"></span>
                        <video class="video" style="width:480px !important;" controls #recordedVideo></video>
                        <br>
                        <button class="btn btn-success btn-lg" *ngIf="!isRecording"
                            (click)="startVideoRecording()">Start Recording</button>
                        <button class="btn btn-warning btn-lg" *ngIf="isRecording" (click)="stopVideoRecording()">Stop
                            Recording</button>
                    </div>
                </div>
                <div class="input-group btn-send-group">
                    <button class="btn btn-link" (click)="showCamera()">Record</button>
                </div>
            </div>

            <div class="chart-form position-relative">
                <div class="input-group text-group">
                    <textarea
                        [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                        [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                        autofocus type="text" class="form-control js-auto-expand resizebele-false"
                        (keyup)="textAreaAdjust(this)" name="Message" #Message="ngModel"
                        [(ngModel)]="messageInfo.Message" placeholder="" rows="1" max-rows="5"
                        style="height:unset;"></textarea>
                </div>
                <div class="input-group btn-send-group">
                    <button
                        [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                        [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                        class="btn btn-link" (click)="sendMessage()"><i class="fa fa-paper-plane"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>