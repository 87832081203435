import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-preview-recording-component',
  standalone: true,
  imports: [TranslateModule,CommonModule],
  templateUrl: './preview-recording-component.component.html',
  styleUrl: './preview-recording-component.component.scss'
})
export class PreviewRecordingComponentComponent implements OnInit {
  videoUrl:string="";
  constructor(public bsModalRef: BsModalRef) {}
  ngOnInit(): void {
  }

}
