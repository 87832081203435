<div class="dashboardInnerPage">
    <div class="teacherDashboardData">
        <div class="dashboard-col dashboard-learning-col">
            <div class="learning-col">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/school-icon.png" alt="">
                </div>
                <h3>School Based<br>Learning</h3>
            </div>
            <div class="learning-col">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/home-icon.png" alt="">
                </div>
                <h3>Home Based<br>Learning</h3>
            </div>
            <div class="learning-col">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/community-icon.png" alt="">
                </div>
                <h3>Community Based<br>Learning</h3>
            </div>
            <div class="learning-col">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/society-icon.png" alt="">
                </div>
                <h3>Society Based<br>Learning</h3>
            </div>
        </div>
        <div class="dashboard-col">
            <div class="title">
                <h3>Interactive Live Classes</h3>
            </div>
            <div class="teacher-class">
                <div *ngFor="let liveclassdata of eventsData" class="class-col-outer">
                    <div class="class-date-time">
                        <span class="bi bi-calendar-week"> {{liveclassdata.EventDate}}</span>
                        <span class="bi bi-clock"> {{liveclassdata.StartTimeISO | customDate:'shortTime'}} ~ {{liveclassdata.EndTimeISO | customDate:'shortTime'}}</span>
                    </div>
                    <div class="classes-col">
                        <div class="class-icon">{{getIcon(liveclassdata.Description)}}</div>
                        <div class="class-data">
                            <h3>{{liveclassdata.ClassName}}</h3>
                            <span>{{liveclassdata.Description}}</span>
                            <div class="calling-btn action-links">
                                <span class="bi bi-camera-video" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    title="Video Call" (click)="getURL(liveclassdata)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="teacher-sidebar">
        <div class="teacherProfile">
            <div class="teacher-image">
                <img
                    [src]="userService.formData.UserInfo.CroppedFilepath?userService.formData.UserInfo.CroppedFilepath:'../../../assets/images/user/default.png'" />
            </div>
            <div class="teacher-details">
                <h2>{{userService.formData.UserInfo.FirstName}} {{userService.formData.UserInfo.MiddleName}}
                    {{userService.formData.UserInfo.Surname}}</h2>
                <span>{{userService.formData.UserInfo.Profession}} Teacher ID: 7ENTE7_64</span>
            </div>
            <div class="teacher-action action-links">
                <span class="bi bi-eye-fill" data-bs-toggle="tooltip" title="View Profile"
                    data-bs-placement="bottom"></span>
                <span (click)="editProfile(userService.formData.UserInfo.UserId)" class="bi bi-three-dots-vertical"
                    data-bs-toggle="tooltip" title="Edit Profile" data-bs-placement="bottom"></span>
                <span class="bi bi-chat" data-bs-toggle="tooltip" title="Chat" data-bs-placement="bottom"></span>
                <span (click)="addNewEvent()" class="bi bi-plus-circle-fill" data-bs-toggle="tooltip"
                    title="Add New Class" data-bs-placement="bottom"></span>
            </div>
        </div>
        <div class="sid-header">
            <h3>Interactive Live Classes</h3>
        </div>
        <bs-datepicker-inline class="inlinedate" name="inlineDate" [(ngModel)]="inlineDate" [bsValue]="inlineDate"
            [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
        </bs-datepicker-inline>


    </div>
</div>

<!-- <div class="container-fluid bg-light"> -->
<!-- <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">


            <div class="bg-white shadow overflow-hidden">
                <div class="px-4 pt-3  bg-dark position-relative bg-s-cover min-h-300px"
                    [style.background-image]="'url(' + (userService.formData.UserInfo.CroppedProfileFilepath?userService.formData.UserInfo.CroppedProfileFilepath:'') + ')'">
                    <div class="media profile-header sm-flex-wrap">
                        <div class="profile mr-3 sm-m-auto text-center sm-w-100">
                            <img [src]="userService.formData.UserInfo.CroppedFilepath?userService.formData.UserInfo.CroppedFilepath:'../../../assets/images/user/default.png'"
                                width="130" class="rounded-circle border border-w-5 border-light mt-2">
                            <a (click)="editProfile(userService.formData.UserInfo.UserId)"
                                class="btn btn-link btn-sm btn-block"
                                [ngClass]="{'pe-none':!userService.formData.UserInfo.IsEditAllowed}">Edit profile</a>
                        </div>
                        <div class="media-body text-white xs-pb-60px">
                            <div class="row m-0">
                                <div class="col-xs-12 col-md-4">
                                    <h4 class="mt-0 mb-0">{{userService.formData.UserInfo.FirstName}}
                                        {{userService.formData.UserInfo.MiddleName}}
                                        {{userService.formData.UserInfo.Surname}}</h4>

                                </div>
                                <div class="col-xs-12 col-md-6 ">
                                    <h4 class="mt-0 mb-0">{{userService.formData.UserInfo.Address}}
                                        {{userService.formData.UserInfo.Street}}</h4>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <h4 class="mt-0 mb-0">{{userService.formData.UserInfo.Profession}}</h4>

                                </div>

                            </div>
                            <div class="row m-0 mt-3">
                                <div class="col-12">
                                    <p>{{userService.formData.UserInfo.About}}</p>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex justify-content-end text-center position-absolute r-0 b-0 bg-light-transparent-05 fix-bottom">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item p-2 border-right border-light">
                                <h5 class="font-weight-bold mb-0 d-block">0</h5><small class="text-muted"> <i
                                        class="fa fa-picture-o mr-1"></i>Photos</small>
                            </li>
                            <li class="list-inline-item p-2 border-right border-light">
                                <h5 class="font-weight-bold mb-0 d-block">0K</h5><small class="text-muted"> <i
                                        class="fa fa-user-circle-o mr-1"></i>Followers</small>
                            </li>
                        </ul>
                    </div>

                </div>
                <section class="course-details-content-section">
                    <div class="container-fluid p-0">
                        <mat-tab-group animationDuration="0ms" class="bg-transparent border-0"
                            (selectedTabChange)="activeTabChange($event)">
                            <mat-tab label="SCHOOL BASED LEARNING" (click)="activeTabChange('IsAcademics')"
                                *ngIf="userService.formData.TeacherInfo?.IsAcademics">
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Teacher SMILES integrates mindfulness & positive psychology based short and easy
                                        techniques while interacting with students.
                                        These practice also improve concentration & recall power resulting in better
                                        academic and cognitive outcomes.
                                        Teachers should bring out the inherent creativity in students for better
                                        motivation & passion for learning.
                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab label="HOME BASED LEARNING" (click)="activeTabChange('IsHumanityLife')"
                                *ngIf="userService.formData.TeacherInfo?.IsHumanityLife">
                                <div class="mt-3">
                                    <p class="tab-content">
                                        For Teachers, SMILES generates a positive learning ecosystem that supports their
                                        local context based new knowledge and skills in a stress free manner.
                                        It motivates them by boosting their self-esteem, helping them utilize their
                                        talent and passion in classrooms and giving due recognition and opportunities
                                        for growth.
                                        It generates curiosity in teachers for learning and exploring new ways of
                                        teaching.<br /><br />

                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab label="COMMUNITY BASED LEARNING" (click)="activeTabChange('IsSkills')"
                                *ngIf="userService.formData.TeacherInfo?.IsSkills">
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Teacher & students start the lesson feeling grateful for today’s learning
                                        opportunity,
                                        do positive affirmations with deep breathing and choose a positive word/value
                                        for today’s lesson.
                                        The teacher tells them about what knowledge they will get today and how it is
                                        related to what we learnt before.
                                        Then together they discuss why this knowledge is helpful in daily life.
                                    </p>
                                </div>
                            </mat-tab>
                            <mat-tab label="SOCIETY BASED LEARNING" (click)="activeTabChange('IsLearnOutOfBox')"
                                *ngIf="userService.formData.TeacherInfo?.IsLearnOutOfBox">
                                <div class="mt-3">
                                    <p class="tab-content">
                                        Modern society demands high quality teaching and learning from teachers.
                                        Teachers have to possess a great deal of knowledge and skills with regard to
                                        both teaching and assessment practices in order to meet those demands and
                                        standards of quality education.
                                        Teach A Class began with a simple idea – bringing useful tools to the teachers
                                        that really needed them.
                                        We wanted teachers to stay motivated and use their enthusiasm to improve
                                        learning in school.<br /><br />
                                        <br /><br />

                                    </p>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </section>

            </div>


        </div>

        <section class="mt-3 p-0">
            <div class="row m-0 justify-content-c xs-flex-wrap">
                <div class="p-2 shadow bg-white event-group">
                    <div class="mb-0 w-100">
                        <label>Interactive Live Classes</label><label class="float-right"><a class="cursor-pointer"
                                (click)="addNewEvent()"><i class="fa fa-plus"></i> Add New Class</a></label>
                        <ag-grid-angular #agGrid style="width: 100%; height:248px;min-height: 200px;max-height: 248px;"
                            class="ag-theme-alpine" id="eventGrid" [gridOptions]="eventGridOptions"
                            [columnDefs]="eventColumnDefs" [defaultColDef]="eventDefaultColDef"
                            [rowSelection]="eventRowSelection" [rowData]="eventsRowData"
                            (gridReady)="onEventReady($event)"></ag-grid-angular>
                    </div>

                </div>

                <div class="ml-15px p-0 inline-calander-group xs-ml-0px xs-mt-3">
                    <bs-datepicker-inline class="inlinedate" name="inlineDate" [(ngModel)]="inlineDate"
                        [bsValue]="inlineDate" [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
                    </bs-datepicker-inline>
                </div>
            </div>
        </section>
        <section class="mt-3 p-0">
            <div class="row m-0 justify-content-c ">
                <div class="p-2 shadow bg-white w-100">
                    <div class="h-100">
                        <label>Assessments</label>
                        <ag-grid-angular #agGrid
                            style="width: 100%; height:calc(50vh - 80px);min-height: calc(100% - 30px);max-height: 500px;"
                            class="ag-theme-alpine" id="myGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowData]="rowData"
                            (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"
                            [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                    </div>
                </div>

            </div>
        </section>
    </div> -->


<!-- <div class="container-fluid mt-3 pb-3"> -->
<!-- <section class="mlr-15px-neg">
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant1 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>10</mat-card-title>
                            <mat-card-subtitle>Programs</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <i class="fa fa-fw fa-graduation-cap"></i>
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant2 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>110</mat-card-title>
                            <mat-card-subtitle>Learners</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <i class="fa fa-fw fa-user-md"></i>
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant3 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>1</mat-card-title>
                            <mat-card-subtitle>Institutes</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <i class="fa fa-fw fa-university"></i>
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant4 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>10</mat-card-title>
                            <mat-card-subtitle>Trainers</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <i class="fa fa-fw fa-users"></i>
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>

    </div>
    </section> -->
<!-- <section> -->
<!-- <div class="row pt-2 mb-3 bg-white shadow" *ngIf="watchList.length>0 ">
                <label>Watch Assignments</label>
                <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 min-height-150 mb-3"
                    *ngFor="let watch of watchList">
                    <div layout="column" flex>
                        <div class="border p-1"
                            [ngClass]="{'border-primary':watch.Status == 'New', 'border-success': watch.Status == 'Completed', 'border-danger':watch.Status == 'Expired'}">
                            <a class="cursor-pointer" (click)="openModel(watch)">
                                <img class="img-fluid z-depth-1"
                                    src="../../../assets/media/videos/watc_hlist_poster.jpeg" alt="video">
                            </a>
                            <span class="d-block w-100 bg-theam2 pt-2 break-word">{{watch.Name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-2 mb-3 bg-white shadow" *ngIf="listenList.length>0 ">
                <label>Listen Assignments</label>
                <div *ngFor="let listen of listenList; let i=index"
                    class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3 min-height-150">
                    <div class="h-100">
                        <div class="border p-1 "
                            [ngClass]="{'border-primary':listen.Status == 'New', 'border-success': listen.Status == 'Completed', 'border-danger':listen.Status == 'Expired'}">
                            <img src="../../../assets/images/common/Listen_think_explore_present.jpeg"
                                (click)="openModel(listen)" class="cursor-pointer" width="100%" />
                            <span class="d-block w-100 bg-theam2 pt-2 break-word">{{listen.Name}}</span>
                        </div>

                    </div>
                </div>
            </div> -->
<!-- <div class="row border pt-3 mb-3">
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3 min-height-150">
                <div class="h-100">
                    <img src="../../../assets/media/audios/audio_player1.png" onClick="document.getElementById('audio_play').play(); return false;" class="audio-poster-image" width="100%" />
                    <audio controls class="w-100 h-100" height="200px" >
                        <source src="../../../assets/media/audios/audio_play1.jpg" type = "audio/mp3" />
                    </audio>
                </div>
            </div>
        </div> -->
<!-- <div class="row pt-2 mb-3 bg-white shadow" *ngIf="readList.length>0 ">
                <label>Read Assignments</label>
                <div *ngFor="let read of readList"
                    class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                    <div class="border p-1 "
                        [ngClass]="{'border-primary':read.Status == 'New', 'border-success': read.Status == 'Completed', 'border-danger':read.Status == 'Expired'}">
                        <a class="cursor-pointer" (click)="openModel(read)">
                            <img src="../../../assets/images/common/read_think_explore_present.jpeg"
                                class="max-height-150px m-auto" />
                        </a>
                        <span class="d-block w-100 bg-theam2 pt-2 break-word">{{read.Name}}</span>
                    </div>
                </div>
            </div> -->
<!-- <div class="row pt-2 mb-3 bg-white shadow" *ngIf="doList.length>0 ">
                <label>Do Assignments</label>
                <div *ngFor="let do of doList"
                    class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                    <div class="border p-1 "
                        [ngClass]="{'border-primary':do.Status == 'New', 'border-success': do.Status == 'Completed', 'border-danger':do.Status == 'Expired'}">
                        <a class="cursor-pointer" (click)="openModel(do)">
                            <img src="../../../assets/images/common/do_think_explore_present.jpeg"
                                class="max-height-150px m-auto" />
                        </a>
                        <span class="d-block w-100 bg-theam2 pt-2 break-word">{{do.Name}}</span>
                    </div>
                </div>
            </div> -->
<!-- <div class="row pt-2 mb-3 bg-white shadow" *ngIf="homeWorkList.length>0 ">
                <label>Home work</label>
                <div *ngFor="let homeWork of homeWorkList"
                    class="col-xl-2 col-lg-2 col-md-3 col-xs-4 col-sm-4 mb-3 min-height-150 text-center">
                    <div class="border p-1 "
                        [ngClass]="{'border-primary':homeWork.Status == 'New', 'border-success': homeWork.Status == 'Completed', 'border-danger':homeWork.Status == 'Expired'}">
                        <a class="cursor-pointer" (click)="openModel(homeWork)">
                            <img src="../../../assets/media/doc/docx.png" class="max-height-150px m-auto" />
                        </a>
                        <span class="d-block w-100 bg-theam2 pt-2 break-word">{{homeWork.Name}}</span>
                    </div>
                </div>
            </div> -->
<!-- </section>
    </div> -->