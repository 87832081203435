<!-- <div class="modal-dialog modal-xl m-0"> -->
<div class="modal-content">

    <div class="modal-header p-1 bg-theam">
        <h3 class="modal-title h3 m-1">{{service.formData.MessageMasterInfo.Name}}</h3>
        <!-- <h3 class="modal-title h3 m-1" >{{'EditAssgnment' | translate}}</h3> -->
    </div>
    <div class="breackout_body p-2 chat-body bg-light">
        <ul class="chat list-style-none pl-0">
            <li class="clearfix" *ngFor="let messagelist of messageList">
                <div style="margin-bottom: 10px;" class="input-group post"
                    [ngClass]="(messagelist.createdBy == commonServ.UserId)?'outgoing':'incoming'">
                    <div class="w-100"
                        [ngClass]="(messagelist.CreatedBy == service.formData.MessageMasterInfo.CreatedBy)?'outgoing-chat':'incoming-chat'">
                        <label class="shadow-sm mb-0">
                            <span *ngIf="messagelist.CreatedBy != commonServ.UserId"
                                class="chat-header">{{messagelist.CreatedByName}} - {{messagelist.CreatedOn}}
                                <hr class="mt-0 mb-1">
                            </span>
                            <span *ngIf="messagelist.CreatedBy == commonServ.UserId" class="chat-header">
                                {{messagelist.CreatedOn}} - {{messagelist.CreatedByName}}
                                <hr class="mt-0 mb-1">
                            </span>

                            <pre class="mb-0">{{messagelist.Message}} </pre>
                        </label>
                    </div>

                    <!-- <div [ngClass]="(messagelist.CreatedBy == service.formData.MessageMasterInfo.CreatedBy)?'outgoing-chat-dateandtime':'incoming-chat-dateandtime'">
                            <label class="mb-0">
                                {{messagelist.CreatedOn}}
                            </label>
                        </div> -->

                </div>
            </li>

        </ul>
    </div>
    <div class="modal-footer p-1">
        <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Close">Close</button>
    </div>

</div>
<!-- </div> -->