import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EmbedVideoService } from 'ngx-embed-video';
import { CUSTOMCONST } from '../shared/Const';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { QuestionService } from '../shared/question.service';
import { ConfirmComponent } from '../common/confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-think',
    templateUrl: './think.component.html',
    styleUrls: ['./think.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, MatRadioModule, MatCheckboxModule, //Ng7LargeFilesUploadLibModule,
       NgClass, MatIconModule, TranslateModule]
})
export class ThinkComponent implements OnInit {

  list: any[] = [];
  fileType: any;
  urlType: string;
  url: any;
  urlSafe: SafeResourceUrl;
  description: any;
  questioner: boolean;
  startAnswer: boolean;
  currentQuestionNo: number;
  questionInfo: any;
  noofQuestions: number;
  enbedUrl: any;
  youtubeUrlId: string;
  ispreviewAnswer: boolean;
  status: string;
  stdAnswerMasterInfo: any = 0;
  CUSTOMCONST: any;
  lastdocumentId: number;
  isprocessing: boolean = false;
  // resultTypeList:any;
  // tfValue = [{text: 'True'},{text: 'False'}];
  fileExtension: any;
  docUrl: any;
  name: string = '';
  constructor(public service: AssignemntService, public questionService: QuestionService,
    public bsModalRef: BsModalRef, public modalService: BsModalService, public sanitizer: DomSanitizer,
    private notificationServ: NotificationService, private embedService: EmbedVideoService,
    public commonServ: CommonService) {
    this.commonServ.OnConfirm$.subscribe(
      item => {
        this.saveStudentAnswerInner();
      }
    );
    this.CUSTOMCONST = CUSTOMCONST;
  }

  ngOnInit(): void {
    this.fileType = this.list[0].data.Type;
    this.urlType = this.list[0].data.UrlType;
    this.url = this.list[0].data.Url;
    this.questioner = this.list[0].data.IsQuestionir;
    this.description = this.list[0].data.Description;
    this.status = this.list[0].data.Status;
    this.startAnswer = false;
    this.currentQuestionNo = 0;
    this.name = this.list[0].data.Name

    //     var tableopening = '<table class="MsoNormalTable"';
    //     var tableclose = '</table>';
    //     this.description = (this.list[0].data.Description).replace(tableopening, '<div class="table-responsive"><table class="table">');
    //     this.description = (this.list[0].data.Description).replace(tableclose, '</table></div>');
    if (this.url != '') {
      var ftList = this.list[0].data.Url.split('.');
      this.fileExtension = ftList[ftList.length - 1];
      if (this.fileExtension === 'doc' || this.fileExtension === 'docx') {
        var re1 = /\\/gi;
        this.docUrl = (this.list[0].data.Url).replace(re1, "/");
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUrl);
      }
    }


    // this.questionService.getquestioninfo(this.list[0].data.QuestionMasterId).subscribe((res : any) => {
    //   this.questionInfo = res;
    // });
    this.service.getassignment(this.list[0].data.AssignmentId).subscribe((res: any) => {
      this.service.formData = res;
      this.service.formData.QuestionInfo = res.QuestionInfo;
      this.service.formData.QuestionMasterInfo = res.QuestionMasterInfo;
      this.noofQuestions = this.service.formData.QuestionInfo.length;
      // this.service.formData.QuestionInfo[0].QuestionNo;
      this.proceedNextStep();
      this.getStudentResult(res.QuestionMasterInfo.QuestionMasterId);
    });

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // console.log ( this.service.formData);

    if (this.urlType == "Url") {
      this.enbedUrl = this.embedService.embed(this.list[0].data.Url,
        {
          query: { portrait: 0, color: '333' },
          attr: { style: 'width:100%;height:450px;min-height: calc(100vh - 190px);' },
        });
      this.youtubeUrlId = this.list[0].data.Url.replace('https://www.youtube.com/watch?v=', '');
    }
    this.commonServ.getlastdocumentid().subscribe((res: any) => {
      this.lastdocumentId = res;
    });
  }

  saveStudentAnswerInner() {
    if (!this.isprocessing) {
      this.isprocessing = true;
      this.service.formData.QuestionInfo[this.currentQuestionNo - 1] = this.questionInfo;

      this.service.savestudentassignment().subscribe((res: any) => {
        this.isprocessing = false;
        if (res.IsSaved) {
          this.notificationServ.ShowInfo('Successfully saved!');
          this.commonServ.CallOnRefreshGrid();
          if (res.AssignmentInfo.IsQuestionir) {
            this.ispreviewAnswer = true;
          } else {
            this.modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL);
          }

          // this.bsModalRef.hide();
        }
        else {
          this.notificationServ.ShowError(res.ErrorMessage);
        }
      });
    }
  }

  changeMC(length, index) {
    var i = 0;
    for (i; i < length; i++) {
      if (i != index) {
        this.questionInfo.QAnswerList[i].StrAnswer = '';
      }
      else {
        this.questionInfo.QAnswerList[index].StrAnswer = this.questionInfo.QAnswerList[index].StrAnswer;
      }

    }

  }


  filesuploaded(fileInfo) {
    if (fileInfo && fileInfo.IsSaved)
      if (this.service.formData.AssignmentInfo.Type === "Home work" || this.service.formData.AssignmentInfo.Type === "Do") {
        this.service.formData.AssignmentInfo.ResponseFileId = fileInfo.UserDocumentId;
      }
      else {
        this.service.formData.QuestionMasterInfo.ResponseFileId = fileInfo.UserDocumentId;
      }

    this.commonServ.getnewlyaddeddocumentids(this.lastdocumentId).subscribe((res: any) => {
      var newlyAddedDocumentList = res;
      if (res && res.length > 1) {
        var docId = '';
        newlyAddedDocumentList.forEach(element => {
          if (docId) {
            docId = docId + ',' + element;
          }
          else {
            docId = element;
          }
        });
        this.service.formData.AssignmentInfo.ResponseFileIds = docId;
        if (this.service.formData.AssignmentInfo.Type !== "Home work") {
          this.service.formData.QuestionMasterInfo.ResponseFileIds = docId;
        }
      }
      else {

      }

    });
  }

  largefileschange(event) {
  }

  prevQuestion() {
    // this.service.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;
    this.currentQuestionNo = this.currentQuestionNo - 1;
    this.questionInfo = this.service.formData.QuestionInfo[this.currentQuestionNo - 1];
  }
  proceedNextStep() {
    this.startAnswer = true;
    this.questionInfo = this.service.formData.QuestionInfo[this.currentQuestionNo];
    //this.noofQuestions = this.service.formData.QuestionMasterInfo.NoOfQuestions;
    this.currentQuestionNo = this.currentQuestionNo + 1;
  }
  getStudentResult(questionMasterId: number) {
    if (questionMasterId > 0) {
      this.questionService.getStudentAnswerInfo(questionMasterId).subscribe((res: any) => {
        this.stdAnswerMasterInfo = res
        // this.resultTypeList = res.ResultTypeList;


      });
    }
    else {
      this.service.getstudentmasterid(this.service.formData.AssignmentInfo.AssignmentId).subscribe((res: any) => {
        this.stdAnswerMasterInfo = {};
        this.stdAnswerMasterInfo.StudentAnswerMasterDataId = res;
      });
    }
  }
  saveStudentAnswer() {
    const initialState = {
      list: [
        { id: 'confirm' },
        { message: "Are you sure want to submit your answers?" }
      ]
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent, { id: CUSTOMCONST.CONFIRM_MODAL, initialState, class: 'second col-12 modal-dialog-centered model-sm', ignoreBackdropClick: true });
  }
}
