<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 *ngIf="actionType!='Analytics'" class="modal-title h3 m-1" >{{list[0].id === 0?'Add QRCode':'Edit QRCode'}}</h3>
          <h3 *ngIf="actionType=='Analytics'" class="modal-title h3 m-1" >QRCode Analytics Info</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0" *ngIf="actionType!='Analytics'">
                <div class="row justify-content-c m-0  bg-white p-2">


                    <div class="row  m-0 py-2 mb-2 p-0">

                        <div class="col-7 border">
                            <div class="row">
                                <div class="col-12 form-group mb-0">
                                    <label>Name</label>
                                    <input class="form-control form-control-sm" name="Name" #Name="ngModel"
                                        [(ngModel)]="QRCodeServ.formData.QRCodeInfo.Name" type='text' required
                                        class.invalid="Name.invalid && Name.touched" />
                                </div>
                                <div class="col-12">
                                    <div class="form-group mb-0">
                                        <label>Qr Type</label>
                                        <select class="form-control form-control-sm appearance-auto" name="QrTypeID" #QrTypeID="ngModel"

                                        [(ngModel)]="QRCodeServ.formData.QRCodeInfo.QrTypeId">
                                          <option  [value]="0" selected>Select Qr Type</option>
                                          <option *ngFor="let qrtype of qrtypelist" [value]="qrtype.QRTypeId">{{qrtype.Name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12" *ngIf="QRCodeServ.formData.QRCodeInfo.QrTypeId == 1">
                                    <div class="form-group mb-0">
                                        <label>Enter URL</label>
                                        <input class="form-control form-control-sm" [(ngClass)]="isUrlInvalid"  name="URL" id ="URL"  [(ngModel)]="URL"/>
                                    </div>

                                </div>
                                <div class="col-12" *ngIf="QRCodeServ.formData.QRCodeInfo.QrTypeId == 2">
                                    <div class="form-group mb-0">
                                        <label>Wifi Name</label>
                                        <input class="form-control form-control-sm" name="WifiName"  id ="WIFIName"  [(ngModel)]="WIFIName"
                                           type='text' required
                                           />
                                    </div>
                                    <div class="form-group mb-0">
                                        <label>Wifi Password</label>
                                        <input class="form-control form-control-sm" name="WifiPassword"  id ="WIFIPassword"  [(ngModel)]="WIFIPassword"
                                           type='text' required
                                           />
                                    </div>
                                    <div class="form-group mb-0">
                                        <label>Wifi Ecryption</label>

                                        <input name="WifiPassword"  id ="WIFISecurity"  [(ngModel)]="WIFISecurity"
                                        value="nopass"  type='radio' required
                                           /> None


                                            <input  name="WifiPassword"  id ="WIFISecurity"  [(ngModel)]="WIFISecurity"
                                            value="WPA"  type='radio' required
                                               /> WPA/WPA2

                                                <input name="WifiPassword"  id ="WIFISecurity"  [(ngModel)]="WIFISecurity"
                                                value="WEP"  type='radio' required
                                                   /> WEP

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-5 border">

                           <div class="col">
                            <div class="col-12 qrcustomizeoptionsbgColor pointeronhover text-center" (click)="previewQRCode()"><strong>Preview</strong></div>

                            <div class="col-12 mt-1 text-center">
                                <img class="mx-w-300px img-fluid" [src]="qrCodePreviewData" [alt]="qrCodePreviewData">
                            </div>

                        </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-7 form-group mb-0">
                                <div (click)="onFrameClick()" class="row pointeronhover qrcustomizeoptionsbgColor mt-2">
                                    <div class="col"> Customize Text and Color</div>
                                    <div class="col text-right">
                                       <span class="pointeronhover"  >
                                            <i class="fa fa-plus" *ngIf="isFrameOptionsVisible==false" aria-hidden="true" ></i>
                                            <i class="fa fa-minus" *ngIf="isFrameOptionsVisible==true" aria-hidden="true"></i>
                                       </span>
                                    </div>
                                </div>
                               <div class="row mt-1" id="frameoptions" *ngIf="isFrameOptionsVisible">
                                  <div class="col divscroll-x">
                                    <div class="row">
                                        <div class="col">
                                            <label>QR Color</label>
                                            <chrome-picker [control]="qrColor"></chrome-picker>
                                        </div>
                                        <div class="col">
                                            <label>QR Background Color</label>
                                            <chrome-picker [control]="qrBgColor" ></chrome-picker>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col">
                                            <label>QR Text</label>
                                            <input class="form-control form-control-sm" type="text" [(ngModel)]="qrText"  />
                                        </div>
                                    </div>
                                     <div class="row mt-2" *ngIf="qrText.length>0" >
                                       <div class="col-12">
                                        <label>Text Position</label>
                                        <div [ngClass]="o.cssName" class="col-6" *ngFor="let o of qrTextCustomOptions" (click)="onFrameSelect(o)">
                                            <div class="col frametextsize">
                                              {{o.textName}}
                                            </div>
                                        </div>
                                       </div>
                                    </div>

                                    <div class="row mt-2" *ngIf="qrText.length>0" >
                                        <div class="col">
                                         <label>QR Text Border?</label>
                                         <div [ngClass]="o.cssName" class="col-6" *ngFor="let o of qrTextBorderRequired" (click)="onQRTextBorderSelect(o)">
                                             <div class="col frametextsize">
                                               {{o.textName}}
                                             </div>
                                         </div>
                                        </div>
                                     </div>

                                    <div class="row mt-3">
                                        <div class="col" *ngIf="qrText.length>0">
                                            <label>Text Color</label>
                                            <chrome-picker [control]="colorControl" (change)="onColorPickerChange()"></chrome-picker>
                                        </div>
                                        <div class="col" *ngIf="qrText.length>0 && isQRBorderRequired==true">
                                            <label>QR Text Border Color</label>
                                            <chrome-picker [control]="qrTextBorderColor"></chrome-picker>
                                        </div>
                                    </div>
                                  </div>

                               </div>


                                <div (click)="onLogoClick()" class="row qrcustomizeoptionsbgColor pointeronhover mt-2">
                                    <div class="col">Logo</div>
                                    <div class="col text-right">
                                       <span class="pointeronhover"  >
                                            <i class="fa fa-plus" *ngIf="isLogoOptionVisible==false" aria-hidden="true" ></i>
                                            <i class="fa fa-minus" *ngIf="isLogoOptionVisible==true" aria-hidden="true"></i>
                                       </span>
                                    </div>
                                </div>
                                <div class="row mt-1" id="logooptions" *ngIf="isLogoOptionVisible">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col-12"><span><i class="fa fa-qrcode framesize" aria-hidden="true"></i></span></div>
                                                <div class="col-12 frametextsize">logo style</div>
                                            </div>
                                            <div class="row">
                                                <input type="file"  accept="image/*" class="file-upload" (change)="FileSelected($event.target.files);">
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mt-2">
                                                    <label>Logo Border</label>
                                                    <input type="number" min="0" [(ngModel)]="logoBorder" class="form-control form-control-sm"/>
                                                </div>
                                                <div class="col-12 mt-2" *ngIf="logoBorder>0">
                                                    <label>Logo Border Color</label>
                                                    <chrome-picker [control]="logoBorderColor" ></chrome-picker>
                                                </div>
                                            </div>

                                        </div>
                                </div>

                           </div>
                    </div>

                </div>
            </section>

            <section class="pt-0" *ngIf="actionType=='Analytics'">
                <div class="row justify-content-c m-0  bg-white p-2">
                    <div class="row">
                        <div class="col-6 border">
                            <b>Operating System Analytics</b>
                            <div class="chart-container mt-2">
                                <canvas  id="OSChart" >{{ oschart }}</canvas>
                            </div>
                        </div>

                        <div class="col-6 border">
                            <b>Is Mobile Device Analytics</b>
                            <div class="chart-container mt-2">
                                <canvas  id="IsMobileChart" >{{ ismobilechart }}</canvas>
                            </div>
                        </div>

                        <div class="col-12 border mt-2 text-left">
                            <strong>Action On Analytics</strong>
                            <div class="chart-container mt-2">
                                <canvas  id="ActionOnChart" >{{ actiononchart }}</canvas>
                            </div>
                        </div>
                        <div class="col-6 border mt-2 text-left">
                            <strong>Browser Analytics</strong>
                            <div class="chart-container mt-2">
                                <canvas  id="BrowserChart" >{{ browserchart }}</canvas>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button  *ngIf="actionType!='Analytics'" type="button" (click)="saveQRCode()" class="btn btn-success float-right" type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
