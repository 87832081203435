<div class="modal-content">
    <div class="modal-header p-1 bg-theam">
        <h3 class="modal-title h3 m-1">{{list[0].action}} Badge</h3>
    </div>
    <form novalidate #form="ngForm" autocomplete="off">
        <div class="modal-body p-2">
            <div>
                <div class="row form-row m-0">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2" *ngIf="list[0].userId==0">
                        <div class="form-group mb-0">
                            <label>Class</label>
                            <select class="form-control form-control-sm appearance-auto" name="selectedYear" [(ngModel)]="selectedClassId" (change)="changeClass()" required>
                                <option [value]="0">
                                    Select Class
                                    </option>
                                <option *ngFor="let class of classList"  [value]="class.Id">
                                {{class.Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>Name*</label>
                            <input class="form-control form-control-sm" type="text" placeholder="Name" name="Name"
                                #Name="ngModel" [(ngModel)]="userService.badgeFormData.BadgeInfo.Name" required
                                class.invalid="Name.invalid && Name.touched">
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>Criteria*</label>
                            <input class="form-control form-control-sm" type="text" placeholder="Criteria"
                                name="Criteria" #Criteria="ngModel"
                                [(ngModel)]="userService.badgeFormData.BadgeInfo.Criteria" required
                                class.invalid="Criteria.invalid && Criteria.touched">
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>IssueDate*</label>
                            <input type="text"
                            class="form-control form-control-sm"
                            placeholder="IssueDate"
                            name="IssueDate"
                            #IssueDate="ngModel"
                            [(ngModel)]="userService.badgeFormData.BadgeInfo.IssueDate"
                            [bsConfig]="datepickerConfig"
                            [minDate]="minDate"
                            bsDatepicker
                            timepicker
                            >
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>ExpiryDate</label>
                            <input type="text"
                            class="form-control form-control-sm"
                            placeholder="ExpiryDate"
                            name="ExpiryDate"
                            #ExpiryDate="ngModel"
                            [(ngModel)]="userService.badgeFormData.BadgeInfo.ExpiryDate"
                            [bsConfig]="datepickerConfig"
                            [minDate]="minDate"
                            bsDatepicker
                            timepicker
                            >
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>Comments*</label>
                            <input class="form-control form-control-sm" type="text" placeholder="Comments"
                                name="Comments" #Comments="ngModel"
                                [(ngModel)]="userService.badgeFormData.BadgeInfo.Comments" required
                                class.invalid="Comments.invalid && Comments.touched">
                        </div>
                    </div>
                    <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4"  *ngIf="list[0].userId==0">

                        <div class="form-group mb-0">
                            <label>{{'Users' | translate}}</label>
                            <!-- <ng-multiselect-dropdown
                                [placeholder]="'Search'"
                                [settings]="dropdownSettings"
                                name="UserIdList"
                                [data]="userList"
                                [(ngModel)]="userService.badgeFormData.BadgeInfo.UserIdList"
                                (onSelect)="onItemSelect($event,'badgeUser')"
                                (onSelectAll)="onSelectAll($event,'badgeUser')"
                                (onDeSelect)="onItemSelect($event,'badgeUser')"
                                (onDeSelectAll)="onSelectAll($event,'badgeUser')">
                                </ng-multiselect-dropdown> -->
                          </div>
                      </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0" >
                            <label>&nbsp;</label>
                            <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                             name="DocumentId" [(ngModel)]="userService.badgeFormData.BadgeInfo.DocumentId"
                             (updatedResponseEmitter)="filesuploaded($event)"
                             title="Upload File" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                            </mb-large-files-uploader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-success" *ngIf="list[0].action=='Add' &&  list[0].userId>0"
                (click)="editBadgeInfo()"
                type="button">Save
            </button>
            <button type="button" class="btn btn-success" *ngIf="list[0].action=='Add' &&  list[0].userId<=0"
                (click)="saveBadgeInfo()"
                type="button">Save
            </button>
            <button type="button" class="btn btn-success" *ngIf="list[0].action=='Edit'" (click)="saveBadgeInfo()"
                type="button">Update</button>
            <button type="button" class="btn btn-danger" (click)="closeModel()" aria-label="Close">Close</button>
        </div>
    </form>
</div>
