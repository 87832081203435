import { formatDate, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
import { EditPaymentsComponent } from './edit-payments.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule]
})
export class PaymentsComponent implements OnInit {

  public bsModalRef:BsModalRef;
  frameworkComponents;
  paymentsRowData:any;
  paymentRowSelection;
  paymentDefaultColDef;
  paymentColumnDefs;
  paymentGridOptions:any;
  gridApi;
gridColumnApi;

currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  constructor(public service:UserService, private modalService: BsModalService,private notificationServ:NotificationService,public commonServ:CommonService) {
    this.commonServ.RefreshGrid$.subscribe(
      item => {
        
        this.service.getpaymentlist().subscribe((res: any) => {
          this.paymentsRowData =  res;
      });
       }
    );
   }

  ngOnInit(): void {
    
this.paymentColumnDefs = [
  { 
    headerName:"PaymentDataId",
    field: 'PaymentDataId', cellRendererFramework: CustommenuComponent, 
    cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editPayment , that : this}, {Name: "Delete", "Click" : this.deletePayment , that : this}]},
    
    filter:false, width:60,maxWidth: 60, 
    headerComponent: 'customHeader', 
    headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  }, 
  { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'Amount', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'AcademicYear', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { headerName:"TermName",field: 'PaymentDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
  { field: 'ClassName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
  { field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.paymentDefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
  sortable: true,
  floatingFilter: true,
  };

  this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
    res = res.slice().reverse();
    this.yearList = res;
    this.selectedYear = res[0].Code;
    this.config = {
      params: {
        startDate: res[0].Code,
        endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
      }
    }
  });
  }
  
onPaymentReady(params) {
  
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  setTimeout(() =>  {
    this.getPayments();
  }, 300);
 
 
}
getPayments(){
  this.service.getpaymentlist().subscribe((res: any) => {
    const rowData =  res;
    this.gridApi.setRowData(rowData);
  });
}
onPaymentReload(data){
  this.paymentsRowData = data;
}
editPayment(PaymentDataId, that){
  that.openPaymentModal(PaymentDataId);
  
}
deletePayment(paymentId, that){
  if(that.commonServ.role == 'Teacher'){
    that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
  }
  else{
var item = that.paymentsRowData.filter(item=>item.PaymentId == paymentId);
that.service.formData.PaymentInfo = item[0];
that.service.formData.PaymentInfo.IsActive = false;
that.service.savePayment().subscribe((res:any)=>{
    if(res.IsSaved){
      that.notificationServ.ShowInfo('Successfully delete!');
      
      this.getPayments();
    }else{
      that.notificationServ.ShowError(res.ErrorMessage);
    }
  });
}
}

  openPaymentModal(paymentId){
    const initialState = {
      list: [
        {id:paymentId}
      ]
    };
    this.bsModalRef = this.modalService.show(EditPaymentsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }
  serchPayments(){
    this.getPayments();
  }
  
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    }
  }
}
