<div id="showconsultationsidebar" class="video-container">
  <!-- Chat Box -->
  <app-webrtc-chat-component title="Chat" />


  <!-- Patient Video Section -->


  <div class="video-camera-outer">
    <!-- <div class="local-camera"> -->
    <div id="local" class="teacher"></div>
    <!-- </div> -->

    <!-- Remote camera display -->
    <!-- <div id="local-video">
     
    </div> -->

    <div id="remote" class="student">
      <button class="btn btn-primary" type="button" disabled id="remoteloaderbutton">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Please wait...
      </button>
    </div>

  </div>
  <div id="room-urls">
  </div>

  <div class="videos_controllers">
    <div style="display: none;" id="displayButtons">

      <div class="mute-video-btn-outer">
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnMute" title="Mute"><span
            class="bi bi-mic-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnUnMute" title="Unmute"><span
            class="bi bi-mic-mute-fill"></span>
        </button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="off-cam"
          title="Camera Off"><span class="bi bi-camera-video-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="start-cam"
          title="Camera On"><span class="bi bi-camera-video-off-fill"></span></button>
      </div>
      <!-- <iframe src="/recordScreen"></iframe> -->
      <div class="video-option-btn-outer">
        <!-- <button class="control-btn" id="screenShare"><span class="bi bi-people-fill"></span> Share Screen</button> -->
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" *ngIf="showchatboxbutton"
          id="showchat" title="Show Chat" (click)="showchatbox()"><span class="bi bi-chat-square-dots"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" *ngIf="hidechatboxbutton"
          id="hidechat" title="Hide Chat" (click)="hidechatbox()"><span class="bi bi-chat-square-dots"></span></button>
      </div>
      <div class="end-call-btn-outer">
        <button class="btn-cut" id="btnLeave" (click)="endCall()" title="Exit Class">Exit Class</button>
      </div>
    </div>
  </div>
</div>