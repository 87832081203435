import { Component, OnInit } from '@angular/core';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { ParentShareDocService } from '../shared/Parent-Share-Doc.service';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CommonService } from '../shared/common.service';
import { EditASSDocComponent } from './edit-assdoc.component';
import { CUSTOMCONST } from '../shared/Const';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditApsdocComponent } from './edit-apsdoc.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-parent-share-doc',
    templateUrl: './parent-share-doc.component.html',
    styleUrls: ['./parent-share-doc.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class ParentShareDocComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  PSDListData: any[];
  public bsModalRef:BsModalRef;

  
  constructor(public PSDService :ParentShareDocService,public commonService:CommonService,private modalService: BsModalService) { 
    this.gridInit();   
    this.commonService.RefreshGrid$.subscribe(
    
      item => {
        this.getParentShareListDocView();
        }
    );
  }

  ngOnInit(): void {
    
  }

  gridInit(){
    
    this.columnDefs = [
      // { 
      //   headerName:"shareDocId",
      //   field: 'shareDocId', cellRendererFramework: CustommenuComponent, 
      //   cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editPSDFile , that : this}, {Name: "Delete", "Click" : this.editPSDFile , that : this}]},
        
      //   filter:false, width:60,maxWidth: 60,  
      //   headerComponent: 'customHeader', 
      //   headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      // }, 
      { 
        headerName:"spaDocId",
        field: 'shareDocId', cellRendererFramework: CustommenuComponent, 
        cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editPSDFile , that : this}, {Name: "Delete", "Click" : this.deleteDocument , that : this}]},
        
        filter:false, width:60,maxWidth: 60, 
        headerComponent: 'customHeader', 
        headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      },
     
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
    { field: 'PurposeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
    { field: 'SchoolCategory', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200 ,maxWidth: 300 },
    { field: 'TypeOfPost', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 200 },
    { field: 'StateId', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 300 ,maxWidth: 300 },

    
   ];
   this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
  this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
   
  };
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getParentShareListDocView();
  }
  getParentShareListDocView(){
    this.PSDService.getParentShareListDocView().subscribe((res : any) => {
      // this.PSDListData =  res.ParentShareDocListInfo;
      const rowData =  res.ParentShareDocListInfo;
      this.gridApi.setRowData(rowData);
      // this.ownDocsServ.formData = res;
    });
  }

  editPSDFile(id,that){
    that.openParentSharedModel(id)
  }

  deleteDocument(id,that){
    that.PSDService.getParentShareDocView(id).subscribe((res : any) => {
      that.PSDService =  res;
      
      if(res){
        that.PSDService.IsActive = false
        that.PSDService.deleteDocument().subscribe((res:any)=>{
          if(res.IsSaved){
            that.notificationServ.ShowInfo('Successfully deleted!');
            that.getParentShareListDocView();
          }else{
            that.notificationServ.ShowError(res.Message);
          }
        });
      }
     });
  }


  openParentSharedModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditApsdocComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
}
