import { Component, OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QuestionService } from '../shared/question.service';
import { NotificationService } from '../shared/notification.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommonService } from '../shared/common.service';
import { ConfirmComponent } from 'src/app/common/confirm.component';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { QuestionMaster } from '../shared/question.model';
import { StudentAnswerpreviewComponent } from './student-answerpreview.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgIf, NgFor, NgClass } from '@angular/common';
@Component({
    selector: 'app-viewdocument',
    templateUrl: './viewdocument.component.html',
    styleUrls: ['./viewdocument.component.scss'],
    standalone: true,
    imports: [NgIf, YouTubePlayerModule, NgxDocViewerModule, NgFor, FormsModule, MatRadioModule, MatCheckboxModule, //Ng7LargeFilesUploadLibModule,
       NgClass, MatIconModule, TranslateModule]
})
export class ViewdocumentComponent implements OnInit {
  list:any[]=[];
  fileType:any;
  urlType:string;
  url:any;
  urlSafe: SafeResourceUrl;
  description:any;
  questioner:boolean;
  startAnswer:boolean;
  currentQuestionNo:number;
  questionInfo:any;
  noofQuestions:number;
  enbedUrl:any;
  youtubeUrlId:string;
  ispreviewAnswer:boolean;
  status:string;
  stdAnswerMasterInfo:any=0;
  CUSTOMCONST : any;
  lastdocumentId: number;
  isprocessing : boolean = false;
  // resultTypeList:any;
  // tfValue = [{text: 'True'},{text: 'False'}];
  fileExtension:any;
  docUrl:any;
  name:string='';
  vid:any;
  constructor(public service:AssignemntService,public questionService:QuestionService,
    public bsModalRef: BsModalRef,public modalService:BsModalService,public sanitizer: DomSanitizer,
    private notificationServ:NotificationService,
    public commonServ:CommonService) {
    this.commonServ.OnConfirm$.subscribe(
      item => {
        this.saveStudentAnswerInner();
          }
    );
    this.CUSTOMCONST = CUSTOMCONST;
   }

  ngOnInit(): void {
    debugger;
    this.fileType = this.list[0].data.Type;
    this.urlType = this.list[0].data.UrlType;
    this.url = this.list[0].data.Url;
    this.questioner = this.list[0].data.IsQuestionir;
    this.description =  this.list[0].data.Description;
    this.status =  this.list[0].data.Status;
    this.startAnswer = false;
    this.currentQuestionNo = 0;
    this.name = this.list[0].data.Name

//     var tableopening = '<table class="MsoNormalTable"';
//     var tableclose = '</table>';
//     this.description = (this.list[0].data.Description).replace(tableopening, '<div class="table-responsive"><table class="table">');
//     this.description = (this.list[0].data.Description).replace(tableclose, '</table></div>');

    if(this.url !=''){
      var ftList = this.list[0].data.Url.split('.');
      this.fileExtension = ftList[ftList.length-1];
      if(this.fileExtension === 'doc' || this.fileExtension === 'docx'){
        var re1 = /\\/gi;
        this.docUrl = (this.list[0].data.Url).replace(re1, "/");
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUrl);

      }
    }


    // this.questionService.getquestioninfo(this.list[0].data.QuestionMasterId).subscribe((res : any) => {
    //   this.questionInfo = res;
    // });
    this.service.getassignment(this.list[0].data.AssignmentId).subscribe((res : any) => {
      this.service.formData =  res;
      this.service.formData.QuestionInfo = res.QuestionInfo;
      this.service.formData.QuestionMasterInfo = res.QuestionMasterInfo;
      this.noofQuestions = this.service.formData.QuestionInfo.length;
      // this.service.formData.QuestionInfo[0].QuestionNo;
      this.getStudentResult(res.QuestionMasterInfo.QuestionMasterId);
  });

    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // console.log ( this.service.formData);

    if(this.urlType == "Url"){
      // this.enbedUrl = this.embedService.embed(this.list[0].data.Url,
      //   {
      //     query: { portrait: 0, color: '333' },
      //     attr: { style:'width:100%;height:450px;min-height: calc(100vh - 190px);'},
      //   });
        this.youtubeUrlId = this.list[0].data.Url.replace('https://www.youtube.com/watch?v=','');
    }
    if(this.urlType == "File" && this.fileType == "Watch")
    {
      setTimeout(()=>{
        this.vid = document.getElementById("assVideo");
        // write logic for star set position of video
        // this.vid.currentTime = this.list[0].data.currentTime
      },500)

    }
    this.commonServ.getlastdocumentid().subscribe((res : any) => {
      this.lastdocumentId = res;
       });
  }
  getStudentResult(questionMasterId:number){
    if(questionMasterId > 0){
    this.questionService.getStudentAnswerInfo(questionMasterId).subscribe((res : any) => {
      this.stdAnswerMasterInfo = res
      // this.resultTypeList = res.ResultTypeList;


  });
}
else {
  this.service.getstudentmasterid(this.service.formData.AssignmentInfo.AssignmentId).subscribe((res : any) => {
    this.stdAnswerMasterInfo = {};
    this.stdAnswerMasterInfo.StudentAnswerMasterDataId = res;
});
}
  }
  proceedNextStep(){
    this.startAnswer = true;
    this.questionInfo = this.service.formData.QuestionInfo[this.currentQuestionNo];
    //this.noofQuestions = this.service.formData.QuestionMasterInfo.NoOfQuestions;
    this.currentQuestionNo = this.currentQuestionNo + 1;
  }
  prevQuestion(){
    // this.service.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;
    this.currentQuestionNo = this.currentQuestionNo -1;
    this.questionInfo = this.service.formData.QuestionInfo[this.currentQuestionNo-1];
  }
  saveStudentAnswer(){
    const initialState = {
      list: [
        {id:'confirm'},
        {message:"Are you sure want to submit your answers?"}
      ]
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent,{id : CUSTOMCONST.CONFIRM_MODAL, initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
  }
  saveStudentAnswerInner(){
    if(!this.isprocessing){
      this.isprocessing = true;
    this.service.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;

    this.service.savestudentassignment().subscribe((res:any)=>{
      this.isprocessing = false;
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonServ.CallOnRefreshGrid();
        if(res.AssignmentInfo.IsQuestionir)
        {
          this.ispreviewAnswer = true;
        }else{
          this.modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL);
        }

        // this.bsModalRef.hide();
      }
      else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  }
  // addStudentAnswers(){
  //   // alert('triger');
  //   this.service.formData.QuestionInfo[this.currentQuestionNo-1] = this.questionInfo;

  //   this.service.addStudentAnswers().subscribe((res:QuestionViewData)=>{
  //     if(res.IsSaved){
  //       this.notificationServ.ShowInfo('Successfully saved!');
  //       this.bsModalRef.hide();
  //     }
  //   });

  // }

  changeMC(length, index ) {
    var i=0;
    for(i; i<length; i++ ){
      if(i!=index)
      {
        this.questionInfo.QAnswerList[i].StrAnswer = '';
      }
      else
      {
        this.questionInfo.QAnswerList[index].StrAnswer = this.questionInfo.QAnswerList[index].StrAnswer;
      }

    }
  }
  largefileschange(event){
  }

  filesuploaded(fileInfo){
    if(fileInfo && fileInfo.IsSaved)
    if(this.service.formData.AssignmentInfo.Type === "Home work"  || this.service.formData.AssignmentInfo.Type === "Do")
    {
      this.service.formData.AssignmentInfo.ResponseFileId = fileInfo.UserDocumentId;
    }
    else{
      this.service.formData.QuestionMasterInfo.ResponseFileId = fileInfo.UserDocumentId;
    }

    this.commonServ.getnewlyaddeddocumentids(this.lastdocumentId).subscribe((res : any) => {
      var newlyAddedDocumentList = res;
      if(res && res.length > 1){
      var docId = '';
      newlyAddedDocumentList.forEach(element => {
        if(docId){
          docId = docId+','+element;
        }
        else {
          docId = element;
        }
      });
      this.service.formData.AssignmentInfo.ResponseFileIds = docId;
      if(this.service.formData.AssignmentInfo.Type !== "Home work")
    {
      this.service.formData.QuestionMasterInfo.ResponseFileIds = docId;
    }
    }
    else{

    }

       });
      }

      viewResult(){
        this.bsModalRef.hide();
        // this.ispreviewAnswer = true;
        const initialState = {
          list: [
            {id:this.stdAnswerMasterInfo.StudentAnswerMasterDataId,
              reqBy:'Student'
            },
            {classidlist : ''}
          ]
        };
        this.bsModalRef = this.modalService.show(StudentAnswerpreviewComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
      }
  htmlContent = '';

config: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '15rem',
  minHeight: '5rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [
    ['bold']
    ],
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ]
};
closeModal(){
  let assingmentData = {
    AssignmentInfo : this.list[0].data, //the sub object which we get in getassignment api call.
    ErrorMessage : '',
    IsSaved : false
    };
    let duration = assingmentData.AssignmentInfo.TotalDuration;
      let currentTime = assingmentData.AssignmentInfo.CompletedTime;
  if(this.urlType == "File" && this.fileType == "Watch")
  {

    duration = this.vid.duration;
      currentTime = this.vid.currentTime;
      if(currentTime >= (duration - 1)){
        // update the Iswhatch ststus
        assingmentData.AssignmentInfo.TotalDuration = duration;
        assingmentData.AssignmentInfo.CompletedTime = currentTime;
        assingmentData.AssignmentInfo.IsWatch = true;
        this.savestudentassignmentvideoInfo(assingmentData);
      }else{
        // update the video start time ststus
        assingmentData.AssignmentInfo.TotalDuration = duration;
        assingmentData.AssignmentInfo.CompletedTime = currentTime;
        assingmentData.AssignmentInfo.IsWatch = false;
        this.savestudentassignmentvideoInfo(assingmentData);
      }

  }
  else if(this.urlType == "Url" && this.fileType == "Watch")
  {

    duration = this.player.getDuration();
      currentTime = this.player.getCurrentTime();
      if(currentTime >= (duration - 1)){
        // update the Iswhatch ststus
        assingmentData.AssignmentInfo.TotalDuration = duration;
        assingmentData.AssignmentInfo.CompletedTime = currentTime;
        assingmentData.AssignmentInfo.IsWatch = true;
        this.savestudentassignmentvideoInfo(assingmentData);
      }else{
        // update the video start time ststus
        assingmentData.AssignmentInfo.TotalDuration = duration;
        assingmentData.AssignmentInfo.CompletedTime = currentTime;
        assingmentData.AssignmentInfo.IsWatch = false;
        this.savestudentassignmentvideoInfo(assingmentData);
      }

  }
  this.modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL)

}
playerVars = {
  cc_lang_pref: 'en',
};
version = '...';
private player;
public ytEvent;
onStateChange(event) {
  this.ytEvent = event.data;
}
savePlayer(player) {
  this.player = player.target;
}

playVideo() {
  this.player.playVideo();
}

pauseVideo() {
  this.player.pauseVideo();
}
savestudentassignmentvideoInfo(data:any){

  this.service.savestudentassignmentvideoInfo(data).subscribe((res:any)=>{
    console.log(res);

  });
}
}
