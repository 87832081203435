import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  
  constructor() { }
  setUserData(userData:any){
    sessionStorage.setItem('userData',JSON.stringify(userData));
  }
  getUserData(){
    const userDataString = sessionStorage.getItem('userData');
    if(userDataString){
      const userData = JSON.parse(userDataString);
      return userData;
    }
    return null;
  }
  formateUserData(data:any){
    const  userData ={
      AuthToken:data.AuthToken,
      BranchId:data.BranchId,
      FullName:data.FullName,
      LogoPath:data.LogoPath,
      Role:data.Role,
      SchoolId:data.SchoolId,
      UserId:data.UserId,
      ClassId:data.Role=='Student'?data.ClassId:0,
    }
    return userData;
 
  }
  setSessionData(key:string, userData:any){
    sessionStorage.setItem(key, JSON.stringify(userData));
  }
  getSessionData(key:string){
    const sessionString = sessionStorage.getItem(key);
    if(sessionString){
      const sessionData = JSON.parse(sessionString);
      return sessionData;
    }
    return null;
  }
}
