import { Component, OnInit } from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class HelpComponent implements OnInit {
  list:any[];
  helpTabName:string;
  CUSTOMCONST:any;
  constructor(public bsModalRef: BsModalRef) {
    
   }

  ngOnInit(): void {
    this.helpTabName = this.list[0].name;
  }

}
