import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';

@Component({
    selector: 'app-terms-of-service',
    templateUrl: './terms-of-service.component.html',
    styleUrls: ['./terms-of-service.component.scss'],
    standalone: true
})
export class TermsOfServiceComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,
    private canonicalService: CanonicalService) { }


  ngOnInit(): void {
    this.titleService.setTitle('Ecosystem LMS Platform for K12 Academics, SEL, Life Skills, STEM.');
    this.metaService.addTags([
      {name: 'keywords', content: 'Knowledge is power, Information is liberating, Education is the premise of progress, Student needs, Teachers can help, '},
      {name: 'description', content: 'At Hybrid Learning Platform team have five core values guide how we work one another, with our board and advisors, and with our partners and collaborators.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
  }

}
