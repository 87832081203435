
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { LeaveData, LeaveInfo, LeaveStatusInfo, LeaveViewData } from './leave.model';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  readonly baseUrl = CONFIG.BASE_URL+'api/leave';
  LeaveData:any = new LeaveData();
  formData:LeaveViewData = new LeaveViewData();
  leaveStatusInfo:LeaveStatusInfo=new LeaveStatusInfo();
  constructor(private http:HttpClient) { 
    this.formData.LeaveInfo = new LeaveInfo();
    // this.leaveStatusInfo = new LeaveStatusInfo();
  }
 

 
  getleaveinfo(){
    return this.http.get(this.baseUrl+'/getleaveinfo');
  }
  saveleaveinfo(){
    return this.http.post(this.baseUrl+'/saveleaveinfo',this.formData);
  }

 // For Parent and Student pass studentid, for teacher.. we don't need it.
  getleaveinfoById(studentid:number){
    var config = {
      params: {
        userId: studentid,
      }
    };
    return this.http.get(this.baseUrl+'/getleaveinfo',config);
    
  }
  getuserleaveinfo(params:any){
    return this.http.get(this.baseUrl+'/getuserleaveinfo',params);
  }
  getassigneduserleaveinfo(params:any){
    return this.http.get(this.baseUrl+'/getassigneduserleaveinfo',params);
  }
  getalluserleaveinfo(params:any){
    return this.http.get(this.baseUrl+'/getalluserleaveinfo',params);
    
  }
  saveleavestatusinfo(){
    return this.http.post(this.baseUrl+'/saveleavestatusinfo',this.leaveStatusInfo);
  }
}
