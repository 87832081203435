import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-desc-myachievement',
    templateUrl: './desc-myachievement.component.html',
    styleUrls: ['./desc-myachievement.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class DescMyachievementComponent implements OnInit {
  list:any;
  CUSTOMCONST:any=CUSTOMCONST; 
  
  constructor(private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService, public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
