<div class="container-fluid bg-light pb-3">
    <section class="pt-3">
        <div class="row">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 ">
                <div class="card cursor-pointer" [ngClass]="{'card-boarder':activeLearningType=='SchoolBL'}" (click)="changeLearningType('SchoolBL')">
                    <div class="card-body text-center">
                        <div class="icon pb-1"><img src="../../assets/images/icons/svg/school_building.svg"></div>
                        <div class="content"><span>School Based Learning</span></div>
                    </div>
                </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0">
                <div class="card cursor-pointer" [ngClass]="{'card-boarder':activeLearningType=='HomeBL'}" (click)="changeLearningType('HomeBL')">
                    <div class="card-body text-center">
                        <div class="icon pb-1"><img src="../../assets/images/icons/svg/home.svg"></div>
                        <div class="content"><span>Home Based Learning</span></div>
                    </div>
                </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0">
                <div class="card cursor-pointer" [ngClass]="{'card-boarder':activeLearningType=='CommunityBL'}" (click)="changeLearningType('CommunityBL')">
                    <div class="card-body text-center">
                        <div class="icon pb-1"><img src="../../assets/images/icons/svg/people.svg"></div>
                        <div class="content"><span>Community Based Learning</span></div>
                    </div>
                </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0">
                <div class="card cursor-pointer" [ngClass]="{'card-boarder':activeLearningType=='SocietyBL'}" (click)="changeLearningType('SocietyBL')">
                    <div class="card-body text-center">
                        <div class="icon pb-1"><img src="../../assets/images/icons/svg/crowd.svg"></div>
                        <div class="content"><span>Society Based Learning</span></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-3">
            <div class="col-12">
                <div *ngIf="activeLearningType == 'SchoolBL'">
                    <div class="assignment-heading d-flex">
                        <img class="pr-2 fw-bold" src="../../assets/images/icons/svg/home.svg">
                        <span class=" pr-2 fw-bold">School Based Learning</span>
                        <span class="bg-primary text-white" style="padding: 4px 8px;">Pedding Assignments (0)</span>
                    </div>
                    <div>
                        <p class="p-0 m-0">
                            Formal/School learning environment: Improving what we measure.
                            1. Build Relationships and Provide support,
                            2. Incorporate technology thoughtfully,
                            3. Support additional Home Learning,
                            4. Continue to engage and momentum.<br /><br />
                            A positive school climate is essential to supporting young people's academic success. Students' motivation, behavior, and learning can be impacted by trusting relationships between teachers and students.
                            The research has shown that a positive school climate is characterized by high-quality interpersonal relationships among students, teachers, and peers.
                        </p>
                    </div>
                </div>

                <div *ngIf="activeLearningType == 'HomeBL'">
                    <div class="assignment-heading d-flex">
                        <img class="pr-2 fw-bold" src="../../assets/images/icons/svg/home.svg">
                        <span class=" pr-2 fw-bold">Home Based Learning</span>
                        <span class="bg-primary text-white" style="padding: 4px 8px;">Pedding Assignments (0)</span>
                    </div>
                    <div>
                        <p class="p-0 m-0">
                            Home learning environment: There’s no place like home. 1. Social Emotional Learning, 2. Outside the Box Learning, 3. Personalized Learning, 4. Life Skills <br /><br />
                            The home is a child’s first school. The character and features of the home environment—including the quality and security of relationships and the availability of various resources—create the foundations of healthy development,
                            learning, and ongoing levels of wellbeing and happiness. “Skills” are centrally important for human capital development and workforce success. Key “SKILLS” that foster youth workforce success: Toward a consensus across fields.

                        </p>
                    </div>
                </div>

                <div *ngIf="activeLearningType == 'CommunityBL'">
                    <div class="assignment-heading d-flex">
                        <img class="pr-2 fw-bold" src="../../assets/images/icons/svg/home.svg">
                        <span class=" pr-2 fw-bold">Community Based Learning</span>
                        <span class="bg-primary text-white" style="padding: 4px 8px;">Pedding Assignments (0)</span>
                    </div>
                    <div>
                        <p class="p-0 m-0">
                            Community learning environment: More traditional learning environments 1. Project Based Learning, 2. Experiential Learning, 3. Collaborative Learning, 4. Cooperative Learning <br /><br />
                            Community-based learning refers to a wide variety of instructional methods, programs that educators use to connect what is being taught in schools to their surrounding.
                            In today’s fast-moving knowledge economy and automated labor market, students
                            experience uncertainty in planning their future. They must learn more about
                            themselves and the world of work and align their life and career decision with
                            their age, developmental stage, and life goals.
                        </p>
                    </div>
                </div>

                <div *ngIf="activeLearningType == 'SocietyBL'">
                    <div class="assignment-heading d-flex">
                        <img class="pr-2 fw-bold" src="../../assets/images/icons/svg/home.svg">
                        <span class=" pr-2 fw-bold">Society Based Learning</span>
                        <span class="bg-primary text-white" style="padding: 4px 8px;">Pedding Assignments (0)</span>
                    </div>
                    <div>
                        <p class="p-0 m-0">
                            Society learning environment: More cultural learning environments 1. Spiritual Wellness.
                            2. Observational Learning.
                            3. Reinforced Learning.
                            4. Behavioral Social Skills.
                            <br /><br />
                            Society plays a significant role in education. The values, morals, and principles of a society will create an education system that upholds the same values, morals, and principles.
                            Education is a social concern. It is a social process. Its objective is to develop and awaken in the child those physical,
                            intellectual and moral states which are acquired of the individual by his society as a whole and the milieu for which he is specially destined.
                        </p>
                    </div>
                </div>
                <div class="assignment-content">

                    <div class="mt-3">
                            <section class="">
                                <div class="container-fluid p-0">
                                    <mat-tab-group animationDuration="0ms" class="bg-transparent border-0 dashboard-tab1"
                                    (selectedTabChange)="activeTabChange($event)">
                                        <mat-tab label="WatchAssingment">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Watch Assignments</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="watchList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{watchList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let watchListData of watchList">
                                                                <div class="card">
                                                                    <div class="assignments-video position-relative">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                      <div class="video-button-group1 d-flex justify-content-between flex-wrap">
                                                                        <div class="d-flex flex-wrap">
                                                                          <div class="p-0 btn-group"> 
                                                                            <button class="btn btn-primary" [disabled]="watchListData.Status === 'Completed'" (click)="openModel(watchListData)">Watch</button>
                                                                          </div>
                                                                          <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                                            <button class="btn btn-success" [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToThink" (click)="openThinkModel(watchListData)">Think</button>
                                                                         </div> 
                                                                         <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                                            <button class="btn btn-primary" [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToExplore" (click)="updateIsExplore()">Explore </button>
                                                                          </div>   
                                                                          <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                                            <button class="btn btn-success" [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToSubmitProject">Present </button>
                                                                        </div>
                                                                        <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                                            <button class="btn btn-success">{{watchListData.Result==""?"Pending":watchListData.Result }}</button>
                                                                        </div>
                                                                        <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                                            <button class="btn btn-primary">Point: {{watchListData.DiscussionPoints<10?'0'+watchListData.DiscussionPoints:watchListData.DiscussionPoints}}</button>
                                                                        </div>
                                                                        <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                                            <!-- <button class="btn btn-success" [title]="watchListData.Comments">Feedback </button> -->
                                                                            <button class="btn btn-success btn-mat" mat-flat-button color="accent"
                                                                                    matTooltip="{{watchListData.Comments?watchListData.Comments:'No Feedback Available'}}"
                                                                                    [matTooltipPosition]="position"
                                                                                    >
                                                                                    Feedback
                                                                            </button>
                                                                        </div>
                                                                        <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                                            <button class="btn btn-primary">SelfAssees</button>
                                                                        </div>
                                                                        </div>




                                                                      </div>
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{watchListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">{{watchListData.StartDate | date:'MMMM d, y'}}</small></p>
                                                                            <p class="card-text"><small class="text-muted">{{watchListData.StartTime | date:'h:mm a'}}</small></p>
                                                                            <p class="card-text"><small class="text-muted">{{watchListData.EndTime | date:'h:mm a'}}</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="watchListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="watchList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Data not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="ListenAssingments">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Listen Assingments</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="listenList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{listenList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let listenListData of listenList">
                                                                <div class="card">
                                                                    <div class="assignments-video">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{listenListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">August 5 2023</small></p>
                                                                            <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                                                            <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="listenListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="listenList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Listen Assignment not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="ReadAssingments">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Read Assingments</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="readList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{readList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let readListData of readList">
                                                                <div class="card">
                                                                    <div class="assignments-video">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{readListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">August 5 2023</small></p>
                                                                            <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                                                            <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="readListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="readList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Read Assignment not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="WriteAssingments">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Write Assingments</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="writeList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{writeList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let writeListData of writeList">
                                                                <div class="card">
                                                                    <div class="assignments-video">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{writeListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">August 5 2023</small></p>
                                                                            <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                                                            <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="writeListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="writeList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Write Assignment not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="DoAssingments">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Do Assingments</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="doList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{doList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let doListData of doList">
                                                                <div class="card">
                                                                    <div class="assignments-video">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{doListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">August 5 2023</small></p>
                                                                            <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                                                            <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="doListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="doList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Do Assignment not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Homework">
                                            <ng-template mat-tab-label>

                                                <div class="m-0">
                                                    <div class="d-flex align-items-center">
                                                        <label class="m-0">Homework</label>

                                                    </div>
                                                </div>

                                            </ng-template>
                                            <div class="mt-3 rounded" *ngIf="homeWorkList.length>0">
                                                <div class="tab-content">
                                                    <div class="today-live-class">

                                                        <div class="row">
                                                            <div class="col-md-12 px-2">
                                                                <p class="m-0 pb-3">{{homeWorkList.length}} Assignments</p>
                                                            </div>
                                                            <div class="col-3 px-2" *ngFor="let homeWorkListData of homeWorkList">
                                                                <div class="card">
                                                                    <div class="assignments-video">
                                                                        <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                                                    </div>
                                                                    <div class="card-body p-0">
                                                                        <div class="assignments-heading">
                                                                            <p class="card-text">{{homeWorkListData.Name}}</p>
                                                                        </div>
                                                                        <div class="assignments-date-time d-flex justify-content-between">
                                                                            <p class="card-text"><small class="text-muted">August 5 2023</small></p>
                                                                            <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                                                            <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                                                        </div>
                                                                        <div class="assignments-disc">
                                                                            <div [innerHtml]="homeWorkListData.Description"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="empty-Data" *ngIf="homeWorkList.length == 0">
                                                <div class="d-flex justify-content-center h-100 align-items-center" >
                                                    <p>Homework not found!</p>
                                                </div>
                                            </div>
                                        </mat-tab>

                                    </mat-tab-group>
                                </div>
                            </section>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
