import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { UserService } from '../shared/user.service';
import { UsereditComponent } from '../user/useredit.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-teacher-list',
    templateUrl: './teacher-list.component.html',
    styleUrls: ['./teacher-list.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class TeacherListComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData:any;
  bsModalRef: BsModalRef;
  gridOptions: any;
  constructor(public commonServ:CommonService,public service:UserService,private modalService: BsModalService,) {
    this.columnDefs = [
      {
        headerName: "Action", field: 'UserId', filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.MenuEditClick, that: this }, { Name: "Delete", "Click": this.deleteUser, that: this }] },
        headerComponent: 'customHeader',
        headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      { field: 'FullName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'Address', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'Street', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'City', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'State', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'PostalNo', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'Mobile', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'SMobile', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'Role', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'Branch', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
      { field: 'Email', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 220 }
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = 'single';
    this.commonServ.RefreshGrid$.subscribe(
      item => {

        this.getStuUserList();
        
      }
    );
   }

  ngOnInit(): void {
  }
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getStuUserList();
    }, 300);
  }
getStuUserList(){
  this.service.getteacheruserlist().subscribe((res: any) => {
    const rowData =  res;
    this.gridApi.setRowData(rowData);
  });
}


deleteUser(UserId, that) {
}
MenuEditClick(UserId, that) {
 const initialState = {
   list: [
     { id: UserId }
   ]

 };
 that.bsModalRef = that.modalService.show(UsereditComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
 that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that))

}
}
