<div class="banner">
  <div class="banner-bg-image">
      <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
  </div>
  <div class="banner-image w-100">
    <img class="img-fluid w-100" src="../../assets/images/benefits_page/benefits_banner.png" alt="contact-banner-image">
  </div>
  <div class="banner-content-left d-none d-sm-block">
      <h1 class="w-100">The Learner-Centered Platform</h1>
      <p> </p>
  </div>
</div>
  <section>
    <div class="container-fluid bg-theam-body">
      <div class="row justify-content-center">
        <div class="col-10">
          
          <div class="row mt-5">
            <div class="col-xl-2 col-lg-2 col-md-3 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/benefits_page/SCHOOL ADMINISTRATORS BENEFITS.jpg" alt="School_Administrators_benefits" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-9 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">School Administrators benefits</h1>
              </div>

              <blockquote class="blockquote">
                <p class="mb-0">"Hybrid Learning Platform enables you to use your school's savings and energy efficiently with its high level of time and performance management!"</p>
              </blockquote>
    
                
              <ul class="pl-0 custom-list-style1">
                <li class="mb-0 right-angle1"><span class="font-weight-500">The Hybrid Learning Platform:</span> It enables your school to take an active role in the transformation process in education.</li>
                <li class="mb-2 right-angle2"><span class="font-weight-500">The Data-Based Integrated Decision Support System:</span> It allows you to improve your teaching processes with the right timing and correct interventions. It creates a corporate memory of all the parameters equally by educational boards.</li>
                <li class="mb-2 right-angle3"><span class="font-weight-500">World's First research-based teaching and learning collaborative personalised and student agency platform. </span>It ensures the storage and transfer of about your students Academics, SEL, Life Skills, Outside The Box Learning in a single platform. It supports the production and creativity oriented work of your students and teachers.</li>
                <li class="mb-2 right-angle4"><span class="font-weight-500">Professional development support:</span> It enables you to have an assertive staff to apply up-to-date educational methods with their competencies. It enables you to increase efficiency and quality in education with innovative and dynamic learning environments. It significantly reduces the administrative costs of your institution.</li>
                <li class="mb-2 right-angle5"><span class="font-weight-500">Anonymous Feedback:</span> Get regular feedback from Students and Parents regarding the teachers to better understand their expectations.</li>
  
  
  
            </ul>
            
            </div>
            
          </div>
          <div class="row mt-5">
            <div class="col-xl-2 col-lg-2 col-md-3 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/benefits_page/Teacher.jpg" alt="Teachers_Benefits" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-9 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Teachers benefits</h1>
              </div>
    
            <blockquote class="blockquote">
              <p class="mb-0">"Hybrid Learning saves teachers a significant amount of time with its calendar structure that enables them to easily manage their educational processes and professional routines."</p>
            </blockquote>
            <ul class="pl-0 custom-list-style1">
                <li class="mb-2 right-angle1">It redefines the role of the teacher as <span class="font-weight-500">innovative educator and </span> places him/her at the epic point of transformation in education.</li>
                <li class="mb-2 right-angle2">It enables the teacher to mould his/her strategies, teach in accordance with <span class="font-weight-500">the habits and learning styles of the students.</span></li>
                <li class="mb-2 right-angle3">It allows the teacher to <span class="font-weight-500">Plan</span> lessons and customise the teaching processes in a <span class="font-weight-500">student-centric way</span> </li>
                <li class="mb-2 right-angle4">It enriches the learning environments with <span class="font-weight-500">qualified, original, and reliable digital content, </span> compatible with national and international curricula.
                </li>
                <li class="mb-2 right-angle5">Use a single application to teach, manage assignments, review Assessments and communicate feedback.<span class="font-weight-500"> </span>
                </li>
                <li class="mb-2 right-angle1">It offers necessary applications <span class="font-weight-500">to create content and interactive assignments and produce assessments for and of learning.</span>
                </li>
                <li class="mb-2 right-angle2"><span class="font-weight-500">The Professional Development Programmes </span>help teachers to become lifelong learner by expanding their professional vision.</li>
            </ul>
            </div>
            
          </div>
          <div class="row mt-5">
            <div class="col-xl-2 col-lg-2 col-md-3 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/benefits_page/student.jpg" alt="Student_Benefits" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-9 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Student benefits</h1>
              </div>
    
            <blockquote class="blockquote">
              <p class="mb-0">"With Hybrid Learning Platform interactions such as discussing, sharing and voting, students improve their communication, problem solving, multi-faceted perspective and critical thinking skills."
              </p>
            </blockquote>
            <ul class="pl-0 custom-list-style1">
                <li class="mb-2 right-angle1">It offers a personalized learning environment for each student.</li>
                <li class="mb-2 right-angle2">It supports students in terms of digital literacy and content production with collaborative projects.</li>
                <li class="mb-2 right-angle3">Peer interaction enables students to take responsibility for learning and teaching with the opportunity of interactive learning. </li>
                <li class="mb-2 right-angle4">With detailed analysis, it provides students with specific guidance to their deficiencies and enables them to save time by working in a target-oriented manner. </li>
                <li class="mb-2 right-angle5">Student's portfolio helps them to be actively responsible in their learning goals & makes them prioritise and manage tasks with the calendar & To-do list features.</li> 
                <li class="mb-2 right-angle1">Student profile helps them to discover & express their personality, goals & ambition.</li>
                
            </ul>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-xl-2 col-lg-2 col-md-3 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/benefits_page/Parent.jpg" alt="Parents_Benefits" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-9 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Parents benefits</h1>
              </div>
            <blockquote class="blockquote">
              <p class="mb-0">"Increased parent satisfaction with strong communication and home-school coordination."
              </p>
            </blockquote>
            <ul class="pl-0 custom-list-style1">
              <li class="mb-2 right-angle1">Parents are ensured that the child makes learning a part of their daily life, both in classroom and beyond, that their social learning skills are strengthened and that this will be reflected in their academic success.</li>
              <li class="mb-2 right-angle2">They are constantly informed of the current progress of their child.</li>
              <li class="mb-2 right-angle3">They know that their child can now learn fully and permanently with special instructions for the deficiencies, and they can follow the process themselves.</li>
              <li class="mb-2 right-angle4">Parents do not need to search for an additional source during the preparation process for the school exams.</li>
              <li class="mb-2 right-angle5">Parents get real time notification on classes, Assignments, and Assessments enabling them to play active role.</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  