import { Component, OnInit } from '@angular/core';
import { ParentShareDocService } from '../shared/Parent-Share-Doc.service';
import { CommonService } from '../shared/common.service';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
} from 'ng-multiselect-dropdown';
import { CUSTOMCONST } from '../shared/Const';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-apsdoc',
    templateUrl: './edit-apsdoc.component.html',
    styleUrls: ['./edit-apsdoc.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgFor, NgMultiSelectDropDownModule, NgIf, Ng7LargeFilesUploadLibModule, TranslateModule]
    imports: [FormsModule, NgFor, NgIf, TranslateModule]

})
export class EditApsdocComponent implements OnInit {
  list:any[]=[];
  url:any;
  documentId:any;
  CUSTOMCONST:any=CUSTOMCONST;
  ChallengeInfo:any=[];
  SchoolCategoryIdList:number;
  stateIdList: any;
  stateList:any=[];
  schoolCatList:any;
  purofpostList:any;
  typeOfPostList:any;
  challengeList:any;
  canFileUpload:boolean=true;
  dropdownSettings:IDropdownSettings;
  constructor(public service :ParentShareDocService,public modalService:BsModalService,private notificationServ:NotificationService, private commonServ:CommonService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.getParentShareDocView();
  }
  getParentShareDocView(){
    this.service.getParentShareDocView(this.list[0].id).subscribe((res:any)=>{
      this.service.formData = res;

      this.stateIdList = res.StateInfo;
      this.schoolCatList=res.SchoolCategoryInfo;
      console.log(this.schoolCatList);

      this.purofpostList=res.PurposeOfPostInfo;
      this.typeOfPostList=res.TypeOfPostInfo;
      this.challengeList=res.ChallengeInfo;
      // console.log("this.stateIdList");
      // console.log(this.stateIdList);
      console.log(res);

    })
  }


  fileTypeChange(){
    this.service.formData.ParentShareDocInfo.TypeOfPostId;

    this.typeOfPostList.find(item=>{
      if(item.Id == this.service.formData.ParentShareDocInfo.TypeOfPostId){
        if((item.Name === "YouTube Link" )|| (item.Name === "links")){
          console.log("Yes");
          console.log(item.Name);
         console.log(item.Id);


         this.canFileUpload = false;
        }
        else{
          console.log("No");
           console.log(item.Name);
          console.log(item.Id);
          this.canFileUpload = true;
        }
      }

    });

  }


onItemSelect(item: any,type:string) {
  if(type == 'SchoolCategory' && this.service.formData.ParentShareDocInfo.SchoolCategoryIdList){

    var ids = item.Id;
    for(var i=0;i<this.service.formData.ParentShareDocInfo.SchoolCategoryIdList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.ParentShareDocInfo.SchoolCategoryIdList[i].Id;
    }
  }
}
onSelectAll(items: any,type:string) {
  if(type == 'SchoolCategory'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }

  }
}

largefileschange(event){
  console.log('event');
  console.log(event);
  debugger;

}
filesuploaded(fileInfo){
  if(fileInfo && fileInfo.IsSaved){
    console.log('fileInfo');
    console.log(fileInfo);
    debugger;
    this.service.formData.ParentShareDocInfo.DocumentId = fileInfo.UserDocumentId;
  }


}
// saveParentShareDocView(){
//   this.service.saveParentShareDocView().subscribe(res=>{
//     console.log(res);

//   });
// }
saveDocument(){
  if(this.list[0].id >0)
  {
    this.service.formData.IsSave = true;
  }
  this.service.saveParentShareDocView().subscribe((res:any)=>{
    if(res.IsSaved){
      this.commonServ.CallOnRefreshGrid();
      this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      if(this.list[0].id >0)
      {
        this.notificationServ.ShowInfo('Data Successfully Updated!');
      }
      else{
        this.notificationServ.ShowInfo('Successfully Saved!');
      }

    }else {
      this.notificationServ.ShowError(res.ErrorMessage);
    }

  })
}
}
