import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import interactiveClassess from "../schoolConst"
//import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import contactPageData from '../schoolConst';
import { CUSTOMCONST } from '../shared/Const';
import { Loader } from '@googlemaps/js-api-loader';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [CarouselModule, LottieModule, MatCardModule]
})
export class HomeComponent implements OnInit {
  CUSTOMCONST:any=CUSTOMCONST;
  contactPageData:any;
  roleList:any[];
  sourceList:any[];
  private map:google.maps.Map;

  
  course: string;
  interactiveclassess:any;
  bannerFirst: AnimationOptions = {
    path: '../../assets/images/Json/home/assessments_and_assignments_interactive_classess.json',
  };
  bannerSecond: AnimationOptions = {
    path: '../../assets/images/Json/home/banner_1.json',
  };
  bannerThird: AnimationOptions = {
    path: '../../assets/images/Json/home/banner_2.json',
  };
  Assessment_Content_Generation: AnimationOptions = {
    path: '../../assets/images/Json/home/assessments_and_assignments_interactive_classess.json',
  };
  Student_Portfolio : AnimationOptions = {
    path: '../../assets/images/Json/home/student_portfolio.json',
  };
  Planning_Management : AnimationOptions = {
    path: '../../assets/images/Json/home/planning_management.json',
  };
  highlights: AnimationOptions = {
    path: '../../assets/images/Json/home/highlights.json',
  }
  constructor(private actRoute: ActivatedRoute, @Inject(PLATFORM_ID) platformId: Object,
  private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) {
    if(this.actRoute.snapshot.params.id){
      this.course = this.actRoute.snapshot.params.id;
    }
    else{
      this.course = '';
    }
    this.isBrowser = isPlatformBrowser(platformId);
   }
   private isBrowser = false;
  

  ngOnInit(): void {
    
    this.loadDefaultMap("india");
    if(typeof window != 'undefined' && this.isBrowser)
    window.scroll(0,0);
    this.interactiveclassess = interactiveClassess.interactiveClassess.assets;
    this.titleService.setTitle('A NEW ERA OF EDUCATION BEGINS WITH HYBRID LEARNING PLATFORM');
    this.metaService.addTags([
      {name: 'keywords', content: 'Hybrid Learning, DYNAMIC LEARNING, Education, Technology, Communication, Society'},
      {name: 'description', content: 'Hybrid Learning Platform facilitates the new age educational processes by bringing the education, technology, communication, and society together on a single platform.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoplay:false,
    autoplayTimeout:8000,
    autoplayHoverPause:true,
    // navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  PartnerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true
  }

  ClientOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  bannerAnimationCreated(animationItem: AnimationItem): void {
  }

  loadDefaultMap(location:string){
    const loader = new Loader({
      apiKey: "AIzaSyDs8OlWeQJ0qVdw06_WKjGDfYymh3Xzyb8",
      version: "weekly",
      libraries: ["places"]
    });
    loader.load().then((google) => {
     
      const mapOptions = {
        center: {lat:39.45296, lng:-3.70491},
        zoom: 2.5,
        styles:this.CUSTOMCONST.MapStyle
      };
      this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
      if(location === "all"){
        const canada= new google.maps.Marker({
          position:contactPageData.contact.Location.canada,
          map:this.map
        });
        const india= new google.maps.Marker({
          position:contactPageData.contact.Location.india,
          map:this.map
        });
        const danmark= new google.maps.Marker({
          position:contactPageData.contact.Location.danmark,
          map:this.map
        });
        const usa = new google.maps.Marker({
          position:contactPageData.contact.Location.usa,
          map:this.map
        });
      } else if(location === "canada"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.canada;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const canada= new google.maps.Marker({
          position:contactPageData.contact.Location.canada,
          map:this.map
        });
        window.open('https://www.hybridlearning.net', "_blank");
      } else if(location === "india"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.india;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const india= new google.maps.Marker({
          position:contactPageData.contact.Location.india,
          map:this.map
        });
        // window.open('https://www.hybridlearning.in', "_blank");
      } else if(location === "danmark"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.danmark;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const danmark= new google.maps.Marker({
          position:contactPageData.contact.Location.danmark,
          map:this.map
        });
        window.open('https://www.hybridlearning.dk', "_blank");
      } else if(location === "usa"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.usa;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const usa = new google.maps.Marker({
          position:contactPageData.contact.Location.usa,
          map:this.map
        });
        window.open('https://www.hybridlearning.us', "_blank");
      }
      
  })
  .catch(e => {
    // do something
  });
  }
  
}
