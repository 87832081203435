<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-image w-100">
          <img class="img-fluid w-100" src="../../assets/images/about_page/about_banner.png" alt="faq-banner-image.png">
        </div>
        <div class="banner-content-left d-none d-sm-block">
          <h1 class="w-100 text-center">Knowledge is power.</h1> 
          <h1 class="w-100 text-center">Information is liberating.</h1>
          <h1 class="w-100 text-center">Education is the premise of progress.</h1>
          <h1 class="w-100 text-center">in every society, in every family.</h1>
        </div>
      </div>
</div>


  <section class="bg-theam-body">
    <div class="container-fluid" >
      <div class="bg-primary py-5" >
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="row ">
              <div class="col-12 mb-3 cart-investing">
               <p class="text-white">We are Educators, Researchers, Technologists, Product designers, Positive Parents and Community-builders working together to solve the complex challenges of learning worldwide for the Next Generation.</p>
               <p class="text-white">We build a unique platform to teach and learn to live quality life for Next Generation, Hybrid Learning Platform will help teachers and students, Parents and school administrators use anytime and everywhere.</p>
               <p class="text-white m-0">Hybrid Learning Platform is used by hundreds of teachers and students in classrooms worldwide. Our growing team is comprised of people who are passionate about empowering educators as they use technology to redefine and improve their classrooms.</p>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <section>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/about_page/Student_with_border.png" alt="Student" class="m-0 w-100">
                        </div>
                      </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                      <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">What students want?</h1>
                      </div>
                        <ul class="pl-0 custom-list-style1">
                            <li class="mb-2 right-angle1">Real World Learning Experiences - a chance to connect to the world beyond school.</li>
                            <li class="mb-2 right-angle2">Opportunities to learn about other cultures - a chance to broaden their perspective.</li>
                            <li class="mb-2 right-angle3">Have more opportunities to demonstrate learning creatively - beyond just paper and pencil tests/assessments.</li>
                            <li class="mb-2 right-angle4">Have more opportunities to use hands - on and tech tools outside of the classroom</li>
                            <!-- <li class="mb-2 right-angle5"><span class="font-weight-500">Anonymous Feedback:</span> Get regular feedback from Students and Parents regarding the teachers to better understand heir expectations.</li> -->
                        </ul>
                    </div>
                </div>

          <div class="row mt-5 align-items-center">

            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">How teachers can help?</h1>
              </div>
            <ul class="pl-0 custom-list-style1">
              <li class="mb-2 right-angle1">Teachers shouldn't make decisions without first asking students. </li>
              <li class="mb-2 right-angle2">Students really need the chance to share themselves in learning.</li>
              <li class="mb-2 right-angle3">It's really important to know the student feedback to think about ways to help.</li>
              <li class="mb-2 right-angle4">Students really want hands-on activities, they are bored of the chalk and talk.</li>
              <li class="mb-2 right-angle5">Teachers need to put on their students' shoes in order to make teaching plans.</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/about_page/Teacher_with_border.png" alt="Teacher" class="m-0 w-100">
              </div>
            </div>
          </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid theam-title-bg mt-5">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                          <h1 class="d-block title pt-1 pb-1 text-center w-70 m-auto">
                              
                              Our Core Values
                            </h1>
                            <p class="text-black">At Hybrid Learning Platform team have five core values guide how we work one another, with our board and advisors, and with our partners and collaborators. 
                              We constantly push ourselves to be our best, we focus on solutions, and we arrive every day inspired to make an impact through our talents, passion and hard work.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">

                    <div class="col-12 col-sm-12 mb-3">
                        <div>
                            <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Trust</h1>
                            </div>
                             <p>
                              As a company, we place a great deal of value on building trusted relationships with our customers and employees.
                            </p>
                        </div>
                        <div>
                            <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Customer Success</h1>
                            </div>
                            <p>The success of our company depends on the success of our customers, and we are dedicated to providing every customer with a positive human experience.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Healthy living</h1>
                            </div>
                            <p>We imagine bold possibilities, muster the courage to go after them, and consider system implications from the start.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Long–Term Plan</h1>
                            </div>
                            <p>We play the long-Term plan and prioritize what has the greatest long-term impact for children.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Love and Care</h1>
                            </div>
                            <p>Relationships and genuine connection are at the heart of our work.</p>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  </section>
 