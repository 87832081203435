import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router'
import { faPencil,faClose, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar ,NgbCalendarGregorian, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TakeAttendanceComponent } from '../attendance/take-attendance.component';
import { ConfirmComponent } from '../common/confirm.component';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { Appointment } from '../shared/appointment.model';
import { AppointmentService } from '../shared/appointment.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
import { EditCalAppointmentComponent } from './edit-cal-appointment.component';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FormsModule } from '@angular/forms';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'], providers: [
        { provide: NgbCalendar, useClass: NgbCalendarGregorian },
    ],
    standalone: true,
    imports: [NgIf, FormsModule, FullCalendarModule, NgStyle, FontAwesomeModule]
})
export class CalendarComponent implements OnInit {

  editIcon = faPencil;
  deleteIcon= faClose;
  calendarIcon= faCalendarCheck;
  public eventData1:any = {};
  public appdata:any={};
  datepickerConfig : BsDatepickerConfig;
  selectedGroup = "";
  daysToShow = 30;
  preheaderhour:number = 0;
  preheaderTime:number=0;
  // timeslotHeight = "";
  todayDate = new Date();
  selectedDate:string='';
  daysToShowList: any = [];
  fbTop1 = "0";
  fbTop2 = "0";
  fbTop3 = "640";
  fbHeight1 = "0";
  fbHeight2 = "640";
  fbHeight3 = "160";
  _dateService: any;
  _dataService: any;
  selectedChileId:number;
  childrenList:any=[];
  userData:any=[];
  urlSafe: SafeResourceUrl;
  role:string;
  classList:any=[];
  selectedClassId:number;
  timeslotHeight: number = 0;
  appViewInfo:Appointment = new Appointment();
  isTakeAttandance:boolean=false;
  public bsModalRef:BsModalRef;
  CUSTOMCONST:any=CUSTOMCONST;
  selectedStuId:number;
  appDate: NgbDate;
  eventData:any;



  
    config = {
      params: {
        studentId: 0,
        startDate :'',
        endDate :'',
        classId:0
      }
    };
    calenderData:any;
    AppointmentData:any={
      event:[]
    };
  constructor(dateService: DateService, dataService: DataService,public userService:UserService,public commonService:CommonService,public sanitizer: DomSanitizer,
    public router:Router,private modalService: BsModalService,public service: AppointmentService,
    private notificationServ: NotificationService,public calendar: NgbCalendar,) { 
    this.eventData1 = this.getjson();
    // this.timeslotHeight = (this.eventData1.options.timeslotHeight*(this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start)).toString()+' px';
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this._dateService = dateService;
    this._dataService = dataService;
    // this.role = commonService.role;
    this.service.formdata.AppointmentInfo.Date = this._dateService.getStringDate(new Date());
    
    this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
    this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;

    this.service.formdata.AppointmentInfo.ClassId = 1;
    this.commonService.RefreshCalender$.subscribe(
      item => {
        this.getAppointments();
       }
    );
    this.commonService.OnConfirm$.subscribe(
      item => {
        this.addAppointmentInfo();
          }
    );
    this.CUSTOMCONST = CUSTOMCONST;
    


  }

  ngOnInit(): void {
    // debugger;
    this.appDate = this.calendar.getToday();
    this.getMyInfo();
    this.eventData = this.getEventData();
    // this.calendarOnInit();
  }
  getAppointments(){
    this.selectedDate=this._dateService.getStringDate(this.service.formdata.AppointmentInfo.Date) ;
    this.service.getAppointments(this.selectedDate,this.selectedClassId).subscribe((res: any) => {
      
      let currentDateTime = new Date();
      let currentStringDateTime = "";
      res.AppointmentList.forEach((item) => {
        currentStringDateTime = this._dateService.getStringDate(currentDateTime);
        item.Color="#0da3ff",
        item.CanTakeAttandance= ((this._dateService.getDateTimeFromString(item.Date, item.StartTime) <= currentDateTime) && (currentStringDateTime === item.Date))
        
      });
      
      this.eventData1.events = res.AppointmentList;
      this.timeslotHeight = (this.eventData1.options.timeslotHeight * (this.eventData1.options.timeslotsPerHour * (this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start)));
      // this.getUserDayWorkTime();

      this.calculateEventsPosition();
      
    });
    
  }
  getStudentAppointments(config){
    
    this.service.getassignedassignmentsbyDateRange(config).subscribe((res: any) => {
       this.calenderData = res;
       
      let currentDateTime = new Date();
      let currentStringDateTime = "";
      res.AppointmentList.forEach((item) => {
        currentStringDateTime = this._dateService.getStringDate(currentDateTime);
        if(item.AttendaceType == 'Absent'){
          item.Color = "#e90404";
          item.borderColor='#e90404';
        }else if(item.AttendaceType == 'Late'){
          item.Color = "#ffbc4d";
          item.borderColor='#ffbc4d';
        }else if(item.AttendaceType == 'Present'){
          item.Color = "#009135";
          item.borderColor='#009135';
        }else{
        item.Color=((this._dateService.getDateTimeFromString(item.Date, item.EndTime) >= currentDateTime) && (currentStringDateTime === item.Date)?"#0da3ff":"#ff7a3d")

        }
        item.date = item.Date.split('-').reverse().join('-');
        item.title = item.Text
        item.textColor='white';
        
      });
      this.eventData1.events = res.AppointmentList;
      this.timeslotHeight = (this.eventData1.options.timeslotHeight * (this.eventData1.options.timeslotsPerHour * (this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start)));

      this.calculateEventsPosition();
      this.calendarOnInit();
    });
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.role = res.UserInfo.Role;
      
      if(this.role == "Student"){
       
        this.selectedClassId = res.StudentInfo.ClassId;
        this.selectedStuId = res.UserInfo.UserId;
        // this.getAppointments();

        

    let date = new Date();
    this.selectedDate=this._dateService.getStringDate(this.service.formdata.AppointmentInfo.Date) ;
    let StartData = this._dateService.getStartDateOfMonth(date);
    let EndData = this._dateService.getEndDateOfMonth(date);
    this.config = {
      params: {
        studentId: this.selectedStuId,
        startDate :StartData,
        endDate :EndData,
        classId:this.selectedClassId
      }
    };
    this.getStudentAppointments(this.config);
        

      }else if(this.role == "Parent"){
        if(this.commonService.selectedChileId<=0)
        {
          this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
          this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
          this.selectedStuId = this.selectedChileId;
        }
        this.childrenList =  res.ParentInfo.ChildrenList;
        this.getuser();

        

      }else if(this.role == 'Cordinator' || this.role == 'Admin' || this.role == 'Teacher'){
        this.commonService.getclasses().subscribe((res:any)=>{
          this.classList = res;
          
        });
        
        this.selectedClassId = this.service.formdata.AppointmentInfo.ClassId = 1;
        this.getAppointments();
      }
      
      
    });
  }
  getuser(){
    var userid = this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.selectedStuId = userid;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData =  res; 
      
      this.selectedClassId = res.StudentInfo.ClassId;
        // this.getAppointments();
        this.getStudentAppointments(this.config);
      
this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath)
    });
  }
  selectChild(id:any){
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    if(id>0){
      // this.getAllStudentsQuestionsById();
    this.getuser();
    }else{
      this.router.navigateByUrl('/add-child');
    }
   
  }
  
  calculateEventsPosition() {
    //sorting.
    var currentObj = this;
    this.eventData1.events = this.eventData1.events.sort((a: any, b: any) => (currentObj._dateService.getDateTimeFromString(a.date, a.StartTime) < currentObj._dateService.getDateTimeFromString(b.date, b.StartTime)) ? 1 : 0);
    var paddingStart = this.eventData1.options.businessHours.limitDisplay ? this.eventData1.options.businessHours.start : 0;
    var millisToDisplay = (this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start) * 3600000;
    var pxPerMillis = this.timeslotHeight / millisToDisplay;
    this.eventData1.events.forEach((element: any) => {
      var d = currentObj._dateService.getDateTimeFromString(element.Date, element.StartTime);
      var nbHours = d.getHours() - paddingStart + d.getMinutes() / 60;
      element.position = (nbHours * currentObj.eventData1.options.timeslotHeight * currentObj.eventData1.options.timeslotsPerHour) + 'px';
      var eventMillis = this.getDSTdayShift(element.Date, element.EndTime).getTime() - this.getDSTdayShift(element.Date, element.StartTime).getTime();
      element.pxHeight = pxPerMillis * eventMillis;
      element.sDate = currentObj._dateService.getDateTimeFromString(element.Date, element.StartTime);
      element.eDate = currentObj._dateService.getDateTimeFromString(element.Date, element.EndTime);
    });
    
    this.daysToShowList = [];
    for (var index = 0; index < this.daysToShow; index++) {
      this.daysToShowList.push({ isToday: this.IsDayToday(index) });
    }
  }
  getDSTdayShift(dt: any, time: string) {
    var date = this._dateService.getDateTimeFromString(dt, time);
    var start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
    var offset1 = start.getTimezoneOffset();
    var offset2 = date.getTimezoneOffset();
    if (offset1 == offset2)
      return date;
    var shift = 1;
    return new Date(date.getTime() - shift * (offset1 > offset2 ? -1 : 1) * (Math.max(offset1, offset2) - Math.min(offset1, offset2)) * 60000);
  }
  IsDayToday(day: number) {
    var date: any = this._dateService.getDateFromStringDate(this.selectedDate);
    date.setDate(date.getDate() + day); //adding no of days.
    var strDate = this._dateService.getStringDate(date);
    return strDate == this._dateService.getStringDate(new Date()) ? true : false;
  }
  //cdkDragConstrainPosition -- need to check how to find the position on drop and calculate the corresponding timing.
  dragEnded(event: any, appInfo: any) {
    var startOffsetMillis = this.eventData1.options.businessHours.limitDisplay ? this.eventData1.options.businessHours.start * 3600000 : 0;
    var timeslotsPerDay = this.eventData1.options.timeslotsPerHour * (this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start);
    var millisToDisplay = (this.eventData1.options.businessHours.end - this.eventData1.options.businessHours.start) * 3600000;
    var d = new Date(this._dateService.getDateFromStringDate(appInfo.date).getTime() + startOffsetMillis + Math.round((event.source.getFreeDragPosition().y + parseInt(appInfo.position.replace('px', ''))) / this.eventData1.options.timeslotHeight) * (millisToDisplay / timeslotsPerDay));
    var nbHours = d.getHours() - (this.eventData1.options.businessHours.limitDisplay ? this.eventData1.options.businessHours.start : 0) + d.getMinutes() / 60;
    
    this.eventData1.isdataloading = true;
    appInfo.position = (nbHours * this.eventData1.options.timeslotHeight * this.eventData1.options.timeslotsPerHour) + 'px';
    setTimeout(() => {
      this.eventData1.isdataloading = false;
    }, 100);

  }
  
  selectedDateChage(type){
    if (type == 'prev') {
      const prevDate = this.calendar.getPrev(this.appDate, 'd', 1);
      // this.datepicker.navigateTo(prevDate);
      this.appDate = prevDate;

      let date = this._dateService.getStringDate(new Date(this.appDate.year, this.appDate.month - 1, this.appDate.day))
      this.service.formdata.AppointmentInfo.Date = this._dateService.getDateFromStringDate(date);
    }
    else if (type == 'next') {
      const prevDate = this.calendar.getNext(this.appDate, 'd', 1);
      // this.datepicker.navigateTo(prevDate);
      this.appDate = prevDate;
      let date = this._dateService.getStringDate(new Date(this.appDate.year, this.appDate.month - 1, this.appDate.day))
      this.service.formdata.AppointmentInfo.Date = this._dateService.getDateFromStringDate(date);
    }
    if(this.role == "Student" || this.role == "Parent"){
      this.getStudentAppointments(this.config);
    } else{
      this.getAppointments();
    }
    
  }
  selectedPrevDate(){
    this.selectedDate=this.service.formdata.AppointmentInfo.Date;
    this.getAppointments();
  }
  selectedNextDate(){
    this.selectedDate=this.service.formdata.AppointmentInfo.Date;
    this.getAppointments();
  }
  selectedClassChange(){
    this.selectedClassId = this.service.formdata.AppointmentInfo.ClassId;
    this.getAppointments();
  }
  userChage(user:any){

  }
  groupChange(){

  }
  counter(i: number) {
    return new Array(i);
}
counterMinMax(min: number,max:number) {
  return new Array(max-min);
}
gethour(hour: number,index:number){
  return hour+index;
}
getheaderTime(hour: number,index:number,min:number){
  hour = this.gethour(hour,index);
  if(this.preheaderhour == hour){
    this.preheaderTime = this.preheaderTime+(60 / this.eventData1.options.timeslotsPerHour);
  }
  else{
    this.preheaderTime = 0;
  }
  this.preheaderhour = hour;
  if (this.preheaderTime < 10 && !this.preheaderTime.toString().startsWith("0")) {
    return "0" + this.preheaderTime;
}
else{
  return this.preheaderTime;
}
}
getjson(){
  return {
  "options": {
    "timeslotsPerHour": 4,
    "timefslotHeight": 20,
    "defaultFreeBusy": {
      "Key": "free",
      "Value": true
    },
    "businessHours": {
      "start": 6,
      "end": 18,
      "limitDisplay": true
    }
  },
  "events": [
    {
      
      "AppointmentId": 5947003,
      "StartTime": "09:45",
      "EndTime": "10:00",
      "Title": "fodbad",
      "Type": "Appointment",
      "Date": "2023-10-22",
      "text": "Bhavana Annem",
      
      "Description": "Test Appointment",
      "Color": "#9f00ff",
      "CanTakeAttandance":false
      
    }
  ],
  
};
}
viewChage(view:string){

}
addEvent(eventId:number){
  // debugger;
  this.openEventModal(eventId);
}
deleteEvent(eventId:number){
this.getAppointmentViewInfo(eventId)
}
getAppointmentViewInfo(id:number){
  this.service.getAppointmentViewInfo(id).subscribe((res: any) => {
    this.service.formdata = res;
    this.deleteEventData();
  });
}

deleteEventData(){

  const initialState = {
    list: [
      {
        id:'delete',
        message:"Are you sure want to delete?"
      },
    ]      
  };
  this.bsModalRef = this.modalService.show(ConfirmComponent,{id : CUSTOMCONST.CONFIRM_MODAL, initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});

  
  
}
addAppointmentInfo() {
  this.service.formdata.AppointmentInfo.IsActive = false;
  this.service.saveAppointmentView().subscribe((res: any) => {
    if (res.IsSaved) {
      this.notificationServ.ShowInfo('Successfully Delete!');
      this.commonService.CallOnRefreshCalender();
      this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
    } else {
      this.notificationServ.ShowError(res.ErrorMessage);
    }
  });
}
openEventModal(eventId){
  const initialState = {
    list: [
      {id:eventId}
    ]
  };
  this.bsModalRef = this.modalService.show(EditCalAppointmentComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
}
takeAttandance(appointmentId:number){
    const initialState = {
      list: [
        {
          AppointmentId:appointmentId,
          ClassId:this.selectedClassId,
          Date:this.selectedDate
        }
      ]
    };
    this.bsModalRef = this.modalService.show(TakeAttendanceComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }

  // calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth',
  //   plugins: [dayGridPlugin],
  //   dayMaxEvents: true,
  //   // eventContent: { 
  //   //   html: `
  //   //   <h3 class="m-0">Title</h3>
  //   //   <p class="m-0">discription</p>` 
  //   // },
  //   events: [
  //     { 
  //       title:"title1", date: '2023-08-22' ,
  //       views: {
  //         timeGridFourDay: {
  //           type: 'timeGrid',
  //           duration: { days: 4 },
  //           buttonText: '4 day'
  //         }
  //       }
  //     },
  //     { title: `<span>event 2</span>`, date: '2023-08-22',textColor:'white',color:'red',className:'', },
      
  //     { title: 'event 2', date: '2023-08-22',textColor:'white',color:'red',className:'abc', },
  //     { title: '', date: '2023-08-22',textColor:'white',color:'red',className:'abc', }
  //   ],
  //   // headerToolbar: {
  //   //   center: 'dayGridMonth,timeGridFourDay' // buttons for switching between views
  //   // },
  //   // views: {
  //   //   timeGridFourDay: {
  //   //     type: 'timeGrid',
  //   //     duration: { days: 4 },
  //   //     buttonText: '4 day'
  //   //   }
  //   // }

  // };

  getEventData(){
    return {
    
    "events": [
      { title: `event 1`, date: '2023-10-22',textColor:'white',color:'#40CA00',className:'', },
      { title: 'Event 6', date: '2023-10-23',textColor:'white',color:'#24958B',className:'abc', },
      {
        title: 'BCH237',
        date: '2023-10-22',textColor:'white',color:'#243395',className:'abc', 
        
        extendedProps: {
          department: 'BioChemistry',
          description: 'Lecture',
        },
        
      },
      

    ],
    
  };
  }
  onDateClick(res: any) {
    alert('Clicked on date : ' + res.dateStr);
  }

  calendarOnInit(){
    // debugger;
    setTimeout(() => {
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin],
        dayMaxEvents: true,
        events: {events: this.eventData1.events},
        // events:this.getEventData(),
        dateClick: this.onDateClick.bind(this),
        
      };
    }, 2500);
  }
  
  Events: any[] = [];
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    // headerToolbar: {
    //   left: 'prev,next today',
    //   center: 'title',
    //   right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    // },
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    
  };
  changedaysToShow(){
    console.log(this.daysToShow);
    let date = new Date();
    let StartDate='' 
    let EndDate = '';
// debugger;
    if(this.daysToShow == 1){
      StartDate = this._dateService.getStringDate(date);
      EndDate = this._dateService.getStringDate(date);
    }else if(this.daysToShow == 7){
      StartDate = this._dateService.getFirstDayOfWeek(date)
      EndDate = this._dateService.getLastDayOfWeek(date);
    }else if(this.daysToShow == 30){
      StartDate = this._dateService.getFirstDayOfMonth(date)
      EndDate = this._dateService.getFirstDayOfMonth(date);
    }
    
    this.config.params.startDate = StartDate;
    this.config.params.endDate = EndDate;
    this.getStudentAppointments(this.config);
  }

}
