import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { CommonService } from '../shared/common.service';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { EditBadgeComponent } from './edit-badge.component';
import { EventService } from 'src/app/shared/event.service';
import { formatDate, NgFor } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-badge-list',
    templateUrl: './badge-list.component.html',
    styleUrls: ['./badge-list.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, MatTabsModule, AgGridModule]
})
export class BadgeListComponent implements OnInit {
  classList: any;
  selectedClassId: number=1;
  selectedUserType: string = "Student";
  activeTab: string;
  eventsRowData: any;


  bsModalRef: BsModalRef;
  frameworkComponents;
  badgeRowData: any;
  badgeRowSelection;
  badgeDefaultColDef;
  badgeColumnDefs;
  badgeGridOptions: any;
  gridApi;
  gridColumnApi;

  

  constructor(
    private userService: UserService,
    public commonServ: CommonService,
    public modalService: BsModalService,
    public eventService: EventService,
  ) {
    this.commonServ.RefreshGrid$.subscribe(
      item => {
        this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);
      }
    );
  }

  ngOnInit(): void {
    this.getclasses();
    this.gridOnInit();
  }
  gridOnInit() {

    this.badgeColumnDefs = [
      {
        headerName: "badgeId",
        field: 'BadgeDataId', cellRendererFramework: CustommenuComponent,
        cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.editbadge, that: this }, { Name: "Delete", "Click": this.deletebadge, that: this }] },

        filter: false, width: 60, maxWidth: 60,
        headerComponent: 'customHeader',
        headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      { field: 'UserName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'Comments', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'IssueDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'ExpiryDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120 },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.badgeDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }

  getclasses() {
    this.commonServ.getclasses().subscribe((res: any) => {
      this.classList = res;
      this.selectedClassId = res[0].Id;

      this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);

    });
  }

  getuserBadgeInfoListByClassId(classId, userType) {
    this.userService.getuserBadgeInfoListByClassId(classId, userType).subscribe((res: any) => {
      const rowData =  res.BadgeListInfo;
      this.gridApi.setRowData(rowData);
    })

  }


  onbadgeReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);
    }, 300);


  }
  // getbadges() {
  //   this.service.getbadgesByParam(this.config).subscribe((res: any) => {
  //     this.badgesRowData = res;
  //   });
  // }
  onbadgeReload(data) {
    this.badgeRowData = data;
  }
  editbadge(badgeDataId, that) {
   
    
    let badgedata = that.badgeRowData.filter(item => item.BadgeDataId == badgeDataId);
    console.log("badgedata");
    console.log(badgedata[0].UserId);
    
    
    that.openbadgeModal(badgeDataId,'Edit',badgedata[0].UserId);

  }
  openbadgeModal(Id:number,Action:string,userId:number){
    const initialState = {
      list: [
        { 
          id: Id,
          action:Action,
          userId:userId
        }
      ]

    };
    this.bsModalRef = this.modalService.show(EditBadgeComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-lg col-12', ignoreBackdropClick: true });

  }
  deletebadge(badgeDataId, that) {
    
    var item = that.badgeRowData.filter(item => item.BadgeDataId == badgeDataId);
    that.userService.badgeFormData.BadgeInfo = item[0];

    that.userService.badgeFormData.BadgeInfo.IssueDate = formatDate(new Date(that.userService.badgeFormData.BadgeInfo.IssueDate), 'dd-MM-yyyy', 'en');
    if(that.userService.badgeFormData.BadgeInfo.ExpiryDate == '' || that.userService.badgeFormData.BadgeInfo.ExpiryDate ==null){
      // resData.BadgeInfo.ExpiryDate = new Date(resData.BadgeInfo.ExpiryDate)
    }else{
      that.userService.badgeFormData.BadgeInfo.ExpiryDate = formatDate(new Date(that.userService.badgeFormData.BadgeInfo.ExpiryDate), 'dd-MM-yyyy', 'en');
    }

    
    that.userService.badgeFormData.BadgeInfo.IsActive = false;
    that.userService.saveUserBadgeViewInfo().subscribe((res: any) => {
        if (res.IsSaved) {
          that.notificationServ.ShowInfo('Successfully delete!');

          this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);
        } else {
          that.notificationServ.ShowError(res.ErrorMessage);
        }
      });
  }
  serchbadges(){
    this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);
  }
  changeClass(){}

  activeTabChange(event) {
    this.selectedUserType = event.tab.textLabel;
    console.log(this.selectedUserType);
    
    this.getuserBadgeInfoListByClassId(this.selectedClassId, this.selectedUserType);
  }
}
