import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { SchoolService } from '../shared/school.service';
import { UserService } from '../shared/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-general-details',
    templateUrl: './general-details.component.html',
    styleUrls: ['./general-details.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, TranslateModule]
})
export class GeneralDetailsComponent implements OnInit {
  gridApi;
  gridColumnApi;
  isEdit:boolean=false;
  schoolid:number;
  public bsModalRef2: BsModalRef;

  public event: EventEmitter<any> = new EventEmitter();
  constructor(public userService:UserService,public service:SchoolService,
     public modalService:BsModalService, public notificationSrv: NotificationService,private commonService:CommonService) { }
  
  schoollist : any = [];
  list: any[] = [];
  ngOnInit(): void {
    this.getUserInfo();
    
  }
  getUserInfo(){
    this.userService.getMyInfo().subscribe((res:any)=>{
      this.schoolid = res.UserInfo.SchoolId;
      this.getSchool();
    })
  }
  getSchool(){
    this.userService.getschool(this.schoolid).subscribe((res:any)=>{
      console.log(res);
      this.service.formData = res;
    })
  }
  
  generateGuid(){
    this.userService.getnewguid().subscribe((res:any)=> {
      this.service.formData.Id = res;
    });
  }
  edittoggle(){
    this.isEdit = this.isEdit==true?false:true;
  }
  updateSchoolInfo(){
    this.userService.updateschool(this.service.formData).subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.notificationSrv.ShowInfo('School saved successfully');
        this.service.formData = res;
        this.isEdit = false;
      }
      else{
        this.notificationSrv.ShowError(res.ErrorMessage);
      }
      
    })
  }
}
