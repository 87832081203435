<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-image w-100">
            <img class="img-fluid w-100" src="../../assets/images/2X/partner-banner-image1.png" alt="faq-banner-image.png">
        </div>
        <div class="banner-content-left d-none d-sm-block">
          <h4 class="w-100 text-center">Partner with Hybrid Learning Platform and Bring Your curriculum to students Life.</h4>
          <p>The publishing industry is under disruption. Hybrid Learning Platform offers publishing partners the ability to create modern learning experiences using our interactive platform.</p>
        </div>
      </div>
</div>

  <section class="bg-theam-body">
    <div class="container-fluid" >
        <div class="theam-title-bg">
            <div class="row justify-content-center">
                <div class="col-10">
                    <div class="row my-4 align-items-center ">
                        <div class="col-12">
                            <div class="section-header">
                              <h1 class="d-block title pt-1 pb-1 text-center w-70 m-auto">
                                  By partnering with Hybrid Learning Platform, School Boards, Publishers, Schools and Content Providers can:
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </section>
  <section >

    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-4 align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/MAKE THE TEACHING EXPERIENCE INTERACTIVE.jpg" alt="value_education" class="m-0 w-100">
                        </div>
                      </div>
                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3">
                      <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">MAKE THE TEACHING EXPERIENCE INTERACTIVE</h1>
                      </div>
                      <p>Easily embed interactive elements and assessments throughout your content so teachers can measure students’ comprehension
                    </p>
                        <!-- <ul class="pl-0 custom-list-style1">
                            <li class="mb-2 right-angle1">Real World Learning Experiences- a chance to connect to the world beyond school.</li>
                            <li class="mb-2 right-angle2">Opportunities to learn about other cultures- a chance to broaden his perspective.</li>
                            <li class="mb-2 right-angle3">Have more opportunities to demonstrate learning creatively- beyond just paper and pencil tests/assessments.</li>
                            <li class="mb-2 right-angle4">Have more opportunities to use hands-on and tech tools outside of the classroom</li>
                        </ul> -->
                    </div>
                </div>

                <div class="row mt-0 align-items-center">

                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3 order-2 order-sms-1">
                    <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">DELIVER A RICH LEARNING EXPERIENCE</h1>
                    </div>
                    <p>
                        Create comprehensive, full-course solutions with teaching notes, interactive Watch, Listen, Read, Write and Do  assignments that teachers and students can use in-and-out of the classrooms.
                    </p>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4 mb-3 order-1 order-sm-2">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-598.png" alt="value_education" class="m-0 w-100">
                    </div>
                    </div>
                </div>

                <div class="row mt-4 align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/ENJOY REAL-TIME REVISIONS.jpg" alt="value_education" class="m-0 w-100">
                        </div>
                      </div>
                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3">
                      <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">ENJOY REAL-TIME REVISIONS</h1>
                      </div>
                      <p>
                          Make updates to your content in real-time based on feedback from teachers and students.
                    </p>

                    </div>
                </div>

                <div class="row mt-0 align-items-center">

                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3 order-2 order-sms-1">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1">When you succeed, we succeed.</h1>
                        </div>
                        <p>
                            Today’s ever-changing labour markets are continuously creating demand for new skills. As business strategies change, individual skillsets must adapt and grow in order to impact the business. Our mission is to help every enterprise meet the challenges of this new reality by enabling the next generation of the world’s expertise. Hybrid Learning Platform global channel partner and alliance programs are an integral part of our mission and a core avenue to all segments of the Private and public sectors.
                        </p>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4 mb-3 order-1 order-sm-2">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/WHEN YOU SUCCEED, WE SUCCEED..jpg" alt="value_education" class="m-0 w-100">
                        </div>
                    </div>
                </div>


                <div class="row mt-4 align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/WHO ARE HYBRID LEARNING PLATFORM PARTNERS.jpg" alt="value_education" class="m-0 w-100">
                        </div>
                      </div>
                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3">
                      <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">Who Are Hybrid Learning Platform Partners?</h1>
                      </div>
                      <p>
                        Hybrid Learning Platform Partners are Curriculum Associates staff who are dedicated to serving educational institutions. Hybrid Learning Platform Partners equip educators to build data-driven classrooms and connect actionable insights with rigorous instruction. The result? Students who are engaged and invested in their learning. Hybrid Learning Platform Partners stems from our core value that the quality of our service is as important as the quality of our products.
                    </p>

                    </div>
                </div>

                <div class="row mt-0 align-items-center">

                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3 order-2 order-sms-1">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1">Partnership Opportunities</h1>
                        </div>
                        <p>
                            There are several ways to partner with Hybrid Learning Platform. The following are some examples of how we can work together.
                        </p>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4 mb-3 order-1 order-sm-2">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/PARTNERSHIP OPPORTUNITIES.jpg" alt="value_education" class="m-0 w-100">
                        </div>
                    </div>
                </div>


                <div class="row mt-4 align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/CONTENT (ACADEMIC, SEL, LIFE SKILLS, OUTSIDE THE BOX).jpg" alt="value_education" class="m-0 w-100">
                        </div>
                      </div>
                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3">
                      <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">Content (Academic, SEL, Life Skills, Outside the Box)</h1>
                      </div>
                      <p>
                        Would you like to have your content discoverable on Hybrid Learning Platform for our mutual schools?  The first step is to encourage your client contact to reach out to their Hybrid Learning Platform.  All current integrations are happening at the request of our clients versus the providers at this time.
                    </p>

                    </div>
                </div>

                <div class="row mt-0 align-items-center">

                    <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-8 mb-3 order-2 order-sms-1">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1">Strategic Partnership</h1>
                        </div>
                        <p>Hybrid Learning Platform is looking to work with strategic partners who are consulting organizations on their tech needs, integrated services around Hybrid Learning Platform and more.</p>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-4 mb-3 order-1 order-sm-2">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/images/partners_page/STRATEGIC PARTNERSHIP.jpg" alt="value_education" class="m-0 w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="contener theam-title-bg mt-5">
        <div class="row justify-content-center m-0">
            <div class="col-10">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                          <h1 class="d-block title pt-1 pb-1 text-center w-70 m-auto">Technology</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">

                    <div class="col-12 col-sm-12 mb-3">
                        <div>
                            <!-- <div class="section-header">
                                <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Concern for Next Generation</h1>
                            </div> -->
                            <p>Hybrid Learning Platform is an open ecosystem connecting multiple platforms for streamlined technology solutions. We are currently integrating with technologies our clients are requesting.  Have a client that is using Hybrid Learning?  Please work with your clients contact to get integration discussions going.</p>
                            <p>Hybrid Learning Platform global channel partner and alliance programs are an integral part of our mission and a core avenue to all segments of the private and public sectors. We seek partnerships with like-minded organizations to join us on this journey. Partnering with us will impact your revenue growth and strategically differentiate your business while helping to equip and enable the new world of career-long learning and skill development.
                            </p>
                            <p>Hybrid Learning Platform offers unparalleled support to ensure your partnership with us is a success. With dedicated Business Development, Channel Solutions, Engineering, and Consulting teams, we work with you to understand your company's goals, business objectives, and available resources to support our partnership. </p>
                            <blockquote class="blockquote">
                                <p>“Our partnership with Hybrid Learning Platform has enabled us to make this connection with our clients, enabling career growth and skills development. The innovative online learning solution has differentiated how we engage with our clients, empowering them to achieve better outcomes through developing a lifelong relationship with education and learning.”
                                </p>
                              </blockquote>
                            <p>All our programs come with the same award-winning service.</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
  </section>

  <section>
    <div class="container-fluid mt-5 pb-5">
        <div class="row justify-content-center">
           <div class="col-10">
               <div class="row align-items-center">
                <div class="order-2 order-md-1 col-xs-12 col-md-5">
                    <h1 class="heading-text">To get started please fill out the application with detailed information on how you want to partner with us.</h1>
                </div>
                <div class="order-1 order-md-2 col-xs-12 col-md-7">
                    <h1 class="heading-text">Partner with us</h1>
                    <form novalidate #form="ngForm" (submit)="saveParterinfo(form)">
                        <div class="form-row mr-1">
                            <div class="form-group full-group m-0">
                                <select name="PartnerFor" class="form-control form-control-sm appearance-auto" #PartnerFor="ngModel"
                                [(ngModel)]="service.partenrFormData.PartnerFor" required class.invalid="PartnerFor.invalid && PartnerFor.touched">
                                    <option value="">Select</option>
                                    <option value="Academics">SCHOOL BASED LEARNING</option>
                                    <option value="SEL">HOME BASED LEARNING</option>
                                    <option value="Life Skills">COMMUNITY BASED LEARNING</option>
                                    <option value="Outside The Box">SOCIETY BASED LEARNING</option>
                                    <option value="Others">Others</option>
                                </select>

                           </div>
                        </div>

                        <div class="form-row mr-1">

                            <div class="form-group half-group mb-0  mr-5px">
                                <label>{{'FirstName'| translate}}</label>
                                <input class="form-control form-control-sm" placeholder="{{'FirstName'| translate}}" name="FirstName" #FirstName="ngModel"
                                [(ngModel)]="service.partenrFormData.FirstName" required class.invalid="FirstName.invalid && FirstName.touched" maxlength="100">
                            </div>
                            <div class="form-group half-group mb-0 ml-5px">
                                <label>{{'LastName'| translate}}</label>
                                <input class="form-control form-control-sm" placeholder="{{'LastName'| translate}}" name="LastName"  #LastName="ngModel" maxlength="100"
                                [(ngModel)]="service.partenrFormData.LastName">
                            </div>
                            <div class="form-group half-group mb-0 mr-5px">
                                <label>{{'Email'| translate}}</label>
                                <input class="form-control form-control-sm" type="email" placeholder="{{'Email'| translate}}" name="Email" #Email="ngModel"
                                [(ngModel)]="service.partenrFormData.Email" required maxlength="50" class.invalid="Email.invalid && Email.touched">
                            </div>
                            <div class="form-group half-group mb-0 ml-5px">
                                <label>{{'Mobile'| translate}}</label>
                                <input class="form-control form-control-sm"  type="number" placeholder="{{'Mobile'| translate}}" name="Mobile" #Mobile="ngModel"
                                [(ngModel)]="service.partenrFormData.Mobile" required maxlength="10" class.invalid="Mobile.invalid && Mobile.touched">
                            </div>
                            <div class="form-group full-group mb-0">
                                <label>{{'Description'| translate}}</label>
                                <textarea class="form-control form-control-sm" placeholder="{{'Description'| translate}}" name="Description" #Description="ngModel"
                                [(ngModel)]="service.partenrFormData.Description" required  class.invalid="Description.invalid && Description.touched" cols="10" rows="2"></textarea>
                            </div>
                        </div>
                        <div class="form-row mt-3 mr-1 justify-content-center">
                            <div class="mb-0">
                                <button class="btn btn-primary custom btn-sm bd-dadios-0" type="submit" [disabled]="form.invalid">{{'Send'| translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>

               </div>
               <!-- <div class="row">
                <div class="col-12 col-sm-12 mb-3">
                    <div>
                        <div class="section-header">
                            <h1 class="title pt-1 pb-0 mb-0 text-transform-default">Interested in collaborating?</h1>
                        </div>
                        <p>We are always open to new partnerships with educational organisations, services and content providers.</p>

                    </div>

                </div>
               </div> -->
           </div>
        </div>
    </div>
</section>
<section class="bg-primary ">
    <div class="container-fluid" >
      <div class="py-5" >
        <div class="row justify-content-center">
            <!-- <div class="col-12 col-md-8 align-self-center mb-3">
                <div class="header ">
                  <h1 class="d-block text-center text-white pt-5 pb-5 title">OUR FOUR PILLARS FOR ALL ROUND DEVELOPMENT</h1>
                </div>
              </div> -->
          <div class="col-10">
            <div class="row ">
              <div class="col-12 cart-investing">
                <div class="header ">
                    <h1 class="d-block text-center text-white title">Interested in collaborating?</h1>
                  </div>
               <p class="text-white text-center m-0">We are always open to new partnerships with educational organisations, services and content providers.</p>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
