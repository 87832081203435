import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../shared/notification.service';
import { CUSTOMCONST } from '../shared/Const';
import { OwnFileService } from '../shared/ownFile.service';
import { OwnFileData } from '../shared/own-file.model';
import { SchoolSharedDocService } from '../shared/school-shared-doc.service';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import {
  // Ng7LargeFilesUploadLibModule,
  Ng7LargeFilesUploadLibComponent
} from 'ng7-large-files-upload-lib';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-assdoc',
    templateUrl: './edit-assdoc.component.html',
    styleUrls: ['./edit-assdoc.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgFor, NgMultiSelectDropDownModule, NgIf, Ng7LargeFilesUploadLibModule, TranslateModule]
    imports: [FormsModule, NgFor, NgIf, TranslateModule]

})
export class EditASSDocComponent implements OnInit {
  list:any[]=[];
  url:any;
  documentId:any;
  CUSTOMCONST:any=CUSTOMCONST;
  ChallengeInfo:any=[];
  SchoolCategoryIdList:number;
  stateIdList: any;
  stateList:any=[];
  schoolCatList:any;
  purofpostList:any;
  typeOfPostList:any;
  challengeList:any;
  canFileUpload:boolean=true;
  dropdownSettings:IDropdownSettings;
  constructor(public service:SchoolSharedDocService,private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService, public bsModalRef: BsModalRef) {
      // this.service.formData.FileId = 0;
    }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.getAdminShareDocView();
  }
  // selectDocumentFile(event){

  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(file);

  //       reader.onload = (event) => {
  //         this.url = (<FileReader>event.target).result;
  //        if(!this.service.formData.OwnFileInfo)


  //        this.service.formData.OwnFileInfo = new OwnFileData();
  //         this.service.formData.FileName = file.name;

  //         this.service.formData.FileSize = file.size;
  //         this.service.formData.FileType = file.type;
  //         this.service.formData.IsOwnDocument = true;
  //         this.service.formData.OriginalContentString = this.url;

  //         this.service.formData.Url = this.url;
  //         this.service.formData.OwnFileInfo.OriginalContentString = this.url;
  //         // debugger;
  //       }
  //   }
  // }
  largefileschange(event){
    console.log('event');
    console.log(event);
    debugger;

  }
  filesuploaded(fileInfo){
    if(fileInfo && fileInfo.IsSaved){
      console.log('fileInfo');
      console.log(fileInfo);
      debugger;
      this.service.formData.AdminShareDocInfo.DocumentId = fileInfo.UserDocumentId;
    }


  }
  getAdminShareDocView(){
    this.service.getAdminShareDocView(this.list[0].id).subscribe((res:any)=>{
      this.service.formData = res;
      this.stateIdList = res.StateInfo;
      this.schoolCatList=res.SchoolCategoryInfo;
      this.purofpostList=res.PurposeOfPostInfo;
      this.typeOfPostList=res.TypeOfPostInfo;
      this.challengeList=res.ChallengeInfo;
      console.log("this.stateIdList");
      console.log(this.stateIdList);
    })
  }


  saveDocument(){
    if(this.list[0].id >0)
    {
      this.service.formData.IsSave = true;
    }
    this.service.saveAdminShareDocView().subscribe((res:any)=>{
      if(res.IsSaved){
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        if(this.list[0].id >0)
        {
          this.notificationServ.ShowInfo('Data Successfully Updated!');
        }
        else{
          this.notificationServ.ShowInfo('Successfully Saved!');
        }

      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }

    })
  }
fileTypeChange(){
  this.service.formData.AdminShareDocInfo.TypeOfPostId;

  this.typeOfPostList.find(item=>{
    if(item.Id == this.service.formData.AdminShareDocInfo.TypeOfPostId){
      if((item.Name === "YouTube Link" )|| (item.Name === "links")){
        console.log("Yes");
        console.log(item.Name);
       console.log(item.Id);


       this.canFileUpload = false;
      }
      else{
        console.log("No");
         console.log(item.Name);
        console.log(item.Id);
        this.canFileUpload = true;
      }
    }

  });

}

onItemSelect(item: any,type:string) {
  if(type == 'SchoolCategory' && this.service.formData.AdminShareDocInfo.SchoolCategoryIdList){

    var ids = item.Id;
    for(var i=0;i<this.service.formData.AdminShareDocInfo.SchoolCategoryIdList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.AdminShareDocInfo.SchoolCategoryIdList[i].Id;
    }
  }
}
onSelectAll(items: any,type:string) {
  if(type == 'SchoolCategory'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }

  }
}
// filesuploaded(fileInfo){
//   if(fileInfo && fileInfo.IsSaved){
//     this.service.formData.AssignmentInfo.FileInfo = fileInfo;
//   }


// }
}
