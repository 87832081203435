import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class DataService extends BaseService {

  public user: any;
  public basicInfo:any;
  public startDate:string = '';
  public endDate:string = '';
  public CalenderUserId:number = 0;
  public groupName:string = '';
  public actionUserRole: string = ''
  public userRole: string = '';
  public CalendarMode:string = '';
  public primaryUser:string = '';
  public previousState:string='';
  public CRDefaultValue :any= { aSep: '', aDec: ',', eDec: 2, mDec: 2 };
  public CRMin0DefaultValue:any = { aSep: '', vMin: 0.00, aDec: ',', eDec: 2, mDec: 2 };
  public CR1Min0DefaultValue:any = { aSep: '', vMin: 0.0, vMax: 99.9, aDec: ',', eDec: 1, mDec: 1 };
  public CRMin0Max100Value:any = { aSep: '', vMin: 0.00, vMax: 100.00, aDec: ',', eDec: 2, mDec: 2, minimumValue: 0 };
  public CRMin1Max100Value:any = { aSep: '', vMin: 1.00, vMax: 100.00, aDec: ',', eDec: 2, mDec: 2 };
  public IsLanguageHide:boolean = false;
  public IsPMDExists:boolean = false;
  public IsEVOPDFEnabled:boolean = true;
//   public appName:string = '/teraFORMOnlineSystemSPA';
//   public apiName:string = '/teraFORMOnlineSystemSPA_RestAPI';
//   public newRefGet:string = 'https://dnhf.dk/#/login/?button_launch_token=';
}


