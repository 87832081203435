<ul class="list-sidebar bg-defoult clear-both mt-3">
  <li>
    <a routerLink="/teacherdashboard/" routerLinkActive="active">
      <i class="fa fa-th-large"></i>
      <span class="nav-label">{{'Dashboard' | translate}} </span>
    </a>

  </li>
  <li>
    <a routerLink="/calendar/" routerLinkActive="active">
      <fa-icon [icon]="calendarIcon"></fa-icon>
      <span class="nav-label">Calendar</span></a>
  </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#functionsDropdown" class="collapsed active">
      <i class="fa fa-sliders-h" aria-hidden="true"></i> <span class="nav-label">Functions</span> <span
        class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="functionsDropdown">
      <li class="active"><a routerLink="/question/" routerLinkActive="active">Assessments</a></li>
      <li><a routerLink="/analysis/" routerLinkActive="active">Analysis</a></li>
      <li><a routerLink="/survey/" routerLinkActive="active">Survey</a></li>
      <li><a routerLink="/assignment/" routerLinkActive="active">Assignments</a></li>
      <li><a routerLink="/event/" routerLinkActive="active">Interactive Live Classes</a></li>
    </ul>
  </li>
  <li> <a routerLink="/user-list/" routerLinkActive="active"><i class="fa fa-users"></i> <span
        class="nav-label">Students</span></a> </li>
  <li> <a routerLink="/chat/" routerLinkActive="active"><i class="fa fa-comments"></i> <span
        class="nav-label">Chat</span></a> </li>
  <li> <a routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span
        class="nav-label">Attendance</span></a> </li>
  <!-- <li> <a  routerLink="" (click)="OnLogOut()"><fa-icon [icon]="logoutIcons"></fa-icon> <span class="nav-label">Logout</span></a> </li> -->

</ul>