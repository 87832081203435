<!-- <ul class="list-sidebar bg-defoult clear-both mt-3">


  <li *ngFor="let menuItem of menuItems; let i=index">
    <a routerLink="/{{menuItem.Route!==('/' || '')?menuItem.Route.split(' ').join(''):'/404'}}/" routerLinkActive="active" #parenActive="routerLinkActive" *ngIf="menuItem.Type == 'link'">
      <img src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{parenActive.isActive?'_green':'_gray'}}.svg" alt="{{menuItem.Icon}}">
      <span class="nav-label">{{menuItem.Name}} </span>
    </a>

    <a href="" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length<=1"
    routerLink="/{{menuItem.ChildMenuList[0].Route!==('/' || '')?menuItem.ChildMenuList[0].Route:'/404'}}/" routerLinkActive="active" >
      <img src="../../assets/images/icons/svg/sidebar/{{menuItem.ChildMenuList[0].Icon}}_gray.svg" alt="{{menuItem.ChildMenuList[0].Icon}}">
      <span class="nav-label">{{menuItem.ChildMenuList[0].Name}}</span>
    </a>

    <a href="#"   data-toggle="collapse" data-target="#{{menuItem.Code}}Dropdown" [ngClass]="(IsOpend == i &&  IsCollapse )?'active':'collapsed'"
    (click)="menuChange(i)" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
          <img src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{(IsOpend == i &&  IsCollapse )?'_green':'_gray'}}.svg" alt="{{menuItem.Icon}}">
        <span class="nav-label">{{menuItem.Name}}</span> <span
          class="fa fa-chevron-left float-right"></span>
      </a>
      <ul class="sub-menu collapse show" id="{{menuItem.Code}}Dropdown" [ngClass]="{'show': (IsOpend == i &&  IsCollapse ) }" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
        <li *ngFor="let subMenuItem of menuItem.ChildMenuList; let childIndex = index" >
          <a routerLink="/{{subMenuItem.Route!==('/' || '')?subMenuItem.Route:'/404'}}/" routerLinkActive="active" (click)="subMenuChange(i+childIndex)">{{subMenuItem.Name}}</a>
        </li>
      </ul>

  </li>

</ul> -->
<li data-bs-toggle="tooltip" title="{{menuItem.Name}}" *ngFor="let menuItem of menuItems; let i=index"
  routerLink="/{{menuItem.Route!==('/' || '')?menuItem.Route.split(' ').join(''):'/404'}}/" routerLinkActive="active"
  #parenActive="routerLinkActive">
  <i class="bi bi-{{menuItem.Icon}}"></i>
  <span>{{menuItem.Name}} </span>
</li>