import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-qrcode-view',
    templateUrl: './qrcode-view.component.html',
    styleUrls: ['./qrcode-view.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class QRCodeViewComponent implements OnInit {
list:any[];
QRCodeUrl:string;
  constructor(public bsModalRef: BsModalRef,private modalService: BsModalService ) { }

  ngOnInit(): void {
    this.QRCodeUrl = this.list[0].QRCodeUrl;
  }

}
