import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/shared/school.service';
import { School } from '../shared/school.model';
import { SchoolmenuComponent } from './schoolmenu.component';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CommonService } from '../shared/common.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-school',
    templateUrl: './school.component.html',
    styleUrls: ['./school.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class SchoolComponent implements OnInit {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: School[];
  // bsModalRef: BsModalRef;
  // modalRef: BsModalRef;
  gridOptions:any;
  constructor(public service:SchoolService,public commonServ:CommonService) {  this.columnDefs = [
    { 
      headerName:"SchoolId",
      field: 'SchoolId', cellRendererFramework: SchoolmenuComponent, filter:false,
      headerComponent: 'customHeader', 
      headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
    },
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Address', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'Street', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'City', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'State', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'PostalNo', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'Mobile', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'Telephone', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 220 },
    { field: 'Website', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 },
    { field: 'IsActive', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 120, minWidth: 120 }
    
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.defaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
      sortable: true,
      floatingFilter: true,
};
this.rowSelection = 'single';
this.commonServ.RefreshGrid$.subscribe(
  item => {
    
    this.service.getSchoolBranchList().subscribe((res : School[]) => {
      this.rowData =  res;
    });
    // setTimeout(() => {
    //   this.service.getUserList().subscribe((res : User[]) => {
    //     this.rowData =  res;
    //   });
    // }, 2000);  
   }
);
}

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.service.getSchoolBranchList().subscribe((res : School[]) => {
      this.rowData =  res;
    });
  }

  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();
  }
}
