import { Component, OnInit } from '@angular/core';
import { faCalendarDays, faQrcode, faUsd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-superadmin-sidebar',
    templateUrl: './superadmin-sidebar.component.html',
    styleUrls: ['./superadmin-sidebar.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgClass, FontAwesomeModule]
})
export class SuperadminSidebarComponent implements OnInit {

  Isfliph:boolean=false;
  qrcodeIcon = faQrcode;
  constructor() { }

  calendarIcon = faCalendarDays;
  currencyIcon = faUsd;
  ngOnInit(): void {
  }
  toggleSidebar(){
    this.Isfliph = document.querySelector(".sidebar.fliph")!= null?false:true
  }

}
