<div class="container-fluid ">
    <div class="row justify-content-center">
        <div class="col-10 pt-5">
            <div class="row mt-5 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                        <h1 class="d-block title pt-1 m-0">Our Privacy Policy</h1>
                        <span>Last Updated: August 10, 2023</span>
                        <p class="mt-2">
                            It is our mission to help Schools and parents deliver meaningful learning experiences. To
                            achieve this, we build easy to
                            use tools that help make things simple for schools and parent to empower them to focus on
                            the important things. It is
                            hence critical that we create a safe and secure environment where Schools, teachers,
                            children, parents, and content
                            providers freely share content and ideas. Creating such a safe and secure environment is
                            central to the success of our
                            endeavours.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center bg-white">
        <div class="col-10">
            <div class="row align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Introduction</h1>


                    <p>
                        This Privacy Policy governs the use of data collected by our websites HybridLearning.in, and our
                        apps –
                        HybridLearning.in community of Educators, Student (hereafter, collectively referred to as “the
                        Service”,
                        “HybridLearning.in”, or, “the HybridLearning.in Service”).
                        By using HybridLearning.in, you are agreeing to this Privacy Policy. If you do not agree, please
                        refrain from using
                        Toddle. You can contact us anytime with queries about this Privacy Policy at <a
                            href="#">info&#64;HybridLearning.in.</a>

                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Definitions</h1>


                    <p>
                        <b>“Profile”</b><br>
                        This includes personally identifiable information that we collect when you create an account.
                        This may include First
                        Name, Last Name, Email, and Phone Number of the user.<br>

                        <b>“Class Journal”</b><br>
                        This includes all the content added to the class journal.<br>

                        <b>“Academic Plans”</b><br>
                        This includes the Programme of Inquiry, Academic year Plans, Learning Experiences, Assessments,
                        Schedule, Reflections
                        created by the teachers using the planning elements on Toddle. The external resources added by
                        the teacher are not
                        included in this.<br>

                        <b>“Messages”</b><br>
                        This includes the messages sent via HybridLearning.in – both from parents to teachers and vice-
                        versa.<br>

                        <b>“Student Portfolio”</b><br>
                        This includes all the content added to a specific student’s portfolio including student
                        persona.<br>

                        <b>“Student Data”</b><br>
                        Any data collected by HybridLearning.in that can be linked back to an individual student. This
                        contains name, age, Email
                        ID, name of parents, school name, and the assessment data.<br>

                        <b>“HybridLearning.in Resource Bank”</b><br>
                        A collection of all the Academic Plans created by teachers. By default, the Academic Plans are
                        private to the school.<br>

                        <b>“Insights”</b><br>
                        HybridLearning.in analyses the data collected from the teachers and students and converts them
                        into actionable points to
                        support teachers in teaching and learning. This set of actionable data-points is collectively
                        referred to as Insights.<br>
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">What is HybridLearning.in?</h1>


                    <h3 class="font-weight-500">

                        HybridLearning.in is an all-in-one SaaS based learning management system (LMS), community
                        portal and engagement platform for K–12 schools.

                    </h3>

                    <span>
                        <h3 class="font-weight-500">Everything in One Place</h3>
                        <ul>
                            <li>
                                One platform to connect, communicate, and collaborate School community asContent
                                providers,
                                Parents, Teachers, Students.
                            </li>
                        </ul>
                    </span>

                    <span>
                        <h3 class="font-weight-500">Enhance Teaching and Learning</h3>
                        <ul>
                            <li>
                                Utilise the latest pedagogy, track student progress, and innovate teaching and learning.
                            </li>
                        </ul>
                    </span>

                    <span>
                        <h3 class="font-weight-500">The Whole Student</h3>
                        <ul>
                            <li>
                                Support the development of the whole student, their academic growth, and wellbeing with
                                Social Emotional learning, Life Skills, Outside the box learning.
                            </li>
                        </ul>
                    </span>

                    <span>
                        <h3 class="font-weight-500">Everyone Engaged</h3>
                        <ul>
                            <li>
                                Easy content and group management keeps people informed and information relevant to
                                them, in one place.
                            </li>
                        </ul>
                    </span>
                    <span>
                        <h3 class="font-weight-500">Market-leading Integrations</h3>
                        <ul>
                            <li>
                                Enhance daily workflows and centralise systems for schools and families.
                            </li>
                        </ul>
                    </span>
                    <span>
                        <h3 class="font-weight-500">Support Every Step of the Way</h3>
                        <ul>
                            <li>
                                Drive success with students and teachers’ engagement, professional services, and a
                                legendary support team.
                            </li>
                        </ul>
                    </span>



                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">HybridLearning.in and Privacy Certifications</h1>


                    <p>
                        <b>HybridLearning.in</b> is a signatory to the Student Privacy Pledge, agreeing to a set of
                        principles intended to safeguard student privacy, including responsible stewardship, protection,
                        and transparent handling of student personal information.
                    </p>


                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Compliance with GDPR</h1>


                    <p>
                        <b>HybridLearning.in</b> collects minimal information from you and only uses it for the purposes
                        explicitly called out in the
                        Privacy Policy. The data collected is stored securely using industry standards. All the details
                        with regards to the
                        nature of the data collected and the reason for collecting it can be found in the Privacy
                        Policy. <b>HybridLearning.in</b>
                        executes a Data Processing Agreement with all the schools in the Global south and Global North
                        Regions.
                    </p>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Data collected by HybridLearning.in</h1>


                    <p>
                        We only collect the data that we need for providing <b>HybridLearning.in</b> services. It is our
                        honest endeavour to minimise
                        the data that we collect about our users.
                    </p>
                    <p>
                        We collect information from all individuals creating an account on <b>HybridLearning.in.</b>
                        This includes School community of
                        teachers, students, parents, and content providers.
                    </p>
                    <p>
                        We also collect log data from all the visitors to our website and teachers and school
                        administrators willingly leaving data for our marketing campaigns.
                    </p>
                    <p>
                        Below is a list of data that we collect from our different users and how we refer to it:
                    </p>
                    <p>
                        <b>“Profile”:</b> This includes personally identifiable information that we collect when you
                        create an account. This may include First Name, Last Name, E-Mail and Phone Number of the user.
                        <br>
                        <b>“Class Journal”:</b> This includes all the content added to the class journal.<br>
                        <b>“Academic Plans”:</b> This includes the Programme of Inquiry, Unit Plans, Learning
                        Experiences, Schedule, Reflections created by the teachers using all the planning elements as
                        specified in the customisable templates. The external resources added by the teacher are not
                        included in this.<br>
                        <b>“Messages”:</b> This includes the messages sent via HybridLearning.in – both from parents to
                        teachers and vice- versa.<br>
                        <b>“Student Portfolio”:</b> This includes all the content added to a specific student’s
                        portfolio – photos, videos, notes, comments etc.<br>
                        <b>“Student Data”:</b> Any data collected by us that can be linked back to an individual
                        student. This contains name, age, Email ID, name of parents and the school’s name.<br>
                        <b>“Log Data”:</b> We collect log data such as your IP address, browser type, device type,
                        operating system, and your mobile carrier. Additionally, we also use cookies to keep you logged
                        into your system to improve your user experience.<br>
                    </p>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Why do we collect this data?</h1>

                    <h3 class="font-weight-500">
                        We use the collected data only to provide services to you as laid out in the Privacy Policy and
                        as authorised by your
                        school. Below are a few use cases that we have for the collected data:
                    </h3>

                    <ul>
                        <li>Allow users to retrieve, view and edit Academic Plans</li>
                        <li>Allow users to access and use our various features such as Journal Content, Activities,
                            Messages etc.</li>
                        <li>Send notifications about activities and updates on your account.</li>
                        <li>Analyse usage information to investigate, prevent, and detect activities on our service that
                            we believe may violate the law or applicable regulations.</li>
                        <li>Provide customer support to users.</li>
                        <li>Derive insights from usage trends to develop new features or to improve the existing ones.
                        </li>
                    </ul>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Where do we store the data?</h1>

                    <ul>
                        <li>Our data is hosted on Amazon Web Services (AWS) servers.</li>
                        <li>For our users in Global south and Global north, we store the data in servers in United
                            States of America to ensure compliance with GDPR.</li>
                    </ul>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">What is the data NOT collected for?</h1>

                    <ul>
                        <li>We do not allow advertising or sharing data for advertising for any data collected through
                            HybridLearning.in</li>
                        <li>We never display ads, share data for the purpose of displaying ads, or allow data collection
                            by advertisers or data brokers.</li>
                        <li>We never sell data to anyone for any purposes.</li>
                        <li>We never allow profiling of our users for targeted online ads</li>
                    </ul>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Data Retention</h1>


                    <p>
                        <b>HybridLearning.in</b> will keep your data for only as long as it is required or as mandated
                        by law or as requested by the
                        3rd party. Before deleting your data, <b>HybridLearning.in</b> will send out 3 reminders to you.
                    </p>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">When does HybridLearning.in share data with third parties?</h1>


                    <p>
                        We are not providing data to any third-party services provider because we are developing
                        complete internal tools and features,
                    </p>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Third Party Analytics</h1>


                    <p>
                        We are not providing data to any third-party services provider because we are developing
                        complete internal tools and features,
                    </p>
                </div>


                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Third Party Analytics</h1>

                    <ul>
                        <li>To improve your experience with HybridLearning.in, we collect and use aggregate data about
                            usage patterns of how you use<br> HybridLearning.in – for example, how you interact with
                            various features on a page, the buttons that you click, the time that you spend on a page,
                            etc. This is done to streamline existing user experience and to provide you a better
                            experience of using HybridLearning.in.</li>
                        <li>We use a small number of third-party services to collect and analyse this data (such as
                            Google Analytics, Sentry). These services are contractually obligated only to use data about
                            your usage of HybridLearning.in to provide analytics services to us and are prohibited from
                            sharing it or using it for other purposes.</li>
                    </ul>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Cookie Policy</h1>

                    <p>
                        We use Cookies and other similar services (such as Local Storage) to keep you logged in to
                        <b>HybridLearning.in</b>, customize
                        your <b>HybridLearning.in</b> experience, understand how you use <b>HybridLearning.in</b>, and
                        promote <b>HybridLearning.in</b> to relevant
                        schools and parents. You can remove or disable cookies via your browser settings, in which case
                        your experience with
                        <b>HybridLearning.in</b> will not be optimal.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Abandoned accounts</h1>

                    <p>
                        We consider an account to be abandoned if it has not been accessed for over a year. We will
                        delete an account and all content associated with such accounts. However, to prevent accidental
                        deletion, we will notify the teacher, the school and any other email IDs associated with the
                        account and provide an opportunity to download the data of the abandoned account.
                    </p>

                </div>


                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Viewing, editing or porting your information</h1>

                    <p>
                        Parents are encouraged to work directly with school to make any changes in your data. If
                        however, you need to get in touch with us, you can write to us and we will work with the school
                        and do our best to make the required changes.
                    </p>
                    <p>
                        Administrators, parents, students and teachers can directly edit their information in their
                        Toddle profiles. Schools also have a right to use any other similar service and can place a
                        request to get all their data. We will do our best to comply to such requests. Once the pending
                        request is processed, the data retention and deletion policies will be followed.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Deleting Toddle Account</h1>

                    <p>
                        You have the right to “forget ability”, i.e., we will remove all your information from our
                        systems if you so wish. If
                        you would like to delete your Toddle account or any content submitted to HybridLearning.in,
                        please reach us. We will
                        notify you with email before deleting your account from our database. After receiving your
                        request, we may still retain
                        information for up to 365 days to provide customer support and prevent accidental deletion.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Data Protection Practices</h1>

                    <p>
                        We follow the latest, industry standards to protect your data. Some measures that are in place
                        include use of highly
                        secure, access-controlled data centres, data encryption in transit, and encryption data at rest
                        etc.
                    </p>
                    <p>
                        Despite these measures, in the event of a security breach, we will notify affected account
                        holders within the amount of
                        time required by the local law or by HybridLearning.in internal data breach policy, whichever is
                        more stringent, so that
                        you can take steps to keep your data safe.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Changes to the Privacy Policy</h1>

                    <p>
                        We may from time to time make changes to this Privacy Policy to account for changes to our
                        practices or applicable law. If we make changes to this Privacy Policy that we believe will
                        materially affect your rights, we will notify you by email about these changes. If you continue
                        to use our service after you receive notice of changes to this Privacy Policy, we will assume
                        that you have accepted these changes.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">Contact Information</h1>

                    <p>
                        If you have any questions about this Privacy Policy, please feel free to write to us at:
                        info&#64;HybridLearning.in and we will reach out to you as soon as possible.
                    </p>

                </div>
            </div>


        </div>
    </div>
</div>