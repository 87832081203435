import { Component, OnInit } from '@angular/core';
import {  ColDef, ValueGetterParams } from 'ag-grid-community';
import { CommonService } from '../shared/common.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-partner-list',
    templateUrl: './partner-list.component.html',
    styleUrls: ['./partner-list.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class PartnerListComponent implements OnInit {

  partnerListcolumnDefs:any;
  partnerListdefaultColDef:any;
  partnerListgridOptions:any;
  partnerListrowSelection;
  partnerListData:any;
  constructor(public commomService:CommonService) { 
    
this.partnerListcolumnDefs = [
  { headerName:"Name",colId: 'LastName&LastName',valueGetter: this.FirstNameLastNameValueGetter, sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120,maxWidth: 200  },
  
  { headerName:"Mobile", field: 'Mobile', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
  { headerName:"Email", field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 300 , maxWidth: 300,minWidth: 200   },
  { headerName:"PartnerFor",field: 'PartnerFor', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 200    },
  
  
  { headerName:"Created Date",field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 150    },
  { headerName:"Comments",field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
];

this.partnerListdefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: false,
  sortable: false,
  floatingFilter: false,
  };
  }

  ngOnInit(): void {
    this.commomService.getregisterinfoWithType("Partner").subscribe((res: any) => {
      this.partnerListData =  res;
  });
  }
  FirstNameLastNameValueGetter(params: ValueGetterParams) {
    return params.data.FirstName + " "+params.data.LastName;
  }

}
