import { Component, ElementRef, EventEmitter, Input, OnInit, output, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/shared/event.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-answer-modal-component',
  standalone: true,
  imports: [CommonModule,FormsModule,TranslateModule],
  templateUrl: './answer-modal-component.component.html',
  styleUrl: './answer-modal-component.component.scss'
})
export class AnswerModalComponentComponent  implements OnInit {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @Output() closeForm = new EventEmitter<string>();
  @Output() answerReload = new EventEmitter<string>();
  @Input() questionId: any;

 
  mediaRecorder!: MediaRecorder;
  recordedChunks: Blob[] = [];
  recordedBlobUrl: string | null = null;
  isRecording = false;

  stream!: MediaStream;
  uploadForm!: FormGroup;
  submitted = false;
  selectedFile: File | null = null;
  fileUploadError: string = '';
  responseData: any;
  blob: any;
  // answerReload :Function;
  // questionId:string;
  constructor(public service:EventService,public bsModalRef: BsModalRef,private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initMediaStream();
     // Initialize the form with validation
     this.uploadForm = this.formBuilder.group({
      // user: ['', Validators.required],
      // subject: ['', Validators.required],
      // description: ['', Validators.required]
    });
  }

  // Initialize the media stream from camera and microphone
  async initMediaStream() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });

      // Set the stream to the video element
      this.videoElement.nativeElement.srcObject = this.stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  }

  // Start recording video and audio
  startRecording() {
    this.recordedChunks = [];
    this.isRecording = true;

    // Initialize MediaRecorder with the media stream
    this.mediaRecorder = new MediaRecorder(this.stream);

    // Event triggered when data is available (i.e., recording in progress)
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

    // Start recording
    this.mediaRecorder.start();
  }

  // Stop recording and create a blob from the recorded data
  stopRecording() {
    this.isRecording = false;

    this.mediaRecorder.stop();

    this.mediaRecorder.onstop = () => {
      // Create a Blob from the recorded chunks
      this.blob = new Blob(this.recordedChunks, { type: 'video/webm' });
    
      // Create a URL for the blob
      this.recordedBlobUrl = URL.createObjectURL(this.blob);
      let stream = this.mediaRecorder.stream; // Get the media stream
      let tracks = stream.getTracks(); // Get all tracks from the stream
  
      // Stop each track (video/audio)
      tracks.forEach(track => track.stop());
    };
  }
  // Getter for easy access to form fields
  get f() {
    return this.uploadForm.controls;
  }

  addAnswer(): void {
    this.submitted = true;
  
    // Validate form fields
    if (this.uploadForm.invalid || !this.blob) {
      if (!this.blob) {
        this.fileUploadError = 'File is required.';
      }
      return;
    }
  
    // Prepare form data for submission
    const formData = new FormData();
    // formData.append('user', this.uploadForm.get('user')?.value);
    formData.append('questionId', this.questionId);
    // formData.append('description', this.uploadForm.get('description')?.value);
    formData.append('file', this.blob, "recording.webm");
    // console.log('Form submission successful:', formData);
      
    // Submit the form data to the backend
    // this.http.post('https://your-backend-api.com/upload', formData).subscribe(
    //   (response) => {
    //     console.log('Form submission successful:', response);
    //     this.responseData = response;
  
    //     // Reset the form
    //     this.uploadForm.reset();
    //     this.submitted = false;
    //     this.selectedFile = null;
    //   },
    //   (error) => {
    //     console.error('Form submission error:', error);
    //     // Handle submission error
    //   }
    // );
    }
    discardRecording() {
      // this.recordedChunks = [];
      this.recordedBlobUrl = null;
      this.isRecording = false;
      this.initMediaStream();
    
      }
    cancel() {
      this.closeForm.emit();
    }
        
}
 