import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { LeaveInfo, LeaveViewData } from 'src/app/shared/leave.model';
import { LeaveService } from 'src/app/shared/leave.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-leave-request',
    templateUrl: './leave-request.component.html',
    styleUrls: ['./leave-request.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule]
})
export class LeaveRequestComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  list: any[] = [];
  yearList: any = [];
  role: string;
  datepickerConfig: BsDatepickerConfig;
  classList: any[] = [];
  selectedYear:string=''
  selectedYearId:number=0;
  selectedChileId:number=0;
  childrenList:any=[];
  userData:any=[];
  leaveTypeList:any;
  urlSafe: SafeResourceUrl;
  Role:string;
  userId:number=0;
  constructor(public leaveServ: LeaveService, public bsModalRef: BsModalRef, 
    private notificationServ: NotificationService, private commonService: CommonService,
    public sanitizer: DomSanitizer,public router:Router,public userService:UserService,) { 
      this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.getStartAndEndDate();
      this.getLeaveInfo();
    }

  ngOnInit(): void {
    
    this.getMyInfo();
  }
  getStartAndEndDate(){
    this.commonService.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.selectedYearId = res[0].Id;
      // this.config.params.startDate =res[0].Code;
      // this.config.params.endDate= '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1);
     
    });
  }

  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.userId = res.UserInfo.UserId;
      if(this.commonService.selectedChileId<=0)
      {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      this.childrenList =  res.ParentInfo.ChildrenList;


      this.getuser();
      
    });
  }
 
  getuser(){
    var userid = this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    // this.config.params.userId =this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData =  res; 
     
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath);
      // this.getleaveinfo();
      // this.getuserleaveinfo();
      this.getLeaveInfo();
    });
  }
  changeChild(){
    this.commonService.selectedChileId = this.selectedChileId;
    // this.getstudentfeeinfo(this.selectedChileId);
}
  selectChild(id:any){
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    // this.config.params.userId = id;
    if(id>0){
      this.commonService.selectedChileId = this.selectedChileId;
    this.getuser();
    }else{
      this.router.navigateByUrl('/add-child');
    }
   
  }
  saveLeaveRequestInfo(form){
    this.leaveServ.saveleaveinfo().subscribe((res:any)=>{
      
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
      }
      
    })
  }
  getLeaveInfo(){
    var userid= this.Role == 'Teacher'?this.userId:this.selectedChileId 
    this.leaveServ.getleaveinfoById(userid).subscribe((res:any)=>{
      this.leaveServ.formData = res;
      this.leaveServ.formData.LeaveInfo.AcademicYearId = this.selectedYearId;
      this.leaveTypeList = this.leaveServ.formData.LeaveTypeList;
      
      
    })
  }
}
