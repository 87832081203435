import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { ParentShareDocService } from '../shared/Parent-Share-Doc.service';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-parentsetting',
    templateUrl: './parentsetting.component.html',
    styleUrls: ['./parentsetting.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgFor, NgMultiSelectDropDownModule, TranslateModule]
    imports: [FormsModule, NgFor, TranslateModule]

})
export class ParentsettingComponent implements OnInit {
  dropdownSettings:IDropdownSettings;
  challengeInfo:any;
  challengeList:any;
  schoolCategoryId:any;
  purposeOfPostList:any;
  schoolCategoryList:any;
  stateList:any;
  purposeOfPostInfo:any;
  stateInfo: any;


  constructor(public service :ParentShareDocService,private modalService: BsModalService,public commonService:CommonService,private notificationServ:NotificationService) {

   }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.getParentSettingInfo();
  }



  getParentSettingInfo(){
    this.service.getParentSettingInfo(0,0,0).subscribe((res : any) => {
      // console.log(res);
      this.service.formData1 = res;
      this.schoolCategoryList = res.SchoolCategoryInfo;
      this.stateList = res.StateInfo;
      this.challengeInfo = res.ChallengeInfo;
      this.purposeOfPostInfo =res.PurposeOfPostInfo;
    });
  }
  saveDocument(){
    this.service.saveParentSettingInfo().subscribe((res:any)=>{
    console.log(res);
      if(res.IsSaved){
        // formData: new SchoolSettingInfo();
        this.service.formData1= res;
        this.notificationServ.ShowInfo('Successfully Saved!');
      }


    })
  }

onItemSelect(item: any,type:string) {
  // if(type == 'ChallengeIdList' && this.service.formData1.ParentSettingInfo.BranchId){

  //   var ids = item.Id;
  //   for(var i=0;i<this.service.formData1.ParentSettingInfo..length;i++){
  //     if(ids){
  //       ids = ids+',';
  //     }
  //     ids = ids+this.service.formData1.ParentSettingInfo.ChallengeIdList[i].Id;
  //   }
  // }
}
onSelectAll(items: any,type:string) {
  if(type == 'ChallengeIdList'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }

  }
}
}
