<div class="inner-form">
  <div class="field-row">
    <div class="select_field video-field" *ngIf="!recordedBlobUrl">
      <label for="">Record Your Question</label>
      <!-- Video Element to Display the Live Stream -->
      <video #videoElement autoplay></video>
      <div class="recording_button action-links mt-3">
        <!-- Recording Buttons -->
        <span (click)="startRecording()" class="rec-button bi bi-record-circle" title="Start Recording"></span>
        <span (click)="stopRecording()" class="rec-button bi bi-stop-circle" title="Stop Recording"></span>
        <span type="button" (click)="addAnswer()" class="add_button w-auto" type="button">Save</span>
      </div>
    </div>
    <div class="select_field video-field" *ngIf="recordedBlobUrl">
      <label for="">Recorded Video</label>
      <video [src]="recordedBlobUrl" controls></video>
      <div class="recording_button action-links mt-3">
        <!-- Recording Buttons -->
        <a [href]="recordedBlobUrl" download="recording.webm" class="rec-button" title="Download Recording"><span
            class="bi bi-download"></span></a>
        <span (click)="discardRecording()" class="rec-button bi bi-x-circle" title="Discard Recording"></span>
        <span type="button" (click)="addAnswer()" class="add_button w-auto" type="button">Save</span>
      </div>
    </div>

    <!-- <button type="button" class="custom-primary-btn custom-danger" (click)="cancel()"
                aria-label="Close">Cancel</button> -->
  </div>
</div>