import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import {FORMAT} from '../../constant/config'
@Component({
  selector: 'app-timer-button',
  templateUrl: './timer-button.component.html',
  styleUrl: './timer-button.component.scss',
  standalone: true,
  imports:[CommonModule]
})

export class TimerButtonComponent implements OnInit {
  @Input() event: any;
  @Input() isdisabled?: boolean = false;
  @Output() isActive = new EventEmitter<string>();
  @Output() reSchedule: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelSchedule: EventEmitter<any> = new EventEmitter<any>();
  startDate: Date;
  endDate: Date;
  selectedTitle: String;
  status: String = "before";
  date: any;
  now: any;
  targetTime: any ;
  targetCloseTime: any ;
  difference: number;
  days: number;
  hours: string;
  minutes: string;
  seconds: string;
  hasReSchedule: boolean = false;
  hasCanceled: boolean = false;
  isAdmin:any=false;
  ngOnInit(): void {

    this.startDate=this.getISODate(this.event.StartTimeISO);
    this.endDate=this.getISODate(this.event.EndTimeISO);
    
    var MS_PER_MINUTE = 60000;
    var durationInMinutes = 5;
    this.targetTime = new Date(this.startDate.getTime() - durationInMinutes * MS_PER_MINUTE);
    this.targetCloseTime = new Date(this.endDate.getTime() - durationInMinutes * MS_PER_MINUTE);
    this.selectedTitle = `${moment(this.startDate).format(FORMAT)} - ${moment(this.endDate).format(FORMAT)}`
    this.selectedTitle=this.selectedTitle.toUpperCase()
    if (this.reSchedule.observers.length > 0) {
      this.hasReSchedule=true
    }
    if (this.cancelSchedule.observers.length > 0) {
      this.hasCanceled=true
    }
  }
  //A lifecycle hook that is called after Angular has fully initialized a component's view
  ngAfterViewInit() {
    // console.log(this.endDate);

    //trigger in every second
    setInterval(() => {
      
      this.tickTock();
      this.difference = (this.targetTime - this.now)/1000 ;
      if(this.targetTime <= this.now && this.endDate >= this.now){
        this.status = 'active'
      }else if(this.targetCloseTime < this.now){
        this.status = 'after'
      }else{
        this.status = 'before'
      }
      
      !isNaN(this.days)
        ? (this.days = Math.floor(this.difference/ (60 * 60 * 24)))
        : (this.days = 0);
    }, 1000);
  }
  //manages variable foe timer
  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    this.days = Math.floor(this.difference/ (60 * 60 * 24));
    this.hours = this.formatIn2dgt(Math.floor(this.difference/3600) % 24);
    this.minutes = this.formatIn2dgt(Math.floor(this.difference/60)% 60);
    this.seconds = this.formatIn2dgt(Math.floor(this.difference)% 60);
  }
  //emits to parent on join click 
  eventClicked(){
    this.isActive.emit(this.event);
  }
  //emits to parent's reSchedule on reSchedule click 
  eventReschedule(){
    this.reSchedule.emit(this.event);
  }
  //convert number in 2 digit format when its less the 10
  formatIn2dgt(time:number): string{
    if(time){
      if(time<10)
      return `0${time}` 
    return `${time}`
    }else{
      return '00'
    }
  }
  //emits to parent's cancelSession on cancelSession click 
  cancelSession() {
    this.cancelSchedule.emit(this.event);
  }
  getISODate(value){
    if (!value) return null;
    var sz=value.length
    if(value[sz-1]!='z' && value[sz-1]!='Z'){
      value=value+"Z"
    }
    
    return new Date(value)
  }    
}
