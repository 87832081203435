import { Component, OnInit } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../shared/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { EditBadgeComponent } from './edit-badge.component';
import { CUSTOMCONST } from '../shared/Const';
import { map } from 'rxjs';
import { NgFor, DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, NgFor, DatePipe]
})
export class BadgeComponent implements OnInit {

  star=faStar;
  badgeInfoList:any;
  bsModalRef: BsModalRef;
  userIs:number;

  constructor(public userService:UserService, public modalService: BsModalService, public commonService: CommonService) { 
    this.commonService.RefreshGrid$.subscribe(
      item => {
        this.getMyInfo();
       }
    );
  }

  ngOnInit(): void {
    this.getMyInfo();
  }
  getMyInfo() {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userIs = res.UserInfo.UserId;
      this.getuserBadgeInfoList(res.UserInfo.UserId);
    });
  }
  getuserBadgeInfoList(userId){
    this.userService.getuserBadgeInfoList(userId).subscribe((res:any)=>{
      console.log(res);
      this.badgeInfoList = res;
    })
  }
  
  openAddModel(Id:number,Action:string){
    const initialState = {
      list: [
        { 
          id: Id,
          action:Action,
          userId:this.userIs
        }
      ]

    };
    this.bsModalRef = this.modalService.show(EditBadgeComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-lg col-12', ignoreBackdropClick: true });

  }
}
