<div class="container-fluid pt-2">
    <owl-carousel-o [options]="featureBannnerOption">
        <ng-template carouselSlide>
            <!-- <img src="../../assets/images/2X/common-banner.png" alt="" style=""> -->
            <div class="h-100 d-flex align-items-center">
                <img src="../../assets/images/2X/Group-606.png" alt="" class="w-100">
            <div class="slider text-content d-none d-sm-block" style="bottom: unset; top: unset;">
                <h1 class="m-0">A Dynamic Learning Environment That Exceeds Classroom Walls</h1>
                <div class="row mt-3 pl-4">
                    <div class="col-7">
                        <p class="mt-2">
                            Hybrid Learning platform is  an educational sharing platform based on collaboration between teacher & student,
                            Either one on one or entire class. <br> It ensures that learning continues uninterruptedly
                            outside the school boundaries. <br>
                            This new generation learning environment; allows effective application of Social Learning,
                            Flipped Learning, Peer Interactive Learning, <br> Real Time Project-Based Learning
                            methodologies.
                            It supports the 21st century skills of the user with academic collaboration, <br> continuous
                            communication, instant follow-up and instant feedback features. Students communicate with
                            Their peers and teachers in a dynamic educational collaborative environment where
                            interaction is continuous.
                        </p>
                    </div>
                </div>
                <div class="row pl-5">
                    <div class="col-4">
                        <ul class="pl-0 custom-list-style1">
                            <li class="mb-2 right-angle1">Formative Assessments</li>
                            <li class="mb-2 right-angle2">Points based Assignments</li>
                            <li class="mb-2 right-angle3">Professional Development support</li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="pl-0 custom-list-style1">
                            <li class="mb-2 right-angle4">Creating votes and getting instant feedback</li>
                            <li class="mb-2 right-angle5">Events and calendar management</li>
                            <li class="mb-2 right-angle1">Discussing and commenting</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <div class="slider images-style1 h-100">
                <div class="image1 image1 d-none d-sm-block">
                    <img src="../../assets/images/2X/Group-555.png" alt="">
                </div>
                <div class="image2">
                    <img src="../../assets/images/Illustrations/transparent.png" alt="">
                </div>
                <div class="image3  d-flex justify-content-end w-100 h-100">
                    <div class="w-45">
                        <!-- <img src="../../assets/GIF/Features_Page/Assessments-and-Assignments-interactive-classess.gif" alt=""
                        class="img-fluid"> -->
                        <ng-lottie 
                    [options]="banner" 
                    (animationCreated)="featureBannerAnimationCreated($event)"
                    width="100%"
                    height="100%"
                    class="d-flex justify-content-end w-100"
                  >
                  </ng-lottie>
                    </div>
                </div>
                <!-- <div class="image3">
              <img src="../../assets/images/2X/Icon.png" alt="">
            </div> -->
                <div class="image4">
                    <img src="../../assets/images/2X/Pattern-1.png" alt="">
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<section>
    <div class="contener theam-title-bg mt-0 mt-sm-5">
        <div class="row justify-content-center m-0">
            <div class="col-12">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                        class="mr-4 h-25px"></span>
                                Physical & Virtual Interactive Classes
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <!-- <img mat-card-image
                            src="../../assets/images/Json/features/virtual_interactive_experience.json"
                            alt="value_education" class="m-0 w-100"> -->
                            <ng-lottie 
                                [options]="VIExperience" 
                                (animationCreated)="featureBannerAnimationCreated($event)"
                                width="100%"
                                height="100%"
                                class="d-flex justify-content-end w-100"
                            >
                            </ng-lottie>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                        <div class="section-header">

                            <h1 class="d-block title pt-1 pb-1">A better virtual interactive experience</h1>
                        </div>
                        <p>
                            Side-by-side sharing for Hands-on instruction test students directly in Hybrid Learning
                            Platform Work Problems interactively with virtual group whiteboards easily share virtual
                            classroom links and recordings.
                        </p>
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1">Interactive classes</h1>
                        </div>
                        <p>
                            Hybrid Learning Platform makes remote learning more immersive by giving instructors and
                            students the ability to share screens simultaneously. Simply enter your recurring class
                            schedule and get URL-based meeting and links that can be published to students and their
                            calendars.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>
<section>
    <div class="contener theam-title-bg mt-5">
        <div class="row justify-content-center m-0">
            <div class="col-12">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                        class="mr-4 h-25px"></span>
                                        
                                Content Management for Assignments & Assessments
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-0 align-items-center">
                    
                    <div class="col-12 mb-3 mt-4">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <!-- <img mat-card-image
                                src="../../assets/images/Json/features/content_management_for_assessments_and_assignments.json"
                                alt="content management for assessments and assignments" class="m-0 w-100"> -->
                                <ng-lottie 
                                [options]="CMForAAssignments" 
                                (animationCreated)="featureBannerAnimationCreated($event)"
                                width="100%"
                                height="100%"
                                class="w-100"
                            ></ng-lottie>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center mt-3">
            <div class="col-10 mb-3 ml-auto mr-auto">
                <span class="font-weight-500 h5">It enable all members of the Learners to access, review, and give feedback on learning</span>
                <p>
                    Students have both fixed and flexible learning, providing them with control of up to the school Students use this time to shape their learning according to their Academic, SEL, Life Skills, Outside the Box learning goals and priorities, which they have choose from school. 
                    <br>
                    <span class="font-weight-500 h5">We believe that to flourish in the highly complex</span>
                <p>
                    The structure of our platform is designed to allow students to exercise a greater level of control over the pace at which they work, the spaces in which they work and what they choose to focus their time on.
                    <br>
                    <span class="font-weight-500 h5">Live Tracking Assignments and Assessments</span>
                <p>
                    Students must emerge from school confident in their own interests, their preferred learning styles, and their capacity to manage themselves in unfamiliar contexts.

                    <br>

            </div>
        </div>
    </div>
</section>
<section>
    <div class="contener theam-title-bg mt-5">
        <div class="row justify-content-center m-0">
            <div class="col-12">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                        class="mr-4 h-25px"></span>
                                        
                                        Assignments Process
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">
                    
                    <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                        <div class="section-header">

                            <h1 class="d-block title pt-1 pb-1">Interactive Assignments that Motivate Students Outside the Class</h1>
                        </div>
                        <p>
                            Multimedia-friendly. Easy-to-use question types. Automatic point based. With Hybrid Learning
                            Platform, you can create dynamic, interactive Watch, Listen, Read, Write and Do Assignments
                            students will love.
                            90% of students said the variety of assessment types in Hybrid Learning Platform helped them
                            develop critical thinking skills.
                        </p>
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1">Keep the learning going after class lets out</h1>
                        </div>
                        <p>
                            The more you do, the more you learn. Sounds simple. The challenge is keeping students
                            engaged with assignments outside of class, while providing the feedback they need to stay
                            motivated and on track.
                            Hybrid Learning Platform solves these issues with its homework system by making it easy to
                            create dynamic, interactive assignments purpose-built for today’s learners. Incorporate
                            interactive elements to engage students with an in-class assignment or homework activity and
                            save yourself time by using pre-built questions you can import with ease. Plus, with auto
                            point based functionality you can make grading a snap, giving you and your students
                            immediate access to results.
                        </p>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img mat-card-image src="../../assets/GIF/Home_Page/Home_Page_Banner_Graphics.gif"
                                alt="value_education" class="m-0 w-100">
                                <!-- <ng-lottie 
                                [options]="HPBGraphics" 
                                (animationCreated)="featureBannerAnimationCreated($event)"
                                width="100%"
                                height="100%"
                                class="w-100"
                            > </ng-lottie> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="contener theam-title-bg mt-5">
        <div class="row justify-content-center m-0">
            <div class="col-12">
                <div class="row my-4 align-items-center m-0">
                    <div class="col-12">
                        <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                        class="mr-4 h-25px"></span>
                                Assessments Process
                            </h1>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-12 pb-3 mt-4">
        <div class="mt-2 mb-2 ml-auto mr-auto">
            <!-- <img mat-card-image src="../../assets/images/Json/features/formative_assessments_process.json"
                alt="Formative Assessments Process" class="m-0 w-100"> -->
                <ng-lottie 
                    [options]="FAProcess" 
                    (animationCreated)="featureBannerAnimationCreated($event)"
                    width="100%"
                    height="100%"
                    class="w-100"
                >
                </ng-lottie>
        </div>
    </div>
</section>
        <section>
            <div class="container-fluid bg-theam-body">
                <div class="row justify-content-center mt-0">
                    <div class="col-10 mb-3 ml-auto mr-auto">
                        <span class="font-weight-500 h5">Run Assessments and Exams, Easily and
                            Securely</span>
                        <p>
                            Empower students to show what they’ve learned in particular subject
                            and lesson. Create and run effective tests and exams to cover any
                            assessment scenario.
                            <br>
                            <span class="font-weight-500 h5">Create a variety of formative and
                                summative assessments</span>
                        <p>
                            Including quizzes, and secure exams students complete on their own
                            devices.
                            Save time with auto grading functionality and maintain academic
                            integrity no matter where assessments take place. With application
                            you can monitor student activity and get an automatically generated
                            proctor report as soon as the assessment ends.
                            <br>
                            <span class="font-weight-500 h5">Live Track Assessment</span>
                        <p>
                            Provides an exciting alternative to traditional reports that
                            provides real-time feedback. Drawing on assessment for learning
                            strategies, the traditional report format is replaced by regular
                            updates on process. Feedback is transparent, high quality, and makes
                            process visible.

                            <br>


                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="contener theam-title-bg mt-5">
                <div class="row justify-content-center m-0">
                    <div class="col-12">
                        <div class="row my-4 align-items-center ">
                            <div class="col-12">
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                        <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                                class="mr-4 h-25px"></span>
                                        Student Academic and social Development portfolio
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid bg-theam-body">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="row mt-5 align-items-center">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                                <div class="mt-2 mb-2 ml-auto mr-auto">
                                    <!-- <img src="../../assets/images/Json/features/student_portfolio_management.json"
                                        alt="value_education" class="m-0 w-100"> -->
                                <ng-lottie 
                                    [options]="SPManagement" 
                                    (animationCreated)="featureBannerAnimationCreated($event)"
                                    width="100%"
                                    height="100%"
                                    class="w-100"
                                >
                                </ng-lottie>
                                </div>
                            </div>
                            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                                <div class="section-header">

                                    <h1 class="d-block title pt-1 pb-1">Portfolio Management of Hybrid Learning
                                        Platform</h1>
                                </div>
                                <p>
                                    It is a unique digital memory feature in which students academic
                                    achievements as well as projects they participate in and
                                    extracurricular social activities are collated and exhibited. It
                                    captures the overall learning and the overall outcomes.
                                    This area, which is created specifically for each student and is
                                    constantly updated, records every step in the learning process, it
                                    ensures that the information about the student is transferred to the
                                    next year.
                                </p>
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1">The portfolio approach is a long-term and
                                        powerful development
                                        monitoring tool</h1>
                                </div>
                                <p>
                                    In terms of comprehensively getting to know the
                                    students by their teachers and school management & planning goals
                                    for the future. It is also critical, considering that it will
                                    involve into an accreditation tool over time.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>


        <section>
            <div class="contener theam-title-bg mt-5">
                <div class="row justify-content-center m-0">
                    <div class="col-7">
                        <div class="row my-4 align-items-center m-0">
                            <div class="col-12">
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1 text-center"><span><img
                                                src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                                class="mr-4 h-25px"></span> School Development Management</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3 mt-4">
                <div class="mt-2 mb-2 ml-auto mr-auto">
                    <!-- <img mat-card-image src="../../assets/images/Json/features/vocational_development_management.json"
                   
                        alt="value_education" class="m-0 w-100"> -->
                        <ng-lottie 
                                    [options]="VDManagement" 
                                    (animationCreated)="featureBannerAnimationCreated($event)"
                                    width="100%"
                                    height="100%"
                                    class="w-100"
                                >
                                </ng-lottie>
                </div>

                <div class="container-fluid bg-theam-body">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-5 align-items-center">

                                <div class="col-12 mb-3">


                                    <div class="container-fluid bg-theam-body">
                                        <div class="row justify-content-center mt-5">
                                            <div class="col-12 mb-3 ml-auto mr-auto">
                                                <span class="font-weight-500 h5">In order to contribute to the
                                                    professional development of new
                                                    generation teachers who are productive, creative, directing, and open to
                                                    communication and interaction.</span>
                                                <p>
                                                    Hybrid Learning Platform supports professional and social
                                                    competencies, teachers are provided with a special sharing and
                                                    collaboration environment for different developmental areas such as education and technologies, assessment and evaluation, classroom management, 21st century teaching methods and techniques.
                                                    <br>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="contener theam-title-bg mt-5">
                <div class="row justify-content-center m-0">
                    <div class="col-10">
                        <div class="row my-4 align-items-center m-0">
                            <div class="col-12">
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1 text-center w-50 m-auto">
                                        <span><img src="../../assets/images/2X/Icon-feather-arrow-right-blue.png" alt=""
                                                class="mr-4 h-25px"></span>
                                        Calendar Management
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid bg-theam-body">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="row mt-5 align-items-center">
                            <div class="col-12 mb-3">
                                <div class="section-header">

                                </div>
                            </div>
                            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">

                                <div class="accordion calendarManagement" id="accordionExample">
                                    <div class="card bg-transparent">
                                        <div class="card-header bg-transparent" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true">
                                            <span class="icon-arrow"><img
                                                    src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                                                    class="mr-4 h-25px"></span>
                                            <span class="title">Hybrid Learning Platform Calendar
                                                Management</span>
                                        </div>
                                        <div id="collapseOne" class="collapse show" data-parent="#accordionExample">
                                            <div class="card-body">
                                                It provides the ability to manage a calendar of online or physical classes schedule,
                                                Assessments and Assignments activities.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card  bg-transparent">
                                        <div class="card-header collapsed bg-transparent" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            <span class="icon-arrow"><img
                                                    src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                                                    class="mr-4 h-25px"></span>
                                            <span class="title">Event Calendar Management</span>
                                        </div>
                                        <div id="collapseTwo" class="collapse" data-parent="#accordionExample">
                                            <div class="card-body">
                                                It delivers the location, time and detailed descriptions
                                                of the activities to be held by your institution to the
                                                targeted users in your schools. However, each school can
                                                plan different activities by also managing its own event
                                                calendar.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card bg-transparent">
                                        <div class="card-header collapsed bg-transparent" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false">
                                            <span class="icon-arrow"><img
                                                    src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                                                    class="mr-4 h-25px"></span>
                                            <span class="title">Exam Schedule Management</span>
                                        </div>
                                        <div id="collapseThree" class="collapse" data-parent="#accordionExample">
                                            <div class="card-body">
                                                It allows you to enter the mock exam and written exam
                                                dates in your institution and to be entered in the
                                                calendars of all relevant users.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card bg-transparent">
                                        <div class="card-header collapsed bg-transparent" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false">
                                            <span class="icon-arrow"><img
                                                    src="../../assets/images/2X/Icon-feather-arrow-right.png" alt=""
                                                    class="mr-4 h-25px"></span>
                                            <span class="title">Curriculum Calendar Management</span>
                                        </div>
                                        <div id="collapseFour" class="collapse" data-parent="#accordionExample">
                                            <div class="card-body">
                                                It is the field where all existing or newly created
                                                courses, all subjects and dates of processing on Hybrid
                                                Learning platform are entered. In this way, a common
                                                teaching schedule can be created.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                                <div class="mt-2 mb-2 ml-auto mr-auto">
                                    <img mat-card-image src="../../assets/images/2X/Group-202.png" alt="value_education"
                                        class="m-0 w-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid bg-primary">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="row py-5 my-4 align-items-center">
                            <div class="col-12 h-100">
                                <owl-carousel-o class="multy-item-carousel HLCPOptions" [options]="HLCPOptions">
                                    <ng-container>
                                        <ng-template carouselSlide>
                                            <div class="row justify-content-center align-items-center">
                                                <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                                                    <div class="section-header">
                                                        <h1 class="d-block title pt-1 pb-1 text-white">
                                                            Hybrid Learning Corporate Operations
                                                        </h1>
                                                        <div class="text-center">
                                                            <img mat-card-image
                                                                src="../../assets/images/2X/Group-566.png"
                                                                alt="value_education" class="w-100px m-auto">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-1 col-lg-1 col-md-1 col-xs-12 col-sm-12 mb-3">
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-3">
                                                    <p class="text-white">
                                                        It is the administrative interface where you can
                                                        perform your institution's user operations and
                                                        exam applications.
                                                        Your teachers and administrators, who are
                                                        declared as Corporate Operations manager by your
                                                        institution, can switch to the Corporate
                                                        Operations screen and perform user transactions
                                                        and assessment and evaluation processes.
                                                    </p>

                                                </div>
                                            </div>

                                        </ng-template>
                                    </ng-container>
                                    <ng-container>
                                        <ng-template carouselSlide class="border">
                                            <div class="row justify-content-center align-items-center">
                                                <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                                                    <div class="section-header">
                                                        <h1 class="d-block title pt-1 pb-1 text-white">
                                                            Usage Reports
                                                        </h1>
                                                        <div class="text-center">
                                                            <img mat-card-image
                                                                src="../../assets/images/2X/Group-566.png"
                                                                alt="value_education" class="w-100px m-auto">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-1 col-lg-1 col-md-1 col-xs-12 col-sm-12 mb-3">
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-3">
                                                    <p class="text-white">
                                                        School administrators can understand various
                                                        gaps & can make the necessary guidelines based
                                                        on the reports on Hybrid Learning Platform, such
                                                        as instant user numbers, active user's ratio,
                                                        number of logins to the system, average usage
                                                        times.
                                                    </p>

                                                </div>
                                            </div>

                                        </ng-template>
                                    </ng-container>


                                </owl-carousel-o>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid bg-theam-body">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row mt-5 align-items-center">
                            <div class="col-12">
                                <div class="text-center">
                                    <img mat-card-image src="../../assets/images/2X/Group-213.png" alt="value_education"
                                        class="w-100px m-auto">
                                </div>
                            </div>

                            <div class="col-12 mb-3">
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1 text-center">COMMUNICATION
                                        APPLICATIONS</h1>
                                </div>
                                <p class="text-center font-weight-500 m-0">
                                    Effective Communication for Time Management
                                </p>
                                <p class="text-center">
                                    Hybrid Learning Communication Applications that enable communication
                                    with teachers, students and parents by institution and / or school
                                    administrators; It includes communication tools, mobile friendly
                                    and notification structure.
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid bg-theam-body">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="row mt-5 align-items-center">

                            <div class="col-12 mb-3">
                                <div class="section-header">
                                    <h1 class="d-block title pt-1 pb-1 text-center theam-color-8">Mobile
                                        Friendly</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 h-100">
                                <owl-carousel-o class="multy-item-carousel mobileAppOptions"
                                    [options]="mobileAppOptions">
                                    <ng-container>
                                        <ng-template carouselSlide>
                                            <div class="row justify-content-center">
                                                <div class="col-5 mb-3">
                                                    <span
                                                        class="d-block h6 font-weight-500 text-center theam-color-333">FOR
                                                        MANAGER</span>
                                                    <p class="text-center theam-color-333">
                                                        You can be aware of the communication processes
                                                        under the roof of the school, and you can take
                                                        precautions whenever you deem necessary.
                                                    </p>
                                                </div>
                                                <div class="col-2 mb-3">
                                                    <div class="divider"></div>
                                                </div>
                                                <div class="col-5 mb-3">
                                                    <span
                                                        class="d-block h6 font-weight-500 text-center theam-color-333">FOR
                                                        TECHER</span>
                                                    <p class="text-center theam-color-333">
                                                        They can review their students' detailed
                                                        homework and mock exam reports and communicate
                                                        with their students.
                                                    </p>
                                                </div>
                                            </div>

                                        </ng-template>
                                    </ng-container>
                                    <ng-container>
                                        <ng-template carouselSlide class="border">
                                            <div class="row justify-content-center">
                                                <div class="col-5 mb-3">
                                                    <span
                                                        class="d-block h6 font-weight-500 text-center theam-color-333">FOR
                                                        STUDENT</span>
                                                    <p class="text-center theam-color-333">
                                                        They can receive homework and study
                                                        notifications, review their reports and contact
                                                        their teachers when necessary in a safe & secure
                                                        manner.
                                                    </p>
                                                </div>
                                                <div class="col-2 mb-3">
                                                    <div class="divider"></div>
                                                </div>
                                                <div class="col-5 mb-3">
                                                    <span
                                                        class="d-block h6 font-weight-500 text-center theam-color-333">FOR
                                                        PARENTS</span>
                                                    <p class="text-center theam-color-333">
                                                        With the messages sent by the institution and
                                                        the notifications sent by our platform, they can
                                                        follow the academic achievements, studies and
                                                        assignments of the students.
                                                    </p>
                                                </div>
                                            </div>

                                        </ng-template>
                                    </ng-container>


                                </owl-carousel-o>
                            </div>

                        </div>
                        <div class="row justify-content-center">

                        </div>
                    </div>
                </div>
            </div>
        </section>