import {AfterViewInit, Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomGrideCelComponent } from 'src/app/custommenu/custom-gride-cel/custom-gride-cel.component';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
// import { QuestionmenuComponent } from 'src/app/question/questionmenu/questionmenu.component';
import { QuezComponent } from 'src/app/question/quez.component';
import { Assignemnt } from 'src/app/shared/assignemnt.model';
import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { QAssignStudentList } from 'src/app/shared/question.model';
import { QuestionService } from 'src/app/shared/question.service';
import { UserService } from 'src/app/shared/user.service';
import { ViewdocumentComponent } from 'src/app/assignment/viewdocument.component';
import { formatDate, NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { EventService } from 'src/app/shared/event.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NotificationService } from 'src/app/shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { CustomTooltipComponent } from 'src/app/custom-tooltip/custom-tooltip.component';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { EditprofileComponent } from 'src/app/user/editprofile.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FeedbackService } from 'src/app/shared/feedback.service';
import { SaveSurveyResultComponent } from 'src/app/survey/save-survey-result.component';
import { HelpComponent } from 'src/app/common/help.component';
import { DateService } from 'src/app/shared/date.service';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { take } from 'rxjs';
import { DairyService } from 'src/app/shared/dairy.service';
import { TimetableService } from 'src/app/shared/timetable.service';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { GoogleChartsModule } from 'angular-google-charts';
import { StudentDairyComponent } from 'src/app/dairy/student-dairy/student-dairy.component';
import { ClasstimetableComponent } from 'src/app/classtimetable/classtimetable.component';
import {TimerButtonComponent} from './../../common-component/timer-button/timer-button.component'
import { CustomDatePipe } from "../../shared/custom-date.pipe";


enum Tabs{
  LiveClass = 0,
  TimeTable = 1,
  SchoolDiary = 2
}

@Component({
    selector: 'app-stu-dashboard',
    templateUrl: './stu-dashboard.component.html',
    styleUrls: ['./stu-dashboard.component.scss'],
    standalone: true,
    imports: [NgIf, GoogleChartsModule, MatTabsModule, NgFor, RouterLink, BsDatepickerModule, FormsModule, TitleCasePipe, StudentDairyComponent, ClasstimetableComponent, TimerButtonComponent, CustomDatePipe]
})
// @Pipe({name: 'convertFrom24To12Format'})
export class StuDashboardComponent implements OnInit, PipeTransform, AfterViewInit   {
  @ViewChild('gradeCharGraph')
  gradeCharGraph: ElementRef;
  rowData: QAssignStudentList;
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowSelection;
  gridApi;
  gridColumnApi;
  gridOptions: any;
  tooltipShowDelay;
  bsModalRef: BsModalRef;
  params: any;
  assignmentList: Assignemnt[];
  watchList: any[] = [];
  listenList: any[] = [];
  readList: any[] = [];
  doList: any[] = [];
  homeWorkList: any[] = [];
  feedbackList: any[] = [];
  surveyList: any[] = [];
  date: any;
  points: number;
  daypoints: number;
  weekpoints: number;
  monthpoints: number;
  assignmentListByDate: Assignemnt[];
  datepickerConfig: BsDatepickerConfig;
  inlineDate: any;

  eventStartTime: any;
  eventEndTime: any;
  attendance: string;

  eventsRowData: any;
  eventRowSelection;
  eventDefaultColDef;
  eventColumnDefs;
  eventGridOptions: any;
  stdAnswerMasterInfo: any;
  answeredInfo: any;
  activeTab: string = "Academics";
  SchoolName: string = "";

  accAssignmentCount: number;
  selAssignmentCount: number;
  lifesAssignmentCount: number;
  outBoxAssignmentCount: number;

  accQstCount: number;
  selQstCount: number;
  lifeQstCount: number;
  outBoxQstCount: number;

  accEventCount: number;
  selEventCount: number;
  lifeEventCount: number;
  outBoxEventCount: number;

  // @ViewChild("chart") chart: ChartComponent;
  graphWidth = 200;
  graphHeight = '100%';
  studentsByGradeCharGraph:any[]=[];
  studentsByGradeCharGraphType: any;
  studentsByGradeCharGraphTitle: any;
  studentsByGradeCharGraphOption = { colors: ['#00b02c', '#ff8000'],  pieHole:0.4 };
  studentsByGradeCharGraphCol: any;

  chartConfig1 = {
    params: {
      chartName  : "",
      viewName: "",
      schoolId : 0,
      userId : 0
    }
  };
  viewName:string = "Year"

  userData:any;

  config = {
    params: {
      studentId: 0,
      dairyDate: formatDate(new Date(), 'dd-MM-yyyy', 'en'),
    }
  };
  todayliveClass:any;
  tomorrowliveClass:any;
  diaryData:any;
  timeTableData:any;
  assignmentData:any=[];



  isShowLearningTime=false;




  constructor(public eventService: EventService, public service: QuestionService,
    public assignedService: AssignemntService, public modalService: BsModalService,
    public userService: UserService, private notificationServ: NotificationService,
    public commonService: CommonService, public feedbacServ: FeedbackService,
    public _DateService:DateService, private sessionServ:SessionStorageService,
    public dairyServ: DairyService,public TTService:TimetableService, ) {
    this.activeTab = "LiveClass";
    this.commonService.RefreshGrid$.subscribe(
      item => {
        this.getassignments();
        this.getQuestion();
      }
    );
    this.datepickerConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, showTodayButton: false, todayPosition: 'center', minMode: 'day', maxDate: new Date() });
    this.inlineDate = new Date();
    this.columnDefs = [
      // {  field: 'AnsweredDate', cellRendererFramework: QuestionmenuComponent, filter:false, width:60,maxWidth: 60 },
      { field: 'Name', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 200, width: 200 },
      { field: 'Description', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 120 },
      { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 200 },
      // { field: 'End Date', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,  minWidth: 200 },
      { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { headerName: "No Of Questions", field: 'NoofQuestions', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, maxWidth: 200, minWidth: 100 },
      {
        field: 'Result', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true,
        maxWidth: 100, minWidth: 100,
        tooltipField: 'Comments',
        tooltipComponentParams: { color: '#ffffff' },
      },

      {
        headerName: "Action",
        field: 'QuestionMasterId', cellRendererFramework: CustomGrideCelComponent, maxWidth: 100, minWidth: 100,
        cellRendererParams: { enableMeetingLink: false, enableButton: true, celName: [{ Name: "Start", "Click": this.startExam, that: this }] },


      },
    ];
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: true,
      floatingFilter: false,
      tooltipComponent: 'customTooltip',
    };
    this.rowSelection = 'single';




    this.eventColumnDefs = [
      { field: 'Description', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { headerName: "Date", field: 'EventDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120 },
      { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      {
        headerName: "Get URL",
        field: 'EventId', cellRendererFramework: CustomGrideCelComponent, maxWidth: 100, minWidth: 100,
        cellRendererParams: { enableMeetingLink: true, enableButton: false, celName: [{ Name: "Join", "Click": this.getURL, that: this }] },
      },

    ];
    this.eventDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: false,
      floatingFilter: false,
    };
  }

  ngOnInit(): void {
    
    this.userData=this.sessionServ.getUserData();
    this.chartConfig1.params.userId = this.userData.UserId;
    this.chartConfig1.params.schoolId = this.userData.SchoolId;
    this.getStudentsByGradeChart();

    this.userService.getMyInfo().subscribe((res: any) => {
      
      this.userService.formData = res;
      
      
      this.attendance = res.StudentInfo.LastAttendended;
      this.SchoolName = res.SchoolList[0].Name;
      // this.userService.formData.ClassMappingList.
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.userService.formData.UserInfo.CroppedProfileFilepath = (res.UserInfo.CroppedProfileFilepath).replace(re, "/");
      }
      this.getStudentFeedback();
      this.getassignments();
      
    });
    this.userService.getstudentpoints().subscribe((res: any) => {
      this.points = res.filter(item => item.Type == 'Total')[0].Points;
      this.daypoints = res.filter(item => item.Type == 'Day')[0].Points;
      this.weekpoints = res.filter(item => item.Type == 'Week')[0].Points;
      this.monthpoints = res.filter(item => item.Type == 'Month')[0].Points;

    });
    
  

    this.getStudentSurvey();
    
    this.getTomorrowEvents();
    this.getTodayEvents();
    this.setTab(Tabs.LiveClass);
    this.getAssingment();
  }
  tabIndex : Tabs = Tabs.LiveClass;
  setTab(tab : Tabs){
    this.tabIndex = tab; 
  }
  ngAfterViewInit() {
    this.getEvents();
    var width = this.gradeCharGraph?.nativeElement?.offsetWidth;
   
    this.graphWidth = width;
    console.log('ngAfterContentInit');
    this.activeTab = "LiveClass";
  }
  
  getassignments() {
    var strDate = this.date.length == 10 ? this.date : formatDate(new Date(), 'dd-MM-yyyy', 'en');
    this.assignedService.getassignedassignmentsbydate(strDate).subscribe((res: any) => {
      let pendingAssignment:any[] ;
      console.log("getassignments");
      console.log(res);
      
      this.accAssignmentCount = res.filter(item => item.IsAcademics == true).length;
      this.selAssignmentCount = res.filter(item => item.IsHumanityLife == true).length;
      this.lifesAssignmentCount = res.filter(item => item.IsSkills == true).length;
      this.outBoxAssignmentCount = res.filter(item => item.IsLearnOutOfBox == true).length;
      if (this.activeTab === "Academics") {
        pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsAcademics == true))
        
        this.assignmentListByDate = res.filter(item => item.IsAcademics == true);
      } else if (this.activeTab === "SEL") {
        pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsHumanityLife == true))

          this.assignmentListByDate = res.filter(item => item.IsHumanityLife == true);
      } else if (this.activeTab === "Life Skills") {
        pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsSkills == true))

        this.assignmentListByDate = res.filter(item => item.IsSkills == true);
      } else if (this.activeTab === "Outside The Box") {
        pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsLearnOutOfBox == true))

        this.assignmentListByDate = res.filter(item => item.IsLearnOutOfBox == true);
      }
      
      this.watchList = this.assignmentListByDate?.filter(item => item.Type == 'Watch');
      this.listenList = this.assignmentListByDate?.filter(item => item.Type == 'Listen');
      this.readList = this.assignmentListByDate?.filter(item => item.Type == 'Read');
      this.doList = this.assignmentListByDate?.filter(item => item.Type == 'Do');
      this.homeWorkList = this.assignmentListByDate?.filter(item => item.Type == 'Home work');

      if (pendingAssignment && pendingAssignment.length > 0) {
        if (pendingAssignment.length == 1) {
          this.notificationServ.ShowWarning('You have ' + pendingAssignment.length.toString() + ' pending assignment');
        }
        else {
          this.notificationServ.ShowWarning('You have ' + pendingAssignment.length.toString() + ' pending assignments');
        }
      }

    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.activeTab === "Academics") {
      this.getQuestion();
    }

  }
  
  getAssingment(){
    let strDate =  formatDate(new Date(), 'dd-MM-yyyy', 'en');
    this.assignedService.getassignedassignmentsbydate(strDate).subscribe((res: any)=> {
      this.assignmentData = res;
     
  
    });
  }
  getQuestion() {

    this.service.getallstudentsquestions().subscribe((res: any) => {
      var pendingQstCount = 0;
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);
        if (res[i].Status == 'New') {
          pendingQstCount = + pendingQstCount;
        }
      }
      this.accQstCount = res.filter(item => item.IsAcademics == true).length;
      this.selQstCount = res.filter(item => item.IsHumanityLife == true).length;
      this.lifeQstCount = res.filter(item => item.IsSkills == true).length;
      this.outBoxQstCount = res.filter(item => item.IsLearnOutOfBox == true).length;
      if (this.activeTab === "SEL") {
        this.rowData = res.filter(item => item.IsHumanityLife == true);
      } else if (this.activeTab === "Life Skills") {
        this.rowData = res.filter(item => item.IsSkills == true);
      } else if (this.activeTab === "Academics") {
        this.rowData = res.filter(item => item.IsAcademics == true);
      } else if (this.activeTab === "Outside The Box") {
        this.rowData = res.filter(item => item.IsLearnOutOfBox == true);
      }
      if (pendingQstCount > 0) {
        if (pendingQstCount == 1) {
          this.notificationServ.ShowWarning('You have ' + pendingQstCount.toString() + ' pending assessment');
        }
        else {
          this.notificationServ.ShowWarning('You have ' + pendingQstCount.toString() + ' pending assessments');
        }
      }
    });

  }
  transform(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'PM' : 'AM';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  onEventReady(params) {
    this.date = new Date();
    this.date = formatDate(this.date, 'dd-MM-yyyy', 'en');
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        this._DateService.getDateTimeFromString(res[i].EventDate, res[i].StartTime);
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);

      }

      this.accEventCount = res.filter(item => item.IsAcademics == true).length;
      this.selEventCount = res.filter(item => item.IsSkills == true).length;
      this.lifeEventCount = res.filter(item => item.IsHumanityLife == true).length;
      this.outBoxEventCount = res.filter(item => item.IsLearnOutOfBox == true).length;
      if (this.activeTab === "SEL") {
        this.eventsRowData = res.filter(item => item.IsHumanityLife == true);
      } else if (this.activeTab === "Life Skills") {
        this.eventsRowData = res.filter(item => item.IsSkills == true);
      } else if (this.activeTab === "Academics") {
        this.eventsRowData = res.filter(item => item.IsAcademics == true);
      } else if (this.activeTab === "Outside The Box") {
        this.eventsRowData = res.filter(item => item.IsLearnOutOfBox == true);
      }
      console.log("his.eventsRowData" );
    console.log(this.eventsRowData );
    });
   
  }
  onSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
    // this.openQuezmodal(selectedRows[0].QuestionMasterId)
  }
  openQuezmodal(QuestionMasterId: number) {
    const initialState = {
      list: [
        { id: QuestionMasterId }
      ]

    };
    this.bsModalRef = this.modalService.show(QuezComponent, { id: CUSTOMCONST.STDASSIGNMENT_MODAL, initialState, class: 'modal-xl', ignoreBackdropClick: true });
  }
  
  startExam(QuestionMasterId, that) {
    var questionData = that.rowData.filter(item => item.QuestionMasterId == QuestionMasterId);
    if (questionData[0].Status == 'Completed') {
      that.notificationServ.ShowError('You already completed this exam!');
    }
    else {
      var sd = questionData[0].StartDate.split('-');
      var ed = questionData[0].EndDate.split('-');
      var examStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + questionData[0].StartTime);
      var examEndTime = new Date(ed[1] + '-' + ed[0] + '-' + ed[2] + ' ' + questionData[0].EndTime);
      this.date = new Date();
      this.date = formatDate(new Date(), 'MM-dd-yyyy HH:mm a', 'en', '+0530');
      var currentTime = new Date(); //new Date(this.date);
      if (currentTime <= examStartTime) {
        that.notificationServ.ShowError('Exam will start at ' + questionData[0].StartDate + ' ' + questionData[0].StartTime);
      }
      else if (currentTime > examEndTime) {
        that.notificationServ.ShowError('Exam date has been expired');
      }
      else {

        if (questionData[0].Status == "New") {
          that.openQuezmodal(QuestionMasterId)
        } else {
          that.notificationServ.ShowError((questionData[0].Status == "Completed") ? 'You already completed this task!' : 'Last submission date has been expired');
        }
      }
    }
  }
  openModel(data) {
    var sd = data.StartDate.split('-');
    var ed = data.EndDate.split('-');
    var examStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + data.StartTime);
    var examEndTime = new Date(ed[1] + '-' + ed[0] + '-' + ed[2] + ' ' + data.EndTime);
    this.date = new Date();
    this.date = formatDate(new Date(), 'MM-dd-yyyy HH:mm a', 'en', '+0530');
    var currentTime = new Date(); //new Date(this.date);
    if (currentTime <= examStartTime) {
      this.notificationServ.ShowError('Assignment will start at ' + data.StartDate + ' ' + data.StartTime);
    }
    
    else {
      const initialState = {
        list: [
          { data: data }
        ]
      };
      this.bsModalRef = this.modalService.show(ViewdocumentComponent, { id: CUSTOMCONST.STDASSIGNMENT_MODAL, initialState, class: 'modal-lg mb-0', ignoreBackdropClick: true });
    }

  }

  onDateChange(e: Event) {
    this.date = e;
    this.date = formatDate(this.date, 'dd-MM-yyyy', 'en');
    this.getassignments();

    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);

      }
      this.eventsRowData = res;
      console.log("this.eventsRowData");
      console.log(this.eventsRowData);
      
    });
  }
  activeTabChange(event) {
    debugger;
    this.activeTab = event.tab.textLabel;

    if(this.activeTab =="LiveClass"){
      this.getTomorrowEvents();
      this.getTodayEvents();
    }else if(this.activeTab =="TimeTable"){
        this.getclasstimetablelistinfo(this.userService.formData.StudentInfo.ClassId);
    }else if(this.activeTab =="SchoolDiary"){
      this.config.params.studentId = this.userData.UserId;
        this.getStudentDiary();
    }


    this.getassignments();
    console.log(this.activeTab);
    
    this.getQuestion();
    
    
    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);

      }
      
      this.accEventCount = res.filter(item => item.IsAcademics == true).length;
      this.selEventCount = res.filter(item => item.IsSkills == true).length;
      this.lifeEventCount = res.filter(item => item.IsHumanityLife == true).length;
      this.outBoxEventCount = res.filter(item => item.IsLearnOutOfBox == true).length;
      if (this.activeTab === "SEL") {
        this.eventsRowData = res.filter(item => item.IsHumanityLife == true);
      } else if (this.activeTab === "Life Skills") {
        this.eventsRowData = res.filter(item => item.IsSkills == true);
      } else if (this.activeTab === "Academics") {
        this.eventsRowData = res.filter(item => item.IsAcademics == true);
      } else if (this.activeTab === "Outside The Box") {
        this.eventsRowData = res.filter(item => item.IsLearnOutOfBox == true);
      }
    });
  }
  
  getURL(eventId, that) {
    // var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    // var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    // var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    // var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    // var w = 1100;
    // var h = 800;
    // var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    // var top = ((height / 2) - (h / 2)) + dualScreenTop;
    //  window.open(
    //   params.data.ZoomUrl,
    //   "Hybrid Learning",
    //   "scrollbars=yes,resizable=yes, width=" + w + ", height=" + h + ", top=" + top + ", left="+left
    // );

    var eventData = that.eventsRowData.filter(item => item.EventId == eventId);

    //  this.eventStartTime = new Date(eventData[0].EventDate +' '+ eventData[0].StartTime);
    //  this.eventEndTime = new Date(eventData[0].EventDate +' '+ eventData[0].EndTime);
    var sd = eventData[0].EventDate.split('-');
    var eventStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + eventData[0].StartTime);
    var eventEndTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + eventData[0].EndTime);

    this.date = new Date();
    this.date = formatDate(new Date(), 'dd-MM-yyyy HH:mm', 'en');
    var currentTime = new Date();
    if (currentTime <= eventStartTime) {
      that.notificationServ.ShowError('Class will start at ' + eventData[0].EventDate + ' ' + eventData[0].StartTime);
    }
    else if (currentTime > eventEndTime) {
      that.notificationServ.ShowError('This class has been expired');
    }
    else {
      that.eventService.saveattendece(eventId).subscribe((res: any) => {

      });
      if (eventData && eventData[0].ZoomUrl) {
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
        var w = 1100;
        var h = 800;
        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        if(typeof window != 'undefined')
        window.open(
          eventData[0].ZoomUrl,
          "Hybrid Learning",
          "scrollbars=yes,resizable=yes, width=" + w + ", height=" + h + ", top=" + top + ", left=" + left
        );
      }
      else {

      }
    }
  }
  editProfile(UserId) {
    const initialState = {
      list: [
        { id: UserId }
      ]

    };
    this.bsModalRef = this.modalService.show(EditprofileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });

  }
  getStudentFeedback() {
    var config = {
      params: {
        userId: this.userService.formData.UserInfo.UserId,
        schoolId: this.userService.formData.UserInfo.SchoolId,
      }
    };
    this.feedbacServ.getStudentFeedbacks(config).subscribe((res: any) => {
      this.feedbackList = res;
    });
  }
  getStudentSurvey() {
    this.feedbacServ.getstudentsurveys().subscribe((res: any) => {
      this.surveyList = res;

      if (this.surveyList.length > 0) {
        //this.surveyList[0].SurveyId
        this.openSSRModal(res);
      }
    });
  }
  mobileAppOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    margin: 10,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    navText: ['<img mat-card-image src="../../assets/images/2X/Group-296.png" alt="value_education" class="m-0 w-100">', '<img mat-card-image src="../../assets/images/2X/Group-295.png" alt="value_education" class="m-0 w-100">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  }
  openSSRModal(surveyData) {
    const initialState = {
      list: [
        { data: surveyData }
      ]

    };
    this.bsModalRef = this.modalService.show(SaveSurveyResultComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  openHelpTooltip(tooltipName:any){
    const initialState = {
      list: [
        { name: tooltipName }
      ]

    };
    this.bsModalRef = this.modalService.show(HelpComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  
  getStudentsByGradeChart(){
    this.chartConfig1.params.viewName = this.viewName;
    this.chartConfig1.params.chartName = "AllCategory";
    this.studentsByGradeCharGraph = [];
    this.userService.getstdNewChartInfo(this.chartConfig1).subscribe((res: any) => {
      res.forEach(element => {
        
        var arrObj = [element.Key, element.ValueInt];
        this.studentsByGradeCharGraph.push(arrObj);
        if(element.ValueInt > 0){
          this.isShowLearningTime = true;
        }
      });
      this.studentsByGradeCharGraphTitle = "Students By Grade";
      this.studentsByGradeCharGraphType = "PieChart";
      // console.log(this.studentsByGradeCharGraph);
      // debugger
      
    });
  }
  getEventsByDate(){
    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);
      }
      this.eventsRowData = res;
      
    });
  }
  getEvents(){
    this.eventService.getEventsByParam(this.config).subscribe((res: any) => {
      this.eventsRowData = res;
    });
  }
  getTodayEvents(){
    let todayDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');

    this.eventService.getEventsByDate(todayDate).pipe(
      take(3)
      ).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);

      }
      this.todayliveClass = res;
      
    });
  }
  getTomorrowEvents(){
    let todayDate = new Date();
    let tomorrowDate = formatDate(todayDate.setDate(todayDate.getDate() + 1), 'dd-MM-yyyy', 'en');
    
    this.eventService.getEventsByDate(tomorrowDate).subscribe((res: any) => {
      for (var i = 0; i < res.length; i++) {
        res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
        res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);

      }
      this.tomorrowliveClass = res;      
    });
  }
  getStudentDiary() {
    this.dairyServ.getStudentDairyViewInfo(this.config).subscribe((res: any) => {
      console.log(res);
      this.diaryData = res.StudentDairyInfo;

    })
  }
  getclasstimetablelistinfo(classId){
    this.TTService.getclasstimetablelistinfo(classId).subscribe((res:any)=>{
      this.timeTableData = res;
    });
  }
  startClass(event: any) {
    localStorage.setItem('eventData',JSON.stringify(event));
    localStorage.setItem('room_id',event.RoomId);
    window.open('live-class', '_blank', 'noopener, noreferrer');
  }
  
}
