import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { QuestionmenuComponent } from 'src/app/question/questionmenu/questionmenu.component';
import { UserService } from 'src/app/shared/user.service';
import { CommonService } from 'src/app/shared/common.service';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { EditprofileComponent } from 'src/app/user/editprofile.component';
import { HelpComponent } from 'src/app/common/help.component';
import { EditProfileComponent } from '../edit-profile.component';
import { faAdd, faDeleteLeft, faEdit, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from 'src/app/shared/notification.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-profile-test-page',
    templateUrl: './profile-test-page.component.html',
    styleUrls: ['./profile-test-page.component.scss'],
    standalone: true,
    imports: [NgFor, FontAwesomeModule]
})
export class ProfileTestPageComponent implements OnInit {
  attendance: string;
  SchoolName: string = "";
  bsModalRef: BsModalRef;
  profileList: any;
  profilrtypeList: any = [];
  profileTypeInfo: any = [];
  studentProfileListInfo: any = [];
  addIcon = faAdd;
  editIcon = faPen;
  deleteIcon = faTrash;
  studentEmpathyInfo:any;
  constructor(public service: UserService, public modalService: BsModalService, public commonService: CommonService,private notificationServ:NotificationService) {
    this.commonService.RefreshGrid$.subscribe(
      item => {

        this.getMyInfo();

      }
    );
  }

  ngOnInit(): void {
    // 
    this.getMyInfo();



  }
  getMyInfo() {
    this.service.getMyInfo().subscribe((res: any) => {
      this.service.formData = res;
      console.log(res);

      this.attendance = res.StudentInfo.LastAttendended;
      this.SchoolName = res.SchoolList[0].Name;
      // this.service.formData.ClassMappingList.
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.service.formData.UserInfo.CroppedProfileFilepath = (res.UserInfo.CroppedProfileFilepath).replace(re, "/");
      }

      this.getStudentProfileInfoList(res.UserInfo.UserId);
      this.getStudentEmpathyInfoList(res.UserInfo.UserId)
    });
  }
  getStudentProfileInfoList(id: number = 0) {
    this.service.getStudentProfileInfoList(id).subscribe((res: any) => {

      res.ProfileTypeInfo.forEach((element, index) => {
        element.Default = [{
          "StudentProfileDataId": 0,
          "Name": "",
          "Rating": 0,
          "ProfileTypeId": 0,
          "IsActive": false,
          "CreatedOn": null
      },{
        "StudentProfileDataId": 0,
        "Name": "",
        "Rating": 0,
        "ProfileTypeId": 0,
        "IsActive": false,
        "CreatedOn": null
    },{
      "StudentProfileDataId": 0,
      "Name": "",
      "Rating": 0,
      "ProfileTypeId": 0,
      "IsActive": false,
      "CreatedOn": null
  },{
    "StudentProfileDataId": 0,
    "Name": "",
    "Rating": 0,
    "ProfileTypeId": 0,
    "IsActive": false,
    "CreatedOn": null
}];
          element.StudentProfileList = res.StudentProfileListInfo.filter(item => {
              if(item.ProfileTypeId == element.Id){
                  element.Default.pop();
                  return element
              }
          });
       
       console.log(element)
        });
      this.profileTypeInfo = res.ProfileTypeInfo;
      this.studentProfileListInfo = res.StudentProfileListInfo;
    })
  }

  editProfile(UserId) {
    const initialState = {
      list: [
        { id: UserId }
      ]

    };
    this.bsModalRef = this.modalService.show(EditprofileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });


  }
 
  openHelpTooltip(tooltipName: any) {
    const initialState = {
      list: [
        { name: tooltipName }
      ]

    };
    this.bsModalRef = this.modalService.show(HelpComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  EditprofileProfile(typeId: number, typeName: string) {
  
    let temp = this.studentProfileListInfo.filter(item => item.ProfileTypeId == typeId );
    if(temp.length >=4){
      this.notificationServ.ShowError("You have exceed maximam limit")
    }
    else{
      const initialState = {
        list: [
          {
            id: typeId,
            name: typeName,
            action:'Add'
          }
        ]
      };
      this.bsModalRef = this.modalService.show(EditProfileComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-md mb-0', ignoreBackdropClick: true });
      
    }
    // this.getStudentTravelInfo();
  }
   
  Editprofile(StudentProfileDataId:number,typeName: string, studentProfileObj) {
    const initialState = {
      list: [
        { 
          SPDId: StudentProfileDataId, 
          data:studentProfileObj,
          name:typeName,
          action:'Edit'
        }
      ]

    };
    this.bsModalRef = this.modalService.show(EditProfileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  deleteProfile(StudentProfileDataId:number,studentProfileObj) {
    this.service.studentProfileInfoView.StudentProfileInfo = studentProfileObj;
    this.service.studentProfileInfoView.StudentProfileInfo.IsActive = false;
    this.service.saveStudentProfileInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo("Delete Successfull");
        this.commonService.CallOnRefreshGrid();
      }
      
    });
  }
  getStudentEmpathyInfoList(id:number){
    this.service.getStudentEmpathyInfoList(id).subscribe((res:any)=>{
     this.studentEmpathyInfo=res;
      console.log(res);
      
    })
  }
}
