//import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule } from '@angular/forms';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-custom-controls',
    templateUrl: './custom-controls.component.html',
    styleUrls: ['./custom-controls.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatCheckboxModule, TimepickerModule, FormsModule, PopoverModule, DatePipe]
})
export class CustomControlsComponent implements OnInit {
  @Output() Editclick = new EventEmitter<string>();
  public params: any;
  controlList : any;
  IsEnableCheckbox:boolean;
  CheckboxChecked: boolean;
  IsEnableStartTime:boolean=false;
  StartTime:any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params): void {
    this.params = params.value;
    
    if(params.colDef.headerName === "ClassId"){
      this.CheckboxChecked = (params.value.split("_")[1] == "true");
      this.IsEnableCheckbox = params.enableCheckbox
      this.controlList = params.controlList;
    }
    if(params.colDef.headerName === "MultiEventId"){
      this.CheckboxChecked = (params.value.split("_")[1] == "true");
      this.IsEnableCheckbox = params.enableCheckbox
      this.controlList = params.controlList;
    }
    if(params.colDef.headerName === "Start Time"){
      this.IsEnableStartTime = true;
      this.StartTime = params.value;
    }
    
    
    // params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    // this.onSortChanged();
    // this.colId = this.params.column.getColId().replace(/\s/g, '');

}

}
