<div class="modal-dialog  modal-xl m-0">
   
    <div class="modal-content">
        <form novalidate #form="ngForm" (submit)="onSave(form)">
            <div class="modal-header bg-theam p-1">
                <h3 class="modal-title h3 m-1">{{'UserEdit' | translate}}</h3>
            </div>
            <div class="modal-body p-2">
            
                <div class="row form-row m-0">
                    
                    <div class="card-body" *ngIf="editimageof === 'profilePic'">
                        <input type="file" (change)="fileChangeEvent($event)" />
                        <div class="row" style="margin-top: 15px;">
                            <div class="text-center col-md-8 w-100">
                                <image-cropper 
                                [imageChangedEvent]="imageChangedEvent" 
                                [maintainAspectRatio]="true" 
                                [aspectRatio]="4 / 4"
                                [resizeToWidth]="256" 
                                format="png" 
                                (imageCropped)="imageCropped($event)" 
                                (imageLoaded)="imageLoaded($event)"
                                (cropperReady)="cropperReady()" 
                                (loadImageFailed)="loadImageFailed()"
                                [roundCropper]=true></image-cropper>
                            </div>
                            <div class="text-center col-md-4">
                                <img [src]="croppedImage" class="img-circle" />
                            </div>
                        </div>
                      </div>

                      <div class="card-body" *ngIf="editimageof === 'coverImage'">
                        <input type="file" (change)="fileChangeEvent($event)" />
                        <div class="row" style="margin-top: 15px;">
                            <div class="text-center col-md-8 w-100">
                                <image-cropper 
                                [imageChangedEvent]="imageChangedEvent" 
                                [maintainAspectRatio]="true" 
                                [aspectRatio]="10 / 3.75"
                                [resizeToWidth]="256" 
                                format="png" 
                                (imageCropped)="coverImageCropped($event)" 
                                (imageLoaded)="coverImageLoaded($event)"
                                (cropperReady)="cropperReady()" 
                                (loadImageFailed)="loadImageFailed()"
                                [roundCropper]=false></image-cropper>
                            </div>
                            <div class="text-center col-md-4">
                                <img [src]="croppedImage" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                        
            
            </div>
            <div class="modal-footer p-1">
                <button type="submit" class="btn btn-success">Save</button>
                <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)" aria-label="Close">Close</button>
            </div>
        </form>
    </div>
  </div>
