import { Component, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import {
  faCalendarDays,
  faCalendarTimes,
  faOutdent,
} from "@fortawesome/free-solid-svg-icons";
import { SidebarService } from "../shared/sidebar.service";
import { map } from "rxjs/operators";
import { RouterLinkActive, RouterLink } from "@angular/router";
import { NgFor, NgIf, NgClass } from "@angular/common";

declare var bootstrap: any; // Ensure Bootstrap is correctly included

@Component({
  selector: "app-teacher-sidebar",
  templateUrl: "./teacher-sidebar.component.html",
  styleUrls: ["./teacher-sidebar.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, RouterLinkActive, RouterLink, NgClass],
})
export class TeacherSidebarComponent implements AfterViewInit {
  @ViewChild("tooltipElement", { static: true }) tooltipElement!: ElementRef;

  // Icons
  calendarIcon = faCalendarTimes;
  timeIcon = faCalendarDays;
  leaveIcon = faOutdent;

  // State variables
  Isfliph = false;
  menuItems: any[] = [];
  IsOpend: number | null = null;
  IsCollapse = false;
  subMenuOpen: number | null = null;
  collapseCondition: string = "";

  constructor(private sidebarSer: SidebarService) {}

  ngAfterViewInit(): void {
    // Initialize tooltips
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(
      (tooltipTriggerEl: any) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  }

  ngOnInit(): void {
    this.getSidebar();
  }

  getSidebar() {
    this.sidebarSer._sidebarMenuData
      .pipe(
        map((res: any) => {
          return res.map((element: any) => {
            let data = element.ChildMenuList;
            data.Condition = "";
            data.forEach((e: any, index: number) => {
              data.Condition +=
                index < data.length - 1
                  ? `TRV${e.Code}.isActive || `
                  : `TRV${e.Code}.isActive`;
            });
            return element;
          });
        })
      )
      .subscribe((res) => {
        this.menuItems = res;
      });
  }

  menuChange(item: number) {
    if (this.IsOpend === item) {
      this.IsOpend = item;
      this.IsCollapse = !this.IsCollapse;
    } else {
      this.IsOpend = item;
      this.IsCollapse = true;
    }
  }

  subMenuChange(item: number) {
    this.subMenuOpen = item;
  }

  toggleSidebar() {
    this.Isfliph = document.querySelector(".sidebar.fliph") === null;
  }
}
