<div class="container-fluid bg-light pb-3">
    <section id="assesments">
        <div class="row">
            <div class="col-12">
                <div class="container-fluid p-0">
                    <mat-tab-group animationDuration="0ms" class="bg-transparent border-0 d-flex"
                    (selectedTabChange)="activeTabChange($event)">
                        <mat-tab label="Profile">
                            <ng-template mat-tab-label>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <label class="m-0">Profile</label>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="rounded">
                                <div class="tab-content bg-white mt-3">
                                    <div class="profile">
                                        <div class="row m-0 pt-3 pb-2">

                                            <div class="col-xl-2 col-md-2 col-sm-2 pr-0 profile-pic">
                                                <section class="">
                                                    <div class="profile-dp"><img src="../../../assets/images/common/homework.jpg" alt=""></div>
                                                    <div class="change-pic pt-1">
                                                        <img src="../../../assets/images/icons/svg/camera_green.svg" alt="">
                                                    </div>
                                                </section>
                                            </div>

                                            <div class="col-xl-10 col-md-10 col-sm-10">
                                                <section class="profile-inpute-group">
                                                    <div class="row">
                                                        <div class="col-6 mb-3">
                                                            <label for="">First Name</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.FirstName">
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Last Name</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.Surname">
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Gender</label>
                                                            <select name="" id="" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.GenderId">
                                                                <option value="1">Male</option>
                                                                <option value="2">Female</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Date Of Birth(DOB)</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.DateOfBirth">
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Email ID</label>
                                                            <div class="card">
                                                                <div class="d-flex justify-content-between align-items-center">
                                                                    <input type="email" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.Email">
                                                                   <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                                                                </div>
                                                        </div>
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Mobile Number</label>
                                                            <!-- <input type="number">
                                                            <img src="../../../assets/images/icons/svg/plus_green.svg" alt=""> -->
                                                            <div class="card">
                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                        <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.Mobile">
                                                                       <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">School</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.SchoolId">
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <label for="">Branch</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.BranchId">
                                                        </div>
                                                        <div class="col-4 mb-3">
                                                            <label for="">State</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.State">
                                                        </div>
                                                        <div class="col-4 mb-3">
                                                            <label for="">City</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.City">
                                                        </div>
                                                        <div class="col-4 mb-3">
                                                            <label for="">Postal No</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.PostalNo">
                                                        </div>
                                                        <div class="col-12 mb-3">
                                                            <label for="">Address</label>
                                                            <input type="text" class="form-control form-control-lg" [(ngModel)]="service.formData.UserInfo.Address">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <button type="button" [disabled]="!service.formData.UserInfo.IsEditAllowed" class="btn btn-success col-2" (click)="saveProfile()" type="button">Update</button>                            
                                                    </div>                                                                                                                                                            
                                                </section>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Persona">
                            <ng-template mat-tab-label>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <label class="m-0">Persona</label>

                                    </div>
                                </div>
                            </ng-template>
                            <div class="rounded mt-4">
                                <div class="tab-content">
                                    <div class="persona">
                                        <div class="row">
                                            <!-- <p>{{ profileTypeInfo | json }}</p> -->
                                            <div class="bio-group bg-white">
                                                <h3 class="m-0">Bio</h3>
                                                <p class="border p-2 shadow w-50 rounded">{{this.service.formData.UserInfo.About}}</p>
                                            </div>
                                            <div class="col-6 mb-3" *ngFor="let profileType of profileTypeInfo">
                                                <!-- <div class="card">
                                                    <div class="card-header">
                                                        <div class="d-flex justify-content-between">
                                                            {{profileType.Name}} <span><img src="../../../assets/images/icons/svg/plus_green.svg" (click)="EditprofileProfile(profileType.Id, profileType.Name)"></span>
                                                        </div>
                                                    </div>
                                                    <ul class="list-group list-group-flush">
                                                        <div class="card border-0" *ngFor="let studentProfileList of profileType.StudentProfileList">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="d-flex justify-content-between align-items-center w-100 m-1">
                                                                    <p type="text" class="form-control form-control-lg m-0" >{{studentProfileList.Name}}</p>
                                                                    <span class="action-group">
                                                                        <fa-icon class="mr-2 text-primary" [icon]="editIcon"
                                                                            (click)="Editprofile(studentProfileList.StudentProfileDataId, profileType.Name, studentProfileList)"></fa-icon>
                                                                        <fa-icon class="text-danger" [icon]="deleteIcon"
                                                                            (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></fa-icon>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                         </div>
                                                        <li class="list-group-item"
                                                            *ngFor="let studentProfileList of profileType.StudentProfileList">

                                                            <div class="card border-0">
                                                                <div class="d-flex justify-content-between align-items-center">
                                                                    <div class="d-flex justify-content-between align-items-center w-100 border">
                                                                        <p type="text" class="form-control form-control-lg m-0 border-0" >{{studentProfileList.Name}}</p>
                                                                        <span class="action-group">
                                                                            <fa-icon class="mr-2 text-primary" [icon]="editIcon"
                                                                                (click)="Editprofile(studentProfileList.StudentProfileDataId, profileType.Name, studentProfileList)"></fa-icon>
                                                                            <fa-icon class="text-danger" [icon]="deleteIcon"
                                                                                (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></fa-icon>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        </li>
                                                        <li class="list-group-item" *ngFor="let defaultvalue of profileType.Default">
                                                        </li>

                                                    </ul>
                                                </div> -->
                                                <div class="">
                                                    <div class="d-flex justify-content-between">
                                                        {{profileType.Name}} <span><img src="../../../assets/images/icons/svg/plus_green.svg" (click)="EditprofileProfile(profileType.Id, profileType.Name)"></span>
                                                    </div>
                                                    <div  *ngFor="let studentProfileList of profileType.StudentProfileList">
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="d-flex justify-content-between align-items-center w-100 my-1">
                                                                <div class="slider-container" *ngIf="profileType.Code === 'Personality'">
                                                                    <span class="slider-label">{{studentProfileList.Name?.split('/')[0]}}</span>
                                                                    <mat-slider
                                                                      [disabled]="isReadOnly"
                                                                      class="slider"
                                                                      [min]="0"
                                                                      [max]="10"
                                                                      [step]="1"
                                                                      thumbLabel="always"       
                                                                      [(ngModel)]="studentProfileList.Rating"
                                                                      >
                                                                    </mat-slider>
                                                                    <span class="slider-label">{{studentProfileList.Name?.split('/')[1]}}</span>
                                                                    <!--<mat-icon *ngIf="isReadOnly">room</mat-icon>-->
                                                                    <!-- <span *ngIf="isReadOnly"> {{ studentProfileList.Rating }} </span> -->
                                                                </div>
                                                                <input class="form-control form-control-sm" type="text" *ngIf="profileType.Code !== 'Personality' && !isReadOnly" class="form-control form-control-lg m-0" name="Name"  
                                                                [(ngModel)]="studentProfileList.Name"/>
                                                                <p type="text" class="form-control form-control-lg m-0" *ngIf="profileType.Code !== 'Personality' && isReadOnly">{{studentProfileList.Name}}</p>
                                                                <span class="action-group ">
                                                                    <fa-icon class="text-danger" [icon]="deleteIcon"
                                                                        (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></fa-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <button type="button" class="btn btn-success col-2" (click)="editPersona()" type="button">Edit</button>
                                            <div class="col-1"></div>
                                            <button type="button" class="btn btn-success col-2" (click)="savePersona()" type="button">Update</button>                            
                                        </div>                                     
                                    </div>

                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Empathy-Mapping">
                            <ng-template mat-tab-label>
                                <div class="m-0"> 
                                    <div class="d-flex align-items-center">                                                     
                                        <label class="m-0">Empathy Mapping</label>                                                                                             
                                    </div>
                                </div>
                            </ng-template>
                            <div class="rounded mt-4">
                                <div class="tab-content">
                                    <div class="empathy-mapping">
                                        <div id="profileLayout" class="col-12 col-xl-12 col-md-12 col-sm-12 p-0">
                                            <div class="d-md-block d-xs-none">
                                                <div class="shadow">                                                    
                                                    <div class="Row">
                                                        <div>
                                                            <span><img src="../../../assets/images/icons/svg/plus_green.svg" (click)="AddEmpathy()"></span>
                                                        </div>      
                                                        <div class="col-md-12 p-0">
                                                            <ag-grid-angular #agGrid
                                                            style="width: 100%; height:calc(100vh - 200px); min-height:200px"
                                                            class="ag-theme-alpine"
                                                            id="myGrid"
                                                            [gridOptions]="GridOptions"
                                                            [columnDefs]="ColumnDefs"
                                                            [defaultColDef]="DefaultColDef"
                                                            [rowSelection]="RowSelection"
                                                            [rowData]="RowData"
                                                            (selectionChanged)="onEmpathySelectionChanged($event)"
                                                            (gridReady)="onGridReady($event)"                                                            
                                                            >
                                                            </ag-grid-angular>                                                            
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <div class="profile-group d-flex">
                                                                <div class="Profile-background">
                                                                    <img src="../../../assets/images/background/Profile-background1.svg" alt="">
                                                                </div>
                                                                <div class="profile-pic">
                                                                    <img src="'../../../assets/images/user/default.png" alt="">
                                                                </div>
                                                                <div class="profile-con-top">
                                                                    <div class="w-50 text-center">
                                                                        <div class="container-singlerow">
                                                                            <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Think_Feel.split('\n')">
                                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                                  {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                                </mat-card-content>
                                                                              </mat-card>                                             
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="text-top white-font">                                                                        
                                                                    <h4>Think & Feel?</h4>                                                                
                                                                </div>
                                                                <div class="profile-con-bottom">
                                                                    <div class="w-50 text-center">
                                                                        <div class="container-singlerow">
                                                                            <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Say_Do.split('\n')">
                                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                                    {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                                </mat-card-content>
                                                                            </mat-card>                                             
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="text-bottom white-font">                                                                        
                                                                    <h4>Say & Do</h4>                                                                
                                                                </div>
                                                                <div class="profile-con-left">
                                                                    <div class="w-50 text-center">
                                                                        <div class="container-singlerow">
                                                                            <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Hear.split('\n')">
                                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                                    {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                                </mat-card-content>
                                                                            </mat-card>                                             
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="text-left white-font">                                                                        
                                                                    <h4>Hear</h4>                                                                
                                                                </div>
                                                                <div class="profile-con-right">
                                                                    <div class="w-50 text-center">
                                                                        <div class="container-singlerow">
                                                                            <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.See.split('\n')">
                                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                                    {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                                </mat-card-content>
                                                                            </mat-card>                                             
                                                                        </div>
                                                                    </div>                                                                    
                                                                </div>
                                                                <div class="text-right white-font">                                                                        
                                                                    <h4>See</h4>                                                                
                                                                </div>
                                                                
                                                            </div>

                                                        </div>
                                                        <div class="col-6 pe-0 pggroup">
                                                            <div class=" text-center border p-4 point">
                                                                <h4 class="text-center m-0 white-font">Pains</h4>
                                                                <div class="container-singlerow">
                                                                    <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Pains.split('\n')">
                                                                        <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                            {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                        </mat-card-content>
                                                                    </mat-card>                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 ps-0 pggroup">
                                                            <div class="text-center border p-4 gain">
                                                                <h4 class="text-center m-0 white-font">Gains</h4>
                                                                <div class="container-singlerow">
                                                                    <mat-card class="empathy-card" *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Gains.split('\n')">
                                                                        <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                            {{EmpathyInfo.length > 40 ? (EmpathyInfo | slice:0:40) : EmpathyInfo}}
                                                                        </mat-card-content>
                                                                    </mat-card>                                             
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-md-none d-sm-block mobile-view">
                                                <div class="shadow">
                                                    <div class="row">
                                                        <div class="col-12 p-3">
                                                            <div class="profile-pic-sm-md text-center border m-auto" >
                                                                <img src="'../../../assets/images/user/default.png" alt="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 think_feel">
                                                                    <h1 class="text-center m-0">Think and Feel</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content>
                                                                                {{EmpathyInfo.Think_Feel}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 say_do">
                                                                    <h1 class="text-center m-0">Say & Do</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content>
                                                                                {{EmpathyInfo.Say_Do}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 hear">
                                                                    <h1 class="text-center m-0">Hear</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content matTooltip="EmpathyInfo">
                                                                                {{EmpathyInfo.Hear}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 see">
                                                                    <h1 class="text-center m-0">See</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content>
                                                                                {{EmpathyInfo.See}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 point">
                                                                    <h1 class="text-center m-0">Point</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content>
                                                                              {{EmpathyInfo.Pains}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-center border">
                                                                <div class="text-center border p-4 gain">
                                                                    <h1 class="text-center m-0">Gains</h1>
                                                                    <div class="container-singlerow">
                                                                        <mat-card class="empathy-card" *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                                            <mat-card-content>
                                                                                {{EmpathyInfo.Gains}}
                                                                            </mat-card-content>
                                                                          </mat-card>                                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </mat-tab>

                    </mat-tab-group>
                    <!-- <div class="search-group">
                        <div class="d-flex align-items-center">
                            <span><img src="../../assets/images/icons/Search.png" alt=""></span>
                            <input type="text">
                        </div>
                    </div>  -->
                </div>
            </div>
        </div>
    </section>
</div>

<!-- <div class="container-fluid bg-light pb-2">
    <div class="row m-0 pt-2 pb-2">

        <div class="col-xl-2 col-md-2 col-sm-2 pr-0 profile-pic">
            <section class="">
                <div class="profile-dp"><img src="../../../assets/images/common/homework.jpg" alt=""></div>
                <div class="change-pic pt-1">
                    <img src="../../../assets/images/icons/svg/camera_green.svg" alt="">
                </div>
            </section>
        </div>

        <div class="col-xl-10 col-md-10 col-sm-10">
            <section class="profile-inpute-group">
                <div class="row">
                    <div class="col-6 mb-3">
                        <label for="">First Name</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Last Name</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Gender</label>
                        <select name="" id="">
                            <option value="">Male</option>
                            <option value="">Female</option>
                        </select>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Date Of Birth(DOB)</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Email ID</label>
                        <div class="card">
                            <div class="d-flex justify-content-between align-items-center">
                                <input type="email" class="w-100">
                               <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                            </div>
                    </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Mobile Number</label>
                        <div class="card">
                                <div class="d-flex justify-content-between align-items-center">
                                    <input type="text" class="w-100">
                                   <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                                </div>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">School</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Branch</label>
                        <input type="text">
                    </div>
                    <div class="col-12">
                        <label for="">Address</label>
                        <input type="text">
                    </div>
                </div>
            </section>
        </div>
    </div>
</div> -->
