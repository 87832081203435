import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditASSDocComponent } from '../fileManagement/edit-assdoc.component';
import { CUSTOMCONST } from '../shared/Const';
import { IDropdownSettings,
  // NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { SchoolSettingService } from '../shared/schoolsetting.service';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';



@Component({
    selector: 'app-school-setting',
    templateUrl: './school-setting.component.html',
    styleUrls: ['./school-setting.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgFor, NgMultiSelectDropDownModule, TranslateModule]
    imports: [FormsModule, NgFor, TranslateModule]

})
export class SchoolSettingComponent implements OnInit {
  dropdownSettings:IDropdownSettings;
  challengeInfo:any;
  schoolCategoryId:any;
  purposeOfPostIdList:any;
  schoolCategoryList:any;
  stateList:any;
  purposeOfPostInfo:any;
  stateInfo: any;


  constructor(public service :SchoolSettingService,private modalService: BsModalService,public commonService:CommonService,private notificationServ:NotificationService) {

   }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.getSchoolSettingInfo();
  }



  getSchoolSettingInfo(){
    this.service.getSchoolSettingInfo(0,0).subscribe((res : any) => {
      console.log(res);
      this.service.formData = res;
      this.schoolCategoryList = res.SchoolCategoryInfo;
      this.stateList = res.StateInfo;
      this.challengeInfo = res.ChallengeInfo;
      this.purposeOfPostInfo =res.PurposeOfPostInfo;
    });
  }
  saveDocument(){
    this.service.saveSchoolSettingInfo().subscribe((res:any)=>{
    console.log(res);
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully Saved!');
        // formData: new SchoolSettingInfo();
        this.service.formData= res;
      }


    })
  }

onItemSelect(item: any,type:string) {
  if(type == 'ChallengeIdList' && this.service.formData.SchoolSettingInfo.ChallengeIdList){

    var ids = item.Id;
    for(var i=0;i<this.service.formData.SchoolSettingInfo.ChallengeIdList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.SchoolSettingInfo.ChallengeIdList[i].Id;
    }
  }
}
onSelectAll(items: any,type:string) {
  if(type == 'ChallengeIdList'){

    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }

  }
}
}
