// import { Tracing } from "trace_events";

export class LeaveData {

}

export class LeaveInfo {
    AcademicYearId: number;
    AssignedUserId: number;
    Comments: string;
    EndDate: string;
    IsActive: boolean;
    IsFullDayLeave: boolean;
    LeaveId: number;
    LeaveStatusId: number;
    LeaveTypeId: number;
    NDays: number;
    Reason: string;
    StartDate: string;
    UserId: number;
    UserRole: string;
}
export class LeaveResultList {
    ClassId: number;
    Code: string;
    Description: String;
    Id: Number;
    IsActive: boolean;
    Name: string;
    NameDK: string;
    ParentGroupId: number;
    SubjectId: number;
}
export class LeaveTypeList {
    Id: number;
    Code: string;
    Name: string;
    NameDK: string;
    Description: string;
    IsActive: boolean;
    ParentGroupId: number;
    SubjectId: number;
    ClassId: number;
}
export class LeaveViewData {
    LeaveInfo: LeaveInfo;
    LeaveResultList: LeaveResultList;
    LeaveTypeList: LeaveTypeList;
    IsSaved: boolean;
    Message: string;
}
export class LeaveStatusInfo {
    LeaveId: number;
    Leavestatus: string;
    IsSaved: boolean;
    Message: string;
}