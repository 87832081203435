import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../shared/common.service';
@Component({
    selector: 'app-qrcoderedirect',
    template: `<div class="d-flex flex-column justify-content-center align-items-center  " style="height:calc(100vh - 300px)">
  <h1>You are redirecting to another page</h1>
  <p>Please do not refresh page or close page</p>
</div>`,
    standalone: true
})
export class QrcoderedirectComponent {

  constructor(public commonServ:CommonService, private activatedRoute: ActivatedRoute, private actRoute: ActivatedRoute) {
    
    this.activatedRoute.queryParams.subscribe((params) => {
      this.commonServ.getQRCodeRedirectionUrl(params.code).subscribe((res)=>{
        if(String(res).includes('WIFI')){
          return String(res)
        }else{       
          window.location.href = String(res);
        }
      });
    });
    
  }
  
}
