import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClassDairyInfo, ClassDairyViewInfo, StudentDairyInfo, SubjectInfo, TeacherInfo } from './dairy.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class DairyService {
  readonly baseUrl = CONFIG.BASE_URL+'api/Dairy';
  StudentDairyInfo=new StudentDairyInfo();

  formData:ClassDairyViewInfo=new ClassDairyViewInfo();
  constructor(private http:HttpClient) { 
    this.formData=new ClassDairyViewInfo()
    // this.formData.ClassDairyInfo = [];
    // this.formData.TeacherInfo = new TeacherInfo();
    // this.formData.SubjectInfo = new SubjectInfo();
  }
  
  

  getStudentDairyViewInfo(config){
    return this.http.get(this.baseUrl+'/getStudentDairyViewInfo',config);
  }
  getClassDairyViewInfo(config){
    return this.http.get(this.baseUrl+'/getClassDairyViewInfo',config);
  }

  saveClassDairyViewInfo(){
    return this.http.post(this.baseUrl+'/saveClassDairyViewInfo',this.formData);
  }
  saveStudentDairyViewInfo(Obj:any){
    return this.http.post(this.baseUrl+'/saveStudentDairyViewInfo',Obj);
  }
  
}
