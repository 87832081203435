import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from './Const';
import {  QuestionViewData } from './question.model';
@Injectable({
  providedIn: 'root'
})
export class StudentService {
  constructor(private http:HttpClient) { }
  readonly baseUrl = CONFIG.BASE_URL+'api/User';
  formData:QuestionViewData = new QuestionViewData();
  
  getstudentsquestions(){
    
    return this.http.get(this.baseUrl+'/getstudentsquestions');
  }

  
}
