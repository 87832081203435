import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-editterm',
    templateUrl: './editterm.component.html',
    styleUrls: ['./editterm.component.scss'],
    standalone: true,
    imports: [FormsModule, BsDatepickerModule, NgFor, TranslateModule]
})
export class EdittermComponent implements OnInit {
  list:any[];
  yearList:any=[];
  datepickerConfig : BsDatepickerConfig;
  CUSTOMCONST:any=CUSTOMCONST;
  
  constructor(public service:UserService, private commonServ:CommonService, public modalService:BsModalService, private notificationServ:NotificationService) { 
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
  }

  ngOnInit(): void {
    this.gettermview();
  }
  gettermview(){
    this.service.gettermview(this.list[0].id).subscribe((res:any)=>{
      console.log(res);
      this.yearList = res.AcademicYearList;
      this.service.termView = res;
    })
  }
  addTermInfo(){
    if(this.list[0].id >0)
    {
      this.service.termView.IsSaved = true;
    }
    this.service.saveterm().subscribe((res:any)=>{
      if(res.IsSaved){
        this.commonServ.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        if(this.list[0].id >0)
        {
          this.notificationServ.ShowInfo('Data Successfully Updated!');
        }
        else{
          this.notificationServ.ShowInfo('Successfully Saved!');
        }
        
      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      
    })
  }

}
