<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{'Assign Student to Breakout Room' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" autocomplete="off">
        <div class="breackout_body">
            <div class="field-row">
                <div class="select_field">
                    <label for="">Group Count</label>
                    <input class="field-control" type="text" placeholder="How Many Groups You Want?" name="tGroups"
                        [(ngModel)]="tGroups">
                </div>
                <div class="select_field">
                    <label for="">Select Group</label>
                    <select class="field-control" [(ngModel)]="selectedGroup" name="selectedGroup">
                        <option [value]="0">Select</option>
                        <option *ngFor="let i of range()" [value]="i">
                            Group {{i}}
                        </option>
                    </select>
                </div>


            </div>
            <div class="select_field mt-3">
                <label>Select Students</label>
                <div class="breackout_student-list">
                    <!-- {{item.Name}} -->
                    @for (item of list; track item; let i = $index) {
                    <div class="form-group col-4 mb-0">
                        <mat-checkbox [checked]="item.groupId === selectedGroup"
                            (change)="update($event.checked, item.Id)" [disabled]="isActive(item)">
                            {{item.Name}}
                        </mat-checkbox>
                    </div>
                    }
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="custom-primary-btn" (click)="submit()" aria-label="Close">Break
                    Out</button>
                <button type="button" class="custom-primary-btn custom-danger" (click)="closeModal()"
                    aria-label="Cancel">Cancel</button>
            </div>
        </div>
    </form>
</div>