<ul class="list-sidebar bg-defoult clear-both mt-3">
  <li>
    <a routerLink="/admindashboard/" routerLinkActive="active">
      <i class="fa fa-th-large"></i>
      <span class="nav-label">Dashboard</span>
    </a>

  </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#functionsDropdown"
      [ngClass]="{'active': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ),'collapsed':!(questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
      <i class="fa fa-sliders-h" aria-hidden="true"></i> <span class="nav-label">Functions</span> <span
        class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="functionsDropdown"
      [ngClass]="{'show': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
      <li><a routerLink="/question/" routerLinkActive="active" #questionActive="routerLinkActive">Assessments</a></li>
      <li><a routerLink="/analysis/" routerLinkActive="active" #analysisActive="routerLinkActive">Analysis</a></li>
      <li><a routerLink="/survey/" routerLinkActive="active" #surveyActive="routerLinkActive">Survey</a></li>
      <li><a routerLink="/assignment/" routerLinkActive="active" #assignmentActive="routerLinkActive">Assignments</a>
      </li>
      <li><a routerLink="/event/" routerLinkActive="active" #eventActive="routerLinkActive">Interactive Live Classes</a>
      </li>
    </ul>
  </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#registersDropdown"
      [ngClass]="{'active': (contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive),'collapsed':!(contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive) }">
      <i class="fa fa-registered" aria-hidden="true"></i> <span class="nav-label">Registers</span> <span
        class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="registersDropdown"
      [ngClass]="{'show': (contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive ) }">
      <li><a routerLink="/contact-list/" routerLinkActive="active" #contactlistActive="routerLinkActive">Contact</a>
      </li>
      <li><a routerLink="/partner-list/" routerLinkActive="active" #partnerlistActive="routerLinkActive">Partner</a>
      </li>
      <li><a routerLink="/registration-list/" routerLinkActive="active"
          #registrationlistActive="routerLinkActive">Registration</a></li>

    </ul>
  </li>
  <li> <a routerLink="/school/" routerLinkActive="active"><i class="fa fa-graduation-cap"></i> <span
        class="nav-label">School</span></a> </li>
  <li> <a routerLink="/user/" routerLinkActive="active"><i class="fa fa-users"></i> <span
        class="nav-label">User</span></a> </li>
  <li> <a routerLink="/chat/" routerLinkActive="active"><i class="fa fa-comments"></i> <span
        class="nav-label">Chat</span></a> </li>
  <li> <a routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span
        class="nav-label">Attendance</span></a> </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#paymentsDropdown"
      [ngClass]="{'active': (termsActive.isActive || paymentsActive.isActive ),'collapsed':!(termsActive.isActive || paymentsActive.isActive ) }">
      <fa-icon [icon]="currencyIcon"></fa-icon><span class="nav-label">Payments</span> <span
        class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="paymentsDropdown"
      [ngClass]="{'show': termsActive.isActive || paymentsActive.isActive }">
      <li class="active"><a routerLink="/terms/" routerLinkActive="active" #termsActive="routerLinkActive">Terms</a>
      </li>
      <li><a routerLink="/payments/" routerLinkActive="active" #paymentsActive="routerLinkActive">Payments</a></li>
    </ul>
  </li>
  <li>
    <a href="#" data-toggle="collapse" data-target="#filesDropdown"
      [ngClass]="{'active': (unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive),'collapsed':!(unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive) }">
      <i class="fa fa-folder-open"></i> <span class="nav-label">Files</span> <span
        class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="filesDropdown" data-target="#filesDropdown"
      [ngClass]="{'show': unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive}">
      <li class="active"><a routerLink="/unusedfiles/" routerLinkActive="active"
          #unusedfilesActive="routerLinkActive">Unused Files - {{unusedfilesActive.isActive ||
          filemanagementActive.isActive || owndocumentsActive.isActive}}</a></li>
      <li><a routerLink="/file-management/" routerLinkActive="active" #filemanagementActive="routerLinkActive">File
          Management</a></li>
      <li><a routerLink="/own-documents/" routerLinkActive="active" #owndocumentsActive="routerLinkActive">Own
          Documents</a></li>
      <li><a routerLink="/school-shared-doc/" routerLinkActive="active" #SchoolSharedDocActive="routerLinkActive">School
          Shared Doc</a></li>
    </ul>
  </li>

  <li> <a routerLink="/qrcode/" routerLinkActive="active"><fa-icon [icon]="qrcodeIcon"></fa-icon> <span
        class="nav-label">QRCode Lisr</span></a> </li>

</ul>