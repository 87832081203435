import "@angular/compiler";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule, translateFactory, playerFactory } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { UserAcademicYearLogComponent } from "./app/academic/user-academic-year-log.component";
import { UsereditComponent } from "./app/user/useredit.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgApexchartsModule } from "ng-apexcharts";
import { WebcamModule } from "ngx-webcam";
import { BarRatingModule } from "ngx-bar-rating";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { GoogleChartsModule } from "angular-google-charts";
import { LottieModule } from "ngx-lottie";
import { Ng7LargeFilesUploadLibModule } from "ng7-large-files-upload-lib";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxTimerModule } from "ngx-timer";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { EmbedVideo } from "ngx-embed-video";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CountdownModule } from "ngx-countdown";
import { MatTabsModule } from "@angular/material/tabs";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ImageCropperModule } from "ngx-image-cropper";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { AgGridModule } from "ag-grid-angular";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { ResizableModule } from "angular-resizable-element";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgReduxModule } from "@angular-redux/store";
import { ModalModule } from "ngx-bootstrap/modal";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from "@angular/material/radio";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { provideAnimations } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FullCalendarModule } from "@fullcalendar/angular";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@abacritt/angularx-social-login";
import { BnNgIdleService } from "bn-ng-idle";
import { SignalrService } from "./app/shared/signalr.service";
import { AuthGuard } from "./app/auth/auth.gaurd.service";
import { TokenInterceptor } from "./app/auth/token.interceptor";
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
} from "@angular/common/http";

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        // LottieModule.forRoot({ player: playerFactory }),
        FullCalendarModule,
        CommonModule,
        /*BrowserModule.withServerTransition({ appId: 'serverApp' }),*/ AppRoutingModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatToolbarModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatRadioModule,
        MatTableModule,
        MatCheckboxModule,
        TranslateModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        NgReduxModule,
        PdfViewerModule,
        BsDatepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        //  PopoverModule.forRoot(),
        DragDropModule,
        ResizableModule,
        ColorPickerModule,
        PopoverModule.forRoot(),
        TimepickerModule.forRoot(),
        //DatetimePopupModule.forRoot(),
        AgGridModule,
        ToastrModule.forRoot({
          timeOut: 10000,
          positionClass: "toast-bottom-right",
          preventDuplicates: true,
        }),
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: translateFactory,
            deps: [HttpClient],
          },
        }),
        ImageCropperModule,
        CarouselModule,
        MatTabsModule,
        CountdownModule,
        AngularEditorModule,
        EmbedVideo.forRoot(),
        YouTubePlayerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        // MatVideoModule
        NgxTimerModule,
        NgxDocViewerModule,
        Ng7LargeFilesUploadLibModule,
        Ng7LargeFilesUploadLibModule.forRoot(
          "https://api.hybridlearning.in/api/Home/FileUpload"
        ),
        // 'https://api.hybridlearning.in/api/Home/FileUpload'
        // http://185.103.120.94:9090/SAPI/Home/FileUpload
        LottieModule.forRoot({ player: playerFactory }),
        GoogleChartsModule,
        FontAwesomeModule,
        BarRatingModule,
        WebcamModule,
        SocialLoginModule,
        NgApexchartsModule,
        NgbModule
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
      },
      AuthGuard,
      SignalrService,
      BnNgIdleService,
      {
        provide: "SocialAuthServiceConfig",
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                "115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com"
              ),
            },
            // 1043793751452-kbjvih3ts3e48k90hq7e9spd92t1fla8.apps.googleusercontent.com
            // 115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com
            // {
            //   id: FacebookLoginProvider.PROVIDER_ID,
            //   provider: new FacebookLoginProvider('clientId')
            // }
          ],
          onError: (err) => {
            console.error(err);
          },
        } as SocialAuthServiceConfig,
      },
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
    ],
  }).catch((err) => console.error(err));
}

if (document.readyState === "complete") {
  bootstrap();
} else {
  document.addEventListener("DOMContentLoaded", bootstrap);
}
