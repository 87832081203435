<!-- Header For Website -->
<div class="container-fluid topnav" *ngIf="!isLogedIn" [ngClass]="{'bg-transparet': !isLogedIn, 'bg-light':isLogedIn}">
  <nav class="navbar navbar-expand-sm navbar-dark {{role}}">
    <a class="navbar-brand d-block d-sm-none" routerLink="/home/">
      <img [src]="(commonServ.schoolLogoUrl | async)!=''?(commonServ.schoolLogoUrl | async):schoolLogoUrl" alt=""
        style="max-width:100px">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand mr-4 d-none d-sm-block" routerLink="/home/">
      <img [src]="(commonServ.schoolLogoUrl | async)!=''?(commonServ.schoolLogoUrl | async):schoolLogoUrl" alt=""
        style="max-width:100px">
    </a>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto mr-auto mb-2 mb-lg-0" id="customized-scrollbar">
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/home/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'Home' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/about/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'About' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/benefits/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'Benefits' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/features/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'Features' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/partners/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'Partners' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/faqs/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>{{'FAQs' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/contact/" routerLinkActive="active">
            {{'Contact' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLogedIn">
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/login/" routerLinkActive="active">
            {{'Login' | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="isLogedIn && role == 'Parent'">
          <!-- <app-parent-nav></app-parent-nav> -->
        </li>
        <li class="nav-item" *ngIf="isLogedIn && (role == 'Parent' )">
          <section>

            <div class="d-flex justify-content-center">
              <div class="mb-3 p-1 small-group-item">
                <mat-card class="dasbord-info-card p-0 pt-2 bg-group10 shadow">

                  <div class="grouup-body">
                    <a routerLink="" (click)="OnLogOut()">
                      <div class="text-center text-black">
                        <div class="icon">
                          <!-- <fa-icon [icon]="logoutIcons"></fa-icon> -->

                        </div>
                        <div class="content">
                          <p>Logout</p>
                        </div>
                      </div>
                    </a>

                  </div>

                </mat-card>
              </div>
            </div>
          </section>

        </li>
      </ul>
    </div>
    <a class="navbar-brand mr-4 d-none d-sm-block" routerLink="/home/">
      <img src="../assets/images/2X/Icon-feather-search.png" alt="" style="max-width:20px">
    </a>
  </nav>
  <div *ngIf="isLogedIn && ( role == 'Parent')">
    <!-- <mat-progress-spinner class="progress-spinner-group" *ngIf="commonServ.isLoading |async" mode="indeterminate"
      value='66' diameter="45"></mat-progress-spinner> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-chatbox *ngIf="commonServ.isChatting |async"></app-chatbox>
  </div>
</div>

<!-- Header For Dashboard -->
<div class="dashboard_outer"
  *ngIf="isLogedIn && (role == 'SuperAdmin' || role == 'Admin' || role == 'Cordinator' || role == 'Teacher' || role == 'Student' || role == 'Parent' || role == 'ContentProvider')">
  <header class="dashboard-header">
    <div class="dashboard-logo">
      <h3>Hybrid Learning</h3>
      <!-- <img [src]="schoolLogoUrl" alt=""> -->
    </div>
    <div class="header-title">
      <span>Welcome,</span>
      <p>{{(userInfo?.FirstName || 'Jone Doe')}}!</p>
    </div>
    <!-- <div class="header-search">
      <div class="search-option">
        <span class="bi bi-search"></span>
        <input type="text" placeholder="Find Something">
      </div>
    </div> -->
    <div class="header-links">
      <div class="action-links">
        <span class="bi bi-chat-right-text" title="Chat" data-bs-toggle="tooltip" data-bs-placement="bottom"></span>
        <span class="bi bi-bell" title="Notification" data-bs-toggle="tooltip" data-bs-placement="bottom"></span>
      </div>
      <div class="profile-image">
        <img [src]="userInfo?.CroppedFilepath || [imagePath]" alt="profilePic">
        <div class="profile-info">

          <h4>{{ (userInfo?.FirstName || 'Unknown') + ' ' + (userInfo?.Surname || 'User') }}</h4>
          <span>Teacher ID: {{ (userInfo?.UserShareId || '')}}</span>
        </div>
      </div>
    </div>
  </header>
  <!-- Dashboard Header End -->

  <!-- Dashboard Sidebar Start -->
  <aside [class.fliph]="Isfliph">
    <ul class="menu_outer">
      <!-- Teacher Sidebar -->
      <app-teacher-sidebar *ngIf="isLogedIn && (role == 'Teacher')"></app-teacher-sidebar>

      <app-content-provider-sidebar *ngIf="isLogedIn && (role == 'ContentProvider')"></app-content-provider-sidebar>
      <app-admin-sidebar *ngIf="isLogedIn && (role == 'Admin')"></app-admin-sidebar>
      <app-superadmin-sidebar *ngIf="isLogedIn && (role == 'SuperAdmin')"></app-superadmin-sidebar>
      <app-cordinator-sidebar *ngIf="isLogedIn && (role == 'Cordinator')"></app-cordinator-sidebar>
      <app-student-sidebar *ngIf="isLogedIn && (role == 'Student')"></app-student-sidebar>
      <app-parent-sidebar *ngIf="isLogedIn && (role == 'Parent')"></app-parent-sidebar>
      <!-- Logout Menu -->
      <li data-bs-toggle="tooltip" title="Forum" routerLink="/forum/" routerLinkActive="active"
        #parenActive="routerLinkActive">
        <i class="bi bi-people"></i>
        <span class="nav-label">Forum</span>
      </li>
      <li data-bs-toggle="tooltip" title="Forum" routerLink="/view-social-event/" routerLinkActive="active"
        #parenActive="routerLinkActive">
        <i class="bi bi-people"></i>
        <span class="nav-label">Social Events</span>
      </li>

      <li (click)="OnLogOut()" data-bs-toggle="tooltip" title="Logout">
        <i class="bi bi-box-arrow-left"></i>
        <span>Logout</span>
      </li>
    </ul>
    <span data-bs-toggle="tooltip" title="Toggle Menu" (click)="toggleSidebar()" class="bi toggle-icon"
      [ngClass]="{'bi-chevron-left':isColapsAside,'bi-chevron-right':!isColapsAside}"></span>
  </aside>

  <div class="dashboard-main-outer" [class.fliph]="Isfliph">
    <!-- <mat-progress-spinner class="progress-spinner-group" *ngIf="commonServ.isLoading |async" mode="indeterminate"
      value='66' diameter="45"></mat-progress-spinner> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <!-- <app-chatbox></app-chatbox> -->
  </div>
</div>

<div class="container-fluid p-0 bg-light" section *ngIf="!isLogedIn">
  <!-- <app-register></app-register> -->
  <!-- <mat-progress-spinner class="progress-spinner-group" *ngIf="commonServ.isLoading |async" mode="indeterminate"
    value='66' diameter="45"></mat-progress-spinner> -->
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <app-chatbox *ngIf="commonServ.isChatting |async"></app-chatbox>
  <!-- <app-chatbox ></app-chatbox> -->
</div>
<footer>
  <section *ngIf="!isLogedIn">
    <div class="container-fluid footer-section bg-footer">
      <div class="row justify-content-center pt-5">
        <!-- <div class="col-10">
          <div class="row mt-5 "> -->
        <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-6 mb-3">
          <div class="footer-logo mb-4">
            <img [src]="(commonServ.schoolLogoUrl | async)==''?(commonServ.schoolLogoUrl | async):schoolLogoUrl" alt=""
              style="max-width:100px">
          </div>

          <p>The newest zone in Educational Technologies: Hybrid Learning Platform is designed to transform your school
            environment as India's first Educational Sharing and Collaboration Platform.</p>

        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-6 mb-3">
          <div class="row footer-menu">
            <div class="col-6">
              <ul class="list-unstyled mb-0">
                <li><a routerLink="/home/">Home</a></li>
                <li><a routerLink="/about/">About</a></li>
                <li><a routerLink="/benefits/">Benefits</a></li>
                <li><a routerLink="/features/">Features</a></li>
                <li><a routerLink="/partners/">Partners</a></li>
                <li><a routerLink="/login/">Login</a></li>
              </ul>
            </div>
            <div class="col-6">
              <ul class="list-unstyled mb-0">
                <li><a routerLink="/faqs/">FAQs</a></li>
                <li><a routerLink="/support/">Support</a></li>
                <li><a routerLink="/terms-of-service/">Terms-of-service</a></li>
                <li><a routerLink="/privacy-policy/">Privacy-policy</a></li>
                <li><a routerLink="/cancellation/">Cancellation</a></li>
                <li><a routerLink="/contact/">Contact</a></li>
              </ul>

            </div>
            <div class="col-12">
              <ul class="list-unstyled Social-media-group">
                <li>
                  <a href="https://www.facebook.com/HybridLearningplatform" target="_blank">
                    <img src="../assets/images/2X/XMLID_834_1.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/HybridLearning_" target="_blank">
                    <img src="../assets/images/2X/iconmonstr-twitter-1.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/hybridlearning/" target="_blank">
                    <img src="../assets/images/2X/XMLID_801_1.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src="../assets/images/2X/Instagram-Glyph1.png" alt="">
                  </a>
                </li>

              </ul>
            </div>
          </div>

        </div>

        <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
          <div class="section-header">
            <h1 class="d-block title pt-1 pb-1">Tools, Resources For K12 and Higher Education.</h1>
            <h1 class="d-block title pt-1 pb-1">Please sign up to Qualify Free Account.</h1>
          </div>
          <p></p>
          <form novalidate form="ngForm" autocomplete="off" (submit)="onRegister(form)">
            <!-- <form action=""> -->

            <div class="row form-row m-0 role-group">
              <div class="custom-p-2 d-flex">
                <label class="d-block w-125px font-20px">I am an/a</label>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="Role"
                  [(ngModel)]="commonServ.userFormData.UserInfo.Role">
                  <mat-radio-button class="example-radio-button ml-3 font-20px" required
                    *ngFor="let role of SignupRoleList" [value]="role.Code">
                    {{role.Name}}
                  </mat-radio-button>
                </mat-radio-group>

              </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" placeholder="Your First Name (Required)" name="FirstName"
                  required minlength="2" [(ngModel)]="this.commonServ.userFormData.UserInfo.FirstName">
              </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" placeholder="Your Last Name (Required)" name="SurName"
                  required minlength="2" [(ngModel)]="this.commonServ.userFormData.UserInfo.Surname">
              </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" type="email" placeholder="Your Email Address (Required)"
                  name="Email" required minlength="10" [(ngModel)]="this.commonServ.userFormData.UserInfo.Email">
              </div>

              <div class="form-group col-6 mb-0">
                <button class="btn btn-primary form-control form-control-sm"
                  [disabled]="form.invalid || isSbscribeClick" type="submit">Join The Waitlist</button>
              </div>
            </div>
          </form>
          <div class="icon-group2">

          </div>
        </div>
      </div>
      <!-- </div>
      </div> -->

    </div>
  </section>



  <div class="dashboard-footer" [ngClass]="{'bg-success': isLogedIn,'bg-primary':!isLogedIn}" [class.fliph]="Isfliph">
    <p>Copyright &copy; Hybrid Learning Platform | All Right Reserved
    </p>
    <!-- <div class="col-6">
          <ul class="footer-social-icons">
            <li>
              <a href="https://www.youtube.com/channel/UCRyQzj1UBVIqOjX-BCq9D-w" target="_blank">
                <img src="../assets/images/icons/youtube.png" alt="">
              </a>
            </li>
              <li>
                <a href="https://www.facebook.com/HybridLearningforlife" target="_blank">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                  </svg>
                </a>
              </li>
             <li>
               <a href="https://twitter.com/HybridLearning_" target="_blank">
                 <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                   <path fill="currentColor" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                 </svg>
               </a>
             </li>
             <li>
               <a href="https://twitter.com/HybridLearning_" target="_blank" href="https://www.linkedin.com/company/hybridlearning/" target="_blank">
                 <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                   <path fill="currentColor" d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
                 </svg>
               </a>
             </li>

          </ul>
         </div> -->
  </div>
</footer>