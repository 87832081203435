
<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{'AddFeedback' | translate}}</h3>
        </div>
        <div class="modal-body p-2">
            <div class="container-fluid bg-light">
                <div class="row">
                    <div class="col-12">
                        <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                            <ag-grid-angular #agGrid
                            style="width: 100%; height:calc(100vh - 230px);min-height: 200px;"
                            class="ag-theme-alpine surveyResult"
                            id="surveyResultGrid"
                            [gridOptions]="surveyResultGridOptions"
                            [columnDefs]="surveyResultColumnDefs"
                            [defaultColDef]="surveyResultDefaultColDef"
                            [rowSelection]="surveyResultRowSelection"
                            [rowData]="surveyResultRowData"
                            (gridReady)="onSurveyReady($event)"
                            [frameworkComponents]="frameworkComponents"
                            (gridReload)="onSurveyReload($event)"
                            ></ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Close">Close</button>
        </div>
    </div>
</div>


 