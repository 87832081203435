<div class="banner">
  <div class="banner-bg-image">
    <img src="../../assets/images/2X/common-banner.png" alt="common-banner-bg-image">
  </div>
  
  <div class="banner-content-center" *ngIf="course === 'hybrid_learning'">
    <h1 class="w-100 text-center" >Hybrid learning</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'humanity'">
    <h1 class="w-100 text-center" >Humanity</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'character'">
    <h1 class="w-100 text-center" >Character</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'responsibility'">
    <h1 class="w-100 text-center" >Responsibility</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'respect'">
    <h1 class="w-100 text-center" >Respect</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'honesty'">
    <h1 class="w-100 text-center" >Honesty</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'empathy'">
    <h1 class="w-100 text-center" >Empathy</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'not_be_lazy'">
    <h1 class="w-100 text-center" >Not Be Lazy</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  <div class="banner-content-center" *ngIf="course === 'kindness'">
    <h1 class="w-100 text-center" >Kindness</h1>
    <button class="btn btn-primary btn-larg mt-3 mx-auto">Inrol</button>
  </div>
  
</div>
    <div *ngIf="course === 'hybrid_learning'">
      
        <section class="bg-light course-details-image-section">
          <div class="container-fluid-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/hybrid_learning.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Hybrid learning:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>

        <section class="course-details-content-section">
          <div class="container-fluid-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/valueeducation/">Value Education</a></li>
                <li class="breadcrumb-item active" aria-current="page">Hybrid Learning</li>
              </ol>
            </nav>
      
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Benefits with Hybrid Learning">
                <div class="mt-3">
                  <ol>
                    <li>High-quality education through remote and virtual teaching and learning.</li>
                    <li>Hybrid learning form an integral part of our education offer for the future.</li>
                    <li>Best support to students for wellbeing when they are out of school.</li>
                    <li>Future education through hybrid learning.</li>
                    <li>hybrid learning change our schooling system.</li>
                  </ol>
                </div>
              </mat-tab>
              <mat-tab label="Benefits of Participation">
                <div class="mt-3">
                  <p class="tab-content">Reflection - consider the learning from the education system's reaction to a remote
                    learning environment
                    Collaboration and connection - enquire with others and make connections for ongoing collaboration
                    Strategic thinking - stimulated by education, consider and plan your 'hybrid learning' next steps.
                  </p>
                </div>
              </mat-tab>
      
            </mat-tab-group>
          </div>
        </section>
      
      
      </div>
      <div *ngIf="course === 'humanity'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/hybrid_learning.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Humanity:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">SEL</a></li>
                <li class="breadcrumb-item active" aria-current="page">Humanity</li>
              </ol>
            </nav>
      
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Overview">
                <div class="mt-3">
                  <p class="tab-content">
                    Humanity is above all, for any student before learning academics they should know and learn more about
                    humanity. To Understand the Nature and Value of Humanities, and continuing with the three major goals.
                  </p>
                  <ul>
                    <li>To Understand Human Nature </li>
                    <li>To Understand Human Roles and Behavior. </li>
                    <li>To Understand Human Ideals</li>
      
                  </ul>
                  <p class="tab-content">This humanity also provides Activities for each topic. These Activities offer
                    specific
                    examples for Humanities lessons. A listing of “Other Suggested Topics” follows. <br>The topics have
                    designed
                    to provide flexibility for year-long topics in Humanities and Character building. </p>
                </div>
              </mat-tab>
              <mat-tab label="Learning Objective">
                <div class="mt-3">
                  <p class="tab-content">Humanities is the integrated study of history, literature, language, philosophy, the
                    visual arts,
                    dance, and music. In the Humanities lessons, emphasis is placed on critical thinking, creativity, and
                    rights
                    and responsibilities of the individual in society. Students explore aspects of human nature, human roles
                    and
                    behavior, and human ideals.
                  </p>
                </div>
              </mat-tab>
              <mat-tab label="Goals">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Goals of the Humanity Lessons</strong>
                  </p>
      
                  <ol type="A">
                    <li>To understand human nature: <br>The aspects of human nature—physical, psychological, social,
                      aesthetic,
                      spiritual-are complex and interrelated. Studying the basic foundations of human nature offers
                      significant
                      ways to better understand the human condition:</li><br>
                    <li>To understand human roles and behavior: <br>While the members of the human family share the same
                      nature
                      and aspire to the same ideals, they play different roles and exhibit varied behavior. Examining ways
                      individuals explore, think, lead, and create offers opportunities for better understanding of the
                      diversity of human nature. </li><br>
                    <li>To understand human ideals: <br>Humans seek perfection-however unattainable. The urge to achieve
                      harmony
                      through the pursuit of truth, love, justice, and beauty exists in all societies and is manifested in
                      their
                      art and artifacts.</li>
                  </ol>
      
                </div>
              </mat-tab>
              <mat-tab label="Outcomes">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Outcomes of the humanity Lessons for Students:</strong>
                  </p>
      
                  <ol>
                    <li>The desired outcomes for students in Humanities are that they will</li>
                    <li>Use a strong command of knowledge </li>
                    <li>Use their knowledge to guide their behavior </li>
                    <li>Communicate and be persuasive about their knowledge </li>
                    <li>Recognize enduring human problems </li>
                    <li>Use their knowledge to inform their evolving system of values</li>
                    <li>Be culturally sensitive in their host nation</li>
                    <li>Use their knowledge to improve their lives and the lives of others</li>
                  </ol>
      
                </div>
              </mat-tab>
              <mat-tab label="Assessment and Evaluation ">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Assessment and Evaluation on Humanity Lessons :</strong>
                  </p>
                  <ol>
                    <li>While all forms of assessment and evaluation may be used in Humanities, those most appropriate are
                      those
                      which determine students’ </li>
                    <li>Use of broad knowledge</li>
                    <li>Use of knowledge and opinion contextually </li>
                    <li>Reasoned thinking and behavior </li>
                    <li>Recognition and use of nuance </li>
                    <li>Recognition and appreciation of interrelationships among disciplines </li>
                    <li>Development and use of personal criteria for interpretation and criticism</li>
                  </ol>
      
                </div>
              </mat-tab>
              <mat-tab label="Characterizes">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Characterizes through Humanity Lessons:</strong>
                  </p>
                  <ol type="A">
                    <li>Interdisciplinary focus <br>Content, examples, and resources from more than one discipline (history,
                      literature, language, philosophy, the visual arts, theater, dance, and music) </li><br>
                    <li>Arts emphasis <br>Theatre, dance, music, and the visual arts; where possible architecture, film and
                      television are also included the making of art as well as history, aesthetics, and criticism of art; the
                      role of art and the artists in society </li><br>
                    <li>Cultural diversity/host nation study <br>Content, examples, and resources from the local setting
                      (culture, history, geography, customs, art) </li><br>
                    <li>Personal values perspective <br>Opportunities for individuals to explore ethical and moral issues</li>
      
                  </ol>
      
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'character'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/character.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Character:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Character</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Character">
                <div class="mt-3">
                  <p class="tab-content">
                    Character education is the process of learning common attitudes,
                    beliefs and behaviors that are important for people to have as responsible citizens.
                  </p>
                </div>
              </mat-tab>
              <mat-tab label="What is character">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>What is character education?</strong>
                    <br>Character education is the process of learning common attitudes, beliefs and behaviors that are
                    important for people to have as responsible citizens. Good character education can provide ground rules
                    for
                    life for adults and young people, and it stresses the importance of helping children learn and practice
                    behaviors that reflect universal ethical values.
      
                  </p>
                  <p>Character education helps children and youth become:</p>
                  <ul>
                    <li>Conscious of the right thing to do.</li>
                    <li>Committed to doing the right thing.</li>
                    <li>Competent in doing the right thing.</li>
                  </ul>
                  <p class="tab-content">
                    When we observe any person, first and foremost we see his character. Based on character we anyone can
                    estimate how the person is transparent, Open minded, how confident is he. If any student have to be best
                    in
                    character they will know the value of time and money. Now simply we can say, character education is
                    everything you do that influences the character of the kids you teach. But to put it in a more focused
                    light, that “character education is the deliberate effort to help people understand, care about, and act
                    upon core ethical values.” <br>“When we think about the kind of character we want for our children, it’s
                    clear that we want them to be able to judge what is right, care deeply about what is right, and then do
                    what
                    they believe to be right—even in the face of pressure from without and temptation from
                    within.”<br>describes
                    a developmental process that involves knowledge, feelings, and action, and thereby provides an integrated
                    foundation on which to structure a coherent and comprehensive lessons on character. It tells us that we
                    need
                    to engage our kids in activities that make them think critically about moral and ethical questions,
                    inspire
                    them to become committed to moral and ethical actions, and give them ample opportunities to practice moral
                    and ethical behavior.
                  </p>
                </div>
              </mat-tab>
              <mat-tab label="Why character">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Why character is Important in education?</strong>
                    <br>
                    Many parents are concerned about in the healthy moral development of children. Increases in delinquency,
                    pregnancies, violence and substance abuse continue to climb among adolescents. Surveys have shown
                    astonishingly high levels of cheating, lying, stealing and drunken driving among teens and young adults.
                    Adults clearly need to do a better job of teaching high standards of behavior in the family, school and
                    community.
      
                    Eventually, somebody less enlightened than you are is going to ask you why you are wasting your time
                    teaching character instead of academics.
                    Quality character education helps schools create a safe, caring and inclusive learning environment for
                    every
                    student and supports academic development. It fosters qualities that will help students be successful as
                    citizens, in the workplace, and with the academic curriculum. It lays the foundation to help students be
                    successful in all of the goals. It is the common denominator that will help schools reach all of their
                    goals! CHARACTER EDUCATION IS NOT ONE MORE THING ON YOUR PLATE! IT IS THE PLATE!!!”
                  </p>
                </div>
              </mat-tab>
              <mat-tab label="Holistic Approach">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>The Holistic Approach</strong>
                    <br>
                    Popular wisdom holds that the best way to implement learning through character is through a holistic
                    approach that integrates character development into every aspect of school life. This approach is also
                    known
                    as whole school reform. Here are some of the distinguishing features of the holistic model:
                  </p>
                  <ul>
                    <li>Everything in the school is organized around the development of relationships between and among
                      students, teachers, and parents.</li>
                    <li>Social emotional learning is emphasized as much as academic learning.</li>
                    <li>Cooperation and collaboration among students are emphasized over competition.</li>
                    <li>Values such as fairness, respect, and honesty are part of everyday lessons.</li>
                    <li>Students are given ample opportunities to practice moral behavior through activities.</li>
                    <li>Discipline and classroom management concentrate on problem-solving rather than rewards and
                      punishments.
                    </li>
                  </ul>
                  <p class="tab-content">
                    But, what if you can’t do all the things listed above? Not to worry. Even if you can’t restructure the
                    whole
                    school there is still a lot you can do to provide meaningful character-building experiences for your
                    students.
                  </p>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'responsibility'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/responsibility.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Responsibility:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Responsibility</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Learning Outcomes ">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Students will understand the following: </strong>
                  </p>
                  <ul>
                    <li>Students will develop an understanding of the concept of responsibility.</li>
                    <li>Students will identify people, organizations or ideals for which they feel responsible.</li>
                    <li>Students will identify ways in which they demonstrate responsibility.</li>
                    <li>Students will relate their own experiences of responsibility to those presented in the visual history
                      testimony accounts.</li>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="Focus">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Supports learning standards in social studies and health.
                    </strong>
                  </p>
                  <ul>
                    <li>Students understand the individual responsibilities of citizenship and promoting a civil society.</li>
                    <li>Students demonstrate the ability to use decision-making skills to enhance health and to create
                      positive
                      personal relationships.</li>
      
                  </ul>
                  <p class="tab-content">
                    SEL Topics:
                  </p>
                  <ul>
                    <li>Self Awareness, Self Management</li>
                    <li>Decision Making</li>
                    <li>Positive Relationships </li>
                  </ul>
                  <p class="tab-content">
                    Character Traits:
                  </p>
                  <ul>
                    <li>Responsibility, Empathy, Caring</li>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="Teaching Strategies ">
                <div class="mt-3">
                  <p class="tab-content">
                    Follow the presentation with the following talking points:
                    <br>Ways we Talk about Responsibility
                    <br>There are different ways we use the word responsibility, including Be responsible, Take
                    responsibility,
                    It’s your responsibility, Act responsibly.
                  </p>
      
                  <p class="tab-content">
                    What are you responsible for?
                  </p>
                  <ul>
                    <li>My own actions</li>
                    <li>My time</li>
                    <li>My possessions</li>
                    <li>Chores</li>
                    <li>My family</li>
                    <li>Pets</li>
                    <li>Homework</li>
                    <li>My community</li>
                    <li>The Earth</li>
                  </ul>
                  <p class="tab-content">
                    Explain what a responsibility is- it’s something you are expected to do. <br>When you do the thing you are
                    expected to do, you are being responsible, and a positive result occurs. <br>When you do not do the thing
                    you are supposed to do, you are not being responsible, or being irresponsible, and there is a negative
                    consequence for having neglected your responsibility.
                  </p>
                  <p class="tab-content">
                    What are some examples of Responsibilities? <br>
                    For example: <br>
                    Do your homework- your parents and teachers happy and you may get praise. <br>
                    <br>If you don’t do it, it hurts your ability to learn and grow. Parents and teachers may be disappointed.
                    <br>There may be consequences like losing privileges until your work is done. <br>
                    Don’t hurt yourself of others.
                  </p>
      
                </div>
              </mat-tab>
              <mat-tab label="Feelings">
                <div class="mt-3">
                  <p class="tab-content">
                    Being responsible feels better than being irresponsible, in the long run. Sometimes it’s hard to do the
                    right, or responsible, thing in the moment. It can be tempting to do what you want to do instead of what
                    you
                    need to do.
                    But long term, actions have consequences. Being responsible gets you the result you want. Irresponsible
                    decisions lead to negative consequences that are no fun.
                    When it’s hard to do the responsible thing, think about how it will feel later when you have to pay for
                    the
                    consequences of your actions.
                    When you’re a kid, it may not seem like that big of a deal. As you get older, the choices and the
                    consequences
                    become more significant.
      
                  </p>
      
                </div>
              </mat-tab>
              <mat-tab label="Activity">
                <div class="mt-3">
                  <ol>
                    <li>How responsible are you? For each of the responsible behaviors listed at the top of this page, rate
                      yourself on a scale of one to five (1=awful, and 5=terrific). For each of these behaviors give an
                      example
                      of how you are either responsible or not, and what you could do to improve.</li>
                    <li>Discuss ways to demonstrate responsibility in your school or local community. As a class, identify a
                      project that will make a difference in your school or local community that can be completed within one
                      year. Brainstorm possible project
                      ideas. Discuss the potential efforts that your class can make in order to address this problem. Evaluate
                      the ideas and choose a project that is feasible and meaningful for the participants involved. Initiate
                      an
                      action plan for your class project.</li>
                  </ol>
      
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'respect'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/respect.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Respect:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Respect</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Learning Outcomes">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Students will understand the following: </strong>
                  </p>
                  <ol>
                    <li>Even when feelings are hurt, we need to treat friends with respect.</li>
                    <li>Talking about an issue is more effective than getting even.</li>
                    <li>Doing the right thing is better than doing the easy thing.</li>
                  </ol>
                </div>
              </mat-tab>
      
              <mat-tab label="Vocabulary ">
                <div class="mt-3">
                  <p class="tab-content">
                    <strong>Respect- </strong> Caring enough about others’ feelings to think before you act
                    <br><strong>Relational aggression bullying-</strong>(A form of bullying in which hurt is caused through
                    damage to relationships or social status.) When you leave someone out of the group or an activity because
                    you want them to feel bad.
                  </p>
      
                </div>
              </mat-tab>
              <mat-tab label="Teaching Strategies ">
                <div class="mt-3">
                  <ul>
                    <li>Read Be Bigger children’s book (paper book or project the ebook on a whiteboard). </li>
                    <li>On each page, focus on the characters’ facial expressions and body language.
                      How is each character feeling on each page? Help the children see how they can pay attention to faces
                      and
                      body language to help them understand how others are feeling (to help prevent misunderstandings), and
                      how
                      they themselves are feeling (to better understand where their actions are coming from). </li>
                    <li>Discuss hurt feelings and how to react to them Talk about how the girl was feeling at the beginning of
                      the book. </li>
                    <li>Why was she upset? (She felt left out and her feelings were hurt.) </li>
                    <li>What did the tree mean by “your heart and stomach are tied up in knots”? (Teach children to pay
                      attention to the cues their body gives them to help them figure out if they are sad, anxious, angry,
                      etc.)
                    </li>
                    <li>Why did the tree say the girl was planning to get even with her friend? What does “getting even”
                      really
                      mean?
                      (You perceive that someone hurt you and so you hurt them in return.) Does getting even take away your
                      hurt?
                      (No, it just makes both of you feel bad.) </li>
                    <li>Discuss issues around respect and relational aggression bullying.
                      In the story about the girl’s grandmother, we see the girls each call on their other friends to exclude
                      someone in order cause hurt (relational aggression bullying).
                      This is an issue of respect and caring. Being respectful means you care enough about others’ feelings
                      not
                      to cause harm. In this case the girls are trying to hurt each other on purpose. Friends don’t do that.
                      Respectful people don’t do that. Even when feelings are hurt, we need to deal with the issue
                      respectfully
                      (caring enough not to cause harm.) </li>
                    <li>Discuss another way the girl could deal with her hurt feelings.
                      The tree shows her how to “Be Bigger” than the hurt feelings (how to rise above the hurt to deal with
                      the
                      issue respectfully.) <br>A more effective way to deal with hurt feelings is:
                      <ol>
                        <li>Figure out what you really want. (your friend to apologize, your feelings not to hurt) </li>
                        <li>Choose an action that helps you get what you really want. (Talk to your friend to let her know she
                          hurt your feelings. Talk through the issues respectfully. Focus on how you are feeling instead of
                          being accusatory.)</li>
                      </ol>
                    </li>
      
                  </ul>
      
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'honesty'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/honesty.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Honesty:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Honesty</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Learning Outcomes ">
                <div class="mt-3">
                  <p class="tab-content">Students will understand the following:</p>
                  <ul>
                    <li>Honesty is about being truthful in what you say and in what you do.</li>
                    <li>Honesty includes telling the truth (not lying), not hiding the truth (deceiving), and acting in a way
                      that is morally
                      right (not stealing, cheating).</li>
                    <li>Honesty is important in how we all work together in society</li>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="Vocabulary">
                <div class="mt-3">
                  <p class="tab-content">Honesty- Only doing or saying things that are truthful.</p>
                  <ul>
                    <li>telling the truth (not lying)</li>
                    <li>acting in a way that is truthful and doesn’t hide the truth (not deceiving)</li>
                    <li>only doing things that are morally right (not stealing or cheating)</li>
                  </ul>
                  <p class="tab-content">Truthful- How something really is or how it really happened. <br>Morally right- The
                    people we live and work with (society) consider some actions to be right and some wrong because of
                    how they help or hurt our ability to get along with each other. Examples of actions that are right include
                    telling the truth
                    and being kind to others. Some examples that are “wrong” include lying, stealing, and cheating.</p>
                </div>
              </mat-tab>
              <mat-tab label="Teaching Strategies ">
                <div class="mt-3">
                  <p class="tab-content">Define honesty and discuss examples of what honesty is. <br>
                    If using the What is Honesty Presentation, Grades K-1, use the images to demonstrate examples of honesty.
                    Discuss the concept of honesty (see vocabulary section). Ask the class for examples of behaviors that are
                    honest and
                    dishonest. <br>Some examples of honesty may include telling the truth, treating people fairly / not taking
                    advantage of people, really
                    meaning what you say, doing what you say you will do, telling the whole truth.
                    Examples of dishonesty may include: lying, not telling the whole truth, bending the truth, hiding the
                    truth,
                    stealing,
                    cheating. <br>Discuss why honesty is important to a society.
                    <br>Pose the question, “What if it was ok to be dishonest? What if our society and laws said it was ok for
                    people to lie and
                    steal?” Allow the children to talk about what could happen and how that would feel. <br>
                    Honesty is important for people to live together peacefully. We need to know our friends, family, and
                    leaders have a
                    common belief in being truthful and trustworthy in order to feel safe and to get along. <br>Discuss why
                    honesty is important in friendships. <br>Discuss how honesty is essential to having positive
                    relationships.
                    For relationships to work, we each need to know we will
                    treat each other fairly and truthfully. <br>Pose some questions that reveal how honesty is important to
                    maintaining positive friendships:
                  </p>
                  <ul>
                    <li>“What if each time your friend came over, she took one of your toys without asking, decided it was
                      hers,
                      and
                      brought it home forever.” (Stealing)</li>
                    <li> “What if your friend lost a school book but told the teacher you were the one who lost it?” (Lying)
                      Complete the honesty worksheet</li>
                  </ul>
                  <p class="tab-content">Using the picture-based What is Honesty Worksheet, Grades K-1, have children circle
                    Green if the action is Honest or Red
                    if it is dishonest.</p>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'empathy'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/empathy.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Empathy:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Empathy</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Learning Outcomes">
                <div class="mt-3">
                  <p class="tab-content">Students will understand the following:
                  </p>
                  <ul>
                    <li>What empathy is and why it is important.</li>
                    <li>How to look for cues in facial expressions and body language to understand how others feel.</li>
                    <li>How to “put yourself in someone else’s shoes”</li>
                    <li>Be able to use empathy to change behaviour and build better relationships.</li>
                  </ul>
                  <p class="tab-content">Students develop empathy through role-play activities. Working in groups, using
                    scripts
                    or hot seating, students start to appreciate the feelings of people involved in negative experiences.</p>
                </div>
              </mat-tab>
              <mat-tab label="Vocabulary ">
                <div class="mt-3">
                  <p class="tab-content">Empathy- The ability to understand how someone else is feeling. Empathy has two
                    parts:
                  </p>
                  <ul>
                    <li>Shared emotion – when you feel an emotion with someone else, even though you aren’t in the same
                      situation</li>
                    <li>Understanding other perspectives- understanding how someone else might see a situation (their
                      perspective)
                      even if you think differently about it. We call this being able to “put yourself in someone else’s
                      shoes.”
                      See the What is Empathy? Page for a full definition and examples in terms kids will understand.</li>
      
                  </ul>
      
                </div>
              </mat-tab>
              <mat-tab label="Focus">
                <div class="mt-3">
                  <p class="tab-content">Supports learning standards in social studies and health.
                  </p>
                  <ul>
                    <li>Students understand the individual responsibilities of citizenship and promoting a civil society.
                    </li>
                    <li>Students demonstrate the ability to use decision-making skills to enhance health and to create
                      positive
                      personal
                      relationships. </li>
                  </ul>
                  <p class="tab-content">SEL Topics:</p>
                  <ul>
                    <li>Social Skills, Self Awareness, Self Management</li>
                    <li>Empathy</li>
                    <li> Positive Relationships</li>
                  </ul>
                  <p class="tab-content">Character Traits:</p>
                  <ul>
                    <li>Empathy, Respect, Responsibility</li>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="Teaching Strategies">
                <div class="mt-3">
      
                  <ol>
                    <li>Define Empathy</li>
      
                  </ol>
                  <p class="tab-content">Explain what empathy is using the definitions in the vocabulary section above. Ask
                    children to describe a time
                    when they have felt empathy. For example: <br>- If they have felt sad or excited when a friend felt sad or
                    excited, even though they weren’t affected by
                    whatever caused their friend to feel that way. (Such as being sad with your friend that he/she didn’t
                    make the soccer team or being excited for your friend when she got a new toy she had been
                    wanting.) <br>- If they ever hurt themselves and their mom said, “I’m sorry”, even though it’s not her
                    fault
                    they got
                    hurt. The mom is really saying “I feel bad that you are feeling bad,” which is showing empathy. <br>- See
                    the What is Empathy? Page for more examples</p>
                  <ol start="2">
                    <li>Talk about why empathy is important</li>
                  </ol>
                  <p class="tab-content">Empathy is important because it helps us get along with other people. It’s the basis
                    of
                    all healthy relationships
                    because we are able to understand how others are feeling and how our actions might impact them. It’s
                    important
                    for relationships with friends and family, but also for working well with teams, and succeeding in a job.
                    Empathy is
                    important to our whole society because it helps us cooperate and live peacefully with others. It helps us
                    understand how people see situations differently so we can treat each other with courtesy and find
                    compromises.
                    One way to see how empathy is important is to consider what life would be like if nobody cared how you
                    felt
                    about
                    anything. What if everyone just said and did whatever they wanted, without caring how it would impact
                    others?</p>
                  <ol start="3">
                    <li>alk about strategies for building empathy</li>
                  </ol>
                  <p class="tab-content">Empathy has two parts: feeling someone’s emotions and understanding their
                    perspective.
                  </p>
                </div>
              </mat-tab>
      
              <mat-tab label="Practice with some examples">
                <div class="mt-3">
                  <p class="tab-content">Putting yourself in someone else’s shoes- In each scenario in the book, or using the
                    “Putting yourself in
                    someone else’s shoes” Worksheet, discuss how each person feels in the same situation. Some students may
                    identify with one character more than the other. Have them “trade places” and tell how the character they
                    don’t
                    identify with may be feeling.
                    <br>- Discuss each situation from both parties’ perspectives and point how they are different. (For
                    example, the boy who is pointing and joking about his friend’s haircut thinks he is just being funny.
                    The boy being pointed out feels embarrassed and insulted.) <br>- Discuss how each situation could have
                    turned out differently if the children had considered how the
                    other person might feel. (For example, if the girl had thought about how her mom would feel before
                    she left messy footprints in the living room, they might have enjoyed eating the berries together
                    instead of unhappily cleaning up the mess.)
                  </p>
      
                </div>
              </mat-tab>
              <mat-tab label="Suggested questions ">
                <div class="mt-3">
                  <ul>
                    <li>What does it mean to put yourself in someone else’s shoes?</li>
                    <li>How empathetic am I?</li>
                    <li>How can I better show empathy toward others? </li>
                  </ul>
      
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'not_be_lazy'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/not_be_lazy.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>Not Be Lazy:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Not Lazy</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Focus">
                <div class="mt-3">
                  <p class="tab-content">A lazy person is someone who does not like to work hard. <br>
                    Someone who is not lazy will always work hard and not take shortcuts.</p>
                </div>
              </mat-tab>
              <mat-tab label="Practical Application">
                <div class="mt-3">
                  <p class="tab-content">Do you always clean up your toys and put them in their proper place or do you just
                    pile
                    them all under your bed? Do you put your dirty clothes in the laundry bin or do you leave them on the
                    floor?
                    In the morning, do you get up and get ready right away or do you lie in bed expecting your parents to
                    change
                    your clothes and get you out of bed? A person who is not lazy will always work hard and not take
                    shortcuts.
                    Consider the ants (or the busy bees). They work hard every day for their entire colony. They prepare their
                    food in summer and do not go hungry in winter.</p>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
      <div *ngIf="course === 'kindness'">
        <section class="bg-light course-details-image-section">
          <div class="container-fluid pb-3">
            <div class="row">
              <div class="col-6">
                <img src="../../assets/images/courseimages/kindness.png" alt="hybrid_learning"
                  class="img-fluid">
              </div>
              <div class="col-6">
                <h1>kindness:</h1>
                <button class="btn btn-primary btn-larg">Inrol</button>
              </div>
            </div>
          </div>
        </section>
        <section class="course-details-content-section">
          <div class="container-fluid pb-3">
      
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/sel/">Sel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Kindness</li>
              </ol>
            </nav>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Focus">
                <div class="mt-3">
                  <p class="tab-content">We should be kind to our siblings, friends, and classmates. </p>
                </div>
              </mat-tab>
              <mat-tab label="Practical Application">
                <div class="mt-3">
                  <p class="tab-content">We should be kind (nice) to those around us. Discuss with the children what
                    types of behavior show kindness and what types do not. Our words can show kindness as well as our actions.
                    For example, when we include others in an activity we are showing kindness. Discuss with the children how
                    they feel when someone is kind to them versus when someone is mean to them.</p>
                </div>
              </mat-tab>
              <mat-tab label="Learning Objective">
                <div class="mt-3">
                  <p class="tab-content">Students will Learn basic ways to show kindness and respect in the class and learning
                    environment.</p>
                </div>
              </mat-tab>
              <mat-tab label="Activity">
                <div class="mt-3">
                  <p class="tab-content">This activity mirrors Kindness, which allows students to drop a stone in a bucket or
                    container-fluid of water and observe and discuss the ripple effect.
                    <br>
                    <strong>Kindness Pond: </strong>Bring in a large bowl of water that can accommodate each student dropping
                    a
                    small stone into and also accommodate the teacher (and possibly students) adding additional stones to over
                    the lesson.
                    <br>Each student takes a small stone, says something kind they have done that day or recently, and drops
                    the
                    stone in the water to demonstrate the ripple effect of their kind act. Encourage students to think about a
                    kind act they did recently (vs. a long time ago) and remind them, as Chloe was reminded, that it can be a
                    very small thing that they did (hold a door open for someone, smile at a classmate, etc.). If anyone
                    cannot
                    think of a kind act, give them the opportunity to state a kindness they experienced themselves, they
                    observed someone else do, or that they will try to do before the end of the day. Remind students that even
                    if they do not feel they have made kind choices so far today, they can always choose kindness moving
                    forward. Kindness is always a choice and they have infinite opportunity to make kind choices each day.
                  </p>
                </div>
              </mat-tab>
              <mat-tab label="Reflect">
                <div class="mt-3">
                  <p class="tab-content">Our kindness pond is going to be a part of our class for the entire RESPECT unit and
                    maybe longer! I want a way for us to physically see how many kind respectful acts we are doing in class
                    and
                    each time we drop a stone I want us to think about how our kind and respectful act can positively impact
                    other people. That is the ripple effect we talked about. Each time you do something that shows kindness
                    and
                    respect to someone else, you give them a feeling that lasts and inspires them to do something kind and
                    respectful for someone else. Think about how kind and respectful our class, our school, and even our world
                    would be if we rippled kindness and respect out to everyone else! That is going to be our goal this year.
                    Each time you see our kindness pond, think about doing something kind and respectful. And, each time I see
                    you all doing something kind and respectful, I will drop another stone in the pond. If you see others
                    being
                    kind and respectful, you can drop a stone, too! Let’s see how many kindness stones we can get in our pond
                    this month!</p>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
