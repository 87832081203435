import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BadgeInfo, BadgeViewInfo, PaymentView, ProfileTypeInfo, StdDiscountInfo, StudentData, StudentProfileInfo, StudentProfileInfoView, StudentProfileViewInfo, TermView, User, UserView, ViewInfo, StudentEmpathyViewInfo, StudentEmpathyInfo } from './user.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { 
    this.formData.UserInfo = new User();
    this.formData.StudentInfo = new StudentData();
    this.studentProfileInfoView.StudentProfileInfo = new StudentProfileInfo();
    this.studentProfileViewInfo.ProfileTypeInfo = new ProfileTypeInfo();
    this.badgeFormData.BadgeInfo = new BadgeInfo();

//     UserInfo:User;
// StudentInfo:StudentData;
// ClassMappingList:Array<any>;
// BatchMappingList:any[];
// TeacherInfo:TeacherData;
// ParentInfo:ParentData;
// ResetPasswordInfo:ResetPassword;
// AdminInfo:AdminData;
  }
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/User';
  readonly baseUrl = CONFIG.BASE_URL + 'api/User';
  // readonly baseUrl = 'https://api.hybridlearning.in/api/User';
  //readonly baseUrl = 'http://localhost/SAPI/api/User';
  // formData:User = new User();
  formData: UserView = new UserView();
  termView: TermView = new TermView();
  paymentView: PaymentView = new PaymentView();
  stdDiscountInfo: StdDiscountInfo = new StdDiscountInfo();
  viewInfo:ViewInfo = new ViewInfo()
  studentProfileViewInfo:StudentProfileViewInfo = new StudentProfileViewInfo();
  studentProfileInfoView:StudentProfileInfoView=new StudentProfileInfoView();
  StudentEmpathyViewInfo:StudentEmpathyViewInfo=new StudentEmpathyViewInfo();
  badgeFormData:BadgeViewInfo = new BadgeViewInfo();
  // registerUser(){
  //   return this.http.post(this.baseUrl+'/registeruser',this.formData);
  // }
  login() {
    var config = {
      username: this.formData.UserInfo.UserLogin,
      password: this.formData.UserInfo.Password
  };
  return this.http.post(this.baseUrl + '/login', config);
  }
  // updateUser(){
  //   return this.http.post(this.baseUrl+'/updateuser',this.formData);
  // }
  saveUser() {
    return this.http.post(this.baseUrl + '/saveuserinfo', this.formData);
  }
  getUserInfo(userId: any) {
    var config = {
      params: {
        userId: userId
      }
    };
    return this.http.get(this.baseUrl + '/getuser', config);
  }
  getMyInfo() {
    return this.http.get(this.baseUrl + '/getmyinfo');
  }
  getBranchList(schoolId: any) {
    var config = {
      params: {
        schoolId: schoolId
      }
    };
    return this.http.get(this.baseUrl + '/getbasicbranchinfo', config);
  }
  getUserList() {
    return this.http.get(this.baseUrl + '/getuserlist');
  }
  getUserListByParams(params) {
    return this.http.get(this.baseUrl + '/getuserlistWithYear', params);
  }
  getschoollogo(guid) {
    var config = {
      params: {
        guid: guid
      }
    };
    return this.http.get(this.baseUrl + '/getschoollogo', config);
  }
  getbatches(classIds: any) {
    var config = {
      params: {
        classIdList: classIds
      }
    };
    return this.http.get(this.baseUrl + '/getbatchesbyclassids', config);
  }
  getclasss(schoolId: number, branchId: number) {
    var config = {
      params: {
        schoolId: schoolId,
        branchId: branchId
      }
    };
    return this.http.get(this.baseUrl + '/getclasslistbyschoolbranch', config);
  }
  getnewguid() {
    return this.http.get(this.baseUrl + '/getnewguid');
  }
  getstudentpoints() {
    return this.http.get(this.baseUrl + '/getstudentpoints');
  }
  getstudentpointsbyId(stuId: any) {
    var config = {
      params: {
        userId: stuId
      }
    };
    return this.http.get(this.baseUrl + '/getstudentpointsbyId', config);
  }

  getStudentsByClassIds(classIdList: any) {
    var config = {
      params: {
        classIdList: classIdList
      }
    };
    return this.http.get(this.baseUrl + '/getstudentsbyclassIds', config);
  }
  getStudentChartiInfo(params: any) {
    return this.http.get(this.baseUrl + '/getstudentchartinfo', params);
  }
  getBasicStudentList(schoolid: number) {
    var config = {
      params: {
        schoolid: schoolid
      }
    };
    return this.http.get(this.baseUrl + '/getbasicstudentlist', config);
  }
  logOut() {
    return this.http.get(this.baseUrl + '/logout');
  }
  resetPassword() {
    var config = {
      Password: this.formData.ResetPasswordInfo.Password,
      NewPassword: this.formData.ResetPasswordInfo.NewPassword,
      ConfirmPassword: this.formData.ResetPasswordInfo.ConfirmPassword
    };
    return this.http.post(this.baseUrl + '/resetpassword', config);
  }

  // For student analytics chart info to display pie chart inside parent dashboard
  getStudentAnalyticsChartInfo(stuId: number, category: string, academicYearId: number) {
    var config = {
      params: {
        studentId: stuId,
        category: category,
        academicYearId: academicYearId,
      }
    };
    return this.http.get(this.baseUrl + '/getstudentanalyticschartinfo', config);
  }
  getuseracademicyearloginfo(userId: number) {
    var config = {
      params: {
        userId: userId,
      }
    };
    return this.http.get(this.baseUrl + '/getuseracademicyearloginfo', config);
  }
  searchuserwithsharedid(userSharedId: string) {
    var config = {
      params: {
        sharedUserId: userSharedId,
      }
    };
    return this.http.get(this.baseUrl + '/searchuserwithsharedid', config);
  }
  saveshareduserinfo() {
    return this.http.post(this.baseUrl + '/saveshareduserinfo', this.formData);
  }
  getshareduserinfo() {
    return this.http.get(this.baseUrl + '/getshareduserinfo');
  }
  getuserloginfo(config: any) {
    return this.http.get(this.baseUrl + '/getuserloginfo', config);
  }

  gettermlist() {
    return this.http.get(this.baseUrl + '/gettermlist');
  }
  gettermlistbyacademicyearid(academicyearid: number) {
    var config = {
      params: {
        academicYearId: academicyearid,
      }
    };
    return this.http.get(this.baseUrl + '/gettermlistbyacademicyearid', config);
  }
  gettermview(termid: number) {
    var config = {
      params: {
        termId: termid,
      }
    };
    return this.http.get(this.baseUrl + '/gettermview', config);
  }
  saveterm() {
    return this.http.post(this.baseUrl + '/saveterm', this.termView);
  }
  getpaymentlist() {
    return this.http.get(this.baseUrl + '/getpaymentlist');
  }
  getpaymentview(paymentid: number) {
    var config = {
      params: {
        paymentId: paymentid,
      }
    };
    return this.http.get(this.baseUrl + '/getpaymentview', config);
  }
  savepaymentinfo() {
    return this.http.post(this.baseUrl + '/savepaymentinfo', this.paymentView);
  }
  getstudentdiscountinfo(studentid: number, academicyearid: number) {
    var config = {
      params: {
        studentId: studentid,
        academicyearId: academicyearid,
      }
    };
    return this.http.get(this.baseUrl + '/getstudentdiscountinfo', config);
  }
  savestudentdiscountinfo() {
    return this.http.post(this.baseUrl + '/savestudentdiscountinfo', this.stdDiscountInfo);
  }
  getstudentfeeinfo(studentid:number){
    var config = {
      params: {
        studentId: studentid,
      }
    };
    return this.http.get(this.baseUrl + '/getstudentfeeinfo', config);
  }
   getpaymentUrl(stdFeeInfo: any){    
    return this.http.post(this.baseUrl + '/getpaymentUrl', stdFeeInfo);
  }
  processPaidPayment(stdFeeInfo: any){    
    return this.http.post(this.baseUrl + '/ProcessPaidPayment', stdFeeInfo);
  }
  processCancelledPayment(stdFeeInfo: any){    
    return this.http.post(this.baseUrl + '/ProcessCancelledPayment', stdFeeInfo);
  }
  saveuseregisterinfo(){
    
    return this.http.post(this.baseUrl + '/saveuseregisterinfo', this.viewInfo);
  }
  getstudentnewchartinfo(params:any){
    
    return this.http.get(this.baseUrl + '/getstudentnewchartinfo', params);
  }
  getstdNewChartInfo(params:any){
    
    return this.http.get(this.baseUrl + '/getstdNewChartInfo', params);
  }

  getstduserlist() {
    return this.http.get(this.baseUrl + '/getstduserlist');
  }
  getparentuserlist() {
    return this.http.get(this.baseUrl + '/getparentuserlist');
  }
  getteacheruserlist() {
    return this.http.get(this.baseUrl + '/getteacheruserlist');
  }
  
  getschool(schoolid){
    var config = {
      params: {
        schoolId: schoolid,
      }
    };
    return this.http.get(this.baseUrl + '/getschool', config);
  }
  updateschool(formdata:any){
    return this.http.post(this.baseUrl + '/updateschool', formdata);
  }


  saveStudentProfileListInfo() {
    return this.http.post(this.baseUrl + '/saveStudentProfileListInfo',this.studentProfileViewInfo);
  }

  //student-profile service
  saveStudentProfileInfo() {
    return this.http.post(this.baseUrl + '/saveStudentProfileInfo',this.studentProfileInfoView);
  }
  saveStudentEmpathyInfo(){
    return this.http.post(this.baseUrl + '/saveStudentEmpathyInfo',this.StudentEmpathyViewInfo);
  }
  getStudentProfileInfoList(studentid){
    var config = {
      params: {
        studentId: studentid,
      }
    };
    return this.http.get(this.baseUrl + '/getStudentProfileInfoList', config);
  }

  // getStudentProfileInfoList() {
  //   return this.http.get(this.baseUrl + '/getStudentProfileInfoList');
  // }
  getStudentEmpathyInfoList(studentid){
    var config = {
      params: {
        studentId: studentid,
      }
    };
    return this.http.get(this.baseUrl + '/getStudentEmpathyInfoList', config);
  }
  getuserBadgeInfoList(studentid){
    var config = {
      params: {
        userId: studentid,
      }
    };
    return this.http.get(this.baseUrl + '/getuserBadgeInfoList', config);
  }
  getuserBadgeInfoListByClassId(classId:number, userType:string){
    var config = {
      params: {
        classId : classId ,
        userType: userType
      }
    };
    return this.http.get(this.baseUrl + '/getuserBadgeInfoListByClassId', config);
  }
  getUserBadgeViewInfo(badgeId:number,studentid:number){
    var config = {
      params: {
        badgeId:badgeId,
        userId: studentid,
      }
    };
    return this.http.get(this.baseUrl + '/getUserBadgeViewInfo', config);
  }
  saveUserBadgeViewInfo(){
    return this.http.post(this.baseUrl + '/saveUserBadgeViewInfo',this.badgeFormData);
  }
}
