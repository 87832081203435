import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-answer-list-component',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './answer-list-component.component.html',
  styleUrl: './answer-list-component.component.scss'
})
export class AnswerListComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @Input() questionId: string = '';
  answerList: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.answerList=[
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 1",
        questionId:"1",
        amswerId:"1"
      },
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 2",
        questionId:"1",
        amswerId:"2"
      },
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 3",
        questionId:"1",
        amswerId:"3"
      },
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 4",
        questionId:"1",
        amswerId:"4"
      },
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 5",
        questionId:"1",
        amswerId:"5"
      },
      {
        subject:"test subject",
        video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
        userName:"test 6",
        questionId:"1",
        amswerId:"6"
      },
    ]
  }
}
