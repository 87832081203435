<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Upload Book</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Books Name' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Books Name' | translate}}" name="BookName"  
                                    #BookName="ngModel" 
                                    [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.BookName"
                                    required class.invalid="BookName.invalid && BookName.touched">

                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Author' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Author' | translate}}" name="Author"  
                                    #Author="ngModel" 
                                    [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.Author"
                                    required class.invalid="Author.invalid && Author.touched">

                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue"
                                    timepicker required class.invalid="StartDate.invalid && StartDate.touched">
                            </div>
    
                        </div>
    
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.EndDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker [bsValue]="bsValue" [minDate]="minDate" required
                                    class.invalid="EndDate.invalid && EndDate.touched">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row m-0">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group mb-0">
                            <label>{{'Source of reading ' | translate}}*</label>
                            <select class="form-control form-control-sm appearance-auto" name="TypeId" #TypeId="ngModel" 
                            [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.SourceId"  required
                                class.invalid="TypeId.invalid && TypeId.touched">                                
                                <option *ngFor="let booksource of service.formDataReadingBooks.BookSourceInfo" [value]="booksource.Id">{{booksource.Name}}</option>
                            </select>

                        </div>
                    </div>
                 
                    <div class="col-2 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                        <div class="form-group mb-0">
                            <label>{{'Hours Spent ' | translate}}*</label>
                            <input class="form-control form-control-sm" type="number"
                                placeholder="{{'Hours Spent ' | translate}}" name="HoursSpent "
                                name="HoursSpent"  
                                #HoursSpent="ngModel" 
                                [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.Hours"
                                required class.invalid="HoursSpent.invalid && HoursSpent.touched">                                
                        </div>
                    </div>
                    <!-- <div class="col-2 col-sm-12 col-md-6 col-lg-2 col-xl-2 px-2">
                        <div class="form-group mb-0">
                            <label>{{'Book Picture ' | translate}}*</label>
                            <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                             name="service.formData.FileId" 
                             (updatedResponseEmitter)="filesuploaded($event)" 
                             title="{{'Upload Image' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                            </mb-large-files-uploader>
                        </div>
                    </div>
                    <div>
                        <div class="row form-row m-0">
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <label>{{'Upload Image' | translate}}</label>
                                    <input class="form-control form-control-sm" type='file'
                                        (change)="selectDocumentFile($event)" name="Url" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <img [src]="url"
                                        *ngIf="(format==='image' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && url"
                                        height="200">
                                    <iframe [src]="urlSafe" *ngIf="fileType === 'pdf' && url" frameborder="0"
                                        height="200"></iframe>
                                </div>
                            </div>

                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="form-group mb-0">
                                <label>{{'Reading outcome ' | translate}}*</label>
                                <input class="form-control form-control-sm" type="textarea"
                                    placeholder="{{'Reading outcome ' | translate}}" name="ReadingOutcome ">                                
                            </div>
                        </div>
                    </div> -->
                    <div class="row form-row m-0 p-0">                    
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="form-group mb-0">
                                <label>{{'Reading outcome ' | translate}}*</label>
                                <textarea
                                    class="form-control form-control-sm appearance-auto resizebele-false"
                                    name="ReadingOutcome " #ReadingOutcome="ngModel"
                                    [(ngModel)]="service.formDataReadingBooks.StudentBookInfo.Outcome"
                                    required class.invalid="ReadingOutcome.invalid && ReadingOutcome.touched"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-c m-0  bg-white p-2">
                        <label class="m-0 p-0">{{'Book Picture ' | translate}}*</label>
                        <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                <div class="form-group mb-0" >
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                     name="DocId" [(ngModel)]="DocId" 
                                     (updatedResponseEmitter)="filesuploaded($event)" 
                                     title="{{'Upload File' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                                    </mb-large-files-uploader>
                                </div>
                            </div>
                            <!-- <div class="col-10">

                                <div class="form-group mb-0">
                                    <mb-large-files-uploader
                                        (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="DocId" [(ngModel)]="DocId"
                                        #FileId="ngModel" required
                                        class.invalid="FileId.invalid && FileId.touched"
                                        (updatedResponseEmitter)="filesuploaded($event)"
                                        title="{{'Upload Image' | translate}}" canUpload="true"
                                        canSendData="true" saveButtonText="" saveButton="false">
                                    </mb-large-files-uploader>
                                </div>
                               
                            </div> -->
                        </div>
                    </div>
                </div>
               

            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="saveDocument()" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>