import { formatDate, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { CommonService } from 'src/app/shared/common.service';
import { DairyService } from 'src/app/shared/dairy.service';
import { EditclassDairyComponent } from '../editclass-dairy/editclass-dairy.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-class-dairy',
    templateUrl: './class-dairy.component.html',
    styleUrls: ['./class-dairy.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, BsDatepickerModule, NgIf, MatTableModule, TranslateModule]
})
export class ClassDairyComponent implements OnInit {
  public bsModalRef:BsModalRef;
  frameworkComponents;
  dairyRowData:any;
  dairyRowSelection;
  dairyDefaultColDef;
  dairyColumnDefs;
  dairyGridOptions:any;
  gridApi;
gridColumnApi;


datepickerConfig : BsDatepickerConfig;
  classList:any[] = [];
  minDate = new Date(); 
selectedDate:any=new Date();
selectedClass:number=1;
  config = {
    params: {
      classId: 1,
      dairyDate: formatDate(new Date(), 'dd-MM-yyyy', 'en'),
    }
  };
  constructor(public dairyServ: DairyService,public commonService:CommonService,public modalService: BsModalService,) { 
   this.commonService.RefreshGrid$.subscribe(
      item => {
        
        this.getClassDairy();
       }
    );
  }

  ngOnInit(): void {
    this.grigColReady();
    this.getClassDairy();
    this.getClass();


   
  }

  grigColReady(){
     
this.dairyColumnDefs = [
  { 
    headerName:"ClassDairyMasterId",
    field: 'ClassDairyMasterId', cellRendererFramework: CustommenuComponent, 
    cellRendererParams : { MenuList : []},
    
    filter:false, width:60,maxWidth: 60, 
    headerComponent: 'customHeader', 
    headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  }, 
  { field: 'Subject', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'DairyNotes', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'DairyDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  ];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.dairyDefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
  sortable: true,
  floatingFilter: true,
  };
  }
  getClassDairy() {
    this.dairyServ.getClassDairyViewInfo(this.config).subscribe((res: any) => {
      // debugger;
      this.dairyRowData = res.ClassDairyInfo;
      this.dairyRowData.shift();      
      this.dairyServ.formData = res;
      this.dataSource = res.ClassDairyInfo;
      this.emptyDataSource = this.dataSource.length >0?[]:[{DataNotFound:'No records found'}];      
    })
  }
  onDairyReady(params){
    this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  setTimeout(() =>  {
    this.getClassDairy();
  }, 300);
  }
  onDairyReload(event){

  }
  serchDairy(){
    this.config.params.dairyDate = formatDate(new Date(this.selectedDate), 'dd-MM-yyyy', 'en');
    this.config.params.classId = this.selectedClass;
    this.getClassDairy();
  }
  changeClass(){
    this.config.params.classId = this.selectedClass;
  }
  changeDate(){
    // this.config.params.dairyDate = ""+parseInt(formatDate(new Date(this.selectedDate), 'dd-mm-yyyy', 'en'));
  }
  editDairy(id, that){
    const initialState = {
      list: [
        id
      ]      
    };
    this.bsModalRef = this.modalService.show(EditclassDairyComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  }
  deleteDairy(id, that){}
  getClass(){
    this.commonService.getclasses().subscribe((res:any)=>{
      this.classList = res;
    });
  }


  dataSource: any[] = [];
  emptyDataSource :any[] = [];
  displayedColumns: string[] = ['Subject', 'Dairy Notes', 'Dairy Date', 'Dairy Action'];

  updateDairy(id){
    var arr = this.dairyServ.formData.ClassDairyInfo.filter(i => i.ClassDairyMasterId === id);
    var todaydate = new Date();
    if(this.commonService.getdatediffdays(arr[0].DairyDate, todaydate) >= 0)
    {
      const initialState = {
        list: [
          {id}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditclassDairyComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});        
    }
  }
  deleteData(id){}
  openClassDairyModel(){
    const initialState = {
      list: [
        {id:0}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditclassDairyComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  }
}
