<div class="modal-dialog modal-md m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{list[0].action}} {{list[0].name}} {{list[0].action=="Edit"?" / "+list[0].data.Name:""}} </h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="">Think Feel</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            #Think_Feel="ngModel" name="Think_Feel"
                            [(ngModel)]="EmpathyInfo.Think_Feel" placeholder="ThinkFeel">
                            </textarea>
                        </div> 
                        <div class="col-6 mb-3">
                            <label for="">See</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            [(ngModel)]="EmpathyInfo.See" placeholder="See" name="See">
                            </textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="">Hear</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            [(ngModel)]="EmpathyInfo.Hear" placeholder="Hear" name="Hear">
                            </textarea>
                        </div> 
                        <div class="col-6 mb-3">
                            <label for="">SayDo</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            [(ngModel)]="EmpathyInfo.Say_Do" placeholder="Saydo" name="Say_Do">
                            </textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="">Pains</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            [(ngModel)]="EmpathyInfo.Pains" placeholder="Pains" name="Pains">
                            </textarea>
                        </div> 
                        <div class="col-6 mb-3">
                            <label for="">Gains</label>
                            <textarea class="form-control form-control-sm" rows="3" cols="3" type="textarea"
                            [(ngModel)]="EmpathyInfo.Gains" placeholder="Gains" name="Gains">
                            </textarea>
                        </div>
                    </div>

                </div>            
            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" *ngIf="list[0].action=='Add'" (click)="saveEmpathyInfo()" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>