<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
           <div class="mt-3 mb-3">
            <div class="modal-header p-1 bg-theam">
                <h3 class="modal-title h3 m-1" >Parent Shared Documents</h3>
              </div>
              
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0">               
                <ag-grid-angular #agGrid
                    style="width: 100%; height:calc(100vh - 232px);min-height: 200px;"
                    class="ag-theme-alpine"
                    id="PSDList"
                    [ensureDomOrder]="true"
                    [enableCellTextSelection]="true"                            
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [rowData]="PSDListData"
                    (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    >
                </ag-grid-angular>            
            </div>
           </div>
        </div>
    </div>
</div>
 