
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { ClassTimeTableInfo, TimetableViewData } from './timetable.model';

@Injectable({
  providedIn: 'root'
})
export class TimetableService {
  readonly baseUrl = CONFIG.BASE_URL+'api/home';
  formData:TimetableViewData = new TimetableViewData();
  constructor(private http:HttpClient) { 
    this.formData.ClassTimeTableInfo = new ClassTimeTableInfo();
    // this.leaveStatusInfo = new LeaveStatusInfo();
  }
 

 
  getclasstimetablelistinfo(classId:number){
    var config = {
      params: {
        classId: classId,
      }
    };
    return this.http.get(this.baseUrl+'/getclasstimetablelistinfo',config);
  }
  saveclasstimetableinfo(){
    return this.http.post(this.baseUrl+'/saveclasstimetableinfo',this.formData);
  }

 // For Parent and Student pass studentid, for teacher.. we don't need it.
 getclasstimetableinfo(classId:number,classTTId:number){
  var config = {
    params: {
      classId: classId,
      classTTId: classTTId,
    }
  };
  return this.http.get(this.baseUrl+'/getclasstimetableinfo',config);
  
}
 
}
