import { Component, OnInit } from '@angular/core';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import * as fileSaver from 'file-saver';
import { AgGridModule } from 'ag-grid-angular';
@Component({
    selector: 'app-unusedfiles',
    templateUrl: './unusedfiles.component.html',
    styleUrls: ['./unusedfiles.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class UnusedfilesComponent implements OnInit {
  list:any[]=[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  fileListData: any[];

  constructor(public notificationServ:NotificationService,public service:AssignemntService,public commonService:CommonService) {
    
    this.columnDefs = [
      { headerName: "File Name", field: 'Key', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150},
      { headerName: "File Path", field: 'Value', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
      { headerName: "File Size", field: 'ValueLong', sortable: false, filter: 'agTextColumnFilter'  ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },      
   ];
   this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
   }

   ngOnInit(): void {
  }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getfiles();
  }
  getfiles(){
    this.commonService.getunusedfiles().subscribe((res : any) => {
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  deleteFiles(){
    if(this.fileListData && this.fileListData.length > 0){
    this.commonService.deleteunusedfiles(this.fileListData).subscribe((res : any) => {
      this.getfiles()
    });
  }
  }
  downloadFiles(){
    if(this.fileListData && this.fileListData.length > 0){
      this.commonService.zipanddownload(this.fileListData).subscribe((res:any)=>{
        fileSaver.saveAs(res, 'filedownloads.zip');
      });
      // this.fileListData.forEach(item =>{
      //   this.commonService.downloadFile(item.Value).subscribe((response:any) => {
      //     let blob:any = new Blob([response], { type: 'image/png;' });
      //     //var url = window.URL.createObjectURL(blob);
      //     //window.open(url);
      //     //window.location.href = response.url;
      //     fileSaver.saveAs(blob, item.Key);
      //   }), error => console.log('Error downloading the file'),
      //                () => console.info('File downloaded successfully');
      // });
    
  }
  }
}
