import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropdownSettings,
  NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { EventService } from '../shared/event.service';
import { NotificationService } from '../shared/notification.service';
import { formatDate, NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-editevent',
    templateUrl: './editevent.component.html',
    styleUrls: ['./editevent.component.scss'],
    standalone: true,
    // imports: [NgIf, FormsModule, NgFor, NgMultiSelectDropDownModule, BsDatepickerModule, TimepickerModule, PopoverModule, MatCheckboxModule, NgClass, DatePipe, TranslateModule]
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TimepickerModule, PopoverModule, MatCheckboxModule, NgClass, DatePipe, TranslateModule,NgMultiSelectDropDownModule]

})
export class EditeventComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  eventReload: Function;
  list:any[]=[];
  datepickerConfig : BsDatepickerConfig;
  classList:any[] = [];
  minDate = new Date();
  dropdownSettings:IDropdownSettings;
  role:string;
  yearList:any=[];
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  constructor(public service:EventService,public bsModalRef: BsModalRef,private notificationServ:NotificationService,private commonService:CommonService) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.role = commonService.role;
   }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All', 
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.service.getEvent(this.list[0].id).subscribe((res: any) => {
    this.service.formData = res;
    this.yearList = res.AcademicYearList;

    // if(this.yearList.length <=0){
    //   this.getAcademicYearList();
    // }
  });
  this.commonService.getclasses().subscribe((res:any)=>{
    this.classList = res;
  });
  this.service.formData.EventInfo.EventId = this.list[0].id;

  }
  // getAcademicYearList(){
  //   this.commonService.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
  //     res = res.slice().reverse();
  //     this.yearList = res;
  //     this.service.formData.EventInfo.AcademicYearId = res[0].Id;
  //     this.config = {
  //       params: {
  //         startDate: res[0].Code,
  //         endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
  //       }
  //     }
  //   });
  // }
  addEventInfo(form){
    this.service.formData.EventInfo.IsActive = true;
    this.service.formData.EventInfo.EndTimeISO = this.service.formData.EventInfo.EndTime;
    this.service.formData.EventInfo.StartTimeISO = this.service.formData.EventInfo.StartTime;
    this.service.formData.EventInfo.RoomId=this.commonService.generateRandomString(10)
    
    this.service.saveEvent().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();

      //   this.service.getEvents().subscribe((res: any) => {
      //     this.gridReload.emit(res);   ;
      // });
        this.eventReload()
      }else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){

      var ids = item.Id;
      for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+this.service.formData.ClassMappingList[i].Id;
      }
    }
  }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){

      var ids = '';
      for(var i=0;i<items.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+items[i].Id;
      }

    }
  }
}
