
<div class="container-fluid pt-3 pb-3 bg-light">
    <section>
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant1 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>10</mat-card-title>
                            <mat-card-subtitle>Programs</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
                                <i class="fa fa-fw fa-graduation-cap"></i>
                                <!-- <mat-icon aria-hidden="false">school</mat-icon> -->
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant2 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>110</mat-card-title>
                            <mat-card-subtitle>Learners</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
                                <i class="fa fa-fw fa-user-md"></i>
                                <!-- <mat-icon aria-hidden="false">school</mat-icon> -->
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant3 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>1</mat-card-title>
                            <mat-card-subtitle>Institutes</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
                                <i class="fa fa-fw fa-university"></i>
                                <!-- <mat-icon aria-hidden="false">school</mat-icon> -->
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="dasbord-info-card p-0 pt-3 bg-lgradiant4 shadow">
                <mat-grid-list cols="2" rowHeight="2:1" class="m-0">
                    <mat-grid-tile class="text-left">
                        <mat-card-header >
                            <mat-card-title>10</mat-card-title>
                            <mat-card-subtitle>Trainers</mat-card-subtitle>
                        </mat-card-header>
                    </mat-grid-tile>
                    <mat-grid-tile tile class="text-right overflow-visivle">
                        <mat-card-header >
                            <mat-card-title>
                                <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
                                <i class="fa fa-fw fa-users"></i>
                                <!-- <mat-icon aria-hidden="false">school</mat-icon> -->
                            </mat-card-title>
                        </mat-card-header>
                    </mat-grid-tile>
                  </mat-grid-list>
                <mat-card-actions class="m-0">
                    <button mat-button class="w-100">More info</button>
                </mat-card-actions>
            </mat-card>
        </div>
        
    </div>
    </section>
    <section>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
                <div layout="column" flex>   
                    <video layout-fill loop="loop" preload="auto" width='100%' controls controlsList="nodownload">
                    <source src="../../../assets/media/videos/Story Video.webm" type="video/webm"/>
                    </video>   
                  </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
                <img src="../../../assets/media/audios/audio_player1.png" onClick="document.getElementById('audio_play').play(); return false;" class="audio-poster-image" />
                <audio controls class="w-100 h-100" >
                    <source src = "../../../assets/media/audios/TEC_CB1_Ch2_AtTheFarm_Story.mp3" type = "audio/mp3" />
                 </audio>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
                <img src="../../../assets/media/audios/audio_player1.png" onClick="document.getElementById('audio_play').play(); return false;" class="audio-poster-image" />
                <audio controls class="w-100 h-100" >
                    <source src = "../../../assets/media/audios/TEC_CB1_Ch2_AttheFarm_Sayitright_A.mp3" type = "audio/mp3" />
                 </audio>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
                <img src="../../../assets/media/audios/audio_player1.png" onClick="document.getElementById('audio_play').play(); return false;" class="audio-poster-image" />
                <audio controls class="w-100 h-100" id="audio_play">
                    <source src = "../../../assets/media/audios/TEC_CB1_Ch2_AttheFarm_Timetolisten.mp3" type = "audio/mp3" />
                 </audio>
                 
            </div>
        </div>
    </section>
    <section>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-3">
                <mat-card class="dasbord-info-card p-0">
                    <mat-card-header class="bg-primary">
                        <mat-card-subtitle class="pt-3 text-white">Registers</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <ag-grid-angular #agGrid
                        style="width: 100%; height:calc(70vh )"
                        class="ag-theme-alpine"
                        id="registerGrid"
                        [gridOptions]="registergridOptions"
                        [columnDefs]="registercolumnDefs"
                        [defaultColDef]="registerdefaultColDef"
                        [rowSelection]="registerrowSelection"
                        [rowData]="registerData"
                        >
                        </ag-grid-angular>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-3">
                
                <mat-card class="dasbord-info-card p-0 ">
                    <mat-card-header class="bg-primary">
                        <mat-card-subtitle class="pt-3 text-white">Don't Missout</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <ag-grid-angular #agGrid
                        style="width: 100%; height:calc(70vh )"
                        class="ag-theme-alpine"
                        id="myGrid"
                        [gridOptions]="register1gridOptions"
                        [columnDefs]="register1columnDefs"
                        [defaultColDef]="register1defaultColDef"
                        [rowSelection]="register1rowSelection"
                        [rowData]="register1Data"
                        >
                        </ag-grid-angular>
                    </mat-card-content>
                </mat-card>
            </div>
            
        </div>
    </section>
</div>