import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
declare const screenRecording: any;

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-screenrecorder',
  templateUrl: './screenrecorder.component.html',
  styleUrl: './screenrecorder.component.scss'
})
export class ScreenrecorderComponent {



  ngOnInit() {
      // This function calls for recording
    screenRecording()
  }
}
