import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs';
import { StudentListInfo } from '../shared/attendance.model';
import { AttendanceService } from '../shared/attendance.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-take-attendence',
    templateUrl: './take-attendance.component.html',
    styleUrls: ['./take-attendance.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatRadioModule, FormsModule]
})
export class TakeAttendanceComponent implements OnInit {
  classList:any=[];
  classId:number=0;
  appointmentId:number=0;
  list:any=[];
  studentList:any;
  attendanceTypeList:any;
  public bsModalRef: BsModalRef;
  urlSafe: SafeResourceUrl;
  re:any="\ ";
  CUSTOMCONST:any=CUSTOMCONST;
  className:string;
  constructor(public commonService:CommonService,public attendanceSer:AttendanceService,public modalService: BsModalService,
    public sanitizer: DomSanitizer,public notificationServ:NotificationService) {
    // console.log(this.list);
     
    // this.classId= this.list[0].classId?this.list[0].classId:0;
   }

  ngOnInit(): void {
    // this.attendanceSer.formdata.StudentListInfo = new StudentListInfo();
     this.classId= this.list[0].ClassId?this.list[0].ClassId:0;
     this.appointmentId = this.list[0].AppointmentId;
    this.getClass();
    this.getStudentAttendanceViewInfo(this.classId,this.appointmentId);
  }
  getClass(){
    this.commonService.getclasses().subscribe((res:any)=>{
      this.classList = res;
      let result = res.filter((obj) => {
        return obj.Id === this.list[0].ClassId;
      });
      Object.assign({}, [result])
      let { Name:cname }= result[0];
      this.className = cname;
      
      
      
    });
  }
  selectedClassChange(){
    let classid = this.classId;
    let appointmentId = this.appointmentId;
    this.getStudentAttendanceViewInfo(classid,appointmentId);
  }
  getStudentAttendanceViewInfo(classid:number,appointmentId:number){
    this.attendanceSer.getStudentAttendanceViewInfo(classid,appointmentId).subscribe((res:any)=>{
      this.attendanceSer.formdata = res;
      this.attendanceTypeList = res.MLAttendanceInfo;
      
      // console.log(res);

      res.StudentListInfo.forEach((item)=>{
        var re = /\\/gi;
        item.ProfileImageUrl.split('.');
        if(res.StudentListInfo.ProfileImageUrl !='')
        {
          item.ImageUrl = (item.ProfileImageUrl).replace(re, "/");
        }
      });
      
      
      this.studentList = res.StudentListInfo;
     
    });
  }
  
  saveStudentAttendance(){
    this.attendanceSer.formdata.AttendanceInfo = this.list[0]
    
      this.attendanceSer.saveStudentAttendanceViewInfo().subscribe((res:any)=>{
        if (res.IsSaved) {
          this.notificationServ.ShowInfo('Successfully saved!');
          this.commonService.CallOnRefreshCalender();
          this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        } else {
          this.notificationServ.ShowError(res.ErrorMessage);
        }
      })
  }
}
