<div class="user-form d-flex align-items-center">
    <div class="container-fluid">
        <div class="row justify-content-c m-0">
            <div class="col-md-12 p-0">
                <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Select Class</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="classId" [(ngModel)]="classId" (change)="changeClass()" required>
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList"  [value]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>&nbsp;</label>
                                <div class="form-control form-control-sm border-0 p-0">
                                    <button type="button" (click)="serchSubject()" class="btn btn-warning float-right btn-sm"
                                        type="button">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular 
                    #agGrid style="width: 100%; height:calc(100vh - 242px)" 
                    class="ag-theme-alpine"
                    id="myGrid" 
                    [gridOptions]="gridOptions" 
                    [columnDefs]="columnDefs" 
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection" 
                    [rowData]="rowData" 
                    (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)" 
                    [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>
                <!-- <div class="row border-0 m-0 py-2 justify-content-between">
                        
                    <div class="col-12  px-2">
                        <div class="form-group mb-0">
                            <div class="form-control form-control-sm border-0 p-0">
                                <button type="button" (click)="openusermodal()" class="btn btn-warning float-right"
                                    type="button">Search Shared Id</button>
                            </div>
                        </div>
                    </div>
                </div> -->
                </div>
                
            </div>
        </div>
    </div>
    
</div>