import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { QuestionmenuComponent } from 'src/app/question/questionmenu/questionmenu.component';
import { UserService } from 'src/app/shared/user.service';
import { CommonService } from 'src/app/shared/common.service';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { EditprofileComponent } from 'src/app/user/editprofile.component';
import { HelpComponent } from 'src/app/common/help.component';
import { EditProfileComponent } from './edit-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  attendance: string;
  SchoolName: string = "";
  bsModalRef: BsModalRef;
  profileList:any;
  profilrtypeList:any=[];
  profileTypeInfo:any=[];
  constructor(public service:UserService,public modalService: BsModalService,public commonService: CommonService) {
    this.getMyInfo();
   }

  ngOnInit(): void {
    // 
    
   
   

  }
  getMyInfo(){
    this.service.getMyInfo().subscribe((res: any) => {
      this.service.formData = res;
      console.log(res);
      
      this.attendance = res.StudentInfo.LastAttendended;
      this.SchoolName = res.SchoolList[0].Name;
      // this.service.formData.ClassMappingList.
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.service.formData.UserInfo.CroppedProfileFilepath = (res.UserInfo.CroppedProfileFilepath).replace(re, "/");
      }

      this.getStudentProfileInfoList(res.UserInfo.UserId);
    });
  }
  getStudentProfileInfoList(id:number=0){
    this.service.getStudentProfileInfoList(id).subscribe((res:any)=>{
      this.profileTypeInfo = res.ProfileTypeInfo;
      console.log("profileTypeInfo");
      console.log(this.profileTypeInfo);
      
    this.profileList = res.StudentProfileInfo;
      console.log(res);
    })
  }

  editProfile(UserId) {
    const initialState = {
      list: [
        { id: UserId }
      ]

    };
    this.bsModalRef = this.modalService.show(EditprofileComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });

    
  }
  openHelpTooltip(tooltipName:any){
    const initialState = {
      list: [
        { name: tooltipName }
      ]

    };
    this.bsModalRef = this.modalService.show(HelpComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-md col-12', ignoreBackdropClick: true });

  }
  EditprofileProfile(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditProfileComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL, initialState,class: 'modal-md mb-0',ignoreBackdropClick: true}); 
    // this.getStudentTravelInfo();
  }
}
