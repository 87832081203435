import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate',
  standalone: true
})
export class CustomDatePipe implements PipeTransform {
  private datePipe: DatePipe;

  constructor() {
    // Initialize DatePipe with the user's locale
    this.datePipe = new DatePipe(navigator.language);
  }

  transform(value: string, format: string = 'shortTime'): string | null {
    if (!value) return null;
    var sz=value.length
    if(value[sz-1]!='z' && value[sz-1]!='Z'){
      value=value+"Z"
    }
    // Create a Date object from the ISO string
    const date = new Date(value)
    
    // Convert the date to the local timezone format
    return this.datePipe.transform(date.toUTCString(), format);
  }
}
