<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <ag-grid-angular #agGrid
                style="width: 100%; height:calc(100vh - 150px);min-height: 200px;"
                class="ag-theme-alpine"
                id="surveytGrid"
                [gridOptions]="surveyGridOptions"
                [columnDefs]="surveyColumnDefs"
                [defaultColDef]="surveyDefaultColDef"
                [rowSelection]="surveyRowSelection"
                [rowData]="surveyRowData"
                (gridReady)="onSurveyReady($event)"
                [frameworkComponents]="frameworkComponents"
                (gridReload)="onSurveyReload($event)"
                ></ag-grid-angular>
            </div>
        </div>
    </div>
</div>
 