import { Component, OnInit } from '@angular/core';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, FontAwesomeModule, TranslateModule]
})
export class SidebarComponent implements OnInit {
  Isfliph:boolean=false;
  constructor() { }

  calendarIcon = faCalendarDays;
  ngOnInit(): void {
  }
  toggleSidebar(){
    this.Isfliph = document.querySelector(".sidebar.fliph")!= null?false:true
  }

}
