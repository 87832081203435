export class QRCode {
  QRCodeId: number=0;
  QrTypeId: number=0;
  Name: string='';
  Code: string='';
  RedirectUrl: string='';
  CreatedOn:string='';
  QRCodeImageUrl: string='';
  IsActive: boolean=true;
  QRText:string='';
  QRTextDisplayPosition:string='';
  QRTextColor:string='';
  QRColor:string='';
  QRBackgroundColor:string='';
  QRLogoBorderSize:number=0;
  QRLogoBorderColor:string='';
  QRLogo:any;
  QRTextBorderRequired:boolean;
  QRTextBorderColor:string='';
}
export class QRCodeView {
  QRCodeInfo:QRCode;
  IsSaved: boolean=false;
  ErrorMessage: string=null;
}
export class QRTypes {

  QrTypeId: number=0;
  Name: string='';
 
}