import { Component, OnInit } from '@angular/core';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { formatDate } from '@angular/common';
import { UserService } from '../shared/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-user-log-info',
    templateUrl: './user-log-info.component.html',
    styleUrls: ['./user-log-info.component.scss'],
    standalone: true,
    imports: [FormsModule, BsDatepickerModule, AgGridModule]
})
export class UserLogInfoComponent implements OnInit {

 
  list:any[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  logListData: any[];
  startDate:any;
  endDate:any;
  datepickerConfig : BsDatepickerConfig;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date(); 
  minDate = new Date();

  config = {
    params: {
      startDate: "",
      endDate: "",
      userId:0
    }
  };
  CUSTOMCONST:any=CUSTOMCONST; 
  constructor(public notificationServ:NotificationService,public service:AssignemntService,public commonService:CommonService,
    public userService:UserService, public modalService:BsModalService, public bsModalRef: BsModalRef) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.bsRangeValue = [this.bsValue, this.maxDate];


    this.startDate = new Date();
    this.endDate = new Date();
    this.config.params.userId = this.modalService.config.initialState.list[0].id;

    this.columnDefs = [
      { headerName:'Event', field: 'EventName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150},
      { headerName:'Date', field: 'EventDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
      { headerName:'IP', field: 'IPAdress', sortable: false, filter: 'agTextColumnFilter'  ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },      
   ];
   this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
   }

  ngOnInit(): void {
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getuserloginfo();
  }

  getuserloginfo(){
    // var startDate ="";
    // var endDate = "";
    if(this.startDate !== undefined && this.endDate  !== undefined){
      this.config.params.startDate = formatDate(this.startDate,'dd-MM-yyyy','en');
      this.config.params.endDate = formatDate(this.endDate,'dd-MM-yyyy','en');
    }
    this.userService.getuserloginfo(this.config).subscribe((res : any) => {
      // this.logListData =  res;
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }

  
  onSearch(form){
    this.getuserloginfo();
  }
  

}
