import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { HttpClient } from '@angular/common/http';
import { AdminShareDocInfo, AdminShareDocView } from './school-shared-doc.service.model';


@Injectable({
  providedIn: 'root'
})
export class SchoolSharedDocService {

  readonly baseUrl = CONFIG.BASE_URL + 'api/Admin';
  formData: AdminShareDocView = new AdminShareDocView();

  constructor(private http: HttpClient) {
    this.formData.AdminShareDocInfo = new AdminShareDocInfo();
  }


  getAdminShareListDocView() {
    return this.http.get(this.baseUrl + '/getAdminShareListDocView');
  }
  getAdminShareDocView(documentid: number) {
    var config = {
      params: {
        shareDocId: documentid,
      }
    };
    return this.http.get(this.baseUrl + '/getAdminShareDocView', config);
  }
  // saveAdminShareDocView(documentid){
  //   var config = {
  //     params: {
  //       documentId: documentid,
  //     }
  //   };
  //   return this.http.post(this.baseUrl+'/saveAdminShareDocView');
  // }
  saveAdminShareDocView() {
    return this.http.post(this.baseUrl + '/saveAdminShareDocView', this.formData);
  }


  getAdminShareListDocViewForAdmin() {
    return this.http.get(this.baseUrl + '/getAdminShareListDocViewForAdmin');
  }
}