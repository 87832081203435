<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1 w-100"
                *ngIf="questionservice.formData.QuestionMasterInfo.QuestionMasterId<=0">{{'AddQuestion' | translate}}
                <label class="float-right m-0" *ngIf="IsStore">Question No. {{questionInfo.QuestionNo}}</label></h3>
            <h3 class="modal-title h3 m-1 w-100" *ngIf="questionservice.formData.QuestionMasterInfo.QuestionMasterId>0">
                {{'EditQuestion' | translate}} <label class="float-right m-0" *ngIf="IsStore">Question No.
                    {{questionInfo.QuestionNo}}</label></h3>
            <!-- <h3 class="modal-title h3 m-1" >Create Online Assessments</h3> -->
        </div>
        <form novalidate form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div *ngIf="!IsStore">
                    <div class="row form-row m-0">
                        <div class="col-8">
                            <div class="form-group mb-0">
                                <label>{{'Name' | translate}}</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Name' | translate}}" name="Name" Name="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.Name" required
                                    class.invalid="Name.invalid && Name.touched">
                            </div>
                        </div>
                        <div class="form-group col-4 mb-0">
                            <label>Academic year</label>
                            <select class="form-control form-control-sm appearance-auto" name="AcademicYearId"
                                AcademicYearId="ngModel"
                                [(ngModel)]="questionservice.formData.QuestionMasterInfo.AcademicYearId">
                                <option [value]="0">Select</option>
                                <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Description' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text"
                                    placeholder="{{'Description' | translate}}" name="Description" Description="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.Description"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'SelectClass' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="ClassId"
                                    ClassId="ngModel" [(ngModel)]="questionservice.formData.QuestionMasterInfo.ClassId"
                                    (change)="classChange()">
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                                    <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Class
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'SelectSubject' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="SubjectId"
                                    SubjectId="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.SubjectId"
                                    (change)="sbjectChange()">
                                    <option [value]="0">Select Subject</option>
                                    <option *ngFor="let subject of subjectlist" [value]="subject.Id">{{subject.Name}}
                                    </option>
                                    <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Subject
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'SelectTopic' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="TopicId"
                                    TopicId="ngModel" [(ngModel)]="questionservice.formData.QuestionMasterInfo.TopicId"
                                    (change)="topicChange()">
                                    <option [value]="0">Select Topic</option>
                                    <option *ngFor="let topic of topiclist" [value]="topic.Id">{{topic.Name}}</option>
                                    <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Topic
                                    </option>
                                </select>
                            </div>
                        </div>



                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" StartDate="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    timepicker required class.invalid="StartDate.invalid && StartDate.touched">
                            </div>

                        </div>
                        <!-- <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartTime' | translate}}</label>
                          <ng-template popTemplate>
                            <timepicker [(ngModel)]="startTime" name="startTime" [showMeridian]="true"></timepicker>
                          </ng-template>
                          <input [ngModel]="startTime | date:'shortTime'" class="form-control form-control-sm" name="startTime" [popover]="popTemplate" [outsideClick]="true" />
                            </div>
                          </div> -->
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartTime' | translate}}</label>
                                <!-- <ng-template popTemplateStartTime>
                                    <timepicker [(ngModel)]="questionservice.formData.QuestionMasterInfo.StartTime" name="Start Time" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input
                                [ngModel]="questionservice.formData.QuestionMasterInfo.StartTime | date:'shortTime'"
                                class="form-control form-control-sm"
                                name="Start Time"
                                [popover]="popTemplateStartTime"
                                [outsideClick]="true"
                                StartTime="ngModel"
                                required class.invalid="StartTime.invalid && StartTime.touched"
                                /> -->
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="questionservice.formData.QuestionMasterInfo.StartTime"
                                        name="StartTime" class="form-control form-control-sm appearance-auto">
                                        <option *ngFor="let sTime of timeSlotList" [value]="sTime.Value">{{sTime.Value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" EndDate="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.EndDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    required class.invalid="EndDate.invalid && EndDate.touched" />
                            </div>

                        </div>
                        <!-- <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndTime' | translate}}</label>
                          <ng-template popTemplate1>
                            <timepicker [(ngModel)]="endTime" name="endTime" [showMeridian]="true"></timepicker>
                          </ng-template>
                          <input [ngModel]="endTime | date:'shortTime'" class="form-control form-control-sm" name="endTime" [popover]="popTemplate1" [outsideClick]="true" />
                            </div>
                          </div> -->
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndTime' | translate}}</label>
                                <!-- <ng-template popTemplateEndTime>
                                    <timepicker [(ngModel)]="questionservice.formData.QuestionMasterInfo.EndTime" name="EndTime" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input
                                [ngModel]="questionservice.formData.QuestionMasterInfo.EndTime | date:'shortTime'"
                                class="form-control form-control-sm"
                                name="EndTime"
                                [popover]="popTemplateEndTime"
                                [outsideClick]="true"
                                EndTime="ngModel"
                                required class.invalid="EndTime.invalid && EndTime.touched"
                                /> -->
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="questionservice.formData.QuestionMasterInfo.EndTime"
                                        name="EndTime" class="form-control form-control-sm appearance-auto">
                                        <option *ngFor="let eTime of timeSlotList" [value]="eTime.Value">{{eTime.Value}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'TotalQuestion' | translate}}</label>
                                <input class="form-control form-control-sm" type="number"
                                    placeholder="{{'TotalQuestion' | translate}}" name="NoOfQuestions"
                                    NoOfQuestions="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.NoOfQuestions">
                            </div>
                        </div>
                    </div>
                    <div class="row form-row m-0" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsAcademics" IsAcademics="ngModel"
                                [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsAcademics">{{'Academics' |
                                translate}}
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsHumanityLife" IsHumanityLife="ngModel"
                                [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsHumanityLife">{{'HumanityLife'
                                |
                                translate}}</mat-checkbox>

                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsSkills" IsSkills="ngModel"
                                [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsSkills">{{'Skills' |
                                translate}}
                            </mat-checkbox>
                        </div>

                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsLearnOutOfBox" IsLearnOutOfBox="ngModel"
                                [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsLearnOutOfBox">{{'LearnOutOfBox'
                                |
                                translate}}</mat-checkbox>
                        </div>

                    </div>
                </div>
                <div *ngIf="IsStore">
                    <div class="row form-row m-0">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'QuestionType' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="QuestionTypeId"
                                    QuestionTypeId="ngModel" [(ngModel)]="questionInfo.QuestionTypeId"
                                    (change)="questionTypeChange()">
                                    <option [value]="0">Select Type</option>
                                    <option *ngFor="let questiontype of questiontypelist" [value]="questiontype.Id">
                                        {{questiontype.Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" *ngIf="list[0].id > 0">
                            <div class="form-group mb-0">
                                <label>{{'QuestionNo' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="QuestionNo"
                                    QuestionNo="ngModel" [(ngModel)]="questionInfo.QuestionNo"
                                    (change)="questionNoChange()">
                                    <option *ngFor="let question of questionlist" [value]="question.QuestionNo">
                                        {{question.QuestionNo}}</option>

                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Marks' | translate}}</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Points' | translate}}" name="Points" Points="ngModel"
                                    [(ngModel)]="questionInfo.Points" required
                                    class.invalid="Points.invalid && Points.touched">
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 float-right">
                            <div class="form-group mb-0" >
                                <label class="float-right">Question No. {{questionInfo.QuestionNo}}</label>
                            </div>
                        </div> -->
                    </div>
                    <div class="row form-row m-0">
                        <div class="col-12">
                            <mat-card class="example-card min-h-fitscreen1 p-1">
                                <mat-card-content class="mb-0">
                                    <div class="row form-row m-0"
                                        *ngIf="(QuestionType == 'MC') || (QuestionType == 'MR') || (QuestionType == 'FIB')  || (QuestionType == 'Eassy')  || (QuestionType == 'TF') || (QuestionType == 'MF') || (QuestionType == 'MM')">
                                        <div class="col-12">
                                            <div class="form-group mb-0">
                                                <label><span>*</span>{{'Type Your Question' | translate}}</label>

                                                <angular-editor class="resizebele-false" name="Question"
                                                    Question="ngModel" [(ngModel)]="questionInfo.Question"
                                                    [config]="config" required
                                                    class.invalid="Question.invalid && Question.touched"></angular-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="QuestionType == 'MC'">
                                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                            <div class="row form-row m-0" *ngIf="i==0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">
                                                        <label>{{'Correct' | translate}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-11">
                                                    <div class="form-group mb-0">
                                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-row m-0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                            style="height: 54px;">
                                                            <div class="bg-theam text-center custom-p-1">
                                                                <label
                                                                    class="text-white">{{QindexLabel[i].text}}</label>
                                                            </div>
                                                            <!-- <div class="text-center custom-p-2">
                                                                <mat-radio-group  aria-label="Select an option" class="mat-radio-label-content-false" name="IsCorrect" IsCorrect="ngModel"  [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect">
                                                                    <mat-radio-button [checked]="qAnswer.IsCorrect" [value]="true"></mat-radio-button>
                                                                  </mat-radio-group>

                                                            </div> -->
                                                            <div class="text-center custom-p-2">
                                                                <mat-radio-group aria-label="Select an option"
                                                                    class="mat-radio-label-content-false"
                                                                    name="CorrectLabel-{{i}}" CorrectLabel="ngModel"
                                                                    [(ngModel)]="qAnswer.CorrectLabel"
                                                                    (change)="changeMC(questionInfo.QAnswerList.length, i )">
                                                                    <mat-radio-button
                                                                        [value]="QindexLabel[i].text"></mat-radio-button>
                                                                </mat-radio-group>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <div class="form-group mb-0">
                                                        <textarea
                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                            name="Answer{{i}}" Answer="ngModel"
                                                            [(ngModel)]="qAnswer.Answer" required
                                                            class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <img [src]="qAnswer.FilePath" alt=""
                                                        class="img img-fluid max-width-100px">
                                                    <div class="form-group mb-0">
                                                        <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader>
                                                    </div>
                                                </div>
                                                <div class="col-1" *ngIf="i>3">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'Correct' | translate}}</label> -->
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                            style="height: 54px;">
                                                            <div
                                                                class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                <mat-icon (click)="deleteRow(qAnswer)"
                                                                    class="text-danger cursor-pointer">delete_outline</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <button class="btn btn-primary" type="submit" (click)='addNewRow("MR")'>Add New
                                            Row</button>

                                    </div>
                                    <div *ngIf="QuestionType == 'Eassy'">

                                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                            <div class="row form-row m-0" *ngIf="i==0">

                                                <div class="col-12">
                                                    <div class="form-group mb-0">
                                                        <label>{{'Answer' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-row m-0">

                                                <div class="col-9">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'QUESTION OPTION' | translate}}</label> -->
                                                        <textarea
                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                            rows="10" name="qAnswer.Answer{{i}}" Answer="ngModel"
                                                            [(ngModel)]="qAnswer.Answer" required
                                                            class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <img [src]="qAnswer.FilePath" alt=""
                                                        class="img img-fluid max-width-100px">
                                                    <div class="form-group mb-0">
                                                        <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader>
                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                    </div>
                                    <div *ngIf="QuestionType == 'FIB'">

                                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                            <div class="row form-row m-0" *ngIf="i==0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">
                                                        <label>{{'Correct' | translate}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-8">
                                                    <div class="form-group mb-0">
                                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-row m-0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">

                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                            style="height: 54px;">
                                                            <div
                                                                class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                <label class="text-white"><span
                                                                        ngIF="i<9">0</span>{{i+1}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'QUESTION OPTION' | translate}}</label> -->
                                                        <textarea
                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                            name="qAnswer.Answer{{i}}" [(ngModel)]="qAnswer.Answer"
                                                            Answer="ngModel" required
                                                            class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <img [src]="qAnswer.FilePath" alt=""
                                                        class="img img-fluid max-width-100px">
                                                    <div class="form-group mb-0">
                                                        <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader>
                                                    </div>
                                                </div>
                                                <div class="col-1" *ngIf="i!=0">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'Correct' | translate}}</label> -->
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                            style="height: 54px;">
                                                            <div
                                                                class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                <mat-icon (click)="deleteRow(qAnswer)"
                                                                    class="text-danger cursor-pointer">delete_outline</mat-icon>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <button class="btn btn-primary" type="submit" (click)='addNewRow("FIB")'>Add New
                                            Row</button>
                                    </div>

                                    <div *ngIf="QuestionType == 'MR'">
                                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                            <div class="row form-row m-0" *ngIf="i==0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">
                                                        <label>{{'Correct' | translate}}</label>


                                                    </div>
                                                </div>
                                                <div class="col-11">
                                                    <div class="form-group mb-0">
                                                        <label>{{'QUESTION OPTION' | translate}}</label>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-row m-0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0">
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                            style="height: 54px;">
                                                            <div class="bg-theam text-center custom-p-1">
                                                                <label
                                                                    class="text-white">{{QindexLabel[i].text}}</label>
                                                            </div>
                                                            <div class="text-center custom-p-2 custom-mt-1">
                                                                <mat-checkbox class="example-margin"
                                                                    name="qAnswer.IsCorrect{{i}}" IsCorrect="ngModel"
                                                                    [(ngModel)]="qAnswer.IsCorrect"
                                                                    value="1"></mat-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <div class="form-group mb-0">
                                                        <textarea name=""
                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                            name="qAnswer.Answer{{i}}" Answer="ngModel"
                                                            [(ngModel)]="qAnswer.Answer" required
                                                            class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <img [src]="qAnswer.FilePath" alt=""
                                                        class="img img-fluid max-width-100px">
                                                    <div class="form-group mb-0">
                                                        <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader>
                                                    </div>
                                                </div>
                                                <div class="col-1" *ngIf="i>3">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'Correct' | translate}}</label> -->
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                            style="height: 54px;">
                                                            <div
                                                                class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                <mat-icon (click)="deleteRow(qAnswer)"
                                                                    class="text-danger cursor-pointer">delete_outline</mat-icon>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <button class="btn btn-primary" type="submit" (click)='addNewRow("MR")'>Add New
                                            Row</button>
                                    </div>
                                    <div *ngIf="QuestionType == 'TF'">
                                        <div class="row form-row m-0 mt-3">
                                            <div class="col-9">
                                                <div class="form-group mb-0">
                                                    <div class="appearance-auto mb-3">

                                                        <div class="custom-p-2">
                                                            <mat-radio-group aria-label="Select an option"
                                                                name="IsCorrect" IsCorrect="ngModel"
                                                                [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect"
                                                                (change)="changeTF()" required
                                                                class.invalid="IsCorrect.invalid && IsCorrect.touched">
                                                                <mat-radio-button class="mr-3"
                                                                    *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index;"
                                                                    [value]="QindexLabel[i].text">{{qAnswer.Answer}}</mat-radio-button>
                                                                <!-- <mat-radio-button  [value]="true">True</mat-radio-button>
                                                              <mat-radio-button  [value]="false">False</mat-radio-button> -->
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                                    class="img img-fluid max-width-100px">
                                                <div class="form-group mb-0">
                                                    <mb-large-files-uploader
                                                        (updatedAttachmentsEmitter)="largefileschange($event)"
                                                        name="FileId" [(ngModel)]="questionInfo.QAnswerList[0].FileId"
                                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                        title="{{'Upload Image' | translate}}" canUpload="true"
                                                        canSendData="true" saveButtonText="">
                                                    </mb-large-files-uploader>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                                            <div class="row form-row m-0" *ngIf="i==0">
                                                <div class="col-1">
                                                    <div class="form-group mb-0" >
                                                        <label>{{'Correct' | translate}}</label>


                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row form-row m-0 mb-2">
                                                <div class="col-1">
                                                    <div class="form-group mb-0 mb-2" >
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                                            <div class="bg-theam text-center custom-p-1">
                                                                <label class="text-white">{{QindexLabel[i].text}}</label>
                                                            </div>
                                                            <div class="text-center custom-p-2">
                                                                <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="IsCorrect" IsCorrect="ngModel"  [(ngModel)]="qAnswer.IsCorrect">
                                                                    <mat-radio-button value="{{QindexLabel[i].text}}"></mat-radio-button>
                                                                  </mat-radio-group>
                                                                  <mat-radio-group aria-label="Select an option"  name="IsCorrect" IsCorrect="ngModel"  [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect" (change)="changeTF()">
                                                                    <mat-radio-button class="mr-3" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index;" [value]="QindexLabel[i].text">{{qAnswer.Answer}}</mat-radio-button>
                                                                </mat-radio-group>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>
                                         -->


                                    </div>
                                    <div *ngIf="QuestionType == 'MF'">
                                        <section>
                                            <div class="row form-row m-0">
                                                <div class="col-12 mt-3">
                                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                                        class="example-radio-group" name="OptionType"
                                                        [(ngModel)]="questionInfo.OptionType">
                                                        <mat-radio-button class="example-radio-button mr-3"
                                                            *ngFor="let optiontype of optionTypeList"
                                                            [value]="optiontype">
                                                            {{optiontype}}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                                <div class="col-12 col-md-8">
                                                    <div class="row m-0"
                                                        *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                        <div class="col-6 p-0">
                                                            <div class="row form-row " *ngIf="i==0">
                                                                <div class="col-3 col-md-2">
                                                                    <div class="form-group mb-0">
                                                                        <label>{{'S.NO' | translate}}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-9 col-md-10">
                                                                    <div class="form-group mb-0">
                                                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row form-row">
                                                                <div class="col-3 col-md-2">
                                                                    <div class="form-group mb-0">
                                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                            style="height: 54px;">
                                                                            <div
                                                                                class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                                <label
                                                                                    class="text-white">{{qAnswer.GroupNo}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-9 col-md-10">
                                                                    <div class="form-group mb-0">
                                                                        <textarea name=""
                                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                                            name="qAnswer.Answer{{i}}" Answer="ngModel"
                                                                            [(ngModel)]="qAnswer.Answer" required
                                                                            class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row form-row " *ngIf="i==0">
                                                                <div class="col-3 col-md-2">
                                                                    <div class="form-group mb-0">
                                                                        <label>{{'S.NO' | translate}}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-9 col-md-10">
                                                                    <div class="form-group mb-0">
                                                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row form-row">
                                                                <div class="col-3 col-md-2">
                                                                    <div class="form-group mb-0">

                                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                            style="height: 54px;">
                                                                            <div
                                                                                class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                                <label
                                                                                    class="text-white">{{qAnswer.GroupNo1}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="col-9 col-md-10"
                                                                    *ngIf="questionInfo.OptionType == 'Text'">
                                                                    <div class="form-group mb-0">
                                                                        <textarea
                                                                            class="form-control form-control-sm appearance-auto resizebele-false"
                                                                            name="qAnswer.Answer1{{i}}"
                                                                            Answer1="ngModel"
                                                                            [(ngModel)]="qAnswer.Answer1" required
                                                                            class.invalid="Answer1.invalid && Answer1.touched"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-9 col-md-10"
                                                                    *ngIf="questionInfo.OptionType == 'Image'">
                                                                    <img [src]="qAnswer.FilePath" alt=""
                                                                        class="img img-fluid max-width-100px">
                                                                    <div class="form-group mb-0">
                                                                        <mb-large-files-uploader
                                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                            name="qAnswer.FileId"
                                                                            [(ngModel)]="qAnswer.FileId"
                                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                            title="{{'Upload Image' | translate}}"
                                                                            canUpload="true" canSendData="true"
                                                                            saveButtonText="">
                                                                        </mb-large-files-uploader>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-4">
                                                    <div class="row m-0 mb-2"
                                                        *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                        <div class="col-12" *ngIf="i==0">
                                                            <div class="form-group mb-0">
                                                                <label>{{'CORRECT ANSWER' | translate}}</label>

                                                            </div>
                                                        </div>
                                                        <div class="col-10">
                                                            <div class="d-flex">
                                                                <div class="form-group mb-0 mr-1"
                                                                    *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                        style="height: 54px;">
                                                                        <div class="bg-theam text-center custom-p-1">
                                                                            <label
                                                                                class="text-white">{{qAnswer1.GroupNo1}}</label>
                                                                        </div>
                                                                        <div class="text-center custom-p-2 custom-mt-1">
                                                                            <mat-radio-group
                                                                                aria-label="Select an option"
                                                                                class="mat-radio-label-content-false"
                                                                                name="{{i}}" CorrectLabel="ngModel"
                                                                                [(ngModel)]="qAnswer.CorrectLabel"
                                                                                required
                                                                                class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                                                                <mat-radio-button
                                                                                    value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                                                                            </mat-radio-group>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2" *ngIf="i>3">
                                                            <div class="form-group mb-0">
                                                                <!-- <label>{{'Correct' | translate}}</label> -->
                                                                <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                    style="height: 54px;">
                                                                    <div
                                                                        class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                        <mat-icon (click)="deleteRow(qAnswer)"
                                                                            class="text-danger cursor-pointer">delete_outline</mat-icon>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                        <button class="btn btn-primary" type="submit" (click)='addNewRow("MF")'>Add New
                                            Row</button>
                                    </div>
                                    <div *ngIf="QuestionType == 'MM'">
                                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                            <div class="row form-row m-0" *ngIf="i==0">
                                                <div class="col-12 mt-3">
                                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                                        class="example-radio-group" name="OptionType"
                                                        [(ngModel)]="questionInfo.OptionType">
                                                        <mat-radio-button class="example-radio-button mr-3"
                                                            *ngFor="let optiontype of optionTypeList"
                                                            [value]="optiontype">
                                                            {{optiontype}}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>

                                                <div class="col-4">
                                                    <div class="row form-row " *ngIf="i==0">
                                                        <div class="col-2">
                                                            <div class="form-group mb-0">
                                                                <label>{{'S.NO' | translate}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-10">
                                                            <div class="form-group mb-0">
                                                                <label>{{'QUESTION OPTION' | translate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="row form-row " *ngIf="i==0">
                                                        <div class="col-2">
                                                            <div class="form-group mb-0">
                                                                <label>{{'S.NO' | translate}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-10">
                                                            <div class="form-group mb-0">
                                                                <label>{{'QUESTION OPTION' | translate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group mb-0">
                                                        <label>{{'CORRECT ANSWER' | translate}}</label>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row form-row m-0">

                                                <div class="col-4">
                                                    <div class="row form-row">
                                                        <div class="col-2">
                                                            <div class="form-group mb-0">

                                                                <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                    style="height: 54px;">
                                                                    <div
                                                                        class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                        <label
                                                                            class="text-white">{{qAnswer.GroupNo}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-10">
                                                            <div class="form-group mb-0">
                                                                <textarea
                                                                    class="form-control form-control-sm appearance-auto resizebele-false"
                                                                    name="qAnswer.Answer{{i}}" Answer="ngModel"
                                                                    [(ngModel)]="qAnswer.Answer" required
                                                                    class.invalid="Answer.invalid && Answer.touched"></textarea>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-4">
                                                    <div class="row form-row">
                                                        <div class="col-2">
                                                            <div class="form-group mb-0">

                                                                <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                    style="height: 54px;">
                                                                    <div
                                                                        class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                        <label
                                                                            class="text-white">{{qAnswer.GroupNo1}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-10">
                                                            <div class="form-group mb-0"
                                                                *ngIf="questionInfo.OptionType == 'Text'">
                                                                <textarea
                                                                    class="form-control form-control-sm appearance-auto resizebele-false"
                                                                    name="qAnswer.Answer1{{i}}" Answer1="ngModel"
                                                                    [(ngModel)]="qAnswer.Answer1" required
                                                                    class.invalid="Answer1.invalid && Answer1.touched"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-9 col-md-10"
                                                            *ngIf="questionInfo.OptionType == 'Image'">
                                                            <img [src]="qAnswer.FilePath" alt=""
                                                                class="img img-fluid max-width-100px">
                                                            <div class="form-group mb-0">
                                                                <mb-large-files-uploader
                                                                    (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                    name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                                    (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                    title="{{'Upload Image' | translate}}"
                                                                    canUpload="true" canSendData="true"
                                                                    saveButtonText="">
                                                                </mb-large-files-uploader>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-3">
                                                    <div class="d-flex">
                                                        <div class="form-group mb-0 mr-1"
                                                            *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                style="height: 54px;">
                                                                <div class="bg-theam text-center custom-p-1">
                                                                    <label
                                                                        class="text-white">{{qAnswer1.GroupNo1}}</label>
                                                                </div>
                                                                <div class="text-center custom-p-2 custom-mt-1">
                                                                    <mat-radio-group aria-label="Select an option"
                                                                        class="mat-radio-label-content-false"
                                                                        name="{{i}}{{j}}" CorrectLabel="ngModel"
                                                                        [(ngModel)]="qAnswer.CorrectLabel" required
                                                                        class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                                                        <mat-radio-button
                                                                            value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                                                                    </mat-radio-group>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-1" *ngIf="i>3">
                                                    <div class="form-group mb-0">
                                                        <!-- <label>{{'Correct' | translate}}</label> -->
                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                            style="height: 54px;">
                                                            <div
                                                                class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                <mat-icon (click)="deleteRow(qAnswer)"
                                                                    class="text-danger cursor-pointer">delete_outline</mat-icon>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <button class="btn btn-primary" type="submit" (click)='addNewRow("MM")'>Add New
                                            Row</button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button *ngIf="IsStore" type="button" class="btn btn-primary" type="button"
                    (click)="prevQuestion()">Back</button>
                <!-- <button *ngIf="IsStore && questionInfo.QuestionNo != noofQuestions" type="button" (click)="addNewAnswer()"  [disabled]="form.invalid  || questionInfo.QuestionTypeId<=0" class="btn btn-primary"  type="button">Next</button> -->
                <!-- <button *ngIf="IsStore && (questionInfo.QuestionNo == noofQuestions || list[0].id > 0)" type="button" (click)="saveQuestionInfo(form)" class="btn btn-success"  type="button" >Save</button> -->
                <!-- <button *ngIf="!IsStore" type="button" class="btn btn-success" (click)="questionInfoStore(form)" [disabled]="form.invalid || questionservice.formData.QuestionMasterInfo.ClassId <= 0 || questionservice.formData.QuestionMasterInfo.SubjectId <= 0 || questionservice.formData.QuestionMasterInfo.TopicId <= 0" type="button">Start</button> -->
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>