import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import { Branch } from './branch.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http:HttpClient) { }
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/User';
  //readonly baseUrl = 'https://api.hybridlearning.in/api/User';
  readonly baseUrl = CONFIG.BASE_URL+'api/User';
  formData:Branch = new Branch();
  
  addBranch(){
   this.formData.IsActive = true;
    return this.http.post(this.baseUrl+'/registerbranch',this.formData);
  }
  updateBranch(){    
    return this.http.post(this.baseUrl+'/updatebranch',this.formData);
  }
  getBranchInfo(branchId: any){
    var config = {
      params: {
        branchId: branchId       
      }
    };
    return this.http.get(this.baseUrl+'/getbranch',config);
  }
}
