<div class="school-form d-flex align-items-center">
    <div class="container-fluid">
        <div class="row justify-content-c m-0">
            <div class="col-md-12 p-0">
                    <ag-grid-angular #agGrid
                    style="width: 100%; height:calc(100vh - 80px)"
                    class="ag-theme-alpine"
                    id="myGrid"
                    [gridOptions]="gridOptions"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [rowData]="rowData"
                    (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents"
                    >
                    </ag-grid-angular>
            </div>
        </div>
    </div>
 </div>