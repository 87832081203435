<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header p-1 bg-theam">
        <h3 class="modal-title h3 m-1" >{{'Assignment' | translate}}</h3>
      </div>
      <form novalidate #form="ngForm"  method="POST">
      <div class="modal-body p-2">

        <section class="bg-light">
            <div class="row">
                <div class="col-12">

                  <!-- <div class="card">
                    <div class="card-header">
                      <div class="row pt-2 pb-2">
                        <div class="col-12">
                            <div class="d-flex ">
                                <div class="assesment-icon mt-2"><img class="p-2 rounded" src="../../assets/images/icons/svg/test_passed.svg" alt=""> </div>
                                <div class="w-100 ml-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><h3 class="m-0">8 Questions</h3> </div>
                                        <div>
                                          <h3 class="m-0 d-flex">
                                            <span>00:00:00</span>
                                            <span>&nbsp; -  8/20</span>
                                          </h3>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h3 class="m-0">Formative Assignment<span class="text-muted">English Term</span></h3>
                                  </div>
                                    <div>
                                        <p class="m-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi, quaerat!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                  </div> -->

                    <mat-card class="example-card bg-light">
                        <mat-card-header *ngIf="!IsStart" class="m-0 d-flex">
                          <mat-card-title class="ml-0">{{questionservice.formData.QuestionMasterInfo.Name}}</mat-card-title>
                          <!-- <mat-card-subtitle class="ml-0">{{questionservice.formData.QuestionMasterInfo.Description}}</mat-card-subtitle> -->

                        </mat-card-header>
                        <mat-card-header *ngIf="IsStart" class="m-0 w-100 justify-content-center">

                            <mat-card-title class="m-0">
                                <div class="row form-row">
                                  <div class="col-12">
                                    <div class="d-flex mb-3 p-2 border bdr-top">
                                      <div class="assesment-icon mt-2"><img class="p-2 rounded" src="../../assets/images/icons/svg/test_passed.svg" alt=""> </div>
                                      <div class="w-100 ml-2 mr-2">
                                        <div class="d-flex justify-content-between align-items-end">
                                          <div class="">
                                            <span class="assesment-small-heading">{{noofQuestions}} Questions</span>
                                          </div>
                                          <div class="d-flex">
                                            <span class="assesment-small-heading mr-4">
                                              <!-- <countup-timer [countUpTimerConfig]="testConfig"></countup-timer> -->
                                            </span>
                                            <span class="assesment-small-heading">{{questionInfo.QuestionNo}}/{{noofQuestions}}</span>
                                          </div>
                                        </div>
                                        <div>
                                          <h3 class="m-0 pt-1 pb-1">{{questionservice.formData.QuestionMasterInfo.Name}}  <span class="text-muted">English Teem 1</span></h3>
                                        </div>
                                        <div>
                                          <p>{{questionservice.formData.QuestionMasterInfo.Description}}</p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                    <!-- <div class="col-md-6 col-12">
                                        <h2>{{noofQuestions}} Questions</h2>
                                    </div>

                                    <div class="col-md-6 col-12">
                                        <h2 class="text-md-right d-flex"> <span><countup-timer [countUpTimerConfig]="testConfig"></countup-timer> </span><span>&nbsp; -  {{questionInfo.QuestionNo}}/{{noofQuestions}}</span></h2>
                                    </div> -->
                                </div>
                                <!-- <div class="row">
                                  <h1>{{questionservice.formData.QuestionMasterInfo.Name}}</h1>
                                  <p>{{questionservice.formData.QuestionMasterInfo.Description}}</p>
                                </div> -->

                            </mat-card-title>


                          </mat-card-header>

                        <mat-card-content *ngIf="!IsStart">
                          <p>
                            {{questionservice.formData.QuestionMasterInfo.Description}}
                          </p>

                        </mat-card-content>
                        <mat-card-content *ngIf="IsStart">
                          <div *ngFor="let question of questionList">
                            <div  *ngIf="question.QuestionType == 'Eassy'"  class="mb-3 p-2 border bdr-left">
                              <h3>
                                <span>Q. {{question.QuestionNo}} </span>  <span class="float-right">Marks:  {{question.Points}}</span>
                                <div class="d-inline-block" [innerHTML]="question.Question"></div>
                              </h3>
                              <div class="col-12">
                                <img [src]="question.QAnswerList[0].FilePath" alt="" class="img img-fluid max-width-50px">
                              </div>
                              <div class="form-group mb-0" >
                                <label>Write Your Answer</label>
                                  <textarea class="form-control m-0 form-control-sm appearance-auto resizebele-false"  name="StrAnswer" #StrAnswer="ngModel"  [(ngModel)]="question.QAnswerList[0].StrAnswer" rows="10"></textarea>
                             </div>
                            </div>
                            <div  *ngIf="question.QuestionType == 'TF'"  class="mb-3 p-2 border bdr-left">
                              <h3>
                                <span>Q.{{question.QuestionNo }}  </span> <span class="float-right">Marks:  {{question.Points}}</span>
                                <div class="d-inline-block" [innerHTML]="question.Question"></div>
                              </h3>
                              <section>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group mb-0" >
                                      <div class="appearance-auto mb-3">
                                        <div class="custom-p-2">
                                          <mat-radio-group aria-label="Select an option"  name="IsAnswer" #IsAnswer="ngModel"  [(ngModel)]="question.QAnswerList[0].IsAnswer" (change)="changeTF()" required class.invalid="IsAnswer.invalid && IsAnswer.touched">
                                            <mat-radio-button class="mr-3" *ngFor="let qAnswer of question.QAnswerList; let i=index;" [value]="qAnswer.Answer">{{qAnswer.Answer}}</mat-radio-button>
                                          </mat-radio-group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section *ngFor="let qAnswer of question.QAnswerList; let i=index" >
                                <div class="row form-row">

                                  <div class="col-6">
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                  </div>
                                </div>
                              </section>

                              </div>
                              <div  *ngIf="question.QuestionType == 'MC'"  class="mb-3 p-2 border bdr-left">
                                <h3>
                                  <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:  {{question.Points}}</span>
                                  <div class="d-inline-block" [innerHTML]="question.Question"></div>
                                </h3>
                                <section *ngFor="let qAnswer of question.QAnswerList; let i=index" >
                                  <div class="row form-row">
                                      <div class="col-6">
                                        <div class="form-group mb-0" >
                                            <div class="appearance-auto mb-3">

                                                <div class="custom-p-2">
                                                  <mat-radio-group aria-label="Select an option" class="" name="StrAnswer-{{i}}" #StrAnswer="ngModel"  [(ngModel)]="qAnswer.StrAnswer" (change)="changeMC(question.QAnswerList.length, i )">
                                                      <mat-radio-button [value]="qAnswer.GroupNo">{{qAnswer.Answer}}</mat-radio-button>
                                                    </mat-radio-group>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                      <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                    </div>
                                  </div>
                                </section>

                                </div>

                            <div  *ngIf="question.QuestionType == 'MR'" class="mb-3 p-2 border bdr-left">
                              <h3>
                                <span>Q. {{question.QuestionNo}} </span>  <span class="float-right">Marks:  {{question.Points}}</span>
                                <div class="d-inline-block" [innerHTML]="question.Question"></div>
                              </h3>
                              <section *ngFor="let qAnswer of question.QAnswerList; let i=index" >
                                <div class="row form-row">
                                  <div class="col-6">
                                      <div class="form-group mb-0" >
                                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0" >
                                              <div class="custom-p-2 custom-mt-1">
                                                  <mat-checkbox class="" name="IsAnswer" #IsAnswer="ngModel" [(ngModel)]="qAnswer.IsAnswer">{{qAnswer.Answer}} </mat-checkbox>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-6">
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                  </div>
                                </div>
                              </section>

                            </div>
                            <div *ngIf="question.QuestionType == 'FIB'" class="mb-3 p-2 border bdr-left">
                              <h3>
                                <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:  {{question.Points}}</span>
                                 <div class="d-inline-block" [innerHTML]="question.Question"></div>
                              </h3>
                              <section *ngFor="let qAnswer of question.QAnswerList; let i=index" >
                                <div class="row form-row">
                                    <div class="col-6">
                                      <div class="form-group mb-0" >
                                          <input type="text" class="m-0 form-control form-control-sm" name="StrAnswer" #StrAnswer="ngModel"  [(ngModel)]="qAnswer.StrAnswer">
                                      </div>
                                  </div>
                                  <div class="col-6">
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                  </div>
                                </div>
                              </section>

                            </div>
                            <div  *ngIf="question.QuestionType == 'MF'"  class="mb-3 p-2 border bdr-left">
                              <h3>
                                <span>Q. {{question.QuestionNo}} </span>  <span class="float-right">Marks:  {{question.Points}}</span>
                                <div class="d-inline-block" [innerHTML]="question.Question"></div>
                              </h3>

                              <section  >

                                <div class="row form-row ">
                                    <div class="col-12 col-md-6">
                                      <div class="row m-0 mb-3 align-items-center" *ngFor="let qAnswer of question.QAnswerList; let i=index">
                                        <div class="col-6">
                                          <div class="form-group mb-0" >
                                              <div class="appearance-auto mb-3">
                                                  <div class="custom-p-2">
                                                      <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                                                  </div>
                                              </div>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="question.OptionType == 'Text'">
                                          <div class="form-group mb-0" >
                                              <div class="appearance-auto mb-3">
                                                  <div class="custom-p-2">
                                                      <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer1}}</p>
                                                  </div>
                                              </div>
                                          </div>
                                        </div>

                                        <div class="col-6" *ngIf="question.OptionType == 'Image'">
                                          <div class="form-group mb-0" >
                                            <div class="appearance-auto mb-3">
                                              <div class="custom-p-2">
                                                {{qAnswer.GroupNo1}}
                                                <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                              </div>
                                            </div>
                                         </div>
                                        </div>
                                      </div>
                                    </div>
                                <div class="col-12 col-md-6">
                                  <div class="row m-0 mb-3" *ngFor="let qAnswer of question.QAnswerList; let i=index">
                                    <div class="col-12">
                                      <div class="d-flex" >
                                        <div class="form-group mb-0 mr-1" >
                                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                              <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                  <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of question.QAnswerList; let j=index">
                                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                              <div class="bg-theam text-center custom-p-1">
                                                  <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                                              </div>
                                              <div class="text-center custom-p-2 custom-mt-1">
                                                  <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="{{i}}" #StrAnswer="ngModel"  [(ngModel)]="qAnswer.StrAnswer">
                                                      <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                                                    </mat-radio-group>

                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              </section>

                              </div>
                              <div  *ngIf="question.QuestionType == 'MM'"  class="mb-3 p-2 border">
                                <h3>
                                  <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:  {{question.Points}}</span>
                                   <div class="d-inline-block" [innerHTML]="question.Question"></div>
                                </h3>
                                <section *ngFor="let qAnswer of question.QAnswerList; let i=index" >
                                  <div class="row form-row">

                                      <div class="col-3">
                                        <div class="form-group mb-0" >
                                            <div class="appearance-auto mb-3">
                                                <div class="custom-p-2">
                                                    <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" *ngIf="question.OptionType == 'Text'">
                                      <div class="form-group mb-0" >
                                          <div class="appearance-auto mb-3">
                                              <div class="custom-p-2">
                                                  <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer}}</p>
                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                    <div class="col-3" *ngIf="question.OptionType == 'Image'">
                                      <div class="form-group mb-0">
                                        <div class="appearance-auto mb-3">
                                            <div class="custom-p-2">
                                              {{qAnswer.GroupNo1}}. <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  <div class="col-6">
                                    <div class="d-flex" >
                                      <div class="form-group mb-0 mr-1" >
                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                            <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                                            </div>
                                        </div>
                                    </div>
                                      <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of question.QAnswerList; let j=index">
                                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                              <div class="bg-theam text-center custom-p-1">
                                                  <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                                              </div>
                                              <div class="text-center custom-p-2 custom-mt-1">

                                                  <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="{{i}}" #IsCorrect="ngModel"  [(ngModel)]="qAnswer.StrAnswer">
                                                      <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                                                    </mat-radio-group>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                                  </div>
                                </section>


                                </div>
                          </div>
                           <!-- <section *ngFor="let question in questionservice.formData.question;let qi=index"> -->

                            <!-- </section> -->
                          </mat-card-content>
                          <mat-card-actions class="float-left m-0" *ngIf="IsStart">
                            <span>{{questionInfo.QuestionNo}} / {{noofQuestions}}</span>
                          </mat-card-actions>

                        <div class="clear-both"></div>
                        <mat-card-content *ngIf="(currentQuestionNo == noofQuestions)">
                          <p class="pt-5 m-0"><span class="text-danger">Note:-</span> Do you have anything to upload? </p>
                          <div class="col-4 mt-3 p-0">
                            <div class="form-group mb-0" >
                                <!-- <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)" (updatedResponseEmitter)="filesuploaded($event)" title="{{'Upload Image, PDF, Video' | translate}}" canUpload="true"  canSendData="true" saveButtonText="">
                                </mb-large-files-uploader> -->

                            </div>
                        </div>
                        </mat-card-content>
                      </mat-card>

                </div>
            </div>
        </section>


      </div>
      <div class="modal-footer p-1">
        <button class="btn btn-primary text-white mlr-4px" (click)="startQuiz()" *ngIf="!IsStart">Start</button>
        <!-- <button class="btn btn-primary text-white mlr-4px" (click)="prevQuestion()" *ngIf="IsStart && (questionInfo.QuestionNo > 1 )">Previous</button> -->
        <!-- <button class="btn btn-primary text-white mlr-4px" (click)="nextQuestion()" *ngIf="IsStart && (questionInfo.QuestionNo != noofQuestions)">Next</button> -->
        <!-- <button class="btn btn-primary text-white mlr-4px" *ngIf="IsStart && (questionInfo.QuestionNo > 1 noofQuestions)">Previous</button> -->
        <button class="btn btn-success text-white mlr-4px" *ngIf="IsStart" (click)="addStudentAnswers()">Submit</button>
        <button class="btn btn-danger text-white mlr-4px"  (click)="bsModalRef.hide()" aria-label="Close">Cancel</button>

      </div>
    </form>
    </div>
  </div>
