<div class="container-fluid bg-light pb-2 h-fittowindow">
    <div class="pt-3">
        <div class="row m-0">
            <div class="col-12 mb-3">
                <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="max-w-20px">
                        <span style="border: solid; border-radius: 50%;"><fa-icon [icon]="star"></fa-icon></span>
                    </div>
                    <div  style="width: calc(100% - 40px);"><h2 class="m-0">you have earnd 4 badge</h2></div>
                    <!-- <div class="max-w-150px">
                        <button class="btn btn-primary" (click)="openAddModel(0,'Add')">Add Badge</button>
                    </div> -->
                </div>
            </div>
            <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3" *ngFor="let badge of badgeInfoList.BadgeListInfo">
                <div class="card w-100 pt-3 shadow rounded">                                                                                              
                    <div class="icon-group position-relative d-flex justify-content-center align-items-center">
                       <img class="badge-bg" src="../../assets/images/icons//svg/badge.svg" alt="">
                       <img class="position-absolute" src="../../assets/images/icons//svg/bolice_badge.svg" alt="">
                    </div>
                    <div class="card-body pt-1 text-center">
                      <h3 class="card-title m-0">{{badge.Name}}</h3> 
                      <p class="subtitle">{{badge.Criteria}}</p>                                         
                      <p class="card-text">{{badge.ExpiryDate | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>                                       
            </div>
        </div>

       
    </div>
</div>
