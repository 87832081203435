
<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <div class="row border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Class</label>

                            <select class="form-control form-control-sm appearance-auto" name="selectedYear" [(ngModel)]="selectedClassId" (change)="changeClass()" required>
                                <option *ngFor="let class of classList"  [value]="class.Id">
                                {{class.Name}}
                                </option>
                            </select>

                        </div>
<!--
                        <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($badge)" /> -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <button type="button" (click)="serchbadges()" class="btn btn-warning float-right" type="button" >Search</button>
                    </div>
                </div>

                <section class="badge-tab-section">
                    <div class="container-fluid p-0">

                        <mat-tab-group animationDuration="0ms" class="bg-transparent border-0 dashboard-tab1"
                            (selectedTabChange)="activeTabChange($event)">
                            <mat-tab label="Student">
                                <ng-template mat-tab-label>

                                    <label class="m-0">Student
                                        <!-- <a (click)="openHelpTooltip('SBAssignment')" class="position-relative"><mat-icon>help_outline</mat-icon></a> -->
                                       </label>

                                </ng-template>
                                <div class="mt-3">
                                    <ag-grid-angular #agGrid
                                        style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                                        class="ag-theme-alpine"
                                        id="badgeGrid"
                                        [gridOptions]="badgeGridOptions"
                                        [columnDefs]="badgeColumnDefs"
                                        [defaultColDef]="badgeDefaultColDef"
                                        [rowSelection]="badgeRowSelection"
                                        [rowData]="badgeRowData"
                                        (gridReady)="onbadgeReady($event)"
                                        [frameworkComponents]="frameworkComponents"
                                        (gridReload)="onbadgeReload($event)"
                                        ></ag-grid-angular>
                                </div>
                            </mat-tab>
                            <mat-tab label="Teacher" >
                                <ng-template mat-tab-label>

                                    <label class="m-0"> Teacher
                                        <!-- <a (click)="openHelpTooltip('HBAssignment')" class="position-relative"><mat-icon>help_outline</mat-icon></a> -->
                                       </label>


                                </ng-template>
                                <div class="mt-3">
                                    <ag-grid-angular #agGrid
                                        style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                                        class="ag-theme-alpine"
                                        id="badgeGrid"
                                        [gridOptions]="badgeGridOptions"
                                        [columnDefs]="badgeColumnDefs"
                                        [defaultColDef]="badgeDefaultColDef"
                                        [rowSelection]="badgeRowSelection"
                                        [rowData]="badgeRowData"
                                        (gridReady)="onbadgeReady($event)"
                                        [frameworkComponents]="frameworkComponents"
                                        (gridReload)="onbadgeReload($event)"
                                        ></ag-grid-angular>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </section>



                <!-- <div class="card-footer text-right p-2 mt-2">
                    <button type="button" class="btn btn-primary" (click)="openMultibadge()" type="button" >Add Multi badge</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
