
    <div class="modal-dialog modal-xl m-0">
        <div class="modal-content">
            <div class="modal-header p-1 bg-theam">
                <h3 class="modal-title h3 m-1" *ngIf="leaveServ.formData.LeaveInfo.LeaveId<=0">Make Leave Request</h3>
                <h3 class="modal-title h3 m-1" *ngIf="leaveServ.formData.LeaveInfo.LeaveId>0">Edit Leave Request</h3>
            </div>
            <form novalidate #form="ngForm" autocomplete="off">
                <div class="modal-body p-2">

                    <div >
                        <div  class="row form-row m-0">
                            <div class="form-group col-4 mb-0" *mgIf="Role!='Teacher'">
                                <label>Select Child</label>
                                <select class="form-control form-control-sm appearance-auto" name="UserId" #UserId="ngModel"
                                [(ngModel)]="leaveServ.formData.LeaveInfo.UserId" >
                                  <option *ngFor="let child of childrenList" [value]="child.Id">{{child.Name}}</option>
                                </select>
                              </div>
                              <div class="form-group col-4 mb-0">
                                <label>Academic year</label>
                                <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel"
                                [(ngModel)]="leaveServ.formData.LeaveInfo.AcademicYearId">
                                  <!-- <option [value]="0">Select</option> -->
                                  <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                                </select>
                              </div>
                              <div class="form-group col-4 mb-0">
                                <label>Leave type</label>
                                <select class="form-control form-control-sm appearance-auto" name="LeaveTypeId" #LeaveTypeId="ngModel"
                                [(ngModel)]="leaveServ.formData.LeaveInfo.LeaveTypeId">
                                  <option [value]="0">Select</option>
                                  <option *ngFor="let leaveType of leaveTypeList" [value]="leaveType.Id">{{leaveType.Name}}</option>
                                </select>
                              </div>
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label>Reason</label>
                                    <textarea class="form-control form-control-sm resizebele-false" type="text" placeholder="Reason" name="Reason" #Reason="ngModel"
                                    [(ngModel)]="leaveServ.formData.LeaveInfo.Reason"></textarea>
                                </div>
                            </div>

                            <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                <div class="form-group mb-0">
                                    <label>StartDate</label>
                                    <input type="text"
                                    class="form-control form-control-sm"
                                    placeholder="StartDate"
                                    name="StartDate"
                                    #StartDate="ngModel"
                                    [(ngModel)]="leaveServ.formData.LeaveInfo.StartDate"
                                    [bsConfig]="datepickerConfig"
                                    bsDatepicker
                                    timepicker
                                    >
                                </div>

                              </div>
                              <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                <div class="form-group mb-0">
                                    <label>EndDate</label>
                                    <input type="text"
                                    class="form-control form-control-sm"
                                    placeholder="EndDate"
                                    name="EndDate"
                                    #EndDate="ngModel"
                                    [(ngModel)]="leaveServ.formData.LeaveInfo.EndDate"
                                    [bsConfig]="datepickerConfig"
                                    bsDatepicker
                                    timepicker
                                    >
                                </div>

                              </div>

                        </div>

                    </div>

                </div>
                <div class="modal-footer p-1">
                    <button type="button" (click)="saveLeaveRequestInfo(form)" class="btn btn-success"  type="button" >Save</button>
                    <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
                  </div>
            </form>
        </div>
    </div>
