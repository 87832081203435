import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CanonicalService } from '../shared/canonical.service';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class BenefitsComponent implements OnInit {
  course: string;
  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hybrid Learning Benefits - ADMINISTRATORS, TEACHERS, STUDENTS, PARENTS');
    this.metaService.addTags([
      {name: 'keywords', content: 'SCHOOL ADMINISTRATORS, TEACHERS, STUDENTS, PARENTS, BENEFITS, LMS'},
      {name: 'description', content: 'The Learner-Centered Platform it enables you to use your schools savings and energy efficiently with its high level of time and performance management!'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
    if(typeof window != 'undefined')
    window.scroll(0,0);
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoplay:true,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

}
