import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Ecosystem LMS Platform for K12 Academics, SEL, Life Skills, STEM.');
    this.metaService.addTags([
      {name: 'keywords', content: 'Knowledge is power, Information is liberating, Education is the premise of progress, Student needs, Teachers can help, '},
      {name: 'description', content: 'At Hybrid Learning Platform team have five core values guide how we work one another, with our board and advisors, and with our partners and collaborators.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
  }

}
