<div class="modal-dialog  modal-xl m-0">
  <div class="modal-content">
    <div class="modal-header bg-theam p-1">
      <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'UserEdit' | translate}}</h3>
      <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId <= 0">Add User</h3>
    </div>
    <div class="breackout_body p-2">
      <form novalidate #form="ngForm" (submit)="onSave(form)" autocomplete="off">
        <div class="row form-row m-0">
          <div class="form-group col-8 p-0 pr-1 mb-0">
            <div class="row form-row m-0 border border-secondary h-100  pt-2">
              <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                <label>{{'UserId' | translate}}</label>
                <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}" name="UserLogin"
                  #UserLogin="ngModel" [(ngModel)]="service.formData.UserInfo.UserLogin">
              </div>
              <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                <label>{{'Password' | translate}}</label>
                <input class="form-control form-control-sm" type="password" placeholder="********" name="Password"
                  #Password="ngModel" [(ngModel)]="service.formData.UserInfo.Password" required
                  class.invalid="Password.invalid && Password.touched">
              </div>

              <div class="form-group col-6 mb-0">
                <label>{{'School' | translate}}</label>
                <select class="form-control form-control-sm appearance-auto" name="SchoolId" #SchoolId="ngModel"
                  [(ngModel)]="service.formData.UserInfo.SchoolId" [disabled]="true">
                  <option [value]="0">Select School</option>
                  <option *ngFor="let school of schoollist" [value]="school.Id">{{school.Name}}</option>
                </select>
              </div>

              <div class="form-group col-6 mb-0">
                <label>{{'Branch' | translate}}</label>
                <select class="form-control form-control-sm appearance-auto" name="BranchId" #BranchId="ngModel"
                  [(ngModel)]="service.formData.UserInfo.BranchId" [disabled]="true">
                  <option [value]="0">Select Branch</option>
                  <option *ngFor="let branch of branchlist" [value]="branch.Id">{{branch.Name}}</option>
                </select>
              </div>


              <div class="form-group col-6 mb-0">
                <label>{{'EnrollmentId' | translate}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'EnrollmentId' | translate}}"
                  name="EnrollmentId" #EnrollmentId="ngModel" [(ngModel)]="service.formData.UserInfo.EnrollmentId"
                  required minlength="8" class.invalid="EnrollmentId.invalid && EnrollmentId.touched" [disabled]="true">
              </div>
              <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                <label>{{'UserId' | translate}} - {{service.formData.UserInfo.UserId}}</label>
                <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}" name="UserLogin"
                  #UserLogin="ngModel" [(ngModel)]="service.formData.UserInfo.UserLogin"
                  [readonly]="service.formData.UserInfo.UserId > 0" [disabled]="true">
              </div>
              <!-- <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                  <label><a class="btn btn-link btn-sm" (click)="resetPassword(service.formData.UserInfo.UserId)">Click here </a>for  {{'ResetPassword' | translate}}</label>
                </div> -->
            </div>
          </div>
          <div class="form-group col-4 mb-0 p-0 pl-1">
            <div class="row form-row m-0 border border-secondary h-100 pt-2 position-relative">
              <div class="cover-image">
                <img src="{{service.formData.UserInfo.ProfileFileInfo.CroppedContentString}}" alt=""
                  class="w-100 img-fluid" *ngIf="service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                <img [src]="service.formData.UserInfo.CroppedProfileFilepath" alt="" class="w-100 img-fluid"
                  *ngIf="!service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
              </div>
              <div class="form-group col-12 text-center">

                <a class="addImageIcon cursor-pointer" (click)="editCoverImage()">
                  <mat-icon>add_a_photo</mat-icon>
                </a>
                <img src="{{service.formData.UserInfo.FileInfo.CroppedContentString}}" alt="" height="110" width="110"
                  class="img-circle border border-w-5 shadow-08" (click)="editPic()"
                  *ngIf="service.formData.UserInfo.FileInfo.CroppedContentString">
                <img [src]="service.formData.UserInfo.CroppedFilepath" alt="" height="110" width="110"
                  class="border border-w-5 img-circle shadow-08" (click)="editPic()"
                  *ngIf="!service.formData.UserInfo.FileInfo.CroppedContentString">
              </div>
            </div>
          </div>
        </div>
        <div class="row form-row m-0 mb-2">
          <div class="form-group col-12 p-0 mb-0">
            <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">

              <div class="form-group col-4 mb-0">
                <label>{{'FirstName' | translate}}</label>
                <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}" name="FirstName"
                  #FirstName="ngModel" [(ngModel)]="service.formData.UserInfo.FirstName" required
                  class.invalid="FirstName.invalid && FirstName.touched" [disabled]="true">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'MidName' | translate}}</label>
                <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}" name="MiddleName"
                  #MiddleName="ngModel" [(ngModel)]="service.formData.UserInfo.MiddleName" required
                  class.invalid="MiddleName.invalid && MiddleName.touched" [disabled]="true">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'Surname' | translate}}</label>
                <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}" name="Surname"
                  #Surname="ngModel" [(ngModel)]="service.formData.UserInfo.Surname" required
                  class.invalid="Surname.invalid && Surname.touched" [disabled]="true">
              </div>

              <div class="form-group col-4 mb-0">
                <label>{{'Gender' | translate}}</label>
                <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel"
                  [(ngModel)]="service.formData.UserInfo.GenderId" [disabled]="true">
                  <option [value]="0">Select Gender</option>
                  <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
                </select>
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'DateOfBirth' | translate}}</label>

                <input type="text" class="form-control form-control-sm" placeholder="{{'DateOfBirth' | translate}}"
                  name="DateOfBirth" #DateOfBirth="ngModel" [(ngModel)]="service.formData.UserInfo.DateOfBirth"
                  [bsConfig]="datepickerConfig" bsDatepicker [disabled]="true">

              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'Role' | translate}}</label>
                <select class="form-control form-control-sm appearance-auto" name="RoleId" #RoleId="ngModel"
                  [(ngModel)]="service.formData.UserInfo.RoleId" [disabled]="true">
                  <option [value]="0">Select Role</option>
                  <option *ngFor="let role of rolelist" [value]="role.Id">{{role.Name}}</option>
                </select>
              </div>

              <div class="form-group col-4 mb-0">
                <label>{{'Mobile' | translate}}</label>
                <input class="form-control form-control-sm appearance-none" type="number"
                  placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel"
                  [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8"
                  class.invalid="Mobile.invalid && Mobile.touched">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'SMobile' | translate}}</label>
                <input class="form-control form-control-sm appearance-none" type="number"
                  placeholder="{{'SMobile' | translate}}" name="SMobile" #SMobile="ngModel"
                  [(ngModel)]="service.formData.UserInfo.SMobile">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'Email' | translate}}</label>
                <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}"
                  name="Email" #Email="ngModel" [(ngModel)]="service.formData.UserInfo.Email" required
                  class.invalid="Email.invalid && Email.touched">
              </div>
              <div class="form-group col-8 mb-0">
                <label>{{'Address' | translate}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'Address' | translate}}"
                  name="Address" #Address="ngModel" [(ngModel)]="service.formData.UserInfo.Address" [disabled]="true"
                  required class.invalid="Address.invalid && Address.touched" [disabled]="true">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'Street' | translate}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'Street' | translate}}"
                  name="Street" #Street="ngModel" [(ngModel)]="service.formData.UserInfo.Street" [disabled]="true">
              </div>

              <div class="form-group col-4 mb-0">
                <label>{{'PostalNo' | translate}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'PostalNo' | translate}}"
                  name="PostalNo" #PostalNo="ngModel" [(ngModel)]="service.formData.UserInfo.PostalNo" required
                  [disabled]="true" class.invalid="PostalNo.invalid && PostalNo.touched">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'City' | translate}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'City' | translate}}" name="City"
                  #City="ngModel" [(ngModel)]="service.formData.UserInfo.City" required [disabled]="true"
                  class.invalid="City.invalid && City.touched">
              </div>
              <div class="form-group col-4 mb-0">
                <label>{{'State' | translate}} - {{role}}</label>
                <input class="form-control form-control-sm" type="text" placeholder="{{'State' | translate}}"
                  name="State" #State="ngModel" [(ngModel)]="service.formData.UserInfo.State" required [disabled]="true"
                  class.invalid="State.invalid && State.touched">
              </div>

            </div>


          </div>
        </div>

        <div class="row form-row m-0 mb-2" *ngIf=" role == 'Parent'">
          <div class="form-group col-12 p-0 mb-0">
            <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
              <div class="form-group col-3 mb-0">
                <!-- <label>{{'Academics' | translate}}</label> -->
                <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                  [(ngModel)]="service.formData.ParentInfo.IsAcademics">{{'Academics' | translate}}</mat-checkbox>
              </div>
              <div class="form-group col-3 mb-0">
                <!-- <label>{{'HumanityLife' | translate}}</label> -->
                <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                  [(ngModel)]="service.formData.ParentInfo.IsHumanityLife">{{'HumanityLife' | translate}}</mat-checkbox>

              </div>
              <div class="form-group col-3 mb-0">
                <!-- <label>{{'Skills' | translate}}</label> -->
                <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                  [(ngModel)]="service.formData.ParentInfo.IsSkills">{{'Skills' | translate}}</mat-checkbox>
              </div>

              <div class="form-group col-3 mb-0">
                <!-- <label>{{'LearnOutOfBox' | translate}}</label> -->
                <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                  [(ngModel)]="service.formData.ParentInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                  translate}}</mat-checkbox>
              </div>
              <div class="form-group col-3 mb-0">
                <label>{{'Children' | translate}}</label>
                <!-- <ng-multiselect-dropdown
                    [placeholder]="'Search'"
                    [settings]="dropdownSettings"
                    name="ChildrenList"
                    [data]="childList"
                    [(ngModel)]="service.formData.ParentInfo.ChildrenList"
                    (onSelect)="onItemSelect($event,'Child')"
                    (onDeSelect)="onItemSelect($event,'Child')">
                      </ng-multiselect-dropdown> -->

              </div>

              <div class="form-group col-3 mb-0">
                <label>{{'ExpiryDate' | translate}}</label>

                <input type="text" class="form-control form-control-sm" placeholder="{{'ExpiryDate' | translate}}"
                  name="ExpiryDate" #ExpiryDate="ngModel" [(ngModel)]="service.formData.ParentInfo.ExpiryDate"
                  [bsConfig]="datepickerConfig" bsDatepicker>

              </div>

            </div>

          </div>
        </div>
        <div class="row form-row m-0 mb-2" *ngIf="role == 'Student' || role == 'Teacher' || role == 'Parent'">
          <div class="form-group col-12 p-0 mb-0">
            <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
              <div class="form-group col-12 mb-0">
                <label class="d-block">{{'About' | translate}} {{role}} <span
                    class="d-inline-block float-right">{{currentLength}}/{{maxLength}}</span></label>
                <textarea class="form-control form-control-sm appearance-auto resizebele-false m-0" name="About"
                  #About="ngModel" [(ngModel)]="service.formData.UserInfo.About" rows="5" maxlength="2000"
                  (keyup)="lenghtCounter()"></textarea>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
    <div class="modal-footer p-1">
      <!-- <button type="button" class="btn btn-primary" (click)="userLogHistory()" *ngIf="this.role==='Parent'||this.role==='Student'">User Log</button> -->
      <button type="button" class="btn btn-primary"
        (click)="userAcademicYearLogInfo(service.formData.UserInfo.UserId)">Academic Year Log</button>
      <button type="button" class="btn btn-primary" (click)="resetPassword(service.formData.UserInfo.UserId)">Reset
        Password</button>
      <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>
      <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)"
        aria-label="Close">Close</button>
    </div>
  </div>
</div>