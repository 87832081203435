
<div class="container-fluid bg-light min-height-100vh pb-2">
    <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between pt-2 pb-2">
            <h2 class="m-0 p-0">Dairy</h2>
            <div class="d-flex align-items-center">
              <img class="cursor-pointer" src="../../assets/images/icons/svg/left_arrow_gray.svg" alt="">
              <h3 class="m-0 p-0">{{commonService.formatdate(selectedDate)}}</h3>
              <img class="cursor-pointer" src="../../assets/images/icons/svg/right_arrow_gray.svg" alt="">
            </div>
          </div>
            <div class="dairy-page">

                <div class="row bg-white border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Date</label>
                            <input type="text"
                                class="form-control form-control-sm"
                                placeholder="{{'Date' | translate}}"
                                name="selectedDate"
                                [(ngModel)]="selectedDate"
                                [bsConfig]="datepickerConfig"
                                bsDatepicker
                                timepicker
                                >
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2 d-flex align-items-center justify-content-end">
                        <label class="d-block m-0">&nbsp;</label>
                        <button type="button" (click)="serchDairy()" class="btn btn-warning float-right" type="button" >Search</button>
                    </div>
                </div>
                <mat-table  [dataSource]="dataSource" *ngIf="dataSource.length > 0" class="mat-elevation-z8 p-2">


                    <!-- Subject Column -->
                    <ng-container matColumnDef="Subject">
                      <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.Subject}}</mat-cell>
                    </ng-container>

                    <!-- Teacher Column -->
                    <ng-container matColumnDef="Teacher">
                      <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.TeacherName}}</mat-cell>
                    </ng-container>

                    <!-- Dairy Notes Column -->
                    <ng-container matColumnDef="Dairy Notes">
                      <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.DairyNotes}}</mat-cell>
                    </ng-container>

                    <!-- Result Column -->
                    <ng-container matColumnDef="Result">
                      <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
                      <mat-cell *matCellDef="let element" (click)="updateDairy(element.StudentDairyId)" [ngClass]="{'text-danger': element.Result=='Pending', 'text-success': element.Result=='Completed'}">{{element.Result}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>


                  <mat-table  [dataSource]="emptyDataSource" *ngIf="dataSource.length === 0" class="mat-elevation-z8 p-2">
                    <!-- Subject Column -->
                    <ng-container matColumnDef="Subject">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject </mat-header-cell>
                        <mat-cell class="d-flex justify-content-center min-h-25px" *matCellDef="let element"> No records found </mat-cell>
                    </ng-container>

                    <!-- Teacher Column -->
                    <ng-container matColumnDef="Teacher">
                      <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <!-- Dairy Notes Column -->
                    <ng-container matColumnDef="Dairy Notes">
                      <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <!-- Result Column -->
                    <ng-container matColumnDef="Result">
                      <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
                      <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>





            </div>
        </div>
    </div>

</div>






<!--
<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <div class="row border m-0 py-2 mb-2 justify-content-between">

                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Date</label>
                            <input type="text"
                                class="form-control form-control-sm"
                                placeholder="{{'Date' | translate}}"
                                name="selectedDate"
                                [(ngModel)]="selectedDate"
                                [bsConfig]="datepickerConfig"
                                bsDatepicker
                                timepicker
                                >
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <button type="button" (click)="serchDairy()" class="btn btn-warning float-right" type="button" >Search</button>
                    </div>
                </div>
                <ag-grid-angular #agGrid
                style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                class="ag-theme-alpine"
                id="dairyGrid"
                [gridOptions]="dairyGridOptions"
                [columnDefs]="dairyColumnDefs"
                [defaultColDef]="dairyDefaultColDef"
                [rowSelection]="dairyRowSelection"
                [rowData]="dairyRowData"
                (gridReady)="onDairyReady($event)"
                [frameworkComponents]="frameworkComponents"
                (gridReload)="onDairyReload($event)"
                ></ag-grid-angular>

            </div>
        </div>
    </div>
</div>
  -->
