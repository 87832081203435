<div class="container-fluid bg-theam-body">
    <div class="row justify-content-center">
        <div class="col-10 pt-5">
            <div class="row mt-5 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <h1 class="d-block font-weight-500 pt-1 pb-1">HybridLearning.in Support Desk</h1>
                    <p>Welcome to HybridLearning.in's Support Desk. You can submit new support tickets here and
                        manage your existing ones.</p>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="container-fluid bg-theam-body">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="row mt-3 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <h1 class="d-block font-weight-500 pt-1 pb-1">HybridLearning.in LMS Support</h1>
                    <p>The purchase of an LMS isn't just a transaction; it's the beginning of a partnership. We pair
                        our Global Ecosystem learning platform with an entire ecosystem of industry-leading LMS
                        support options at hybridlearning.in. Customer service is available 24/7—365 days a year—and
                        is handled 100% internally, so you can count on excellent service every time. Each query and
                        phone call, email, and support ticket is promptly addressed by a qualified team member who
                        knows the product and how you use it. When you work with a company that views LMS support as
                        a business-boosting contribution to a valuable partnership, you'll notice the difference.
                        Isn't that refreshing?</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid theam-title-bg">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="row mt-3 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                        <h1 class="d-block title pt-1 pb-1">Dedicated one-on-one meeting</h1>

                        <h1 class="d-block pt-1 pb-1 font-weight-500">No two schools are alike. With personalized
                            meeting, we’ll show you
                            how best to use hybridearning.in to meet the needs of your school and achieve your teaching
                            and
                            learning goals.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg-theam-body">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="row mt-3 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                  
                    <div>
                        <h2 class="d-block title pt-1 pb-1 m-0 font-weight-500">Support for instructional design on a
                            hands-on basis</h2>
                        <p>Help optimizing your school community features for specific learning objectives, class
                            formats, and teaching methods. We have a team of instructional designers ready to help.</p>

                        <h2 class="d-block title pt-1 pb-1 m-0 font-weight-500">A comprehensive learning resource</h2>
                        <p>Discover live and on-demand webinars, in-depth guides, and articles. Through active learning,
                            you will learn the latest teaching tips and best practices.</p>

                        <h2 class="d-block title pt-1 pb-1 m-0 font-weight-500">Technical support that is reliable</h2>
                        <p>Teachers and students can contact hybridlearning.in for technical support via email or live
                            chat.</p>

                        <h2 class="d-block title pt-1 pb-1 m-0 font-weight-500">Availability 24/7/365</h2>
                        <p>Clients of HybridLearning.in have access to in-house support 24 hours a day, 365 days a year.
                            With 99.99% uptime, teaching and learning never stop.</p>

                        <h2 class="d-block title pt-1 pb-1 m-0 font-weight-500">Quality is our commitment.</h2>
                        <p>The HybridLearning.in Support team anticipates your growth and is prepared to meet the
                            evolving needs of every student.</p>

                        <ul>
                            <li>
                                Please submit one request per topic.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<section>
    <div class="container-fluid bg-theam-body pb-3">
        <div class="row justify-content-center">
            <div class="col-8 pt-5">
                
                <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Your email address</label>
                      <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
                    </div>
                    <div class="form-group">
                      <label for="sub">Subject</label>
                      <input type="text" class="form-control" id="sub" placeholder="Subject">
                      <small class="form-text text-muted">Pleasae submit one topic per request</small>
                    </div>
                    <div class="form-group">
                        <label for="desc">Description</label>
                        <textarea class="form-control" id="desc" rows="3"></textarea>
                      </div>
                      <div class="form-group">
                        <label for="attachment">Attachment</label>
                        <input type="file" class="form-control" id="attachment" placeholder="Upload file">
                      </div>

                      <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary">Submit</button>
                      </div>
                    
                  </form>


            </div>
        </div>
    </div>
</section>