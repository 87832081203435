import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import CONFIG from './Const';
import { QRCode, QRCodeView, QRTypes } from './QRCode.model';

@Injectable({
  providedIn: 'root'
})
export class QRCodeService {

  readonly baseUrl = CONFIG.BASE_URL+'api/admin';
  public formData:QRCodeView = new QRCodeView();
  QRCodeView:QRCode = new QRCode();
  // QRTypes: QRTypes = new QRTypes();
  constructor(private http:HttpClient) {
    this.formData.QRCodeInfo = new QRCode();
   }
  
  // For get the QRCode List
   getQRCodeListView(){
    return this.http.get(this.baseUrl+'/getQRCodeListView');
  }
  
  // For get specific QRCode
  getQRCodeView(qrCodeId:number){
    var config = {
      params: {
        qrCodeId : qrCodeId,
      }
    };
    return this.http.get(this.baseUrl+'/getQRCodeView',config);
    
  }
  // For saving save QRCode
  saveQRCodeView(){
    debugger;
    return this.http.post(this.baseUrl+'/saveQRCodeView',this.formData);
  }
  deleteQRCode(){
    return this.http.post(this.baseUrl+'/saveQRCodeView',this.formData);
  }

  previewQRCode(){
    return this.http.post(this.baseUrl+'/getQRCodePreview',this.formData);
  }

  getQRAnalyticsInfo(qrCodeId:number){
    var config = {
      params: {
        qrCodeId : qrCodeId,
      }
    };
    return this.http.get(this.baseUrl+'/getQRAnalyticsInfo',config);
    
  }

  public uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(this.baseUrl + 'uploadQRLogo', formData);
  }

  // For get the redirect url
  getQRCodeRedirectionUrl(Code:string){
    var config = {
      params: {
        code : Code,
      }
    };
    return this.http.get(CONFIG.BASE_URL+'api/home/getQRCodeRedirectionUrl',config);
    
  }
}