<div class="container-fluid bg-theam-body">
    <div class="row justify-content-center">
        <div class="col-10 pt-5">
            <div class="row mt-5 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <p>At HybridLearning.in, we strive to provide you with the best possible online learning experience.
                        We understand that there might be circumstances where you need to cancel your enrollment in a
                        subscription. This policy outlines our guidelines and procedures for cancellations to ensure a
                        fair and transparent process for all users.</p>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="container-fluid bg-theam-body">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="row mt-3 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">                   
                    <p>
                        <b>1. Cancellation Period:</b> You may cancel your enrollment of subscription in a period on www.hybridlearning.in within 30 days from the date of purchase. After this period, cancellations will not be accepted.
                    </p>
                    <p>
                        <b>2. Cancellation Process: </b> To initiate a cancellation, please follow these steps:                     
                    </p>
                    <p>
                        Log in to your account on www.hybridlearning.in.
                    </p>
                    <p>
                        Navigate to the "My profile" section.
                    </p>
                    <p>
                        Locate the usage you wish to cancel and click on the "Cancel" button.
                    </p>
                    <p>
                        Follow the on-screen instructions to complete the cancellation process.
                    </p>

                    <p>
                        <b>3. Cancellation Refund:</b>
                    </p>
                    <p>
                        If the cancellation is requested within the specified cancellation period, you will be eligible for a full refund of the subscription fee.
                    </p>
                    <p>
                        Refunds will be processed using the same payment method used for the original purchase.
                    </p>
                    <p>
                        Please allow up to 10 business days for the refund to reflect in your account.
                    </p>  

                    <p>
                        <b>4. Non-Transferable: </b> Course enrollments and cancellations are non-transferable. The refund will be issued to the original payer and cannot be transferred to another user.
                    </p>

                    <p>
                        <b>5. Course Progress and Access:</b>
                    </p>
                    <p>
                        If you have accessed any course materials or completed any portions of the course before requesting cancellation, you will not be eligible for a refund.
                    </p>
                    <p>
                        Cancellation will result in the removal of your access to the course content, assessments, and any associated resources.
                    </p>
                    <p>
                        <b>6. Contact Us:</b> If you have any questions or need assistance with the cancellation process, please contact our support team at support&#64;hybridlearning.in.
                    </p>
                    <p>
                        Please note that this cancellation policy is subject to change, and any updates will be communicated to users through our website. It is advisable to review this policy periodically for any modifications.
                    </p>
                    <p>
                        Last updated: 10th August 2023
                    </p>
                
                        

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">                   
                                     
                        

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">                   
                   
                    
                    

                </div>
            </div>
        </div>
    </div>
</div>
