<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div class="mt-3 mb-3">
                <div class="modal-header p-1 bg-theam">
                    <h3 class="modal-title h3 m-1">School setting </h3>
                </div>

                <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0">
                    <form novalidate #form="ngForm" autocomplete="off">
                        <div class="modal-body p-2">
                            <div>
                                <div class="row form-row m-0">
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="form-group mb-0">
                                            <label>School Category </label>
                                            <select class="form-control form-control-sm appearance-auto"
                                                name="schoolCategoryId"
                                                #schoolCategoryId="ngModel"
                                                [(ngModel)]="service.formData1.ParentSettingInfo.SchoolCategoryId"
                                                >
                                                <option [value]="0">Type Of Post</option>
                                                <option *ngFor="let schoolCategory of schoolCategoryList" [value]="schoolCategory.Id">{{schoolCategory.Name}}</option>
                                            </select>

                                        </div>

                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="form-group mb-0">
                                            <label>State List</label>
                                            <select class="form-control form-control-sm appearance-auto"
                                                name="stateIdList"
                                                #stateIdList="ngModel"
                                                [(ngModel)]="service.formData1.ParentSettingInfo.StateId"
                                                >
                                                <option [value]="0">Type Of Post</option>
                                                <option *ngFor="let state of stateList" [value]="state.Id">{{state.Name}}</option>
                                            </select>

                                        </div>

                                    </div>

                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                        <div class="form-group mb-0">
                                            <label>{{'Challange Info' | translate}}</label>
                                            <!-- <ng-multiselect-dropdown [placeholder]="'Search'"
                                                [settings]="dropdownSettings" name="SchoolCategoryIdList"
                                                [data]="challengeInfo"
                                                [(ngModel)]="service.formData1.ParentSettingInfo.ChallengeIdList"
                                                (onSelect)="onItemSelect($event,'SchoolCategory')"
                                                (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                                (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                                (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                            </ng-multiselect-dropdown> -->
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                        <div class="form-group mb-0">
                                            <label>Purpose Of Post</label>
                                            <!-- <ng-multiselect-dropdown [placeholder]="'Search'"
                                                [settings]="dropdownSettings" name="PurposeOfPostIdList"
                                                [data]="purposeOfPostInfo"
                                                [(ngModel)]="service.formData1.ParentSettingInfo.PurposeOfPostIdList"
                                                (onSelect)="onItemSelect($event,'SchoolCategory')"
                                                (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                                (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                                (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                            </ng-multiselect-dropdown> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer p-1">
                            <button type="button" class="btn btn-success" (click)="saveDocument()"
                                type="button">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
