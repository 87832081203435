import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Appointment, appViewInfo } from './appointment.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class AppointmentService  {
    readonly baseUrl = CONFIG.BASE_URL+'api/calendar';
    formdata:appViewInfo = new appViewInfo();
    constructor(private http:HttpClient) { 
        this.formdata.AppointmentInfo = new Appointment();
    }
    getAppointments(date: string, classId: number){
        var config = {
            params: {
                date: date,
                classId:classId
            }
          };
        return this.http.get(this.baseUrl+'/getAppointments',config);
    }
    getAppointmentViewInfo(appointmentId: number){
        var config = {
            params: {
                appointmentId: appointmentId
            }
          };
        return this.http.get(this.baseUrl+'/getAppointmentViewInfo',config);
    }
    saveAppointmentView(){
        return this.http.post(this.baseUrl+'/saveAppointmentView',this.formdata);
    }
    getStudentAppointments(config){
        return this.http.get(this.baseUrl+'/getStudentAppointments',config)
      }
      getassignedassignmentsbyDateRange(config){
        return this.http.get(this.baseUrl+'/getStudentAppointmentsWithDateRange',config)
      }
}
