import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { EditsurveyComponent } from './editsurvey.component';
import { SurveyResultComponent } from './survey-result.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class SurveyComponent implements OnInit {
  public bsModalRef: BsModalRef;
  frameworkComponents;
  surveyRowData: any;
  surveyRowSelection;
  surveyDefaultColDef;
  surveyColumnDefs;
  surveyGridOptions: any;
  surveyGridApi;
  surveyGridColumnApi
  constructor(
    public SurveySer: FeedbackService,
    private modalService: BsModalService,
    private notificationServ: NotificationService,
    public commonServ: CommonService
  ) {
    this.commonServ.RefreshGrid$.subscribe(
      item => {

        this.SurveySer.getsurveylist().subscribe((res: any) => {
          this.surveyRowData = res;
        });
      }
    );
    this.setGridInfo();
  }

  ngOnInit(): void {
    
  }
  setGridInfo(){
    this.surveyColumnDefs = [
      {
        headerName: "SurveyId",
        field: 'SurveyId', cellRendererFramework: CustommenuComponent,
        cellRendererParams: { MenuList: [{ Name: "View Result", "Click": this.viewSurveyResult, that: this }, { Name: "Edit", "Click": this.editSurvey, that: this }, { Name: "Delete", "Click": this.deleteSurvey, that: this }] },
        filter: false, width: 60, maxWidth: 60,
        headerComponent: 'customHeader',
        headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      { field: 'Description', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'RatingType', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },

    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.surveyDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }

  onSurveyReady(params) {

    this.surveyGridApi = params.api;
    this.surveyGridColumnApi = params.columnApi;
    this.SurveySer.getsurveylist().subscribe((res: any) => {
      const rowData =  res;
      this.surveyGridApi.setRowData(rowData);
    });
  }
  onSurveyReload(data) {
    this.surveyRowData = data;
  }
  editSurvey(surveyId, that) {
    that.openSurveyEditModal(surveyId);

  }
  viewSurveyResult(surveyId, that) {
    that.openSurveyResultModal(surveyId);

  }

  deleteSurvey(surveyId, that) {
    var that1: any = this;
    if (that.commonServ.role == 'Teacher') {
      that1.that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
    }
    else {
      var item = that.surveyRowData.filter(item => item.SurveyId == surveyId);
      item[0].IsActive = false;
      that1.that.SurveySer.saveSurvey(item[0]).subscribe((res: any) => {
        if (res.IsSaved) {
          that1.that.notificationServ.ShowInfo('Successfully delete!');
          that1.that.SurveySer.getsurveylist().subscribe((res: any) => {
            that1.that.surveyRowData = res;
          });
        } else {
          that1.that.notificationServ.ShowError(res.ErrorMessage);
        }
      });
    }
  }
  openSurveyEditModal(surveyId) {
    const initialState = {
      list: [
        { id: surveyId }
      ]
    };
    this.bsModalRef = this.modalService.show(EditsurveyComponent, { initialState, class: 'modal-lg mb-0', ignoreBackdropClick: true });
  }
  // openSurveyResultModal(surveyId,that){
  //   const initialState = {
  //     list: [
  //       {Id:surveyId}
  //     ]
  //   };
  //   that.bsModalRef = this.modalService.show(SurveyResultComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  // }

  openSurveyResultModal(surveyId) {
    const initialState = {
      list: [
        { SurveyId: surveyId }
      ]
    };
    this.bsModalRef = this.modalService.show(SurveyResultComponent, { id: CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });

  }
}
