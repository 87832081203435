import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";

declare const whiteboard: any;
// declare const canvas: any;

@Component({
  selector: "app-white-board",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./white-board.component.html",
  styleUrl: "./white-board.component.scss",
})
export class WhiteBoardComponent {
  closeWhiteboard: Function;
  constructor(public bsModalRef: BsModalRef) {}
  roomId: string | null;
  ngOnInit() {
    this.roomId = localStorage.getItem("room_id");
    whiteboard(this.roomId);
    // canvas()
    this.closeWhiteboard();
  }
}
