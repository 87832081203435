// import { BarColumnLabelPlacement } from "ag-grid-community/dist/lib/interfaces/iSparklineCellRendererParams";
//import { BarColumnLabelPlacement } from "ag-grid-community";

export class Dairy {

}
export class StudentDairyInfo {
    ClassDairyMasterId: number;
    UserId: number;
    DairyDate: string;
    ClassId: number;
    SubjectId: number;
    DairyNotes: string;
    IsActive: boolean;
    Subject: string;
    TeacherName: string;
    StudentDairyId: number;
    StudentId: number;
    ResultId: number;
    Result: string;
}
export class ClassDairyInfo {
    ClassDairyMasterId: number;
    UserId: number;
    DairyDate:string;
    ClassId: number;
    SubjectId:number;
    DairyNotes:string;
    IsActive:boolean;
    Subject:string;
}
export class SubjectInfo {
    Id: number;
    Code:string;
    Name:string;
    NameDK:string;
    Description: string;
    IsActive:boolean;
    ParentGroupId: number;
    SubjectId: number;
    ClassId:number;
}
export class TeacherInfo {
    TeacherId:number;
    UserId:number;
    FullName:string;

}
export class ClassDairyViewInfo{
    ErrorMessage:string;
    IsSaved:boolean;
    ClassDairyInfo:[ClassDairyInfo];
    SubjectInfo:[SubjectInfo];
    TeacherInfo:TeacherInfo;

}
