import { formatDate, NgFor, NgIf, NgClass, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { QuezComponent } from '../question/quez.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { QuestionService } from '../shared/question.service';
import { SessionStorageService } from '../shared/session-storage.service';
import { Router } from '@angular/router';
import { ReadMoreComponent } from '../feedback/read-more.component';
import { map, tap } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-new-assesments',
    templateUrl: './new-assesments.component.html',
    styleUrls: ['./new-assesments.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NgFor, NgIf, NgClass, DatePipe]
})
export class NewAssesmentsComponent implements OnInit {
  activeTab: any;
  assessmentData:any[]=[];
  date:any;
  bsModalRef: BsModalRef;

  feedbackList: any;
userData:any=[];
  constructor(
    private notificationServ:NotificationService, 
    public commonService:CommonService,
    public assessmentServ:QuestionService,
    public modalService: BsModalService,
    private feedbacServ:FeedbackService,
    private sessionServ:SessionStorageService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.getQuestion();
    this.userData = this.sessionServ.getUserData();
  }


  activeTabChange(event) {
    
    this.activeTab = event.tab.textLabel;

    if(this.activeTab =="Assessments"){
      this.getQuestion();
    }else if(this.activeTab =="Feedback"){ 
      this.getStudentFeedback();      
    }

  }
  toTime(timeString){
    let timeTokens = timeString.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1]);
}
  getQuestion() {

    this.assessmentServ.getallstudentsquestions().pipe(
      map((res: any, index: number) => {
        let data = [];
        res.forEach(item => {
          item.StartTime = this.toTime(item.StartTime);
          item.EndTime = this.toTime(item.EndTime);
          item.StartDate = new Date(item.StartDate.split('-').reverse().join('-'));
          data.push(item);
        })
        return data

      })

    ).subscribe((res: any) => {
      var pendingQstCount = 0;
      console.log('assessmentData');
      console.log(res);

      this.assessmentData = res;
      // for (var i = 0; i < res.length; i++) {
      //   res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
      //   res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);
      //   if (res[i].Status == 'New') {
      //     pendingQstCount = + pendingQstCount;
      //   }
      // }
      // this.accQstCount = res.filter(item => item.IsAcademics == true).length;
      // this.selQstCount = res.filter(item => item.IsHumanityLife == true).length;
      // this.lifeQstCount = res.filter(item => item.IsSkills == true).length;
      // this.outBoxQstCount = res.filter(item => item.IsLearnOutOfBox == true).length;
      // if (this.activeTab === "SEL") {
      //   this.rowData = res.filter(item => item.IsHumanityLife == true);
      // } else if (this.activeTab === "Life Skills") {
      //   this.rowData = res.filter(item => item.IsSkills == true);
      // } else if (this.activeTab === "Academics") {
      //   this.rowData = res.filter(item => item.IsAcademics == true);
      // } else if (this.activeTab === "Outside The Box") {
      //   this.rowData = res.filter(item => item.IsLearnOutOfBox == true);
      // }
      // if (pendingQstCount > 0) {
      //   if (pendingQstCount == 1) {
      //     this.notificationServ.ShowWarning('You have ' + pendingQstCount.toString() + ' pending assessment');
      //   }
      //   else {
      //     this.notificationServ.ShowWarning('You have ' + pendingQstCount.toString() + ' pending assessments');
      //   }
      // }
    })
  }

  
  startExam(QuestionMasterId) {
    var questionData = this.assessmentData.filter(item => item.QuestionMasterId == QuestionMasterId);
    if (questionData[0].Status == 'Completed') {
      this.notificationServ.ShowError('You already completed this exam!');
    }
    else {
      var sd = questionData[0].StartDate.split('-');
      var ed = questionData[0].EndDate.split('-');
      var examStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + questionData[0].StartTime);
      var examEndTime = new Date(ed[1] + '-' + ed[0] + '-' + ed[2] + ' ' + questionData[0].EndTime);
      this.date = new Date();
      this.date = formatDate(new Date(), 'MM-dd-yyyy HH:mm a', 'en', '+0530');
      var currentTime = new Date(); //new Date(this.date);
      if (currentTime <= examStartTime) {
        this.notificationServ.ShowError('Exam will start at ' + questionData[0].StartDate + ' ' + questionData[0].StartTime);
      }
      else if (currentTime > examEndTime) {
        this.notificationServ.ShowError('Exam date has been expired');
      }
      else {

        if (questionData[0].Status == "New") {
          this.openQuezmodal(QuestionMasterId)
        } else {
          this.notificationServ.ShowError((questionData[0].Status == "Completed") ? 'You already completed this task!' : 'Last submission date has been expired');
        }
      }
    }
  }
  openQuezmodal(QuestionMasterId: number) {
    const initialState = {
      list: [
        { id: QuestionMasterId }
      ]

    };
    this.bsModalRef = this.modalService.show(QuezComponent, { id: CUSTOMCONST.STDASSIGNMENT_MODAL, initialState, class: 'modal-xl', ignoreBackdropClick: true });
  }

  getStudentFeedback() {
    var config = {
      params: {
        userId: this.userData.UserId,
        schoolId: this.userData.SchoolId,
      }
    };
    this.feedbacServ.getStudentFeedbacks(config).subscribe((res: any) => {
      
      this.feedbackList = res;
    });
  }

  startAssessment(id:any){
    this.router.navigate(['/myassessment', id])
  }

  openReadmoreModel(data) {
    
      const initialState = {
        list: [
          { data: data }
        ]
      };
      this.bsModalRef = this.modalService.show(ReadMoreComponent, { id: CUSTOMCONST.STDASSIGNMENT_MODAL, initialState, class: 'modal-md mb-0', ignoreBackdropClick: true });
    

  }
}
