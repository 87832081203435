import { 
  Component,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
    selector: 'numeric-cell',
    template: `<div class="form-group mb-0">
  <input [ngModel]="StartTime" class="form-control form-control-sm" name="start Time"  />
</div>`,
    standalone: true,
    imports: [FormsModule]
})
export class TimeEditorComponent implements OnInit  {

public params: any;
  public value: number;
  public StartTime: any;
  public highlightAllOnFocus: boolean = true;
  private cancelBeforeStart: boolean = false;
  @ViewChild('input', { read: ViewContainerRef }) public input: any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params): void {
    this.params = params;
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      params.charPress && '1234567890'.indexOf(params.charPress) < 0;
  }

  setInitialState(params: any) {
    let startValue;
    let highlightAllOnFocus = true;
    this.StartTime =  params.value;;
   
    }




  

}
