import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { map } from 'rxjs';
import { formatDate, NgIf, NgFor } from '@angular/common';
import { IDropdownSettings,
  //  NgMultiSelectDropDownModule
   } from 'ng-multiselect-dropdown';
import { TranslateModule } from '@ngx-translate/core';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-badge',
    templateUrl: './edit-badge.component.html',
    styleUrls: ['./edit-badge.component.scss'],
    standalone: true,
    // imports: [FormsModule, NgIf, NgFor, BsDatepickerModule, NgMultiSelectDropDownModule, Ng7LargeFilesUploadLibModule, TranslateModule]
    imports: [FormsModule, NgIf, NgFor, BsDatepickerModule,  TranslateModule]

})
export class EditBadgeComponent implements OnInit {
  list:any[];
  minDate = new Date();
  datepickerConfig : BsDatepickerConfig;
  dropdownSettings:IDropdownSettings;
  userList:any;
  classList:any;
  selectedClassId:number;

  constructor(public userService:UserService, private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService) { }

  ngOnInit(): void {
    if(this.list[0].userId==0){
      this.getclasses();
    }

    this.userService.getUserBadgeViewInfo(this.list[0].id,this.list[0].userId)

    .subscribe((res:any)=>{
      this.userService.badgeFormData.BadgeInfo = res.BadgeInfo;
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
  }
  editBadgeInfo(){
    this.userService.badgeFormData.BadgeInfo.IsActive = true;
    this.userService.badgeFormData.BadgeInfo.UserId = this.list[0].userId;
    this.userService.badgeFormData.BadgeInfo.IssueDate = formatDate(new Date(this.userService.badgeFormData.BadgeInfo.IssueDate), 'dd-MM-yyyy', 'en');
    if(this.userService.badgeFormData.BadgeInfo.ExpiryDate == '' || this.userService.badgeFormData.BadgeInfo.ExpiryDate == null){
      // resData.BadgeInfo.ExpiryDate = new Date(resData.BadgeInfo.ExpiryDate)
    }else{
      this.userService.badgeFormData.BadgeInfo.ExpiryDate = formatDate(new Date(this.userService.badgeFormData.BadgeInfo.ExpiryDate), 'dd-MM-yyyy', 'en');
    }
    this.userService.saveUserBadgeViewInfo().subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.commonServ.CallOnRefreshGrid();
        this.notificationServ.ShowInfo("Badge successfully save");
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    })
  }
  saveBadgeInfo(){
    this.userService.badgeFormData.BadgeInfo.IsActive = true;
    this.userService.badgeFormData.BadgeInfo.IssueDate = formatDate(new Date(this.userService.badgeFormData.BadgeInfo.IssueDate), 'dd-MM-yyyy', 'en');
    if(this.userService.badgeFormData.BadgeInfo.ExpiryDate == '' || this.userService.badgeFormData.BadgeInfo.ExpiryDate == null){
      // resData.BadgeInfo.ExpiryDate = new Date(resData.BadgeInfo.ExpiryDate)
    }else{
      this.userService.badgeFormData.BadgeInfo.ExpiryDate = formatDate(new Date(this.userService.badgeFormData.BadgeInfo.ExpiryDate), 'dd-MM-yyyy', 'en');
    }
    this.userService.saveUserBadgeViewInfo().subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.commonServ.CallOnRefreshGrid();
        this.notificationServ.ShowInfo("Badge successfully save");
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
      }else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    })
  }



  updateBadgeInfo(){

  }
  closeModel(){
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
  }

  largefileschange(event){
  }
  filesuploaded(fileInfo){
    if(fileInfo && fileInfo.IsSaved){
      this.userService.badgeFormData.BadgeInfo.DocumentId = fileInfo.UserDocumentId;
    }


  }


onItemSelect(item: any,type:string) {
  if(type == 'badgeUser' && this.userService.badgeFormData.BadgeInfo.UserIdList){

    let ids = item.Id;
    for(var i=0;i<this.userService.badgeFormData.BadgeInfo.UserIdList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.userService.badgeFormData.BadgeInfo.UserIdList[i].Id;
    }
    // this.userService.badgeFormData.BadgeInfo.UserIdList=[ids];
  }
}
onSelectAll(items: any,type:string) {
  if(type == 'badgeUser'){

    let ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }
    // this.userService.badgeFormData.BadgeInfo.UserIdList=[ids];
  }
}
getclasses() {
  this.commonServ.getclasses().subscribe((res: any) => {
    this.classList = res;
    this.selectedClassId = 0;

  });
}
changeClass(){
    this.getstudentsbyclassIds(this.selectedClassId)
}
getstudentsbyclassIds(classid){
  this.userService.getStudentsByClassIds(classid).subscribe((res:any)=>{
    this.userList = res;
  });
}
}
