<div class="dashboardInnerPage flex-wrap inner-page-outer inner-formPage">
  <div class="title w-100">
    <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
      class="bi bi-arrow-left"></span>
    <h3>Create Question</h3>
  </div>

  <div class="inner-form">
    <div class="field-row">

      <div class="select_field video-field" *ngIf="!recordedBlobUrl">
        <label for="">Record Your Question</label>
        <!-- Video Element to Display the Live Stream -->
        <video #videoElement autoplay></video>
        <div class="recording_button action-links mt-3">
          <!-- Recording Buttons -->
          <span (click)="startRecording()" class="rec-button bi bi-record-circle" title="Start Recording"></span>
          <span (click)="stopRecording()" class="rec-button bi bi-stop-circle" title="Stop Recording"></span>
        </div>
      </div>
      <div class="select_field video-field" *ngIf="recordedBlobUrl">
        <label for="">Recorded Video</label>
        <video [src]="recordedBlobUrl" controls></video>
        <div class="recording_button action-links mt-3">
          <!-- Recording Buttons -->
          <a [href]="recordedBlobUrl" download="recording.webm" class="rec-button" title="Download Recording"><span
              class="bi bi-download"></span></a>
          <span (click)="discardRecording()" class="rec-button bi bi-x-circle" title="Discard Recording"></span>
        </div>
      </div>
      <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" class="select_field">


        <label for="subject">Subject:</label>
        <textarea class="field-control" id="subject" type="text" formControlName="subject"></textarea>
        <div *ngIf="submitted && uploadForm.controls.subject.errors">
          <small class="error" *ngIf="uploadForm.controls.subject.errors.required">
            Subject is required.
          </small>
        </div>

        <span type="submit" class="add_button mt-3">Submit</span>

      </form>
    </div>

  </div>
</div>

<!-- upload-form.component.html -->





<!-- Display Submitted Data (Optional) -->
<div *ngIf="responseData">
  <h3>Form Submitted Successfully!</h3>
  <pre>{{ responseData | json }}</pre>
</div>