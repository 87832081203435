
<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <ag-grid-angular #agGrid
                style="width: 100%; height:calc(100vh - 150px);min-height: 200px;"
                class="ag-theme-alpine"
                id="feedbacktGrid"
                [gridOptions]="feedbackGridOptions"
                [columnDefs]="feedbackColumnDefs"
                [defaultColDef]="feedbackDefaultColDef"
                [rowSelection]="feedbackRowSelection"
                [rowData]="feedbackRowData"
                (gridReady)="onFeedbackReady($event)"
                [frameworkComponents]="frameworkComponents"
                (gridReload)="onFeedbackReload($event)"
                ></ag-grid-angular>
                
            </div>
        </div>
    </div>
</div>
 