import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, FormArray, FormsModule } from '@angular/forms';
import {  QuestionMaster, QuestionViewData ,QuestionData, QAnswerData} from '../shared/question.model';
import { QuestionService } from '../shared/question.service';
import {NotificationService} from '../shared/notification.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddmlinfoComponent } from './addmlinfo/addmlinfo.component';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { CUSTOMCONST } from '../shared/Const';
import { ThisReceiver } from '@angular/compiler';
import { formatDate, NgIf, NgFor, NgClass } from '@angular/common';
import { DateService } from '../services/date.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';


@Component({
    selector: 'app-questionedit',
    templateUrl: './questionedit.component.html',
    styleUrls: ['./questionedit.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, NgClass, MatCheckboxModule, MatCardModule, AngularEditorModule, MatRadioModule, //Ng7LargeFilesUploadLibModule,
       MatIconModule, TranslateModule, //NgForm
       ]
})
export class QuestioneditComponent implements OnInit {
// formData : QuestionViewData;
list: any[] = [];
classList : any = [];
subjectlist : any = [];
topiclist : any = [];
questiontypelist : any = [];
questionlist : any = [];
noofQuestions:number = 0;
datepickerConfig : BsDatepickerConfig;
public questionInfo :QuestionData;
gridApi;
gridColumnApi;
frameworkComponents;
columnDefs;
defaultColDef;
rowSelection;
bsModalRef2: BsModalRef;
gridOptions:any;
IsStore:boolean;
QuestionType:any;
FormGroup:UntypedFormGroup;
totalRow: number;
QindexLabel:any[] = [];
QindexLabel2:any[] =[];

bsValue = new Date();
bsRangeValue: Date[];
maxDate = new Date();
minDate = new Date();
time: Date = new Date();
public startTime: string;
public endTime: string;
CUSTOMCONST : any;
role:string;
optionTypeList: string[] = ['Text', 'Image'];
yearList:any=[];
timeSlotList:any;
appDate: NgbDate;


constructor(public service:CommonService, public questionservice:QuestionService, public bsModalRef: BsModalRef,
  private notificationServ:NotificationService,private modalService: BsModalService , private _fb : UntypedFormBuilder,
  public dateService: DateService) {
  this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
 // this.minDate.setDate(this.minDate.getDate() - 0);
  //this.maxDate.setDate(this.maxDate.getDate() + 30);
  //this.bsRangeValue = [this.bsValue, this.maxDate];
  this.CUSTOMCONST = CUSTOMCONST;
  this.service.RefreshItemList$.subscribe(
    item => {
      this.questionservice.getquestioninfo(this.list[0].id).subscribe((res: QuestionViewData) => {

      this.subjectlist = res.SubjectList;
      this.topiclist = res.TopicList;
      this.questionlist = res.QuestionInfo;

    });
      this.service.getclasses().subscribe((res: any) => {
        this.classList=res;
      });
     }
  );
 }

ngOnInit(): void {
  this.service.gettimelist().subscribe(res=>{
    console.log("timeSlotList");
    console.log(res);

    this.timeSlotList = res;
  })
  this.role = localStorage.getItem('UserRole')
    this.questionservice.getquestioninfo(this.list[0].id).subscribe((res: QuestionViewData) => {

    this.questionservice.formData = res;
    this.subjectlist = res.SubjectList;
    this.topiclist = res.TopicList;
    this.questiontypelist = res.QuestionTypeList;
    this.yearList = res.AcademicYearList;
      this.questionservice.formData.QuestionMasterInfo.QuestionMasterId = this.list[0].id;

      this.questionlist = res.QuestionInfo;

  });
  this.service.getclasses().subscribe((res: any) => {
    this.classList=res;
  });

    this.questionservice.formData.QuestionMasterInfo = new QuestionMaster();
    this.questionservice.formData.QuestionMasterInfo.SubjectId = 0;
    this.questionservice.formData.QuestionMasterInfo.TopicId = 0;
    this.questionservice.formData.QuestionMasterInfo.ClassId = 0;

}
enddateSetting(){
  this.minDate = new Date();
}
addNewRow(Type:string){

  if(this.questionInfo.QuestionType == 'FIB'){
    var answer1 = new QAnswerData();
    answer1.IsActive = true;
    answer1.Answer = '';
    answer1.FileId = 0;
    answer1.AnswerNo = this.questionInfo.QAnswerList.length+1;
    this.questionInfo.QAnswerList.push(answer1);
  }else if(this.questionInfo.QuestionType == 'MC' || this.questionInfo.QuestionType == 'MR'){
   if(this.questionInfo.QAnswerList.length+1 <= 8)
    {
      var answer1 = new QAnswerData();
      answer1.IsActive = true;
      answer1.Answer = '';
      answer1.FileId = 0;
      answer1.AnswerNo = this.questionInfo.QAnswerList.length+1;
      this.questionInfo.QAnswerList.push(answer1);
    }
    else{
      this.notificationServ.ShowInfo('You can add maximam 8 rows only!');
    }
  }
  else if(this.questionInfo.QuestionType == 'MF'|| this.questionInfo.QuestionType == 'MM'){
    if(this.questionInfo.QAnswerList.length+1 <= 8)
     {
       var answer1 = new QAnswerData();
       answer1.IsActive = true;
       answer1.Answer = '';
       answer1.Answer1 = '';
       answer1.FileId = 0;
       answer1.GroupNo = this.QindexLabel[this.questionInfo.QAnswerList.length].text;
       answer1.GroupNo1 = this.QindexLabel2[this.questionInfo.QAnswerList.length].text;
       answer1.AnswerNo = this.questionInfo.QAnswerList.length+1;
       this.questionInfo.QAnswerList.push(answer1);
     }
     else{
       this.notificationServ.ShowInfo('You can add maximam 8 rows only!');
     }
   }
}
deleteRow(qAnswer: QAnswerData)
{
 this.questionInfo.QAnswerList.forEach((element,index)=>{
  if(element.AnswerNo== qAnswer.AnswerNo) this.questionInfo.QAnswerList.splice(index,1);
});
// debugger;
 for(var i =0;i<this.questionInfo.QAnswerList.length;i++){
   this.questionInfo.QAnswerList[i].AnswerNo = i+1;
 }
}
questionInfoStore(form:NgForm){
  if(this.questionservice.formData.QuestionMasterInfo.NoOfQuestions >= 1 ){
    this.IsStore = true;

    if(this.list[0].id>0 && this.questionservice.formData.QuestionInfo && this.questionservice.formData.QuestionInfo.length > 0){
      this.questionInfo = this.questionservice.formData.QuestionInfo[0];
      this.noofQuestions = this.questionservice.formData.QuestionMasterInfo.NoOfQuestions;
     this.questionTypeChange();
   }
   else{
    this.noofQuestions = this.questionservice.formData.QuestionMasterInfo.NoOfQuestions;
    this.questionInfo = new QuestionData();
    this.questionInfo.QuestionNo=1;
    this.questionInfo.QuestionTypeId =0;
   }
  }




}
changeTF() {
  this.questionInfo.QAnswerList[1].IsCorrect = !this.questionInfo.QAnswerList[0].IsCorrect;
}
changeMC(length, index ) {

  var i=0;
  for(i; i<length; i++ ){
    if(i!=index)
    {
      this.questionInfo.QAnswerList[i].CorrectLabel = '';
    }
    else
    {
      this.questionInfo.QAnswerList[index].CorrectLabel = this.questionInfo.QAnswerList[index].CorrectLabel;
    }

  }
}
addNewAnswer(){
  var nextQuestion = [];
  if(this.questionservice.formData.QuestionInfo && this.questionservice.formData.QuestionInfo.length > 0){
    nextQuestion = this.questionservice.formData.QuestionInfo.filter(item => item.QuestionNo == this.questionInfo.QuestionNo+1);
  }
  if(this.list[0].id>0 && nextQuestion.length > 0){
    //this.questionInfo;
    if(this.questionInfo.QuestionNo < this.questionservice.formData.QuestionInfo.length ){
      this.questionInfo = this.questionservice.formData.QuestionInfo[this.questionInfo.QuestionNo];
      // this.questionInfo.QuestionNo=1;
      // this.questionInfo.QuestionTypeId =this.questionInfo[0].QuestionTypeId;
      this.questionTypeChange();
    }

  }
  else if(nextQuestion.length > 0){
  //  var currentQ  =this.service.formData.QuestionInfo.filter(item => item.QuestionNo == this.questionInfo.QuestionNo);
  //  currentQ = this.questionInfo;
  this.questionInfo = nextQuestion[0];
  this.QuestionType = this.questionInfo.QuestionType;
  }
  else{

  if(this.questionservice.formData.QuestionInfo == null)
  this.questionservice.formData.QuestionInfo = new Array<QuestionData>();
  // debugger;
  if(this.questionInfo.QuestionId <= 0)
  this.questionservice.formData.QuestionInfo.push(this.questionInfo);
  this.questionInfo = new QuestionData();
  this.questionInfo.QuestionNo = this.questionservice.formData.QuestionInfo.length+1;
  // this.tempVal = this.questionInfo.QuestionNo;
  this.questionInfo.QuestionTypeId = 0;
  this.questionInfo.Points = 0;
  this.QuestionType = '';
  }
}
prevQuestion(){
  if(this.list[0].id>0){
    //this.questionInfo;
    var tempVal = this.questionInfo.QuestionNo-1;
    if(tempVal>0){
      this.questionInfo = this.questionservice.formData.QuestionInfo[tempVal-1];
    }
    this.questionTypeChange();
  }else{


  // debugger;
    if(this.questionInfo.QuestionNo > 1){
      var prvQ = this.questionservice.formData.QuestionInfo.filter(item => item.QuestionNo == this.questionInfo.QuestionNo-1);
    this.questionInfo = prvQ[0];
    this.QuestionType = this.questionInfo.QuestionType;
    if (this.questionInfo.QuestionId <= 0)
      this.questionservice.formData.QuestionInfo.pop();
    }
    else{
      this.IsStore = false;
    }
  }
}
saveQuestionInfo(form:NgForm){
  debugger;
  var statrtime = this.questionservice.formData.QuestionMasterInfo.StartTime;
  var endtime = this.questionservice.formData.QuestionMasterInfo.EndTime;

  // if(this.questionservice.formData.QuestionInfo == null){
  //   this.questionservice.formData.QuestionInfo = new Array<QuestionData>();
  // this.questionservice.formData.QuestionInfo.push(this.questionInfo);
  // }
  if(this.questionservice.formData.QuestionInfo == null)
  this.questionservice.formData.QuestionInfo = new Array<QuestionData>();
  if(this.questionInfo.QuestionId <= 0 || (this.questionservice.formData.QuestionInfo.filter(item => item.QuestionId == this.questionInfo.QuestionId) || []).length <= 0){
  this.questionservice.formData.QuestionInfo.push(this.questionInfo);
  }

  var formData = JSON.parse(JSON.stringify(this.questionservice.formData));
  // formData.QuestionMasterInfo.StartTime = formatDate(statrtime,'HH:mm','en');
  // formData.QuestionMasterInfo.EndTime = formatDate(endtime,'HH:mm','en');

  // var QinfoLength = this.questionservice.formData.QuestionInfo.length;
  // for(var i=0; i <= QinfoLength; i++ ){
  //   if(i < this.questionservice.formData.QuestionMasterInfo.NoOfQuestions){
  //     this.questionInfo.IsActive = true;
  //   }
  //   else{
  //     this.questionInfo.IsActive = false;
  //   }
  // }

  this.questionservice.savequestioninfo(formData).subscribe((res:QuestionViewData)=>{
    if(res.ErrorMessage){
      this.notificationServ.ShowError(res.ErrorMessage);
    }
    else if(res.IsSaved){
  this.notificationServ.ShowInfo('Successfully saved!');
  this.service.CallOnRefreshGrid();
  this.bsModalRef.hide();
}
  });
}
classChange(){
  if(this.questionservice.formData.QuestionMasterInfo.ClassId == -1){
    this.openAddMLInfoModal('ClassData',0,0);
  }
  else if(this.questionservice.formData.QuestionMasterInfo.ClassId > 0){
    this.subjectlist = [];
    this.service.getsubjects(this.questionservice.formData.QuestionMasterInfo.ClassId).subscribe((res => {
      this.subjectlist = res;
    }));
  }
}
sbjectChange() {
  if(this.questionservice.formData.QuestionMasterInfo.SubjectId < 0){
    if(this.questionservice.formData.QuestionMasterInfo.ClassId > 0){
      this.openAddMLInfoModal('MLSubject',this.questionservice.formData.QuestionMasterInfo.ClassId,0);
    }
    else{
      this.notificationServ.ShowError('Please select class.');
    }

  }
  else if(this.questionservice.formData.QuestionMasterInfo.ClassId > 0 && this.questionservice.formData.QuestionMasterInfo.SubjectId > 0){
    this.topiclist = [];
    this.service.gettopics(this.questionservice.formData.QuestionMasterInfo.ClassId,this.questionservice.formData.QuestionMasterInfo.SubjectId).subscribe((res => {
      this.topiclist = res;
    }));
  }

}
topicChange() {
  if(this.questionservice.formData.QuestionMasterInfo.TopicId < 0){
    if(this.questionservice.formData.QuestionMasterInfo.ClassId > 0 && this.questionservice.formData.QuestionMasterInfo.SubjectId > 0){
      this.openAddMLInfoModal('MLTopic',this.questionservice.formData.QuestionMasterInfo.ClassId,this.questionservice.formData.QuestionMasterInfo.SubjectId);
    }
    else if(this.questionservice.formData.QuestionMasterInfo.ClassId <= 0){
      this.notificationServ.ShowError('Please select class.');
    }
    else if(this.questionservice.formData.QuestionMasterInfo.SubjectId <= 0){
      this.notificationServ.ShowError('Please select subject.');
    }
  }

}
questionTypeChange(){
  if(this.list[0].id > 0 && this.questionInfo.QuestionId > 0){
    this.QuestionType = '';
    if(this.questionInfo.QuestionTypeId > 0){
      for(var i = 0 ;i<this.questiontypelist.length;i++){
        if(this.questiontypelist[i].Id == this.questionInfo.QuestionTypeId){
          this.QuestionType = this.questiontypelist[i].Code;
          break;
        }
      }
    }
    this.questionInfo.QuestionType = this.QuestionType;
    if(this.QuestionType == 'MC' || this.QuestionType == 'MR'){
      this.QindexLabel = [{text: 'A'},{text: 'B'},{text: 'C'},{text: 'D'},{text: 'E'},{text: 'F'},{text: 'G'},{text: 'H'}];
    }
    else  if(this.QuestionType == 'TF'){
      this.QindexLabel = [{text: true},{text: false}];
    }
   // this.questionInfo.QAnswerList=this.questionInfo[0].QAnswerList;
  }
  else{
    this.questionInfo.OptionType="Text";
    this.QuestionType = '';
    if(this.questionInfo.QuestionTypeId > 0){
      for(var i = 0 ;i<this.questiontypelist.length;i++){
        if(this.questiontypelist[i].Id == this.questionInfo.QuestionTypeId){
          this.QuestionType = this.questiontypelist[i].Code;
          break;
        }
      }
    }
  this.questionInfo.QuestionType = this.QuestionType;
  // this.initItemRow(this.questiontypelist[i].Code);
  if(this.QuestionType == 'TF'){
    this.QindexLabel = [{text: true},{text: false}];
    // this.questionInfo.QAnswerList[0].Answer= "True";
    // this.questionInfo.QAnswerList[1].Answer= "False";
    this.questionInfo.QAnswerList = new Array<QAnswerData>();
    var answer1 = new QAnswerData();
    answer1.AnswerNo = 1;
    answer1.IsActive = true;
    answer1.Answer = "True";
    answer1.IsCorrect = true;
    answer1.FileId = 0;
    this.questionInfo.QAnswerList.push(answer1);
    var answer2 = new QAnswerData();
    answer2.AnswerNo = 2;
    answer2.IsActive = true;
    answer2.Answer = "False";
    answer2.FileId = 0;
    this.questionInfo.QAnswerList.push(answer2);
  } else if(this.QuestionType == 'FIB' || this.QuestionType == 'Eassy'){
    this.questionInfo.QAnswerList = new Array<QAnswerData>();
    var answer1 = new QAnswerData();
    answer1.AnswerNo = 1;
    answer1.IsActive = true;
    answer1.IsCorrect = true;
    answer1.FileId = 0;
    this.questionInfo.QAnswerList.push(answer1);
  } else if(this.QuestionType == 'MC' || this.QuestionType == 'MR'){
    this.QindexLabel = [{text: 'A'},{text: 'B'},{text: 'C'},{text: 'D'},{text: 'E'},{text: 'F'},{text: 'G'},{text: 'H'}];
    this.questionInfo.QAnswerList = new Array<QAnswerData>();

    var answer1 = new QAnswerData();
    answer1.AnswerNo = 1;
    answer1.IsActive = true;
    answer1.FileId = 0;
    answer1.GroupNo = this.QindexLabel[0].text;
    this.questionInfo.QAnswerList.push(answer1);

    var answer2 = new QAnswerData();
    answer2.AnswerNo = 2;
    answer2.IsActive = true;
    answer2.FileId = 0;
    answer2.GroupNo = this.QindexLabel[1].text;
    this.questionInfo.QAnswerList.push(answer2);

    var answer3 = new QAnswerData();
    answer3.AnswerNo = 3;
    answer3.IsActive = true;
    answer3.FileId = 0;
    answer3.GroupNo = this.QindexLabel[2].text;
    this.questionInfo.QAnswerList.push(answer3);

    var answer4 = new QAnswerData();
    answer4.AnswerNo = 4;
    answer4.IsActive = true;
    answer4.FileId = 0;
    answer4.GroupNo = this.QindexLabel[3].text;
    this.questionInfo.QAnswerList.push(answer4);

  }else if(this.QuestionType == 'MF' || this.QuestionType == 'MM'){
    this.QindexLabel = [{text: 'A'},{text: 'B'},{text: 'C'},{text: 'D'},{text: 'E'},{text: 'F'},{text: 'G'},{text: 'H'}];
    this.QindexLabel2 = [{text: 'P'},{text: 'Q'},{text: 'R'},{text: 'S'},{text: 'T'},{text: 'U'},{text: 'V'},{text: 'W'}];
    this.questionInfo.QAnswerList = new Array<QAnswerData>();

    var answer1 = new QAnswerData();
    answer1.Answer = '';
    answer1.Answer1 = '';
    answer1.GroupNo = this.QindexLabel[0].text;
    answer1.GroupNo1 = this.QindexLabel2[0].text;
    answer1.AnswerNo = 1;
    answer1.IsActive = true;
    answer1.FileId = 0;
    this.questionInfo.QAnswerList.push(answer1);

    var answer2 = new QAnswerData();
    answer2.Answer = '';
    answer2.Answer1 = '';
    answer2.GroupNo = this.QindexLabel[1].text;
    answer2.GroupNo1 = this.QindexLabel2[1].text;
    answer2.AnswerNo = 2;
    answer2.IsActive = true;
    answer2.FileId = 0;
    this.questionInfo.QAnswerList.push(answer2);

    var answer3 = new QAnswerData();
    answer3.Answer = '';
    answer3.Answer1 = '';
    answer3.GroupNo = this.QindexLabel[2].text;
    answer3.GroupNo1 = this.QindexLabel2[2].text;
    answer3.AnswerNo = 3;
    answer3.IsActive = true;
    answer3.FileId = 0;
    this.questionInfo.QAnswerList.push(answer3);

    var answer4 = new QAnswerData();
    answer4.Answer = '';
    answer4.Answer1 = '';
    answer4.GroupNo = this.QindexLabel[3].text;
    answer4.GroupNo1 = this.QindexLabel2[3].text;
    answer4.AnswerNo = 4;
    answer4.IsActive = true;
    answer4.FileId = 0;
    this.questionInfo.QAnswerList.push(answer4);
  }
  }
}
openAddMLInfoModal(tableName,classId,subjectId) {
  const initialState = {
    list: [
      {id:0},
      {Text:tableName},
      {classId:classId},
      {subjectId:subjectId}
    ]

  };

  this.bsModalRef2 = this.modalService.show(AddmlinfoComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
}
largefileschange(event){
}
qAnswerFilesuploaded(fileInfo, index){
  if(fileInfo && fileInfo.IsSaved){
      this.questionInfo.QAnswerList[index].FileId = fileInfo.UserDocumentId;
  }


}
questionNoChange(){
  if(this.questionInfo.QuestionNo > 0){
    for(var i = 0 ;i<this.questionlist.length;i++){
      if(this.questionlist[i].QuestionNo == this.questionInfo.QuestionNo){
        this.questionInfo.QuestionNo = (this.questionInfo.QuestionNo - 1)
        this.addNewAnswer()
        break;
      }
    }
  }
}
htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  chkIsGreater(){
    let date = this.dateService.getStringDate(new Date(this.appDate.year, this.appDate.month - 1, this.appDate.day))
    let sdatetime = this.dateService.getDateTimeFromString(date, this.questionservice.formData.QuestionMasterInfo.StartTime)
    let edatetime = this.dateService.getDateTimeFromString(date, this.questionservice.formData.QuestionMasterInfo.EndTime);
    if(sdatetime > edatetime){
      this.notificationServ.ShowError("End Time should be grater than Start time");
      this.questionservice.formData.QuestionMasterInfo.EndTime = "";
    }
  }

}
