<div class="container-fluid ">
    <div class="row justify-content-center">
        <div class="col-10 pt-5">
            <div class="row mt-5 align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                        <h1 class="d-block title pt-1 m-0">Dedicated one-on-one meeting</h1>
                        <span>Last Updated:Augustb 7,2023</span>
                        <p class="mt-2">It is HybridLearning.in mission to help schools and parents create world-class
                            learning experiences by providing to them a one stop end- to- end solution. To ensure this,
                            it is essential that we create a safe and secure environment where teachers, children and
                            parents freely share content and ideas. Creating such a safe and secure environment is
                            central to the success of our endeavours.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center bg-white">
        <div class="col-10">
            <div class="row align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">1. Introduction</h1>


                    <p>
                        1.1. These Terms of Service govern your use of our websites at <a
                            href="www.HybridLearning.in">www.HybridLearning.in</a>, and the HybridLearning.in
                        Application. By signing this agreement, you agree to be bound by our Terms of Service (our
                        “Terms”).
                    </p>
                    <p>
                        1.2. Occasionally Service Provider* may, at its discretion, make changes to the Service* and the
                        Order Form to account for changes in practice and applicable laws. Upon such material changes to
                        the Agreement or Services, Service Provider shall notify the Subscriber* by email and post a
                        notice on our Service 15 days before the changes take effect. By continuing to use the Service
                        or entitle the Users to continue using the Service after changes are made, Subscriber is
                        expressing and acknowledging its acceptance of the changes.
                    </p>
                    <p class="mt-1 mb-1">*See definitions below</p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">2. Definitions</h1>


                    <p>
                        In this document, unless the context otherwise requires:
                    </p>

                    <p>

                        “<b>Agreement</b>” or “<b>Order Form</b>” means the order form, these Terms of Service and any
                        supplementary
                        documents referred to herein (executed in written form online).<br>

                        “<b>Confidential Information</b>” means any information that: (i) if disclosed in writing, is
                        labelled as “confidential” or
                        “proprietary”; (ii) if disclosed orally, is designated confidential at disclosure; or (iii)
                        whether it being written or
                        oral and whether it being designated as confidential or not, by its nature, and/or the
                        circumstances of its disclosure,
                        makes it reasonably likely that it is confidential. Confidential Information may include, but is
                        not limited to, trade
                        secrets, discoveries, ideas, concepts, know-how, techniques, future product plans, designs,
                        specifications, data,
                        computer programs, business activities and operations, customer lists, reports, studies,
                        personnel information,
                        financial information, information disclosed by demonstration of any product and other technical
                        and business
                        information.<br>

                        “<b>Effective Date</b>” means the date the Subscriber and the Service Provider execute the Order
                        Form.<br>

                        “<b>Intellectual Property Rights</b>” means all copyright and related rights, design right,
                        registered designs, patents, trade
                        and service marks (registered and unregistered), database rights, know-how, rights in
                        confidential information and all
                        other intellectual property rights throughout the world for the full term of the rights
                        concerned.<br>

                        "<b>Service</b>” means access to web portals and mobile applications provided by the Service
                        Provider, including the planning, documentation, evidence collection, assessment, portfolio
                        tools and any other tools or features that may be developed in the future.<br>

                        “<b>Service Provider</b>” means NESR Infotech Private Limited, operating through its brand
                        HybridLearning.in.<br>

                        specific Subscriber and its registered Users. The Subscription Period is twelve (12) months with
                        automatic renewal every 12 months and will commence on the Effective Date, or where so agreed a
                        longer or shorter period, as set forth in the subscription service form. Each agreed
                        subscription will have a separate Subscription Period<b>Subscri.</b>“<b>ption Period</b>” means
                        the
                        Service subscription period for a <br>

                        “<b>Terms and conditions of Use</b>” means the Service Provider’s supplementary document “Terms
                        and
                        conditions of Use” that the Subscriber must accept to use the Service, available at as the same
                        may be updated from time to time.<br>

                        “<b>User Account Information</b>” means information requested by Service Provider on
                        Subscriber’s Users
                        to set up and register the Users for Services.<br>

                        “<b>Users</b>” means individual students, teachers, parents, administrators, and content
                        providers who are authorized by the
                        Subscriber to use the Service, for whom subscriptions to a Service have been procured, and who
                        have been supplied user
                        identifications and passwords by Subscriber (or by Service Provider at Subscriber’s request).

                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">3. Supply of Services</h1>


                    <p>
                        3.1. Service Provider hereby undertakes to supply to Subscriber the subscribed Service Deals on
                        the terms set forth in
                        the Order Form
                    </p>
                    <p>
                        3.2. By subscribing to Services, the signatory warrants and represents that it is authorized to
                        bind the Subscriber to
                        this Agreement. Subscriber further warrants and represents that; (i) it is registered in its
                        territory and will only
                        grant User access to the Services in that territory; (ii) it complies with and will comply with
                        the subscription
                        requirements set out in the Order Form; (iii) any registration information that it submits to
                        the Service Provider is
                        true, accurate and complete, and it agrees to keep it that way at all times; (iv) it is
                        authorized to grant all
                        permissions and licenses provided in this Agreement to its Users; (v) it shall comply with any
                        laws and regulations that
                        apply to its use of the Services (in particular local regulatory regimes on privacy and data
                        protection); and (vi) it
                        shall not use the Services for any other use than set forth herein and shall not allow any third
                        party to do so.
                    </p>
                    <p>
                        3.3. Use of the Services requires User registration with Service Provider. Such registration is
                        made by Service Provider
                        upon receiving the requested User Account Information from Subscriber.
                    </p>
                    <p>
                        3.4. Subscriber confirms that it will accept and comply with Service Provider’s Terms and
                        conditions of Use and other
                        relevant terms, and that Users consent to the processing of any personal data as set forth in
                        this Agreement.
                    </p>
                    <p>
                        3.5. Subscriber confirms that it is responsible that it’s Users always comply with the terms of
                        the Agreement and adhere
                        to Service Provider’s requirements and guidelines in relation to use of the Service, as
                        designated by Service Provider
                        from time to time, including applicable Terms and conditions of Use and instructions.
                    </p>
                    <p>
                        3.6. Subscriber or registered Users are responsible for all hardware, communication networks and
                        other equipment
                        necessary for use of Services, and the due installation thereof. Subscriber is solely
                        responsible for all activities
                        conducted by Users, through each of any User’s logins. Subscriber is not allowed to engage in
                        service bureau use,
                        outsourcing, renting, reselling, sublicensing, concurrent use of a single User login, or
                        time-sharing of the Service.
                    </p>
                    <p>
                        3.7. Subscriber shall not and shall not permit Users to; (i) copy, translate, create a
                        derivative work of, reverse
                        engineer, reverse assemble, disassemble, or decompile the Service or any part thereof or
                        otherwise attempt to discover
                        any source code or modify the Service in any manner or form; (ii) use the Service in a manner
                        that is contrary to
                        applicable law or in violation of any third party rights of privacy or Service Provider’s
                        Intellectual Property Rights;
                        (iii) use or knowingly permit the use of any security testing tools in order to probe, scan or
                        attempt to penetrate or
                        ascertain the security of the Service; (iv) access the Service by any means other than through
                        the interfaces that are
                        provided by Service Provider; or (v) use or allow Users or third parties to use the Services in
                        any unauthorized manner
                        or in breach of the Terms of Use.
                    </p>
                    <p>
                        3.8. The Service Provider shall; (i) authorize registered Users access to and assign unique
                        passwords and usernames to
                        Users; (ii) grant Users access to the portals and mobile applications relevant to that User in
                        accordance with the terms
                        and conditions of this Agreement; and (iii) endeavour that the use of the Service is secure,
                        error-free, or that the
                        Service will meet the subscribed requirements, or that errors in the Service are reasonably
                        resolved and that the
                        overall system hosting the Service is free of viruses or other harmful components. Service
                        Provider will use its
                        reasonable endeavours to resolve any issues related to the Service, as part of its technical
                        support obligations.
                    </p>
                    <p>
                        3.9. Service Provider reserves the right to make such changes to the features and functionality
                        of the Service, systems
                        and interfaces as Service Provider sees fit at its sole discretions. Notwithstanding the
                        aforesaid, Service Provider
                        will notify the Subscriber, or where relevant its Users, of any material changes in advance
                        where reasonably practicable
                        and where such prior notice would not adversely affect Service Provider’s commercial interests.
                    </p>
                    <p>
                        3.10. To the fullest extent possible under applicable law, Service Provider does not give any
                        warranty, express or
                        implied, as to the quality, content and availability or fitness for a specific purpose of the
                        Service, or the non-
                        infringement of any third-party Intellectual Property Rights.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">4. FEES AND PAYMENT</h1>


                    <p>
                        4.1. The Services are provided as specified in the Order Form and in email from the designated
                        contact person. Prices
                        and conditions for subscribed Service are as set forth in the Order Form.
                    </p>
                    <p>
                        4.2. The prices and conditions for the services are subject for a review from time to time, and
                        the Service Provider
                        reserves the right to propose adjustments to the prices at the latest two (2) months prior to
                        the expiration of a
                        Subscription Period. Unless the Subscriber cancels the subscription pursuant to the terms set
                        forth herein, the adjusted
                        prices for the subscribed Service Deals will apply when the subscription is renewed for a
                        subsequent successive
                        Subscription Period.
                    </p>
                    <p>
                        4.3. Service Provider will invoice Subscriber for the Full Subscription Period at the start of
                        each Subscription Period.
                        Unless otherwise agreed in the Agreement, Service Provider will invoice the Subscriber the Full
                        Subscription Period as
                        specified in the Order Form. Invoiced Amounts are in the currency set out in the Order Form, and
                        exclude taxes, levies
                        or duties of any kind including value added, General Sales, sales use, or withholding taxes.
                    </p>
                    <p>
                        4.4. All invoices are payable within fourteen (14) calendar days as of the relevant invoice
                        date, unless otherwise
                        agreed in the Agreement. All amounts owed hereunder, not paid when due, will be subject to
                        penalty interest at the rate
                        of 18% per annum, as well as compensation for costs for recovery of late payment. Upon late
                        payment, Service Provider,
                        at its own discretion, may temporarily restrict Subscribers and Users access to the Service,
                        until such time that full
                        payment is received.
                    </p>
                    <p>
                        4.5. Incase the actual number of users/ students is different from the number of users/ students
                        mentioned in the order
                        form, an adjustment will be made in the invoice raised in the next billing cycle.
                    </p>

                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">5. SUBSCRIPTION AND CANCELLATION</h1>


                    <p>
                        5.1. Unless otherwise agreed in the Agreement, the Subscription Period shall be twelve (12) months commencing on the
                        Effective Date. Unless the Subscriber cancels the subscription pursuant to the terms set forth herein, a subscription is
                        automatically renewed for a subsequent successive Subscription Period.
                    </p>
                    <p>
                        5.2. The subscription is binding during the full duration of the Subscription Period. Notwithstanding the aforesaid,
                        Subscriber may prematurely cancel a subscription by submitting a cancellation note to the Service Provider at the latest
                        sixty (60) calendar days prior to expiration of a Subscription Period.
                    </p>
                    <p>
                        5.3. Pursuant to the provisions herein, any Subscriber requests for modification of the Service’s scope or cancellation
                        of the Subscription Period must be submitted in writing to the designated contact person as mentioned in the order form.
                    </p>
                    <p>
                        5.4. Upon the termination of this Agreement for any reason any sum owing or due to Service Provider shall be immediately
                        payable and the rights of the Subscriber and Users herein shall be immediately cancelled.
                    </p>                
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">6. INTELLECTUAL PROPERTY RIGHTS</h1>


                    <p>
                        6.1. Service Provider and its licensors, as applicable, shall remain the exclusive owner of all Intellectual Property
                        Rights in the Service (including the underlying technology, software, and content). Subscriber may not remove any
                        legends or statements in the Service, or any materials provided therewith regarding Service Provider’s and its
                        licensors’ proprietary rights.
                    </p>
                    <p>
                        6.2. Service Provider’s trademarks, service marks, trade names, logos, domain names, and any other features of the
                        Service are the sole property of Service Provider and its licensors. The license granted to Subscriber and its Users
                        herein does not grant any rights to use Intellectual Property Rights or any other features of the Service, whether for
                        commercial or non-commercial use.
                    </p>
                    <p>
                        6.3. It follows from the Service Provider’s Terms and conditions of Use that Subscriber and/or its Users shall become
                        the owner of any content that they create on the Toddle portals. The content created on journals and portfolios shall
                        belong to the parents and children (wherever they are above the legal age).
                    </p>                          
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">7. CONFIDENTIALITY AND PRIVACY</h1>


                    <p>
                        7.1. All Confidential Information exchanged between the Service Provider, Subscriber and its Users shall be kept
                        strictly confidential and not disclosed to any third party without prior written consent of the owner of the
                        Confidential Information. At all times, Confidential Information shall be treated and stored carefully and appropriately
                        so that the Confidential Information is not inadvertently made available to any third party or otherwise disclosed in
                        breach of this Agreement.
                    </p>
                    <p>
                        7.2. Notwithstanding the aforesaid, this Agreement shall not prohibit the disclosure of Confidential Information to the
                        extent that such disclosure is permitted or required by law, regulation or order of a court or other governmental
                        authority. Furthermore, the Service Provider may disclose Confidential Information on a need-to-know basis to its
                        contractors and service providers who have executed written agreements requiring them to maintain such information in
                        strict confidence and use it only to facilitate the performance of their services in connection with the Services.
                    </p>
                    <p>
                        7.3. All the data that the Subscriber enters in HybridLearning.in belongs to the Subscriber, however, the Subscriber and
                        its Users may not copy, make transcriptions or recordings or in any other way reproduce or duplicate any document or
                        other medium containing Confidential Information or Intellectual Property Rights, without Service Provider’s written
                        consent. Upon termination of this Agreement, the Subscriber may request the Service Provider to provide it with a copy
                        of all the data. Service Provide will cater to this request within one week of receiving such a request.
                    </p>
                    <p>
                        7.4. Service Provider will comply with applicable Data Protection Regulation as well as with its own, at each time
                        applicable, Privacy Policy in relation to any Subscriber or User personal data that it may collect and process for the
                        purposes of supplying the Service. Service Provider will process any such personal data in accordance with Service
                        Provider’s Privacy Policy, available online at <a href="https://www.hybridlearning.in">https://www.hybridlearning.in</a>
                    </p>                          
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">8. LIMITATION OF LIABILITY</h1>


                    <p>
                        8.1. Service Provider shall not be liable for any loss of profits, business, goodwill, revenue, sales, or data, or for
                        any indirect, consequential, incidental, or special loss or damages of any kind under or in connection with this
                        Agreement, save where such loss or damage is due to Service Provider’s gross negligence or willful violation of the
                        terms of this Agreement. Notwithstanding aforesaid, the Service Provider’s maximum liability due to any person, firm or
                        corporation whatsoever arising out of or in the connection with any license, use or other employment of the Service,
                        whether such liability arises from any claim based on breach or repudiation of contract, breach of warranty, negligence,
                        tort, statutory duty, or otherwise, shall in no case exceed the equivalent of three (3) months in subscription fees for
                        the relevant Subscription Period. Subscriber shall not have the right to terminate this Agreement unless such loss or
                        damage is due to Service Provider’s gross negligence or willful violation of the terms of this Agreement.
                    </p>
                    <p>
                        8.2. Service Provider shall not be liable for damages for any delay or default in performance of its undertakings of
                        obligations under this Agreement, if such delay or default is caused by force majeure, including but without limitation
                        thereto, wars, insurrections, fires, passing of laws or any governmental order, regulation or ruling, or any other
                        act(s) beyond the reasonable control of Service Provider, and Subscriber shall not have the right to terminate this
                        Agreement unless such delay or default in performance is caused by force majeure for a period of more than six (6)
                        months. During the force majeure event, Service Provider will use all reasonable efforts to avoid, reduce or eliminate
                        the force majeure event’s prevention, restriction, or delay of the performance of its obligations under this Agreement.
                    </p>                                           
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">9. CHANGE OF CONTROL</h1>


                    <p>
                        9.1. Over time, HybridLearning.in may grow and reorganize. We may share your information, including personal information
                        with affiliates such as a parent company, subsidiaries, joint venture partners or other companies that we control or
                        that are under common control with us, in which case we will require those companies to agree to use your personal
                        information in a way that is consistent with this Privacy Policy.
                    </p>
                    <p>
                        9.2. In the event of a change to our organizations such that all or a portion of HybridLearning.in or its assets are
                        acquired by or merged with a third-party, or in any other situation where personal information that we have collected
                        from users would be one of the assets transferred to or acquired by that third-party, this Privacy Policy will continue
                        to apply to your information, and any acquirer would only be able to handle your personal information as per this policy
                        (unless you give consent to a new policy). We will provide you with notice of an acquisition within thirty (30) days
                        following the completion of such a transaction, by posting on our homepage and by email to your email address that you
                        provided to us. If you do not consent to the use of your personal information by such a successor company, subject to
                        applicable law, you may request its deletion from the company.
                    </p>  
                    <p>
                        9.3. In the unlikely event that HybridLearning.in goes out of business, or files for bankruptcy, we will protect your
                        personal information, and will not sell it to any third-party.
                    </p>                                         
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">10. MISCELLANEOUS</h1>


                    <p>
                        10.1. Any notice or other communication to be given or served under or in connection with this Agreement shall be in
                        writing and shall be sent by e-mail to the other party’s contact person set forth in the order form.
                    </p>
                    <p>
                        10.2. Subscriber may not assign, mortgage, charge any of its rights or sub-contract or otherwise delegate any of its
                        obligations under this Agreement, except with the written consent of the Service Provider.
                    </p>  
                    <p>
                        10.3. This Agreement constitutes the whole of the agreement between the parties and supersedes any previous agreement
                        and no modification of this Agreement shall be effective unless it is made in writing and executed by or on behalf of
                        the parties unless otherwise is set forth in this Agreement.
                    </p>

                    <p>
                        10.4. No failure or delay by any party in exercising any of its rights under this Agreement shall be deemed to be a
                        waiver of that right, or otherwise prejudice, affect or restrict the rights or remedies of that party in relation to the
                        other party, and no waiver by any party of a breach of any provision of this Agreement shall be deemed to be a waiver of
                        any subsequent breach of the same or any other provision of this Agreement.
                    </p>
                    <p>
                        10.5. All provisions of this Agreement that can be reasonably interpreted as surviving upon the full performance,
                        expiry, termination, cancellation, or voidance of the Agreement (provisions on intellectual property rights and
                        confidentiality) shall survive said performance, expiry, termination, cancellation or voidance.
                    </p>
                </div>


                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-3">

                    <h1 class="d-block pt-1">11. APPLICABLE LAW AND DISPUTE RESOLUTION</h1>


                    <p>
                        11.1. Any dispute, controversy or claim arising out of or in connection with this Agreement, or the breach, termination,
                        or invalidity thereof, shall be finally settled by the Courts of Bengaluru, India
                    </p>
                    
                </div>
            </div>


        </div>
    </div>
</div>