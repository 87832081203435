import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public token: string = '';
  public role = '';
  public ApplicationUrl: string = '';
  public selLanguage: string = 'EN'
  public IsAllowPrint: boolean = false;
  public IsAllowExcel: boolean = false;
  public IsWrapDataListPDF: boolean = false;
  public ClinicUserCount: number = 0;
  public IsTermsSignRequired: boolean = false;
  public loggedUser: any;
  public regional: any = {};
  public username: string;
}
