import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { AnalysisQuestion } from '../shared/analysis.model';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { StudAnalysisQuestionComponent } from './stud-analysis-question.component';
import { AgGridModule } from 'ag-grid-angular';


@Component({
    selector: 'app-stu-analysis-results',
    templateUrl: './stu-analysis-results.component.html',
    styleUrls: ['./stu-analysis-results.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class StuAnalysisResultsComponent implements OnInit {
  list:any[]=[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  analysisStuColumnDefs;
  analysisStuDefaultColDef;
  analysisStuRowData;
  analysisStuRowSelection;
  analysisStuGridOptions:any;
  
  CUSTOMCONST:any=CUSTOMCONST; 
  
  constructor(public service:AnalysisService,  public modalService:BsModalService,
    public bsModalRef: BsModalRef,public commonServ:CommonService,private notificationServ:NotificationService) {
    
    this.analysisStuColumnDefs = [
      
      { field: 'Question', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'Score', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
     
      
  ];
  
  this.analysisStuDefaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
        sortable: true,
        floatingFilter: true,
  };
  this.analysisStuRowSelection = 'single';
  
}

  ngOnInit(): void {
  }
  onAnalyticGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getAnalysisList();
    }, 300);
    
  }
  getAnalysisList(){
    this.service.getStudentAnalysisResults(this.list[0].studentId,this.list[0].analysisMasterId).subscribe((res : any) => {
     
      this.service.formData =  res;
      this.analysisStuRowData =  res.AnalysisQuestionListInfo;

    });
  }










}
