
<div class="container-fluid bg-light pb-2 min-height-100vh">
    <div class="row m-0">
        <div class="col-xl-12 col-md-12 col-sm-12 p-0">
            <div class="pl-2">

                <div class="d-flex justify-content-between pt-2 pb-2">
                  <h2 class="m-0 p-0">Calender</h2>
                  <div  *ngIf="(role == 'Cordinator' || role == 'Admin' || role == 'Teacher')">
                    <div class="icon">
                        <label (click)="addEvent(0)">Add Event</label>
                    </div>
                </div>
                  <div class="d-flex align-items-center">

                    <img class="cursor-pointer" src="../../assets/images/icons/svg/left_arrow_gray.svg" alt="">
                    <h3 class="m-0 p-0">
                      <div class="input-group selectdiv">
                        <select [(ngModel)]="daysToShow" name="EndTime"
                        class="form-control form-control-sm appearance-auto" (change)="changedaysToShow()">
                            <option value="1">Day</option>
                            <option value="7">Week</option>
                            <option value="30">Month</option>
                        </select>
                    </div>
                    </h3>
                    <img class="cursor-pointer" src="../../assets/images/icons/svg/right_arrow_gray.svg" alt="">
                  </div>
                </div>
                <full-calendar [options]="calendarOptions">
                    <ng-template #eventContent let-arg >
                      <!-- <pre>{{arg.event|json}}</pre> -->
                      <h3 class="m-0">{{ arg.event.title }}</h3>
                      <p class="m-0">{{ arg.event.extendedProps.Description }}</p>
                      <div class="fc-event-border-top" [ngStyle]="{'border-color':arg.event.borderColor}"></div>

                    <div class="delete-button" *ngIf="(role == 'Cordinator' || role == 'Admin' || role == 'Teacher')">
                        <div class="icon">
                            <fa-icon [icon]="deleteIcon" (click)="deleteEvent(arg.event.extendedProps.AppointmentId)" title="Delete Event"></fa-icon>
                        </div>
                    </div>
                    </ng-template>
                  </full-calendar>
            </div>

        </div>
    </div>
</div>

