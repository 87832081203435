
<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >{{reqBy === 'Teacher'?('StudentAnswerPreview' | translate):('Result'| translate)}}</h3>
        </div>
        <div class="modal-body p-2  model-max-window-h">
            <div > <!--*ngIf="stdAnswerMasterInfo.ResponseFilePath==''"-->
                <div *ngFor="let questionInfo of service.formData.QuestionInfo; let Aindex=index">
                    <div *ngIf="questionInfo.QuestionType == 'Eassy'">
                        <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                          <div class="row m-0">
                            <div class="col-10 p-0">
                              <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                            </div>
                            <div class="col-2 p-0">
                              <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                            </div>
                          </div>

                        </h3>
                        <div class="form-group mb-0" >
                          <label class="text-primary font-weight-500">Student Ans.</label>
                          <p [innerHtml]="questionInfo.QAnswerList[0].StrAnswer"></p>
                       </div>
                       <div class="form-group mb-0">
                        <span class="text-success font-weight-500">Correct Ans.</span>
                        <p [innerHtml]="questionInfo.QAnswerList[0].Answer"></p>
                       </div>

                    </div>
                    <div  *ngIf="questionInfo.QuestionType == 'TF'">
                        <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                          <div class="row m-0">
                            <div class="col-10 p-0">
                              <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                            </div>
                            <div class="col-2 p-0">
                              <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                            </div>
                          </div>
                        </h3>


                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                          <div class="row form-row">
                              <div class="col-12">
                                <div class="form-group mb-0" >
                                    <div class="appearance-auto mb-3">
                                        <div class="custom-p-2" [ngClass]="qAnswer.IsCorrect?'correct-answer':qAnswer.IsCorrect != qAnswer.IsAnswer?'wrong-answer':''">
                                            <mat-radio-group aria-label="Select an option" class="" name="IsAnswer{{Aindex}}">
                                                <mat-radio-button [checked]="qAnswer.IsAnswer" value="qAnswer.IsAnswer" [disabled]="true">{{qAnswer.Answer}} <mat-icon *ngIf="qAnswer.IsAnswer">{{(qAnswer.IsAnswer == qAnswer.IsCorrect)?'done':'close'}}</mat-icon></mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </section>
                        <div class="col-12">
                          <img [src]="questionInfo.QAnswerList[0].FilePath" alt="" class="img img-fluid max-width-50px">
                        </div>
                    </div>
                    <div  *ngIf="questionInfo.QuestionType == 'MC'">
                        <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                          <div class="row m-0">
                            <div class="col-10 p-0">
                              <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                            </div>
                            <div class="col-2 p-0">
                              <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                            </div>
                          </div>
                        </h3>
                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                          <div class="row form-row">
                            <div class="col-12">
                              <div class="form-group mb-0" >
                                  <div class="appearance-auto mb-3">
                                      <!-- <div class="custom-p-2" [ngClass]="qAnswer.IsCorrect?'correct-answer':qAnswer.IsCorrect != qAnswer.IsAnswer?'wrong-answer':''">
                                          <mat-radio-group aria-label="Select an option" class="" name="IsAnswer" >
                                              <mat-radio-button  [checked]="qAnswer.IsAnswer" [value]="qAnswer.IsAnswer" [disabled]="true">{{qAnswer.Answer}}<mat-icon *ngIf="qAnswer.IsAnswer">{{(qAnswer.IsAnswer == qAnswer.IsCorrect)?'done':'close'}}</mat-icon></mat-radio-button>
                                            </mat-radio-group>
                                      </div> -->
                                      <div class="custom-p-2" [ngClass]="qAnswer.CorrectLabel != ''?'correct-answer':((qAnswer.StrAnswer != '') && qAnswer.CorrectLabel != qAnswer.StrAnswer)?'wrong-answer':''">
                                        <mat-radio-group aria-label="Select an option" class="" name="StrAnswer" >
                                            <mat-radio-button [checked]="qAnswer.StrAnswer != '' " [value]="qAnswer.StrAnswer" [disabled]="true">
                                              <span *ngIf="questionInfo.OptionType == 'Text'">{{qAnswer.Answer}} </span>
                                              <mat-icon *ngIf="(qAnswer.StrAnswer != '')">{{(qAnswer.CorrectLabel != '')?'done':'close'}}</mat-icon>
                                              <span *ngIf="questionInfo.OptionType == 'Image'"><img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px ml-3"></span>
                                            </mat-radio-button>
                                          </mat-radio-group>
                                    </div>
                                  </div>
                              </div>
                            </div>

                          </div>
                        </section>

                        </div>
                    <div  *ngIf="questionInfo.QuestionType == 'MR'">
                      <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                        <div class="row m-0">
                          <div class="col-10 p-0">
                            <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                          </div>
                        </div>
                      </h3>
                      <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                        <div class="row form-row">
                          <div class="col-12">
                              <div class="form-group mb-0" >
                                  <div class="appearance-auto rounded-0 p-0 border-0" >
                                      <div class="custom-p-2 custom-mt-1" [ngClass]="qAnswer.IsCorrect?'correct-answer':qAnswer.IsCorrect != qAnswer.IsAnswer?'wrong-answer':''">
                                          <mat-checkbox class=""  [checked]="qAnswer.IsAnswer" [value]="qAnswer.IsAnswer" [disabled]="true">
                                            <span *ngIf="questionInfo.OptionType == 'Text'">{{qAnswer.Answer}} </span>
                                            <mat-icon *ngIf="qAnswer.IsAnswer">{{(qAnswer.IsAnswer == qAnswer.IsCorrect)?'done':'close'}}</mat-icon>
                                            <span *ngIf="questionInfo.OptionType == 'Image'"><img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px ml-3"></span>
                                          </mat-checkbox>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <!-- <div class="col-12">
                            <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                          </div> -->
                        </div>
                      </section>

                    </div>
                    <div *ngIf="questionInfo.QuestionType == 'FIB'">
                      <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                        <div class="row m-0">
                          <div class="col-10 p-0">
                            <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                          </div>
                        </div>
                      </h3>
                      <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                        <div class="row form-row">
                            <div class="col-12">
                              <div class="form-group mb-0" >
                                  <label class="d-block"><span class="text-primary font-weight-500">Student Ans.</span> {{qAnswer.StrAnswer}}</label>
                                  <label class="d-block"><span class="text-success font-weight-500">Correct Ans.</span> {{qAnswer.Answer}}</label>
                              </div>
                          </div>
                          <div class="col-12">
                            <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                          </div>
                        </div>
                      </section>

                    </div>
                    <div  *ngIf="questionInfo.QuestionType == 'MF'">
                      <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                        <div class="row m-0">
                          <div class="col-10 p-0">
                            <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                          </div>
                        </div>
                      </h3>

                      <section>

                        <div class="row form-row mb-2">
                          <div class="col-12 col-md-6">
                            <div class="row m-0 mb-3 align-items-center" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                              <div class="col-6">
                                <div class="form-group mb-0" >
                                    <div class="appearance-auto mb-3">
                                        <div class="custom-p-2">
                                            <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="col-6" *ngIf="questionInfo.OptionType == 'Text'">
                                <div class="form-group mb-0" >
                                    <div class="appearance-auto mb-3">
                                        <div class="custom-p-2">
                                            <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer1}}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="col-6" *ngIf="questionInfo.OptionType == 'Image'">
                                <div class="form-group mb-0" >
                                  <div class="appearance-auto mb-3">
                                    <div class="custom-p-2">
                                        {{qAnswer.GroupNo1}}
                                        <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                    </div>
                                  </div>
                                </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="row m-0 mb-3" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="col-12">
                              <div class="d-flex" >
                                <div class="form-group mb-0 mr-1" >
                                  <div class="appearance-auto rounded-0 p-0 " style="height: 54px;">
                                      <div class="bg-gray text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                          <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                                      </div>
                                  </div>
                                </div>
                                <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                  <div class="form-control form-control-sm  appearance-auto rounded-0 p-0" style="height: 54px;" [ngClass]="qAnswer1.GroupNo1 == qAnswer.CorrectLabel?'correct-answer border-success':qAnswer1.GroupNo1 == qAnswer.StrAnswer?'wrong-answer border-danger':''">
                                      <div class="text-center custom-p-1" [ngClass]="qAnswer1.GroupNo1 == qAnswer.CorrectLabel?'bg-success':qAnswer1.GroupNo1 == qAnswer.StrAnswer?'bg-danger':'bg-gray'">
                                          <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                                      </div>
                                      <div class="text-center custom-p-2 custom-mt-1">
                                          <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="{{i}}">
                                              <mat-radio-button [checked]="qAnswer1.GroupNo1 == qAnswer.StrAnswer" [disabled]="true" value="{{qAnswer.StrAnswer}}"></mat-radio-button>
                                            </mat-radio-group>

                                      </div>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        </div>
                      </section>

                      </div>
                      <div  *ngIf="questionInfo.QuestionType == 'MM'">
                        <h3 [ngClass]="Aindex!=0?'mb-0 mt-3':'m-0'">
                          <div class="row m-0">
                            <div class="col-10 p-0">
                              <span>Q.{{Aindex+1}}  </span> <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
                            </div>
                            <div class="col-2 p-0">
                              <div class="d-flex justify-content-end text-primary"><span>Marks:  {{questionInfo.Points}}</span></div>
                              <div class="d-flex justify-content-end  text-success"><span>Scored Marks:  {{questionInfo.StudentScore}}</span></div>

                            </div>
                          </div>
                        </h3>
                        <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index" >
                          <div class="row form-row align-items-center">

                              <div class="col-3">
                                <div class="form-group mb-0" >
                                    <div class="appearance-auto mb-3">
                                        <div class="custom-p-2">
                                            <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3" *ngIf="questionInfo.OptionType == 'Text'">
                              <div class="form-group mb-0" >
                                  <div class="appearance-auto mb-3">
                                      <div class="custom-p-2">
                                          <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer}}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="col-3" *ngIf="questionInfo.OptionType == 'Image'">
                            <div class="form-group mb-0">
                              <div class="appearance-auto mb-3">
                                  <div class="custom-p-2">
                                    {{qAnswer.GroupNo1}}. <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                                  </div>
                              </div>
                            </div>

                          </div>
                          <div class="col-6">
                            <div class="d-flex" >
                              <div class="form-group mb-0 mr-1" >
                                <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                    <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                        <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                                    </div>
                                </div>
                            </div>
                              <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                  <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                                      <div class="bg-theam text-center custom-p-1">
                                          <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                                      </div>
                                      <div class="text-center custom-p-2 custom-mt-1">
                                            <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="{{i}}" #IsCorrect="ngModel"  [(ngModel)]="qAnswer.StrAnswer">
                                              <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                                            </mat-radio-group>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                          </div>
                        </section>
                        </div>
                </div>
            </div>
            <div *ngIf="stdAnswerMasterInfo.ResponseFilePath !='' && !fileInfoList">
              <!-- {{urlSafe}} -->
              <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileType === 'pdf'">
                <iframe [src]="urlSafe" width="100%" style="height: calc(100vh - 180px); max-height: 100%;" type='application/pdf' frameborder="0" allowfullscreen></iframe>

              </div>
              <div class="col-12 min-height-150 p-0 mt-3 border" *ngIf="fileType === 'doc' || fileType === 'docx'">
                <ngx-doc-viewer
                  [url]="docUrl"
                  viewer="google"
                  style="width:100%;height:50vh;"
                ></ngx-doc-viewer>
                <p class="pt-3 pl-2"><a [href]="docUrl" download>Click here </a>  for download this file</p>
              </div>
              <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png'">
                <img [src]="urlSafe" alt="" class="img-fluid">
              </div>
              <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileType === 'webm' || fileType === 'mp4' || fileType === 'mov' || fileType === 'wmv' || fileType === 'avi'">
                <video layout-fill  class="max-w-500px m-auto"  controls controlsList="nodownload">
                  <source [src]="urlSafe" type="video/webm"/>
                </video>
              </div>
              <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileType === 'mp3' || fileType === 'aac' || fileType === 'waf' ">
                <div class="h-100 mx-w-300px position-relative">
                  <img src="../../../assets/media/audios/audio_player1.png" onClick='document.getElementById("audio1").play(); return false;' class="" width="100%"/>
                  <audio controls class="w-100 h-100" height="200px"id="audio1"
                    style="height: 200px !important;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width:calc(100% - 30px) !important;max-width:300px !important;"
                  >
                  <source [src]="urlSafe" type = "audio/mp3" />
                  </audio>
                </div>
              </div>
            </div>
            <div *ngIf="fileInfoList">
              <div *ngFor="let fileList of fileInfoList; let i = index">
                <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileList.fileType === 'pdf' ">
                  <iframe [src]="fileList.urlSafe" width="100%" style="height: calc(100vh - 180px); max-height: 100%;" type='application/pdf' frameborder="0" allowfullscreen></iframe>
                </div>
                <div class="col-12 min-height-150 p-0 mt-3 border" *ngIf="fileList.fileType === 'doc' || fileList.fileType === 'docx'">
                  <ngx-doc-viewer
                    [url]="fileList.docUrl"
                    viewer="google"
                    style="width:100%;height:50vh;"
                  ></ngx-doc-viewer>
                  <p class="pt-3 pl-2"><a [href]="docUrl" download> Click here </a>  for download this file.</p>
                </div>
                <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileList.fileType === 'jpg' || fileList.fileType === 'jpeg' || fileList.fileType === 'png'">
                  <img [src]="fileList.urlSafe" alt="" class="img-fluid">
                </div>
                <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileList.fileType === 'webm' || fileList.fileType === 'mp4' || fileList.fileType === 'mov' || fileList.fileType === 'wmv' || fileList.fileType === 'avi'">
                  <video layout-fill  class="max-w-500px m-auto"  controls controlsList="nodownload">
                    <source [src]="fileList.urlSafe" type="video/webm"/>
                  </video>
                </div>
                <div class="col-12 min-height-150 p-0 mt-3" *ngIf="fileList.fileType === 'mp3' || fileList.fileType === 'aac' || fileList.fileType === 'waf' ">
                  <div class="h-100 mx-w-300px position-relative">
                    <img src="../../../assets/media/audios/audio_player1.png" onClick='document.getElementById("audio1").play(); return false;' class="" width="100%" />
                    <audio controls class="w-100 h-100" height="200px"id="audio1"
                      style="height: 200px !important;
                      position: absolute;
                      bottom: 0;
                      left: 50%;
                      transform: translateX(-50%);
                      width:calc(100% - 30px) !important;max-width:300px !important;"
                    >
                    <source [src]="fileList.urlSafe" type = "audio/mp3" />
                    </audio>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3" *ngIf="reqBy === 'Teacher'">
              <h3>Teacher review section</h3>
              <div class="dropdown-divider"></div>
              <div class="custom-p-2" >
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group"
                  [(ngModel)]="stdAnswerMasterInfo.ResultId">
                  <mat-radio-button class="example-radio-button mr-4" *ngFor="let resultList of resultTypeList" [value]="resultList.Id">
                    {{resultList.Name}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div  class="row form-row m-0">
                <div class="col-12">
                    <div class="form-group mb-0" >
                        <label><span>*</span>{{'Comments' | translate}}</label>
                        <textarea class="resizebele-false form-control" name="Comments"  #Comments="ngModel" [(ngModel)]="stdAnswerMasterInfo.Comments"></textarea>
                       <!-- <angular-editor class="resizebele-false" name="Comments"  #Comments="ngModel" [(ngModel)]="stdAnswerMasterInfo.Comments" [config]="config"></angular-editor> -->
                    </div>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer p-1">
          <button type="button" class="btn btn-success" *ngIf="role != 'Student' && role != 'Parent'" (click)="saveResult()">Submit</button>
          <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
          </div>
    </div>
</div>
