import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import { Contact,DemoRequestViewData, MasterListData, MLData, Partner, UserRegisterData } from './contact.model';
import { BehaviorSubject, Subject } from 'rxjs';
import CONFIG from './Const';
import { MessageData, MessageMasterData } from './chat.model';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {QRTypes} from './QRCode.model';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isUserLoggedIn:boolean = false;
  // public schoolLogoUrl:string = '';
  public schoolLogoUrl = new BehaviorSubject<string>('');
  public userInfo = new BehaviorSubject<any>([]);
  public isLoading:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isChatting :BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public MessageList:MessageData[] = [];
  private _sendMsgSource = new Subject<MessageData[]>();
  private _loadMsgSource = new Subject<MessageMasterData>();
  private _openChatSource = new Subject<boolean>();
  sendMsg$ = this._sendMsgSource.asObservable();
  loadMsg$ = this._loadMsgSource.asObservable();
  openChat$ = this._openChatSource.asObservable();

  SendMessageList(MessageList: MessageData[]) {
    this._sendMsgSource.next(MessageList);
  }
  loadMessage(MasterData : MessageMasterData) {
    this._loadMsgSource.next(MasterData);
  }

  OpenChat(){
    //this._openChatSource.next(false);
    this._openChatSource.next(true);
  }
  //public isLogoLoaded:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http:HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.CheckLoggedInStatus();
    
   }
   private isBrowser = false;
  public messageMasterInfo:any;
  // Observable string sources
  private loggedInSource = new Subject();
  private gridRefreshSource = new Subject();
  private achievementsRefreshSource = new Subject();
  private calenderRefreshSource =new Subject()
  private itemSource = new Subject();
  private confirmSource = new Subject();
  //private missionConfirmedSource = new Subject<string>();
public role:string;
  // Observable string streams
  IsLoggedIn$ = this.loggedInSource.asObservable();
  RefreshGrid$ = this.gridRefreshSource.asObservable();
  RefreshAchievements$ = this.achievementsRefreshSource.asObservable();
  RefreshCalender$ = this.calenderRefreshSource.asObservable();
  RefreshItemList$ = this.itemSource.asObservable();
  OnConfirm$ = this.confirmSource.asObservable();
  //missionConfirmed$ = this.missionConfirmedSource.asObservable();
  UserId:number;
  formData:Contact = new Contact();
  userFormData:UserRegisterData = new UserRegisterData();
  partenrFormData:Partner = new Partner();
  DemoRequestFormData:DemoRequestViewData = new DemoRequestViewData();
  QRTypesdata: QRTypes = new QRTypes();
  // readonly baseUrl = 'http://localhost/SAPI/api/Home';
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/Home';
  readonly baseUrl = CONFIG.BASE_URL+'api/Home';
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/Home';
  //readonly baseUrl = 'https://api.hybridlearning.in/api/User';
  selectedChileId:any;
  getAuthToken(key){
    if(this.isBrowser && typeof window != 'undefined'){
    var res = localStorage.getItem(key);
    return res;
    }
  }
  // Service message commands
  CallOnLoggedIn() {
    this.loggedInSource.next(true);
  }
  CallOnRefreshGrid() {
    this.gridRefreshSource.next(true);
  }
  CallOnRefreshCalender() {
    this.calenderRefreshSource.next(true);
  }
  CallOnRefreshItem() {
    this.itemSource.next(true);
     }
  CallOnRefreshAchievements(name:string){
    this.achievementsRefreshSource.next({IsRefresh:true,Name:name});
 
  }
  CallOnConfirm() {
    this.confirmSource.next(true);
  }
  CheckLoggedInStatus() {
    var isToken = typeof window != 'undefined' ? localStorage.getItem('schoolAuth'):'';
    if(isToken)
    {
      this.CallOnLoggedIn();
    }
  }
  GetToken(){
    return localStorage.getItem('schoolAuth')||'';
  }
  GetRole(){
    return localStorage.getItem('UserRole')||'';
  }
  HaveAccsess(){
    var loggintooken = this.GetToken();
    var  role = this.GetRole();
    if(loggintooken !== '' && role === "SuperAdmin"){
      return true;
      }
      else{
        return false;
      }
  }
  clearsessionStorage(){
    return localStorage.clear();
  }
  contactMe(){
    return this.http.post(this.baseUrl+'/sendcontactemail',this.formData);
  }
  registerMe(formData:Contact){
    return this.http.post(this.baseUrl+'/sendsignupinfo',formData);
  }
  getMlListInfo(mlTableName:string){
    var config = {
      params: {
        mlTableName: mlTableName,
      }
    };
    return this.http.get(this.baseUrl+'/getmllistinfo',config);
  }
  addmlInfo(tableName:string,code:string,name:string){
    var masterData = new MasterListData();
    masterData.TableName = tableName;
    masterData.MLInfo = new MLData();
    masterData.MLInfo.Code = code;
    masterData.MLInfo.Name = name;
    masterData.MLInfo.IsActive = true;
    return this.http.post(this.baseUrl+'/addmlinfo',masterData);
  }
  udpatemlinfo(tableName:string,Id:number,name:string){
    var masterData = new MasterListData();
    masterData.TableName = tableName;
    masterData.MLInfo = new MLData();
    masterData.MLInfo.Id = Id;
    masterData.MLInfo.Name = name;
    masterData.MLInfo.IsActive = true;
    return this.http.post(this.baseUrl+'/udpatemlinfo',masterData);
  }
  deletemlinfo(tableName:string,Id:number,name:string){
    var masterData = new MasterListData();
    masterData.TableName = tableName;
    masterData.MLInfo = new MLData();
    masterData.MLInfo.Id = Id;
    masterData.MLInfo.Name = name;
    masterData.MLInfo.IsActive = false;
    return this.http.post(this.baseUrl+'/udpatemlinfo',masterData);
  }
  addmlInfoWithClassAndSubject(tableName:string,code:string,name:string,classId:number,subjectId:number){
    var masterData = new MasterListData();
    masterData.TableName = tableName;
    masterData.MLInfo = new MLData();
    masterData.MLInfo.Code = code;
    masterData.MLInfo.Name = name;
    masterData.MLInfo.IsActive = true;
    masterData.MLInfo.ClassId = classId;
    masterData.MLInfo.SubjectId = subjectId;
    return this.http.post(this.baseUrl+'/addmlinfo',masterData);
  }
  addbatchInfo(tableName:string,code:string,name:string,classId:number){
    var masterData = new MasterListData();
    masterData.TableName = tableName;
    masterData.MLInfo = new MLData();
    masterData.MLInfo.Code = code;
    masterData.MLInfo.Name = name;
    masterData.MLInfo.IsActive = true;
    masterData.MLInfo.ParentGroupId = classId;
    return this.http.post(this.baseUrl+'/addmlinfo',masterData);
  }
  getclasses(){
    return this.http.get(this.baseUrl+'/getclasses');
  } 
  getbatches(classId:number){
    var config = {
      params: {
        classId: classId       
      }
    };
    return this.http.get(this.baseUrl+'/getbatches',config);
  } 
  getsubjects(classId:number){
    var config = {
      params: {
        classId: classId       
      }
    };
    return this.http.get(this.baseUrl+'/getsubjectsbyclassid',config);
  } 
  gettopics(classId:number,subjectId:number){
    var config = {
      params: {
        classId: classId,
        subjectId:subjectId
      }
    };
    return this.http.get(this.baseUrl+'/gettopicssbyclassidsubjectid',config);
  } 
  getlastdocumentid(){
    return this.http.get(this.baseUrl+'/getlastdocumentid');
  }
  gePaymentToken(){
    return this.http.get(CONFIG.BASE_URL+'api/Braintree/getClientToken');
  }
  saveSale(nonce:string, amt:number){
      return this.http.post(CONFIG.BASE_URL+'api/Braintree/saveSale',{ nonceFromTheClient: nonce, amt: amt});
  }
  getnewlyaddeddocumentids(prevdocid:number){
    var config = {
      params: {
        prevdocumentid: prevdocid       
      }
    };
    return this.http.get(this.baseUrl+'/getnewlyaddeddocumentids',config);
  } 
  getunusedfiles(){
    return this.http.get(this.baseUrl+'/getunusedfiles');
  }
  deleteunusedfiles(fileList:any){
    return this.http.post(this.baseUrl+'/deleteunusedfiles',fileList);
  }
  downloadFile(filePath:any){
    var config = {
      params: {
        filePath: filePath       
      },     
    };
    return this.http.get(this.baseUrl+'/filedownload',config);
  }
  zipanddownload(fileList:any){
    return this.http.post(this.baseUrl+'/zipanddownload',fileList,{ responseType: 'blob'});
  }
  getstudentfilesinfo(startDate, endDate){
    var config = {
      params: {
        startDate: startDate,       
        endDate: endDate       
      },
    }
    return this.http.get(this.baseUrl+'/getstudentfilesinfo',config);
  }
  // 12 Hour Time formate
  timeFormate(time: any): any {
    // time = time.substr(11,8);
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour >= 12 ? 'PM' : 'AM';
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    return `${hour==0?12:hour}:${min} ${part}`
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     fileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
  getregisterlist(){
    return this.http.get(this.baseUrl+'/getregisterinfo');
  }

  saveParterinfo(){
    return this.http.post(this.baseUrl+'/sendsignupinfo',this.partenrFormData);
    // return this.http.post(this.baseUrl+'/sendparterinfo',this.partenrFormData);
  }
  getuseregisterinfo(){
    return this.http.get(this.baseUrl+'/getuseregisterinfo');
  }
  getuseracademicyearloginfo(){
    return this.http.get(this.baseUrl+'/getuseracademicyearloginfo');
  }
  saveuseregisterinfo(){
    return this.http.post(this.baseUrl+'/saveuseregisterinfo',this.userFormData);
  }
  strReplace(str:string, replaceWhat:string, replaceTo:string) {
    replaceWhat = replaceWhat.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var re = new RegExp(replaceWhat, 'g');
    return str.replace(re, replaceTo);
}
getregisterinfoWithType(regType :string){
  var config = {
    params: {
      regType: regType   
    },
  }
  return this.http.get(this.baseUrl+'/getregisterinfoWithType',config);
}


getsubjectsbyclassid(classId:number) {
  var config = {
    params: {
      classId: classId   
    },
  }
  return this.http.get(this.baseUrl + '/getsubjectsbyclassid',config);
}
getdemorequestinfo(){
    return this.http.get(this.baseUrl+'/getdemorequestinfo');
  }
  savedemorequestinfo(){
    return this.http.post(this.baseUrl+'/savedemorequestinfo',this.DemoRequestFormData );
  }
  
  // For get the redirect url
  getQRCodeRedirectionUrl(Code:string){
    var config = {
      params: {
        code : Code,
      }
    };
    return this.http.get(this.baseUrl+'/getQRCodeRedirectionUrl',config);
    
   }
   getqrtypelist(){
    return this.http.get(this.baseUrl+'/getqrtypelist');
  }
  // gettimelist(){
  //   return this.http.get(this.baseUrl+'/gettimelist');
  // }
  gettimelist(){
    return this.http.get(CONFIG.BASE_URL+'api/home/gettimelist');
  }

  getdatediffdays(dt: any, dt1 :any){
    let t = new Date(dt1);
    let idt = new Date(dt.substring(3,6) +  dt.substring(0,2) + dt.substring(5));
    let diff:any = idt.getTime() - t.getTime();       
    var daydiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return daydiff;
  }

  formatdate(dt){
    return formatDate(new Date(dt), 'dd-MM-yyyy', 'en');
  }
  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } 
}
