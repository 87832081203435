import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import { School } from './school.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(private http:HttpClient) { }
  readonly baseUrl = CONFIG.BASE_URL+'api/User';
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/User';
  //readonly baseUrl = 'https://api.hybridlearning.in/api/User';
  //readonly baseUrl = 'http://localhost/SAPI/api/User';
  formData:School = new School();

  addSchool(){
    this.formData.IsActive = true;
    return this.http.post(this.baseUrl+'/registerschool',this.formData);
  }
  getSchoolList(){
    return this.http.get(this.baseUrl+'/getschoollist');
  }
  getSchoolBranchList(){
    return this.http.get(this.baseUrl+'/getschoolbranchlist');
  }
  getSchoolInfo(schoolId: any){
    var config = {
      params: {
        schoolId: schoolId       
      }
    };
    return this.http.get(this.baseUrl+'/getschool',config);
  }
  updateSchool(){
    return this.http.post(this.baseUrl+'/updateschool',this.formData);
  }


 

}
