import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NotificationService } from '../shared/notification.service';
import { AnalysisStudentComponent } from './analysis-student.component';
import { CustomGrideCelComponent } from 'src/app/custommenu/custom-gride-cel/custom-gride-cel.component';
import { StuAnalysisResultsComponent } from './stu-analysis-results.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-analytics-students',
    templateUrl: './analysis-students.component.html',
    styleUrls: ['./analysis-students.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class AnalysisStudentsComponent implements OnInit {
  list:any[]=[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  analysisStuColumnDefs;
  analysisStuDefaultColDef;
  analysisStuRowData;
  analysisStuRowSelection;
  analysisStuGridOptions:any;
  
  currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  classList:any[];
  categoryList:any[];
  CUSTOMCONST:any=CUSTOMCONST; 

  
  constructor(public service:AnalysisService,  public modalService:BsModalService,
    public bsModalRef: BsModalRef,public commonServ:CommonService,private notificationServ:NotificationService) {
    
    this.analysisStuColumnDefs = [
      // { 
      //   headerName:"Id",
      //   field: 'Id', cellRendererFramework: CustommenuComponent, 
      //   cellRendererParams : { MenuList : this.commonServ.role == 'Student'?  [{Name: "Complete Analysis", "Click" : this.completeAnalytic , that : this}]: [{Name: "Review Analysis", "Click" : this.reviewAnalytic , that : this}]},
        
      //   filter:false, width:60,maxWidth: 60, 
      //   headerComponent: 'customHeader', 
      //   headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      // }, 
      { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'BatchName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'ClassName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'IsCompleted', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'Mobile', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'Email', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      {
        headerName: "IsComplete",  sortable: true,filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, },
        field: 'Id', cellRendererFramework: CustomGrideCelComponent, width: 120,
        cellRendererParams: {enableButton: false, celName: [{ Name: "Complete", "Click": this.completeAnalytic, that: this }] },
      },

      
  ];
  // this.frameworkComponents = { 
  //   customHeader: CustomHeader 
  // };
  this.analysisStuDefaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
        sortable: true,
        floatingFilter: true,
  };
  this.analysisStuRowSelection = 'single';
  this.commonServ.RefreshGrid$.subscribe(
  
    item => {
      this.getAnalysisList();
      }
  );
   }

  ngOnInit(): void {
  }
  onAnalyticGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getAnalysisList();
    }, 300);
    
  }
  getAnalysisList(){
    this.service.getStudentsForAnalysis(this.list[0].id).subscribe((res : any) => {
     
      this.analysisStuRowData =  res;

    });
  }
  onAnalysisReload(data){
    this.analysisStuRowData = data;
  }
  completeAnalytic(stuId:number, that,IsCompleted){
//    that.openquestionmodal.bind(that);

    if(!IsCompleted){
      that.openEditModal(stuId);
    }else{
      that.openStuAnalysisResultsModal(stuId);
    }
    
  }
  reviewAnalytic(stuId:number, that){
    //    that.openquestionmodal.bind(that);
        that.openEditModal(stuId);
      }
  
  deleteAnalytic(stuId:number, that){
    if(that.commonServ.role == 'Teacher'){
      that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
    }
    else{
    that.service.deleteAnalytic(stuId).subscribe((res:any)=>{
        if(res.IsSaved){
          that.notificationServ.ShowInfo('Successfully deleted!');
          this.getAnalysisList();
        }else{
          that.notificationServ.ShowError(res.ErrorMessage);
        }
      });
    }
  }
 
  openEditModal(stuId:number) {
    const initialState = {
      list: [
        {
          studentId:stuId,
          analysisMasterId:this.list[0].id
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(AnalysisStudentComponent,{id: CUSTOMCONST.LABEL_TWO_MODAL,initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  
  openStuAnalysisResultsModal(stuId:number) {
    const initialState = {
      list: [
        {
          studentId:stuId,
          analysisMasterId:this.list[0].id
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(StuAnalysisResultsComponent,{id: CUSTOMCONST.LABEL_TWO_MODAL,initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  
  onAnalyticSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
  }
  
  
  saveAnalytics(){
   
    this.service.saveAnalysisView().subscribe((res : any) => {

    });
  }
   
}
