import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AnalysisQuestion } from '../shared/analysis.model';
import { AnalysisService } from '../shared/analysis.service';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-analytic-questionair',
    templateUrl: './analysis-questionair.component.html',
    styleUrls: ['./analysis-questionair.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor]
})
export class AnalysisQuestionairComponent implements OnInit {
  list:any[]=[];
  questionObj: AnalysisQuestion;
  categoryList:any[];
  scoreList:any[]= [
    { Id:1,Name:1},
    { Id:2,Name:2},
    { Id:3,Name:3},
    { Id:4,Name:4},
    { Id:5,Name:5},
    { Id:6,Name:6},
    { Id:7,Name:7},
    { Id:8,Name:8},
    { Id:9,Name:9},
    { Id:10,Name:10},
    
  ];

  CUSTOMCONST:any=CUSTOMCONST;
  
  constructor(public analysisServ:AnalysisService, public modalService:BsModalService,
  public bsModalRef: BsModalRef,public commonServ:CommonService) {
    this.analysisServ.questionId = this.modalService.config.initialState.list[0].id;
    this.categoryList = this.analysisServ.formData.CategoryList;
    if( this.analysisServ.questionId <= 0){
    this.questionObj = new AnalysisQuestion();
    this.questionObj.AnalysisQuestionId = 0;
    this.questionObj.AnalysisMasterId = 0;
    this.questionObj.Question = '';
    this.questionObj.CategoryId = 0;
    this.questionObj.Score = 10;
    this.questionObj.IsActive = true;
    }
    else{
      this.questionObj = this.analysisServ.formData.AnalysisQuestionListInfo.find(item => item.AnalysisQuestionId == this.analysisServ.questionId);
    }
    // this.analysisServ.formData.AnalysisQuestionListInfo.find(item => item.AnalysisQuestionId == this.analysisServ.questionId);
   }

  ngOnInit(): void {
    
  }

  saveAnalysisQuestionair(){
    
    
    if(this.questionObj.AnalysisQuestionId <= 0){

    this.questionObj.OrderNo = this.analysisServ.formData.AnalysisQuestionListInfo.length + 1;
    this.analysisServ.formData.AnalysisQuestionListInfo.push(this.questionObj);
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL);
    }
    else{
      //this.questionObj = this.analysisServ.formData.AnalysisQuestionListInfo.find(item => item.AnalysisQuestionId == this.analysisServ.questionId);
      this.analysisServ.formData.AnalysisQuestionListInfo.push(this.questionObj);
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL);
    }
    
  }
}
