  <div class="container-fluid pt-0">
    <!-- <ng-lottie [options]="options"></ng-lottie> -->
    <owl-carousel-o [options]="customOptions">
      
      <ng-container>
        <ng-template carouselSlide>
          <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
          <div class="slider text-content d-sm-flex align-items-center h-100 d-none">
            <h1 class="m-0">One Platform, Multi Choice of Teaching And Learning</h1>
            <p class="mt-2"></p>
            
          </div>
          <div class="slider images-style1 h-100">
            <div class="image1">
              <img src="../../assets/images/2X/Pattern.png" alt="">
            </div>
            
            <div class="image2 d-flex justify-content-end align-items-center h-100">
              <!-- <img src="../../assets/images/2X/banner-transparent.png" alt="" class="w-100"> -->
              <!-- <div class="w-100 w-sm-60" ><img src="../../assets/GIF/Home_Page/Banner_1.gif" alt=""></div> -->
              <ng-lottie 
                [options]="bannerSecond" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="100%"
                height="100%"
                class="d-flex justify-content-end w-100 pr-5"
              >
              </ng-lottie>
            </div>
            <div class="image3 d-none d-sm-block">
              <img src="../../assets/images/2X/Icon.png" alt="">
            </div>
            <div class="image4">
              <img src="../../assets/images/2X/Pattern-3.png" alt="">
            </div>
          </div>
          
        </ng-template>
      </ng-container>
      <ng-container>
        <ng-template carouselSlide>
          <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
          <div class="slider text-content d-sm-flex align-items-center h-100 d-none">
            <h1 class="m-0">Facilitating Educators to Meet Every Student's Needs</h1>
            <p class="mt-2"></p>
            
          </div>
          <div class="slider images-style1  w-100 h-100">
            <div class="image1 d-none d-sm-block">
              <img src="../../assets/images/2X/Pattern.png" alt="">
            </div>
            
            <div class="image2 d-flex justify-content-center justify-content-sm-end w-100 align-items-center h-100">
              <!-- <img src="../../assets/images/2X/banner-transparent.png" alt="" class="w-100"> -->
              <!-- <div class="w-60 w-sm-40" ><img src="../../assets/GIF/Home_Page/Banner_2.gif" alt=""></div> -->
              <ng-lottie 
                [options]="bannerThird" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="100%"
                height="100%"
                class="d-flex justify-content-center justify-content-sm-end w-100 pr-0 pr-sm-5"
              >
              </ng-lottie>
            </div>
            <div class="image3 d-none d-sm-block">
              <img src="../../assets/images/2X/Icon.png" alt="">
            </div>
            <div class="image4">
              <img src="../../assets/images/2X/Pattern-3.png" alt="">
            </div>
          </div>
          
        </ng-template>
      </ng-container>
      <ng-container>
        <ng-template carouselSlide>
          <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
          <div class="slider text-content  d-sm-flex align-items-center h-100 d-none">
            <h1 class="m-0">Be A Part Of Empowering Young Minds In Dynamic Learning</h1>
            <p class="mt-2"></p>
          </div>
          <div class="slider images-style1 h-100">
            <div class="image2 d-flex justify-content-center justify-content-sm-end align-items-center h-100">
              <img class="d-none d-sm-block" src="../../assets/images/home_page/Banner3.png" alt="">
              <img  class="d-block d-sm-none w-80" src="../../assets/images/home_page/Banner_3.png" alt="">
              <!-- <ng-lottie 
                [options]="bannerFirst" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="700px"
                height="600px"
                class="d-flex justify-content-end w-100 pr-5" >
              </ng-lottie> -->
            </div>
            <div class="image1 d-none d-sm-block">
              <img src="../../assets/images/2X/Pattern.png" alt="">
            </div>
            <div class="image3 d-none d-sm-block">
              <img src="../../assets/images/2X/Icon.png" alt="">
            </div>
            <div class="image4">
              <img src="../../assets/images/2X/Pattern-3.png" alt="">
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <section class="bg-theam-body investing-yourself">
    <div class="container-fluid" >
      <div class="bg-half-color position-relative" >
        <div class="row justify-content-center position-relative z-inxex-1">
          <div class="col-12 col-md-8 align-self-center mb-3">
            <div class="header ">
              <h1 class="d-block text-center text-white">What are we teaching today?</h1>
              <h1 class="d-block text-center text-white">Who are they learning from?</h1>
              <h1 class="d-block text-center text-white">Where did they learn?</h1>
              <h1 class="d-block text-center text-white">How can they survive in the Next Generation?</h1>
              <h1 class="d-block text-center text-white">This is the questions everyone has been asking for the past 10 years, what kind of education will replace the classical historical industrial model.</h1>
              
              <h1 class="d-block text-center text-white pt-5 pb-5 title">Hybrid Learning Ecosystem Platform…. Education Beyond School</h1>
              <h1 class="d-block text-center text-white"> There is an increasing number of people asking themselves: “ Where do they learn in their everyday lives? ”</h1>
            </div>
          </div>
          <div class="col-10">
            <div class="row ">
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-562.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">School Based Learning</span>
                     <div class="text-center">
                       <ul class="list-unstyled p-0">
                         <li>A positive school climate is essential to supporting young people's academic success. Students' motivation, behavior, and learning can be impacted by trusting relationships between teachers and students. 
                          </li>
                          
                        <ul class="pl-0 custom-list-style1"> 
                          <li class="mb-2 right-angle1">Build Relationships and Provide support.</li>
                          <li class="mb-2 right-angle2">Incorporate technology thoughtfully.</li>
                          <li class="mb-2 right-angle3">Support additional Home Learning.</li>
                          <li class="mb-2 right-angle4">Continue to engage and momentum.</li>
                        </ul>
                       </ul>
                       
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Path-948.png" alt="">
                </div>
                <a class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Home Based Learning</span>
                     <div class="text-center">
                      <ul class="list-unstyled p-0">
                        <li>The home is a childs first school. The character of the home environment—including the quality of relationships the foundations of healthy development, learning, and ongoing levels of wellbeing and happiness. 
                         </li>
                        
                        <ul class="pl-0 custom-list-style1"> 
                          <li class="mb-2 right-angle1">SEL (Social Emotional Learning).</li>
                          <li class="mb-2 right-angle2">Outside the Box Learning.</li>
                          <li class="mb-2 right-angle3">Life Skills Learning.</li>
                          <li class="mb-2 right-angle4">Personalised Learning.</li>
                        </ul>
                        </ul>
                       
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-561.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Community Based Learning</span>
                     <div class="text-center">
                       <ul class="list-unstyled p-0">
                          <li>Community based learning refers to a wide variety of instructional methods, programs that educators use to connect what is being taught in schools to their surrounding. In today’s fast-moving labor market, student should learn. 
                           </li>
                          <ul class="pl-0 custom-list-style1"> 
                            <li class="mb-2 right-angle1">Project Based Learning.</li>
                            <li class="mb-2 right-angle2">Experiential Learning.</li>
                            <li class="mb-2 right-angle3">Collaborative Learning.</li>
                            <li class="mb-2 right-angle4">STEM Learning.</li>
                          </ul>
                        </ul>
                         
                     </div>
                    </mat-card-content>
           
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-560.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Society Based Learning</span>
                     <div class="text-center">
                      <ul class="list-unstyled p-0">
                        <li>Society plays a significant role in education. The values, morals, and principles of a society will create an education system that upholds the same values, morals, and principles. Education is a social concern.
                          </li>
                        <ul class="pl-0 custom-list-style1"> 
                          <li class="mb-2 right-angle1">Spiritual Wellness.</li>
                          <li class="mb-2 right-angle2">Observational Learning.</li>
                          <li class="mb-2 right-angle3">Reinforced Learning.</li>
                          <li class="mb-2 right-angle4">Behavioral Social Skills.</li>
                        </ul>
                      </ul>
                     
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  <section>
    <div class="container-fluid bg-theam-body">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">A Dynamic Learning Environment That Exceeds Classroom Walls</h1>
              </div>
    
            <p>Hybrid Learning Platform facilitates the new age educational processes by bringing the education, technology, communication, and society together on a single platform. 
              This sharing environment: ‘Teacher-Student, Teacher-Teacher, Student-Student’ enables the effective application of Social Learning, Peer Interaction, and Inquiry-based Learning strategies.</p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">Educational Sharing and collaboration</li>
              <li class="mb-2 right-angle2">Global, Creative and Nature–led learning</li>
              <li class="mb-2 right-angle3">Global Research and Education Network</li>
              <li class="mb-2 right-angle4">Planning and management system</li>
              <li class="mb-2 right-angle5">Multi directional-real time review and feedback system</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3  order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/home_page/Dynamic_Learning_Environment.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
          </div>

          <div class="row mt-5 align-items-center">
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/home_page/Measurement_Evaluation.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">MEASUREMENT, EVALUATION and INCOMPLETE</h1>
              </div>
    
              <p>HLP evaluates student performance in a holistic manner so that you can make the most accurate decisions about your students and guide them correctly, it determines how deep the student learns a subject by analysing all variables.</p>
              <p>Historically, some of the most common uses of learning analytics is prediction of student learning success, and the identification of students who are at risk of failing a course or dropping out of their studies. While it is reasonable that these two problems attracted a lot of attention, learning analytics are far more powerful.</p>
                
              <ul class="pl-0 custom-list-style1"> 
                <li class="mb-2 right-angle1">Detailed Performance Analysis.</li>
                <li class="mb-2 right-angle2">Smart Measurement Evaluation.</li>
                <li class="mb-2 right-angle3">Alerts the students on incomplete tasks.</li>
                <li class="mb-2 right-angle4">Develop student awareness by supporting self-learning</li>
                <li class="mb-2 right-angle5">Supporting student development of lifelong learning skills and strategies</li>
                <li class="mb-2 right-angle1">Provision of personalised and timely feedback to students regarding their learning</li>
                
              </ul>
            </div>
            
          </div>

          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Portfolio Management</h1>
              </div>
              <p><span class="font-weight-500">Guide your students to master their process of learning and personal growth through Portfolio</span>  </p>    
            <p>Let your students gain ownership of their own learning. Hybrid Learning Platform helps students to visualise their whole learning process by compiling all learning activities, including goals, tasks and reflections, into one software.

              Specially created for each student and constantly updated, the portfolio saves every step in the education.</p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">Portfolios can capture the whole students</li>
              <li class="mb-2 right-angle2">Motivation for learning may increase</li>
              <li class="mb-2 right-angle3">SCHOOL LEARNING ENVIRONMENT Growth Portfolio </li>
              <li class="mb-2 right-angle4">HOME LEARNING ENVIRONMENT Growth Portfolio</li>
              <li class="mb-2 right-angle5">COMMUNITY LEARNING ENVIRONMENT Growth Portfolio </li>
              <li class="mb-2 right-angle1">SOCIETY LEARNING ENVIRONMENT Growth Portfolio</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <!-- <img mat-card-image src="../../assets/GIF/Home_Page/Student_Portfolio.gif" alt="value_education" class="m-0 w-100"> -->
                  <ng-lottie 
                [options]="Student_Portfolio" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="100%"
                height="100%"
                class="d-flex justify-content-end w-100 pr-5"
              ></ng-lottie>
              </div>
            </div>
          </div>

          <div class="row mt-sm-5 align-items-center">
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <!-- <img mat-card-image src="../../assets/GIF/Home_Page/Assessment_Content_Generation.gif" alt="value_education" class="m-0 w-100"> -->
                  <ng-lottie 
                [options]="Assessment_Content_Generation" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="100%"
                height="100%"
                class="d-flex justify-content-end w-100 pr-5"
              ></ng-lottie>
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Dynamic ASSIGNMENTS and ASSESSMENTS</h1>
              </div>
    
              <p><span class="font-weight-500">Watch, Listen, Read, Write, and Do Assignments</span>  </p>
              <ul class="pl-0 custom-list-style1">
              <li class="mb-2 right-angle1">Easily assign assignments and set custom due dates to accommodate the needs of individual learners.</li>
              <li class="mb-2 right-angle2">Allow students multiple attempts when responding to assignments / questions.</li>
              <li class="mb-2 right-angle3">Get them immediate rewards by making the correct answer visible so they can learn as they go.</li>
              <li class="mb-2 right-angle4">Send customised and individual feedback to guide the students.</li>
              <p><span class="font-weight-500">Get a real-time view of student's performance through Assessments</span> </p>
                
                        
                <li class="mb-2 right-angle1">Easy to Create summative and formative assessments</li>
                <li class="mb-2 right-angle2">View all assignment score in the application automatically</li>
                <li class="mb-2 right-angle3">Interactive and Personalized</li>
                <li class="mb-2 right-angle4">Awesome Analytics</li>
              </ul>
            </div>
            
          </div>

          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">PLANNING & MANAGEMENT SYSTEM</h1>
              </div>
    
            <p>Hybrid Learning Platform, with its Planning and Management feature, offers school administrators a foresight of the future of the institution through a comprehensive evaluation of all systems and thus enables them to create a strategical plan for an all inclusive growth within and beyond the school. It orients the school to a common goal and increases the quality of administration.</p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">HLP is more than a product, we're your partner.</li>
              <li class="mb-2 right-angle1">Insightful planning</li>
              <li class="mb-2 right-angle2">Single Point Access</li>
              <li class="mb-2 right-angle3">Time Saving</li>
              <li class="mb-2 right-angle4">Data Integrity</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <!-- <img mat-card-image src="../../assets/GIF/Home_Page/Planning_Management.gif" alt="value_education" class="m-0 w-100"> -->
                  <ng-lottie 
                [options]="Planning_Management" 
                (animationCreated)="bannerAnimationCreated($event)"
                width="100%"
                height="100%"
                class="d-flex justify-content-end w-100 pr-5"
              >
              </ng-lottie>
              </div>
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </section>
  <section class="bg-theam-body">

    <div class="container-fluid  pb-3">
      
    <div class="ourpartner-section">
      <div class="row m-0">
        
        <div class="col-12 ">
        <!-- <span class="d-block h5 theam-color-3 pt-3 pb-5 text-center">Our Partner</span> -->

        <owl-carousel-o class="multy-item-carousel" [options]="PartnerOptions">
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/partner1.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/partner2.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/partner3.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/partner4.png" alt="">
              
            </ng-template>
          </ng-container>
          
        </owl-carousel-o>
        </div>
        
      </div>
    </div>

    </div>
  </section>
  <section>
    <div class="container-fluid award-sectio bg-theam-body">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row mt-5 align-items-center">
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h1 class="d-block title pt-1 pb-1">Awards & Recognitions</h1>
              </div>
              <p>Transitioning the entire school system to Hybrid Learning poses its unique challenges. Adapting school infrastructure, equipment, materials, and personnel to function in both remote and in-person environments is not easy. With the right equipment such as software, the process can be made simpler.</p>
              <div class="award-icon-group">
                <img src="../../assets/images/2X/Group-133.png" alt="">
              </div>
              
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                <div class="award-group border">
                  <img mat-card-image src="../../assets/images/award/awards&recognition_2.png" alt="awards&recognition_2" class="m-0 w-100">
                </div>
                <div class="award-group border">
                  <img mat-card-image src="../../assets/images/award/awards&recognition_1.png" alt="awards&recognition_1" class="m-0 w-100">
                </div>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
        
    </div>
  </section>
  
  <section>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-7">
                <div class="row mt-5 align-items-center">
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <img mat-card-image src="../../assets/images/2X/Group-134.png"
                                alt="value_education" class="w-60px">
                        </div>
                    </div>
                    
                    <div class="col-12 mb-3">
                        <div class="section-header">
                          <h1 class="theam-color-8 text-center m-0">In A New Chapter</h1>
                          <h1 class="d-block title pt-1 pb-1 text-center">A New Era of Education Begins With Hybrid Learning Platform</h1>
                        </div>
                        <!-- <p class="text-center font-weight-500 m-0">
                            Effective Communication for Time Management
                        </p> -->
                        <p class="text-center">
                          Hybrid Learning Platform is designed to transform your school environment to adapt to this new era. Get the best of educational sharing and collaboration through this platform.
                        </p>
                        <div class="icon-group2 d-flex justify-content-end">
                          <img class="w-60px" src="../../assets/images/2X/Group-135.png" alt="">
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row mt-5 align-items-center">
              <div class="col-12 mb-3">
                <div class="mt-2 mb-2 ml-auto mr-auto">
                  <ng-lottie 
                    [options]="highlights" 
                    (animationCreated)="bannerAnimationCreated($event)"
                    
                    class="d-flex w-100 " >
                  </ng-lottie>
                  <!-- <img mat-card-image src="../../assets/GIF/Home_Page/Highlights.gif" alt="Highlights" class="m-0 w-100"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</section>
<section>
  <div id="map" style="height: 500px;"></div>
  <!-- <ul class="list-group rounded-0">
    <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('all')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP,  All Location </li>
    <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('canada')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP, Canada www.hybridlearning.net</li>
    <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('india')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;">  HLP, India www.hybridlearning.in</li>
     <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('usa')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP, USA www.hybridlearning.us</li>
    <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('danmark')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;">  HLP, Danmark www.hybridlearning.dk</li>
  </ul> -->
  <!-- <button (click)="loadDefaultMap('canada')"><a href="www.hybridlearning.net" target="_blank" rel="noopener noreferrer">Canada</a>  </button>
  <button (click)="loadDefaultMap('india')"><a href="www.hybridlearning.in" target="_blank" rel="noopener noreferrer">India</a> </button>
  <button (click)="loadDefaultMap('danmark')"><a href="www.hybridlearning.dk" target="_blank" rel="noopener noreferrer">Danmark</a> </button>
  <button (click)="loadDefaultMap('usa')"><a href="www.hybridlearning.us" target="_blank" rel="noopener noreferrer">USA</a> </button> -->
  <a class="map-loaction" (click)="loadDefaultMap('all')" target="_blank">All</a>
    <a class="map-loaction" (click)="loadDefaultMap('canada')" target="_blank">Canada</a>
    <a class="map-loaction" (click)="loadDefaultMap('india')" target="_blank">India</a>
    <a class="map-loaction" (click)="loadDefaultMap('danmark')" target="_blank">Danmark</a>
    <a class="map-loaction" (click)="loadDefaultMap('usa')" target="_blank">USA</a>
</section>
 