import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import $ from "jquery";
import { EventService } from "src/app/shared/event.service";
import { NotificationService } from "src/app/shared/notification.service";
import { FormsModule } from "@angular/forms";
import { WebrtcChatComponentComponent } from "../webrtc-chat-component/webrtc-chat-component.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BreakOutRoomsComponent } from "../break-out-rooms/break-out-rooms.component";
declare const manyToMany: any;
declare const cameraOff: any;
declare const sendMessage: any;

@Component({
  selector: "app-webrtc-group-call",
  standalone: true,
  imports: [CommonModule, FormsModule, WebrtcChatComponentComponent],
  templateUrl: "./webrtc-group-call.component.html",
  styleUrl: "./webrtc-group-call.component.scss",
})
export class WebrtcGroupCallComponent {
  showchatboxbutton: boolean = true;
  hidechatboxbutton: boolean = false;
  grouproomId: string | null;
  showList: boolean = false;
  studentList: any[] = [];
  public bsModalRef: BsModalRef;
  constructor(
    public eventService: EventService,
    public notificationService: NotificationService,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    localStorage.setItem("DuringConsultation", "true");

    $(document).ready(function () {
      $("#btnMute").show();
      $("#btnUnMute").hide();
      $("#off-cam").show();
      $("#start-cam").hide();
    });

    // calling function for getting consultation status
    this.grouproomId = localStorage.getItem("grouproomId");
    if (this.grouproomId) manyToMany(this.grouproomId, "openOrJoin", false);
  }
  socketIo() {}
  endCall() {
    // window.close();
    window.location.href = "/call-feedback";
  }
  // This function calls to send message

  // This function calls when doctor click on chat button
  showchatbox() {
    this.showchatboxbutton = false;
    this.hidechatboxbutton = true;
    $(document).ready(function () {
      $("#showconsultationsidebar").addClass("chatBoxOpen");
    });
  }
  // This function calls for hide chat box

  hidechatbox() {
    this.showchatboxbutton = true;
    this.hidechatboxbutton = false;

    $(document).ready(function () {
      $("#showconsultationsidebar").removeClass("chatBoxOpen");
    });
  }
  handRaise() {
    const msg = JSON.stringify({
      type: "handraise",
    });
    sendMessage(msg);
  }
  openBreakOut() {
    const msg = JSON.stringify({
      type: "handraise",
    });
    const initialState = {};
    this.bsModalRef = this.modalService.show(BreakOutRoomsComponent, {
      initialState,
      class: "modal-lg mb-0",
      ignoreBackdropClick: true,
    });
  }
}
