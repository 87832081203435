// import { Tracing } from "trace_events";

export class TimetableListInfo {

}
export class ClassTimeTableInfo {
    ClassTimeTableId: number;
    ClassId: number;
    SubjectId: number;
    TeacherId: number;
    StartDate: string;
    StartTime: string;
    EndDate: string;
    EndTime: string;
    Comments: string;
    IsMonday: boolean;
    IsTuesday: boolean;
    IsWednesday: boolean;
    IsThursday: boolean;
    IsFriday: boolean;
    IsSaturday: boolean;
    IsSunday: boolean;
    IsActive: boolean;
}

export class TimetableViewData {
    ClassTimeTableInfo: ClassTimeTableInfo;
    IsSaved: boolean;
    Message: string;
}
