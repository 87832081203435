import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateService } from 'src/app/services/date.service';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TimetableService } from 'src/app/shared/timetable.service';
import { UserService } from 'src/app/shared/user.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-edit-time-table',
    templateUrl: './edit-time-table.component.html',
    styleUrls: ['./edit-time-table.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, MatCheckboxModule]
})
export class EditTimeTableComponent implements OnInit {
  list:any[]=[];
  classTTId:number=0;
  classId:number=1;
  classList:any;
  subjectList:any;
  selectedClassId:number;
  datepickerConfig : BsDatepickerConfig;
  minDate = new Date();
  timeSlotList:any=[];
  public eventData1:any = {};
  preheaderhour: number = 0;
  preheaderTime: number = 0;
  appDate: NgbDate;
  constructor(public commonServ:CommonService,public bsModalRef: BsModalRef,
    public TTService:TimetableService,private notificationServ: NotificationService,
    public dateService: DateService,public calendar: NgbCalendar,
    public userService:UserService) { 
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.eventData1 = this.getjson();
  }

  ngOnInit(): void {
    this.classTTId = this.list[0].id;
    this.appDate = this.calendar.getToday();
    this.getclasstimetableinfo(this.classId,this.classTTId);
    this.getclasses();
    this.gettimeslotlist();
    this.getMyInfo();
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      
    });
  }
  counterMinMax(min: number,max:number) {
    return new Array(max-min);
  }
  counter(i: number) {
    return new Array(i);
  }
  gethour(hour: number, index: number) {
    return (hour + index) < 10 ? '0' + (hour + index) : hour + index;
  }
  getheaderTime(hour: any, index: number, min: number) {
    hour = this.gethour(hour, index);
    if (this.preheaderhour == hour) {
      this.preheaderTime = this.preheaderTime + (60 / this.eventData1.options.timeslotsPerHour);
    }
    else {
      this.preheaderTime = 0;
    }
    this.preheaderhour = hour;
    if (this.preheaderTime < 10) {
      return "0" + this.preheaderTime;
    }
    else {
      return this.preheaderTime;
    }
  }
  chkIsGreater(){
    let date = this.dateService.getStringDate(new Date(this.appDate.year, this.appDate.month - 1, this.appDate.day))
    let sdatetime = this.dateService.getDateTimeFromString(date, this.TTService.formData.ClassTimeTableInfo.StartTime)
    let edatetime = this.dateService.getDateTimeFromString(date, this.TTService.formData.ClassTimeTableInfo.EndTime);
    if(sdatetime > edatetime){
      this.notificationServ.ShowError("End Time should be grater than Start time");
      this.TTService.formData.ClassTimeTableInfo.EndTime = "";
    }
  }
  getjson(){
    return {
    "options": {
      "timeslotsPerHour": 4,
      "timeslotHeight": 20,
      "defaultFreeBusy": {
        "Key": "free",
        "Value": true
      },
      "businessHours": {
        "start": 6,
        "end": 18,
        "limitDisplay": true
      }
    }
  }
  }
gettimeslotlist(){
  let index=0;
  for (let hour of this.counterMinMax(this.eventData1.options.businessHours.start,this.eventData1.options.businessHours.end)){
   let j = 0;
   
    for (let min of  this.counter(this.eventData1.options.timeslotsPerHour)){
      let obj= {value:'',date:''};
      let intervel = this.gethour(this.eventData1.options.businessHours.start, index)+':'+this.getheaderTime(this.eventData1.options.businessHours.start,index,j);
      obj.value= intervel;
      obj.date=intervel;
      this.timeSlotList.push(obj);
      
      
      
      j++;
    }
    let obj= {value:'',date:''};
    if(index == ((this.eventData1.options.businessHours.end-this.eventData1.options.businessHours.start)-1)){
      obj.value= this.eventData1.options.businessHours.end+':'+'00';
      obj.date= this.eventData1.options.businessHours.end+':'+'00';
      this.timeSlotList.push(obj);
    }
    index++;
  }
  
}
getclasses(){
  this.commonServ.getclasses().subscribe((res:any)=>{
    this.classList= res;
    console.log(res);
  });
}
changeClass(){
  this.selectedClassId = this.TTService.formData.ClassTimeTableInfo.ClassId;
  this.getSubject();
}
getSubject(){
  this.commonServ.getsubjectsbyclassid(this.selectedClassId).subscribe((res:any)=>{
    console.log('subject');
    console.log(res);
    this.subjectList=res;
    
  })
}
  getclasstimetableinfo(classId:number,classTTId:number){
    this.TTService.getclasstimetableinfo(classId,classTTId).subscribe((res:any)=>{
      console.log('getclasstimetableinfo');
      console.log(res);
      this.TTService.formData = res;
    });
  }
  // saveclasstimetableinfo(){
  //   this.TTService.saveclasstimetableinfo().subscribe((res:any)=>{
  //     console.log(res);
  //   });
  // }
  saveTimeTableInfo(){
    this.TTService.formData.ClassTimeTableInfo.TeacherId= this.userService.formData.UserInfo.UserId;
    this.TTService.saveclasstimetableinfo().subscribe((res:any)=>{
      console.log(res);
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonServ.CallOnRefreshGrid();
        this.bsModalRef.hide();
      }
    });
  }
}
