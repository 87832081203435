import { Component, OnInit } from '@angular/core';
import { faCalendarTimes, faGraduationCap, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { SessionStorageService } from '../shared/session-storage.service';
import { SidebarService } from '../shared/sidebar.service';
import { map } from 'rxjs';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-student-sidebar',
    templateUrl: './student-sidebar.component.html',
    styleUrls: ['./student-sidebar.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLinkActive, RouterLink, NgClass]
})
export class StudentSidebarComponent implements OnInit {
  userIcon = faUserCircle;
  capIcon = faGraduationCap;
  timeIcon=faCalendarTimes;
  menuItems:any=[];
  IsOpend:number;
  IsCollapse:boolean=false;
  subMenuOpen:number;
  collapseCondition:string;
  constructor(private sidebarSer:SidebarService,private route: ActivatedRoute) {
    console.log('this.route.snapshot.params');
    console.log(this.route);
    
   }

  ngOnInit(): void {
    this.sidebarSer._sidebarMenuData
    .pipe(
      
      map((res:any)=>{
        // debugger;
        let d :any;
        d=res;
        d.forEach((element) => {
          let data:any;
            data = element.ChildMenuList;
            data.Condition='';
          element.ChildMenuList.forEach((e, index) => {
            
            
            data.Condition +=  index<element.ChildMenuList.length-1?"TRV"+e.Code+'.isActive' +" || ":"TRV"+e.Code+'.isActive';
            return data;
           })
          // data.Condition +=  index<data.length-1?element.Code +" || ":element.Code ;
         });
         return d;
        
      })
    )
    .subscribe(res=>{
      this.menuItems = res;
      // this.collapseCondition =res.Condition;
      console.log(res);
      
    }
      )
  }

  
  menuChange(item:number){
    if(this.IsOpend === item) {
      this.IsOpend=item;
      this.IsCollapse=this.IsCollapse?false:true;
    }else{
      this.IsOpend=item;
      this.IsCollapse=true;
    }
  }
  subMenuChange(item:number){
this.subMenuOpen=item;
  }
}
