import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
@Component({
    selector: 'app-paymentcancel',
    templateUrl: './paymentcancel.component.html',
    styleUrls: ['./paymentcancel.component.scss'],
    standalone: true
})
export class PaymentcancelComponent implements OnInit {

  constructor(private route: ActivatedRoute,private userService:UserService,private notificationServ:NotificationService,private router:Router) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);  
        const paymentInfo = {
          FeeListInfo: [],
          ErrorMessage: '',
          IsSaved: false,
          PaymentList:[],
          StudentFeeMasterInfo:{},
          PaymentUrl:'',
          PaymentStatus:'',
          PaymentId:params.session_id
        };
        this.userService.processCancelledPayment(paymentInfo).subscribe((res: any) => {
          if(res && res.PaymentStatus === 'Cancelled'){
            this.router.navigateByUrl('/finance');
          }
          else if(res.ErrorMessage){
            this.notificationServ.ShowError(res.ErrorMessage);
          }
        });
      }
    );
  }

}
