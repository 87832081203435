import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { achievements } from '../shared/achievements.service';
import { NotificationService } from '../shared/notification.service';
import { DateService } from '../shared/date.service';
import { TranslateModule } from '@ngx-translate/core';
// import { Ng7LargeFilesUploadLibModule } from 'ng7-large-files-upload-lib';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-myhobby',
    templateUrl: './edit-myhobby.component.html',
    styleUrls: ['./edit-myhobby.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, BsDatepickerModule,
      // Ng7LargeFilesUploadLibModule,
       TranslateModule]
})
export class EditMyhobbyComponent implements OnInit {

  list:any[];
  CUSTOMCONST:any=CUSTOMCONST;
  datepickerConfig : BsDatepickerConfig;
  maxDate = new Date();
  minDate = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];
  format: string;
  fileType: string;
  url: any;
  urlSafe: any;
  fileUploading:boolean;

public bsModalRef:BsModalRef;
  constructor(public service:achievements,private notificationServ:NotificationService, private commonServ:CommonService,
    public modalService:BsModalService,public _dateSer :DateService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  })
    }

  ngOnInit(): void {
    // this.service.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
    console.log(this.list[0].id);
    this.getStudentHobbyInfo();

  }
  enddateSetting(){
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.bsRangeValue = [this.bsValue, this.maxDate];

  }
  saveDocument(){
    debugger;
    if(this.list[0].id >0)
    {
      console.log(this.service.formDataHobby);

    this.service.formDataHobby.IsSave = true;
    }
    this.service.saveStudentHobbyInfo().subscribe((res:any)=>{
      if(res.IsSaved){
        // this.commonServ.CallOnRefreshGrid();

        if(this.list[0].id >0)
        {
          this.notificationServ.ShowInfo('Data Successfully Updated!');
          this.commonServ.CallOnRefreshAchievements("Hobby");
        }
        else{
          this.notificationServ.ShowInfo('Successfully Saved!');
          this.commonServ.CallOnRefreshAchievements("Hobby");
        }
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }

    })

  }
  filesuploaded(fileInfo){
    let fileData:any[]=[];
    if(fileInfo && fileInfo.IsSaved){
      fileData.push(fileInfo.UserDocumentId);
      this.service.formDataHobby.StudentHobbyInfo.DocumentIdList=fileData;
    }


  }
  largefileschange(event){
  }
  selectDocumentFile(event){

    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      }
      if(this.format == 'image')
      {
        this.fileUploading = true;
        reader.onload = (event) => {

        }
      }
      else{
        this.notificationServ.ShowError('Invalid file format! Please select PDF file only' );
      }
    }
  }

  getStudentHobbyInfo(){
    // debugger;
    this.service.getStudentHobbyInfo(this.list[0].id || 0).subscribe((res:any)=>{
      this.service.formDataHobby = res;


    })
  }
  closeModel(){
    this.commonServ.CallOnRefreshGrid();
    this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)
  }
  ngOnDestroy(){
    // this.service.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
  }
}
