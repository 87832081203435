<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">

                <mat-tab-group animationDuration="0ms" class="bg-transparent header-bg-light border-0"
                    (selectedTabChange)="activeTabChange($event)">
                    <mat-tab label="Manual Attandance">
                        <ng-template mat-tab-label>
                            Manual Attandance
                        </ng-template>
                        <div class="mt-2">
                            <div class="tab-content px-0">
                                <div class="row border m-0 py-2 mb-2 justify-content-between">
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                                        <div class="form-group mb-0">
                                            <label>Date</label>
                                            <input type="text" class="form-control form-control-sm m-0" name="Date"
                                            #StartDate="ngModel" [(ngModel)]="selectedDate"
                                            [bsConfig]="datepickerConfig" bsDatepicker timepicker
                                            (ngModelChange)="selectedDateChage()">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                                        <div class="form-group mb-0">
                                            <label>Select Class</label>
                                            <select class="form-control form-control-sm appearance-auto m-0"
                                            name="ClassId" #ClassId="ngModel" [(ngModel)]="selectedClassId"
                                            >
                                            <option [value]="0">Select Class</option>
                                            <option *ngFor="let class of classList" [value]="class.Id">
                                                {{class.Name}}</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                                        <div class="form-group mb-0 d-flex flex-wrap justify-content-end">
                                            <label class="d-block w-100">&nbsp;</label>
                                            <button type="button" class="btn btn-warning btn-sm float-right" (click)="serchMAttendece()">Search</button>
                                        </div>
                                    </div>
                                </div>
                                <ag-grid-angular #agGrid
                                    style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                                    class="ag-theme-alpine" id="MAttendeceGrid" 
                                    [gridOptions]="MAttendeceGridOptions"
                                    [columnDefs]="MAttendeceColumnDefs" 
                                    [defaultColDef]="MAttendeceDefaultColDef"
                                    [rowSelection]="MAttendeceRowSelection" 
                                    [rowData]="MAttendeceRowData"
                                    (gridReady)="onMAttendeceReady($event)" 
                                    (gridReload)="onMAttendeceReload($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Auto Generated Attendance">
                        <ng-template mat-tab-label>
                            Auto Generated Attendance
                        </ng-template>
                        <div class="mt-2">
                            <div class="tab-content px-0">
                                <div class="row border m-0 py-2 mb-2 justify-content-between">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                        <div class="form-group mb-0">
                                            <label>Academic Year</label>

                                            <select class="form-control form-control-sm appearance-auto m-0"
                                                name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()"
                                                required>
                                                <option *ngFor="let year of yearList" [value]="year.Code">
                                                    {{year.Name}}
                                                </option>
                                            </select>

                                        </div>
                                        
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                        <div class="form-group mb-0 d-flex flex-wrap justify-content-end">
                                            <label class="d-block w-100">&nbsp;</label>
                                            <button type="button" class="btn btn-warning btn-sm float-right">Search</button>
                                        </div>
                                    </div>
                                </div>
                                <ag-grid-angular #agGrid
                                    style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                                    class="ag-theme-alpine" id="attendeceGrid" [gridOptions]="attendeceGridOptions"
                                    [columnDefs]="attendeceColumnDefs" [defaultColDef]="attendeceDefaultColDef"
                                    [rowSelection]="attendeceRowSelection" [rowData]="attendeceRowData"
                                    (gridReady)="onAttendeceReady($event)" (gridReload)="onAttendeceReload($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </mat-tab>
                   
                </mat-tab-group>
                <!-- <div class="card-footer text-right p-2 mt-2">
                    <button type="button" class="btn btn-primary" (click)="openMultiEvent()" type="button" >Add Multi Event</button>
                </div> -->
            </div>
        </div>
    </div>
</div>