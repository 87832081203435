<!-- when current time is between start or end time -->
<div *ngIf="status == 'active'" class="count-down-timer">
    <span class="toJoin">{{selectedTitle}}
        <button class="btn btn-primary m-0" (click)="eventClicked()" [disabled]="isdisabled">Join</button>
    </span>
</div>
<!-- when current time after end time -->
<div *ngIf="status == 'after'" class="count-down-timer">
    <span class="toJoin">{{selectedTitle}}
        <p class="m-0" disabled>Expired</p>
    </span>
</div>
<!-- when current time is before start time -->
<div *ngIf="status == 'before'" class="count-down-timer">
    <span class="toJoin">{{selectedTitle}}
        <p class="m-0 ms-auto" disabled>(Time Left)<br>{{days}}:{{hours}}:{{minutes}}:{{seconds}}</p>
        <button class="closeBtn bi bi-arrow-repeat ms-auto me-3" *ngIf="hasReSchedule" (click)="eventReschedule()"
            title="Reschedule Session"></button>
        <button class="closeBtn bi bi-x" (click)="cancelSession()"
                title="Cancel Session"></button>
    </span>
</div>