
export class SidebarInfo{
    ChildMenuList: childMenuList[];
    Code: number;
    Icon: string;
    MenuId: number;
    Name: string;
    ParentId: number;   
    Route: string;  
    Type: string;
    UserMenuId: number;
    IsActive:boolean;
}

export class SidebarViewInfo{
    SidebarInfo : SidebarInfo;
    IsSave: boolean;
    ErrorMessage:string;
    MenuInfo : SidebarInfo[];
}
export class childMenuList{
    MenuId: number;
    Code: number;
    Name: string;
    Route: string;  
    Type: string;
    UserMenuId: number;
    UserId: number;
    IsActive:boolean;
    Icon: string;
    ParentId: number;
    ChildMenuList: null;   
    
} 


