<div class="chat_boxOuter">
  <h2>Q&A</h2>
  <!-- Message box for displaying messages -->

  <div class="message_box" id="message_box" #chatContainer>
    <!-- <div *ngFor="let msg of messages" [ngClass]="{'message-container': true, 'incoming': msg.type === 'incoming'}">
          <p>{{ msg.message }}</p>
        </div>
      <br> -->
  </div>
  <!-- Textarea for typing messages -->

  <div class="input-container" id="input-container">
    <textarea [(ngModel)]="message" (keyup.enter)="sendMessage()" placeholder="Type your message..."></textarea>
    <button (click)="sendMessage()" class="bi bi-send"></button>
    <button (click)="sendFiles()" class="bi bi-send"></button>
  </div>
</div>