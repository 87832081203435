import { formatDate, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { CustommenuComponent } from 'src/app/custommenu/custommenu.component';
import { Assignemnt } from 'src/app/shared/assignemnt.model';
import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { AssignClassesComponent } from '../question/assign-classes/assign-classes.component';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { EditassignmentComponent } from './editassignment.component';
import { StudentAnswerlistComponent } from './student-answerlist.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-create-assignment',
    templateUrl: './create-assignment.component.html',
    styleUrls: ['./create-assignment.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule, NgIf]
})
export class CreateAssignmentComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: Assignemnt[];
  gridOptions:any;
  public bsModalRef:BsModalRef;
  
  currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  constructor(public service:AssignemntService, private modalService: BsModalService,public commonServ:CommonService,private notificationServ:NotificationService) {  
    this.columnDefs = [
    { 
      headerName:"AssignmentId",
      field: 'AssignmentId', cellRendererFramework: CustommenuComponent, 
      cellRendererParams : { MenuList : this.commonServ.role == 'Teacher'?  [{Name: "Edit", "Click" : this.editAssignment , that : this}, {Name: "Assign Classes", "Click" : this.assignClasses , that : this},{Name: "Answer List", "Click" : this.getAnswerList , that : this}]: [{Name: "Edit", "Click" : this.editAssignment , that : this}, {Name: "Delete", "Click" : this.deleteAssignment , that : this},{Name: "Assign Classes", "Click" : this.assignClasses , that : this},{Name: "Answer List", "Click" : this.getAnswerList , that : this}]},
      
      filter:false, width:60,maxWidth: 60, 
      headerComponent: 'customHeader', 
      headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
    }, 
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'StartDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120,maxWidth: 200  },
    { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 ,maxWidth: 200 },
    { headerName:'Class',field: 'ClassNames', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.defaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
      sortable: true,
      floatingFilter: true,
};
this.rowSelection = 'single';
this.commonServ.RefreshGrid$.subscribe(
  
  item => {
    this.getassignments();
    }
);
}

  ngOnInit(): void {
    this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
        }
      }
    });
    
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.getassignments();
    setTimeout(() =>  {
      this.getassignments();
    }, 300);
    
  }
  getassignments(){
    this.service.getassignmentsByParam(this.config).subscribe((res : any) => {
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  editAssignment(AssignmentId, that){
//    that.openquestionmodal.bind(that);
    that.openquestionmodal(AssignmentId);
  }
  assignClasses(AssignmentId, that){
    that.openAssignClassesModal(AssignmentId)
  }
  getAnswerList(AssignmentId, that){
    //var selItem = that.rowData.filter(item => item.AssignmentId == AssignmentId);
    
    const initialState = {
      list: [
        {id:AssignmentId}
      ]      
    };
    this.bsModalRef = that.modalService.show(StudentAnswerlistComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  deleteAssignment(assignmentId, that){
    if(that.commonServ.role == 'Teacher'){
      that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
    }
    else{
    that.service.deleteassignment(assignmentId).subscribe((res:any)=>{
        if(res.IsSaved){
          that.notificationServ.ShowInfo('Successfully deleted!');
          this.getassignments();
        }else{
          that.notificationServ.ShowError(res.ErrorMessage);
        }
      });
    }
  }
 
  openquestionmodal(assignmentId:number) {
    const initialState = {
      list: [
        {id:assignmentId}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditassignmentComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  openAssignClassesModal(assignmentId:number) {
    var assignmentInfo = this.rowData.filter(item => item.AssignmentId == assignmentId);
    const initialState = {
      list: [
        {id:assignmentId},
        {type:'Assignment'},
        {classidlist : assignmentInfo[0].ClassIdList}
      ]      
    };
    this.bsModalRef = this.modalService.show(AssignClassesComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  onSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
  }
  onBtExport(){
    //this.gridApi.exportDataAsExcel();
    this.service.getassignmentsprintinfo().subscribe((res:any)=>{
        this.commonServ.exportAsExcelFile(res,'Assignments');
    });
  
  }
  
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    }
  }
  serchAssignment(){
    this.getassignments();
  }
   
  
}
