import { Component, OnInit } from '@angular/core';
import { AssignemntService } from '../shared/assignemnt.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as fileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-file-management',
    templateUrl: './file-management.component.html',
    styleUrls: ['./file-management.component.scss'],
    standalone: true,
    imports: [FormsModule, BsDatepickerModule, AgGridModule, TranslateModule]
})
export class FileManagementComponent implements OnInit {

  list:any[]=[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  fileListData: any[];
  filterStartDate:any;
  filterEndDate:any;
  datepickerConfig : BsDatepickerConfig;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();

  constructor(public notificationServ:NotificationService,public service:AssignemntService,public commonService:CommonService) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.bsRangeValue = [this.bsValue, this.maxDate];


    this.columnDefs = [
      { headerName: "File Name", field: 'Key', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150},
      { headerName: "File Path", field: 'Value', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
      { headerName: "File Size", field: 'ValueLong', sortable: false, filter: 'agTextColumnFilter'  ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },      
   ];
   this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
   }

  ngOnInit(): void {
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getstudentfilesinfo();
  }
  getstudentfilesinfo(){
    var startDate ="";
    var endDate = "";
    if(this.filterStartDate !== undefined && this.filterEndDate  !== undefined){
      startDate = formatDate(this.filterStartDate,'dd-MM-yyyy','en');
      endDate = formatDate(this.filterEndDate,'dd-MM-yyyy','en');
    }
    
    this.commonService.getstudentfilesinfo(startDate, endDate).subscribe((res : any) => {
      const rowData =  res;
      this.gridApi.setRowData(rowData);
    });
  }
  onSearch(form){
    this.getstudentfilesinfo();
  }
  deleteFiles(){
    if(this.fileListData && this.fileListData.length > 0){
    this.commonService.deleteunusedfiles(this.fileListData).subscribe((res : any) => {
      this.getstudentfilesinfo()
    });
  }
  }
  downloadFiles(){
    if(this.fileListData && this.fileListData.length > 0){
      this.commonService.zipanddownload(this.fileListData).subscribe((res:any)=>{
        fileSaver.saveAs(res, 'filedownloads.zip');
      });
      
  }
  }

}
