export class Appointment {
    AppointmentId:number;
    ClassId:number=0;
    Date:string;
    StartTime:string;
    EndTime:string;
    Text:string;
    Description:string;
    IsActive:boolean;
}
export class appViewInfo{
    AppointmentInfo:Appointment;
    // ClassList:ClassList
}
// class ClassList {
//     "Id": number;
//     "Name": string;
//     "Description": string;
//     "IsActive": boolean;
// }

