<div class="reg-form d-flex align-items-center">
    <div class="container-fluid">
       
        <div class="row justify-content-c">
            
            <div class="col-xs-12 col-sm-8 col-md-5 bg-white p-4 mt-80px mb-3 ">
                <h1 class="display-4 text-center">{{'Register'| translate}}</h1>
                <form novalidate #form="ngForm" >
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <label>{{'FirstName' | translate}}</label>
                            <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.FirstName" required class.invalid="FirstName.invalid && FirstName.touched">
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'MidName' | translate}}</label>
                            <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}" name="MiddleName" #MiddleName="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.MiddleName" required class.invalid="MiddleName.invalid && MiddleName.touched">
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'Surname' | translate}}</label>
                            <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.Surname" required class.invalid="Surname.invalid && Surname.touched">
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'Gender' | translate}}</label>
                            <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel" [(ngModel)]="commServ.userFormData.UserInfo.GenderId">
                              <option  [value]="0">Select Gender</option>
                              <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
                            </select>
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'DateOfBirth' | translate}}</label>
                            <input type="text" class="form-control form-control-sm" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.DOB" [bsConfig]="datepickerConfig" bsDatepicker>
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'Mobile' | translate}}</label>
                            <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.MobileNo" required minlength="8" class.invalid="Mobile.invalid && Mobile.touched">
                          </div>
                          <div class="form-group col-12 mb-0">
                            <label>{{'Email' | translate}}</label>
                            <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel" 
                            [(ngModel)]="commServ.userFormData.UserInfo.Email" required class.invalid="Email.invalid && Email.touched">
                          </div>
                          <div class="form-group col-12 mb-0">
                              <label>Role</label>
                              <select class="form-control form-control-sm appearance-auto" name="RoleId" #RoleId="ngModel" [(ngModel)]="commServ.userFormData.UserInfo.RoleId">
                                <option  [value]="0">Select Role</option>
                                <option *ngFor="let role of rolelist" [value]="role.Id">{{role.Name}}</option>
                              </select>
                            </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="form-group full-group mb-0">
                            <button class="btn btn-primary btn-lg btn-block form-control bd-dadios-0" (click)="register()" [disabled]="form.invalid">{{'Register'| translate}}</button>
                        </div>
                    </div>       
                </form>
            </div>
        </div>
        
       
    </div>
    
</div>


<!-- 

<div class="container-fluid bg-light pb-3">
    <section class="pt-3">
        <div class="row justify-content-c m-0  bg-white mt-80px">
           
            
<div class="m-0 w-100 p-0">
    <div class="modal-content rounded-0">
      <div class="modal-header bg-theam p-1 rounded-0">
        <h3 class="modal-title h3 m-1">Register</h3>
      </div>
      <div class="modal-body p-2">
        <form novalidate #form="ngForm" autocomplete="off">
         
          <div class="row form-row m-0 mb-2">
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                <div class="form-group col-12 mb-0">
                  <label>{{'FirstName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.FirstName" required class.invalid="FirstName.invalid && FirstName.touched">
                </div>
                <div class="form-group col-12 mb-0">
                  <label>{{'MidName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}" name="MiddleName" #MiddleName="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.MiddleName" required class.invalid="MiddleName.invalid && MiddleName.touched">
                </div>
                <div class="form-group col-12 mb-0">
                  <label>{{'Surname' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.Surname" required class.invalid="Surname.invalid && Surname.touched">
                </div>
                <div class="form-group col-12 mb-0">
                  <label>{{'Gender' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel" [(ngModel)]="commServ.userFormData.UserInfo.GenderId">
                    <option  [value]="0">Select Gender</option>
                    <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 mb-0">
                  <label>{{'DateOfBirth' | translate}}</label>
                  <input type="text" class="form-control form-control-sm" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.DOB" [bsConfig]="datepickerConfig" bsDatepicker>
                </div>
               <div class="form-group col-12 mb-0">
                  <label>{{'Mobile' | translate}}</label>
                  <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.MobileNo" required minlength="8" class.invalid="Mobile.invalid && Mobile.touched">
                </div>
                <div class="form-group col-12 mb-0">
                  <label>{{'Email' | translate}}</label>
                  <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel" 
                  [(ngModel)]="commServ.userFormData.UserInfo.Email" required class.invalid="Email.invalid && Email.touched">
                </div>
                <div class="form-group col-12 mb-0">
                    <label>Role</label>
                    <select class="form-control form-control-sm appearance-auto" name="RoleId" #RoleId="ngModel" [(ngModel)]="commServ.userFormData.UserInfo.RoleId">
                      <option  [value]="0">Select Role</option>
                      <option *ngFor="let role of rolelist" [value]="role.Id">{{role.Name}}</option>
                    </select>
                  </div>
              </div>
              
              
            </div>
          </div>
                    
      </form>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-success" (click)="register()">Save</button>
        
      </div>
    </div>
  </div>

        </div>
    </section>
</div>
 -->
