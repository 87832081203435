import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropdownSettings,
  NgMultiSelectDropDownModule
 } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../shared/common.service';
import { EventService } from '../../shared/event.service';
import { NotificationService } from '../../shared/notification.service';
import { formatDate, NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule, NgForm } from '@angular/forms';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-create-social-event',
  standalone: true,
  imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, TimepickerModule, PopoverModule, MatCheckboxModule, NgClass, DatePipe, TranslateModule,NgMultiSelectDropDownModule],
  templateUrl: './create-social-event.component.html',
  styleUrl: './create-social-event.component.scss'
})
export class CreateSocialEventComponent {
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  userList: any[] = [];
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any ;
  dropdownSettings:IDropdownSettings;
  datepickerConfig : BsDatepickerConfig;
  minDate = new Date();
  role:string;
  selectedUsers: any;

  constructor(public service: UserService,public eventService:EventService,public bsModalRef: BsModalRef, public commonServ: CommonService) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.role = commonServ.role;
  }
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'UserId',
      textField: 'FullName',
      selectAllText: 'Select All', 
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
        }
      }
    });
      this.getUserList()
  }
  getUserList(){
    this.service.getUserListByParams(this.config).subscribe((res: any) => {
      this.userList =  res;
   
    });
  }
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    }
    this.getUserList()
  }
  onSelectAll($event: any,arg1: string) {
    console.log($event,arg1);
  }
  onItemSelect($event: any,arg1: string) {
    console.log($event,arg1);
  }
  addEventInfo() {
    console.log(this.eventService.formData.EventInfo);
    console.log(this.selectedUsers);
    
  }
    
    
}
