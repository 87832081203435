import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomControlsComponent } from 'src/app/custommenu/custom-controls/custom-controls.component';
import { CustomHeader } from 'src/app/custommenu/custom-header.component';
import { ClassData } from 'src/app/shared/question.model';
import { QuestionService } from 'src/app/shared/question.service';
 import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
// import { ClassData } from '../shared/question.model';
@Component({
    selector: 'app-assign-classes',
    templateUrl: './assign-classes.component.html',
    styleUrls: ['./assign-classes.component.scss'],
    standalone: true,
    imports: [AgGridModule, TranslateModule]
})
export class AssignClassesComponent implements OnInit {
  gridApi;
  toggleSelection: boolean;
  getRowNodeId;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: ClassData[];
  gridOptions:any;
  list: any[] = [];
  constructor(public questionservice:QuestionService, public bsModalRef: BsModalRef,public assignService : AssignemntService,private notificationServ:NotificationService,private commonService:CommonService) {
    this.toggleSelection = false;
    this.columnDefs = [      
      { 
        headerName:"ClassId",
        field: 'IdActive',  cellRendererFramework: CustomControlsComponent, 
        cellRendererParams : {enableCheckbox : true, controlList : [{Name: "checkbox",  Click: params => this.selectUnselect(params,this), that:this}] },
        filter:false, width:60,maxWidth: 60, 
        headerComponent: 'customHeader', 
        headerComponentParams : { button : "click", checkboxToggle : true, menuIcon : "far fa-check-square", enableSorting:false, "Click":this.selectUnselectAll, that:this }                 
      }, 
      { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
      { field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  ];
  this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
  this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
        sortable: true,
        floatingFilter: true,
  };
  this.rowSelection = 'single';
  this.getRowNodeId = params => {
    return params.ClassId;
  };
  }
  
    ngOnInit(): void {
    }
  
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.commonService.getclasses().subscribe((res : ClassData[]) => {
        this.rowData =  res;
        this.rowData.filter( i => 
          { 
            i.IsActive = false;
            i.IdActive = i.Id+"_"+i.IsActive;
          }
          );
        if(this.list.length > 1 && this.list[2].classidlist && this.list[2].classidlist.length > 0){         
          for(var i =0;i<this.list[2].classidlist.split(',').length;i++){
            var row = this.rowData.filter(item => item.Id == this.list[2].classidlist.split(',')[i]);
            row[0].IsActive = true;
            row[0].IdActive = row[0].Id+"_"+row[0].IsActive;
          }
        }
      });
    }
    assignCass(){
      var idList = [];
      this.gridApi.forEachNode(function (params:any) {
        if(params.data.IsActive){
          idList.push(params.data.Id);
        }
      });
      if(this.list.length > 1 && this.list[1].type == 'Assignment'){
        var assObj1 = {
          Id: this.list[0].id,
          IdList: idList
        };
              
              this.assignService.assignToClass(assObj1).subscribe((res : any) => {
                if(res.ErrorMessage){
                  this.notificationServ.ShowError(res.ErrorMessage);
                }
                else if(res.IsSaved){
                this.notificationServ.ShowInfo("Classes assigned successfully");
                this.commonService.CallOnRefreshGrid();
                this.bsModalRef.hide();
                }
              });
      }
      else{
var assObj = {
  QuestionMasterId: this.list[0].id,
  IdList: idList
};
      
      this.questionservice.assignToClass(assObj).subscribe((res : any) => {
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
        this.notificationServ.ShowInfo("Classes assigned successfully");
        this.bsModalRef.hide();
        }
      });
    }
  }
    selectUnselect(idactive ,that){
     var id = idactive.split("_")[0];
      // alert(cki);
     var selItem = that.rowData.filter(item => item.Id == id);
     selItem[0].IsActive = !selItem[0].IsActive;
     selItem[0].IdActive = selItem[0].Id + "_"+selItem[0].IsActive;
    }
    selectUnselectAll(ClassId ,that){
      that.toggleSelection = !that.toggleSelection; 
      if(that.rowData){
        that.rowData.filter( i => 
          { 
            i.IsActive = that.toggleSelection;
            i.IdActive = i.Id+"_"+i.IsActive;
          }
          )
      }
       that.gridApi.setRowData(that.rowData);
   
      // var params = {
      //   force: true,
      //   suppressFlash: true,
      //   rowNodes: that.rowData,
      // };
     // that.gridApi.redrawRows();
    }
}
