<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <!-- <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Upload Book</h3>
        </div> -->
        <div class="d-flex justify-content-end p-2">
            <i class="fa fa-times cursor-pointer" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
            aria-label="Close"></i>
          </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2" *ngIf="achievementName === 'book'">
                <div class="row m-0" >
                    <div class="col-4 ">
                        <img class="border" [src]="bookDetails.FileInfoList!=null?bookDetails.FileInfoList[0].Result:'../../assets/images/2X/bg-home.png'" alt="">
                    </div>
                    <div class="col-8">
                        <div class="row m-0 book-description">
                            <div class="col-12">
                                <h1 class="m-0">{{bookDetails.BookName}}</h1>
                                <h3>Published by: {{bookDetails.Author}}</h3>
                            </div>
                            <div class="col-4 border-right">
                                <span>Started on</span>
                                <h4 class="m-0">{{bookDetails.EndDate}}</h4>
                            </div>
                            <div class="col-4 border-right">
                                <span>Pages</span>
                                <h4 class="m-0">Static 50</h4>
                            </div>
                            <div class="col-4">
                                <span>My Rating</span>
                                <h4 class="m-0">Static 5</h4>
                            </div>
                            <div class="col-12 mt-4">
                                <span>My Learning/Feedback</span>
                                <div [innerHTML]="bookDetails.Outcome" class="mb-3 mt-1"> </div>
                                <div class="d-flex cursor-pointer" (click)="openUploadBookModel(bookDetails.StudentBookDataId)">
                                    <img src="../../assets/images/icons/svg/edit_file_green.svg" alt="">
                                    <span class="text-success">Edit Details</span>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body p-2" *ngIf="achievementName === 'tarvel'">
                <div class="row m-0">
                    <div class="col-4 ">
                        <img class="border" [src]="tarvelDetails.FileInfoList!=null?tarvelDetails.FileInfoList[0].Result:'../../assets/images/2X/bg-home.png'" alt="">
                    </div>
                    <div class="col-8">
                        <div class="row m-0 book-description">
                            <div class="col-12">
                                <h1 class="m-0">Place: {{tarvelDetails.Place}}</h1>
                                <h3>Place Visited: {{tarvelDetails.PlaceVisited}}</h3>
                            </div>
                            <div class="col-4 border-right">
                                <span>Started on</span>
                                <h4 class="m-0">{{tarvelDetails.StartDate}}</h4>
                            </div>
                            <div class="col-4 border-right">
                                <span>Pages</span>
                                <h4 class="m-0">Static 50</h4>
                            </div>
                            <div class="col-4">
                                <span>Completed On</span>
                                <h4 class="m-0">{{tarvelDetails.EndDate}}</h4>
                            </div>
                            <div class="col-12 mt-4">
                                <span>My Learning/Feedback</span>
                                <div [innerHTML]="tarvelDetails.Outcome" class="mb-3 mt-1"> </div>
                                <div class="d-flex cursor-pointer" (click)="openVisitingPlaceModel(tarvelDetails.StudentTravelDataId)">
                                    <img src="../../assets/images/icons/svg/edit_file_green.svg" alt="">
                                    <span class="text-success">Edit Details</span>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body p-2" *ngIf="achievementName === 'hobby'">
                <div class="row m-0">
                    <div class="col-4 ">
                        <img class="border" [src]="hobbyDetails.FileInfoList!=null?hobbyDetails.FileInfoList[0].Result:'../../assets/images/2X/bg-home.png'" alt="">
                    </div>
                    <div class="col-8">
                        <div class="row m-0 book-description">
                            <div class="col-12">
                                <h1 class="m-0">{{hobbyDetails.Name}}</h1>
                            </div>
                            <div class="col-4 border-right">
                                <span>Started on</span>
                                <h4 class="m-0">{{hobbyDetails.EndDate}}</h4>
                            </div>
                            <div class="col-4 border-right">
                                <span>Pages</span>
                                <h4 class="m-0">Static 50</h4>
                            </div>
                            <div class="col-4">
                                <span>My Rating</span>
                                <h4 class="m-0">Static 5</h4>
                            </div>
                            <div class="col-12 mt-4">
                                <span>My Learning/Feedback</span>
                                <div [innerHTML]="hobbyDetails.Outcome" class="mb-3 mt-1"> </div>
                                <div class="d-flex cursor-pointer" (click)="openMyHobbyModel(hobbyDetails.StudentHobbyDataId)">
                                    <img src="../../assets/images/icons/svg/edit_file_green.svg" alt="">
                                    <span class="text-success">Edit Details</span>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body p-2" *ngIf="achievementName === 'achievement'">
                <div class="row m-0">
                    <div class="col-4 ">
                        <img class="border" [src]="achievementDetails.FileInfoList!=null?achievementDetails.FileInfoList[0].Result:'../../assets/images/2X/bg-home.png'" alt="">
                    </div>
                    <div class="col-8">
                        <div class="row m-0 book-description">
                            <div class="col-12">
                                <h1 class="m-0">{{achievementDetails.Achievement}}</h1>
                                <h3>Competition Name: {{achievementDetails.CompetitionName}}</h3>
                            </div>
                            <div class="col-4 border-right">
                                <span>Started on</span>
                                <h4 class="m-0">{{achievementDetails.EndDate}}</h4>
                            </div>
                            <div class="col-4 border-right">
                                <span>Pages</span>
                                <h4 class="m-0">Static 50</h4>
                            </div>
                            <div class="col-4">
                                <span>My Rating</span>
                                <h4 class="m-0">Static 5</h4>
                            </div>
                            <div class="col-12 mt-4">
                                <span>My Learning/Feedback</span>
                                <div [innerHTML]="achievementDetails.Outcome" class="mb-3 mt-1"> </div>
                                <div class="d-flex cursor-pointer" (click)="openMyAchievementModel(achievementDetails.StudentAchievementDataId)">
                                    <img src="../../assets/images/icons/svg/edit_file_green.svg" alt="">
                                    <span class="text-success">Edit Details</span>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>