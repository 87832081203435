import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { GridImageComponent } from '../grid-image/grid-image.component';
import { CommonService } from '../shared/common.service';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-survey-result',
    templateUrl: './survey-result.component.html',
    styleUrls: ['./survey-result.component.scss'],
    standalone: true,
    imports: [AgGridModule, TranslateModule]
})
export class SurveyResultComponent implements OnInit {
  list:any[] = [];
  frameworkComponents;
  surveyResultRowData:any;
  surveyResultRowSelection;
  surveyResultDefaultColDef;
  surveyResultColumnDefs;
  surveyResultGridOptions:any;
  surveyResultGridApi;
  surveyResultGridColumnApi
  
  
  
  
  constructor(
      public SurveySer:FeedbackService, 
      private notificationServ:NotificationService,
      public commonServ:CommonService,
      public bsModalRef: BsModalRef ) {
    //   var config = {
    //     params: {
    //       SurveyId: this.list[0].SurveyId,
    //     }
    //   };
    // this.commonServ.RefreshGrid$.subscribe(
      
    //   item => {
        
    //     this.SurveySer.getsuveyresults(config).subscribe((res: any) => {
    //       this.surveyResultRowData =  res;
    //   });
    //    }
    // );
   }

  ngOnInit(): void {
this.surveyResultColumnDefs = [
  // { 
  //   headerName:"SurveyId",
  //   field: 'SurveyId', cellRendererFramework: CustommenuComponent, 
  //   cellRendererParams : { MenuList : [{Name: "View Result", "Click" : this.viewSurveyResult , that : this}, {Name: "Edit", "Click" : this.editSurvey , that : this}, {Name: "Delete", "Click" : this.deleteSurvey , that : this}]},
    
  //   filter:false, width:60,maxWidth: 60, 
  //   headerComponent: 'customHeader', 
  //   headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  // }, 
  
  { 
    headerName:"Image",
    field: 'CreatedByImageUrl', cellRendererFramework: GridImageComponent, 
    
    height: 60,
    filter: false,
    sortable: false,
  } ,
  { field: 'UserName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'Result', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
  this.surveyResultDefaultColDef = {
    flex: 1,
    minWidth: 100,
    minHeight: 60,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
}
  
onSurveyReady(params) {
  
  this.surveyResultGridApi = params.api;
  this.surveyResultGridColumnApi = params.columnApi;
  var config = {
    params: {
      surveyId: this.list[0].SurveyId,
    }
  };
  this.SurveySer.getsuveyresults(config).subscribe((res: any) => {
    this.surveyResultRowData =  res;
  });
}
onSurveyReload(data){
  this.surveyResultRowData = data;
}
editSurvey(surveyResultId, that){
  that.openSurveyEditModal(surveyResultId);
  
}
viewSurveyResult(surveyResultId, that){
  that.openSurveyResultModal(surveyResultId);
  
}

deleteSurvey(surveyResultId, that){
  if(that.commonServ.role == 'Teacher'){
    that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
  }
  else{
var item = that.surveyResultRowData.filter(item=>item.SurveyId == surveyResultId);
that.SurveySer.feednackData.SurveyResultInfo = item[0];
that.SurveySer.feednackData.SurveyResultInfo.IsActive = false;
that.SurveySer.saveSurveyResult(that.SurveySer.feednackData.SurveyResultInfo).subscribe((res:any)=>{
    if(res.IsSaved){
      that.notificationServ.ShowInfo('Successfully delete!');
      that.SurveySer.getSurvey().subscribe((res: any) => {
        that.surveyResultRowData =  res;
    });
    }else{
      that.notificationServ.ShowError(res.ErrorMessage);
    }
  });
}
}

}

