import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { EditASSDocComponent } from '../fileManagement/edit-assdoc.component';
import { UploadBookComponent } from './upload-book.component';
import { BookDescriptionComponent } from './book-description.component';
import { achievements } from '../shared/achievements.service';
import { EditVisitingPlaceComponent } from './edit-visiting-place.component';
import { EditMyachievementComponent } from './edit-myachievement.component';
import { EditMyhobbyComponent } from './edit-myhobby.component';
import { StudentBookInfo } from '../shared/achievements.model';
import { DescMyachievementComponent } from './desc-myachievement.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-achievements',
    templateUrl: './achievements.component.html',
    styleUrls: ['./achievements.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NgFor, MatButtonModule, MatMenuModule, MatIconModule]
})
export class AchievementsComponent implements OnInit {
  activeTab: string = "ReadingBooks";
  list:any;
  public bsModalRef:BsModalRef;
  bookList:any=[];
  tarvelList:any=[];
  hobbyList:any=[];
  achievementList:any=[];

  // totalBookList:number=0;
  // totalTarvelList:number=0;
  // totalHobbyList:number=0;
  // totalAchievementList:number=0;


  constructor(public service:achievements,public modalService: BsModalService,public commonService:CommonService) { 
    this.commonService.RefreshAchievements$.subscribe( (item :any) => {
        if(item.Name === "Book"){
          this.getStudentBookInfoList();
        }
        if(item.Name === "Travel"){
          this.getStudentTravelInfoList();
        }
        if(item.Name === "Hobby"){
          this.getStudentHobbyInfoList();
        }
        if(item.Name === "Achievement"){
          this.getStudentAchievementInfoList();
        }
        
        }
    );
  }

  ngOnInit(): void {
    this.service.formDataReadingBooks.StudentBookInfo = new  StudentBookInfo();
    console.log(StudentBookInfo);
    
    this.getStudentBookInfoList();
    this.getStudentTravelInfoList();
    this.getStudentHobbyInfoList();
    this.getStudentAchievementInfoList();
  }

  activeTabChange(event) {
    
    this.activeTab = event.tab.textLabel;
    console.log(this.activeTab);   
    // if(this.activeTab==this.ReadingBooks)
    // {
    //   this.getStudentBookInfoList();
    // }
    // VisitingPlaces   
    // Hobby
    // Achievements
  }
  openUploadBookModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(UploadBookComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  
  }
  onEditBook(id:number){
    this.openUploadBookModel(id);
  }
  openBookDescModel(id, achivementName:string){
    const initialState = {
      list: [
        {
          id:id,
          name:achivementName
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(BookDescriptionComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-md mb-0',ignoreBackdropClick: true}); 
  
  }
  openVisitingPlaceModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditVisitingPlaceComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL, initialState,class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
    // this.getStudentTravelInfo();
  }
  openVisitingPlaceDescModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(BookDescriptionComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-md mb-0',ignoreBackdropClick: true}); 
  
  }

  openMyHobbyModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditMyhobbyComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
  openMyHobbyDescModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(BookDescriptionComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-md mb-0',ignoreBackdropClick: true}); 
  
  }
  openMyAchievementModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditMyachievementComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }
  openMyAcievementDescModel(id){
    const initialState = {
      list: [
        {id:id}
      ]      
    };
    this.bsModalRef = this.modalService.show(DescMyachievementComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-md mb-0',ignoreBackdropClick: true}); 
  
  }



  getStudentBookInfoList(id:number=0){
    this.service.getStudentBookInfoList(id).subscribe((res:any)=>{
    this.bookList = res.StudentBookListInfo;
    
      console.log("StudentBookListInfo");
      console.log(res);
    })
  }
  
  getStudentTravelInfoList(id:number=0){
    this.service.getStudentTravelInfoList(id).subscribe((res:any)=>{
    this.tarvelList = res.StudentTravelListInfo;
      console.log(res);
    })
  }

  getStudentHobbyInfoList(id:number=0){
    this.service.getStudentHobbyInfoList(id).subscribe((res:any)=>{
    this.hobbyList = res.StudentHobbyListInfo;
      console.log(res);
    })
  }

  getStudentAchievementInfoList(id:number=0){
    this.service.getStudentAchievementInfoList(id).subscribe((res:any)=>{
    this.achievementList = res.StudentAchievementListInfo;
      console.log(res);
    })
  }
 


}
