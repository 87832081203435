import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  socket = io("https://awrxhub.com:9550");

  constructor(private toastr:ToastrService,private commonService:CommonService) { }
   ShowError(msg:string){
    this.toastr.error(msg,'',{positionClass: 'toast-center-center' , enableHtml: true});
  }
  ShowInfo(msg:string){
    this.toastr.success(msg,'',{positionClass: 'toast-center-center' , enableHtml: true});
  }
  ShowWarning(msg:string){
this.toastr.warning(msg,'',{positionClass: 'toast-center-center' , enableHtml: true});
  }
  replaceValues(msg:string){
return this.commonService.strReplace(msg,'<br>','')
  }

  sendUserRequest(data: any) {
    this.socket.emit('message', data)
  }
  getUserRequest(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('message', (message: any) => {
        observer.next(message);
      });
    });
  }
}
