import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WebrtcStudentComponentComponent} from './../webrtc-student-component/webrtc-student-component.component'
import {WebrtcTeacherComponentComponent} from './../webrtc-teacher-component/webrtc-teacher-component.component'
@Component({
  selector: 'app-webrtc-component',
  standalone: true,
  imports: [CommonModule,WebrtcStudentComponentComponent,WebrtcTeacherComponentComponent],
  templateUrl: './webrtc-component.component.html',
  styleUrl: './webrtc-component.component.scss'
})
export class WebrtcComponentComponent {
  role:string;
  isTeacher:boolean=false;
  ngOnInit() {
    this.role=localStorage.getItem('UserRole').toLowerCase()
    this.isTeacher=this.role==='teacher'
  }
}
