<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="TTService.formData.ClassTimeTableInfo.ClassTimeTableId<=0">Add Time Table</h3>
            <h3 class="modal-title h3 m-1" *ngIf="TTService.formData.ClassTimeTableInfo.ClassTimeTableId>0">Edit Time Table</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">

                <div>
                    <div class="row form-row m-0">
                        <div class="form-group col-4 mb-0">
                            <label>Select Class</label>
                            <select class="form-control form-control-sm appearance-auto" name="ClassId" #ClassId="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.ClassId" (change)="changeClass()">
                                <option [value]="0">Select</option>
                                <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-4 mb-0">
                            <label>Select Subject</label>
                            <select class="form-control form-control-sm appearance-auto" name="SubjectId"
                                #SubjectId="ngModel" [(ngModel)]="TTService.formData.ClassTimeTableInfo.SubjectId">
                                <option [value]="0">Select</option>
                                <option *ngFor="let subject of subjectList" [value]="subject.Id">{{subject.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>Start Date</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="Start Date" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="TTService.formData.ClassTimeTableInfo.StartDate"
                                    [bsConfig]="datepickerConfig" [minDate]="minDate" bsDatepicker timepicker>
                            </div>
                        </div>
                        
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>Start Time</label>
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="TTService.formData.ClassTimeTableInfo.StartTime" name="StartTime"
                                    class="form-control form-control-sm appearance-auto" >
                                    <option [value]="0">Select</option>
                                        <option *ngFor="let sTime of timeSlotList" [value]="sTime.value">{{sTime.value}}</option>
                                    </select>
                                </div>
                            </div>
                          </div>
                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>End Date</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="End Date" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="TTService.formData.ClassTimeTableInfo.EndDate"
                                    [bsConfig]="datepickerConfig" [minDate]="minDate" bsDatepicker timepicker>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>End Time</label>
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="TTService.formData.ClassTimeTableInfo.EndTime" name="EndTime"
                                    class="form-control form-control-sm appearance-auto" (change)="chkIsGreater()">
                                        <option [value]="0">Select</option>    
                                        <option *ngFor="let eTime of timeSlotList" [value]="eTime.value">{{eTime.value}}</option>
                                    </select>
                                </div>
                            </div>
                          </div>
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>Comments</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text"
                                    placeholder="Comments" name="Comments" #Comments="ngModel"
                                    [(ngModel)]="TTService.formData.ClassTimeTableInfo.Comments"></textarea>
                            </div>
                        </div>
                        <!-- <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>StartDate</label>
                                <input type="text" class="form-control form-control-sm" placeholder="StartDate"
                                    name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="TTService.formData.ClassTimeTableInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker timepicker>
                            </div>
                        </div>
                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>EndDate</label>
                                <input type="text" class="form-control form-control-sm" placeholder="EndDate"
                                    name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="TTService.formData.ClassTimeTableInfo.EndDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker timepicker>
                            </div>
                        </div> -->
                        
                      
                          
                    </div>
                    <div class="row form-row m-0">
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsMonday" #IsMonday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsMonday">Monday</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsTuesday" #IsTuesday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsTuesday">Tuesday</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsWednesday" #IsWednesday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsWednesday">Wednesday</mat-checkbox>
                        </div>
                        
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsThursday" #IsThursday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsThursday">Thursday</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsFriday" #IsFriday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsFriday">Friday</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsSaturday" #IsSaturday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsSaturday">Saturday</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsSunday" #IsSunday="ngModel"
                                [(ngModel)]="TTService.formData.ClassTimeTableInfo.IsSunday">Sunday</mat-checkbox>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="saveTimeTableInfo()" class="btn btn-success" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>