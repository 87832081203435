import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';

@Component({
    selector: 'app-paymentsuccess',
    templateUrl: './paymentsuccess.component.html',
    styleUrls: ['./paymentsuccess.component.scss'],
    standalone: true
})
export class PaymentsuccessComponent implements OnInit {

  constructor(private route: ActivatedRoute,private userService:UserService,private notificationServ:NotificationService,private router:Router) {

   }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        const paymentInfo = {
          FeeListInfo: [],
          ErrorMessage: '',
          IsSaved: false,
          PaymentList:[],
          StudentFeeMasterInfo:{},
          PaymentUrl:'',
          PaymentStatus:'',
          PaymentId:params.session_id
        };
        this.userService.processPaidPayment(paymentInfo).subscribe((res: any) => {
          if(res && res.PaymentStatus === 'Completed'){
            this.router.navigateByUrl('/finance');
          }
          else if(res.ErrorMessage){
            this.notificationServ.ShowError(res.ErrorMessage);
          }
        });
      }
    );
  }

}
