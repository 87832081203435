import { Component, OnInit } from '@angular/core';
import {
  faBarChart, faCalendarDays, faFaceRollingEyes,
  faFileInvoiceDollar, faPoll, faQrcode, faShippingFast, faTasks,
  faUsd, faVideoCamera, faCommentSms
} from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs';
import { SidebarService } from '../shared/sidebar.service';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-content-provider-sidebar',
    templateUrl: './content-provider-sidebar.component.html',
    styleUrls: ['./content-provider-sidebar.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLinkActive, RouterLink, NgClass]
})
export class ContentProviderSidebarComponent implements OnInit {


  Isfliph: boolean = false;
  calendarIcon = faCalendarDays;
  currencyIcon = faUsd;
  liveClassIcon = faVideoCamera;
  tasksIcon = faTasks;
  assesmentIcon = faFaceRollingEyes;
  serveyIcon = faPoll;
  analyticsIcon = faBarChart;
  trackingIcon = faShippingFast;
  billingIcon = faFileInvoiceDollar;
  qrcodeIcon = faQrcode;
  feedbackIcin = faCommentSms;

  menuItems:any=[];
  IsOpend:number;
  IsCollapse:boolean=false;
  subMenuOpen:number;
  collapseCondition:string;


  constructor(private sidebarSer:SidebarService) {

  }


  
  ngOnInit(): void {
    this.getsidebar();
  }
  getsidebar() {
    this.sidebarSer._sidebarMenuData
      .pipe(

        map((res: any) => {
          // debugger;
          let d: any;
          d = res;
          d.forEach((element) => {
            let data: any;
            data = element.ChildMenuList;
            data.Condition = '';
            element.ChildMenuList.forEach((e, index) => {
              data.Condition += index < element.ChildMenuList.length - 1 ? "TRV" + e.Code + '.isActive' + " || " : "TRV" + e.Code + '.isActive';
              return data;
            })
          });
          return d;

        })
      )
      .subscribe(res => {
        this.menuItems = res;

      }
      )
  }
  toggleSidebar() {
    this.Isfliph = document.querySelector(".sidebar.fliph") != null ? false : true
  }
  menuChange(item: number) {
    if (this.IsOpend === item) {
      this.IsOpend = item;
      this.IsCollapse = this.IsCollapse ? false : true;
    } else {
      this.IsOpend = item;
      this.IsCollapse = true;
    }
  }
  subMenuChange(item: number) {
    this.subMenuOpen = item;
  }
}
