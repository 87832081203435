<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{'Breakout Room' | translate}}</h3>
    </div>
    <div class="breackout_body breackout_student_body">
        <div class="br-tittle" id="breakout-loader-1">
            <p>Teacher is creating break out rooms please wait....</p>
        </div>
        <div class="modal_footer" style="display: none;" id="breakout-switch-1">
            <a type="button" class="custom-primary-btn" href="webrtc-group-call" id="switch-call-2">Switch to Breakout Room</a>
            <button type="button" class="custom-primary-btn" (click)="bsModalRef.hide()" aria-label="Close">Continue to
                class</button>
                <button type="button" (click)="bsModalRef.hide()" style="display: none;" id="close-popup">Close</button>
        </div>
    </div>
</div>