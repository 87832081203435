import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-course-details',
    templateUrl: './course-details.component.html',
    styleUrls: ['./course-details.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, MatTabsModule]
})
export class CourseDetailsComponent implements OnInit {

  
  course: string;
  
  constructor(private actRoute: ActivatedRoute) {
    
    if(this.actRoute.snapshot.params.id){
      this.course = this.actRoute.snapshot.params.id;
    }
    else{
      this.course = '';
    }
  }

  ngOnInit(): void {
    if(typeof window != 'undefined')
    window.scroll(0,0);
  }

}
