import { Component, OnInit } from '@angular/core';
import { faCalendarDays, faDollarSign, faEnvelope, faFolder, faPencilSquare, faUsd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-parent-sidebar',
    templateUrl: './parent-sidebar.component.html',
    styleUrls: ['./parent-sidebar.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, FontAwesomeModule]
})
export class ParentSidebarComponent implements OnInit {

  Isfliph:boolean=false;
  constructor() { }

  calendarIcon = faCalendarDays;
  currencyIcon = faUsd;
  financeIcon = faDollarSign;
  editIcon = faPencilSquare;
  folderIcon = faFolder;
  messageIcon = faEnvelope;
  ngOnInit(): void {
  }
  toggleSidebar(){
    this.Isfliph = document.querySelector(".sidebar.fliph")!= null?false:true
  }

}

