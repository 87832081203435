import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attendance, StudentListInfo } from './attendance.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  readonly baseUrl = CONFIG.BASE_URL+'api/Attendance';
  formdata:Attendance = new Attendance();
  constructor(private http:HttpClient) { 
      // this.formdata.StudentListInfo = new StudentListInfo();
  }
  
  getStudentAttendanceViewInfo(classid: number,appointmentId:number){
      var config = {
          params: {
            classId : classid,
            appointmentId : appointmentId
          }
        };
      return this.http.get(this.baseUrl+'/getStudentAttendanceViewInfo',config);
  }
  saveStudentAttendanceViewInfo(){
      return this.http.post(this.baseUrl+'/saveStudentAttendanceViewInfo',this.formdata);
  }
  getmanualattendancelist(config){
    return this.http.get(this.baseUrl+'/getmanualattendancelist',config);
  }
 
}
