import { Component, OnInit } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { NotificationService } from "../shared/notification.service";
import { UserService } from "src/app/shared/user.service";
import { CommonService } from "src/app/shared/common.service";
import { User, UserView } from "../shared/user.model";
import { UserLogin } from "../shared/user.model";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { SignalrService } from "src/app/shared/signalr.service";
import { Title, Meta } from "@angular/platform-browser";
import { CanonicalService } from "../shared/canonical.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CUSTOMCONST } from "../shared/Const";
import { SelfRegistrationComponent } from "../user/self-registration.component";

import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { SessionStorageService } from "../shared/session-storage.service";
import { tap } from "rxjs";
import { SidebarService } from "../shared/sidebar.service";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  standalone: true,
  imports: [FormsModule, RouterLink, TranslateModule],
})
export class LoginComponent implements OnInit {
  // hide = true;
  socialUser: SocialUser;
  isLoggedIn: boolean = false;
  LoginMessage: string = "";
  role: string;
  CUSTOMCONST: any = CUSTOMCONST;
  public bsModalRef: BsModalRef;
  //@Output() messageEvent = new EventEmitter<boolean>();
  constructor(
    public service: UserService,
    public commonServ: CommonService,
    private notificationSrv: NotificationService,
    public router: Router,
    private route: ActivatedRoute,
    private signalRServ: SignalrService,
    private titleService: Title,
    private metaService: Meta,
    private canonicalService: CanonicalService,
    private modalService: BsModalService,
    private socialAuthSrv: SocialAuthService,
    private sessionService: SessionStorageService,
    private sidebarSer: SidebarService
  ) {}

  ngOnInit(): void {
    this.socialAuthSrv.authState.subscribe((User) => {
      this.socialUser = User;
      console.log("this.socialUser");
      console.log(this.socialUser);
    });
    this.titleService.setTitle(
      "Welcome to New ERA of Education Begins with Hybrid Learning Platform"
    );
    this.metaService.addTags([
      {
        name: "keywords",
        content:
          "New ERA of Education, Hybrid Learning Platform, Login, Signup",
      },
      {
        name: "description",
        content:
          "Students, Parents, Teachers. Login with your account to access the online classroom, portfolio and a variety of assignments and assessment tools for Learning Academics, SEL, Life Skills, STEM in Hybrid Learning Platform",
      },
      { name: "robots", content: "index, follow" },
    ]);
    this.canonicalService.setCanonicalURL();
    if (typeof window != "undefined") window.scroll(0, 0);
    this.service.formData = new UserView();
    this.service.formData.UserInfo = new User();
    this.getschoollogo();

    if (this.commonServ.isUserLoggedIn) {
      if (this.role == "Student") {
        var isExternalAuth =
          this.route.snapshot.queryParamMap.get("authenticate") || "";
        if (isExternalAuth == "Y") {
          this.router.navigateByUrl("/registration");
        } else {
          this.router.navigateByUrl("/studentdashboard");
        }
      } else if (this.role == "Teacher") {
        this.router.navigateByUrl("/teacherdashboard");
      } else if (this.role == "Parent") {
        var isExternalAuth =
          this.route.snapshot.queryParamMap.get("authenticate") || "";
        if (isExternalAuth == "Y") {
          this.router.navigateByUrl("/add-child");
        } else {
          this.router.navigateByUrl("/result");
        }
      } else if (this.role == "SuperAdmin") {
        this.router.navigateByUrl("/admindashboard");
      } else if (this.role == "Admin") {
        this.router.navigateByUrl("/dashboard");
      } else if (this.role == "ContentProvider") {
        this.router.navigateByUrl("/dashboard");
      }
    } else {
      this.commonServ.clearsessionStorage();
      var isauthenticate =
        this.route.snapshot.queryParamMap.get("authenticate") || "";
      var accessCode =
        this.route.snapshot.queryParamMap.get("accessCode") || "";
      if (isauthenticate == "Y" && accessCode) {
        //console.log(accessCode);
        var decodedStr = atob(accessCode);
        //console.log(decodedStr);
        if (decodedStr && decodedStr.split(":").length == 2) {
          this.service.formData.UserInfo.UserLogin = decodedStr.split(":")[0];
          this.service.formData.UserInfo.Password = decodedStr.split(":")[1];
          if (
            this.service.formData.UserInfo.UserLogin &&
            this.service.formData.UserInfo.Password
          ) {
            this.onLogin(undefined);
          }
        }
      }
    }
  }
  getmymenu() {
    this.sidebarSer.getmymenu().subscribe((res: any) => {
      this.sidebarSer.setSidebarMenuData(res);
    });
  }
  onLogin(form: NgForm) {
    //this.commonServ.isLogoLoaded.next(false);
    this.service
      .login()
      .pipe(
        tap((res: any) => {
          if (res.AuthToken) {
            this.sessionService.setUserData(
              this.sessionService.formateUserData(res)
            );

            localStorage.setItem("schoolAuth", res.AuthToken);
            localStorage.setItem("UserRole", res.Role);
            localStorage.setItem("schoolLogo", res.LogoPath);
            localStorage.setItem("schoolAuth", res.AuthToken);
            localStorage.setItem("UserRole", res.Role);
            localStorage.setItem("schoolLogo", res.LogoPath);

            this.commonServ.isUserLoggedIn = true;
            this.commonServ.schoolLogoUrl.next(res.LogoPath);
            this.commonServ.role = res.Role;
            this.getmymenu();
            this.service.getMyInfo().subscribe((res: any) => {
              this.sessionService.setSessionData(
                "userInfo",
                JSON.stringify(res.UserInfo)
              );
              localStorage.setItem("userInfo", JSON.stringify(res.UserInfo));
              this.commonServ.userInfo.next(res.UserInfo);
            });
          }
        })
      )
      .subscribe(
        (res: UserLogin) => {
          if (res.AuthToken) {
            this.resetForm(form);
            this.isLoggedIn = true;
            if (this.commonServ.schoolLogoUrl) {
              //this.commonServ.isLogoLoaded.next(true);
            }
            //this.messageEvent.emit(this.isLoggedIn);
            this.commonServ.CallOnLoggedIn();
            this.role = res.Role;
            //open the chat once user is loggedin
            if (this.commonServ.isUserLoggedIn) this.commonServ.OpenChat();
            if (res.Role == "Student") {
              var isExternalAuth =
                this.route.snapshot.queryParamMap.get("authenticate") || "";
              if (isExternalAuth == "Y") {
                this.router.navigateByUrl("/registration");
              } else {
                this.router.navigateByUrl("/studentdashboard");
              }
            } else if (res.Role == "Teacher") {
              this.router.navigateByUrl("/teacherdashboard");
            } else if (res.Role == "Parent") {
              var isExternalAuth =
                this.route.snapshot.queryParamMap.get("authenticate") || "";
              if (isExternalAuth == "Y") {
                this.router.navigateByUrl("/add-child");
              } else {
                this.router.navigateByUrl("/result");
              }
            } else if (res.Role == "SuperAdmin") {
              this.router.navigateByUrl("/admindashboard");
            } else if (res.Role == "ContentProvider") {
              this.router.navigateByUrl("/dashboard");
            } else {
              this.router.navigateByUrl("/dashboard");
            }
            this.signalRServ.initiateSignalrConnection(
              res.AuthToken,
              res.UserId
            );
            this.commonServ.UserId = res.UserId;
          } else if (res.ErrorMessage) {
            this.notificationSrv.ShowError(res.ErrorMessage);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  resetForm(form: NgForm) {
    if (form) {
      form.form.reset();
    }
    this.service.formData = new UserView();
    this.service.formData.UserInfo = new User();
  }
  getschoollogo() {
    var guid = "";
    this.route.queryParams.subscribe((params) => {
      guid = params.Id;
    });
    if (guid) {
      //this.commonServ.isLogoLoaded.next(false);
      this.service.getschoollogo(guid).subscribe((res: any) => {
        if (res && res.SchoolId > 0) {
          this.commonServ.schoolLogoUrl.next(res.FilePath);
          this.LoginMessage = res.LoginMessage;
          if (this.commonServ.schoolLogoUrl) {
            //this.commonServ.isLogoLoaded.next(true);
          }
        }
      });
    }
  }
  openSelfRegistrationModel() {
    const initialState = {
      list: [{ id: 0 }],
    };
    this.bsModalRef = this.modalService.show(SelfRegistrationComponent, {
      id: CUSTOMCONST.LABEL_ONE_MODAL,
      initialState,
      class: "modal-lg mb-0",
      ignoreBackdropClick: true,
    });
  }
  signInWithGoogle(): any {
    this.socialAuthSrv.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  googleauthcompleted(data) {
    console.log("googleauthcompleted");
    console.log(data);
  }
}
