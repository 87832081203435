import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import $ from "jquery";
import { EventService } from "src/app/shared/event.service";
import { NotificationService } from "src/app/shared/notification.service";
import { SplitRoomComponent } from "../split-room/split-room.component";
import { PreviewRecordingComponentComponent } from "../preview-recording-component/preview-recording-component.component";
import { WebrtcChatComponentComponent } from "../webrtc-chat-component/webrtc-chat-component.component";
import { WhiteBoardComponent } from "../white-board/white-board.component";
import html2canvas from 'html2canvas';
import { StudentListComponent2 } from "../student-list/student-list.component";
declare const oneToMany: any;
declare const cameraOff: any;
declare const screenRecording: any;
declare const canvas: any;
declare const sendMessage: any;
declare const shreFiles: any;
declare const sendFile: any;
declare const uploadRecFile: any;
declare const captureStream: any;
declare const stopCaptureStream: any;
// declare const closeWhiteBoard: any;

@Component({
  selector: "app-webrtc-teacher-component",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    WebrtcChatComponentComponent,
    WhiteBoardComponent, 
    StudentListComponent2
  ],
  templateUrl: "./webrtc-teacher-component.component.html",
  styleUrl: "./webrtc-teacher-component.component.scss",
})
export class WebrtcTeacherComponentComponent {
  stopCaptureStreamCall() {
    stopCaptureStream()
  }
captureStreamCall() {
  captureStream()
}
  showWhiteBoard: boolean = false;
  isShareScreen: boolean = false;
  showchatboxbutton: boolean = true;
  hidechatboxbutton: boolean = false;
  showList: boolean = false;
  StudentList: any[] = [];
  receivedData: any;
  selectedFile: File | null = null;
  downloadVideoUrl: string;
  mediaRecorder: MediaRecorder | null = null;
  recordedChunks: Blob[] = [];
  canvasInterval: any;
  chunks: Blob[] = [];
  // mediaRecorder: any;
  // chunks: Blob[] = [];
  stream: MediaStream;
  public bsModalRef: BsModalRef;
  manyTomany: boolean =false;
  typeTitle: string="Enable many to many";
  constructor(
    public eventService: EventService,
    public notificationService: NotificationService,
    private modalService: BsModalService
  ) {}

  roomId: string | null;
  @ViewChild('recordableDiv') recordableDiv!: ElementRef<HTMLDivElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('audioElement') audioElement!: ElementRef<HTMLAudioElement>;
  @ViewChild("chatContainer") chatContainer!: ElementRef;

  ngOnInit() {
    localStorage.setItem("DuringConsultation", "true");
    $(document).ready(function () {
      $("#btnMute").show();
      $("#btnUnMute").hide();
      $("#off-cam").show();
      $("#start-cam").hide();
    });
    this.socketIo();
    // calling function for getting consultation status
    this.roomId = localStorage.getItem("room_id");
    if (this.roomId) {
      this.screenOrVideo();
      // this.startRecording();
      this.getStudentList();
      this.updateAttandance();
    }
  }
  ngAfterViewInit() {
    // Now, recordableDiv and other ViewChild elements are available
  }
  socketIo() {}
  screenOrVideo() {
    if (this.roomId) {
      oneToMany(this.roomId, "openOrJoin", this.isShareScreen);
    }
  }
  // async startRecording() {
  //   if (!this.recordableDiv || !this.recordableDiv.nativeElement) {
  //     console.error('recordableDiv is not defined!');
  //     return;
  //   }

  //   const divElement = this.recordableDiv.nativeElement;

  //   // Capture the div as a canvas using html2canvas
  //   const canvas = await html2canvas(divElement);
  //   const context = this.canvasElement.nativeElement.getContext('2d');

  //   if (!context) {
  //     console.error('Failed to get 2D context from canvasElement!');
  //     return;
  //   }

  //   // Set canvas dimensions to match the div
  //   this.canvasElement.nativeElement.width = canvas.width;
  //   this.canvasElement.nativeElement.height = canvas.height;

  //   // Draw the captured image on the canvas
  //   context.drawImage(canvas, 0, 0);
  //   console.log("hhhhhh",this.recordableDiv);

  //   // Capture the canvas stream
  //   this.stream = this.canvasElement.nativeElement.captureStream(25);  // Capture at 25fps

  //   this.mediaRecorder = new MediaRecorder(this.stream);
  //   this.mediaRecorder.ondataavailable = (event: any) => {
  //     if (event.data.size > 0) {
  //       this.chunks.push(event.data);
  //     }
  //   };

  //   this.mediaRecorder.onstop = () => {
  //     const videoBlob = new Blob(this.chunks, { type: 'video/webm' });
  //     const videoURL = URL.createObjectURL(videoBlob);
  //     this.downloadVideoUrl = videoURL;

  //     // Automatically trigger download
  //     uploadRecFile(videoBlob);
  //     $("#captureBtn").show()
  //     $("#previewBtn").show()
  //     $("#download").show()
  //     $("#stopBtn").hide()
  //     // Set the recorded video to an audio element (you can use a video element too)
  //     this.audioElement.nativeElement.src = videoURL;
  //     this.audioElement.nativeElement.play();
  //   };

  //   this.mediaRecorder.start();
  //   $("#captureBtn").hide()
  //   $("#stopBtn").show()
  //   $("#showconsultationsidebar").addClass("rec-on")
  // }
  async captureDivToCanvasStream(): Promise<MediaStream | null> {
    const divElement = this.recordableDiv.nativeElement;

    const canvas = await html2canvas(divElement);
    const canvasStream = (canvas as any).captureStream(); // Capture the canvas as a stream
    return canvasStream;
  }
  async startCanvasCapture(): Promise<MediaStream | null> {
    const divElement = this.recordableDiv.nativeElement;

    // Create a hidden canvas to draw frames on
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    canvas.width = divElement.offsetWidth;
    canvas.height = divElement.offsetHeight;

    // Create a new stream from the canvas
    const canvasStream = (canvas as any).captureStream(30);

    // Capture frames periodically using html2canvas
    this.canvasInterval = setInterval(async () => {
      const canvasImage = await html2canvas(divElement);
      ctx.drawImage(canvasImage, 0, 0, canvas.width, canvas.height);
    }, 100); // Capture frame every 100ms

    return canvasStream;
  }
  async startRecording() {
    // // Ensure the video and audio stream is available
    // const videoStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    
    // // // Create a MediaStream from the video stream
    // // const canvasStream = this.captureDivToCanvasStream(); // Capture at 25fps
    // const divElement = this.recordableDiv.nativeElement;

    // const canvas = await html2canvas(divElement);
    // const canvasStream = (canvas as any).captureStream(30); // Capture the canvas as a stream
    // return canvasStream
    const canvasStream = await  this.startCanvasCapture();
    const combinedStream = new MediaStream([...canvasStream.getTracks()]);

    this.mediaRecorder = new MediaRecorder(combinedStream);
    
    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data.size > 0) {
        this.chunks.push(event.data);
      }
    };

    this.mediaRecorder.onstop = async () => {
      const videoBlob = new Blob(this.chunks, { type: 'video/webm' });
      this.downloadVideoUrl = URL.createObjectURL(videoBlob);
      
      // Call the upload function
      await uploadRecFile(videoBlob);
      
      $("#captureBtn").show()
      $("#previewBtn").show()
      $("#download").show()
      $("#stopBtn").hide()
      
      // Set the recorded video to an audio element (you can use a video element too)
      this.audioElement.nativeElement.src = this.downloadVideoUrl;
      this.audioElement.nativeElement.play();
    };

    this.mediaRecorder.start();
       $("#captureBtn").hide()
    $("#stopBtn").show()
    $("#showconsultationsidebar").addClass("rec-on")
  }
  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      $("#showconsultationsidebar").removeClass("rec-on")
    } else {
      console.error('MediaRecorder is not initialized!');
    }
  }

  downloadRecordedFile() {
    const a = document.getElementById('download')  as HTMLAnchorElement;
    a.href = this.downloadVideoUrl;
    a.style.display="flex"
    a.download = 'recorded-video.webm';
   
    // $("#showconsultationsidebar").removeClass("rec-on")

    // Release the object URL after download
    // URL.revokeObjectURL(this.downloadVideoUrl);
  }
  switchShowList() {
    this.showList = !this.showList;
    $(document).ready(function () {
      $("#studentshow").toggleClass("active");
    });
  }
  getStudentList() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    this.eventService
      .getStudentByEventId(eventData.EventId)
      .subscribe((res: any) => {
        const StudentList = res.StudentList;
        localStorage.setItem("StudentList", JSON.stringify(StudentList));
        this.StudentList = StudentList;
      });
  }
  updateAttandance() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const actionData = {
      EventId: eventData.EventId,
      Action_Type: "Join",
    };

    this.eventService.saveRoomStatusInfo(actionData).subscribe((res: any) => {
      const rowData = res.StudentList;
      this.StudentList = rowData;
    });
  }
  // This function calls when doctor click on chat button
  showchatbox() {
    // this.showchatboxbutton = false;
    // this.hidechatboxbutton = true;

    $(document).ready(function () {
      $("#showconsultationsidebar").toggleClass("chatBoxOpen");
      $("#showchat").toggleClass("active");
    });
  }
  // This function calls for hide chat box

  // hidechatbox() {
  //   this.showchatboxbutton = true;
  //   this.hidechatboxbutton = false;

  //   $(document).ready(function () {
  //     $("#showconsultationsidebar").removeClass("chatBoxOpen");
  //   });
  // }
  endCall() {
    // window.close();
    window.location.href = "/call-feedback";
  }
  enableWhiteBoard() {
    $(document).ready(function () {
      $(
        "app-webrtc-teacher-component #showconsultationsidebar #local"
      ).toggleClass("teacher-whitboard-share");
    });
    this.showWhiteBoard = !this.showWhiteBoard;
    if(this.showWhiteBoard != true){
      const msg = JSON.stringify({
        type:"close-whiteboard",
      })
      sendMessage(msg);
      // closeWhiteBoard()
    }

    // const initialState = {};
    // this.bsModalRef = this.modalService.show(WhiteBoardComponent, {
    //   initialState,
    //   class: "testing",
    //   ignoreBackdropClick: true,
    // });
  }
  makeSplitRoom() {
    const initialState = {
      list: JSON.parse(localStorage.getItem("StudentList")),
    };
    this.bsModalRef = this.modalService.show(SplitRoomComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered breackoutroom_modal",
      ignoreBackdropClick: true,
    });
  }
  previewRecording() {
    const initialState = {
      videoUrl: this.downloadVideoUrl, // Set the video URL to pass
    };
    this.bsModalRef = this.modalService.show(
      PreviewRecordingComponentComponent,
      {
        initialState,
        class: "modal-dialog modal-dialog-centered breackoutroom_modal",
        ignoreBackdropClick: true,
      }
    );
  }
   // Event when file is selected
   onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]; // Get the selected file
      console.log(this.selectedFile);
    }
  }

  // Upload logic
  onUpload(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      shreFiles(this.selectedFile)
      // sendFile(this.selectedFile)
      // Here you would typically make an HTTP request to upload the file
      // Example:
      // this.http.post('your-backend-api-url', formData).subscribe(response => {
      //   console.log(response);
      // });

      console.log('File ready for upload:', this.selectedFile);
    } else {
      console.log('No file selected.');
    }
  }
  recOn(){
    // console.log("hhhhhhhh");    
    sendMessage(JSON.stringify({ type: "rec-on"}))
  }
  recOff(){
    // console.log("jjjjjjj");
    sendMessage(JSON.stringify({ type: "rec-off"}))
  }
  switchType(){
    console.log(this.manyTomany,this.typeTitle,"here");
    
    this.typeTitle=this.manyTomany?"Disable many to many":"Enable many to many"
    this.manyTomany=!this.manyTomany
    sendMessage(JSON.stringify({ type: "type-change"}))
  }
}
