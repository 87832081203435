import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/shared/common.service";
import { Router } from "@angular/router";
import { SignalrService } from "./shared/signalr.service";
import { NgForm, FormsModule } from "@angular/forms";
import { NotificationService } from "./shared/notification.service";
import { Contact, UserInfo, UserRegisterData } from "./shared/contact.model";
import { UserService } from "./shared/user.service";
import { Title, Meta } from "@angular/platform-browser";
import { CanonicalService } from "./shared/canonical.service";
import { BnNgIdleService } from "bn-ng-idle";
import contactPageData from "./schoolConst";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { ViewInfo } from "./shared/user.model";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { SessionStorageService } from "./shared/session-storage.service";
import { SidebarService } from "./shared/sidebar.service";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { RouterModule } from "@angular/router";
import { ChatComponent } from "./chat/chat.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { StudentSidebarComponent } from "./sidebar/student-sidebar.component";
import { ContentProviderSidebarComponent } from "./sidebar/content-provider-sidebar.component";
import { TeacherSidebarComponent } from "./sidebar/teacher-sidebar.component";
import { AdminSidebarComponent } from "./sidebar/admin-sidebar.component";
import { CordinatorSidebarComponent } from "./sidebar/cordinator-sidebar.component";
import { SuperadminSidebarComponent } from "./sidebar/superadmin-sidebar.component";
import { ParentSidebarComponent } from "./sidebar/parent-sidebar.component";
import { ChatboxComponent } from "./chat/chatbox.component";
import { AgGridModule } from "ag-grid-angular";
import {
  faStar,
  faStarHalfAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    MatRadioModule,
    TranslateModule,
    CommonModule,
    MatCardModule,
    FontAwesomeModule,
    RouterModule,
    ChatComponent,
    SidebarComponent,
    FormsModule,
    StudentSidebarComponent,
    AdminSidebarComponent,
    CordinatorSidebarComponent,
    ContentProviderSidebarComponent,
    TeacherSidebarComponent,
    SuperadminSidebarComponent,
    ParentSidebarComponent,
    ChatboxComponent,
    AgGridModule,
  ],
})
export class AppComponent {
  // constructor(library: FaIconLibrary) {
  //   // ngRedux.configureStore(LoginReducer, initialState);
  //   library.addIcons(faStar, faStarHalfAlt, farStar, faTimesCircle);
  // }
  selectChild(arg0: any) {
    throw new Error("Method not implemented.");
  }
  //@ViewChild(LoginComponent) loginRef;
  title = "A New ERA Of Hybrid Learning";
  //@Input()
  Isfliph: boolean = false;
  isColapsAside: boolean = true;
  logoutIcons = faSignOut;
  isLogedIn: boolean = false;
  role: string;
  // isChatExpend:boolean=false;
  SignupName: string;
  SignupFName: string;
  SignupLName: string;
  SignupEmail: string;
  SignupRoleId: number = 0;
  SignupRoleList: any[] = contactPageData.contact.SignupRoleList;
  schoolLogoUrl: string = "../assets/images/logo/logo.png";
  isSbscribeClick: boolean = true;
  imagePath: string;

  calendarIcon = faCalendarDays;
  // userPicData:any;
  userInfo: any = this.sessionSer.getSessionData("userInfo")
    ? this.sessionSer.getSessionData("userInfo")
    : [];
  // userData: any;
  // urlSafe: any;
  // childrenList: any;
  faStar = faStar;
  faStarHalfAlt = faStarHalfAlt;
  faTimesCircle = faTimesCircle;
  form: FormGroup;

  constructor(
    public translateService: TranslateService,
    public commonServ: CommonService,
    library: FaIconLibrary,
    public router: Router,
    private signalRServ: SignalrService,
    private notificationSrv: NotificationService,
    public userServ: UserService,
    private titleService: Title,
    private metaService: Meta,
    private canonicalService: CanonicalService,
    private bnIdle: BnNgIdleService,
    private sidebarSer: SidebarService,
    private sessionSer: SessionStorageService,
    private fb: FormBuilder
  ) {
    this.imagePath = "/assets/images/user/default.png";
    if (localStorage.getItem("schoolLogo")) {
      this.commonServ.schoolLogoUrl.next(localStorage.getItem("schoolLogo"));
    }
    // library.addIcons(faStar, faStarHalfAlt, farStar, faTimesCircle);
    this.commonServ.userInfo.next(this.userInfo);

    translateService.addLangs(["en", "da"]);
    translateService.setDefaultLang("en");
    //translateService.use('en');
    //this.isLogedIn = this.usrService.isLoggedIn;
    this.commonServ.isUserLoggedIn = false;
    this.commonServ.IsLoggedIn$.subscribe((item) => {
      this.isLogedIn = true;
      this.commonServ.isUserLoggedIn = true;
      this.role = localStorage.getItem("UserRole") ?? "";
      this.commonServ.role = localStorage.getItem("UserRole") ?? "";

      // this.userPicData = this.sessionSer.getSessionData('userImages');
      // this.userInfo = this.sessionSer.getSessionData('userInfo');
      this.commonServ.userInfo.subscribe((res: string) => {
        this.userInfo = res;
      });
      this.commonServ.schoolLogoUrl.subscribe((res: string) => {
        console.log(res);

        this.schoolLogoUrl = res;
      });
      this.startInactivityWatching();
    });
    this.commonServ.isChatting.next(false);
    this.commonServ.openChat$.subscribe((item) => {
      this.commonServ.isChatting.next(true);
    });
    this.signalRServ.chatStarted.subscribe((item) => {
      if (this.commonServ.isChatting) {
        this.commonServ.isChatting.next(false);
      }
      this.commonServ.isChatting.next(true);
      if (
        !this.commonServ.messageMasterInfo ||
        this.commonServ.messageMasterInfo.MessageMasterId !=
          item.MessageMasterId
      ) {
        this.commonServ.messageMasterInfo = item;
      }
      this.commonServ.loadMessage(item);
    });
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
    // if(this.isLogedIn){
    //   this.startInactivityWatching();
    // }
  }

  ngOnInit() {
    // this.changeMenu();
    // this.getmymenu();

    this.commonServ.userFormData = new UserRegisterData();
    this.commonServ.userFormData.UserInfo = new UserInfo();
    this.commonServ.CheckLoggedInStatus();
    this.sidebarSer.CheckSidebar();
    // console.log(this.SignupRoleList);
    this.getuseregisterinfo();
    this.userServ.viewInfo.UserInfo = {
      ...this.userServ.viewInfo.UserInfo,
      Role: "Parent",
    };

    if (localStorage.getItem("schoolLogo")) {
      this.commonServ.schoolLogoUrl.next(localStorage.getItem("schoolLogo"));
    } else {
      this.commonServ.schoolLogoUrl.next(this.schoolLogoUrl);
    }
  }

  getuseregisterinfo() {
    this.commonServ.getuseregisterinfo().subscribe((res: any) => {
      this.commonServ.userFormData = res;
    });
  }
  startInactivityWatching() {
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.OnLogOut();
        this.bnIdle.stopTimer();
        // alert('Your session has been expired');
      }
    });
  }
  onRegister(form: any) {
    this.isSbscribeClick = true;
    this.commonServ.saveuseregisterinfo().subscribe(
      (res: any) => {
        if (res.status) {
          this.notificationSrv.ShowInfo(res.message);
        } else if (res.IsSuccess) {
          this.notificationSrv.ShowError(res.ErrorMessage);
        }
        // else {
        //   this.notificationSrv.ShowError(res.ErrorMessage);
        // }
        this.resetForm(form);
        this.isSbscribeClick = false;
      },
      (err) => {
        this.notificationSrv.ShowError(err);
      }
    );
  }
  resetForm(form: NgForm) {
    form.form.reset();
    this.SignupName = "";
    this.SignupEmail = "";
  }
  translateSite(langauge: string) {
    this.translateService.use(langauge);
  }
  OnLogOut() {
    localStorage.removeItem("schoolAuth");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("schoolLogo");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userData");
    localStorage.removeItem("schoolAuth");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("schoolLogo");

    this.userServ.logOut().subscribe(
      async (res: any) => {
        this.commonServ.clearsessionStorage();
        this.isLogedIn = false;
        this.commonServ.isUserLoggedIn = false;
        this.signalRServ.UnSubscribe(this.commonServ.UserId);
        this.commonServ.isChatting.next(false);
        this.commonServ.userInfo.next([]);
        this.commonServ.schoolLogoUrl.next("");

        // this.router.navigateByUrl('/login');
      },
      (err) => {
        console.log(err);
      }
    );
    // this.commonServ.clearsessionStorage();
    // this.isLogedIn = false;
    // this.commonServ.isUserLoggedIn = false;
    // this.signalRServ.UnSubscribe(this.commonServ.UserId);
    // localStorage.removeItem('schoolAuth');
    // this.router.navigateByUrl('/login');
    window.location.href = "/login";
  }
  onActivate(event: Event) {
    if (typeof window != "undefined") window.scrollTo(0, 0);
  }
  changeMenu() {
    var menuItem = document.querySelector("#navbarNav");
    if (menuItem.className === "navbar-collapse collapse show") {
      menuItem.setAttribute("class", "navbar-collapse collapse");
    }

    // var activeClass = document.querySelector(".active");
    // activeClass.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    // if(activeClass.getAttribute('class')=== 'dropdown-item'){
    //   activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand active');
    // }
    // else{
    //   activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    // }
  }
  changeactiveMenu(event) {
    if (event.target.className === "dropdown-item") {
      event.target.parentElement.parentElement.firstElementChild.setAttribute(
        "class",
        "nav-link dropdown-toggle navbar-brand active"
      );
    } else {
      var activeClass = document.querySelector(".dropdown-toggle");
      activeClass.setAttribute(
        "class",
        "nav-link dropdown-toggle navbar-brand"
      );
    }

    // console.log();
    // var activeClass = document.querySelector(".active");
    // console.log(activeClass);
  }

  // Sidebar Toggle Button JS
  toggleSidebar() {
    this.Isfliph = document.querySelector(".fliph") != null ? false : true;
    if (this.isColapsAside) {
      this.isColapsAside = false;
    } else {
      this.isColapsAside = true;
    }
  }

  // getmymenu(){
  //   this.login.getmymenu(4).subscribe((res) => {
  //     console.log(res);
  //   })
  // }
}
