
<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <div class="row border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Academic Year</label>

                            <select class="form-control form-control-sm appearance-auto" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()" required>
                                <option *ngFor="let year of yearList"  [value]="year.Code">
                                {{year.Name}}
                                </option>
                            </select>

                        </div>
<!--
                        <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <button type="button" (click)="serchData()" class="btn btn-warning float-right" type="button" >Search</button>
                    </div>
                </div>
                <ag-grid-angular #agGrid
                  style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                  class="ag-theme-alpine"
                  id="eventGrid"
                  [gridOptions]="leaveGridOptions"
                  [columnDefs]="leaveColumnDefs"
                  [defaultColDef]="leaveDefaultColDef"
                  [rowSelection]="leaveRowSelection"
                  [rowData]="leaveRowData"
                  (gridReady)="onLeaveReady($event)"
                  [frameworkComponents]="frameworkComponents"
                  (gridReload)="onLeaveReload($event)"
                  ></ag-grid-angular>

            </div>
        </div>
    </div>
</div>
