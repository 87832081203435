import { Component, OnInit } from '@angular/core';
import { QRCodeService } from '../shared/QRCode.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CUSTOMCONST } from '../shared/Const';
import { EditQRCodeComponent } from './edit-qrcode.component';
import { CustomGrideCelComponent } from '../custommenu/custom-gride-cel/custom-gride-cel.component';
import { QRCodeViewComponent } from './qrcode-view.component';
import { NotificationService } from '../shared/notification.service';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-qrcode-list',
    templateUrl: './qrcode-list.component.html',
    styleUrls: ['./qrcode-list.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class QRCodeListComponent implements OnInit {

  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  QRCodeListData: any[];
  public bsModalRef:BsModalRef;

  
  constructor(
    public QRCodeServ:QRCodeService,
    private modalService: BsModalService,
    public commonService:CommonService,
    private notificationServ:NotificationService
    ) { 
    this.gridInit();   
    this.commonService.RefreshGrid$.subscribe(
    
      item => {
        this.getQRCodeList();
        }
    );
  }

  ngOnInit(): void {
    this.getQRCodeList();
  }
  getQRCodeList(){
    this.QRCodeServ.getQRCodeListView().subscribe((res:any) =>{
      const rowData =  res;
      this.gridApi.setRowData(rowData.QRCodeListInfo);
      
    })
  }


  gridInit(){
    
    this.columnDefs = [
      { 
        headerName:"QRCodeId",
        field: 'QRCodeId', cellRendererFramework: CustommenuComponent, 
        cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editQRCode , that : this}, {Name: "Delete", "Click" : this.deleteQRCode, that : this},  {Name: "Analytics", "Click" : this.viewQRAnalytics, that : this}]},
        
        filter:false, width:60,maxWidth: 60, 
        headerComponent: 'customHeader', 
        headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
      }, 
      {
        headerName: "Image",  sortable: true,filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, },
        field: 'QRCodeImageUrl', cellRendererFramework: CustomGrideCelComponent, width: 120,autoHeight: true,
        cellRendererParams: {enableButton: false, celName: [{ Name: "Complete", "Click": this.openImage, that: this }] },
      },
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150 ,maxWidth: 300 },
    { field: 'Code', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200 ,suppressSizeToFit: true },
    { field: 'RedirectUrl', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 200 , },
    // { field: 'QRCodeImageUrl', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, minWidth: 150 ,suppressSizeToFit: true },

    
   ];
   this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
 this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   this.getQRCodeList();
  }

  editQRCode(id,that){
    that.openQRCodeEditModel(id,'Edit')
  }

  viewQRAnalytics(id,that){
    that.openQRAnalyticsModel(id,'Analytics')
  }

  deleteQRCode(QRCodeId,that){
    var item = that.QRCodeListData.filter(item => item.QRCodeId == QRCodeId);
    that.QRCodeServ.formData.QRCodeInfo= item[0];
    let data = item[0];
    that.deleteQRcode(QRCodeId, data)
  }
deleteQRcode(id:number, data:any){
  // debugger;
  this.QRCodeServ.getQRCodeView(id).subscribe((res : any) => {
    this.QRCodeServ.formData =  res;
    
    if(res){
      this.QRCodeServ.formData.QRCodeInfo.IsActive = false
      this.QRCodeServ.deleteQRCode().subscribe((res:any)=>{
        if(res.IsSaved){
          this.notificationServ.ShowInfo('Successfully deleted!');
          this.getQRCodeList();
        }else{
          this.notificationServ.ShowError(res.Message);
        }
      });
    }
   });
}
  openQRCodeEditModel(id,Action){
    const initialState = {
      list: [
        {
          id:id,
          action:Action,
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(EditQRCodeComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }

  openQRAnalyticsModel(id,Action){
    const initialState = {
      list: [
        {
          id:id,
          action:Action,
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(EditQRCodeComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  }

  openImage(url,that){
    console.log(url);
    
    const initialState = {
      list: [
        {QRCodeUrl:url}
      ]      
    };
    that.bsModalRef = that.modalService.show(QRCodeViewComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-md mb-0',ignoreBackdropClick: true}); 
  }
}
