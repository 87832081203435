import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-life-skills',
    templateUrl: './life-skills.component.html',
    styleUrls: ['./life-skills.component.scss'],
    standalone: true
})
export class LifeSkillsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(typeof window != 'undefined')
    window.scroll(0,0);
  }

}
