import { Component, OnInit } from '@angular/core';
import { QRCodeService } from '../shared/QRCode.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { QRCode, QRCodeView } from '../shared/QRCode.model';
import { el } from '@fullcalendar/core/internal-common';
import { ColorPickerControl, ColorPickerModule } from '@iplab/ngx-color-picker';
import { Validators, FormsModule } from '@angular/forms';
import Chart from 'chart.js/auto';
import { NgIf, NgFor, NgClass } from '@angular/common';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}


@Component({
    selector: 'app-edit-qrcode',
    templateUrl: './edit-qrcode.component.html',
    styleUrls: ['./edit-qrcode.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, NgClass, ColorPickerModule]
})

export class EditQRCodeComponent implements OnInit {
  list:any[];
  qrtypelist:any[];
  selected_type : number;
 URL: string;
  WIFIName : string;
  WIFIPassword : string;
  WIFISecurity: string;
  WIFIRedirecctUrl : string = 'WIFI:S:${WIFIName};T:${WPA};P:${WIFIPassword};H:true;;';
  public bsModalRef:BsModalRef;
  CUSTOMCONST=CUSTOMCONST
  isUrlInvalid:string='';

  isFrameOptionsVisible:boolean= false
  isColorOptionVisible:boolean=false
  isLogoOptionVisible:boolean=false
  frameOptionSelectedStyle:string=''
  qrText:string=''
  qrTextDisplayPostion:string=''
  qrCodePreviewData:string=''
  logoBorder:number=0;
  qrLogoImagePreview:any;
  isQRBorderRequired:boolean;
  actionType:string=''

  oschart: any;
  actiononchart:any;
  ismobilechart:any;
  browserchart:any;
  
  osLabels: any[] = []
  osData: any[] = []
  actiononLabels: any[] = []
  actiononData: any[] = []
  ismobileLabels: any[] = []
  ismobileData: any[] = []
  browserLabels: any[] = []
  browserData: any[] = []
 



  selectedFile: ImageSnippet;

  qrTextCustomOptions:any[]=[{'cssName':'', 'textName':'Text Top', 'value':'TOP'}, {'cssName':'', 'textName':'Text Bottom', 'value':'BOTTOM'}]
  qrTextBorderRequired:any[]=[{'csName':'', 'textName':'Yes', 'value':true}, {'csName':'', 'textName':'No', 'value':false}]
  public colorControl = new ColorPickerControl()
                          .setValueFrom('rgba(0, 0, 0, 1)');
  public qrColor = new ColorPickerControl()
                          .setValueFrom('rgba(0, 0, 0, 1)');
  public qrBgColor = new ColorPickerControl()
                          .setValueFrom('rgba(255, 255, 255, 1)');
  public logoBorderColor = new ColorPickerControl()
                          .setValueFrom('rgba(0, 0, 0, 1)');  
  public qrTextBorderColor = new ColorPickerControl()
                          .setValueFrom('rgba(0, 0, 0, 1)');                        

  constructor(public QRCodeServ:QRCodeService,public modalService: BsModalService,public commonService:CommonService,
    private notificationServ:NotificationService) { }

  ngOnInit(): void {
    
    this.actionType = this.list[0]?.action;
    if(this.actionType=='Analytics'){
      this.QRCodeServ.getQRAnalyticsInfo(this.list[0].id).subscribe((res : any) => {  
       
       var oslbls=res.map(x=>x.Os)
       this.osLabels = [...new Set(oslbls.map(item => item))];
       this.osLabels.forEach(element => {
        var count= res.filter(x=>x.Os==element)?.length
        this.osData.push(count);
       });

       var bwrlbls=res.filter(x=>x.IsMobileDevice==false).map(x=>x.Browser)      
       this.browserLabels = [...new Set(bwrlbls.map(item => item))];
       this.browserLabels.forEach(element => {
        var bwrcount= res.filter(x=>x.Browser==element)?.length
        this.browserData.push(bwrcount);
       });

       var aolbls=res.map(x=> x.CreatedOn.split(' ')[0].split('-')[2])
       this.actiononLabels = [...new Set(aolbls.map( item => item))];
       this.actiononLabels.forEach(element => {
        var aocount= res.filter(x=>x.CreatedOn.split(' ')[0].split('-')[2]==element)?.length
        this.actiononData.push(aocount);
       });

       var ismobileLabels=res.map(x=>x?.IsMobileDevice==true?'Mobile':'Web')
       this.ismobileLabels = [...new Set(ismobileLabels.map(item => item))];
       this.ismobileLabels.forEach(element => {
        if(element=='Mobile'){
          var ismobcount= res.filter(x=>x?.IsMobileDevice==true)?.length
          this.ismobileData.push(ismobcount);
        }else{
          var ismobcount= res.filter(x=>x?.IsMobileDevice==false)?.length
          this.ismobileData.push(ismobcount);
        }
        
       });

       

       this.createOSChart();
       this.createActionOnChart();
       this.createIsMobileChart();
       this.createBrowserChart();
      });

    }else{
      this.getQRCodeView(this.list[0].id);
      this.commonService.getqrtypelist().subscribe((res : any) => {      
        this.commonService.QRTypesdata = res;     
        this.qrtypelist = res;
        this.frameOptionSelectedStyle="";    
      });
    }
   
   
  }
//   onOptionsSelected(event) { 
//     console.log('hi');
//     this.selected_type = event.target.value; 
//     console.log( event.target.value);
// } 
padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

 formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      this.padTo2Digits(date.getHours()),
      this.padTo2Digits(date.getMinutes()),
      this.padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}
  getQRCodeView(id:number){

    // this.commonService.getQRCodeRedirectionUrl('084047fb-6f67-49bc-a371-f41b27f417c6').subscribe((res:any)=>{
    //   console.log("pwd str", res);
    // })

    this.QRCodeServ.getQRCodeView(id).subscribe((res:any)=>{

      if(res?.QRCodeInfo.QrTypeId=="1"){
        this.URL=res?.QRCodeInfo?.RedirectUrl
        //this.URL

      }else if(res?.QRCodeInfo.QrTypeId=="2"){
        console.log("QRDATA",res?.QRCodeInfo?.RedirectUrl.split(';'));
        var urldata:any[]= res?.QRCodeInfo?.RedirectUrl.split(';');
        if(urldata.length>0){
          this.WIFIName= urldata[0].split(':')[2];
          this.WIFISecurity= urldata[1].split(':')[1];
          this.WIFIPassword= urldata[2].split(':')[1];       
        }
      }
     
       this.QRCodeServ.formData = res;

    });
  }
  saveQRCode(){  

    if(this.QRCodeServ.formData.QRCodeInfo.QrTypeId == 1){
       this.QRCodeServ.formData.QRCodeInfo.RedirectUrl = this.URL; 
       var result= this.validteUrl2(this.URL);
       if(!result)
       {
         this.isUrlInvalid='errorborder'
         this.notificationServ.ShowError("Invalid URL format");
         return;
       }
       this.isUrlInvalid='';
    }
    else if(this.QRCodeServ.formData.QRCodeInfo.QrTypeId == 2)
    { 
      this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WIFIName}", this.WIFIName);
      this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WIFIPassword}", this.WIFIPassword);
      this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WPA}", this.WIFISecurity);
    
      this.QRCodeServ.formData.QRCodeInfo.RedirectUrl = this.WIFIRedirecctUrl; 
    }
   
    this.QRCodeServ.formData.QRCodeInfo.IsActive = true;  
    this.QRCodeServ.formData.QRCodeInfo.QRText= this.qrText;
    this.QRCodeServ.formData.QRCodeInfo.QRTextDisplayPosition=this.qrTextDisplayPostion;
    this.QRCodeServ.formData.QRCodeInfo.QRTextColor=this.colorControl.value.toHexString();
    this.QRCodeServ.formData.QRCodeInfo.QRColor=this.qrColor.value.toHexString();
    this.QRCodeServ.formData.QRCodeInfo.QRBackgroundColor=this.qrBgColor.value.toHexString();
    this.QRCodeServ.formData.QRCodeInfo.QRLogoBorderColor=this.logoBorderColor.value.toHexString();
    this.QRCodeServ.formData.QRCodeInfo.QRLogoBorderSize=this.logoBorder
    this.QRCodeServ.formData.QRCodeInfo.QRLogo=this.qrLogoImagePreview;
    this.QRCodeServ.formData.QRCodeInfo.QRTextBorderRequired=this.isQRBorderRequired;
    this.QRCodeServ.formData.QRCodeInfo.QRTextBorderColor=this.qrTextBorderColor.value.toHexString();

    this.QRCodeServ.saveQRCodeView().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      }else{
        this.notificationServ.ShowError(res.ErrorMessage)
      }
    })
  }

  previewQRCode(){
   

      if(this.QRCodeServ.formData.QRCodeInfo.QrTypeId == 1){
          this.QRCodeServ.formData.QRCodeInfo.RedirectUrl = this.URL;  
          var result= this.validteUrl2(this.URL)
          console.log("b", result);
          if((!result))
          {
            this.isUrlInvalid='errorborder'
            this.notificationServ.ShowError("Invalid URL format");
            return;
          }
          this.isUrlInvalid='';
      }
      else if(this.QRCodeServ.formData.QRCodeInfo.QrTypeId == 2)
      { 
        this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WIFIName}", this.WIFIName);
        this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WIFIPassword}", this.WIFIPassword);
        this.WIFIRedirecctUrl = this.WIFIRedirecctUrl.replace("${WPA}", this.WIFISecurity);
      
        this.QRCodeServ.formData.QRCodeInfo.RedirectUrl = this.WIFIRedirecctUrl;  
      }
      
      this.QRCodeServ.formData.QRCodeInfo.IsActive = true;  
      this.QRCodeServ.formData.QRCodeInfo.QRText= this.qrText;
      this.QRCodeServ.formData.QRCodeInfo.QRTextDisplayPosition=this.qrTextDisplayPostion;
      this.QRCodeServ.formData.QRCodeInfo.QRTextColor=this.colorControl.value.toHexString();
      this.QRCodeServ.formData.QRCodeInfo.QRColor=this.qrColor.value.toHexString();
      this.QRCodeServ.formData.QRCodeInfo.QRBackgroundColor=this.qrBgColor.value.toHexString();
      this.QRCodeServ.formData.QRCodeInfo.QRLogoBorderColor=this.logoBorderColor.value.toHexString();
      this.QRCodeServ.formData.QRCodeInfo.QRLogoBorderSize=this.logoBorder
      this.QRCodeServ.formData.QRCodeInfo.QRLogo=this.qrLogoImagePreview;
      this.QRCodeServ.formData.QRCodeInfo.QRTextBorderRequired=this.isQRBorderRequired;
      this.QRCodeServ.formData.QRCodeInfo.QRTextBorderColor=this.qrTextBorderColor.value.toHexString();

        this.QRCodeServ.previewQRCode().subscribe((res:string)=>{
          this.qrCodePreviewData= res;
        })
      
  }

  onFrameClick(){
    this.isFrameOptionsVisible=!this.isFrameOptionsVisible;
  }

  onColorPickerChange(){    
    this.isColorOptionVisible=!this.isColorOptionVisible;
  }

  onLogoClick(){
    console.log(this.colorControl.value.toRgbString())
    this.isLogoOptionVisible=!this.isLogoOptionVisible
  }

  setQRTextStyle(values: any):void{
    console.log(values.currentTarget.value)
  }

  onFrameSelect(value:any):void{
    console.log(value)
    this.qrTextCustomOptions.forEach(element => {
      if(element.textName==value.textName){
        this.qrTextDisplayPostion= value.value;
        element.cssName ='qrcustomoptions'
      }else{
        element.cssName =''
      }
    });    
  }

  onQRTextBorderSelect(value:any):void{
    this.qrTextBorderRequired.forEach(element => {
      if(element.textName==value.textName){
        this.isQRBorderRequired= value.value;
        element.cssName ='qrcustomoptions'
      }else{
        element.cssName =''
      }
    });    
  }

  processFile(imageInput: any) {
    console.log(imageInput);
    const file: File = imageInput.target.files[0];
    this.qrLogoImagePreview=  imageInput.target.result.toString().split('base64,')[1]; ;
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.QRCodeServ.uploadImage(this.selectedFile.file).subscribe(
        (res) => {        
        },
        (err) => {        
        })
    });

    reader.readAsDataURL(file);
  }

  FileSelected(files){
    let fileData = <File>files[0];
    let FileName = fileData.name;
    let reader = new FileReader();
    var selectedFile = <File>files[0];

    reader.onload = function (readerEvt: any) {
        var arrayBuffer = readerEvt.target.result.toString().split('base64,')[1]; 
        this.qrLogoImagePreview= arrayBuffer
        console.log(this.qrCodePreviewData);
    }.bind(this);
    reader.readAsDataURL(selectedFile);

  }

  validteUrl(url:string){
    const reg = '/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g';
    var result=  url.match(reg);// Validators.pattern()  
    
  }

validteUrl2(userInput) {
  var res1 = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  var res = userInput.match(/^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)  
  if(res == null)
      return false;
  else
      return true;
}

  createOSChart() {
    this.oschart = new Chart("OSChart", {
      type: 'pie', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.osLabels,
        datasets: [{
          label: 'Scan Count',
          data: this.osData,         
          hoverOffset: 4
        }],
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }

  createActionOnChart() {
    this.actiononchart = new Chart("ActionOnChart", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.actiononLabels,
        datasets: [{
          label: 'Scan Count',
          data: this.actiononData,         
          //hoverOffset: 4
        }],
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }

  createIsMobileChart() {
    this.ismobilechart = new Chart("IsMobileChart", {
      type: 'pie', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.ismobileLabels,
        datasets: [{
          label: 'Scan Count',
          data: this.ismobileData,         
          hoverOffset: 4
        }],
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }

  createBrowserChart() {
    this.browserchart = new Chart("BrowserChart", {
      type: 'pie', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.browserLabels,
        datasets: [{
          label: 'Scan Count',
          data: this.browserData,         
          hoverOffset: 4
        }],
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }

}
