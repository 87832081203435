import { Component, OnInit } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    standalone: true,
    imports: [SidebarComponent]
})
export class TestComponent implements OnInit {

  youtubeUrl = 'https://www.youtube.com/watch?v=WsQD0quPFRY';
  enbedUrl :any;
  constructor(private embedService: EmbedVideoService){
    this.enbedUrl = this.embedService.embed(this.youtubeUrl,
      {
        query: { portrait: 0, color: '333' },
        attr: { style:'width:100%;height:100%'},
      });
  }

  ngOnInit(): void {
    
  }
  title = 'school';
  
  
}
