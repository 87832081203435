<div class="user-form d-flex align-items-center">
    <div class="container-fluid">
      <div class="row justify-content-c m-0">
        <div class="col-md-12 p-0">
          <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">
  
  
  
            <div class="modal-content">
              <div class="modal-header bg-theam p-1">
                <h3 class="modal-title h3 m-1"> Fee Info</h3>
              </div>
              <div class="modal-body p-2">
                <form novalidate #form="ngForm" autocomplete="off">
                    <div class="row form-row m-0">
                        <div class="form-group col-12 p-0 pr-1 mb-2 p-0 pr-1 mb-0 border border-secondary">
                            <div class="row form-row m-0 pt-0">
                                
                              <div class="form-group col-4 mb-0">
                                <label>Selected Child</label>
                                <select class="form-control form-control-sm appearance-auto" name="selectedChild" [(ngModel)]="selectedChileId" (change)="changeChild()">
                                  <option *ngFor="let child of childrenList" [value]="child.Id">{{child.Name}}</option>
                                  <option [value]="0">Link a child to my account</option>
                                </select>
                              </div>
<!--                              
                              <div class="form-group col-4 mb-0" *ngIf="userService.formData.UserInfo.UserId > 0">
                                
                              </div>
                              <div class="form-group col-4 mb-0">
                                <label>&nbsp;</label>
                                <div class="form-control form-control-sm border-0 p-0">
                                    <button type="button" (click)="searchUserWithSharedId()" class="btn btn-warning float-right btn-sm" type="button">Search</button>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        <table class="border-left border-right border-secondary w-auto">
                            <thead>
                             <tr class="border-bottom border-top border-secondary">
                                 <th class="border-right border-secondary">Name</th>
                                 <th class="border-right border-secondary">Description</th>
                                 <th>Amount</th>
                             </tr>
                            </thead>
                            <tbody>
                             <tr class="border-bottom border-secondary" *ngFor="let feeinfo of feeInfo">
                                 <td class="border-right border-secondary">{{feeinfo.Text}}</td>
                                 <td class="border-right border-secondary">{{feeinfo.Description}}</td>
                                 <td>{{feeinfo.Amount}}</td>
                             </tr>
                            </tbody>
                         </table>
                    </div>
                </form>
              </div>
              <div class="modal-footer p-1">
                <!-- <button type="button" class="btn btn-warning" (click)="searchUserWithSharedId()" *ngIf="service.formData.UserInfo.UserId <= 0">Search</button> -->
                <button type="button" class="btn btn-success" (click)="Paynow()" [disabled]="stdFeeInfo.StudentFeeMasterInfo.Total <= 0">Pay Now</button>
                
              </div>
            </div>
  
          </div>
  
        </div>
      </div>
    </div>
  
  </div>