<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" >{{'Assign' | translate}}</h3>
        </div>
        <div class="modal-body p-2">
            <div class="row justify-content-c m-0">
                <div class="col-md-12 p-0">
                        <ag-grid-angular #agGrid
                        style="width: 100%; height:calc(90vh - 80px)"
                        class="ag-theme-alpine"
                        id="myGrid"
                        [getRowNodeId]="getRowNodeId"
                        [gridOptions]="gridOptions"
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        [rowSelection]="rowSelection"
                        [rowData]="rowData"
                        (gridReady)="onGridReady($event)"
                        [frameworkComponents]="frameworkComponents"
                        >
                        </ag-grid-angular>
                </div>
            </div>
        </div>
        <div class="modal-footer p-1">
            <button class="btn btn-success text-white mlr-4px" (click)="assignCass()" >Save</button>
            <button class="btn btn-danger text-white mlr-4px" (click)="bsModalRef.hide()" aria-label="Close">Cancel</button>
        </div>
    </div>
</div>

