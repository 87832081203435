import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../shared/notification.service';
import { CUSTOMCONST } from '../shared/Const';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-class',
    templateUrl: './edit-class.component.html',
    styleUrls: ['./edit-class.component.scss'],
    standalone: true,
    imports: [FormsModule, TranslateModule]
})
export class EditClassComponent implements OnInit {
  list:any[];
  bsModalRef: BsModalRef;
  class:any[];
  className:string;
  CUSTOMCONST:any=CUSTOMCONST;
  constructor(public commonServ:CommonService,public modalService: BsModalService,private notificationServ:NotificationService) { }

  ngOnInit(): void {
    this.getclasses();
  }
  getclasses(){
    this.commonServ.getclasses().subscribe((res:any)=>{
      console.log(res);
      this.class = res;
      this.class.forEach((element, index) => {
        if (element.Id == this.list[0].id) {
          console.log("element");
          console.log(element);
          this.className = element.Name;
         
  
        }
      });
    });
  }
  addMlInfo(){
    this.commonServ.udpatemlinfo("ClassData",this.list[0].id,this.className).subscribe((res:any) =>{
      if(res.ErrorMessage){
        //
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        //display added message.
        this.notificationServ.ShowInfo('Item update successfully');
        this.commonServ.CallOnRefreshGrid();
        // this.bsModalRef.hide();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        
      }
    });
  }
}
