

<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >Analytics Questions for Student</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0 p-2">
                    <div class="row border-bottom m-0 mb-2 justify-content-between p-0" *ngFor="let rowdata of rowData; let rowindex=index">
                        <div class="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 px-2">
                            <div class="d-flex">
                                <div class="pr-2"><h4>Q No. {{rowindex+1}})</h4></div>
                                <div>{{rowdata.Question}}</div>
                            </div>
                        </div>
                       
                        <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 px-2">
                            <div class="form-group mb-0">
                                <!-- <label>Score</label> -->
                                
                                <select class="form-control form-control-sm appearance-auto" name="Score" 
                                [(ngModel)]="rowdata.Score"  required>
                                    <option [value]="0">Select Score</option>
                                    <option *ngFor="let score of scoreList[rowindex]; let i=index"  [value]="score.Id">
                                    {{score.Name}}
                                    </option>
                                </select>
        
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="saveStudentAnalysis()" class="btn btn-success float-right" type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
