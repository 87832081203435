import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap/modal";
import {
  LocationStrategy,
  PathLocationStrategy,
  CommonModule,
} from "@angular/common";
import { EmbedVideo } from "ngx-embed-video";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { NgxTimerModule } from "ngx-timer";
import { WebcamModule } from "ngx-webcam";

import { TokenInterceptor } from "./auth/token.interceptor";
// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
// import { MatVideoModule} from 'mat-video';

import { ContactComponent } from "./contact/contact.component";
import { HomeComponent } from "./home/home.component";
import { SmsAuthenticateComponent } from "./sms-authenticate/sms-authenticate.component";
import { UserComponent } from "./user/user.component";
import { AgGridModule } from "ag-grid-angular";
import { UsereditComponent } from "./user/useredit.component";
import { CustommenuComponent } from "./custommenu/custommenu.component";
import { CustomHeader } from "./custommenu/custom-header.component";
import { MatNativeDateModule } from "@angular/material/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { SchoolComponent } from "./school/school.component";
import { SchooleditComponent } from "./school/schooledit.component";
import { SchoolmenuComponent } from "./school/schoolmenu.component";
import { BranchComponent } from "./branch/branch.component";
import { TestComponent } from "./test/test.component";
import { EditpicComponent } from "./user/editpic.component";
import { EditOwnDocsComponent } from "./fileManagement/edit-own-docs.component";
import { ShowOwnDocsComponent } from "./fileManagement/show-own-docs.component";
//import { OwnDocumentsComponent } from './fileManagement/own-documents.component';
import { ImageCropperModule } from "ngx-image-cropper";
import { AuthGuard } from "./auth/auth.gaurd.service";
import { CarouselModule } from "ngx-owl-carousel-o";
import { CourseDetailsComponent } from "./course-details/course-details.component";
import { LifeSkillsComponent } from "./life-skills/life-skills.component";
import { SignalrService } from "./shared/signalr.service";

import { ProfileHostDirective } from "./profile/profile-host.directive";
import { ProfileComponent } from "./profile/profile.component";
import { LoginReducer, InitialState, initialState } from "./store/reducer";
import { NgReduxModule, NgRedux } from "@angular-redux/store";
import { AdminComponent } from "./admin/admin.component";
import { DasboardComponent } from "./admin/dasboard/dasboard.component";
import { QuestionComponent } from "./question/question.component";
import { QuestioneditComponent } from "./question/questionedit.component";
import { ValueEducationComponent } from "./course-details/value-education/value-education.component";
import { AddmlinfoComponent } from "./question/addmlinfo/addmlinfo.component";
import { StuDashboardComponent } from "./student/stu-dashboard/stu-dashboard.component";
import { CountdownModule } from "ngx-countdown";
import { QuezComponent } from "./question/quez.component";
import { QuestionmenuComponent } from "./question/questionmenu/questionmenu.component";
import { AssignClassesComponent } from "./question/assign-classes/assign-classes.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CustomGrideCelComponent } from "./custommenu/custom-gride-cel/custom-gride-cel.component";
//import { TimepickerModule } from 'ngx-bootstrap/timepicker';
//import { PopoverModule } from 'ngx-bootstrap/popover';
import { PopoverModule, PopoverConfig } from "ngx-bootstrap/popover";
import { CustomControlsComponent } from "./custommenu/custom-controls/custom-controls.component";
import { TeacherDashboardComponent } from "./teacher/teacher-dashboard/teacher-dashboard.component";
import { CreateAssignmentComponent } from "./assignment/create-assignment.component";
import { EditassignmentComponent } from "./assignment/editassignment.component";
import { ViewdocumentComponent } from "./assignment/viewdocument.component";
import { EventComponent } from "./event/event.component";
import { EditeventComponent } from "./event/editevent.component";
import { EditbannerComponent } from "./school/editbanner.component";
import { StudentAnswerlistComponent } from "./assignment/student-answerlist.component";
import { StudentAnswerpreviewComponent } from "./assignment/student-answerpreview.component";
import { PaymentComponent } from "./payment/payment.component";
import {
  Ng7LargeFilesUploadLibModule,
  Ng7LargeFilesUploadLibComponent,
} from "ng7-large-files-upload-lib";
import { ChatComponent } from "./chat/chat.component";
import { EditchatComponent } from "./chat/editchat.component";
import { ChatboxComponent } from "./chat/chatbox.component";
import { ViewchatComponent } from "./chat/viewchat.component";
import { StuanswerlistComponent } from "./question/stuanswerlist.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { CustomTooltipComponent } from "./custom-tooltip/custom-tooltip.component";
import { BenefitsComponent } from "./benefits/benefits.component";
import { FeaturesComponent } from "./features/features.component";
import { FaqComponent } from "./faq/faq.component";
import { MultieventComponent } from "./event/multievent.component";
import { TimeEditorComponent } from "./custommenu/time-editor/time-editor.component";
import { ParentDashboardComponent } from "./parent/parent-dashboard.component";
import { ConfirmComponent } from "./common/confirm.component";
import { UnusedfilesComponent } from "./school/unusedfiles.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { AttendenceComponent } from "./attendance/attendance.component";
import { FileManagementComponent } from "./school/file-management.component";
import { EditprofileComponent } from "./user/editprofile.component";
import { AboutComponent } from "./about/about.component";
import { PartnersComponent } from "./partners/partners.component";
import { AdmindashboardComponent } from "./admin/admindashboard/admindashboard.component";
import { ViewChildResultComponent } from "./parent/view-child-result.component";
import { FeedbacklistComponent } from "./feedback/feedbacklist.component";
import { EditFeedbackComponent } from "./feedback/edit-feedback.component";
import { UserListComponent } from "./user/user-list.component";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

import { GoogleChartsModule } from "angular-google-charts";
import { StudentFeedbacklistComponent } from "./feedback/student-feedbacklist.component";
import { SurveyComponent } from "./survey/survey.component";
import { SurveyResultComponent } from "./survey/survey-result.component";
import { EditsurveyComponent } from "./survey/editsurvey.component";
import { SaveSurveyResultComponent } from "./survey/save-survey-result.component";

import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
// import { faStar, faStarHalfAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";

import { BarRatingModule } from "ngx-bar-rating";
import { GridImageComponent } from "./grid-image/grid-image.component";
import { HelpComponent } from "./common/help.component";
import { PasswordResetComponent } from "./login/password-reset.component";
import { AnalysisComponent } from "./analysis/analysis.component";
import { AnalysisesComponent } from "./analysis/analysises.component";
import { AnalysisQuestionairComponent } from "./analysis/analysis-questionair.component";
import { AnalysisStudentsComponent } from "./analysis/analysis-students.component";
import { AnalysisStudentComponent } from "./analysis/analysis-student.component";
import { StudAnalysisQuestionComponent } from "./analysis/stud-analysis-question.component";
import { StuAnalysisResultsComponent } from "./analysis/stu-analysis-results.component";
import { OwnDocumentsComponent } from "./fileManagement/own-documents.component";
import { SelfRegistrationComponent } from "./user/self-registration.component";
import { UserAcademicYearLogComponent } from "./academic/user-academic-year-log.component";
import { SearchUserSharedIdComponent } from "./user/search-user-shared-id.component";
import { BnNgIdleService } from "bn-ng-idle";
import { UserLogInfoComponent } from "./user/user-log-info.component";
import { PaymentsComponent } from "./payments/payments.component";
import { TermComponent } from "./term/term.component";
import { EdittermComponent } from "./term/editterm.component";
import { EditPaymentsComponent } from "./payments/edit-payments.component";
import { StuOfferComponent } from "./student/stu-offer.component";
import { UserPaymentfeeInfoComponent } from "./payments/user-paymentfee-info.component";
import { EditChildComponent } from "./parent/edit-child.component";
import { ParentNavComponent } from "./navbar/parent-nav.component";
// import { EditOwnDocsComponent } from './fileManagement/edit-own-docs.component';
// import { ShowOwnDocsComponent } from './fileManagement/show-own-docs.component';
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { HomeWorkComponent } from "./parent/child/home-work.component";
import { ResultComponent } from "./parent/child/result.component";
import { UserFeeInfoComponent } from "./payments/user-fee-info.component";
import { RegistrationComponent } from "./student/registration.component";
import { StudentNavComponent } from "./navbar/student-nav.component";

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "@abacritt/angularx-social-login";
import { ChildMenuComponent } from "./parent/child-menu/child-menu.component";
import { ShareAndEarnComponent } from "./share-and-earn/share-and-earn.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { AttendanceCalendarComponent } from "./parent/attendance-calendar.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ParentCalendarComponent } from "./parent/parent-calendar.component";
import { AttandanceCalComponent } from "./calendar/attandance-cal.component";
import { EditCalAppointmentComponent } from "./calendar/edit-cal-appointment.component";
// Note we need a separate function as it's required
// by the AOT compiler.
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { TakeAttendanceComponent } from "./attendance/take-attendance.component";
import { ContactListComponent } from "./registers/contact-list.component";
import { PartnerListComponent } from "./registers/partner-list.component";
import { RegistrationListComponent } from "./registers/registration-list.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TeacherSidebarComponent } from "./sidebar/teacher-sidebar.component";
import { AdminSidebarComponent } from "./sidebar/admin-sidebar.component";
import { ParentSidebarComponent } from "./sidebar/parent-sidebar.component";
import { StudentSidebarComponent } from "./sidebar/student-sidebar.component";
import { SuperadminSidebarComponent } from "./sidebar/superadmin-sidebar.component";
import { CordinatorSidebarComponent } from "./sidebar/cordinator-sidebar.component";
import { TeacherListComponent } from "./teacher-list/teacher-list.component";
import { StudentListComponent } from "./student-list/student-list.component";
import { ClassListComponent } from "./class-list/class-list.component";
import { SubjectListComponent } from "./subject-list/subject-list.component";
import { InstituteLogoComponent } from "./scgool/institute-logo.component";
import { GeneralDetailsComponent } from "./school/general-details.component";
import { LeaveComponent } from "./leave/leave.component";
import { TeacherLeaveComponent } from "./leave/teacher-leave/teacher-leave.component";
import { LeaveRequestComponent } from "./leave/leave-request/leave-request.component";
import { TeacherComponent } from "./leave/admin/teacher/teacher.component";
import { StudentComponent } from "./leave/admin/student/student.component";
import { ApproveLeaveComponent } from "./leave/admin/approve-leave/approve-leave.component";
import { TimeTableComponent } from "./timetable/time-table/time-table.component";
import { ClasstimetableComponent } from "./classtimetable/classtimetable.component";
import { EditTimeTableComponent } from "./timetable/edit-time-table/edit-time-table.component";
import { TimeTablesComponent } from "./timetable/teacher/time-tables/time-tables.component";
import { StudentDairyComponent } from "./dairy/student-dairy/student-dairy.component";
import { ClassDairyComponent } from "./dairy/class-dairy/class-dairy.component";
import { EditclassDairyComponent } from "./dairy/editclass-dairy/editclass-dairy.component";
import { EditstudebtDairyComponent } from "./dairy/editstudebt-dairy/editstudebt-dairy.component";
import { SchoolSharedDocComponent } from "./fileManagement/school-shared-doc.component";
import { EditASSDocComponent } from "./fileManagement/edit-assdoc.component";
import { EditClassComponent } from "./class-list/edit-class.component";
import { SchoolSettingComponent } from "./schoolsetting/school-setting.component";
import { QRCodeListComponent } from "./QRCode/qrcode-list.component";
import { EditQRCodeComponent } from "./QRCode/edit-qrcode.component";
import { AchievementsComponent } from "./achievements/achievements.component";
import { UploadBookComponent } from "./achievements/upload-book.component";
import { BookDescriptionComponent } from "./achievements/book-description.component";
import { QRCodeViewComponent } from "./QRCode/qrcode-view.component";
import { QrcoderedirectComponent } from "./QRCode/qrcoderedirect.component";
import { EditVisitingPlaceComponent } from "./achievements/edit-visiting-place.component";
import { EditMyhobbyComponent } from "./achievements/edit-myhobby.component";
import { EditMyachievementComponent } from "./achievements/edit-myachievement.component";
import { DescVisitingPlaceComponent } from "./achievements/desc-visiting-place.component";
import { DescMyhobbyComponent } from "./achievements/desc-myhobby.component";
import { DescMyachievementComponent } from "./achievements/desc-myachievement.component";
import { EditProfileComponent } from "./student-profile/edit-profile.component";
import { EditEmpathyComponent } from "./student-profile/edit-empathy.component";
import { ProfileTestPageComponent } from "./student-profile/profile-test-page/profile-test-page.component";
import { ParentShareDocComponent } from "./fileManagement/parent-share-doc.component";
import { EditApsdocComponent } from "./fileManagement/edit-apsdoc.component";
import { ParentsettingComponent } from "./parentsetting/parentsetting.component";
import { BadgeComponent } from "./badge/badge.component";
import { EditBadgeComponent } from "./badge/edit-badge.component";
import { BadgeListComponent } from "./badge/badge-list.component";
import { SidemenuSettingComponent } from "./user/sidemenu-setting/sidemenu-setting.component";
import { SupportComponent } from "./about/support.component";
import { TermsOfServiceComponent } from "./about/terms-of-service.component";
import { PrivacyPolicyComponent } from "./about/privacy-policy.component";
// import { CancellationComponent } from './about/cancellation.component';
import { PaymentsuccessComponent } from "./paymentsuccess/paymentsuccess.component";
import { PaymentcancelComponent } from "./paymentcancel/paymentcancel.component";
import { CancellationComponent } from "./cancellation/cancellation.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { NewAssesmentsComponent } from "./new-assesments/new-assesments.component";
import { StudentAssignmentsComponent } from "./assignment/student-assignments.component";
import { MyProfileComponent } from "./student-profile/my-profile/my-profile.component";
import { ContentProviderSidebarComponent } from "./sidebar/content-provider-sidebar.component";
import { CompleteAssessmentComponent } from "./complete-assessment/complete-assessment.component";
import { ReadMoreComponent } from "./feedback/read-more.component";
import { CountdownComponent } from "./countdown/countdown.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ThinkComponent } from "./assignment/think.component";
import { ColorPickerModule } from "@iplab/ngx-color-picker";

import { NgForm } from "@angular/forms";

export function playerFactory() {
  return player;
}

@NgModule()
/* TODO(standalone-migration): clean up removed NgModule class manually.
{
    declarations: [AppComponent],
    imports: [
        // LottieModule.forRoot({ player: playerFactory }),
        FullCalendarModule,
        CommonModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatToolbarModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatRadioModule,
        MatTableModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        NgReduxModule,
        PdfViewerModule,
        BsDatepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        //  PopoverModule.forRoot(),
        DragDropModule,
        ResizableModule,
        ColorPickerModule,
        PopoverModule.forRoot(),
        TimepickerModule.forRoot(),
        //DatetimePopupModule.forRoot(),
        AgGridModule.withComponents([]),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateFactory,
                deps: [HttpClient]
            },
        }),
        ImageCropperModule,
        CarouselModule,
        MatTabsModule,
        CountdownModule,
        AngularEditorModule,
        EmbedVideo.forRoot(),
        YouTubePlayerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        // MatVideoModule
        NgxTimerModule,
        NgxDocViewerModule,
        Ng7LargeFilesUploadLibModule,
        Ng7LargeFilesUploadLibModule.forRoot('https://api.hybridlearning.in/api/Home/FileUpload'),
        // 'https://api.hybridlearning.in/api/Home/FileUpload'
        // http://185.103.120.94:9090/SAPI/Home/FileUpload
        LottieModule.forRoot({ player: playerFactory }),
        GoogleChartsModule,
        FontAwesomeModule,
        BarRatingModule,
        WebcamModule,
        SocialLoginModule,
        NgApexchartsModule,
        NgbModule,
        LoginComponent,
        RegisterComponent,
        ContactComponent,
        HomeComponent,
        SmsAuthenticateComponent,
        UserComponent,
        UsereditComponent,
        CustommenuComponent,
        CustomHeader,
        SchoolComponent,
        SchooleditComponent,
        SchoolmenuComponent,
        BranchComponent,
        TestComponent,
        EditpicComponent,
        CourseDetailsComponent,
        LifeSkillsComponent,
        ProfileHostDirective,
        ProfileComponent, AdminComponent,
        DasboardComponent, QuestionComponent,
        QuestioneditComponent, ValueEducationComponent, AddmlinfoComponent,
        StuDashboardComponent, QuezComponent, QuestionmenuComponent, AssignClassesComponent,
        CustomGrideCelComponent, CustomControlsComponent, TeacherDashboardComponent,
        CreateAssignmentComponent, EditassignmentComponent, EditOwnDocsComponent,
        ShowOwnDocsComponent, ViewdocumentComponent, EventComponent, EditeventComponent,
        EditbannerComponent, StudentAnswerlistComponent, StudentAnswerpreviewComponent,
        ChatComponent, EditchatComponent, ChatboxComponent, ViewchatComponent,
        StuanswerlistComponent, CalendarComponent, CustomTooltipComponent, BenefitsComponent,
        FeaturesComponent, FaqComponent, MultieventComponent, TimeEditorComponent,
        ParentDashboardComponent, ConfirmComponent, UnusedfilesComponent, AttendenceComponent,
        FileManagementComponent, EditprofileComponent,
        AboutComponent,
        SupportComponent,
        TermsOfServiceComponent,
        PrivacyPolicyComponent,
        CancellationComponent,
        PartnersComponent,
        AdmindashboardComponent, ViewChildResultComponent, FeedbacklistComponent,
        EditFeedbackComponent, UserListComponent, StudentFeedbacklistComponent,
        SurveyComponent, SurveyResultComponent, EditsurveyComponent, SaveSurveyResultComponent,
        PaymentComponent,
        GridImageComponent, HelpComponent, PasswordResetComponent,
        AnalysisComponent, AnalysisesComponent, AnalysisQuestionairComponent,
        AnalysisStudentsComponent,
        AnalysisStudentComponent,
        StudAnalysisQuestionComponent,
        StuAnalysisResultsComponent,
        OwnDocumentsComponent,
        EditOwnDocsComponent,
        ShowOwnDocsComponent,
        SelfRegistrationComponent,
        UserAcademicYearLogComponent,
        SearchUserSharedIdComponent,
        UserLogInfoComponent,
        PaymentsComponent,
        TermComponent,
        EdittermComponent,
        EditPaymentsComponent,
        StuOfferComponent,
        UserPaymentfeeInfoComponent,
        EditChildComponent,
        ParentNavComponent,
        HomeWorkComponent,
        ResultComponent,
        UserFeeInfoComponent,
        RegistrationComponent,
        StudentNavComponent,
        ChildMenuComponent,
        ShareAndEarnComponent,
        AttendanceCalendarComponent,
        ParentCalendarComponent,
        AttandanceCalComponent,
        EditCalAppointmentComponent,
        TakeAttendanceComponent,
        ContactListComponent,
        PartnerListComponent,
        RegistrationListComponent,
        SidebarComponent,
        TeacherSidebarComponent,
        AdminSidebarComponent,
        ParentSidebarComponent,
        StudentSidebarComponent,
        SuperadminSidebarComponent,
        CordinatorSidebarComponent,
        TeacherListComponent,
        StudentListComponent,
        ClassListComponent,
        SubjectListComponent,
        InstituteLogoComponent,
        GeneralDetailsComponent,
        LeaveComponent,
        TeacherLeaveComponent,
        LeaveRequestComponent,
        TeacherComponent,
        StudentComponent,
        ApproveLeaveComponent,
        TimeTableComponent,
        EditTimeTableComponent,
        TimeTablesComponent,
        StudentDairyComponent,
        ClassDairyComponent,
        EditclassDairyComponent,
        EditstudebtDairyComponent,
        SchoolSharedDocComponent,
        EditASSDocComponent,
        EditClassComponent,
        SchoolSettingComponent,
        QRCodeListComponent,
        EditQRCodeComponent,
        AchievementsComponent,
        UploadBookComponent,
        BookDescriptionComponent,
        QRCodeViewComponent,
        QrcoderedirectComponent,
        EditVisitingPlaceComponent,
        EditMyhobbyComponent,
        EditMyachievementComponent,
        DescVisitingPlaceComponent,
        DescMyhobbyComponent,
        DescMyachievementComponent,
        EditProfileComponent,
        ProfileTestPageComponent,
        ParentShareDocComponent,
        EditApsdocComponent,
        ParentsettingComponent,
        BadgeComponent,
        EditBadgeComponent,
        BadgeListComponent,
        SidemenuSettingComponent,
        PaymentsuccessComponent,
        PaymentcancelComponent,
        NewAssesmentsComponent,
        StudentAssignmentsComponent,
        MyProfileComponent,
        ContentProviderSidebarComponent,
        CompleteAssessmentComponent,
        ReadMoreComponent,
        CountdownComponent,
        ThinkComponent
    ],
    exports: [
        Ng7LargeFilesUploadLibComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor, multi: true
        },
        AuthGuard, SignalrService, BnNgIdleService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com')
                    },
                    // 1043793751452-kbjvih3ts3e48k90hq7e9spd92t1fla8.apps.googleusercontent.com
                    // 115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com
                    // {
                    //   id: FacebookLoginProvider.PROVIDER_ID,
                    //   provider: new FacebookLoginProvider('clientId')
                    // }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
} */
export class AppModule {
  constructor(ngRedux: NgRedux<InitialState>, library: FaIconLibrary) {
    ngRedux.configureStore(LoginReducer, initialState);
    // library.addIcons(faStar, faStarHalfAlt, farStar, faTimesCircle);
  }
}
export function translateFactory(httpClient: HttpClient) {
  //return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}
// /*  webpack:///./node_modules/ng7-large-files-upload-lib/__ivy_ngcc__/fesm2015/ng7-large-files-upload-lib.js */
