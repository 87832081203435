export class User {
    UserId:number = 0;
    UserLogin:string;
    Password:string;
    UserName:string;
    Mobile:number;
    MobileCCId:number;
    Email:string;
    FirstName:string;
    FullName:string;
    LastName:string;
    Telephone:number;
    Description:string;
    SMobile:string;
    DateOfBirth:string;
    GenderId:number;
    RoleId:number;
    Role:string;
    BranchId:number;
    PostalNo:string;
    SMobileCCId:number;
    Address:string;
    City:string;
    State:string;
    Street:string;
    MiddleName:string;
    Surname:string;
    EnrollmentId:string;
    SchoolId:number;
    IsSaved:boolean;
    ErrorMessage:string;
    IsActive:boolean;
    CroppedFileContent:string;
    About:string;
    StudentInfo:StudentData;
    CroppedFilepath:string;
    FileInfo: FileData;
    Url:string;
    Profession:string;
    TeacherInfo:TeacherData;
    IsEditAllowed:boolean;
    ProfileFileInfo:ProfileFileData;
    CroppedProfileFilepath:string;
    ParentInfo:ParentData;
    AcademicYearId:number;
    Comments:string;
    UserShareId:string;
    IsUserShare : boolean;
    IsSearchByUserShareId:boolean;
    SchoolName:string;
    Grade:string;
    Syllabus :string;
   
}

export class UserView {
    UserInfo:User;
StudentInfo:StudentData;
ClassMappingList:Array<any>;
BatchMappingList:any[];
TeacherInfo:TeacherData;
ParentInfo:ParentData;
ResetPasswordInfo:ResetPassword;
AdminInfo:AdminData;
}
export class AdminData{
    NoOfCordinatorsAllowed:number;
    NoOfStudentsAllowed:number;
    NoOfTeachersAllowed:number;
    NoOfParentsAllowed:number;
    AdminDataId:number;
    UserId:number;
}
export class UserLogin{
    AuthToken:string;
    SessionId:string;
    ErrorMessage:string;
    Role:string;   
    FullName:string; 
    LogoPath:string;
    UserId:number;
}
export class StudentListData{
    Id: number;
    Name: string;
    Email: string;
    Mobile: string;
}
export class StudentData{
    StudentDataId:number;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    ClassId:number;
    BatchId:number;
    Class:string;
    Batch:string;
    StartDate:string;
    ExpiryDate:string;

    IsAttendPrivateTution:boolean;
    InstituteName:string;
    IsSubscribe:string;
    Motivation:string;
    Source:string;
    IsAllowPublish:boolean;
    Country:string;
}
export class TeacherData{
    TeacherDataId:number;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    StartDate:string;
    ExpiryDate:string;
}
export class ParentData{
    ParentDataId:number;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    StartDate:string;
    ExpiryDate:string;
    StudentIdList:Array<number>;
    ChildrenList:Array<any>;
}
export class FileData{
    UserDocumentId:number;
    FileName:string;
    FilePath:string;
    CroppedFilepath:string;
    CroppedContentString:string;
    OriginalContentString:string;
    FileType:string;
    FileHeader:string;
    FileSize:number;
    Description:string;
}
export class ProfileFileData{
    UserDocumentId:number;
    FileName:string;
    FilePath:string;
    CroppedFilepath:string;
    CroppedContentString:string;
    OriginalContentString:string;
    FileType:string;
    FileHeader:string;
    FileSize:number;
    Description:string;
}
export class UserMapData{
    Id:number;
    Name:string;
}
export class ResetPassword{
    Password:string;
    NewPassword:string;
    ConfirmPassword:string;
    UserId:number;
}
export class TermView{
    ErrorMessage:string;
    IsSaved: boolean;
    TermInfo: TermInfo;
}
export class TermInfo{
    TermId:number;
    Name:string;
    StartDate:string;
    EndDate:string;
    AcademicYearId:number;
    IsActive:boolean;
}
export class PaymentView{
   
    ErrorMessage:string;
    IsSaved: boolean;
    PaymentInfo:PaymentInfo;
}
export class PaymentInfo{
    PaymentId:number;
    Name:string;
    Description:string;
    Amount :number;
    AcademicYearId:number;
    TermId:number;
    ClassId:number;
    IsActive:boolean;
}
export class StdDiscountInfo{
    Amount :number;
    AcademicYearId:string;
    Discount:number;
    ErrorMessage:string;
    IsActive:boolean;
    IsSaved:boolean;
    StudentDiscountDataId:number;
    StudentId:number;
}
export class ViewInfo{
    UserInfo:User;
}

export class StudentProfileInfo{
    StudentProfileDataId:number=0;
    Name:string='';
    ProfileTypeId:number=0;
    Rating:number=0;
    IsActive:boolean=false;
    CreatedOn:string='';
    IsSaved:boolean=false;
    ErrorMessage:string='';
}

export class ProfileTypeInfo{
    Id:number;
    Name:string;
}
export class StudentProfileViewInfo{
    StudentProfileListInfo:StudentProfileInfo;
    ProfileTypeInfo:ProfileTypeInfo;
    IsSaved:boolean;
    ErrorMessage:string;
}
export class StudentEmpathyViewInfo{
    StudentEmpathyInfo:StudentEmpathyInfo[];
    IsSaved:boolean;
    ErrorMessage:string;
}
export class StudentEmpathyInfo{
    Think_Feel:String='';
    See:String='';
    Hear:String='';
    Say_Do:string='';
    Pains:String='';
    Gains:String='';
}
export class StudentProfileInfoView{
    StudentProfileInfo:StudentProfileInfo;
    EmpathyInfo : StudentEmpathyInfo;
    
}
export class BadgeViewInfo{
    BadgeInfo:BadgeInfo;
    IsSaved :boolean= false;
    ErrorMessage: string=null;
}
export class BadgeInfo {
    BadgeDataId: number;
    Name: string;
    Criteria: string;
    Issuer: string;
    IssueDate: string;
    ExpiryDate: string;
    Receipient: string;
    Comments: string;
    DocumentId: number;
    IsActive: boolean;
    UserId: number;
    UserIdList : Array<any>
}