<div class="container-fluid bg-light pb-2 page-achivement">
  <section class="self-learning-header pt-3 mb-3">
    <div class="row">
      <div class="col-xl-3 col-md-3 col-sm-3">
        <div class="card total-books">
          <div class="d-flex align-items-center h-100">
            <div class="content">
              <h1 class="card-title m-0">{{bookList.length}}</h1>
              <p class="card-text">Total Reading Books</p>
            </div>
            <div class="icon">
              <img src="../../assets/images/icons/svg/exam_gray.svg" alt="">
            </div>
          </div>
          <!-- <div class="footer d-flex align-items-center">
            <span>See Details</span>
            <img src="../../assets/images/icons/svg/right_gray.svg" alt="">
          </div> -->
        </div>              
      </div>

      <div class="col-xl-3 col-md-3 col-sm-3">
        <div class="card total-places">
          <div class="d-flex align-items-center h-100">
            <div class="content">
              <h1 class="card-title m-0">{{tarvelList.length}}</h1>
              <p class="card-text">Total Visiting Place</p>
            </div>
            <div class="icon">
              <img src="../../assets/images/icons/svg/video_gray.svg" alt="">
            </div>
          </div>
            <!-- <div class="footer d-flex align-items-center">
            <span>See Details</span>
            <img src="../../assets/images/icons/svg/right_gray.svg" alt="">
          </div> -->
        </div>              
      </div>

      <div class="col-xl-3 col-md-3 col-sm-3">
        <div class="card total-hobbies">
          <div class="d-flex align-items-center h-100">
            <div class="content">
              <h1 class="card-title m-0">{{hobbyList.length}}</h1>
              <p class="card-text">Total Hobby Practice</p>
            </div>
            <div class="icon">
              <img src="../../assets/images/icons/svg/task_gray.svg" alt="">
            </div>
          </div>
            <!-- <div class="footer d-flex align-items-center">
            <span>See Details</span>
            <img src="../../assets/images/icons/svg/right_gray.svg" alt="">
          </div> -->
        </div>              
      </div>

      <div class="col-xl-3 col-md-3 col-sm-3">
        <div class="card total-achievements">
          <div class="d-flex align-items-center h-100">
            <div class="content">
              <h1 class="card-title m-0">{{achievementList.length}}</h1>
              <p class="card-text">Total Achievements</p>
            </div>
            <div class="icon">
              <img src="../../assets/images/icons/svg/popular_man_gray.svg" alt="">
            </div>
          </div>
            <!-- <div class="footer d-flex align-items-center">
            <span>See Details</span>
            <img src="../../assets/images/icons/svg/right_gray.svg" alt="">
          </div> -->
        </div>              
      </div>
     
    </div>
  </section>
  <div class="row m-0">
    <div class="col-xl-12 col-md-12 col-sm-12 p-0">      
      <div class="bg-white shadow overflow-hidden">        
        <section class="course-details-content-section">
          <div class="container-fluid p-0">
            <mat-tab-group animationDuration="0ms" class="bg-transparent border-0"
              (selectedTabChange)="activeTabChange($event)" style="height: 80vh;">
              <mat-tab label="ReadingBooks">
                <ng-template mat-tab-label>

                  <label class="m-0">My Reading Books
                    <!-- <a (click)="openHelpTooltip('SBAssignment')" class="position-relative"><mat-icon>help_outline</mat-icon></a> -->
                  </label>
                </ng-template>
                <div class="m-2 d-flex justify-content-end">
                  <div (click)="openUploadBookModel(0)"><img src="../../assets/images/icons/svg/upload.svg" alt=""></div>
                  <!-- <button type="button" class="btn btn-success" (click)="openUploadBookModel(0)">Upload</button> -->
                </div>
                <div class="row pl-2 pr-2">
                  <div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2  mb-3" *ngFor="let book of bookList">
                    <div class="card">

                      <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        aria-label="Example icon-button with a menu" class="menuList">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="openUploadBookModel(book.StudentBookDataId)">
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item>
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                      <img class="card-img-top" [src]="book.FileInfoList!=null?book.FileInfoList[0].Result:'../../assets/images/common/homework.jpg'" alt="Card image cap">
                      <div class="card-body pt-1" (click)="openBookDescModel(book.StudentBookDataId,'book')">
                        <h3 class="card-title m-0">{{book.BookName}}</h3>
                        <p class="card-text"><small class="text-muted">{{book.Author}}</small></p>
                      </div>
                    </div>
                  </div>



                </div>
              </mat-tab>
              <mat-tab label="VisitingPlaces">
                <ng-template mat-tab-label>
                  <label class="m-0">My Visiting Places
                  </label>
                </ng-template>

                <div class="m-2 d-flex justify-content-end">
                  <div (click)="openVisitingPlaceModel(0)"><img src="../../assets/images/icons/svg/upload.svg" alt=""></div>
                  <!-- <button type="button" class="btn btn-success" (click)="openVisitingPlaceModel(0)">Upload</button> -->
                </div>
                <div class="row pl-2 pr-2">
                  <div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2  mb-3"
                    *ngFor="let tarvel of tarvelList">
                    <div class="card">

                      <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        aria-label="Example icon-button with a menu" class="menuList">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="openVisitingPlaceModel(tarvel.StudentTravelDataId)">
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item>
                          <span>Delete</span>
                        </button>
                      </mat-menu>

                      <img class="card-img-top" [src]="tarvel.FileInfoList!=null?tarvel.FileInfoList[0].Result:'../../assets/images/common/homework.jpg'" alt="Card image cap">
                      <div class="card-body pt-1" (click)="openBookDescModel(tarvel.StudentTravelDataId,'tarvel')">
                        <h3 class="card-title m-0">{{tarvel.PlaceVisited}}</h3>
                        <p class="card-text"><small class="text-muted">{{tarvel.Place}}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Hobby">
                <ng-template mat-tab-label>
                  <label class="m-0">My Hobby practicing
                  </label>
                </ng-template>
                <div class="m-2 d-flex justify-content-end">
                  <div (click)="openMyHobbyModel(0)"><img src="../../assets/images/icons/svg/upload.svg" alt=""></div>
                  <!-- <button type="button" class="btn btn-success" (click)="openMyHobbyModel(0)">Upload</button> -->
                </div>
                <div class="row pl-2 pr-2">
                  <div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2  mb-3" *ngFor="let hobby of hobbyList">
                    <div class="card">

                      <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        aria-label="Example icon-button with a menu" class="menuList">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="openMyHobbyModel(hobby.StudentHobbyDataId)">
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item>
                          <span>Delete</span>
                        </button>
                      </mat-menu>

                      <img class="card-img-top" [src]="hobby.FileInfoList!=null?hobby.FileInfoList[0].Result:'../../assets/images/common/homework.jpg'" alt="Card image cap">
                      <div class="card-body pt-1" (click)="openBookDescModel(hobby.StudentHobbyDataId, 'hobby')">
                        <h3 class="card-title m-0">{{hobby.Name}}</h3>
                        <p class="card-text"><small class="text-muted">{{hobby.EndDate}}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Achievements">
                <ng-template mat-tab-label>
                  <label class="m-0">My Achievements
                  </label>
                </ng-template>
                <div class="m-2 d-flex justify-content-end">
                  <div (click)="openMyAchievementModel(0)"><img src="../../assets/images/icons/svg/upload.svg" alt=""></div>
                  <!-- <button type="button" class="btn btn-success" (click)="openMyAchievementModel(0)">Upload</button> -->
                </div>
                <div class="row pl-2 pr-2">
                  <div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2  mb-3"
                    *ngFor="let achievement of achievementList">
                    <div class="card">

                      <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        aria-label="Example icon-button with a menu" class="menuList">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="openMyAchievementModel(achievement.StudentAchievementDataId)">
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item>
                          <span>Delete</span>
                        </button>
                      </mat-menu>

                      <img class="card-img-top" [src]="achievement.FileInfoList!=null?achievement.FileInfoList[0].Result:'../../assets/images/common/homework.jpg'" alt="Card image cap">
                      <div class="card-body pt-1" (click)="openBookDescModel(achievement.StudentAchievementDataId,'achievement')">
                        <h3 class="card-title m-0">{{achievement.CompetitionName}}</h3>
                        <p class="card-text"><small class="text-muted">{{achievement.Achievement}}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>

            </mat-tab-group>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>