export class Branch {
    BranchId:number;
    SchoolId: number;
    Name: string;
    RegistrationNo: string;
    Address: string;
    Street: string;
    City: string;
    State: string;
    PostalNo: string;
    Email: string;
    Mobile: string;
    Telephone: string;
    Website: string;
    IsActive: boolean;
}
