import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { ChatService } from '../shared/chat.service';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
import { ChatboxComponent } from './chatbox.component';
import { ViewchatComponent } from './viewchat.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class ChatComponent{

  public bsModalRef:BsModalRef;
  frameworkComponents;
  chatsRowData:any;
  chatRowSelection;
  chatDefaultColDef;
  chatColumnDefs;
  chatGridOptions:any;
  gridApi;
  gridColumnApi;
public chatbox : ChatboxComponent;
  constructor(public service:ChatService, private modalService: BsModalService,private notificationServ:NotificationService, public commonServ:CommonService) { 
    this.commonServ.RefreshGrid$.subscribe(
      item => {
        
        this.service.getMessages().subscribe((res: any) => {
          this.chatsRowData =  res;
      });
       }
    );
  }

  ngOnInit(): void {
    this.chatColumnDefs = [
    { 
      headerName:"MessageMasterId",
      field: 'MessageMasterId', cellRendererFramework: CustommenuComponent, 
      cellRendererParams : { MenuList : [{Name: "View Chat", "Click" : this.viewChat , that : this}, {Name: "Delete", "Click" : this.deleteMassage , that : this}]},
      
      filter:false, width:60,maxWidth: 60, 
      headerComponent: 'customHeader', 
      headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
    }, 
    {field:'UserName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Name', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    { field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    {field:'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
    
  ];
  this.frameworkComponents = { 
    customHeader: CustomHeader 
  };
  this.chatDefaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: false,
    sortable: false,
    floatingFilter: false,
    };
  }

  viewChat(messageMasterId,that){
    that.openChatModal(messageMasterId);
  }
onChatReady(params) {
  
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  this.service.getMessages().subscribe((res: any) => {
    const chatsRowData =  res;
    this.gridApi.setRowData(chatsRowData);
  });
}
onChatReload(data){
  this.chatsRowData = data;
}
editMassage(eventId, that){
  that.openChatModal(eventId);
  
}
deleteMassage(eventId, that){
// if(!that.service.formData.EventInfo){
//   that.service.formData.EventInfo = {EventId:eventId,IsActive:false};
// }
// that.service.saveChat().subscribe((res:any)=>{
//     if(res.IsSaved){
//       that.notificationServ.ShowInfo('Successfully delete!');
//       that.service.getEvents().subscribe((res: any) => {
//         that.eventsRowData =  res;
//     });
//     }else{
//       that.notificationServ.ShowError(res.ErrorMessage);
//     }
//   });
}
openChatModal(eventId){
  const initialState = {
    list: [
      {id:eventId}
    ]
  };
  this.bsModalRef = this.modalService.show(ViewchatComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
}
}
