import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import $ from "jquery";
import { EventService } from "src/app/shared/event.service";
import { NotificationService } from "src/app/shared/notification.service";
import { SplitRoomComponent } from "../split-room/split-room.component";
import { PreviewRecordingComponentComponent } from "../preview-recording-component/preview-recording-component.component";
import { WebrtcChatComponentComponent } from "../webrtc-chat-component/webrtc-chat-component.component";
import { WhiteBoardComponent } from "../white-board/white-board.component";

declare const oneToMany: any;
declare const cameraOff: any;
declare const screenRecording: any;
declare const canvas: any;
declare const sendMessage: any;
declare const shreFiles: any;
declare const sendFile: any;

@Component({
  selector: "app-webrtc-teacher-component",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    WebrtcChatComponentComponent,
    WhiteBoardComponent,
  ],
  templateUrl: "./webrtc-teacher-component.component.html",
  styleUrl: "./webrtc-teacher-component.component.scss",
})
export class WebrtcTeacherComponentComponent {
  showWhiteBoard: boolean = false;
  isShareScreen: boolean = false;
  showchatboxbutton: boolean = true;
  hidechatboxbutton: boolean = false;
  showList: boolean = false;
  StudentList: any[] = [];
  receivedData: any;
  selectedFile: File | null = null;

  public bsModalRef: BsModalRef;
  constructor(
    public eventService: EventService,
    public notificationService: NotificationService,
    private modalService: BsModalService
  ) {}

  roomId: string | null;
  @ViewChild("chatContainer") chatContainer!: ElementRef;
  ngOnInit() {
    localStorage.setItem("DuringConsultation", "true");
    $(document).ready(function () {
      $("#btnMute").show();
      $("#btnUnMute").hide();
      $("#off-cam").show();
      $("#start-cam").hide();
    });
    this.socketIo();
    // calling function for getting consultation status
    this.roomId = localStorage.getItem("room_id");
    if (this.roomId) {
      this.screenOrVideo();
      this.startRecording();
      this.getStudentList();
      this.updateAttandance();
    }
  }
  socketIo() {}
  screenOrVideo() {
    if (this.roomId) {
      oneToMany(this.roomId, "openOrJoin", this.isShareScreen);
    }
  }
  startRecording() {
    screenRecording();
  }
  switchShowList() {
    this.showList = !this.showList;
    $(document).ready(function () {
      $("#studentshow").toggleClass("active");
    });
  }
  getStudentList() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    this.eventService
      .getStudentByEventId(eventData.EventId)
      .subscribe((res: any) => {
        console.log(res.StudentList);
        const StudentList = res.StudentList;
        localStorage.setItem("StudentList", JSON.stringify(StudentList));
        this.StudentList = StudentList;
      });
  }
  updateAttandance() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const actionData = {
      EventId: eventData.EventId,
      Action_Type: "Join",
    };

    this.eventService.saveRoomStatusInfo(actionData).subscribe((res: any) => {
      const rowData = res.StudentList;
      this.StudentList = rowData;
    });
  }
  // This function calls when doctor click on chat button
  showchatbox() {
    // this.showchatboxbutton = false;
    // this.hidechatboxbutton = true;

    $(document).ready(function () {
      $("#showconsultationsidebar").toggleClass("chatBoxOpen");
      $("#showchat").toggleClass("active");
    });
  }
  // This function calls for hide chat box

  // hidechatbox() {
  //   this.showchatboxbutton = true;
  //   this.hidechatboxbutton = false;

  //   $(document).ready(function () {
  //     $("#showconsultationsidebar").removeClass("chatBoxOpen");
  //   });
  // }
  endCall() {
    // window.close();
    window.location.href = "/call-feedback";
  }
  enableWhiteBoard() {
    $(document).ready(function () {
      $(
        "app-webrtc-teacher-component #showconsultationsidebar #local"
      ).toggleClass("teacher-whitboard-share");
    });
    this.showWhiteBoard = !this.showWhiteBoard;
    // const initialState = {};
    // this.bsModalRef = this.modalService.show(WhiteBoardComponent, {
    //   initialState,
    //   class: "testing",
    //   ignoreBackdropClick: true,
    // });
  }
  makeSplitRoom() {
    const initialState = {
      list: JSON.parse(localStorage.getItem("StudentList")),
    };
    this.bsModalRef = this.modalService.show(SplitRoomComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered breackoutroom_modal",
      ignoreBackdropClick: true,
    });
  }
  previewRecording() {
    const initialState = {
      videoUrl: localStorage.getItem("videoUrl"),
    };
    this.bsModalRef = this.modalService.show(
      PreviewRecordingComponentComponent,
      {
        initialState,
        class: "modal-dialog modal-dialog-centered breackoutroom_modal",
        ignoreBackdropClick: true,
      }
    );
  }
   // Event when file is selected
   onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]; // Get the selected file
      console.log(this.selectedFile);
    }
  }

  // Upload logic
  onUpload(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      shreFiles(this.selectedFile)
      // sendFile(this.selectedFile)
      // Here you would typically make an HTTP request to upload the file
      // Example:
      // this.http.post('your-backend-api-url', formData).subscribe(response => {
      //   console.log(response);
      // });

      console.log('File ready for upload:', this.selectedFile);
    } else {
      console.log('No file selected.');
    }
  }
}
