import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-user-fee-info',
    templateUrl: './user-fee-info.component.html',
    styleUrls: ['./user-fee-info.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor]
})
export class UserFeeInfoComponent implements OnInit {
  feeInfo:any={};
  selectedChileId:number;
  childrenList:any=[];
stdFeeInfo:any={};


  constructor(public userService:UserService,private notificationServ:NotificationService) { }
 
  ngOnInit(): void {
    this.getMyInfo();
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.selectedChileId=res.ParentInfo.ChildrenList[0].Id;
      this.childrenList =  res.ParentInfo.ChildrenList;
      this.getstudentfeeinfo(this.selectedChileId)

    });
  }
  getstudentfeeinfo(stuId) {
    this.userService.getstudentfeeinfo(stuId).subscribe((res: any) => {
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else{
      this.stdFeeInfo = res;
      this.feeInfo = res.FeeListInfo;
     
      }
    });
  }
  Paynow(){
    this.userService.getpaymentUrl(this.stdFeeInfo).subscribe((res: any) => {
      console.log("getpayment url");
      console.log(res);
      if(res && res.PaymentUrl){
        window.location.href = res.PaymentUrl;
      }
      else if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  changeChild(){
    this.getstudentfeeinfo(this.selectedChileId)
  }
}
