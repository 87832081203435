import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CanonicalService } from '../shared/canonical.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: true
})
export class FaqComponent implements OnInit {
  course: string;
  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('“You can’t grow if you are afraid of change.” - Hybrid Learning');
    this.metaService.addTags([
      {name: 'keywords', content: 'New ERA Learning, LEARNING MANAGEMENT SYSTEM, LMS, EDTECH, '},
      {name: 'description', content: 'Hybrid Learning Platform is the world’s first “Collaborative, Personalized Learning, Next Generation Education Management System for Academics, SEL, Life Skills, Outside the box”.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
    if(typeof window != 'undefined')
    window.scroll(0,0);
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoplay:true,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

}
