import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { FileData } from '../shared/school.model';
import { SchoolService } from '../shared/school.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-editbanner',
    templateUrl: './editbanner.component.html',
    styleUrls: ['./editbanner.component.scss'],
    standalone: true,
    imports: [FormsModule, ImageCropperModule, TranslateModule]
})
export class EditbannerComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn : any;
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  public event: EventEmitter<any> = new EventEmitter();
  public FileInfo:FileData = new FileData();
  constructor(public service:SchoolService,public bsModalRef2: BsModalRef) { }

  ngOnInit(): void {
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    );
this.FileInfo.FileName = this.imageChangedEvent.target.files[0].name;
this.FileInfo.CroppedContentString = event.base64;
this.FileInfo.FileSize = this.imageChangedEvent.target.files[0].size;
this.FileInfo.FileType = this.imageChangedEvent.target.files[0].type;
    return this.fileToReturn;
}
base64ToFile(data, filename) {

  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

imageLoaded(image: any) {
  this.FileInfo.OriginalContentString = image.original.base64;
}
cropperReady() {
    /* cropper ready */
}
loadImageFailed() {
    /* show message */
}
onSave(form:NgForm){
  this.triggerEvent(this.FileInfo);
  this.bsModalRef2.hide();
}
triggerEvent(item: FileData) {
  this.event.emit({ data: item , res:200 });
}
}
