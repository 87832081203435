import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class SupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
