import { formatDate, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { EventService } from '../shared/event.service';
import { NotificationService } from '../shared/notification.service';
import { EditeventComponent } from './editevent.component';
import { MultieventComponent } from './multievent.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, AgGridModule]
})
export class EventComponent implements OnInit {
  public bsModalRef:BsModalRef;
  frameworkComponents;
  eventsRowData:any;
  eventRowSelection;
  eventDefaultColDef;
  eventColumnDefs;
  eventGridOptions:any;
  gridApi;
gridColumnApi;

currentyear:any;
  selectedYear:string;
  startDate:string;
  endDate:string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    }
  };
  constructor(public service:EventService, private modalService: BsModalService,private notificationServ:NotificationService,public commonServ:CommonService) {
    this.commonServ.RefreshGrid$.subscribe(
      item => {
        this.getEvents();
       }
    );
   }

  ngOnInit(): void {
    
this.eventColumnDefs = [
  { 
    headerName:"EventId",
    field: 'EventId', cellRendererFramework: CustommenuComponent, 
    cellRendererParams : { MenuList : [{Name: "Edit", "Click" : this.editEvent , that : this}, {Name: "Delete", "Click" : this.deleteEvent , that : this}]},
    
    filter:false, width:60,maxWidth: 60, 
    headerComponent: 'customHeader', 
    headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
  }, 
  { field: 'UserName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'ClassName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { field: 'Description', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
  { headerName:"Date",field: 'EventDate', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120  },
  { field: 'StartTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 , maxWidth: 100   },
  { field: 'EndTime', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100    },
];
this.frameworkComponents = { 
  customHeader: CustomHeader 
};
this.eventDefaultColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
  sortable: true,
  floatingFilter: true,
  };

  this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
    res = res.slice().reverse();
    this.yearList = res;
    this.selectedYear = res[0].Code;
    this.config = {
      params: {
        startDate: res[0].Code,
        endDate: '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1),
      }
    }
  });
  }
  
onEventReady(params) {
  
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  setTimeout(() =>  {
    this.getEvents();
  }, 300);
 
 
}
getEvents(){
  this.service.getEventsByParam(this.config).subscribe((res: any) => {
    const rowData =  res;
    this.gridApi.setRowData(rowData);
  });
}
onEventReload(data){
  this.eventsRowData = data;
}
editEvent(eventId, that){
  that.openEventModal(eventId);
  
}
deleteEvent(eventId, that){
  if(that.commonServ.role == 'Teacher'){
    that.notificationServ.ShowError('You are not allowed to delete, Please contact administrator.');
  }
  else{
var item = that.eventsRowData.filter(item=>item.EventId == eventId);
that.service.formData.EventInfo = item[0];
that.service.formData.EventInfo.IsActive = false;
that.service.saveEvent().subscribe((res:any)=>{
    if(res.IsSaved){
      that.notificationServ.ShowInfo('Successfully delete!');
      
      this.getEvents();
    }else{
      that.notificationServ.ShowError(res.ErrorMessage);
    }
  });
}
}
openMultiEvent(){
  this.bsModalRef = this.modalService.show(MultieventComponent,{class: 'modal-lg mb-0',ignoreBackdropClick: true});
}
  openEventModal(eventId){
    const initialState = {
      list: [
        {id:eventId}
      ]
    };
    this.bsModalRef = this.modalService.show(EditeventComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }
  serchEvents(){
    this.getEvents();
  }
  
  changeYear(){
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate: '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1),
      }
    }
  }
}
