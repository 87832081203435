<div class="container-fluid bg-light pb-3">
    <section id="assesments">
        <div class="row">
            <div class="col-12">
                <div class="container-fluid p-0">
                    <mat-tab-group animationDuration="0ms" class="bg-transparent border-0 d-flex"
                    (selectedTabChange)="activeTabChange($event)">
                        <mat-tab label="Assessments">
                            <ng-template mat-tab-label>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <label class="m-0">Assessments</label>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="rounded">
                                <div class="tab-content">
                                    <div class="assesments">

                                        <div class="row bdr-bottom pt-2 pb-2" *ngFor="let assessment of assessmentData">
                                            <div class="col-10">
                                                <div class="d-flex ">
                                                    <div class="assesment-icon mt-2"><img class="p-2 rounded" src="../../assets/images/icons/svg/test_passed.svg" alt=""> </div>
                                                    <div class="w-100 ml-2">
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <h3 class="m-0">
                                                                {{assessment.Name}}
                                                                 <!-- <span class="text-muted">English Term</span> -->
                                                            </h3>

                                                            <span>{{assessment.StartDate | date:'MMMM d, y'}}</span>
                                                        </div>
                                                        <div>
                                                            <p class="m-0">{{assessment.Description}}</p>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="mr-4">
                                                                <span class="text-muted mr-2">Start Time</span>
                                                                <span>{{assessment.StartTime | date:'h:mm a'}} </span>
                                                            </div>
                                                            <div>
                                                                <span class="text-muted mr-2">End Time</span>
                                                                <span>{{assessment.EndTime | date:'h:mm a'}} </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-2 d-flex align-items-center justify-content-end">
                                                <button class="btn "  [ngClass]="{'btn-danger':assessment.Status==='Expired'}" *ngIf="assessment.Status==='Expired'" (click)="startExam(assessment.QuestionMasterId)">Expired</button>
                                                <button class="btn "  [ngClass]="{'btn-warning':assessment.Status==='Completed'}" *ngIf="assessment.Status==='Completed'" (click)="startExam(assessment.QuestionMasterId)">Completed</button>
                                                <button class="btn"  [ngClass]="{'btn-success':assessment.Status==='New'}" *ngIf="assessment.Status==='New'" (click)="startAssessment(assessment.QuestionMasterId)">Start</button>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Feedback">
                            <ng-template mat-tab-label>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <label class="m-0">Feedback</label>

                                    </div>
                                </div>
                            </ng-template>
                            <div class="rounded mt-4">
                                <div class="tab-content">
                                    <div class="assesments">

                                        <div class="row">
                                           <div class="col-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 pb-4 ng-star-inserted" *ngFor="let feedback of feedbackList">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="card-title d-flex justify-content-between">
                                                        <h3 class="m-0">{{feedback.CreatedByName}}</h3>
                                                        <span>{{feedback.CreatedOn}}</span>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <p>{{feedback.Feedback}}</p>
                                                        <span class="card-btn cursor-pointer" (click)="openReadmoreModel(feedback)">Read More<i class="fa fa-solid fa-angle-right ml-2"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                           </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </mat-tab>

                    </mat-tab-group>
                    <div class="tab-search-group">
                        <div class="d-flex align-items-center">
                            <span><img src="../../assets/images/icons/Search.png" alt=""></span>
                            <input type="text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
