import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignemntService } from 'src/app/shared/assignemnt.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QuestionService } from '../shared/question.service';
import { NotificationService } from '../shared/notification.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { StudentAnswerpreviewComponent } from '../assignment/student-answerpreview.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-view-child-result',
    templateUrl: './view-child-result.component.html',
    styleUrls: ['./view-child-result.component.scss'],
    standalone: true,
    imports: [NgIf, NgxDocViewerModule, NgFor, NgClass, MatRadioModule, MatIconModule, MatCheckboxModule, FormsModule, TranslateModule]
})
export class ViewChildResultComponent implements OnInit {
  list: any[] = [];
  fileType: any;
  urlType: string;
  url: any;
  urlSafe: SafeResourceUrl;
  description: any;
  questioner: boolean;
  startAnswer: boolean=false;
  currentQuestionNo: number=0;
  questionInfo: any;
  noofQuestions: number;
  enbedUrl: any;
  youtubeUrlId: string;
  ispreviewAnswer: boolean;
  status: string;
  stdAnswerMasterInfo: any = 0;
  CUSTOMCONST: any;
  lastdocumentId: number;
  fileExtension: any;
  docUrl: any;
  getStudentAnswerInfoConfig: any;
  constructor(public service: AssignemntService, public questionService: QuestionService, public bsModalRef: BsModalRef, public modalService: BsModalService, public sanitizer: DomSanitizer, private notificationServ: NotificationService, private embedService: EmbedVideoService, public commonServ: CommonService) {
    
    this.CUSTOMCONST = CUSTOMCONST;
  }

  ngOnInit(): void {
    this.fileType = this.list[0].data.Type;
    this.urlType = this.list[0].data.UrlType;
    this.url = this.list[0].data.Url;
    this.questioner = this.list[0].data.IsQuestionir;
    this.description = this.list[0].data.Description;
    this.status = this.list[0].data.Status;

    if (this.url != '') {
      var ftList = this.list[0].data.Url.split('.');
      this.fileExtension = ftList[ftList.length - 1];
      if (this.fileExtension === 'doc' || this.fileExtension === 'docx') {
        var re1 = /\\/gi;
        this.docUrl = (this.list[0].data.Url).replace(re1, "/");
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUrl);
      }
    }

    this.service.getassignment(this.list[0].data.AssignmentId).subscribe((res: any) => {
      this.service.formData = res;
      this.service.formData.QuestionInfo = res.QuestionInfo;
      this.service.formData.QuestionMasterInfo = res.QuestionMasterInfo;
      this.noofQuestions = this.service.formData.QuestionInfo.length;
      this.getStudentAnswerInfoConfig = {
        params: {
          schoolId: this.list[0].userinfo.schoolId,
          userId: this.list[0].userinfo.userId,
          questionMasterId: res.AssignmentInfo.QuestionMasterId
        }
      }
      this.getStudentResult(this.getStudentAnswerInfoConfig);
    });

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    if (this.urlType == "Url") {
      this.enbedUrl = this.embedService.embed(this.list[0].data.Url,
        {
          query: { portrait: 0, color: '333' },
          attr: { style: 'width:100%;height:450px;min-height: calc(100vh - 190px);' },
        });
      this.youtubeUrlId = this.list[0].data.Url.replace('https://www.youtube.com/watch?v=', '');
    }
    this.commonServ.getlastdocumentid().subscribe((res: any) => {
      this.lastdocumentId = res;
    });
  }
  getStudentResult(config: any) {

    if (config.params.questionMasterId > 0) {
      this.questionService.getStudentAnswerInfoForParent(config).subscribe((res: any) => {
        this.stdAnswerMasterInfo = res

      });
    }
    else {
      this.service.getstudentmasterid(this.service.formData.AssignmentInfo.AssignmentId).subscribe((res: any) => {
        this.stdAnswerMasterInfo = {};
        this.stdAnswerMasterInfo.StudentAnswerMasterDataId = res;
      });
    }
  }
  
  
  largefileschange(event) {
  }

  
  viewResult() {
    this.bsModalRef.hide();
    const initialState = {
      list: [
        {
          id: this.stdAnswerMasterInfo.StudentAnswerMasterDataId,
          reqBy: 'Student'
        },
        { classidlist: '' }
      ]
    };
    this.bsModalRef = this.modalService.show(StudentAnswerpreviewComponent, { initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
  }
  
}
