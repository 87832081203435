
export class SchoolSettingInfo{
    PurposeOfPostIdList: Array<any>;
    SchoolCategoryId: number;
    StateId: number;
    ChallengeIdList: Array<any>;
    SchoolSettingId: number;
    SchoolId: number;
    BranchId: number;    
}

export class SchoolSettingViewInfo{
     SchoolSettingInfo : SchoolSettingInfo;
    IsSave: boolean;
    ErrorMessage:string;
}