<!-- <ul class="list-sidebar bg-defoult clear-both mt-3">
    <li>
      <a routerLink="/dashboard/" routerLinkActive="active" >
        <i class="fa fa-th-large"></i>
        <span class="nav-label">Dashboard</span>
      </a>

  </li>
  <li>
    <a href="#" data-toggle="collapse" data-target="#configDropdown" [ngClass]="{'active': (ILActive.isActive || GDActive.isActive || StudentsActive.isActive || classesActive.isActive),'collapsed':!(ILActive.isActive || GDActive.isActive || StudentsActive.isActive || classesActive.isActive) }">
      <i class="fa fa-cogs"></i> <span class="nav-label">Configurations</span> <span class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="configDropdown" [ngClass]="{'show': (ILActive.isActive || GDActive.isActive || StudentsActive.isActive || classesActive.isActive) }">
      <li class="active"><a routerLink="/institute-logo/" routerLinkActive="active" #ILActive="routerLinkActive">Logo</a></li>
      <li><a routerLink="/general-details/" routerLinkActive="active" #GDActive="routerLinkActive">General Details</a></li>
      <li><a routerLink="/student-list/" routerLinkActive="active" #StudentsActive="routerLinkActive">Student Profiles</a></li>
      <li><a  routerLink="/class-list/" routerLinkActive="active" #classesActive="routerLinkActive">Age Group</a></li>
    </ul>
    </li>
    <li>
      <a href="#" data-toggle="collapse" data-target="#paymentsDropdown" [ngClass]="{'active': (termsActive.isActive || paymentsActive.isActive ),'collapsed':!(termsActive.isActive || paymentsActive.isActive ) }">
          <fa-icon [icon]="currencyIcon"></fa-icon> <span class="nav-label">Fee Management</span> <span
              class="fa fa-chevron-left float-right"></span>
      </a>
      <ul class="sub-menu collapse" id="paymentsDropdown" [ngClass]="{'show': termsActive.isActive || paymentsActive.isActive }" >
          <li class="active"><a routerLink="/terms/" routerLinkActive="active" #termsActive="routerLinkActive">Fees Configuration</a></li>
          <li><a routerLink="/payments/" routerLinkActive="active" #paymentsActive="routerLinkActive">Fee Payments</a></li>
      </ul>
  </li>
  <li>
    <a href="#" data-toggle="collapse" data-target="#LMSDropdown" [ngClass]="{'active': (eventActive.isActive || assignmentActive.isActive || surveyActive.isActive || analysisActive.isActive ),'collapsed':!(eventActive.isActive || assignmentActive.isActive || surveyActive.isActive || analysisActive.isActive) }">
        <fa-icon [icon]="currencyIcon"></fa-icon> <span class="nav-label">LMS</span> <span
            class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="LMSDropdown" [ngClass]="{'show': eventActive.isActive || assignmentActive.isActive || surveyActive.isActive || analysisActive.isActive }" >
        <li class="active"><a routerLink="/event/" routerLinkActive="active" #eventActive="routerLinkActive">Interactive Live Classes</a></li>
        <li><a routerLink="/assignment/" routerLinkActive="active" #assignmentActive="routerLinkActive">Assignments</a></li>
        <li><a routerLink="/survey/" routerLinkActive="active" #surveyActive="routerLinkActive">Survey</a></li>
        <li><a routerLink="/analysis/" routerLinkActive="active" #analysisActive="routerLinkActive">Analysis</a></li>
    </ul>
</li>

  <li> <a  routerLink="/question/" routerLinkActive="active"><fa-icon [icon]="tasksIcon"></fa-icon> <span class="nav-label">Assessments</span></a> </li>
  <li> <a  routerLink="//" routerLinkActive="active"><fa-icon [icon]="feedbackIcin"></fa-icon> <span class="nav-label">Feedback</span></a> </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#BillingDropdown"
    [ngClass]="{'active': (UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive),'collapsed':!(UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive) }">
    <fa-icon [icon]="billingIcon"></fa-icon> <span class="nav-label">Billing</span> <span class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="BillingDropdown"
    [ngClass]="{'show': (UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive) }">
      <li><a routerLink="/usege/" routerLinkActive="active" #UsageActive="routerLinkActive">Usage Metrics</a></li>
      <li><a routerLink="/storage/" routerLinkActive="active" #StorageActive="routerLinkActive">Storage</a></li>
      <li><a routerLink="/number-of-student/" routerLinkActive="active" #NOStudentsActive="routerLinkActive">Number of Students </a></li>
      <li><a routerLink="/invoice/" routerLinkActive="active" #InvoiceActive="routerLinkActive">Bills and Invoice</a></li>
    </ul>
  </li>
  <li> <a  routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span class="nav-label">Attendance</span></a> </li>


    <li>
        <a href="#" data-toggle="collapse" data-target="#filesDropdown" [ngClass]="{'active': (unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive|| parentsharedocActive.isActive),'collapsed':!(unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive|| parentsharedocActive.isActive) }">
            <i class="fa fa-folder-open"></i> <span class="nav-label">Files</span> <span
                class="fa fa-chevron-left float-right"></span>
        </a>
        <ul class="sub-menu collapse" id="filesDropdown" data-target="#filesDropdown" [ngClass]="{'show': unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || parentsharedocActive.isActive}">
            <li class="active"><a routerLink="/unusedfiles/" routerLinkActive="active" #unusedfilesActive="routerLinkActive">Unused Files - {{unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || parentsharedocActive.isActive}}</a></li>
            <li><a routerLink="/file-management/" routerLinkActive="active" #filemanagementActive="routerLinkActive">File Management</a></li>
            <li><a routerLink="/own-documents/" routerLinkActive="active" #owndocumentsActive="routerLinkActive">Own Documents</a></li>
            <li><a routerLink="/parent-share-doc/" routerLinkActive="active" #parentsharedocActive="routerLinkActive">Parent Share Doc</a></li>

        </ul>
    </li>
  </ul> -->




  <ul class="list-sidebar bg-defoult clear-both mt-3">


    <li *ngFor="let menuItem of menuItems; let i=index">
      <a routerLink="/{{menuItem.Route!==('/' || '')?menuItem.Route:'/404'}}/" routerLinkActive="active" #parenActive="routerLinkActive" *ngIf="menuItem.Type == 'link'">
        <img src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{parenActive.isActive?'_green':'_gray'}}.svg" alt="{{menuItem.Icon}}">
        <span class="nav-label">{{menuItem.Name}} </span>
      </a>

      <a href="" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length<=1"
      routerLink="/{{menuItem.ChildMenuList[0].Route!==('/' || '')?menuItem.ChildMenuList[0].Route:'/404'}}/" routerLinkActive="active" >
        <img src="../../assets/images/icons/svg/sidebar/{{menuItem.ChildMenuList[0].Icon}}_gray.svg" alt="{{menuItem.ChildMenuList[0].Icon}}">
        <span class="nav-label">{{menuItem.ChildMenuList[0].Name}}</span>
      </a>

      <a href="#"   data-toggle="collapse" data-target="#{{menuItem.Code}}Dropdown" [ngClass]="(IsOpend == i &&  IsCollapse )?'active':'collapsed'"
      (click)="menuChange(i)" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
            <img src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{(IsOpend == i &&  IsCollapse )?'_green':'_gray'}}.svg" alt="{{menuItem.Icon}}">
          <span class="nav-label">{{menuItem.Name}}</span> <span
            class="fa fa-chevron-left float-right"></span>
        </a>
        <ul class="sub-menu collapse show" id="{{menuItem.Code}}Dropdown" [ngClass]="{'show': (IsOpend == i &&  IsCollapse ) }" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
          <li *ngFor="let subMenuItem of menuItem.ChildMenuList; let childIndex = index" >
            <a routerLink="/{{subMenuItem.Route!==('/' || '')?subMenuItem.Route:'/404'}}/" routerLinkActive="active" (click)="subMenuChange(i+childIndex)">{{subMenuItem.Name}}</a>
          </li>
        </ul>

    </li>

  </ul>
