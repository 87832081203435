import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StudentAnswerpreviewComponent } from '../assignment/student-answerpreview.component';
import { CustomGrideCelComponent } from '../custommenu/custom-gride-cel/custom-gride-cel.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { QuestionService } from '../shared/question.service';
import { EditprofileComponent } from '../user/editprofile.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'app-stuanswerlist',
    templateUrl: './stuanswerlist.component.html',
    styleUrls: ['./stuanswerlist.component.scss'],
    standalone: true,
    imports: [AgGridModule, TranslateModule]
})
export class StuanswerlistComponent implements OnInit {
  list:any[]=[];
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  answerListData: any[];
  answerListOptions:any;
  constructor(public service:QuestionService,public bsModalRef:BsModalRef,  private modalService: BsModalService,public commonService:CommonService) { 
    this.commonService.RefreshGrid$.subscribe(
      item => {
          this.service.getquestionoverview(this.list[0].id).subscribe((res : any) => {
            this.answerListData =  res;
          });
        }
    );

    this.columnDefs = [
      { field: 'ClassName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150},
      { 
        headerName:"Name",
        field: 'UserId', cellRendererFramework: CustomGrideCelComponent,  width: 200,sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, },
        cellRendererParams : {enableStudentLink : true,  celName : [{Name: "View Profile", "Click" : this.viewAtudentProfile , that : this}]},
        
        
      },
      { field: 'NoOfQuections', sortable: false, filter: false ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },
      { field: 'NoOfCorrectAnswers', sortable: false, filter: false ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 180, maxWidth: 180 },
      { field: 'TotalTime', editable:true,sortable: false, filter: false ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 100, maxWidth: 100 },
      { headerName:'Total Marks',field: 'TotalPoints', editable:true,sortable: false, filter: false ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 150, maxWidth: 150 },
      {  headerName:'Scored',field: 'Points', editable:true,sortable: false, filter: false ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 100, maxWidth: 100 },
      { 
        headerName:"Status",
        field: 'StdQuestionMasterId', cellRendererFramework: CustomGrideCelComponent,  maxWidth: 100, minWidth: 100,sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, },
        cellRendererParams : {enableMeetingLink : false, enableButton : true,  celName : [{Name: "Join", "Click" : this.answerPreview , that : this}]},
      },
   ];
   this.defaultColDef = {
    flex: 1,
    minWidth: 100,
    // editable:true,
    filter: true,
        sortable: true,
        floatingFilter: true,
        
  };
  }

  ngOnInit(): void {
    // this.service.getquestionoverview(this.list[0].id).subscribe((res:any)=>{
    // });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.service.getquestionoverview(this.list[0].id).subscribe((res : any) => {
      this.answerListData =  res;
    });
  }
  onCellValueChanged(params) {
    
    // this.service.updatePoints(params.data).subscribe((res:any)=>{
      
    //     });
    
  }
  viewAtudentProfile(UserId, that){
    const initialState = {
      list: [
        {id:UserId}
      ]
      
    };
    that.bsModalRef = that.modalService.show(EditprofileComponent,{id : CUSTOMCONST.USER_EDIT_MODAL, initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  
  }
  answerPreview(StdQuestionMasterId, that){
    var assignmentInfo = that.answerListData.filter(item => item.StdQuestionMasterId == StdQuestionMasterId);
    const initialState = {
      list: [
        {id:StdQuestionMasterId, reqBy:'Teacher'},
        {classidlist : assignmentInfo[0].ClassIdList}
      ]      
    };
    this.bsModalRef = that.modalService.show(StudentAnswerpreviewComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
}
