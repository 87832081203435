<div class="dashboardInnerPage flex-wrap inner-page-outer">
  <div class="title w-100">
    <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
      class="bi bi-arrow-left"></span>
    <h3>All Questions</h3>
    <a class="add_button" routerLink="/create-forum">Ask Question</a>
  </div>
  <div class="all_questionOuter">
    <div class="question-col" *ngFor="let question of list">
      <div class="question-header">
        <div class="question-title">
          <h3>{{question.subject}}</h3>
          <p><span class="bi bi-person"></span> {{question.userName}}</p>
        </div>
        <a class="vie_details_button" [routerLink]="['/forum-question-details', question.questionId]">View Details</a>
      </div>
      <div class="ques-video">
        <video [src]="question.video"></video>
      </div>
    </div>
  </div>
</div>