import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class DateService extends BaseService {

    getDateFromStringDate(strDate: string) {
        var newDate: any = '';
        if (strDate && typeof strDate == 'string' && strDate.indexOf('-') >= 0) {
            var splitDate: Array<string> = strDate.split('-');
            newDate = new Date(parseInt(splitDate[2].split(' ')[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[0]));
        }
        else {
            newDate = new Date(strDate);
        }
        return newDate;
    }
    getStringDate(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        return this.getFormatedStringDate(date.getDate()) + '-' + this.getFormatedStringDate(date.getMonth() + 1) + '-' + date.getFullYear();
    }
    getStringDateTime(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        if (date.getMonth() < 9) {

            return ('0' + date.getDate()).slice(-2) + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + this.getStringTime(date) + ':' + ('0' + date.getSeconds()).slice(-2);
        }
        else {
            return ('0' + date.getDate()).slice(-2) + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + this.getStringTime(date) + ':' + ('0' + date.getSeconds()).slice(-2);
        }
    }
    getStringTime(date: any) {
        if (typeof date == 'string') {
            date = this.getDateTimeFromStringDateTime(date);
        }
        if (date.getMinutes() < 10) {
            return this.getFormatedStringDate(date.getHours()) + ':0' + date.getMinutes();
        }
        else {
            return this.getFormatedStringDate(date.getHours()) + ':' + date.getMinutes();
        }
    }
    getDateTimeFromStringDateTime(strDateTime: any) {
        var newDate: any = '';
        if (strDateTime && typeof strDateTime == 'string' && strDateTime.indexOf('-') >= 0) {
            var strDate = strDateTime.split(' ')[0];
            var strTime = strDateTime.split(' ')[1];
            var splitDate = strDate.split('-');
            var splitTime = strTime.split(':');
            newDate = new Date(parseInt(splitDate[2].split(' ')[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[0]), parseInt(splitTime[0]), parseInt(splitTime[1]), parseInt(splitTime[2].split(' ')[0]));
        }
        else {
            newDate = new Date(strDateTime);
        }
        return newDate;
    }
    getDateTimeFromString(strDate: string, strTime: string) {
        var dateTime = this.getDateFromStringDate(strDate);
        dateTime.setHours(this.getHour(strTime));
        dateTime.setMinutes(this.getMinute(strTime));
        return dateTime;
    }
    getFirstDayOfWeek(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        //var first = date.getDate() - date.getDay()+1; // First day is the day of the month - the day of the week
        let day = date.getDay(),
            diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        let firstday = new Date(date.setDate(diff));
        return this.getFromDate(firstday);
    }
    getLastDayOfWeek(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        //var first = date.getDate() - date.getDay()+1; // First day is the day of the month - the day of the week
        let day = date.getDay(),
            diff = date.getDate() - day  + (day == 0 ? -6 : 1); // adjust when day is sunday
        let lastDay = new Date(date.setDate(diff + 6));
        return this.getFromDate(lastDay);
    }
    getFirstDayOfMonth(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return this.getFromDate(lastDay);
    }
    getLastDayOfMonth(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        var first = new Date(date.getFullYear(), date.getMonth(), 1);
        return this.getFromDate(first);
    }
    
    getFromDate(date: any) {
        if (date) {
            if (typeof date == 'string') {
                date = this.getDateFromStringDate(date);
            }
            if (date.getMonth() < 9) {
                if (date.getDate() < 9) {
                    return '0' + date.getDate() + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 00:00:00';
                }
                else {
                    return date.getDate() + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 00:00:00';
                }
            }
            else {
                if (date.getDate() < 9) {
                    return '0' + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 00:00:00';
                }
                else {
                    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 00:00:00';
                }
            }
        }
        else {
            return date;
        }
    }
    getStartDateOfMonth(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return this.getFromOnlyDate(lastDay);
    }
    getEndDateOfMonth(date: any) {
        if (typeof date == 'string') {
            date = this.getDateFromStringDate(date);
        }
        var first = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return this.getFromOnlyDate(first);
    }
    getFromOnlyDate(date: any) {
        if (date) {
            if (typeof date == 'string') {
                date = this.getDateFromStringDate(date);
            }
            if (date.getMonth() < 9) {
                if (date.getDate() < 9) {
                    return '0' + date.getDate() + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
                }
                else {
                    return date.getDate() + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear();
                }
            }
            else {
                if (date.getDate() < 9) {
                    return '0' + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() ;
                }
                else {
                    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() ;
                }
            }
        }
        else {
            return date;
        }
    }
    getEndDate(date: any) {
        if (date) {
            if (typeof date == 'string') {
                date = this.getDateFromStringDate(date);
            }
            if (date.getMonth() < 9) {
                return date.getDate() + '-' + '0' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 23:59:59';
            }
            else {
                return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' 23:59:59';
            }
        }
        else {
            return date;
        }
    }
    getHour(strInput: string) {
        return strInput.split(':')[0];
    }
    getMinute(strInput: string) {
        return strInput.split(':')[1];
    }
    getFormatedStringDate(no: number) {
        if (no <= 9) {
            return '0' + no;
        }
        else {
            return no;
        }
    }
    formatDate(date: any, format: string) {
        var returnStr = '';
        for (var i = 0; i < format.length; i++) {
            var curChar = format.charAt(i);
            if (i != 0 && format.charAt(i - 1) == '\\') {
                returnStr += curChar;
            }
            else {
                returnStr += this.replaceChars(date, curChar);
            }
        }
        return returnStr;
    }
    replaceChars(date: any, curChar: string) {
        switch (curChar) {
            case 'd': { return (date.getDate() < 10 ? '0' : '') + date.getDate(); } break;
            case 'D': { return this.regional.dayNamesShort[date.getDay()]; } break;
            case 'j': { return date.getDate(); } break;
            case 'l': { return this.regional.dayNames[date.getDay()]; } break;
            case 'N': { var _d = date.getDay(); return _d ? _d : 7; } break;
            case 'S': { return (date.getDate() % 10 == 1 && date.getDate() != 11 ? 'st' : (date.getDate() % 10 == 2 && date.getDate() != 12 ? 'nd' : (date.getDate() % 10 == 3 && date.getDate() != 13 ? 'rd' : 'th'))); } break;
            case 'w': { return date.getDay(); } break;
            case 'z': { var d: any = new Date(date.getFullYear(), 0, 1); return Math.ceil((date - d) / 86400000); } break;
            // Week
            case 'W': { var d: any = new Date(date.getFullYear(), 0, 1); return Math.ceil((((date - d) / 86400000) + d.getDay() + 1) / 7); } break;
            // Month
            case 'F': { return this.regional.monthNames[date.getMonth()]; } break;
            case 'm': { return (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1); } break;
            case 'M': { return this.regional.monthNamesShort[date.getMonth()]; } break;
            case 'n': { return date.getMonth() + 1; } break;
            case 't': { var d = date; return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() } break;
            // Year
            case 'L': { var year = date.getFullYear(); return (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)); } break;
            case 'o': { var d: any = new Date(date.valueOf()); d.setDate(d.getDate() - ((date.getDay() + 6) % 7) + 3); return d.getFullYear(); } break;
            case 'Y': { return date.getFullYear(); } break;
            case 'y': { return ('' + date.getFullYear()).substr(2); } break;
            // Time
            case 'a': { return date.getHours() < 12 ? 'am' : 'pm'; } break;
            case 'A': { return date.getHours() < 12 ? 'AM' : 'PM'; } break;
            case 'B': { return Math.floor((((date.getUTCHours() + 1) % 24) + date.getUTCMinutes() / 60 + date.getUTCSeconds() / 3600) * 1000 / 24); } break;
            case 'g': { return date.getHours() % 12 || 12; } break;
            case 'G': { return date.getHours(); } break;
            case 'h': { return ((date.getHours() % 12 || 12) < 10 ? '0' : '') + (date.getHours() % 12 || 12); } break;
            case 'H': { return (date.getHours() < 10 ? '0' : '') + date.getHours(); } break;
            case 'i': { return (date.getMinutes() < 10 ? '0' : '') + date.getMinutes(); } break;
            case 's': { return (date.getSeconds() < 10 ? '0' : '') + date.getSeconds(); } break;
            case 'u': { var m = date.getMilliseconds(); return (m < 10 ? '00' : (m < 100 ? '0' : '')) + m; } break;
            // Timezone
            case 'e': { return 'Not Yet Supported'; } break;
            case 'I': { return 'Not Yet Supported'; } break;
            case 'O': { return (-date.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(date.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(date.getTimezoneOffset() / 60)) + '00'; } break;
            case 'P': { return (-date.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(date.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(date.getTimezoneOffset() / 60)) + ':00'; } break;
            case 'T': { var m = date.getMonth(); date.setMonth(0); var result = date.toTimeString().replace(/^.+ \(?([^\)]+)\)?$/, '$1'); date.setMonth(m); return result; } break;
            case 'Z': { return -date.getTimezoneOffset() * 60; } break;
            // Full Date/Time
            case 'c': { return this.formatDate(date, 'Y-m-d\\TH:i:sP'); } break;
            case 'r': { return this.formatDate(date, 'D, d M Y H:i:s O'); } break;
            case 'U': { return date.getTime() / 1000; } break;
            default: { return curChar; } break;
        }
    }
    getWeekNo(date: Date) {
        var d: any = new Date(+date);
        d.setHours(0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        var dd: any = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d - dd) / 8.64e7) + 1) / 7);
    }
    getYearList() {
        var yearList = [];
        for (var index = 2000; index <= 2100; index++) {
          var item = {
            ValueInt: index,
            Text: index.toString()
          };
          yearList.push(item);
        }
        return yearList;
      }
      getMonthList() {
        var monthList = [];
        for (var index = 1; index <= 12; index++) {
          var item = {
            ValueInt: index,
            Text: this.regional[this.selLanguage].monthNames[index - 1]
          };
          monthList.push(item);
        }
        return monthList;
      }
}
