export class ParentShareDocInfo {
    ParentSharedDataId:number;
    Name:string;
    DocumentId:number;
    SchoolCategoryIdList:Array<any>;
    PurposeOfPostIdList:Array<any>;
    TypeOfPostId:any;
    ChallengeIdList:Array<any>;
    IsActive:boolean;
    Url:string;
    StateIdList:Array<any>;

}
export class ParentShareDocView {
    ErrorMessage:string;
    FileInfo:string;
    IsSave:boolean;
    ParentShareDocInfo:ParentShareDocInfo;
    ChallengeInfo:[];
    PurposeOfPostInfo:[];
    SchoolCategoryInfo:[];
    StateId:[];
    TypeOfPostInfo:[];

}
export class ParentSettingInfo{
    BranchId:number;
    ParentSettingId:number; 
    SchoolCategoryId:number;
    ChallengeIdList:Array<any>;
    PurposeOfPostIdList:Array<any>;
    SchoolId:number;
    StateId:number;
    UserId:number;
}
export class ParentSettingView {
    ErrorMessage:string;
    IsSave:boolean;
    ParentSettingInfo:ParentSettingInfo;
    ChallengeInfo:[];
    PurposeOfPostInfo:[];
    SchoolCategoryInfo:[];
    StateInfo:[];
    TypeOfPostInfo:[];

}


