import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { FeedbackData } from '../shared/feedback.model';
import { FeedbackService } from '../shared/feedback.service';
import { NotificationService } from '../shared/notification.service';
import { UserService } from '../shared/user.service';
import { EditFeedbackComponent } from './edit-feedback.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-student-feedbacklist',
    templateUrl: './student-feedbacklist.component.html',
    styleUrls: ['./student-feedbacklist.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, BsDatepickerModule, AgGridModule, TranslateModule]
})
export class StudentFeedbacklistComponent implements OnInit {

  list: any = [];
  genderlist: any = [];
  role: string = '';
  CUSTOMCONST:any;
  datepickerConfig: BsDatepickerConfig;
  
  feedbackRowData:any;
  feedbackRowSelection;
  feedbackDefaultColDef;
  feedbackColumnDefs;
  feedbackGridOptions:any;
  feedbackGridApi;
  feedbackGridColumnApi
  points:number;
  daypoints:number;
  weekpoints:number;
  monthpoints:number;
  frameworkComponents;

  constructor(public service: UserService, public bsModalRef: BsModalRef, public modalService: BsModalService,
    private notificationServ: NotificationService, private commonService: CommonService, 
    public userService:UserService, public feedbackServ:FeedbackService) {

      this.commonService.RefreshItemList$.subscribe(
        item => {
          this.getStudentFeedback();
        }
      );

      this.CUSTOMCONST = CUSTOMCONST;
      this.datepickerConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, showTodayButton: false });
      this.commonService.RefreshGrid$.subscribe(
        item => {
          this.getStudentFeedback();
        }
      );
     }

  ngOnInit(): void {
    // this.feedbackServ.formData = new FeedbackData();
    this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData = res;
      this.userService.formData = res;
      this.genderlist = res.GenderList;
      this.role = res.UserInfo.Role;
      this.getStudentFeedback();
    });

    if(localStorage.getItem('UserRole') == "Admin"){
      this.feedbackColumnDefs = [
        { 
          headerName:"Action",
          field: 'FeedbackId', cellRendererFramework: CustommenuComponent, 
          cellRendererParams : { 
            MenuList : [
              {Name: "Edit", "Click" : this.editFeedback , that : this}, 
              {Name: "Delete", "Click" : this.deleteFeedback , that : this}
            ]
          },
          
          // filter:false, width:100,maxWidth:100, 
          // headerComponent: 'customHeader', 
          // headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false} 
        }, 
        
        { headerName:"Given", field: 'CreatedByName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'Feedback', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        
      ];
      
    }
    else{
      this.feedbackColumnDefs = [
  
        { headerName:"Given", field: 'CreatedByName', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'Feedback', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        
      ];
    }
    this.frameworkComponents = { 
      customHeader: CustomHeader 
    };
  this.feedbackDefaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  this.userService.getstudentpointsbyId(this.list[0].id).subscribe((res:any) => {
    this.points = res.filter(item=>item.Type == 'Total')[0].Points;
    this.daypoints = res.filter(item=>item.Type == 'Day')[0].Points;
    this.weekpoints = res.filter(item=>item.Type == 'Week')[0].Points;
    this.monthpoints = res.filter(item=>item.Type == 'Month')[0].Points;
    
  }); 
  }
  feedbackRowSelectionChanged(event){

  }
onFeedbackReady(params) {
  
  this.feedbackGridApi = params.api;
  this.feedbackGridColumnApi = params.columnApi;
 
}
onFeedbackReload(data){
  this.feedbackRowData = data;
}
  getStudentFeedback(){
    var config = {
      params: {
        userId :  this.service.formData.UserInfo.UserId,
        schoolId: this.service.formData.UserInfo.SchoolId,
      }
    };
    this.feedbackServ.getStudentFeedbacks(config).subscribe((res:any) => {
      this.feedbackRowData = res;
    });
  }
  editFeedback(feedbackId, that){
    that.openFeedbakModal(feedbackId);    
  }
  
  deleteFeedback(feedbackId:any, that:any){
    that.feedbackServ.formData = that.feedbackRowData.find(item=>item.FeedbackId == feedbackId);
    that.feedbackServ.formData.IsActive = false;
  that.feedbackServ.savefeedback().subscribe((res:any)=>{
      if(res.IsSaved){
        that.getStudentFeedback();
      //   that.notificationServ.ShowInfo('Successfully delete!');
      //   that.FeedbackSer.getFeedback().subscribe((res: any) => {
      //     that.feedbackRowData =  res;
      // });
      }else{
        that.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  openFeedbakModal(Id){
    var config = {
      params: {
        userId :  this.service.formData.UserInfo.UserId,
        schoolId: this.service.formData.UserInfo.SchoolId,
        feedbackId:Id
      }
    };
    const initialState = {
      list: [
        {id:this.list[0].id},
        {config}
      ]
    };
    // this.bsModalRef = this.modalService.show(EditFeedbackComponent,{initialState, class: 'modal-xl col-12'});
    this.bsModalRef = this.modalService.show(EditFeedbackComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });

  }
}
