import { Component, OnInit } from '@angular/core';
import { faBarChart, faCalendarDays, faFaceRollingEyes, faFileInvoiceDollar, faPoll, faQrcode, faShippingFast, faTasks, faUsd, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';


@Component({
    selector: 'app-admin-sidebar',
    templateUrl: './admin-sidebar.component.html',
    styleUrls: ['./admin-sidebar.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgClass, FontAwesomeModule]
})
export class AdminSidebarComponent implements OnInit {

  Isfliph:boolean=false;
  constructor() { 
 
  }

  calendarIcon = faCalendarDays;
  currencyIcon = faUsd;
  liveClassIcon= faVideoCamera;
  tasksIcon = faTasks;
  assesmentIcon=faFaceRollingEyes;
  serveyIcon=faPoll;
  analyticsIcon=faBarChart;
  trackingIcon=faShippingFast;
  billingIcon=faFileInvoiceDollar;
  qrcodeIcon = faQrcode;

  ngOnInit(): void {
    
  }
  toggleSidebar(){
    this.Isfliph = document.querySelector(".sidebar.fliph")!= null?false:true
  }
  
  
}
