<div class="dashboardInnerPage feedbackPage">
    <div class="feedback-form">
        <div class="feedback-icon">
            <img src="../../../assets/images/dashboard-icons/feedback-icon.png" alt="Feedback Icon">
        </div>
        <h5>Give Feedback</h5>
        <p>Please rate your experience</p>

        <div class="rating-feild">
            <div [ngClass]="{'rating-star': true,'selected' : rating >= 1}">
                <input type="checkbox" id="rating01" class="round-checkbox" name="rate1" [checked]="rating >= 1"  (change)="rate(1)"/>
                <label for="rating01" class="bi bi-star"></label>
            </div>
            <div [ngClass]="{'rating-star': true,'selected' : rating >= 2}">
                <input type="checkbox" id="rating02" class="round-checkbox" name="rate1" [checked]="rating >= 2" (change)="rate(2)"/>
                <label for="rating02" class="bi bi-star"></label>
            </div>
            <div [ngClass]="{'rating-star': true,'selected' : rating >= 3}">
                <input type="checkbox" id="rating03" class="round-checkbox" name="rate1" [checked]="rating >= 3" (change)="rate(3)"/>
                <label for="rating03" class="bi bi-star"></label>
            </div>
            <div [ngClass]="{'rating-star': true,'selected' : rating >= 4}">
                <input type="checkbox" id="rating04" class="round-checkbox" name="rate1" [checked]="rating >= 4" (change)="rate(4)"/>
                <label for="rating04" class="bi bi-star"></label>
            </div>
            <div [ngClass]="{'rating-star': true,'selected' : rating >= 5}">
                <input type="checkbox" id="rating05" class="round-checkbox" name="rate1" [checked]="rating >= 5" (change)="rate(5)"/>
                <label for="rating05" class="bi bi-star"></label>
            </div>
        </div>

        <div class="comments-col">
            <h3>Additional Comments</h3>
            <textarea [(ngModel)]="feedback" placeholder="Type your message..."></textarea>
            <button (click)="submit()">Submit</button>
        </div>
    </div>
</div>