import { formatDate, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomHeader } from '../custommenu/custom-header.component';
import { CustommenuComponent } from '../custommenu/custommenu.component';
import { CommonService } from '../shared/common.service';
import { LeaveService } from '../shared/leave.service';
import { UserService } from '../shared/user.service';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-leave',
    templateUrl: './leave.component.html',
    styleUrls: ['./leave.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgFor, FormsModule, AgGridModule]
})
export class LeaveComponent implements OnInit {
  frameworkComponents;
  leaveRowData:any;
  leaveRowSelection;
  leaveDefaultColDef;
  leaveColumnDefs;
  leaveGridOptions:any;
  gridApi;
  gridColumnApi;


  config:any = {
    params: {
      userId: 0,
      startDate:'',
      endDate:'',

    }
  };
  yearList:any[]=[];
  selectedYear:string=''
  selectedChileId:number=0;
  childrenList:any=[];
  userData:any=[];
  urlSafe: SafeResourceUrl;
  constructor(public userService:UserService, public commonService:CommonService,
     private leaveServ:LeaveService,public sanitizer: DomSanitizer,public router:Router) {
      this.commonService.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.selectedChileId=this.commonService.selectedChileId?this.commonService.selectedChileId:0;
      this.commonService.RefreshGrid$.subscribe(
        item => {
          this.getuserleaveinfo();
         }
      );
      }

  ngOnInit(): void {
    this.getStartAndEndDate();
    this.getMyInfo();
    this.greadReady();
  }
  selectChild(id:any){
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    this.config.params.userId = id;
    if(id>0){
      this.commonService.selectedChileId = this.selectedChileId;
    this.getuser();
    }else{
      this.router.navigateByUrl('/add-child');
    }
   
  }
  greadReady() {

    this.leaveColumnDefs = [
      {
        headerName: "LeaveId",
        field: 'LeaveId', cellRendererFramework: CustommenuComponent,
        cellRendererParams: { MenuList: [{ Name: "Edit", "Click": this.editLeave, that: this }, { Name: "Delete", "Click": this.deleteLeave, that: this }] },

        filter: false, width: 70, maxWidth: 70, minWidth: 70,
        headerComponent: 'customHeader',
        headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      { field: 'UserName', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'LeaveType', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { field: 'Reason', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
      { headerName: "StartDate", field: 'StartDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 120 },
      { field: 'EndDate', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'LeaveStatus', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
      { field: 'IsFullDayLeave', sortable: true, filter: 'agTextColumnFilter', suppressMenu: true, menuTabs: [], floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120, maxWidth: 100 },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader
    };
    this.leaveDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getMyInfo(){
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      if(this.commonService.selectedChileId<=0)
      {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      this.childrenList =  res.ParentInfo.ChildrenList;
      // this.getAllStudentsQuestionsById();


      this.getuser();
      
    });
  }
  changeChild(){
    this.commonService.selectedChileId = this.selectedChileId;
    // this.getstudentfeeinfo(this.selectedChileId);
}
  getuser(){
    var userid = this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.config.params.userId =this.commonService.selectedChileId>0?this.commonService.selectedChileId:0;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData =  res; 
     
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.UserInfo.CroppedFilepath);
      // this.getleaveinfo();
      this.getuserleaveinfo();
    });
  }
  getuserleaveinfo(){
    this.leaveServ.getuserleaveinfo(this.config).subscribe((res:any)=>{
      this.leaveRowData = res.LeaveListInfo;
      console.log(res);
      
    })
  }
  
  getStartAndEndDate(){
    this.commonService.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config.params.startDate =res[0].Code;
      this.config.params.endDate= '30-04-'+(parseInt(formatDate(new Date(res[0].Code), 'yyyy', 'en'))+1);
     
    });
  }
  serchLeave(){
    this.getuserleaveinfo();
  }
  changeYear(){
    this.config.params.startDate =this.selectedYear;
      this.config.params.endDate= '30-04-'+(parseInt(formatDate(new Date(this.selectedYear), 'yyyy', 'en'))+1);
    
  }



  onLeaveReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getuserleaveinfo();
    }, 300);


  }

  onLeaveReload(data) {
    this.leaveRowData = data;
  }
  deleteLeave(that) {

  }
  editLeave(that) {

  }
}
