import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";
import { SmsAuthenticateComponent } from "./sms-authenticate/sms-authenticate.component";
import { UserComponent } from "./user/user.component";
import { SchoolComponent } from "./school/school.component";
import { TestComponent } from "./test/test.component";
import { AuthGuard } from "./auth/auth.gaurd.service";
import { CourseDetailsComponent } from "./course-details/course-details.component";
import { LifeSkillsComponent } from "./life-skills/life-skills.component";
import { AdminComponent } from "./admin/admin.component";
import { DasboardComponent } from "./admin/dasboard/dasboard.component";
import { ValueEducationComponent } from "./course-details/value-education/value-education.component";
import { QuestionComponent } from "./question/question.component";
import { StuDashboardComponent } from "./student/stu-dashboard/stu-dashboard.component";
import { TeacherDashboardComponent } from "./teacher/teacher-dashboard/teacher-dashboard.component";
import { CreateAssignmentComponent } from "./assignment/create-assignment.component";
import { EventComponent } from "./event/event.component";
import { ChatComponent } from "./chat/chat.component";
import { ChatboxComponent } from "./chat/chatbox.component";
import { BenefitsComponent } from "./benefits/benefits.component";
import { FeaturesComponent } from "./features/features.component";
import { FaqComponent } from "./faq/faq.component";
import { UnusedfilesComponent } from "./school/unusedfiles.component";
import { AttendenceComponent } from "./attendance/attendance.component";
import { FileManagementComponent } from "./school/file-management.component";
import { ParentDashboardComponent } from "./parent/parent-dashboard.component";
import { AboutComponent } from "./about/about.component";
import { PartnersComponent } from "./partners/partners.component";
import { AdmindashboardComponent } from "./admin/admindashboard/admindashboard.component";
import { FeedbacklistComponent } from "./feedback/feedbacklist.component";
import { UserListComponent } from "./user/user-list.component";
import { SurveyComponent } from "./survey/survey.component";
import { PasswordResetComponent } from "./login/password-reset.component";
import { AnalysisesComponent } from "./analysis/analysises.component";
import { OwnDocumentsComponent } from "./fileManagement/own-documents.component";
import { SelfRegistrationComponent } from "./user/self-registration.component";
import { PaymentsComponent } from "./payments/payments.component";
import { TermComponent } from "./term/term.component";
import { PaymentComponent } from "./payment/payment.component";
import { EditChildComponent } from "./parent/edit-child.component";
import { HomeWorkComponent } from "./parent/child/home-work.component";
import { ResultComponent } from "./parent/child/result.component";
import { UserFeeInfoComponent } from "./payments/user-fee-info.component";
import { RegistrationComponent } from "./student/registration.component";
import { ShareAndEarnComponent } from "./share-and-earn/share-and-earn.component";
import { ParentCalendarComponent } from "./parent/parent-calendar.component";
import { AttandanceCalComponent } from "./calendar/attandance-cal.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { ContactListComponent } from "./registers/contact-list.component";
import { PartnerListComponent } from "./registers/partner-list.component";
import { RegistrationListComponent } from "./registers/registration-list.component";
import { TeacherListComponent } from "./teacher-list/teacher-list.component";
import { StudentListComponent } from "./student-list/student-list.component";
import { ClassListComponent } from "./class-list/class-list.component";
import { SubjectListComponent } from "./subject-list/subject-list.component";
import { InstituteLogoComponent } from "./scgool/institute-logo.component";
import { GeneralDetailsComponent } from "./school/general-details.component";
import { LeaveComponent } from "./leave/leave.component";
import { TeacherLeaveComponent } from "./leave/teacher-leave/teacher-leave.component";
import { TeacherComponent } from "./leave/admin/teacher/teacher.component";
import { StudentComponent } from "./leave/admin/student/student.component";
import { TimeTableComponent } from "./timetable/time-table/time-table.component";
import { TimeTablesComponent } from "./timetable/teacher/time-tables/time-tables.component";
import { ClassDairyComponent } from "./dairy/class-dairy/class-dairy.component";
import { StudentDairyComponent } from "./dairy/student-dairy/student-dairy.component";
import { SchoolSharedDocComponent } from "./fileManagement/school-shared-doc.component";
import { ParentShareDocComponent } from "./fileManagement/parent-share-doc.component";
import { SchoolSettingComponent } from "./schoolsetting/school-setting.component";
import { QRCodeListComponent } from "./QRCode/qrcode-list.component";
import { AchievementsComponent } from "./achievements/achievements.component";
import { QrcoderedirectComponent } from "./QRCode/qrcoderedirect.component";
import { ProfileComponent } from "./student-profile/profile.component";
import { ProfileTestPageComponent } from "./student-profile/profile-test-page/profile-test-page.component";
import { ParentsettingComponent } from "./parentsetting/parentsetting.component";
import { BadgeComponent } from "./badge/badge.component";
import { BadgeListComponent } from "./badge/badge-list.component";
import { SupportComponent } from "./about/support.component";
import { TermsOfServiceComponent } from "./about/terms-of-service.component";
import { PrivacyPolicyComponent } from "./about/privacy-policy.component";
import { PaymentsuccessComponent } from "./paymentsuccess/paymentsuccess.component";
import { PaymentcancelComponent } from "./paymentcancel/paymentcancel.component";
import { CancellationComponent } from "./cancellation/cancellation.component";
import { NewAssesmentsComponent } from "./new-assesments/new-assesments.component";
import { StudentAssignmentsComponent } from "./assignment/student-assignments.component";
import { MyProfileComponent } from "./student-profile/my-profile/my-profile.component";
import { QuezComponent } from "./question/quez.component";
import { CompleteAssessmentComponent } from "./complete-assessment/complete-assessment.component";
import { WebrtcComponentComponent } from "./webrtc/webrtc-component/webrtc-component.component";
import { ScreenrecorderComponent } from "./webrtc/screenrecorder/screenrecorder.component";
import { FeedbackComponentComponent } from "./webrtc/feedback-component/feedback-component.component";
import { WebrtcGroupCallComponent } from "./webrtc/webrtc-group-call/webrtc-group-call.component";
import { GeneralForumComponentComponent } from "./forum/general-forum-component/general-forum-component.component";
import { QuestionDetailsComponentComponent } from "./forum/question-details-component/question-details-component.component";
import { CreateForumComponentComponent } from "./forum/create-forum-component/create-forum-component.component";
import { CreateSocialEventComponent } from "./event/create-social-event/create-social-event.component";
import { ViewSocialEventComponent } from "./event/view-social-event/view-social-event.component";

export const APP_ROUTES: Routes = [
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "self-register", component: SelfRegistrationComponent },
  { path: "home", component: HomeComponent },
  { path: "course/:id", component: CourseDetailsComponent },
  { path: "contact", component: ContactComponent },
  { path: "smsauth", component: SmsAuthenticateComponent },

  { path: "benefits", component: BenefitsComponent },
  { path: "features", component: FeaturesComponent },
  { path: "faqs", component: FaqComponent },
  { path: "about", component: AboutComponent },
  { path: "partners", component: PartnersComponent },

  { path: "support", component: SupportComponent },
  { path: "terms-of-service", component: TermsOfServiceComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "cancellation", component: CancellationComponent },
  // {path: 'user', component: UserComponent},
  // {path: 'school', component: SchoolComponent},
  { path: "test", component: TestComponent },
  {
    path: "smsauth",
    component: SmsAuthenticateComponent,
    canActivate: [AuthGuard],
  },
  { path: "user", component: UserComponent, canActivate: [AuthGuard] },
  { path: "school", component: SchoolComponent, canActivate: [AuthGuard] },
  { path: "sel", component: ValueEducationComponent },
  { path: "sel/:id", component: CourseDetailsComponent },
  { path: "lifeskills", component: LifeSkillsComponent },
  { path: "payment", component: PaymentComponent },
  { path: "admin", component: AdminComponent, canActivate: [AuthGuard] },
  {
    path: "admin/dashboard",
    component: DasboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admindashboard",
    component: AdmindashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "question", component: QuestionComponent, canActivate: [AuthGuard] },
  { path: "dashboard", component: DasboardComponent, canActivate: [AuthGuard] },
  {
    path: "studentdashboard",
    component: StuDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "teacherdashboard",
    component: TeacherDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "assignment", component: CreateAssignmentComponent },
  { path: "event", component: EventComponent, canActivate: [AuthGuard] },
  { path: "chat", component: ChatComponent, canActivate: [AuthGuard] },
  { path: "chatbox", component: ChatboxComponent, canActivate: [AuthGuard] },

  {
    path: "unusedfiles",
    component: UnusedfilesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "file-management",
    component: FileManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "own-documents",
    component: OwnDocumentsComponent,
    canActivate: [AuthGuard],
  },
  { path: "attendence", component: AttendenceComponent },
  {
    path: "parentdashboard",
    component: ParentDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "feedback", component: FeedbacklistComponent },
  { path: "survey", component: SurveyComponent, canActivate: [AuthGuard] },
  { path: "user-list", component: UserListComponent, canActivate: [AuthGuard] },
  {
    path: "teacher-list",
    component: TeacherListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "student-list",
    component: StudentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "class-list",
    component: ClassListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "subject-list",
    component: SubjectListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reset-password",
    component: PasswordResetComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "analysis",
    component: AnalysisesComponent,
    canActivate: [AuthGuard],
  },
  { path: "terms", component: TermComponent, canActivate: [AuthGuard] },
  { path: "payments", component: PaymentsComponent, canActivate: [AuthGuard] },
  {
    path: "add-child",
    component: EditChildComponent,
    canActivate: [AuthGuard],
  },
  { path: "homework", component: HomeWorkComponent, canActivate: [AuthGuard] },
  { path: "result", component: ResultComponent, canActivate: [AuthGuard] },
  {
    path: "finance",
    component: UserFeeInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "registration",
    component: RegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "referral",
    component: ShareAndEarnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact-list",
    component: ContactListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "partner-list",
    component: PartnerListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "registration-list",
    component: RegistrationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "institute-logo",
    component: InstituteLogoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "general-details",
    component: GeneralDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "teacher-leave",
    component: TeacherLeaveComponent,
    canActivate: [AuthGuard],
  },

  { path: "child-leave", component: LeaveComponent, canActivate: [AuthGuard] },
  {
    path: "getstudent-leave",
    component: StudentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "getteacher-leave",
    component: TeacherComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "time-table",
    component: TimeTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "time-tables",
    component: TimeTablesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "class-dairy",
    component: ClassDairyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "getdairy",
    component: StudentDairyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "school-shared-doc",
    component: SchoolSharedDocComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "parent-share-doc",
    component: ParentShareDocComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "school-seting",
    component: SchoolSettingComponent,
    canActivate: [AuthGuard],
  },
  { path: "qrcode", component: QRCodeListComponent, canActivate: [AuthGuard] },
  {
    path: "achievements",
    component: AchievementsComponent,
    canActivate: [AuthGuard],
  },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "profile-test",
    component: ProfileTestPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "parent-setting",
    component: ParentsettingComponent,
    canActivate: [AuthGuard],
  },
  { path: "badge", component: BadgeComponent, canActivate: [AuthGuard] },
  {
    path: "badge-list",
    component: BadgeListComponent,
    canActivate: [AuthGuard],
  },

  { path: "paymentSuccessful", component: PaymentsuccessComponent },
  { path: "paymentCancel", component: PaymentcancelComponent },
  { path: "calendar", component: CalendarComponent },
  { path: "qrcoderedirect", component: QrcoderedirectComponent },
  { path: "assessments", component: NewAssesmentsComponent },
  { path: "myassessment/:id", component: CompleteAssessmentComponent },
  { path: "assignments", component: StudentAssignmentsComponent },
  { path: "myprofile", component: MyProfileComponent },
  { path: "live-class", component: WebrtcComponentComponent },

  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "recordScreen", component: ScreenrecorderComponent },

  { path: "recordScreen", component: ScreenrecorderComponent },
  { path: "call-feedback", component: FeedbackComponentComponent },
  { path: "webrtc-group-call", component: WebrtcGroupCallComponent },

  { path: "forum", component: GeneralForumComponentComponent },
  { path: "forum-question-details/:id", component: QuestionDetailsComponentComponent },
  { path: "create-forum", component: CreateForumComponentComponent },
  { path: "create-social-event", component: CreateSocialEventComponent },
  { path: "view-social-event", component: ViewSocialEventComponent },
];
