<!-- Outer container for iframe and buttons -->

<div class="iframeOuter">

    <!-- Button to start recording -->

    <button style="display: flex;" id="captureBtn" class="record-button " title="Start Recording">
        <span class="fa fa-dot-circle"></span>
    </button>
    <!-- Button to stop recording (initially hidden) -->

    <button style="display: none;" id="stopBtn" class="record-button active" title="Stop Recording">
        <span class="fa fa-dot-circle"></span>
    </button>

    <a id="download" href="#" style="display: none;" class="addAvailability">Download</a>
</div>
<style>
    .record-button {
        background-color: rgb(19, 19, 19);
        height: 50px;
        width: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }

    .record-button span {
        color: #fff;
        font-size: 20px;
    }

    .iframeOuter {
        background: #181818;
    }

    .record-button.active {
        background-color: rgb(134, 8, 8);
    }
</style>