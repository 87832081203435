export class Contact {
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: string;
  Description: string;
  MasterListDataInfo: MasterListData;
  JobTitle: string;
  SchoolName: string;
  SchoolType: string;
  City: string;
  Country: string;
  Source: string;
  RoleId:number;
}
export class ContactResponse {
  status: boolean;
  message: string;
}
export class Partner {
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: string;
  Description: string;
  PartnerFor: string;
}
export class PartnerResponse {
  status: boolean;
  message: string;
}
export enum ToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
  // Other positions you would like
}
export class MasterListData {
  MLInfo: MLData;
  TableName: string;
  IsSaved: boolean;
  ErrorMessage: string;
}
export class MLData {
  Code: string;
  Name: string
  IsActive: boolean;
  ParentGroupId: number;
  ClassId: number;
  SubjectId: number;
  Id: number;
}
export class UserInfo {
  DOB: string;
  Email: string;
  FirstName: string;
  GenderId: number;
  MiddleName: string;
  MobileNo: number;
  Password: string;
  RoleId: number;
  SchoolId: number;
  SchoolName: string;
  Surname: string;
  UserId: number;
  UserName: string;
  Role:string;
  Mobile:string;
  Description:string;
  IsUserReferred:boolean;
}
export class UserRegisterData{
  UserInfo:UserInfo;
  IsSaved:boolean;
}
export class DemoRequestViewData{
  
}