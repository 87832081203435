import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from '../shared/Const';
import { UserService } from '../shared/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
@Component({
  selector: 'app-user-academic-year-log',
  templateUrl: './user-academic-year-log.component.html',
  styleUrls: ['./user-academic-year-log.component.scss'],
  standalone:true,
  imports:[BrowserAnimationsModule, FormsModule, AgGridModule]
})
export class UserAcademicYearLogComponent implements OnInit {
list:any[];
gridApi;
  gridColumnApi;
  frameworkComponents;
  ColumnDefs;
  DefaultColDef;
  RowData;
  RowSelection;
  GridOptions:any;
  CUSTOMCONST:any=CUSTOMCONST;
  constructor(public service:UserService, public commonServ:CommonService,  public modalService:BsModalService,
    public bsModalRef: BsModalRef) {
      this.ColumnDefs = [
        { field: 'CreatedBy', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'CreatedOn', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120  },
        { field: 'NewAcademicYear', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
        { field: 'OldAcademicYear', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 200  },
    ];
  }

  ngOnInit(): void {
    this.getuseracademicyearloginfo();
  }
  getuseracademicyearloginfo(){
    this.service.getuseracademicyearloginfo(this.list[0].id).subscribe((res : any) => {
      this.RowData =  res;
     });
  }
  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() =>  {
      this.getuseracademicyearloginfo();
    }, 300);
  }
  onSelectionChanged(event){

  }
}
