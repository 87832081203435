export class AdminShareDocInfo {
    AdminSharedDataId:number;
    Name:string;
    DocumentId:number;
    SchoolCategoryIdList:Array<any>;
    PurposeOfPostIdList:Array<any>;
    TypeOfPostId:any;
    ChallengeIdList:Array<any>;
    IsActive:boolean;
    Url:string;
    StateIdList:Array<any>;
}
export class AdminShareDocView {
    ErrorMessage:string;
    FileInfo:string;
    IsSave:boolean;
    AdminShareDocInfo:AdminShareDocInfo;
    ChallengeInfo:[];
    PurposeOfPostInfo:[];
    SchoolCategoryInfo:[];
    StateId:[];
    TypeOfPostInfo:[];

}
