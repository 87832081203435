import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CustomHeader } from "src/app/custommenu/custom-header.component";
import { CustommenuComponent } from "src/app/custommenu/custommenu.component";
import { AssignClassesComponent } from "src/app/question/assign-classes/assign-classes.component";
import { QuestioneditComponent } from "src/app/question/questionedit.component";
import { AssignamentView, Assignemnt } from "src/app/shared/assignemnt.model";
import { AssignemntService } from "src/app/shared/assignemnt.service";
import { QuestionList } from "src/app/shared/question.model";
import { QuestionService } from "src/app/shared/question.service";
import { UserService } from "src/app/shared/user.service";
import { ViewdocumentComponent } from "src/app/assignment/viewdocument.component";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { formatDate, NgClass, NgIf, NgFor } from "@angular/common";
import { EventService } from "src/app/shared/event.service";
import { EditeventComponent } from "src/app/event/editevent.component";
import { CustomGrideCelComponent } from "src/app/custommenu/custom-gride-cel/custom-gride-cel.component";
import { CommonService } from "src/app/shared/common.service";
import { NotificationService } from "src/app/shared/notification.service";
import { CUSTOMCONST } from "src/app/shared/Const";
import { EditprofileComponent } from "src/app/user/editprofile.component";
import { FormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { MatTabsModule } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { CustomDatePipe } from "src/app/shared/custom-date.pipe";

@Component({
  selector: "app-teacher-dashboard",
  templateUrl: "./teacher-dashboard.component.html",
  styleUrls: ["./teacher-dashboard.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    MatTabsModule,
    NgIf,
    AgGridModule,
    BsDatepickerModule,
    FormsModule,
    NgFor,
    CustomDatePipe
],
  // providers: [CustomDatePipe] 
})
export class TeacherDashboardComponent implements OnInit {
  // formData : QuestionViewData;
  subjectlist: any = [];
  topiclist: any = [];
  questiontypelist: any = [];
  public inlineDate: any;
  gridApi;
  gridColumnApi;
  gridEventApi;
  gridEventColumnApi;
  frameworkComponents;
  columnDefs;

  defaultColDef;
  rowSelection;
  rowData: QuestionList[];
  gridOptions: any;
  datepickerConfig: BsDatepickerConfig;

  eventsRowData: any;
  eventRowSelection;
  eventDefaultColDef;
  eventColumnDefs;
  eventGridOptions: any;
  // maxDate = new Date();
  // minDate = new Date();
  bsValue = new Date();
  // bsRangeValue: Date[];
  date: any;
  typeList: any;
  assignmentList: Assignemnt[];
  assignmentListByDate: Assignemnt[];
  watchList: any[] = [];
  listenList: any[] = [];
  readList: any[] = [];
  doList: any[] = [];
  homeWorkList: any[] = [];
  eventStartTime: any;
  eventEndTime: any;
  activeTab: string;
  eventsData: any[] = [];
  router: any;

  public bsModalRef: BsModalRef;
  constructor(
    public eventService: EventService,
    public service: QuestionService,
    public assignedService: AssignemntService,
    private modalService: BsModalService,
    public userService: UserService,
    public commonServ: CommonService,
    private notificationServ: NotificationService,
    // private customDatePipe: CustomDatePipe
  ) {
    this.router = Router;
    this.activeTab = "Academics";
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: false,
      minMode: "day",
    });
    // this.minDate.setDate(this.minDate.getDate() - 0);
    // this.maxDate.setDate(this.maxDate.getDate() + 30);
    // this.bsRangeValue = [this.bsValue, this.maxDate];
    //this.inlineDate = formatDate(new Date(),'dd-MM-yyyy','en');
    this.inlineDate = new Date();
    this.columnDefs = [
      {
        headerName: "QuestionMasterId",
        field: "QuestionMasterId",
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Edit", Click: this.editQuestion, that: this },
            { Name: "Delete", Click: this.deleteQuestion, that: this },
            { Name: "Assign Classes", Click: this.assignClasses, that: this },
          ],
        },

        filter: false,
        width: 60,
        maxWidth: 60,
        headerComponent: "customHeader",
        headerComponentParams: {
          button: "click",
          enableMenu: true,
          menuIcon: "fa-plus",
          enableSorting: false,
        },
      },
      {
        field: "Name",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Subject",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Topic",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "NoOfQuestions",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
    ];

    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";

    this.eventColumnDefs = [
      {
        field: "ClassName",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        headerName: "Date",
        field: "EventDate",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 120,
      },
      {
        field: "StartTime",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },
      {
        field: "EndTime",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },

      {
        headerName: "Get URL",
        field: "EventId",
        cellRendererFramework: CustomGrideCelComponent,
        maxWidth: 100,
        minWidth: 100,
        cellRendererParams: {
          enableMeetingLink: true,
          enableButton: false,
          celName: [{ Name: "Join", Click: this.getURL, that: this }],
        },
      },
      // {
      //   headerName:"ClassId",
      //   field: 'IsActive',  cellRendererFramework: CustomControlsComponent,
      //   cellRendererParams : {enableMeetingURL : true, controlList : [{Name: "Join",  that:this}] },
      //   filter:false, width:60,maxWidth: 60,

      // },
    ];
    this.eventDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: false,
      floatingFilter: false,
    };

    this.commonServ.RefreshGrid$.subscribe((item) => {
      if (this.activeTab === "Academics") {
        this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
          const rowData = res;
          if (rowData.length) {
            this.eventsData = rowData;
          }
        });
      } else {
        this.eventsRowData = [];
      }
    });
  }

  ngOnInit(): void {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.userService.formData.UserInfo.CroppedProfileFilepath =
          res.UserInfo.CroppedProfileFilepath.replace(re, "/");
      }
    });
    this.getassignments();
    this.onEventReady();
    // $(document).ready(function () {
    //   $('[data-bs-toggle="tooltip"]').tooltip();
    // });
    // this.date = new Date();
    // const formattedDate = this.datePipe.transform(this.date, 'shortTime');
    // console.log('Formatted Date:', formattedDate);
  }
  getassignments() {
    this.assignedService.getassignedassignments().subscribe((res: any) => {
      if (this.activeTab === "Academics") {
        this.assignmentList = res.filter((item) => item.IsAcademics == true);
      } else if (this.activeTab === "SEL") {
        this.assignmentList = res.filter((item) => item.IsHumanityLife == true);
      } else if (this.activeTab === "Life Skills") {
        this.assignmentList = res.filter((item) => item.IsSkills == true);
      } else if (this.activeTab === "Outside The Box") {
        this.assignmentList = res.filter(
          (item) => item.IsLearnOutOfBox == true
        );
      }
      // this.assignmentList = res;
      this.watchList = this.assignmentList.filter(
        (item) => item.Type == "Watch"
      );
      this.listenList = this.assignmentList.filter(
        (item) => item.Type == "Listen"
      );
      this.readList = this.assignmentList.filter((item) => item.Type == "Read");
      this.doList = this.assignmentList.filter((item) => item.Type == "Do");
      // this.homeWorkList = this.assignmentListByDate.filter(item => item.Type == 'Home work');
      // this.assignedService.formData.AssignmentInfo.Type
      // this.watchList[0].Id
      // this.listenList.length
    });
  }
  addEvent(event: any) {}
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.date = formatDate(this.date, "dd-MM-yyyy", "en");
    this.service.getquestionlist(this.date).subscribe((res: QuestionList[]) => {
      const rowData = res;
      this.gridApi.setRowData(rowData);
      // this.rowData =  res;
    });
  }
  onEventReady() {
    this.date = new Date();
    this.date = formatDate(this.date, "dd-MM-yyyy", "en");
    // this.gridEventApi = params.api;
    // this.gridEventColumnApi = params.columnApi;
    // this.eventService.getEvents().subscribe((res : any) => {
    //   this.eventsRowData =  res;
    // });
    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      const rowData = res;
      this.eventsData = rowData;
      // this.gridEventApi.setRowData(rowData);
    });
  }
  // getAssignedData(params) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  //   this.assignmentService.getassignedassignments().subscribe((res : AssignamentView) => {
  //     this.assignmentRowData = res;
  //     this.typeList = res.AssignmentTypeList;
  //   });
  // }
  editQuestion(QuestionId, that) {
    that.openquestionmodal(QuestionId);
  }
  assignClasses(QuestionId, that) {
    that.openAssignClassesModal(QuestionId);
  }

  deleteQuestion(QuestionId, that) {}

  openquestionmodal(questionId: number) {
    const initialState = {
      list: [{ id: questionId }],
    };
    this.bsModalRef = this.modalService.show(QuestioneditComponent, {
      initialState,
      class: "modal-xl col-12",
      ignoreBackdropClick: true,
    });
  }
  openAssignClassesModal(questionId: number) {
    const initialState = {
      list: [{ id: questionId }],
    };
    this.bsModalRef = this.modalService.show(AssignClassesComponent, {
      initialState,
      class: "modal-xl col-12",
      ignoreBackdropClick: true,
    });
  }
  onSelectionChanged($event) {
    // var selectedRows = this.gridApi.getSelectedRows();
  }
  openModel(data) {
    const initialState = {
      list: [{ data: data }],
    };
    this.bsModalRef = this.modalService.show(ViewdocumentComponent, {
      id: CUSTOMCONST.STDASSIGNMENT_MODAL,
      initialState,
      class: "modal-lg mb-0",
      ignoreBackdropClick: true,
    });
    // if(data.Status == "New"){
    //   const initialState = {
    //     list: [
    //       {data:data}
    //     ]
    //   };
    //   this.bsModalRef = this.modalService.show(ViewdocumentComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
    // }else if(data.Status == "Completed"){
    //   this.notificationServ.ShowError('You already Completed this assignment');
    // }
    // else{
    //   this.notificationServ.ShowError('Last date of submission has been expired');
    // }
  }
  onDateChange(e: Event) {
    
    this.date = e;
    this.date = formatDate(this.date, "dd-MM-yyyy", "en");
    console.log(this.date);
    
    this.getassignments();
    this.assignedService
      .getassignedassignmentsbydate(this.date)
      .subscribe((res: any) => {
        this.assignmentListByDate = res;
        this.watchList = this.assignmentListByDate.filter(
          (item) => item.Type == "Watch"
        );
        this.listenList = this.assignmentListByDate.filter(
          (item) => item.Type == "Listen"
        );
        this.readList = this.assignmentListByDate.filter(
          (item) => item.Type == "Read"
        );
        this.doList = this.assignmentListByDate.filter(
          (item) => item.Type == "Do"
        );
        this.homeWorkList = this.assignmentListByDate.filter(
          (item) => item.Type == "Home work"
        );
        // this.assignedService.formData.AssignmentInfo.Type
        // this.watchList[0].Id
      });

    //   this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
    this.eventService.getEvents().subscribe((res: any) => {
      const rowData = res;
      if (rowData.length) {
        this.gridEventApi.setRowData(rowData);
        this.eventsRowData = rowData;
      }
    });
    if (this.activeTab === "Academics") {
      this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
        // this.eventsRowData = res;
        const rowData = res;
        if (rowData.length) {
          // this.gridEventApi.setRowData(rowData);
          this.eventsData = res;
        }
      });

      this.service
        .getquestionlist(this.date)
        .subscribe((res: QuestionList[]) => {
          // this.rowData = res;
          const rowData = res;
          // this.gridApi.setRowData(rowData);
        });
    } else {
      this.eventsRowData = [];
      this.rowData = [];
    }
  }
  addNewEvent() {
    const initialState = {
      list: [{ id: 0 }],
      eventReload:this.onEventReady
    };
    this.bsModalRef = this.modalService.show(EditeventComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  getURL(eventData) {

    var eventStartTime = new Date(eventData.StartTimeISO);
    var eventEndTime = new Date(eventData.EndTimeISO);

    this.date = new Date();
    this.date = formatDate(new Date(), "dd-MM-yyyy HH:mm", "en");
    var currentTime = new Date();
    if (currentTime <= eventStartTime) {
      this.notificationServ.ShowError(
        "Event will start at " + eventData.EventDate + " " + eventData.StartTime
      );
    } else if (currentTime > eventEndTime) {
      this.notificationServ.ShowError("This class has been expired");
    } else {
      localStorage.setItem("eventData", JSON.stringify(eventData));
      localStorage.setItem("room_id", eventData.RoomId);
      window.open("live-class", "_blank", "noopener, noreferrer");
    }
  }
  editProfile(UserId) {
    const initialState = {
      list: [{ id: UserId }],
    };
    this.bsModalRef = this.modalService.show(EditprofileComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-xl col-12",
      ignoreBackdropClick: true,
    });
  }
  activeTabChange(event) {
    this.activeTab = event.tab.textLabel;
    this.getassignments();
    this.eventService.getEventsByDate(this.date).subscribe((res: any) => {
      const rowData = res;
      if (rowData.length) {
        // this.gridEventApi.setRowData(rowData);
        this.eventsData = rowData;
      }
    });

    this.service.getquestionlist(this.date).subscribe((res: QuestionList[]) => {
      const rowData = res;
      this.gridApi.setRowData(rowData);
    });
  }
  getIcon(str){
    const words = str.split(" ");
    var lstIdx=words.length - 1
    if (lstIdx>0) return `${words[0][0]}${words[lstIdx][0]}`
    else return 'Ki'
  }
}
