import { Injectable } from '@angular/core';
import CONFIG from './Const';
import { HttpClient } from '@angular/common/http';
import {  SchoolSettingViewInfo } from './schoolsetting.model';


@Injectable({
  providedIn: 'root'
})
export class SchoolSettingService {
  
    formData: SchoolSettingViewInfo= new SchoolSettingViewInfo();
    readonly baseUrl = CONFIG.BASE_URL+'api/user';

  constructor(private http:HttpClient) {
   
   }
   
  

getSchoolSettingInfo(schoolid:number,branchid:number){
    var config = {
        params: {
          schoolId: schoolid,
          branchId :branchid
        }
      };
    return this.http.get(this.baseUrl+'/getSchoolSettingInfo',config);
  }

  saveSchoolSettingInfo(){
      return this.http.post(this.baseUrl+'/saveSchoolSettingInfo',this.formData);
    }
}
