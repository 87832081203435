<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="list[0].id<=0">Add Class Dairy</h3>
            <h3 class="modal-title h3 m-1" *ngIf="list[0].id>0">Edit Class Dairy</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div >
                    <!-- <div  class="row form-row m-0">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">

                            <div class="form-group mb-0">
                                <label>Select Class</label>
                                <select class="form-control form-control-sm appearance-auto" name="selectedClass"
                                    [(ngModel)]="selectedClass" (change)="getSubject()">
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>Date</label>
                                <input type="text"
                                class="form-control form-control-sm"
                                placeholder="Date"
                                name="DairyDate"
                                #StartDate="ngModel"
                                [(ngModel)]="selectedDate"
                                [bsConfig]="datepickerConfig"
                                [minDate]="minDate"
                                bsDatepicker
                                timepicker
                                >
                            </div>

                          </div>


                    </div> -->
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Class{{selectedClass}}</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="selectedClass" [(ngModel)]="selectedClass" (change)="onSelectClass($event)"  required>
                                    <option *ngFor="let class of classList"  [ngValue]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>

                            </div>

                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Date{{selectedDate}}</label>
                                <input type="text" 
                                    class="form-control form-control-sm" 
                                    placeholder="{{'Date' | translate}}" 
                                    name="selectedDate"
                                    [(ngModel)]="selectedDate"
                                    [bsConfig]="datepickerConfig"
                                    [minDate]="minDate"
                                    bsDatepicker                                    
                                    >
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <button type="button" (click)="search()" class="btn btn-warning float-right" type="button" >Search</button>
                        </div>
                    </div>
                    <div class="row" *ngFor="let cdInfo of dairyServ.formData.ClassDairyInfo; let i =index">
                        <div *ngIf="cdInfo.ClassDairyMasterId === list[0].id || list[0].id <= 0">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">

                            <div class="form-group mb-0">
                                <label>Select Subject</label>
                                <select class="form-control form-control-sm appearance-auto" name="SubjectId{{i}}"
                                    [(ngModel)]="dairyServ.formData.ClassDairyInfo[i].SubjectId">
                                    <option [value]="0">Select subject</option>
                                    <option *ngFor="let subject of dairyServ.formData.SubjectInfo" [value]="subject.Id">{{subject.Name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">

                            <div class="form-group mb-0">
                                <label>Select Teacher</label>
                                <select class="form-control form-control-sm appearance-auto" name="UserId{{i}}" #TeachUserIderId="ngModel"
                                    [(ngModel)]="dairyServ.formData.ClassDairyInfo[i].UserId">
                                    <option [value]="0">Select teacher {{dairyServ.formData.ClassDairyInfo[i].UserId}}</option>
                                    <option *ngFor="let teacher of dairyServ.formData.TeacherInfo" [value]="teacher.UserId">{{teacher.FullName}}</option>
                                </select>

                            </div>
                        </div>

                          <div class="col-12">
                            <div class="form-group mb-0">
                                <label>Dairy Notes</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text" placeholder="Dairy Notes" name="DairyNotes{{i}}"
                                #DairyNotes="ngModel" [(ngModel)]="dairyServ.formData.ClassDairyInfo[i].DairyNotes"></textarea>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addClassDairyInfo()" class="btn btn-success"  type="button" >Save</button>
                <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
