<div class="reg-form d-flex align-items-center">
    <div class="container-fluid">
       
        <div class="row justify-content-c">
            
            <div class="col-xs-12 col-sm-8 col-md-5 bg-light p-4 ">
                <h1 class="display-4 text-center">{{'Register'| translate}}</h1>
                <form novalidate #form="ngForm" (submit)="onRegister(form)">
                    <div class="form-row">
                        <div class="form-group full-group mb-0">
                            <label>{{'Login'| translate}}</label>
                            <input class="form-control form-control-lg" placeholder="Login" name="userlogin" #UserLogin="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.UserLogin" required minlength="8" class.invalid="UserLogin.invalid && UserLogin.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                            <label>{{'Password'| translate}}</label>
                            <input class="form-control form-control-lg" type="password" placeholder="********" name="Password" #Password="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.Password" required minlength="8" class.invalid="Password.invalid && Password.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                            <label>{{'Name'| translate}}</label>
                            <input class="form-control form-control-lg" placeholder="UserName" name="User Name" #UserName="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.UserName" required class.invalid="UserName.invalid && UserName.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                            <label>{{'Mobile'| translate}}</label>
                            <input class="form-control form-control-lg" type="number" placeholder="Mobile" name="Mobile" #Mobile="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8" class.invalid="Mobile.invalid && Mobile.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                            <label>{{'Email'| translate}}</label>
                            <input class="form-control form-control-lg" type="email" placeholder="Email" name="Email" #Email="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.Email" required class.invalid="Email.invalid && Email.touched">
                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="form-group full-group mb-0">
                            <button class="btn btn-primary btn-lg btn-block form-control bd-dadios-0" type="submit" [disabled]="form.invalid">{{'Register'| translate}}</button>
                        </div>
                    </div>       
                </form>
            </div>
        </div>
        
       
    </div>
    
</div>
