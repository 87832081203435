<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" >Add School Shared Documents</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div >
                    <div  class="row form-row m-0">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="form-group mb-0">
                                <label>Name</label>
                                <input class="form-control form-control-sm " type="text"
                                placeholder="Name" name="Name"
                                #Name="ngModel"
                                [(ngModel)]="service.formData.AdminShareDocInfo.Name"
                                required class.invalid="Name.invalid && Name.touched"/>
                            </div>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="form-group mb-0">
                                <label>Type Of Post</label>
                            <select class="form-control form-control-sm appearance-auto" name="TypeOfPostId" #TypeOfPostId="ngModel"
                            [(ngModel)]="service.formData.AdminShareDocInfo.TypeOfPostId" (change)="fileTypeChange()">
                              <option [value]="0">Type Of Post</option>
                              <option *ngFor="let typeofpost of typeOfPostList" [value]="typeofpost.Id">{{typeofpost.Name}}</option>
                            </select>

                            </div>

                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            <div class="form-group mb-0">
                                <label>{{'School Category' | translate}}</label>
                                <!-- <select class="form-control form-control-sm appearance-auto" name="ClassId" #ClassId="ngModel"
                                [(ngModel)]="service.formData.EventInfo.ClassId">
                                  <option [value]="0">Select Class</option>
                                  <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                                </select> -->
                                <!-- <ng-multiselect-dropdown
                                    [placeholder]="'Search'"
                                    [settings]="dropdownSettings"
                                    name="SchoolCategoryIdList"
                                    [data]="schoolCatList"
                                    [(ngModel)]="service.formData.AdminShareDocInfo.SchoolCategoryIdList"
                                    (onSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                    (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                    </ng-multiselect-dropdown> -->
                              </div>
                          </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group mb-0"3
                                <label>School Category</label>
                            <select class="form-control form-control-sm appearance-auto" name="SchoolCategoryId" #SchoolCategoryId="ngModel"
                            [(ngModel)]="service.formData.AdminShareDocInfo.SchoolCategoryId"
                           >
                              <option [value]="0">School Category</option>
                              <option *ngFor="let school of schoolCatList" [value]="school.Id">{{school.Name}}</option>
                            </select>

                            </div>

                        </div> -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            <div class="form-group mb-0">
                                <label>{{'Purpose Of Post' | translate}}</label>
                                <!-- <ng-multiselect-dropdown
                                    [placeholder]="'Search'"
                                    [settings]="dropdownSettings"
                                    name="PurposeOfPostIdList"
                                    [data]="purofpostList"
                                    [(ngModel)]="service.formData.AdminShareDocInfo.PurposeOfPostIdList"
                                    (onSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                    (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                    </ng-multiselect-dropdown> -->
                              </div>
                          </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group mb-0">
                                <label>Purpose Of Post</label>
                            <select class="form-control form-control-sm appearance-auto" name="PurposeOfPostId" #PurposeOfPostId="ngModel"
                            [(ngModel)]="service.formData.AdminShareDocInfo.PurposeOfPostId"
                           >
                              <option [value]="0">Purpose Of Post</option>
                              <option  *ngFor="let purofpost of purofpostList" [value]="purofpost.Id">{{purofpost.Name}}</option>
                            </select>

                            </div>

                        </div> -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            <div class="form-group mb-0">
                                <label>{{'Challenge' | translate}}</label>
                                <!-- <ng-multiselect-dropdown
                                    [placeholder]="'Search'"
                                    [settings]="dropdownSettings"
                                    name="ChallengeIdList"
                                    [data]="challengeList"
                                    [(ngModel)]="service.formData.AdminShareDocInfo.ChallengeIdList"
                                    (onSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                    (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                    </ng-multiselect-dropdown> -->
                              </div>
                          </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group mb-0">
                                <label>Challenge</label>
                            <select class="form-control form-control-sm appearance-auto" name="ChallengeId" #ChallengeId="ngModel"
                            [(ngModel)]="service.formData.AdminShareDocInfo.ChallengeIdList"
                           >
                              <option [value]="0">Challenge</option>
                              <option *ngFor="let challenge of challengeList" [value]="challenge.Id">{{challenge.Name}}</option>
                            </select>

                            </div>

                        </div> -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            <div class="form-group mb-0">
                                <label>{{'State' | translate}}</label>
                                <!-- <ng-multiselect-dropdown
                                    [placeholder]="'Search'"
                                    [settings]="dropdownSettings"
                                    name="StateId"
                                    [data]="stateIdList"
                                    [(ngModel)]="service.formData.AdminShareDocInfo.StateIdList"
                                    (onSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onSelectAll)="onSelectAll($event,'SchoolCategory')"
                                    (onDeSelect)="onItemSelect($event,'SchoolCategory')"
                                    (onDeSelectAll)="onSelectAll($event,'SchoolCategory')">
                                    </ng-multiselect-dropdown> -->
                              </div>
                          </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group mb-0">
                                <label>State</label>
                            <select class="form-control form-control-sm appearance-auto" name="StateId" #StateId="ngModel"
                            [(ngModel)]="service.formData.AdminShareDocInfo.StateId"
                           >
                              <option [value]="0">State</option>
                              <option *ngFor="let state of stateIdList" [value]="state.Id">{{state.Name}}</option>
                            </select>

                            </div>

                        </div> -->

                        <div class="row justify-content-c m-0  bg-white p-2" *ngIf="canFileUpload">
                            <div class="row border m-0 py-2 mb-2 justify-content-between p-0">

                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                    <div class="form-group mb-0" >
                                        <!-- <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)" name="DocumentId"
                                        [(ngModel)]="service.formData.AdminShareDocInfo.DocumentId"
                                        (updatedResponseEmitter)="filesuploaded($event)"
                                        title="{{'Upload File' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                                        </mb-large-files-uploader> -->
                                        <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="DocumentId" [(ngModel)]="service.formData.AdminShareDocInfo.DocumentId"
                                        (updatedResponseEmitter)="filesuploaded($event)"
                                        title="Upload File"  canUpload="true"
                                        canSendData="true" saveButtonText="" saveButton="false"  class="inner-btn-none">
                                    </mb-large-files-uploader>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!canFileUpload">
                            <div class="form-group mb-0">
                                <label>Url</label>
                                <input class="form-control form-control-sm appearance-auto" type="text"
                                placeholder="Url" name="Url"
                                #Url="ngModel"  [(ngModel)]="service.formData.AdminShareDocInfo.Url"
                                required class.invalid="Url.invalid && Url.touched"/>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button"  class="btn btn-success" (click)="saveDocument()" type="button" >Save</button>
                <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
