<!-- <ul class="list-sidebar bg-defoult clear-both mt-3">
    <li>
      <a routerLink="/teacherdashboard/" routerLinkActive="active" >
        <i class="fa fa-th-large"></i>
        <span class="nav-label">{{'Dashboard' | translate}} </span>
      </a>

  </li>
  <li>
   <a routerLink="/calendar/" routerLinkActive="active">
      <fa-icon [icon]="calendarIcon"></fa-icon>
     <span class="nav-label">Calendar</span></a>
  </li>

  <li>
    <a href="#" data-toggle="collapse" data-target="#functionsDropdown" [ngClass]="{'active': (questionActive.isActive || assignmentActive.isActive || eventActive.isActive ),'collapsed':!(questionActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
      <i class="fa fa-sliders-h" aria-hidden="true"></i> <span class="nav-label">Functions</span> <span class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse" id="functionsDropdown" [ngClass]="{'show': (questionActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
      <li class="active"><a routerLink="/question/" routerLinkActive="active" #questionActive="routerLinkActive">Assessments</a></li>
      <li><a routerLink="/assignment/" routerLinkActive="active" #assignmentActive="routerLinkActive">Assignments</a></li>
      <li><a  routerLink="/event/" routerLinkActive="active" #eventActive="routerLinkActive">Interactive Live Classes</a></li>
    </ul>
    </li>

  <li> <a  routerLink="/teacher-leave/" routerLinkActive="active"> <fa-icon [icon]="leaveIcon"></fa-icon> <span class="nav-label">Leave</span></a> </li>
  <li> <a  routerLink="/time-tables/" routerLinkActive="active"> <fa-icon [icon]="timeIcon"></fa-icon> <span class="nav-label">Time Table</span></a> </li>
  <li> <a  routerLink="/class-dairy/" routerLinkActive="active"> <i class="fa fa-book" aria-hidden="true"></i> <span class="nav-label">Class Dairy</span></a> </li>
  <li> <a  routerLink="/user-list/" routerLinkActive="active"><i class="fa fa-users"></i> <span class="nav-label">Students</span></a> </li>
  <li> <a  routerLink="/chat/" routerLinkActive="active"><i class="fa fa-comments"></i> <span class="nav-label">Chat</span></a> </li>
  <li> <a  routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span class="nav-label">Attendance</span></a> </li>

  </ul> -->


<!-- <ul class="menu_outer">
<li class="active" data-bs-toggle="tooltip" title="Dashboard">
  <i class="bi bi-speedometer2"></i>
  <span>Dashboard</span>
</li>
<li data-bs-toggle="tooltip" title="Calendar">
  <i class="bi bi-calendar2-event"></i>
  <span>Calendar</span>
</li>
<li data-bs-toggle="tooltip" title="Chat">
  <i class="bi bi-chat-text"></i>
  <span>Chat</span>
</li>
<li data-bs-toggle="tooltip" title="Attendance">
  <i class="bi bi-journals"></i>
  <span>Attendance</span>
</li>
<li data-bs-toggle="tooltip" title="Assignments">
  <i class="bi bi-file-earmark-text"></i>
  <span>Assignments</span>
</li>
</ul> -->

<li data-bs-toggle="tooltip" title="{{menuItem.Name}}" *ngFor="let menuItem of menuItems; let i=index"
  routerLink="/{{menuItem.Route!==('/' || '')?menuItem.Route.split(' ').join(''):'/404'}}/" routerLinkActive="active"
  #parenActive="routerLinkActive">
  <i class="bi bi-{{menuItem.Icon}}"></i>
  <span>{{menuItem.Name}} </span>
</li>




<!-- <ul class="list-sidebar bg-defoult clear-both mt-3">


  <li *ngFor="let menuItem of menuItems; let i=index">
    <a routerLink="/{{menuItem.Route!==('/' || '')?menuItem.Route.split(' ').join(''):'/404'}}/"
      routerLinkActive="active" #parenActive="routerLinkActive" *ngIf="menuItem.Type == 'link'">
      <img src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{parenActive.isActive?'_green':'_gray'}}.svg"
        alt="{{menuItem.Icon}}">
      <span class="nav-label">{{menuItem.Name}} </span>
    </a>

    <a href="" *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length<=1"
      routerLink="/{{menuItem.ChildMenuList[0].Route!==('/' || '')?menuItem.ChildMenuList[0].Route:'/404'}}/"
      routerLinkActive="active">
      <img src="../../assets/images/icons/svg/sidebar/{{menuItem.ChildMenuList[0].Icon}}_gray.svg"
        alt="{{menuItem.ChildMenuList[0].Icon}}">
      <span class="nav-label">{{menuItem.ChildMenuList[0].Name}}</span>
    </a>

    <a href="#" data-toggle="collapse" data-target="#{{menuItem.Code}}Dropdown"
      [ngClass]="(IsOpend == i &&  IsCollapse )?'active':'collapsed'" (click)="menuChange(i)"
      *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
      <img
        src="../../assets/images/icons/svg/sidebar/{{menuItem.Icon}}{{(IsOpend == i &&  IsCollapse )?'_green':'_gray'}}.svg"
        alt="{{menuItem.Icon}}">
      <span class="nav-label">{{menuItem.Name}}</span> <span class="fa fa-chevron-left float-right"></span>
    </a>
    <ul class="sub-menu collapse show" id="{{menuItem.Code}}Dropdown"
      [ngClass]="{'show': (IsOpend == i &&  IsCollapse ) }"
      *ngIf="menuItem.Type == 'sub' && menuItem.ChildMenuList.length>1">
      <li *ngFor="let subMenuItem of menuItem.ChildMenuList; let childIndex = index">
        <a routerLink="/{{subMenuItem.Route!==('/' || '')?subMenuItem.Route:'/404'}}/" routerLinkActive="active"
          (click)="subMenuChange(i+childIndex)">{{subMenuItem.Name}}</a>
      </li>
    </ul>

  </li>

</ul> -->