<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
        <form novalidate #form="ngForm" (submit)="resetPassword(form)" autocomplete="off">
            <div class="modal-header bg-theam p-1">
                <h3 class="modal-title h3 m-1">{{'UserEdit' | translate}}</h3>
            </div>
            <div class="breackout_body p-2">

                <div class="row form-row m-0">
                    <div class="form-group col-12 p-0 pr-1 mb-0">
                        <div class="row form-row m-0 border border-secondary pt-2">
                            <div class="form-group col-4 mb-0">
                                <label>{{'CurrentPassword'| translate}}</label>
                                <input class="form-control form-control-sm" type="password" placeholder="********"
                                    name="Password" #Password="ngModel"
                                    [(ngModel)]="service.formData.ResetPasswordInfo.Password" required minlength="5"
                                    class.invalid="Password.invalid && Password.touched">
                            </div>
                            <div class="form-group col-4 mb-0">
                                <label>{{'NewPassword'| translate}}</label>
                                <input class="form-control form-control-sm" type="password" placeholder="********"
                                    name="Password" #Password="ngModel"
                                    [(ngModel)]="service.formData.ResetPasswordInfo.NewPassword" required minlength="5"
                                    class.invalid="Password.invalid && Password.touched">
                            </div>
                            <div class="form-group col-4 mb-0">
                                <label>{{'ConfirmPassword'| translate}}</label>
                                <input class="form-control form-control-sm" type="password" placeholder="********"
                                    name="Password" #Password="ngModel"
                                    [(ngModel)]="service.formData.ResetPasswordInfo.ConfirmPassword" required
                                    minlength="5" class.invalid="Password.invalid && Password.touched">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="submit" class="btn btn-success">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.RESET_PWD_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>